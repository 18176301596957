import React from "react";

const naishadh_vora_vmware = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Naishadh Vora
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>VMware</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p class="has-line-data" data-line-start="20" data-line-end="21">
              Standard structure was followed - 2 Tech, 1 Manager, 1 HR.
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p class="has-line-data" data-line-start="24" data-line-end="25">
              They had 15-20 MCQs for quant and technical sections. Then were 2
              sections of questions (10-15 each) which were programming language
              specific. You had 4 options of programming languages, and you had
              to choose 2 of them (I went with C and Java). Then there was one
              coding question which had to be solved in 30 minutes. I could only
              manage half of the test cases, but I got through because they
              decided to shortlist a lot of candidates (50+) and then eliminate
              in the interviews.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <p class="has-line-data" data-line-start="28" data-line-end="29">
              First technical round lasted about 45 mins. I was asked about OS
              concepts - deadlock, threading, etc for about 15 minutes, and then
              given a small coding question about trees. It was simple, just a
              small variation on pre order traversal. I was asked to code this
              out (in the language of my preference - I went with Java). Then he
              gave me a few variations on the same question and asked me how I
              would handle those. These were just discussed, I did not have to
              code these out.
            </p>
            <p class="has-line-data" data-line-start="30" data-line-end="31">
              The second technical round was slighly longer, about an hour, and
              I was asked about a few networking concepts along with OS. I was
              asked about caching, it’s need, and different types of caches
              (FIFO, LRU, etc). One of the coding questions was about the
              appropriate data structures I would use to implement a LRU cache.
              Then we had a discussion about the variations about the same,
              optimizing space/time, spatial vs temporal requirement to find out
              least recently used element, etc. Finally we had a discussion
              about sorting techniques, and whether the random pivot in
              quicksort makes a difference in the time complexity, and what
              sorting technique would be more appropriate on a linked list vs
              array list.
            </p>
            <p class="has-line-data" data-line-start="32" data-line-end="33">
              The next round was a manager round, where he asked me to introduce
              myself and then some basic networking questions. This was followed
              by a coding questions and then some general puzzles. It lasted for
              about an hour.
            </p>
            <p class="has-line-data" data-line-start="34" data-line-end="35">
              The HR round was more of a formality and I was asked about my
              background, future plans, etc.
            </p>
          </p>

          <p>
            <b>
              4. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            <p class="has-line-data" data-line-start="38" data-line-end="39">
              They will mostly focus on OS/Networking questions and you should
              prepare well for those. They are flexible about the language of
              your choice, but C++/Java would be preferable. Apart from this,
              you should definitely make it a point through the past interview
              experiences of others from GeeksForGeeks, I was asked a couple of
              questions which were the exact same. All the best!
            </p>
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:voranaishadh@gmail.com">voranaishadh@gmail.com</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default naishadh_vora_vmware;

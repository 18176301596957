import React from "react";

const ritik_naik = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Ritik Naik</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              The internship was only open to CS students
              <br></br>
              There were 3 rounds
            </p>
            <ol>
              <li>
                <strong>Aptitude + coding</strong>
              </li>
              <li>
                <strong>Technical Interview</strong>
              </li>
              <li>
                <strong>Senior Manager Interview</strong>
              </li>
            </ol>
            <p>After this I was asked to call HR regarding the offer.</p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />

            <li>
              The aptitude round was moderate. It was held on Amcat platform and
              had questions based on CS, Verbal ability, Logical and
              mathematical reasoning. There was a dedicated section to Data
              related questions which included graph interpretation and stocks
              data. Time was divided section wise so speed was the essence here.
            </li>
            <li>
              The coding questions were also easy-moderate. There were two
              coding questions:
              <ol>
                <li>
                  <b>Question 1:</b> An input bit sequence was given and we were
                  supposed to fill in missing bits from the input and return all
                  possible answers.(this was a backtracking question)
                </li>
                <li>
                  <b>Question 2:</b> Based on geometry where it was asked to
                  find the coordinates of a quadrilateral given some data about
                  its diagonal(Logical, mathematical)
                </li>
              </ol>
              <p>
                The coding questions were quite lengthy to read but once
                understood,then both the questions were doable.
              </p>
            </li>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              There were 2 interview rounds (1 Technical and 1 Senior
              Managerial)
            </p>
            <li>
              The Technical Interview went on for a long time, almost 1 hr. He
              touched almost all parts of CS and DSA. I was given 4 live coding
              questions based on BST, linked list, arrays
              <ol>
                <li>
                  I was asked to code a BST from scratch including structure
                  definition.
                </li>
                <li>Sorted insert in linked list</li>
                <li>
                  Binary conversion(He wanted a particular solution here, the
                  usual printing of binaries did not satisfy him)
                </li>
                <li>A simple Logic based question was asked</li>
              </ol>
              <p>
                After the live coding he continued with QnA asking general
                programming(paradigms), OOP(4 pillars) and DBMS questions. He
                also gave some scenrios in particular programming language and
                asked about their output.
              </p>
            </li>
            <li>
              After the Technical interview was the Senior Manager Interview
              Round which again went on for about 45min. This round was purely
              based on my Resume and projects. Almost everything from my resume
              was touched(including non technical skills and experiences which I
              had mentioned)
              <br></br>
              <p>
                He asked questions on Web, DBMS, OOP, Memory Management. He went
                in depth about my projects and hackathons. He particularly asked
                about my approaches in the projects and hackathons which did not
                work, so be thorough not only about the working of the projects
                but also the changes and different architectures and algorithms
                which you tried but did not work. He was mainly focused on my
                problem solving thinking ability.
              </p>
            </li>
          </p>

          <p>
            <b>4. How many questions were asked based on the projects done?</b>
            <br />
            <p>
              The entire Senior Manager round (45 min) was based on my Projects
              and Resume while touching few other general concepts.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              GeeksforGeeks is a really good of interview preparation and
              problem solving for DSA. For CP I used Codechef(I did not do much
              CP). If you are a beginner then Hackerrank is a good place to
              start. For specific company related experiences again GFG archives
              and glassdoor are good. You can also use DSA 450 questions by Love
              Babbar. For aptitude try solving RS Aggarwal, Indiabix is a good
              platform to practice. I would suggest to stick to one or max two
              languages to practice coding. I mainly used C++ and if required
              then I used python.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              <article>
                Practice coding problems regularly and be consistent in it.
                Stick to one or two coding platforms. I did my practice on GFG
                and Codechef. Try to cover all DSA concepts (try to do it
                randomly and don’t just stick to one one type of data structure
                of weeks) and when you feel that you have a good grip over it
                then try solving some algorithm. Competitive programming. DP is
                a must and also it improves your thinking ability. While
                practicing do as many problems as you can but keep in mind that
                number of problems you solve do not matter.
              </article>
              What matters is how efficiently and quickly you solve the problems
              and what you learn from them.
              <article>
                When solving the coding round questions first try the naive
                approach, anaylze the problem further and if time permits then
                go for optimization in.Focus should be to try and cover as many
                test cases as you can.
              </article>
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            Be good at typing. Be sure about what you put in your resume, you
            must be able to answer any question which is related to your
            resume.. The interviewers are not only interested in your technical
            skills but also in your professional skills and other activities. If
            you do not know the answer to a question then instead of beating
            round the bush, admit it straight away that you do not know the
            exact answer but do explain whatever knowledge you have about the
            question.
          </p>
          <br />
          <br />
          <p>
            <i>
              Be calm and friendly. Keep a smile on your face. Be fluent and
              confident in what you say. Try to feel that the interview is just
              a normal conversation.
            </i>
          </p>

          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />

            <a href="mailto: ">naikrr19.comp@coep.ac.in</a>
            <br />
            <a href="https://www.linkedin.com/in/ritik-naik-a207551b2/">
              LinkedIn Handle
            </a>

            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ritik_naik;

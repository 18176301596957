import React from "react";

const anup_nair = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Anup Nair</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            DE Shaw &amp; Co.
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              DE Shaw had an online hiring process in College of Engineering
              Pune on 1 September 2020. All electrical branches were allowed
              with a CGPA criteria of 7.5. The structure of the entire process
              was as follows:
            </p>
            <ol>
              <li>
                <strong>Coding and Aptitude Test</strong>
              </li>
              <li>
                <strong>Technical Interview Round 1</strong>
              </li>
              <li>
                <strong>Technical Interview Round 2</strong>
              </li>
              <li>
                <strong>HR interview</strong>
              </li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              The process began with a pre-placement talk on 1<sup>st</sup>
              September 2020 followed by an aptitude test the same day. The test
              was conducted on{" "}
              <a href="https://www.hackerrank.com">HackerRank</a> platform and
              had 4 sections:
            </p>
            <ol>
              <li>
                <strong>Quant MCQs</strong> (10 Questions +2 for correct -0.5
                for wrong)
              </li>
              <li>
                <strong>Technical MCQs</strong> (10 Questions +2 for correct
                -0.5 for wrong)
              </li>
              <li>
                <strong>Coding Section</strong> (1 Question)
              </li>
              <li>
                <strong>Systems MCQs</strong> (5 Questions +2 for correct -0.5
                for wrong)
              </li>
            </ol>
            <p>
              The test was timed for 70 minutes and each section had their own
              time limits with no rollover. The first section was the coding
              section and it had one question to be solved in 20 minutes.
            </p>
            <b>The question goes as follows :</b>
            <p>
              You have stones in the weight range of 1 to 9 inclusive and you
              are supposed to build a stack with the stones. The stack is said
              to be stable if the weight of a stone below is always greater than
              or equal to the ones above it. Given a sum S and number of stones
              M we have to find an ideal stack structure that would maximise the
              function s<sub>1</sub>
              <sup>2</sup>
              <em>
                s<sub>2</sub>
                <sup>2</sup> .....
              </em>
              s<sub>m</sub>
              <sup>2</sup> will be maximised and the resultant stack structure
              should be printed starting from the bottom. If no such stack
              exists then the program should return -1. Coming up with a DP
              based solution was the approach but it was time consuming and 20
              minutes was a short time. Try to test it against the base cases
              and get most test cases passed. A greedy approach for the same by
              traversing and building the stack would also suffice.
            </p>
            <p>
              Technical had 10 MCQs to be solved in 20 minutes. The questions
              covered almost all CS fundamentals including{" "}
              <strong>DSA, OOPs, DBMS</strong> and some{" "}
              <strong>networking</strong>. There were also many predict output
              questions. Taking time and solving questions is the key. Try to
              solve as many as possible. Systems MCQs were more based on general
              understanding and was easy to solve. Quant was lengthy and key to
              a good score is to practice a lot and have formulas handy. The
              questions were moderately difficult and time management would be
              able to fetch a good score.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              At the end of Aptitude test 16 students were shortlisted from 135
              students.
            </p>
            <p>
              The
              <strong>
                {" "}
                <em>first round</em>{" "}
              </strong>
              of the <strong>Technical Interview</strong> was scheduled on 3rd
              September 2020 on BlueJeans platform. The interview lasted for 45
              minutes.
            </p>
            <p>
              There was a panel of two interviewers and they were extremely
              helpful and friendly to talk to. The interview began with a brief
              introduction about myself and the interviewer asked me to go
              through my projects. Since I did not have any internship
              experience prior to this, most of the focus was on my projects. I
              had three projects on my resume and they focused heavily on the
              concepts covered in the projects. My projects had several tools
              and technologies associated with them and I was questioned on
              almost all aspects of the projects.
            </p>
            <b>Some of the questions that were asked during round 1 were:</b>
            <ol>
              <li>
                Describe all the views that you incorporated in your project and
                how are each views protected from the other users?
              </li>
              <li>
                Since I had a login system with the project I was asked about
                the potential drawbacks of my approach and was asked to design a
                more efficient and safer approach for the same. I suggested
                hashing but the interviewer asked me to think more. He asked me
                about Private and Public Keys and asked me whether that could be
                a potential approach. I explained him the modified approach and
                he was happy.
              </li>
            </ol>
            <p>
              Next they asked me a few questions on OOP. Having a good
              understanding of the basic concepts were enough to get most of the
              questions. This was followed by questions on DSA which was
              moderately difficult. They again looked back at my resume and
              questioned me on another project. They asked my contributions in
              the project and wanted me to explain the ins and outs of all that
              I had done. Since the project was also live, they checked it out.
              They asked me the entire process of how an API call is done and
              what all is involved in it. After I explained, they asked me to
              reduce the number of API calls to the minimum. I suggested caching
              the responses and they looked satisfied. Next they asked me my
              favourite subject and I answered Computer Networks. They asked me
              about the TCP/IP suite and some questions on peer to peer
              architecture. The interview concluded with them asking an
              application of networking concepts in one of my projects.
            </p>
            <p>
              5 students were shortlisted for the next round.{" "}
              <strong>
                <em>Round 2</em>
              </strong>{" "}
              was also held on BlueJeans platform. This interview went for 50
              minutes.
            </p>
            <p>
              Second round was a <strong>Technical and HR Round</strong>{" "}
              combined and I had a panel of 5 people. The interview began with
              me having a small talk with the HR and then they started with some
              HR based questions. I was asked to introduce myself and about my
              non-technical activities. I had mentioned that I do digital
              illustration and then I was asked about the softwares I use. Next
              they moved to technical. I was asked to explain the architecture
              of one of the projects that I had mentioned. They asked me the
              pros and cons of the architecture design and our solutions to
              tackle them. The interviewer kept on asking a lot of follow up
              questions on the project. I was then asked about NAT and a few
              more networking concepts. Then they followed it up with some DSA
              questions which were relatively easier as compared to first round.
              The discussion then shifted to my DSA project which was external
              sorting. This was followed up with some questions on OS. I was
              asked about Paging in OS. Then they asked me about my choice of
              programming language. They asked me about difference between C++
              and Python, complied and interpreted languages, internal
              implementation of Python Data Structures and a few more questions
              on compilation process. HR continued with a few more questions
              towards the end and gave me a puzzle. The interview concluded
              after that and it lasted for about 50 minutes.
            </p>
            <p>
              In brief, the interviews were pretty deep, and all that you claim
              to know will be tested thoroughly. The interviewers are pretty
              great and they keep on providing hints and suggestions to your
              answers. Just try to stay calm and answer the questions
              confidently.
            </p>
          </p>

          <p>
            <b>4. How many questions were asked based on the projects done?</b>
            <br />A lot of them. My interviews were mainly centred around my
            projects and resume. The major chunk of the interview consisted in
            checking my understanding of the projects and all the tech-stacks
            that I had used. They focused heavily on scalable and optimised
            approaches in my projects and went very deep into my projects. They
            also asked me about my extra-curricular activities from my resume
            and focused on a lot of key points that I had mentioned.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              I started off with revision of all basic DSA concepts and moved
              onto{" "}
              <a href="https://www.codechef.com/">
                <em>CodeChef</em>
              </a>
              . I would suggest using CodeChef only if you have a lot of time in
              hand. Else try using{" "}
              <a href="https://leetcode.com/">
                <em>Leetcode</em>
              </a>
              ,{" "}
              <a href="https://www.hackerrank.com/">
                <em>HackerRank</em>
              </a>{" "}
              and{" "}
              <a href="https://www.geeksforgeeks.org/">
                <em>GFG</em>
              </a>{" "}
              for a lot of company centric problems and common resources. GFG is
              the best for technical interviews and make sure that you cover all
              important topics in DSA and OOP. Use{" "}
              <a href="http://indiabix.com/">
                <em>Indiabix</em>
              </a>{" "}
              and/or RS Aggarwal book for aptitude. Keep on practising timed
              tests for solving more questions.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Keep a list of good problems that you have solved. This will come
              in handy during the last days. Keep on solving questions on
              HackerRank and Leetcode daily. Even if you don’t get the question
              completely, try to use the brute force approach and get maximum
              test cases passed. Refer to{" "}
              <a href="https://github.com/PlayerForever/CS_eBooks/blob/master/Cracking%20the%20Coding%20Interview%206th%20Edition.pdf">
                <em>Cracking the Coding Interview</em>
              </a>{" "}
              for some good questions too. Keep C++ and/or Python handy for
              solving questions. Read the time limits before attempting the
              question and choose the language accordingly.
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            DE Shaw has a pretty focused hiring process. You will be tested on a
            lot of concepts that you claim to know. Very important to have good
            questions and knowing the ins and outs of your projects. Keep
            revisiting old projects and get insights into optimising them and
            making improvements into them. Understand the fundamentals of any
            tool that you use as you will be questioned very deeply in the
            interviews. DSA and OOP fundamentals along with some basics of OS
            and DBMS are always a plus point. Try to stick to the point and
            answer accurately.
          </p>
          <br />
          <p>
            <i>
              Stay motivated throughout the entire process as sometimes you may
              not end up getting the best result. Aptitudes are also dependent
              on luck so accept it and keep trying. Once in the interview, you
              can more freely express your ideas. Keep motivating yourself and
              your friends. Also reach out to seniors in case of any help. They
              can really do a lot in keeping a calm head and guiding you with
              the right resources. At the end of the day, keep working hard and
              smart. Be optimistic to learn from experiences.
            </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:nairas18.comp@coep.ac.in">
              nairas18.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/anupn08/">LinkedIn Handle</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default anup_nair;

import React from "react";

const soham_dombale = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Soham Dombale
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Pattern Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Aptitude round (online)
                                </li>
                                <li>
                                    Technical round
                                </li>
                                <li>
                                    HR round
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Three coding questions introduced on doselect platform.
                            <br />
                            Time : 90 mins
                            <br />
                            <ol>
                                <li>
                                    A question similar to house robbers.Concept used: A circular linked list.
                                </li>
                                <li>
                                    The overlapping interval problem.
                                </li>
                                <li>
                                    Was relatively confusing and due to time constraints, I couldn't solve it.
                                </li>
                            </ol>
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 2 rounds: (held at the company itself)
                        <ol>
                            <li>
                                Technical Round:
                                <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                    <li>
                                        Half hour interview
                                    </li>
                                    <li>
                                        The interviewer mainly was interested in my projects and cross
                                        questioned it.
                                    </li>
                                    <li>
                                        Type of Questions:
                                        <ul style={{ listStyleType: "square", paddingLeft: "40px" }}>
                                            <li>
                                                Why this project?
                                            </li>
                                            <li>
                                                What problems has it solved? Anything I could upgrade? Can we implement another data structure on it?  Why this data structure?
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        He was also interested in DBMS and asked how can I implement
                                        SQL in my project.
                                    </li>
                                    <li>
                                        I was asked to write the basic queries in SQL and explain them as well.
                                    </li>
                                    <li>
                                        OOPS : code in java on abstraction, overloading and overriding.
                                    </li>
                                    <li>
                                        Basic DSA questions like binary search ,merge sort, their complexity.
                                    </li>
                                    <li>
                                        Basic linux commands.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                HR Round:
                                <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                    <li>
                                        10-15 min interview
                                    </li>
                                    <li>
                                        Self introduction.Basic questions related to family, friends, extracurriculars.
                                    </li>
                                    <li>
                                        Questions like what do I expect from the company?Why pattern
                                        tech?
                                    </li>
                                </ul>
                                Note:Generally HR is looking for communication skills, body language.
                            </li>
                        </ol>

                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Online Aptitude round : 21 students
                        <br />
                        Technical Round : 10-12 students
                        <br />
                        HR Round : 7 students
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Coding ninjas interview questions, Leetcode, Striver and Love babbar.
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                <li>
                                    Be consistent with your work!
                                </li>
                                <li>
                                    Stay updated.
                                </li>
                                <li>
                                    Practise common interview questions.
                                </li>
                            </ul>

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                <li>
                                    Most of the questions were about the project.
                                </li>
                                <li>
                                    2-3 questions on FOSS( Free and open source software) since I'm a member of COFSUG.
                                </li>

                            </ul>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Be frank and confident, about yourself as well as your work .Have a general idea of what the company does.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Be frank and stay curious so as to never stop learning. Don't be afraid to make mistakes - they're your best teachers. And certainly don't feel demotivated.
                            Enjoy your journey and be the best part of yourself as it may be hard but not impossible.
                            <br />
                            Good luck juniors!

                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        LinkedIn : <a href="https://www.linkedin.com/in/soham-dombale?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">Soham Dombale</a>
                        <br />
                    </div>
                </div>
            </section >
        </div >
    );
};

export default soham_dombale;

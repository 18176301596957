import React from "react";

const falguni_patil_citi = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Falguni Patil
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>CITI </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />
            There was a ppt followed by a written test and 2 interview rounds (1
            Tech + 1 HR round).
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            Written test was divided into following sections:
            <ol>
              <li>Quantitative aptitude</li>
              <li>Logical reasoning</li>
              <li>
                Technical mcqs (c output/error questions, dbms, OS, OOPS,
                Networking)
              </li>
              <li>2 coding questions(1 easy + 1 medium).</li>
            </ol>
            Try to solve at least 1 coding question. Every section of written
            test had some cutoff so solve every section seriously. (There was no
            negative marking).
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            There were 2 rounds (1 tech + 1 HR).
            <ol>
              <li>
                Technical interview -
                <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
                  <li>
                    Introduce yourself, then she asked me to explain one of my
                    projects.
                  </li>
                  <li>
                    In which language you’re more comfortable (While answering
                    this question make sure that your all concepts of that
                    language are clear).(I told c and c++, hence she asked
                    further questions on c and c++)
                  </li>
                  <li>
                    Difference between c and c++, OOPs concept in c++, virtual
                    functions, abstract class, function overriding, function
                    overloading.
                  </li>
                  <li>
                    Apply concepts of oops in cricket(she was checking if I can
                    apply concepts in real life as well).
                  </li>
                  <li>
                    What is key, difference between foreign key and primary key,
                    what is normalization?
                  </li>
                </ul>
                Then she asked two puzzles -
                <ol>
                  <li>
                    <a href="https://www.geeksforgeeks.org/puzzle-7-3-bulbs-and-3-switches/">
                      Puzzle 1
                    </a>
                  </li>
                  <li>
                    Find a number, which is twice the sum of its digits. (eg .
                    27)
                  </li>
                </ol>
              </li>
              <li>
                HR interview -
                <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
                  <li>
                    Introduce yourself, and Explain one of the best projects of
                    yours.
                  </li>
                  <li>Tell me about your internship and work you did.</li>
                  <li>What makes you different from others?</li>
                  <li> What do you know about CITI Corp?</li>
                  <li>
                    {" "}
                    Why did you apply for CITI and not Credit Suisse(as I did my
                    internship at cs)?
                  </li>
                  - I answered that CITI visited COEP earlier than Credit Suisse
                  then he modified the question to the next question.
                  <li>
                    What will you choose between citi and credit suisse and why?
                  </li>
                  <li>What do you do in your free time?</li>
                  <li>
                    He asked me if I’ve any accounts on platforms like Codechef,
                    Hackerrank, Leetcode etc.
                  </li>
                  <li>Where do you see yourself in 5 years?</li>
                </ul>
              </li>
            </ol>
            Then He was done with the interview and seemed satisfied with my
            answers. He asked me if I’d question for him.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            Interviewers asked me 2-3 questions on projects, Most of the
            interviewers start their interview by asking questions on the
            resume.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            For technical interview preparation I referred Geeksforgeeks,
            CTCI(Cracking the coding interviews) and Leetcode.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Practice coding questions on leetcode and hackerrank.(That really
            helps for solving coding questions)
            <br />
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            At least one question in interview will be on dbms so make your dbms
            concepts clear before appearing for the interview. Make sure that
            your resume is precise and mention only things on which you can
            talk.
            <br />
          </p>

          <i>
            Be confident during interview that makes more difference on our
            impression, during answering HR questions make sure you are positive
            about it and don’t bluff any answer. If you are not sure about your
            answer clearly mention it. All the best!!
            <br />
          </i>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:patilfp17.it@coep.ac.in">patilfp17.it@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default falguni_patil_citi;

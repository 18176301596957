import React from "react";

const pallavi_buwa = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Pallavi Buwa
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />
            Aptitude test + 2 technical rounds + 1 HR (merged with tech for us)
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test was conducted on the AMCAT platform which consisted of 3 sections:
            <ul style={{paddingLeft:"40px", listStyle:"disc"}}>
              <li>
              English proficiency
              </li>
              <li>
              Quant - aptitude questions based on graphs and stocks
              </li>
              <li>
              Coding - 2 questions, 30 minutes each (not everyone got the same questions) <br />
              <ol>
                <li>
                The first one was a grid based questions wherein coordinates of a swimming pool were given, along with those of buildings surrounding it and their respective heights. The floors of the buildings with a direct, unobstructed view of the pool were to be priced higher than the ones without and the aim was to calculate the prices of the floors in a given building.
                </li>
                <li>
                The second one was the application of an N-ary tree to calculate the maximum throughput in a given network with multiple connections. The main challenge was constructing the n-ary tree to solve the problem.
                </li>
              </ol>
              </li>
            </ul>
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            2 technical rounds followed by an HR round were planned for us, however the HR round was merged with the tech rounds.
            <ol style={{paddingLeft:"40px"}}>
              <li>
              In the first round, I was asked about all the skills I had stated in my resume. I was asked to explain how and where I had used the tools and technologies I had mentioned. I was also asked to code a BST and then explain one of my projects. I was also quizzed on basic OOPs paradigms and asked some general tech questions. This was followed by HR questions where I was asked about my strengths and weaknesses and my long term plans.
                
              </li>
              <li>
              In the second round, I was asked about my areas of interest and the previous work I had done. I was asked about the project I had worked on during my internship and what I had learnt from it. Finally, I was given a case study and asked to find an optimal approach to tackle it. This was followed by general talk about the company and the finance domain.
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            27 students were shortlisted for the first round. However, some of these had been placed in other companies by then so only around 20 appeared for the interviews. Out of these, 7 were selected.
          </p>

          <p>
            <b>
              5. Some good resources you used in your Placement prep
            </b>{" "}
            <br />
            Coding: LeetCode and Hackerrank <br />
            Quant: IndiaBix <br />
            Theory: GFG
          </p>
          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Practice is the key. Having thorough knowledge of one programming language for coding helps a lot. Trying to solve as many questions as possible on core data structures and dynamic programming.

            <br />
          </p>

          <p>
            <b>
              7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume?
            </b>
            <br />
            <p>I was asked to explain only one of the projects I had mentioned in my resume. No questions were asked regarding clubs and extracurricular activities.
            </p>
          </p>
          <p>
            <b>
              8. Is there something specific that you would want to tell people who try for the
              same company?
            </b>
            <br />
            <p>Some background information about the company and general knowledge about the finance domain will definitely help (especially stocks).</p>
          </p>
          <p>
            <b>
              9.Final Tip for Juniors
            </b>
            <p>
            Stay calm and confident. Be honest at all times. Explain your thought process to the interviewer. Be consistent with your coding practice and keep revising DSA, OS, CN and DBMS concepts. All the best!
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email ID : <a href="mailto:buwapu19.comp@coep.ac.in">
            buwapu19.comp@coep.ac.in
            </a>            
          </div>
        </div>
      </section>
    </div>
  );
};
export default pallavi_buwa;

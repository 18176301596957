module.exports = {
  backGround: {
    minWidth: "100%",
    minHeight: "100vh",
    backgroundColor: "#000000",
    backgroundImage: `url(${require("../assets/img/bg/Background.jpg")})`,
    // backgroundImage: `url(
    //   https://c4.wallpaperflare.com/wallpaper/410/77/199/5bd0bdc12d414-wallpaper-preview.jpg
    //   )`,
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
};

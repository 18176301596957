import React from "react";

const sanchit_rajmane = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Sanchit Nilkanth Rajmane
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Siemens</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There were two rounds: <br />
                            <ol>
                                <li>
                                    Round 1 - Aptitude Round
                                </li>
                                <li>
                                    Round 2 - Interview Rounds  - Technical + HR Round

                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Online Mode
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Total 2 rounds including the aptitude round <br />
                        <ol>
                            <li>
                                First round was Aptitude Round <br />
                                <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                    <li>
                                        Quants - 10 Questions
                                    </li>
                                    <li>
                                        English - 10 Questions
                                    </li>
                                    <li>
                                        Logical  - 10 Questions
                                    </li>
                                    <li>
                                        Computer Fundamentals 25 Questions
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Second Round : Technical +HR Round <br />
                                Most of the questions were based on my resume and extracurricular activities, such as my hobbies and interests. The interviewer asked me a lot about my projects, focusing on how they were built and my specific role and responsibilities in them. Additionally, the interviewer wanted to know about my career goals and aspirations. There were the usual HR questions, including some about my personal life. Overall, if you’re preparing for an interview with Siemens, make sure you can discuss your projects in detail, be ready to answer technical questions related to the skills you’ve listed on your resume, and expect some personal and HR questions too.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Around 50 students were shortlisted for Aptitude Test <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                After 1st Round - 13
                            </li>
                            <li>
                                After 2nd Round - 10
                            </li>
                        </ul>

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                GFG last minute notes on OS, CN, DBMS
                            </li>
                            <li>
                                For Recursion, DP and Graph - Take U Forward Series
                            </li>
                            <li>
                                Striver’s SDE sheet
                            </li>
                            <li>
                                Leetcode
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practice , Practice and Practice
                            Quality of questions is much more important than the Quantity of questions
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Many questions related to extra-curricular and co-curricular activities were asked , as they help the interviewer to judge your overall personality.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                <li>
                                    <b>Build Some Good Projects:</b> Focus on building good web development projects. There are many well-designed courses and YouTube tutorials out there. Try to work with technologies like Java with Spring Boot, React, or Angular.
                                </li>
                                <li>
                                    <b>Be Patient:</b> : Remember, good things take time. Stay patient.
                                </li>
                                <li>
                                    <b>Learn from your mistakes</b> As DP wisely said, "Those who cannot remember the past are condemned to repeat it." Always learn from your mistakes to keep improving.
                                </li>
                            </ul>
                        </p>
                    </p>
                    <br />
                </div>
            </section >
        </div >
    );
};

export default sanchit_rajmane;

import React from "react";

const neha_pawar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Neha Pawar</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            CarrotSoft Technologies Pvt.Ltd.
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            Technical + HR Interview Round
            <br />
            <b>Structure of Aptitude Round :</b>
            <br />
            No aptitude test was conducted.
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <p>
              There was only one interview round. The overall duration of the
              interview was 15-20 minutes. To start with I was asked “Tell me
              about yourself” precisely in 1 to 1.5 minutes. The job role was
              Android Developer so the interviewer asked me about technologies,
              programming languages I know regarding it. There were many
              questions related to the project mentioned in the resume. I was
              asked mainly about the thought process and challenges faced in
              projects. Interviewer also asked about concepts related to DBMS
              and OOPS. As I mentioned about HTML-CSS in my resume, he also
              asked me about my interest in frontend development. At the end I
              was asked ‘Why should I hire you?’ This is a very common question
              so be prepared for this in advance.
            </p>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              Total 14 students were interviewed and 4 were given the final
              offer.
            </p>
          </p>

          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              <ol>
                <li>
                  <b>DSA -</b>
                  450 DSA Cracker | GfG Practice | Leetcode | Hackerrank |
                  Striver
                </li>
                <li>
                  <b>For Aptitude:</b>{" "}
                  Indiabix | Interviewbit
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>5. Some tips to crack the coding questions.</b> <br />
            <p>
            Practice is the only key. Practise regularly and time yourself on the questions. Track your progress. Revise questions as needed.
            </p>
          </p>
          
          <p>
            <strong>6. Final Tip for Juniors :</strong>
            <p>
              <ol>
                <li>Be calm throughout the process and don't get disheartened by the failures.</li>
                <li>Be confident while answering questions, but don’t bluff.</li>
                <li>This selection is based on luck also, so always keep your chin up.</li>
                <li>Always remember the best is yet to come!</li>
              </ol>
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:pawarnj20.comp@coep.ac.in">
            pawarnj20.comp@coep.ac.in
            </a>
            <br />
            LinkedIn: <a href="https://www.linkedin.com/in/neha-pawar-987b17211/">Neha Pawar</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default neha_pawar;

// quasim_shaikh
import React from "react";

const quasim_shaikh = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Qasim Shaikh</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Hilti Technology Solutions India
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>The entire process was divided into 3 rounds:</p>
            <ol>
              <li>Aptitude Test (90 minutes)</li>
              <li>Technical Interview Round</li>
              <li>HR Interview</li>
            </ol>
            <b>Structure of Aptitude Round :</b>
            <br />
            The Aptitude Test consisted of multiple sections:
            <ol>
              <li>Coding question</li>
              <li>OOPs</li>
              <li>CN</li>
              <li>DBMS</li>
              <li>OS</li>
              <li>Mathematical and Verbal Reasoning</li>
            </ol>
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <ol>
              <li>
                <b>Aptitude</b>
                <br />
                The Aptitude round was conducted <b>
                  online off campus
                </b> on <b>Mercer Mettl</b> Online Assessment platform. The
                candidates were given a window of 4 hours and 30 minutes to
                appear for the test. The test was for 90 minutes
                <br />
                During the test, we were allowed to navigate between sections
                except for the Mathematical Reasoning which was for 30 minutes.
                <br />
                The problem statement for the Coding Question was to extract
                Dates from large strings which can be done using{" "}
                <b>Regular Expression.</b>
              </li>
              <li>
                <b>Technical Interview</b>
                <br />
                The interview started with my introduction, and then the
                interviewer asked me to explain the approach for the Coding
                question asked in the aptitude test. I was able to explain to
                him two approaches to the given question and later on explained
                to him why have I gone for Regular Expression.
                <br />
                Further, I was asked questions on OOPs like:
                <ol>
                  <li>What are the four pillars of OOPs?</li>
                  <li>Explain each of them with a real-life example.</li>
                  <li>What are various types of Inheritance?</li>
                </ol>
                <br />
                After that, I was asked questions on DBMS like:
                <ol>
                  <li>What is DBMS?</li>
                  <li>Difference between DBMS and RDBMS.</li>
                </ol>
                <br />
                Then I was asked some questions related to my projects. The
                Interviewer asked me why did I choose MongoDB as my database for
                the project which I have mentioned in my resume. Furthermore, I
                was asked to explain my DSA project which was Autocomplete and
                Autocorrection using Tries. I mentioned to him some limitations
                in the project, so the next question was how will you overcome
                these limitations. To answer such type of question one should
                have in-depth knowledge of the Data Structure used in the
                project.
                <br />
                Then I was asked to give Pseudo Code for a String problem which
                is to find First Repeating Character Sequence In String (E.g. 
                <mark style={{"backgroundColor": "pink"}}>aaa</mark>bbbaabb<mark>aaa</mark>). I was then asked to convert my solution to
                source code by using any programming language of my choice
                (Python) and then explain the approach (the reason I chose
                Python was that String Manipulation is easier in it as compared
                to other languages).
                <br />
                After that, I was asked two puzzles:
                <ol>
                  <li>
                    <a href="https://www.geeksforgeeks.org/puzzle-8-balls-problem/">
                      8 Balls Problem
                    </a>
                  </li>
                  <li>
                    <a href="https://www.geeksforgeeks.org/puzzle-set-35-2-eggs-and-100-floors/">
                      2 Eggs and 100 Floors
                    </a>
                  </li>
                </ol>
                While solving the puzzles what matters most is your thought
                processing, so make sure that you can express it clearly and
                precisely to the interviewer.
                <br />
                This round lasted for around 1 hour.
              </li>
              <li>
                <b>HR Interview</b>
                <br />
                The interview started with my introduction, in which I told the
                interviewer about my education journey, my hobbies, and the
                extracurricular activities that I mentioned on my resume. He
                then asked some questions about my family background. (I will
                recommend you all to deliver some unique and interesting facts
                about yourself while giving your introduction). Then I was asked
                questions about the clubs which I was a part of, their inducting
                process.
                <br />
                Furthermore, He asked me:
                <ol>
                  <li>Why do you want to join Hilti?</li>
                  <li>Where do you see yourself in 5 years?</li>
                  <li>
                    Do you consider yourself best among the other candidates who
                    are part of this process?
                  </li>
                </ol>
                After that, the Interviewer explained me about the organization.
                He also asked me whether I attended the Pre-Placement Talk for
                their Internship Program. And I honestly answered him that I did
                not 🙂.
                <br />
                This round latest for around 20 to 25 minutes.
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              <ol>
                <li>
                  Technical Interview - <b>26</b> students
                </li>
                <li>
                  HR Interview - <b>18</b> students
                </li>
                <li>
                  Final offer - <b>5</b> students
                </li>
              </ol>
            </p>
          </p>
          

          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              <ol>
                <li>
                  <a href="https://www.udemy.com/course/datastructurescncpp/">
                    Mastering Data Structures & Algorithms using C and C++
                  </a>{" "}
                  - by Abdul Bari
                </li>
                <li>
                  <a href="https://450dsa.com/">450 DSA Sheet</a>- by Love
                  Babbar
                </li>
                <li>
                  <a href="https://www.youtube.com/@takeUforward">
                    Graphs and Dynamic Programming
                  </a>
                  - by Striver
                </li>
                <li>
                  <a href="https://www.geeksforgeeks.org/lmns-gq/">
                    LMNs for CN, DBMS and OS{" "}
                  </a>
                  - by GFG
                </li>
                <li>
                  <a href="https://www.tutorialspoint.com/cplusplus/cpp_object_oriented.htm">
                    OOPs in C++
                  </a>
                  - by tutorialspoint
                </li>
                <li>
                  <a href="https://www.indiabix.com/">IndiaBix</a>-for Aptitude
                </li>
                <li>
                  <a href="https://www.interviewbit.com/practice/">
                    InterviewBit
                  </a>
                  -for Interview preparation
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>5. Some tips to crack the coding questions.</b> <br />
            <p>
              <ol>
                <li>
                  Try to complete most of the questions from the 450 DSA Sheet
                  as it will give you a good hold over the fundamentals of DSA
                </li>
                <li>
                  Check out past year's Coding questions asked in the Aptitude
                  test for your dream company.
                </li>
                <li>
                  Establish a good hold on libraries of the programming
                  languages which provide in-built optimized Data Structures
                  e.g. STL in C++ and Collections Framework in Java
                </li>
              </ol>
            </p>
          </p>
          
          <p>
            <strong>6. Final Tip for Juniors :</strong>
            <p>
            Don’t lose hope if you’re rejected by some of the companies. Take it in a positive
way to excel yourself by learning from the mistakes that you have done.
All the best 👍
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:shaikhmq20.comp@coeptech.ac.in">
            shaikhmq20.comp@coeptech.ac.in
            </a>
            <br />
            LinkedIn: 
            <a href="https://www.linkedin.com/in/qasim-shaikh-444217221/"> Qasim Shaikh</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default quasim_shaikh;

import coordinator from "../../img/team/2019/coordinator.jpg";
import hod from "../../img/team/2019/hod.jpg";
import president from "../../img/team/2021/president.jpeg";
import vicePresident from "../../img/team/2021/vicePresident.jpg";
import techLead1 from "../../img/team/2021/techLead.jpeg";
import techLead2 from "../../img/team/2021/techLead2.jpg";
import financeHead1 from "../../img/team/2021/financeHead.jpg";
import financeHead2 from "../../img/team/2021/financeHead2.jpg";
import eventHead1 from "../../img/team/2021/e&oHead.jpg";
import eventHead2 from "../../img/team/2021/e&oHead2.png";
import creativesHead1 from "../../img/team/2021/creativesHead.jpg";
import creativesHead2 from "../../img/team/2021/creativesHead2.jpg";

const team2021 = [
  {
    post: [
      {
        initial: "Dr. (Mrs)",
        name: "Vahida",
        surname: "Attar",
        desc: "Head, Department of Computer Engineering and Information Technology",
        image: hod,
        mail: "mailto:hod.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/vahidacompcoepacin",
      },
      {
        initial: "Mr.",
        name: "Amit",
        surname: "Joshi",
        desc: "CSI COEP Student Chapter Coordinator",
        image: coordinator,
        mail: "mailto:adj.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/adjcompcoepacin",
      },
    ],
  },
  {
    post: [
      {
        name: "Shreya",
        surname: "Vaidya",
        position: "President",
        image: president,
        linkedin: "https://www.linkedin.com/in/shreya-vaidya/",
        github: "https://github.com/shreyavaidya2311",
      },
      {
        name: "Sameer",
        surname: "Kavthekar",
        position: "Vice President",
        image: vicePresident,
        linkedin: "https://www.linkedin.com/in/sameer-kavthekar",
        github: "https://github.com/sameerkavthekar",
      },
    ],
  },
  {
    post: [
      {
        name: "Ashutosh",
        surname: "Pardeshi",
        position: "Technical Head",
        image: techLead1,
        linkedin:
          "https://www.linkedin.com/in/ashutoshsingh-pardeshi-ab0247195/",
        github: "https://github.com/Ashpar2001",
      },
      {
        name: "Kartik",
        surname: "Mandhan",
        position: "Technical Head",
        image: techLead2,
        linkedin: "https://www.linkedin.com/in/kartik-mandhan-13aa4b192",
        github: "https://github.com/kartikmandhan",
      },
      {
        name: "Ketaki",
        surname: "Jadhav",
        position: "Finance Head",
        image: financeHead1,
        linkedin: "https://www.linkedin.com/in/ketaki-jadhav-27b5591b4",
        github: "https://github.com/Ketaki-k-jadhav",
      },
      {
        name: "Nimit",
        surname: "Jain",
        position: "Finance Head",
        image: financeHead2,
        linkedin: "https://www.linkedin.com/in/nimit-jain-09911/",
        github: "https://github.com/Nim09911",
      },
      {
        name: "Vedant",
        surname: "Bairagi",
        position: "Events & Operations Head",
        image: eventHead1,
        linkedin: "https://www.linkedin.com/in/vedant-bairagi-4a874a201",
        github: "https://github.com/vedantBairagi",
      },
      {
        name: "Vishwesh",
        surname: "Pujari",
        position: "Events & Operations Head",
        image: eventHead2,
        linkedin: "https://www.linkedin.com/in/vishwesh-pujari-7205391b6",
        github: "https://github.com/vishwesh-pujari",
      },
      {
        name: "Mudit",
        surname: "Bapna",
        position: "Creatives Head",
        image: creativesHead1,
        linkedin: "https://www.linkedin.com/in/mudit-bapna-484b9b20a/",
        github: "https://github.com/Muditbapna",
      },
      {
        name: "Pratik",
        surname: "Bandre",
        position: "Creatives Head",
        image: creativesHead2,
        linkedin: "https://www.linkedin.com/in/pratik-bandre-5313971b9/",
        github: "https://github.com/Pratik-D-Bandre",
      },
    ],
  },
];

export default team2021;

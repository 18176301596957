import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
  Badge,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.05)",
      cursor: "pointer",
    },
    marginRight: "2rem",
    maxHeight: "50",
    minWidth: "275",
    margin: "1.2rem",
    backgroundColor: "#262d46",
    color: "white",
  },
  badgeRoot: {
    fontSize: "1.2rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    padding: ".6rem",
    paddingTop: ".9rem",
    paddingBottom: ".9rem",
    borderRadius: "1rem",
  },
  badgeColor: {
    backgroundColor: "rgba(44,79,150,1)",
    color: "rgba(255,255,255,1)",
  },
  badgeAlign: {
    top: "1.5rem",
    right: "1.8rem",
  },
}));

function NameCard(props) {
  const { image, firstname, lastname, company, isNew } = props;
  const classes = useStyles();
  // const [cardClicked, setCardClicked] = useState(false);
  // const [cardItem, setCardItem] = useState([]); //eslint-disable-line

  // const handleCardClick = () => {
  //   setCardClicked(true);
  //   setCardItem(html);
  // };

  // useEffect(() => {
  //   props.handleNameCardClick(cardClicked, html, date);
  // }, [cardClicked]); //eslint-disable-line

  return (
    <Badge
      color="primary"
      classes={{
        badge: classes.badgeRoot,
        colorPrimary: classes.badgeColor,
        anchorOriginTopRightRectangle: classes.badgeAlign,
      }}
      badgeContent="New"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      invisible={!isNew}
    >
      <Card className={classes.root}>
        <CardMedia
          component="img"
          image={image}
          style={{width: "300px",height:"300px", margin: "auto", objectFit: "cover" }}
          alt={`${firstname} ${lastname}`}
          title={`${firstname} ${lastname}`}
        />

        <CardContent className="text-center">
          <Typography
            gutterBottom
            variant="h5"
            component="h3"
            // style={{ fontFamily: "Open Sans" }}
          >
            {firstname} {lastname}
          </Typography>
          <Typography gutterBottom variant="h6" component="h4">
            {company}
          </Typography>
        </CardContent>
      </Card>
    </Badge>
  );
}

export default NameCard;

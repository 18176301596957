import React from "react";

const riddhi_tharewal = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
         Riddhi Tharewal
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Nuance</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            It was a short process comprising of -
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li>Resume Shortlisting (31 students were selected)</li>
              <li>Written test</li>
              <li>Tech + HR Interview</li>
            </ul>
            Finally 9 students were selected.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test was 90 minutes long and divided into 3 sections 
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li>
              2 Coding questions : 1st question was easy, 2nd was easy to moderate but both question were solvable.
              </li>
              <li>
              MCQ based on C/C++, DSA, Code snippets, aptitude, etc.
              </li>
              <li>2 subjective questions one of which was why you want to join nuance and 
second was where do you see yourself after 5 years.(Don’t remember exact question).
</li>
            </ul>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
           <p>
           There was one interview round which lasted for almost one hour. There were 3 interviewer. Interviewer first asked to introduce myself. They looked through my Resume thoroughly, and started with questions on my projects which I mentioned. Later they started asking the concepts on oops, the current project I am working on. The latest technologies I am aware of. They asked 2 SQL Query. Few questions on data structures like hashing, linked list, etc. At the end they asked 2 coding questions. They asked questions from DBMS, computer networks, DSA, OOP.


           </p>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>
            <br />
            <p class="has-line-data" data-line-start="48" data-line-end="51">
            As mentioned above the discussion itself started with projects , they first asked to explain the project and then follow up questions on projects.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <p class="has-line-data" data-line-start="54" data-line-end="56">
            I use to practice on HackerRank and at the time of internship I referred questions from GFG also. I used GFG for revising concepts related to DSA, OOP, etc. 

            </p>
          </p>

          <p class="has-line-data" data-line-start="57" data-line-end="58">
            <strong>6. Some tips to crack the coding questions.</strong>
            <p class="has-line-data" data-line-start="59" data-line-end="64">
            To crack the coding question there is only one tip to practice as many question as you can and keep revising it.
            </p>
          </p>

          <p>
            <i>
              Be calm and friendly. Keep a smile on your face. Be fluent and
              confident in what you say. Try to feel that the interview is just
              a normal conversation.
            </i>
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:tharewalrg19.comp@coep.ac.in">
            tharewalrg19.comp@coep.ac.in

            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default riddhi_tharewal;

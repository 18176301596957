import * as React from "react";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import "../assets/css/testimonial.css";
import { Button } from "@material-ui/core";
import { Typography, Box } from "@mui/material";
import editorial_2022 from "../assets/js/editorial";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Editorial() {

  

  const [open, setOpen] = React.useState(false);

  const [table, setTable] = useState([]);

  const handleClickOpen = (x) => {
    setTable(editorial_2022[0][x]);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const row1 = table[0]
  const row2 = table[1]
  const row3 = table[2]

  return (
    <Box >
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "30px", }}>
        <div class="card_testimonial">
          <div class="card-content_testimonial">
            <h1 style={{ textAlign: "center", marginBottom: "30px" }}>
              CodeQuest Junior
            </h1>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={3}
              xs={12}
            >
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  marginBottom: ".7rem",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    padding: "15px 15px 15px 15px",
                    backgroundColor: "#003399",
                    fontSize: "1rem",
                  }}
                  onClick={() => handleClickOpen("sy_week_1")}
                >
                  <Typography style={{color:"white", marginRight:"10px"}}>
                  {"Week - 1"}
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  marginBottom: ".7rem",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    padding: "15px 15px 15px 15px",
                    backgroundColor: "#003399",
                    fontSize: "1rem",
                  }}
                  onClick={() => handleClickOpen("sy_week_2")}
                >
                  <Typography style={{color:"white", marginRight:"10px"}}>
                  {"Week - 2"}
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  marginBottom: ".7rem",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    padding: "15px 15px 15px 15px",
                    backgroundColor: "#003399",
                    fontSize: "1rem",
                  }}
                  onClick={() => handleClickOpen("sy_week_3")}
                >
                  <Typography style={{color:"white", marginRight:"10px"}}>
                  {"Week - 3"}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <h1
              style={{
                textAlign: "center",
                marginTop: "40px",
                marginBottom: "30px",
              }}
            >
              CodeQuest Senior
            </h1>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={3}
              xs={12}
            >
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  marginBottom: ".7rem",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    padding: "15px 15px 15px 15px",
                    backgroundColor: "#003399",
                    fontSize: "1rem",
                  }}
                  onClick={() => handleClickOpen("ty_week_1")}
                >
                  <Typography style={{color:"white", marginRight:"10px"}}>
                  {"Week - 1"}
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  marginBottom: ".7rem",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    padding: "15px 15px 15px 15px",
                    backgroundColor: "#003399",
                    fontSize: "1rem",
                  }}
                  onClick={() => handleClickOpen("ty_week_2")}
                >
                  <Typography style={{color:"white", marginRight:"10px"}}>
                  {"Week - 2"}
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  marginBottom: ".7rem",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    padding: "15px 15px 15px 15px",
                    backgroundColor: "#003399",
                    fontSize: "1rem",
                  }}
                  onClick={() => handleClickOpen("ty_week_3")}
                >
                  <Typography style={{color:"white", marginRight:"10px"}}>
                  {"Week - 3"}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Editorial
          </BootstrapDialogTitle>
          <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Problem Name</StyledTableCell>
                  <StyledTableCell align="center">Difficulty Level</StyledTableCell>
                  <StyledTableCell align="center">Problem Statement</StyledTableCell>
                  <StyledTableCell align="center">Code</StyledTableCell>
                  <StyledTableCell align="center">Editorial</StyledTableCell>
                </TableRow>
              </TableHead>
              {row1 ? 
                <TableBody>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">{row1.problem_name}</StyledTableCell>
                  <StyledTableCell align="center">{"Easy"}</StyledTableCell>
                  <StyledTableCell align="center">
                    <a href={row1.problem_statement} target='_blank' rel='noopener noreferrer'>View</a>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <a href={row1.code} target='_blank' rel='noopener noreferrer'>View</a>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <a href={row1.explanation} target='_blank' rel='noopener noreferrer'>View</a>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">{row2.problem_name}</StyledTableCell>
                  <StyledTableCell align="center">{"Medium"}</StyledTableCell>
                  <StyledTableCell align="center">
                    <a href={row2.problem_statement} target='_blank' rel='noopener noreferrer'>View</a>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <a href={row2.code} target='_blank' rel='noopener noreferrer'>View</a>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <a href={row2.explanation} target='_blank' rel='noopener noreferrer'>View</a>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">{row3.problem_name}</StyledTableCell>
                  <StyledTableCell align="center">{"Hard"}</StyledTableCell>
                  <StyledTableCell align="center">
                    <a href={row3.problem_statement} target='_blank' rel='noopener noreferrer'>View</a>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <a href={row3.code} target='_blank' rel='noopener noreferrer'>View</a>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <a href={row3.explanation} target='_blank' rel='noopener noreferrer'>View</a>
                  </StyledTableCell>
                </StyledTableRow>
            </TableBody>:
                <>
                </>
              }
            </Table>
          </TableContainer>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </Box>
  );
}

import React from "react";

const advait_karmalkar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Advait Karmalkar
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>The recruitment process for Citi consisted of three rounds: –</p>
            <ol>
              <li>Aptitude Test (27th September, 2022)</li>
              <li>Technical Interview (28th September, 2022)</li>
              <li>HR Interview (28th September, 2022)</li>
            </ol>
            350+ students appeared for the aptitude test out of which 40
            students were shortlisted for the technical interview. Finally,{" "}
            <b>8 students were selected.</b>
            <b>Structure of Aptitude Round :</b>
            <br />
            So prior to the interview, an aptitude test was conducted, where
            students from all branches were allowed to appear.
            <ul style={{ listStyle: "circle", paddingLeft: "40px" }}>
              <li>
                The aptitude test was conducted on the AMCAT platform and was
                for 130 minutes.
              </li>
              <li>
                The assessment included quantitative ability, verbal reasoning,
                logical reasoning, technical MCQs, and two coding problems at
                the end.
              </li>
              <li>
                The 2 coding questions were as follows:
                <br />
                <b>Q1: </b>You have to encrypt a non-empty string phrase. The
                encryption adds a ‘cyclic shift’ to each letter where the value
                of this ‘cyclic shift’ is decided by the position of the letter
                from the end of its word. The shift value for each letter of a
                word is its index value (starting from 0) from the right-most
                character of the word.
                <br />
                <br />
                <b>Q2: </b>Write an algorithm to multiply the original increment
                matrix with its transpose.
              </li>
            </ul>
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <br />
            There were 2 interview rounds as follows:
            <ol>
              <li>
                <b>Technical Interview (30 minutes):</b>
                <br />A total of 40 candidates were selected for the technical
                interview. In the technical interview, the interviewer began by
                questioning, "Tell me about yourself," which is one of the most
                common questions asked during an interview. He then read my
                entire resume, focusing mainly on my projects and technical
                aspects. On my resume I had mentioned 4 projects, the
                interviewer read the descriptions and firstly asked me to
                describe one of my projects which involved file handling in C.
                He started by asking me about differences between binary and
                text files then asked me questions related to file processing in
                industrial applications. His focus was more on practical
                implementations than theoretical applications, the last question
                raised on this project was: "how do we handle the processing of
                very very large files and explain the methodology for the same".
                Next he brought up the project which comprised of heavily used
                Advanced Data Structures, and I had to explain everything in
                great detail. He asked, why I used Red Black trees, as opposed
                to AVL trees, and what was the advantage of using the Red Black
                tree. The other aspect was industry related, which was "At what
                level can your project be swiftly implemented, and how many
                maximum records can your system process on a larger scale?" i.e:
                Project - voting management system. As a final question, he
                asked me about OOPs, including polymorphism, inheritance, and
                many other topics.
              </li>
              <li>
                <b>HR Interview (20 minutes):</b>
                <br />
                Coming to the next round, they shortlisted around 18-20 students
                for the HR interview. This round started with a question "Tell
                me about yourself". During the interview, HR asked me to
                elaborate on one of my projects that was based on face
                recognition. He then contemplated my interests and achievements.
                He also asked me "Why Citi ?" and multiple situational
                questions. The HR round can be called as an HR cum technical
                round.
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              <ol>
                <li>
                  round 40 students were selected for the technical interview.
                </li>
                <li>8-20 students were selected for the HR interview.</li>
                <li>8 students received the final offer.</li>
              </ol>
            </p>
          </p>
          <p>
            <b>
              4. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <p>
              Most of the questions asked in the technical interview were
              related to projects. During the HR interview, extracurricular
              activities play a very significant role as they primarily assess
              the student's overall qualities and abilities. It was my national
              level table tennis achievements that prompted the interviewer's
              interest. I was also asked about my duties and responsibilities as
              swimming secretary. He also asked me about the certifications I
              learned playing the tabla. At the same time it is very important
              to show your technological interest.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              <ol>
                <li>
                  For coding practice, I solved questions on GeeksForGeeks,
                  HackerRank and LeetCode.
                </li>
                <li>
                  For the concepts of DSA and DBMS I watched the entire playlist
                  by Love Babbar which has end to end details of important
                  questions.
                </li>
                <li>
                  I would also recommend practicing and preparing some standard
                  answers for the HR interview.
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              <ol>
                <li>
                  Try to practice as many questions as possible on
                  GeeksForGeeks, Hackerrank, LeetCode.
                </li>
                <li>
                  In case you are stuck with the problem, try to analyze it and
                  first go for the brute force approach and then try to optimize
                  it as you proceed.
                </li>
                <li>
                  Analyzing and optimizing the code is very important as it may
                  give you the “Time Limit Exceeded” error.
                </li>
              </ol>
            </p>
          </p>
          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company :
            </strong>
            <p>
              I would recommend everyone to practice coding regularly and at the
              same time focus on developing your overall personality as your
              character and qualities are automatically reflected during the
              interview. Be optimistic, and I believe it is worthwhile to apply
              to Citi.
            </p>
          </p>
          <p>
            <strong>8. Final Tip for Juniors :</strong>
            <p>
              <ol>
                <li>
                  Be confident: Be optimistic and confident about what you say
                  during the interview. At the end of the day, you may find
                  yourself in a scenario where you do not know the answer to a
                  question. But you can still persuade the interviewer.
                </li>
                <li>
                  Build conversations by making the ambience a little casual by
                  building conversations.
                </li>
                <li>
                  Try to explain your skillset in a simple, appropriate manner
                </li>
                <li>
                  When adding skills to your resume, ensure that you are
                  confident about them and can answer any questions related to
                  them.
                </li>
              </ol>
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:advaitpk20.comp@coep.ac.in">
              advaitpk20.comp@coep.ac.in
            </a>
            <br />
            LinkedIn:
            <a href="https://www.linkedin.com/in/advait-karmalkar">
              Advait Karmalkar
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default advait_karmalkar;

import React from "react";

const sameer_kavthekar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Sameer Kavthekar
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            DE Shaw &amp; Co.
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              D. E. Shaw was the first company to come for internships on 15th
              August 2021. The entire process was completely online. <br />
              The eligibility criteria of D.E. Shaw were 7+ CGPA for all
              Electrical Group branches and no backlogs.
              <br />
              <br />
              The entire process was divided into 3 rounds:
              <ol>
                <li>Aptitude Test on HackerRank (80 minutes)</li>
                <li>Technical Interview 1 (30 minutes) </li>
                <li>Technical Interview 2 (50 minutes) </li>
              </ol>
              All the rounds took place on the same day.
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              The Aptitude Test was conducted on HackerRank, was 80 minutes long
              and had 26 questions.
              <br />
              Each section, except the coding question, had +2 for correct
              answer and -0.5 for wrong answer. The sections had to be attempted
              serially and switching between sections was not allowed.
            </p>
            <p>
              There were 4 sections in the test:
              <ol>
                <li>1 Coding Question (30 minutes) </li>
                <li>10 Quantitative Questions (20 minutes) </li>
                <li>10 Technical Questions (20 minutes)</li>
                <li>5 Systems Questions (10 minutes) </li>
              </ol>
            </p>
            <p>
              <b>Coding Question:</b>
              <br />
              The given question was:
              <br />
              <i>
                Given 2 arrays of size n, alien_health and alien_damage. Each
                element in alien_health contains health of the ith alien and
                each element in alien_damage contains the damage done by the ith
                alien. You are a player who has H health, and you do D damage.
                The player can earn points, equal to the alien's health, by
                killing an alien i.e., making the health of the alien 0 or less.
                The player always attacks first followed by the respective
                alien. The game ends when the players health becomes 0 or less.
                Find the maximum number of points the player can earn.
              </i>
            </p>
            <p>
              <b>Quant Section:</b>
              <br />
              This section had mathematical questions on concepts like:
              <ol>
                <li>Work-time</li>
                <li>Interest</li>
                <li>Trains</li>
                <li>Boat in river</li>
                <li>Tank and pipe</li>
                <li>Business Partnership</li>
              </ol>
              The last 3 questions were based on logical reasoning, like the
              questions done in DSGT subject.
              <br />
              <br />
              The questions were quite lengthy and knowledge of the type of
              question was essential. A lot of practice is required to score
              good in this section. Time management strategies will also help me
              score well.
            </p>
            <p>
              <b>Technical Sections:</b>
              <br />
              This section had questions on core concepts like Operating
              Systems, Computer Networks, Database Management Systems, Object
              Oriented Programming and Data Structures/Algorithms.
              <br />
              <br />
              This section requires one to know the minute details about the
              above topics. The questions targeted many unknown concepts and
              solving them was quite tricky.
            </p>
            <p>
              <b>Systems Section:</b>
              <br />
              This section contained easy, Computer Science General Knowledge
              Questions like “Which of the following is not a container manager
              (Hypervisor, Kubernetes Elastic and Mesos)".
              <br />
              <br />
              Staying informed about the latest technologies is the best
              strategy for this section.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              There were 2 technical interview rounds conducted, no HR round was
              conducted.
            </p>
            <p>
              <b>Technical Interview 1:</b>
              <br />
              16 students were shortlisted for this round.
              <br />
              There were two interviewers for this round and one of them was the
              overall head of the Systems Department. This interview round
              focussed solely on 2 projects that I had done - A webapp and a
              garbage collector.
              <br />
              They asked a lot about AWS, Docker, Reverse Proxies and Cloud
              which were technologies I had used in one of my projects. He also
              asked questions about Computer Architecture and Operating Systems
              like “What is the use of the heap in any program” and “How can you
              crash a computer using page faults”. The second interviewer asked
              me a basic question on reversing a linked list and then asked me
              about the architecture and design of a food ordering app. Then the
              interviewers asked me about the pillars of OOPs, Difference
              between C and C++, Reason why C++ is faster than Java. The
              interview concluded after they asked me about the different
              flavours of Linux that I had used.
            </p>
            <p>
              <b>Technical Interview 2:</b>
              <br />
              6 students were shortlisted for this round.
              <br />
              Like the last round, there were two interviewers. This interview
              was a pure technical one as they only asked me questions from
              Computer Networks, Operating Systems, Computer Organization. I was
              asked the following questions:
              <ol>
                <li>Difference between TCP and UDP sockets.</li>
                <li>
                  Maximum number TCP or UDP sockets that can be made in any UNIX
                  system.
                </li>
                <li>What is HTTPS and how is it different from HTPP.</li>
                <li>What is TLS and SSL Certificates.</li>
                <li>What is a kernel.</li>
                <li>Difference between thread and process.</li>
                <li>How files are stored in a computer.</li>
                <li>List different file systems.</li>
                <li>How paging works and what is a page table.</li>
                <li> Difference between 32-bit and 64-bit processor.</li>
                <li> What is a GPU and how is it different from a CPU.</li>
                <li>What is Interrupt Vector Table.</li>
                <li>Differences between Windows and Linux.</li>
              </ol>
              Finally, the interview concluded by them asking “If you had to buy
              a laptop, what would be the specifications you would look out
              for?”. For me this interview was extremely tough. I was not able
              to answer questions in much detail as the subjects had not been
              covered in class and I was cross questioned on every answer I
              gave.
            </p>
          </p>

          <p>
            <b>4. How many questions were asked based on the projects done?</b>
            <br />
            <p>
              The whole technical round 1 revolved around projects for me. I was
              asked about every little detail about my projects. I was also
              asked about alternatives to technologies I had used like “What
              could you have used instead of Nginx for reverse proxying”. I was
              also asked about the data structures in my garbage collector
              project and time complexities of some of the operations.
              <br />
              No questions on projects were asked in the second technical round.
            </p>
            <p>
              I feel that it is important to have good projects on your resume.
              It is also very important that you know every small detail about
              the projects you put on your resume.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
                <li>
                  For coding practice, I solved questions on GeeksForGeeks,
                  HackerRank and LeetCode
                </li>
                <li>
                  For Theory I referred GeeksForGeeks, Aditya Verma and Abdul
                  Bari
                </li>
                <li>
                  For Quant Questions I practiced from IndiaBix, solving RS
                  Agarwal is recommended as well.
                </li>
                <li>
                  I would also suggest looking at Last Minute Notes on
                  GeeksForGeeks before the test.
                </li>
              </ul>
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
                <li>
                  The best tip to crack the coding question is consistent
                  practice. Solving different types of questions on various
                  concepts will help you gain a core understanding of the
                  underlying algorithms and data structures being used. I also
                  advise solving 2-3 questions daily so that progress can be
                  made in an incremental manner.
                </li>
                <li>
                  During the test, try a brute force solution first and then
                  optimize it.
                </li>
                <li>
                  If unable to pass any test cases, try to find some edge case
                  and pass the test for it.
                </li>
                <li>
                  Try and stick to C++ or Java for the coding questions as they
                  have a vast standard library and are fast enough to not throw
                  TLE.
                </li>
              </ul>
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            DE Shaw focuses highly on your development skills, and you will be
            questioned about the very basics of any projects you mention on your
            resume. Interviews will also be focused on the core computer skills
            like Operating Systems, Networks and Databases. Be sure to be
            prepared for concepts that you have not learned in college yet. Try
            to be as precise with your answer as possible.
          </p>
          <br />
          <p>
            <i>
              The whole internship process is very hectic, and a lot of luck is
              involved in the process. Don't be demotivated if you don't get
              selected for any company and be sure to stay positive throughout.
              Keep talking to your family and friends and trust your own
              preparation. All the best!
            </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:kavthekarsr19.comp@coep.ac.in">
              kavthekarsr19.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/sameer-kavthekar/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default sameer_kavthekar;

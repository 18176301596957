import React from "react";

const jinit_sanghvi = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Jinit Sanghvi
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <h5>Structure of the hiring process</h5>
            <h6>
              <em>Aptitude Test</em>
            </h6>
            An online aptitude test was conducted on HackerRank with online
            proctoring to shortlist candidates for interviews. The Aptitude Test
            was 135 minutes long and this time was split section-wise, so even
            if you finished a particular section before time, you can’t utilize
            the remaining time for any other section. There was a coding
            section, which had 2 short coding problems followed by a quant
            section and a technical section. Quant was challenging and
            time-consuming and it covered topics ranging from permutations and
            combinations to geometry. Technical Section mostly had questions
            related to core Computer courses like DSA and Microprocessor
            Techniques but most of them were doable. There was an advanced
            coding section with a relatively tougher coding question that would
            require knowledge of Data Structures. Finally, there was a
            subjective section with essay type questions related to teamwork and
            tenacity.
            <br />
            <br />
            <h6>
              <em>Technical Interviews</em>
            </h6>
            Technical Interviews were conducted on CodePair which is basically a
            platform where you can live code and your interviewer will be
            sharing the same screen. I had 2 technical interviews and 1 HR plus
            technical interview at the end. Candidates were shortlisted for
            successive interviews on the basis of their previous interview, so
            it’s important to do well in all of them. My first technical
            interview involved live coding and a few questions about algorithms.
            For coding, I was asked to code in C since I was comfortable with it
            and they wanted to check my low- level programming. My second
            technical interview mainly involved tricky questions about
            programming languages, memory management and pointers. This was
            followed by 1 coding question. My last interview was part technical
            and part HR. In this interview, I was asked as to what I would do to
            implement a time-sensitive trading platform and how would I
            implement it in real time.
            <br />
          </p>

          <p>
            <h5>Questions based on Projects and Resume</h5>
            I was asked to briefly explain my projects and Resume in my first
            technical round and there were a few questions about my past
            projects and internships.
            <br />
          </p>

          <p>
            <h5>Good Resources for Preparation</h5>
            I think there are too many platforms out there, and almost all of
            them are sufficient for preparation. My advice is that you should
            stick to 1-2 platforms and try solving diverse questions ranging
            from Dynamic Programming to Binary Trees. I relied on HackerRank and
            GeeksForGeeks in order to prepare, and these platforms are more than
            enough. Be sure to checkout GeeksForGeeks archives where people
            share their experiences and the kind of questions they were asked.
            <br />
          </p>

          <p>
            <h5>Tips to crack the coding questions</h5>
            Be as consistent as possible and start solving coding questions well
            before these rounds so you get comfortable. It is important to be
            confident and composed while solving such questions and that comes
            only with practice. As far as interviews are concerned, live coding
            can be tough but try to explain your approach to the interviewer
            before you start. Showing that kind of initiative will have more
            impact than the final outcome.
            <br />
          </p>

          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:sanghvij18.comp@coep.ac.in">
              sanghvij18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default jinit_sanghvi;

import React from "react";

const tanmayee_bharati = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
          Tanmayee Nitin Bharati
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
          Colgate-Palmolive
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            There were 2 rounds for the selection process.
          
            <ol>
              <li>
                <p><b>Round 1:</b> Pymetrics Test
</p>
              </li>
              <li>
                <p><b>Round 2:</b> Personal Interview

</p>
              </li>

            </ol>
         
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            
            <p>
            Generally the company conducts an aptitude test but it wasn't there for us. A pymterics test was conducted which had 12 simple pymetrics games to test our personality and corporate qualities. Play these games with patience and concentration as the results are looked in very seriously, as the company analyses your dominating qualities and compare them with their requirements and decide if you fit the role.
                </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
            There was 1 personal interview the next day of pymetrics test. The interview went on for 20 to 25 minutes, where the interviewer mainly focused on my projects. More than half questions were asked based on one of the projects I did. I was asked to describe in brief about any one of the projects, data structures I implemented, domain I worked in,and the  functionalities. I had already prepared a short description of every project that included most of the above mentioned points, you can do the same so that you don't get stuck while explaining. Later I was asked about the challenges I faced while working on it, and why did I pick that topic for my project. Finally some general questions were asked like why teamwork is important, what are your future goals, what motivates me to keep working on a problem or a challenge. The key to answer these questions is BEING TRUE. The interviewers going to ask feedback questions over every answer you give, so it's better if you answer what you really believe in and know the reason why you do :)

            </p>
          
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <p>
            
            Maximum questions were based on the project. More than 10 of 20 mins of the interview were utilized solely on my project. Resume was just looked upon to refer my extra curricular activities and other projects. 
            
            </p>
         
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
            GFG to revise DSA and OOPs, IndiaBix for general interview questions.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
            We didn't have an aptitude test still I would suggest the ones I referred for the test- HackerRank's Interview Preparation Kit, competitive coding practice on Leetcode to strengthen your coding skills.

            </p>
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
         
          <p>
          I came to know in the interview that the company works around Aritficial Intelligence/ Machine Learning domain, so if you are interested in it, you may take up a course on Coursera or UpGrad and learn atleast the basics of AI/ML. This might give you a upper hand during the interviews. Research over the company's recent activities and achievements as being updated about the company you have applied is always beneficial!
          </p>
          </p>
          <br />
          <p>
              <i>
              Be calm and true while you answer the questions. Think out loud so that the interviewer knows about your thought process. The interviewers are very friendly and cooperative, so don't worry about the interviews, answer them confidently. 
Prepare well! All the best!

              </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:bharatitn19.comp@coep.ac.in">
            bharatitn19.comp@coep.ac.in 
            </a>
            
            
          </div>
        </div>
      </section>
    </div>
  );
};

export default tanmayee_bharati;

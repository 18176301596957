import * as React from "react";
import { makeStyles, Grid } from "@material-ui/core"
import { useState } from "react";
import "../assets/css/testimonial.css";
import { Button } from "@material-ui/core";
import { Typography, Box } from "@mui/material";
import editorial_2023 from "../assets/js/editorial23";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TY_Q1 from "../assets/content/Legendary Blacksmith.pdf";
import TY_Q2 from "../assets/content/Jack & Tom.pdf";
import TY_Q3 from "../assets/content/World Of Zenorita.pdf";
import SY_Q1 from "../assets/content/Mystical Realm of Eldoria.pdf";
import SY_Q2 from "../assets/content/The city of Cinetopia.pdf";
import SY_Q3 from "../assets/content/Festival of Lights.pdf";
import { FaGithub } from "react-icons/fa";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isMobile, isTablet } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    cardContent: {
        [theme.breakpoints.down("md")]: {
            padding: "0px",
        }
    },
    heading:{
        textAlign: "center",
        margin: "30px", 
        color:'white',
        [theme.breakpoints.down("md")]: {
            fontSize: theme.typography.pxToRem(25)
        } 
    }
    
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function Editorial() {
    const classes = useStyles();
    const table_sy = editorial_2023[0]['sy'];
    const table_ty = editorial_2023[0]['ty'];
    const sy_row1 = table_sy[0]
    const sy_row2 = table_sy[1]
    const sy_row3 = table_sy[2]
    const ty_row1 = table_ty[0]
    const ty_row2 = table_ty[1]
    const ty_row3 = table_ty[2]

    return (
        <Box >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "30px", marginTop:"40px" }}>
                <div class="card_testimonial">
                <div class={classes.cardContent}>
                    {/* <div class="card-content_testimonial" > */}

                        <h1 class={classes.heading}>
                            CodeQuest Junior
                        </h1>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Problem Name</StyledTableCell>
                                        <StyledTableCell align="center">Difficulty Level</StyledTableCell>
                                        <StyledTableCell align="center">Problem Statement</StyledTableCell>
                                        <StyledTableCell align="center">Editorial</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                {sy_row1 ?
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">{sy_row1.problem_name}</StyledTableCell>
                                            <StyledTableCell align="center">{"Easy"}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={sy_row1.problem_statement} target='_blank' rel='noopener noreferrer'>View</a>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={SY_Q1} download="Mystical Realm of Eldoria.pdf">Download</a>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">{sy_row2.problem_name}</StyledTableCell>
                                            <StyledTableCell align="center">{"Medium"}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={sy_row2.problem_statement} target='_blank' rel='noopener noreferrer'>View</a>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={SY_Q2} download="The city of Cinetopia.pdf">Download</a>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">{sy_row3.problem_name}</StyledTableCell>
                                            <StyledTableCell align="center">{"Hard"}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={sy_row3.problem_statement} target='_blank' rel='noopener noreferrer'>View</a>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={SY_Q3} download="Festival of Lights.pdf">Download</a>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody> :
                                    <>
                                    </>
                                }
                            </Table>
                        </TableContainer>

                        <h1 class={classes.heading}>
                            CodeQuest Senior
                        </h1>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Problem Name</StyledTableCell>
                                        <StyledTableCell align="center">Difficulty Level</StyledTableCell>
                                        <StyledTableCell align="center">Problem Statement</StyledTableCell>
                                        <StyledTableCell align="center">Editorial</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                {ty_row1 ?
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">{ty_row1.problem_name}</StyledTableCell>
                                            <StyledTableCell align="center">{"Easy"}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={ty_row1.problem_statement} target='_blank' rel='noopener noreferrer'>View</a>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={TY_Q1} download="Legendary Blacksmith.pdf">Download</a>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">{ty_row2.problem_name}</StyledTableCell>
                                            <StyledTableCell align="center">{"Medium"}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={ty_row2.problem_statement} target='_blank' rel='noopener noreferrer'>View</a>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={TY_Q2} download="Jack & Tom.pdf">Download</a>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">{ty_row3.problem_name}</StyledTableCell>
                                            <StyledTableCell align="center">{"Hard"}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={ty_row3.problem_statement} target='_blank' rel='noopener noreferrer'>View</a>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <a href={TY_Q3} download="World Of Zenorita.pdf">Download</a>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody> :
                                    <>
                                    </>
                                }
                            </Table>
                        </TableContainer>
                        <div style={{ marginTop: '20px', marginRight: '20px', textAlign: "end" }}><b style={{ color: "white", marginRight: "8px", font: "2.5rem" }}>Code :</b>
                            <a href="https://github.com/CSI-COEP/CodeQuest4.0-Solutions" target="_blank" rel="noopener noreferrer">
                                <i>
                                    <FaGithub size={30} />
                                </i>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </Box>
    );
}

import { v4 as uuidv4 } from "uuid";


export const TestimonialEvents = [
  {
    eventName: "Inspiron 2.0",
    eventlink:"https://www.linkedin.com/posts/csicoep_csi-coep-computerscience-activity-7046456692607258624-Z_fe?utm_source=share&utm_medium=member_desktop",
   
    feedBacks: [
      {
        id: uuidv4(),
        heading: "Inspiron 2.0 - Feedback ",
        subHeading: "2023",
        description:
          "We really had a very good experience in our participation in the Inspiron 2.0 and we would like to thank you for providing us the oppurtunity to participate in this Hackathon!",
      },
      {
        id: uuidv4(),
        heading: "Inspiron 2.0 - Feedback ",
        subHeading: "2023",
        description:
          "I had a new experience in participating via online mode and i would like to thank the Team Inspiron 2.0 for providing us this oppurtunity to participate in this Hackathon. Again, Thank you team Inspiron 2.0",       
      },
      {
        id: uuidv4(),
        heading: "Inspiron 2.0 - Feedback ",
        subHeading: "2023",
        description:
          "First i would like to let the Inspiron 2.0 Organising team know that this is my First Hackathon and i'm really feeling honoured to be a part of this Hackathon. i had a very good experience here and i would like to thank the Team Inspiron 2.0 for providing our team this opportunity for being a part of this Hackathon.",
      },
      {
        id: uuidv4(),
        heading: "Inspiron 2.0 - Feedback ",
        subHeading: "2023",
        description:
          "The hackathon was very well conducted. Mentoring was properly done and was a very good experience.",
      },
      {
        id: uuidv4(),
        heading: "Inspiron 2.0 - Feedback ",
        subHeading: "2023",
        description:
          "It was an amazing experience! From start to finish, the event was incredibly well-organized.The hackathon provided an excellent platform for me to showcase our skills and develop new ones. I also appreciated the guidance and mentorship that was available to us throughout the event. The organizers were always on hand to answer our questions and provide support, and the judges gave us valuable feedback that helped us refine our ideas and presentation.",
      },
    ],
  },
  {
    eventName: "Code to Compete",
    eventlink:"https://www.linkedin.com/posts/csicoep_competitiveprogramming-competitivecoding-activity-6855499178441752576-n0wv?utm_source=linkedin_share&utm_medium=android_app",
   
    feedBacks: [
      {
        id: uuidv4(),
        heading: "Code to Compete - Feedback ",
        subHeading: "2021",
        description:
          "The session was quite smooth and well planned. Most of the doubts were solved in the session itself. An extremely good introductory session. Thank you!",
         

      },
      {
        id: uuidv4(),
        heading: "Code to Compete - Feedback ",
        subHeading: "2021",
        description:
          "It was good session and cleared doubts like to start with which programming language. Thanks for Today's Session!!",       
      },
      {
        id: uuidv4(),
        heading: "Code to Compete - Feedback ",
        subHeading: "2021",
        description:
          "An amazing session, great insights on competitive programming.",
      },
      {
        id: uuidv4(),
        heading: "Code to Compete - Feedback ",
        subHeading: "2021",
        description:
          "Good Session, really informative and helpful!",
      },
    ],
  },
  {
    eventName: "Chrome Dev-Tools",
    eventlink:"https://www.linkedin.com/posts/csicoep_computer-society-of-india-coep-student-chapter-activity-6890579900697571328-1BLc?utm_source=linkedin_share&utm_medium=android_app",
   
    feedBacks: [
      {
        id: uuidv4(),
        heading: "Chrome Dev-Tools - Feedback ",
        subHeading: "2022",
        description:
          "The session was great and really interactive. Also, many doubts were cleared. So yes, it was an amazing session ",
       
      },
      {
        id: uuidv4(),
        heading:  "Chrome Dev-Tools - Feedback ",
        subHeading: "2022",
        description:
          "Amazing session! Looking forward to more such sessions! ",
        
       
      },
      {
        id: uuidv4(),
        heading:  "Chrome Dev-Tools - Feedback ",
        subHeading: "2022",
        description:
          "It was great and got to learn some new stuff",
          
        
      },
      {
        id: uuidv4(),
        heading:  "Chrome Dev-Tools - Feedback ",
        subHeading: "2022",
        description:
          "Overall session was informative. Good session ",
         
        
      },
    ],
  },
  {
    eventName: "Internship for Dummies",

    feedBacks: [
      {
        id: uuidv4(),
        heading: "Internship for Dummies - Feedback ",
        subHeading: "2022",
        description:
          "In session everything was explained properly and doubts were cleared. It was helpful to get bird sight view about internships. Thank you for conducting session.",
      
       
      },
      {
        id: uuidv4(),
        heading: "Internship for Dummies - Feedback ",
        subHeading: "2022",
        description:
          "The session was really great and to the point. It really helped me. Thank you for session guys",
      
      },
      {
        id: uuidv4(),
        heading: "Internship for Dummies - Feedback ",
        subHeading: "2022",
        description:
          "Extremely well presented with a good flow and delineation of topics. Thank you!",
          
       
      },
      {
        id: uuidv4(),
        heading: "Internship for Dummies - Feedback ",
        subHeading: "2022",
        description:
          "Great session, got a better idea about internships now! Thank you!",
       
      
      },
    ],
  },
  {
    eventName: "Surviving SY for Dummies",
   
    feedBacks: [
      {
        id: uuidv4(),
        heading: "Surviving SY for Dummies - Feedback ",
        subHeading: "2021-22",
        description:
          "The session was truly relevant and to the point. Thank you for conducting the session.",
        eventlink:"https://www.linkedin.com/posts/csicoep_internships-mentor-dummies-activity-6866752761057288193-mGty?utm_source=linkedin_share&utm_medium=android_app"
      },
      {
        id: uuidv4(),
        heading: "Surviving SY for Dummies - Feedback ",
        subHeading: "2021-22",
        description:
          "We got the track we were in search of.. Really helpful",
        eventlink:"https://www.linkedin.com/posts/csicoep_internships-mentor-dummies-activity-6866752761057288193-mGty?utm_source=linkedin_share&utm_medium=android_app"
      },
      
      {
        id: uuidv4(),
        heading: "Surviving SY for Dummies - Feedback ",
        subHeading: "2021-22",
        description:
          "You guys were awesome. In a nutshell, this session helped me a lot to clear my doubts regarding the subjects.",
        eventlink:"https://www.linkedin.com/posts/csicoep_internships-mentor-dummies-activity-6866752761057288193-mGty?utm_source=linkedin_share&utm_medium=android_app"
      },
      {
        id: uuidv4(),
        heading:"Surviving SY for Dummies - Feedback ",
        subHeading: "2021-22",
        description:
          "The session was amazing. It cleared most of my doubts about this new semester.",
        eventlink:"https://www.linkedin.com/posts/csicoep_internships-mentor-dummies-activity-6866752761057288193-mGty?utm_source=linkedin_share&utm_medium=android_app"  
      }, 
      {
        id: uuidv4(),
        heading: "Surviving SY for Dummies - Feedback ",
        subHeading: "2021-22",
        description:
          "The session was really interactive and it will surely help a lot.",
        eventlink: "https://www.linkedin.com/posts/csicoep_internships-mentor-dummies-activity-6916034603300311040-qTvx?utm_source=linkedin_share&utm_medium=android_app",
      },
      {
        id: uuidv4(),
        heading: "Surviving SY for Dummies - Feedback ",
        subHeading: "2021-22",
        description:
          "Relevant and important details were shared. Hence found it useful.",
        eventlink: "https://www.linkedin.com/posts/csicoep_internships-mentor-dummies-activity-6916034603300311040-qTvx?utm_source=linkedin_share&utm_medium=android_app",
      },
      {
        id: uuidv4(),
        heading: "Surviving SY for Dummies - Feedback ",
        subHeading: "2021-22",
        description:
          "Keep up the good work! ",
        eventlink: "https://www.linkedin.com/posts/csicoep_internships-mentor-dummies-activity-6916034603300311040-qTvx?utm_source=linkedin_share&utm_medium=android_app",
      },
      {
        id: uuidv4(),
        heading: "Surviving SY for Dummies - Feedback ",
        subHeading: "2021-22",
        description:
          "Excellent session",
        eventlink: "https://www.linkedin.com/posts/csicoep_internships-mentor-dummies-activity-6916034603300311040-qTvx?utm_source=linkedin_share&utm_medium=android_app",
      },
    ],
  },
  {
    eventName: "Surviving TY for Dummies",
   
    feedBacks: [
      {
        id: uuidv4(),
        heading: "Surviving TY for Dummies-Feedback ",
        subHeading: "2022",
        description:
          "It was good and helpful. As the things have changed for this academic year, I think what seniors told was enough to get an idea.",
        eventlink:"https://www.linkedin.com/posts/csicoep_internships-placements-mentor-activity-6837050504820858881-3d8z?utm_source=linkedin_share&utm_medium=android_app",
      },
      {
        id: uuidv4(),
        heading:"Surviving TY for Dummies-Feedback ",
        subHeading: "2022",
        description:
          "It was a great session. It was helpful for further academic activities.",
          eventlink:"https://www.linkedin.com/posts/csicoep_internships-placements-mentor-activity-6837050504820858881-3d8z?utm_source=linkedin_share&utm_medium=android_app",
      },
      {
        id: uuidv4(),
        heading: "Surviving TY for Dummies-Feedback ",
        subHeading: "2022",
        description:
          "The was nice, I got my doubts cleared and for sure the points they told will help me.",
          eventlink:"https://www.linkedin.com/posts/csicoep_internships-placements-mentor-activity-6837050504820858881-3d8z?utm_source=linkedin_share&utm_medium=android_app",
      },
      {
        id: uuidv4(),
        heading: "Surviving TY for Dummies-Feedback ",
        subHeading: "2022",
        description:
          "Nice sessions, must continue.",
          eventlink:"https://www.linkedin.com/posts/csicoep_internships-placements-mentor-activity-6837050504820858881-3d8z?utm_source=linkedin_share&utm_medium=android_app",
      },
      {
        id: uuidv4(),
        heading: "Surviving TY for Dummies-Feedback ",
        subHeading: "2022",
        description:
          "As all ways, it is an important and informative session to help us prepare for upcoming Sem.",
         eventlink: "https://www.linkedin.com/posts/csicoep_internships-mentor-dummies-activity-6866752761057288193-mGty?utm_source=linkedin_share&utm_medium=android_app"
      },
      {
        id: uuidv4(),
        heading: "Surviving TY for Dummies-Feedback ",
        subHeading: "2022",
        description:
          "Was Perfect Session, Cleared almost all doubts",
          eventlink: "https://www.linkedin.com/posts/csicoep_internships-mentor-dummies-activity-6866752761057288193-mGty?utm_source=linkedin_share&utm_medium=android_app"
      },
      {
        id: uuidv4(),
        heading: "Surviving TY for Dummies-Feedback ",
        subHeading: "2022",
        description:
          "It was helpful :)",
          eventlink: "https://www.linkedin.com/posts/csicoep_internships-mentor-dummies-activity-6866752761057288193-mGty?utm_source=linkedin_share&utm_medium=android_app"
      },
    ],
  },
];
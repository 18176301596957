import React from "react";

const kaustubh_virkar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Kaustubh Virkar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Hilti Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There were 2 rounds , one technical and one HR.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            It had one coding question and MCQ questions related to OS, CN, DSA, questions related to coding.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 2 rounds. In the technical round the focus mainly was on projects that I had put in the resume. I was asked to explain in brief about the projects and then asked some technical questions based on them.
                        For the 2nd round , it was the HR round, I was asked about my hobbies and what are my expectations from the internship. Both the interviews were of about 30-45 mins
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        There were 20 students selected for the interviews and 12 shortlisted for the 2nd round of interviews and finally 4 were selected for the internship
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        I used the striver sheet for DSA. It covers everything that you will need to prep coding wise.
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Do strivers sheet properly and try to identify overlapping algo or techniques in questions.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            I was asked a lot , almost 80% of my technical interview,  on my projects in the technical interview. The HR round was mostly about extracurricular activities and clubs.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Know your projects wel, through and through. The company judges a lot on personality so do have extra curricular activities and hobbies.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Be confident , conversational skills are the most important. Don’t stress even if you do fumble some questions the outcome is always for the best.

                        </p>
                    </p>
                    <br />



                </div>
            </section >
        </div >
    );
};

export default kaustubh_virkar;

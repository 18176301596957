import React from "react";

const apurva_deshpande = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Apurva Deshpande
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Deutsche Bank
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              Deutsche Bank was the second company to visit COEP to hire for
              both Interns and FTEs. There were 4 rounds in the Internship
              Hiring Process
            </p>
            <ol>
              <li>
                <p>Online Coding Test (Aptitude Test)</p>
              </li>
              <li>
                <p>Technical Interview</p>
              </li>
              <li>
                <p>Professional Fitment Round</p>
              </li>
              <li>
                <p>HR Interview</p>
              </li>
            </ol>
            <p>Each one was an elimination round.</p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              <ol>
                <li>
                  <p>
                    The Online Coding Test was conducted on HackerEarth. It had
                    10 MCQ questions and 2 Coding questions for a duration of
                    120 min.
                  </p>
                </li>
                <li>
                  <p>
                    The MCQ questions were based on Computer fundamentals like –
                    Computer networks, Object oriented programming (OOP) (mainly
                    Java based), Database Management Systems (DBMS), Operating
                    Systems (OS), Computer Networks (CN) and Data Structures and
                    Algorithms (DSA).
                  </p>
                </li>
                <li>
                  <p>
                    The Coding questions were of easy-medium difficulty based on
                    Arrays and Strings.
                  </p>
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              7 students were shortlisted from those who gave the test for the
              further process. In the morning we had a Pre-Placement talk where
              they briefed us about the company. Next the rest interviews for
              both interns and FTEs were scheduled throughout the day.{" "}
            </p>
            <ol>
              <li>
                <strong>Technical Interview Round</strong>
                <p>
                  It lasted for around 40 min. I was first asked to introduce
                  myself, some interesting projects I have worked upon. Then I
                  was asked questions on OOP (Object Oriented Programming),
                  Computer Networks and Operating Systems (Basics). Then he
                  asked Coding questions, for which I had to code the solutions
                  by sharing my screen. Some questions were
                </p>
                <p>
                  <ol>
                    <li>
                      <p>What is polymorphism and its types?</p>
                    </li>
                    <li>
                      <p>What is multithreading?</p>
                    </li>
                    <li>
                      <p>What are advantages of multithreading?</p>
                    </li>
                    <li>
                      <p>
                        What is the difference between Multithreading and
                        Multiprogramming?
                      </p>
                    </li>
                  </ol>
                </p>
              </li>
              <li>
                <p>
                  <strong>Coding questions: </strong>
                  <ol>
                    <li>
                      Sort an array having 0,1,2 only (Explain all different
                      approaches)
                    </li>
                    <li>
                      Given an input file – having data as account number,
                      currency, balance of different customers. Process the
                      contents of the file and save total balance in the account
                      of each customer in a different output file (Write the
                      pseudocode for it)
                    </li>
                    <li>
                      Array has numbers from 1 to N, with 1 missing number. Find
                      the missing number. (All approaches){" "}
                    </li>
                  </ol>
                </p>
              </li>
              <li>
                <p>
                  <strong>Professinal Fitness Round: </strong>
                </p>
                <p>
                  It lasted for 30 min. 5 students were shortlisted to give this
                  round. In this the interviewer asked me about my projects, and
                  skills and went in depth of 1-2 projects. As I had a project
                  on Grep Command Implementation (which is used to search
                  patterns in files), the interviewer asked me the algorithm
                  that Google is using to search for information in its large
                  database. As it was an open-ended question, I told all ideas
                  or approaches I could think of. I was asked to describe the
                  different steps that might be used for data pre-processing,
                  and searching. Sometimes the interviewer also gave some hints,
                  which eventually helped me reach the final solution, and he
                  was also satisfied with the approach at the end.{" "}
                </p>
              </li>
              <li>
                <strong>HR Round: </strong>
                <p>
                  It lasted for 20 min. In this, I was asked about some general
                  HR questions – tell me about yourself, why do you want to join
                  Deutsche Bank, strengths and weaknesses, interests and
                  hobbies. Then he asked me about my most interesting project
                  and its impact on different users, difficulties faced in the
                  project and how I overcame/solved them.{" "}
                </p>
                <p>
                  After these rounds, we got the results at around 8.30pm, and 3
                  students were selected for the final internship offer.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <p>
              I was asked 3-4 questions on projects I had done, and skills,
              courses mentioned in the resume.{" "}
            </p>
            <br />
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              Firstly, I revised basics of DSA and standard problems through
              GeeksForGeeks (GFG). For practicing, I used Hackerrank Interview
              Preparation Kit and Love Babbar’s Final 450 questions (Link -{" "}
              <a href="https://youtu.be/4iFALQ1ACdA">
                Love_Babbar
              </a>{" "}
              ). It is a comprehensive sheet and I wasn’t able to complete all
              the questions, and hence, towards the end I practised some must do
              interview questions on GFG (Link -{" "}
              <a href="https://www.geeksforgeeks.org/must-coding-questions-company-wise/">
                Geeks For Geeks
              </a>{" "}
              )
            </p>
            <p>
              For aptitude I referred GFG for theory & formulae and Indiabix for
              practicing questions & mock tests.{" "}
            </p>
            <p>
              For CS fundamental subjects like – OOP, CN, DBMS and OS, I
              referred notes from Apna College YouTube channel (Link -{" "}
              <a href="https://youtube.com/playlist?list=PLfqMhTWNBTe1wRkDkQ0m1lJ7KDpTNRuvj">
                Apna_College
              </a>
              ) and GFG.{" "}
            </p>
            <p>
              Also, before actual interview, I went through interview
              experiences on CSI COEP website and GFG, to get an idea of type of
              questions which could be asked.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              To solve coding questions, I would suggest to practice competitive
              programming on any 1-2 platforms and solving questions in a timed
              manner. I practised on Hackerrank and GFG. In online tests, first
              try to come up with a brute force solution to pass some test
              cases, then think to optimize it by applying some known techniques
              or finding a pattern/formula in the question.
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            In the interviews, try to tell the interviewer whatever you are
            thinking and what is your approach to solve the question, starting
            from the brute force solution, till the optimised one. Also, do ask
            questions to the interviewer at the end of the interview, this shows
            your interest towards working in the company. In each of the rounds,
            I had asked some questions like – what tech stack is used, work
            culture of the company, etc. at the end.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:deshpandeav19.comp@coep.ac.in">
              deshpandeav19.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/avd151/">LinkedIn Handle</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default apurva_deshpande;

import React from "react";

const gaurav_wawdhane = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Gaurav Wawdhane
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Barclays</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            Aptitude Test of 1 hour 30 minutes on HackerEarth on 06 Nov 2020.
            One interview round (Technical and HR) on 10 Nov 2020.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test was conducted on HackerEarth. It had 30 MCQs and 2
            programming questions. The MCQs were technical and were mostly
            related to the OOPS concept and DBMS. There were few MCQs related to
            Cloud Computing and output prediction. Most of the questions related
            to Cloud Computing and DBMS were theoretical questions.
            <br />
            There were 2 coding questions which were of 50 and 20 marks
            respectively. The second coding question worth 20 marks was quite
            easy and it took around 5 minutes. I don’t remember the exact coding
            questions but they were related to the array manipulation and
            graphs.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            There was only one interview which is a mixure of technical as well
            as HR interview. Technicals questions were related to the Data
            Structure and DBMS. However, most of the technical questions were
            based on the technologies which I have used in my project. I was
            asked to describe myself a little, to which I gave a brief intro
            about myself.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            There were many questions related to the projects. They asked me
            about the challenges I faced in my projects. They asked many
            questions on API as one of my project contains API calls. The
            technical questions were mostly on the projects.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />I referred R.S. Aggarwal for general aptitude questions.
            GeeksForGeeks and CodeChef for coding questions.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b>
            <br />
            Try to understand the question first and then start coding. C++ or
            Python is good for solving coding questions as it has many libraries
            helpful for solving the coding questions. Practice a lot of coding
            questions. This will help you to analyze yourself about your coding
            knowledge.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:wawdhanegv18.comp@coep.ac.in">
              wawdhanegv18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default gaurav_wawdhane;

import React from "react";

const rohit_shidid = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Rohit Shidid</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}> FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              First, there was an aptitude round which was on their platform,
              then was resume shortlisting, after that was the written test (In
              which there were three questions related to business, finance and
              marketing), moving on was the Group discussion session, right
              after the GD was technical and HR Round.
            </p>
            <b>Structure of Aptitude Round: </b>
            <br />
            There were a few coding questions, some finance-related questions,
            also some general knowledge questions. The test covered vast content
            and various areas of interests
          </p>

          <p>
            <b>2. How many students were shortlisted after each round?</b>
            <p>
              In first <b>40</b> were shortlisted for GD and written test,{" "}
              <b>then at the end,14 students were selected</b>
            </p>
          </p>

          <p>
            <b>
              3. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              Use Indiabix for aptitude, study DSA and OOP and keep your basics
              clear.
            </p>
          </p>
          <br />
        </div>
      </section>
    </div>
  );
};

export default rohit_shidid;

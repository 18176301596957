import React from "react";

const sehajdeep_singh = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Sehajdeep Singh
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}> BNY Mellon</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>The interview process was divided into 2 parts.</p>
            <ol>
              <li>
                The first round was the Technical Round and the second round was
                the HR round.
              </li>
              <li>
                After the HR round, the finalized shortlist for students was
                announced.
              </li>
            </ol>

            <b>Structure of Aptitude Round :</b>
            <br />
            <ol>
              <li>The aptitude round was purely coding based.</li>
              <li>
                It was conducted online on Hackerrank. The duration was 90
                minutes.
              </li>
              <li>
                There were 4 coding questions, one easy, two medium and one
                hard.
              </li>
              <li>You are allowed to switch between the questions.</li>
              <li>Each coding question had a total of 15 test cases.</li>
            </ol>
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <p>There were 3 rounds:</p>
            <ol>
              <li>
                Aptitude Round - This round was coding based. Students from
                Computer, Electronics, Electrical and Mechanical were allowed to
                give this test. There was a minimum CGPA criteria of 7 to give
                this test. Around 300 students appeared for this test. Everyone
                had different set of questions. I had questions based on arrays,
                strings and dynamic programming.
              </li>
              <li>
                Technical Round - Based on aptitude round results, students were
                shortlisted for the technical interview. In the technical round,
                I was asked questions related to my resume and coding skills.
                First, I was asked to introduce myself. Following that I was
                asked to explain the projects mentioned in the resume and the
                difficulties I faced in my projects. Then, I was asked questions
                on OOPs(What is encapsulation ? What is operator overloading ?).
                After this I was given 4-5 coding questions to solve which were
                : Write the pseudo code of QuickSort. Given an unsorted array,
                dry run bubble sort. Swap 2 numbers without using an extra
                variable. Given an array of size ‘N’ where each element is
                unique and each element is i greater than 0 and less than N,
                find the missing element:
                <br />
                <a href="https://www.geeksforgeeks.org/find-the-missing-number/">
                  Link to the Problem
                </a>
                <br />
                Write a recursive program for the fibonacci series. Then I was
                asked to find an error in a given code.The interviewer was very
                friendly and even gave me hints if I got stuck in a question.
                This round lasted about 45-50 minutes for me.
              </li>
              <li>
                HR Round - Following the technical round most of the students
                were selected for the HR round. The interviewer went through my
                resume thoroughly and even saw my sheets that I used for rough
                work during the technical round. I was asked only one technical
                question, he asked me to convert an iterative program to a
                recursive one. Then, he asked me what I knew about the company.
                He also asked what was my favorite part about being a
                programmer. I was asked to tell that, aside from the college
                curriculum, what other skills (both technical and
                non-technicals) have I learned. I was also asked to mention any
                other certifications I had on any of the coding platforms
                (Hackerrank, Leetcode, Codechef, GeeksforGeeks etc.). He also
                asked me about other positions of responsibilities mentioned in
                my resume. The interviewer was very kind and this round was more
                like a discussion. The interview lasted about 40 minutes.
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              Approximately <b>300</b> students appeared for the aptitude test.
              Out of the 300 only <b>18</b> were shortlisted for the technical
              round. Following the technical round, <b> 15 </b>were shortlisted
              for the HR round.{" "}
              <b>Finally, 14 students received the final offer.</b>
            </p>
          </p>

          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              <ol>
                <li>
                  IndiaBix is one of the best websites for general aptitude.
                </li>
                <li>
                  For core CS subjects like Object Oriented Programming,
                  Computer Networks, Operating Systems, Database Management
                  Systems and System Design you can refer to GeeksforGeeks.
                </li>
                <li>
                  If you are planning to do DSA in C++ you can refer to{" "}
                  <a href="https://www.youtube.com/playlist?list=PLDzeHZWIZsTryvtXdMr6rPh4IDexB5NIA">
                    Placement Series of CodeHelp - by Love Babbar
                  </a>
                  <br />
                  To practice problems, you can start from Hackerrank as a
                  beginner and later you can move onto platforms like
                  GeekforGeeks, Leetcode and Codechef. Please practice the 450
                  questions sheet by Love Babbar (
                  <a href="https://450dsa.com/">450 DSA Cracker</a> ). Just try
                  to solve as many problems as you can.
                </li>
              </ol>
            </p>
          </p>
          <p>
            <strong>5. Some tips to crack coding questions :</strong>
            <p>
              <ol>
                <li>
                  For coding questions first read the problem carefully at least
                  twice. Don’t panic if the description of the problem is too
                  lengthy.
                </li>
                <li>
                  After understanding the problem, try to dry run your approach
                  using pen and paper.
                </li>
                <li>
                  First, try to submit the brute force approach, then try to
                  optimize your code if you can't clear the majority of the test
                  cases.
                </li>
                <li>
                  If you can’t think of a solution at all, then you can hardcode
                  some sample and corner test cases. But this should be your
                  last resort.
                </li>
              </ol>
            </p>
          </p>
          <p>
            <strong>
              6. Is there something specific that you would want to tell people
              who try for the same company :
            </strong>
            <p>
              Incase of BNY Mellon, cracking the aptitude round was tougher. If
              you are able to crack the aptitude round, you can easily crack the
              interviews. Make sure that you are calm and confident during the
              interviews. If you have a doubt in any question they have asked,
              make sure that it is cleared before you proceed. If they ask you a
              coding question, explain your approach to the interviewer so that
              they can help you if you get stuck or you’re going on the wrong
              path.
            </p>
          </p>
          <p>
            <strong>7. Final Tip for Juniors :</strong>
            <p>
              Before appearing for a company, you can ask your seniors about the
              general flow of the process. This helps you prepare accordingly.
              Make sure you attend the pre-placement talks for the company you
              are appearing for, as they might ask you questions regarding the
              company. Mention only those skills on your resume that you are
              actually good at. Finally, don’t be demotivated if you are not
              selected for the first few companies. All the best !!
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:sehajdeeps20.comp@coep.ac.in">
              sehajdeeps20.comp@coep.ac.in
            </a>
            <br />
            LinkedIn Handle:
            <a href="https://www.linkedin.com/in/sehajdeep1814/">
              Sehajdeep Singh
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default sehajdeep_singh;

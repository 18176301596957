import coordinator from "../../img/team/2019/coordinator.jpg";
import creativeshead from "../../img/team/2019/creativeshead.jpg";
import creativeshead2 from "../../img/team/2019/creativeshead2.jpg";
import eohead from "../../img/team/2019/e&ohead.jpg";
import eohead2 from "../../img/team/2019/e&ohead2.jpg";
import financehead from "../../img/team/2019/financehead.jpg";
import financehead2 from "../../img/team/2019/financehead2.jpg";
import hod from "../../img/team/2019/hod.jpg";
import president from "../../img/team/2019/president.jpg";
import techlead from "../../img/team/2019/techlead.jpg";
import techlead2 from "../../img/team/2019/techlead2.jpg";
import vicepresident from "../../img/team/2019/vicepresident.jpg";

const team2019 = [
  {
    post: [
      {
        initial: "Dr. (Mrs)",
        name: "Vahida",
        surname: "Attar",
        desc:
          "Head, Department of Computer Engineering and Information Technology",
        image: hod,
        mail: "mailto:hod.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/vahidacompcoepacin",
      },
      {
        initial: "Mr.",
        name: "Amit",
        surname: "Joshi",
        desc: "CSI COEP Student Chapter Coordinator",
        image: coordinator,
        mail: "mailto:adj.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/adjcompcoepacin",
      },
    ],
  },
  {
    post: [
      {
        name: "Naman",
        surname: "Modi",
        position: "President",
        image: president,
        linkedin: "https://www.linkedin.com/in/naman-modi/",
        github: "https://github.com/naman-modi",
      },
      {
        name: "Mayank",
        surname: "Jain",
        position: "Vice President",
        image: vicepresident,
        linkedin: "https://www.linkedin.com/in/mayank-v-jain/",
        github: "https://github.com/mayank-02",
      },
    ],
  },
  {
    post: [
      {
        name: "Ved",
        surname: "Dandekar",
        position: "Technical Head",
        image: techlead,
        linkedin: "https://www.linkedin.com/in/ved-dandekar-91667a150/",
        github: "https://github.com/veddandekar",
      },
      {
        name: "Naishadh",
        surname: "Vora",
        position: "Technical Lead",
        image: techlead2,
        linkedin: "https://www.linkedin.com/in/naishadhvora/",
        github: "https://github.com/naishadh14",
      },
      {
        name: "Aayush",
        surname: "Shah",
        position: "Finance Lead",
        image: financehead,
        linkedin: "https://www.linkedin.com/in/shahaayush1999/",
        github: "https://github.com/shahaayush1999",
      },
      {
        name: "Eesha",
        surname: "Kurode",
        position: "Finance Head",
        image: financehead2,
        linkedin: "https://www.linkedin.com/in/eesha-kurode/",
        github: "https://github.com/eesha-k",
      },
      {
        name: "Umang",
        surname: "Sakhare",
        position: "Events & Operations Head",
        image: eohead2,
        linkedin: "https://www.linkedin.com/in/umang-sakhare-a9a30b173/",
        github: "https://github.com/umang-sakhare17",
      },
      {
        name: "Ankita",
        surname: "Vaid",
        position: "Events & Operations Head",
        image: eohead,
        linkedin: "https://www.linkedin.com/in/ankita-vaid-a73156177/",
        github: "https://github.com/ANKI1905",
      },
      {
        name: "Falguni",
        surname: "Patil",
        position: "Creatives Head",
        image: creativeshead,
        linkedin: "https://www.linkedin.com/in/falguni-patil-860930174/",
        github: "https://github.com/Falguni99",
      },
      {
        name: "Yash",
        surname: "Gangwar",
        position: "Creatives Head",
        image: creativeshead2,
        linkedin: "https://www.linkedin.com/in/yashgangwar/",
        github: "https://github.com/YashGangwar",
      },
    ],
  },
];

export default team2019;

import React from "react";

const omkar_nagwekar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Omkar Nagwekar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            1 interview round consisting of technical and hr questions
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Aptitude round had english finance and certain coding questions
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Due to time constraint the interview consisted of only 1 round in which i was asked about my projects in detail and that took almost 30 minutes and in the next 10 minutes i was asked a DSA question.


                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        10 students were shortlisted after the first(final) round
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Basics of coding should be strong.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            I had 2 projects so a lot pf questions were asked on project and 1 question was asked about the club i was in.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Make sure you have good communication skills and your basics of coding are strong
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Don't lose hopes. Keep trying. Make sure that in an interview you dont say anything that you have never studied or have studied partially. Go with your strongest points. Cover everything from your resume. Go with the flow and all the best!!!
                        </p>
                    </p>
                    <br />
                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:nagwekaros21.comp@coeptech.ac.in">
                            nagwekaros21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>



                </div>
            </section >
        </div >
    );
};

export default omkar_nagwekar;

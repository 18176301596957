import React from "react";

const sandesh_sahane = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Sandesh Sahane
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Pattern Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Aptitude Round (Everyone above 7 CGPA was allowed)
                                </li>
                                <li>
                                    Pre-placement talk
                                </li>
                                <li>
                                    Interview (20 students were selected for interview)
                                </li>
                                <li>
                                    Technical Round (30-40 mins)
                                </li>
                                <li>
                                    HR Round (10–15 mins)
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Aptitude round had questions of math, logical reasoning, and coding questions.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        First there was an aptitude test. Then interviews were at the pattern office at KP. First, there were technical rounds. Probably students whose first technical round was not so good had to give one more. Technical rounds last for 30 min. In technical rounds, they asked about SQl, DBMS, DSA, and OOPS and most of the questions were based on resume-like skills mentioned and the projects mentioned. And then HR rounds with some common questions like why you choose a pattern or where you see in 5 years…
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After the aptitude test 20 were shortlisted for technical interviews. Then around 10 were asked to give an HR interview out of which 7 were finally selected.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        For DSA I used to solve POTD of GFG and also solved Striver’s SDE sheet. For core subjects, I read LMN of GFG of subjects like DBMS, CN, and OS. For practice of aptitude questions, I used to solve questions from indiabix.


                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Most coding questions are of graph, DP, trees, and binary search. For that, I watched the video playlist of Striver for DP and graph and for the rest of the DSA, I watched Love Babbar’s playlist. The playlist is very big if you don't have time then you can watch Striver’s playlist too. They are also good.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            They mainly focused on projects only like why you chose this data structure or this algorithm. What were the alternatives? For me, they asked questions regarding extracurricular activities as in my resume
                            I mentioned that I was the web and android head of Zest team. Then they asked me about my role as a head and what activities I had done there.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Keep coding knowledge very well and be ready with multiple algorithms if any coding question is asked in the interview. They mostly hire for development from our college so it's better if you know about development(frontend/backend/full stack), especially in MERN and ruby.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            DSA, OOPS, and DBMS are very important topics that will be asked in every company from starting(aptitude test) to ending (technical rounds). Practice DSA problems every day. You can’t learn DSA in 1 week like other subjects so start practicing/learning at least 2 months before as there are many topics to be covered. Especially questions on DP and graphs are asked very frequently. Have in-depth knowledge of projects and be ready for any question on the project from alternative approaches and some interviewers might ask you to write some code. After all, it depends on in which area does interviewer has expertise the company is hiring for development but the interviewer knows OS so he will ask more questions on that topic so be ready for anything. Do at least LMN from GFG for OS and CN. And for DSA striver’s SDE sheet is sufficient, you will get a clear idea about the questions asked.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:sahaness21.comp@coeptech.ac.in">
                            sahaness21.comp@coeptech.ac.in
                        </a>
                        <br />

                        LinkedIn : <a href="https://www.linkedin.com/in/sandesh-sahane-7955b5249/">Sandesh Sahane</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default sandesh_sahane;

import React from "react";

const yash_chandak = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Yash Umesh Chandak
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Barclays</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. How many interview rounds were there for the company?</b><br />
            <p> In all there were <b>two</b> rounds. 1st the aptitude round and second the online
              interview round
            </p>
            <ol>
              <li>
                <b>Aptitude round : </b> The test was conducted on Hackerearth. Duration of the
                test was 1.5 hrs. The test consisted of MCQ’s, mostly of predict the output
                of given code snippet type (Java) and some were based on theory of OS
                and DBMS. There were 2 coding questions of moderate difficulty.
              </li>
              <li>
                <b>Online interview round : </b> There was only one online interview round of
                about 30 minutes. I was asked to describe my resume. Then the
                interviewer asked me questions based on my projects. In my case he
                asked a lot on DBMS and SQL queries as I mentioned MySQL in my
                resume. Then he asked me some fundamental questions on Python
                programming like slicing of strings, tuple and dictionary, etc. And finally
                some simple questions on data structures. My interview was completely
                technical. No questions were asked on extra curricular activities or clubs or
                other things.

              </li>
            </ol>
          </p>

          <p>
            <b>
              2. How many students were shortlisted after each round?
            </b>
            <br />
            <p>
              27 students after aptitude and 11 after interview were shortlisted.
            </p>
          </p>

          <p>
            <b>
              3. Some good resources you used in your internship prep
            </b>
            <br />
            <p>
              GFG for coding. Youtube, GFG, etc for concepts. College placement notes by
              Apna college and Love babbar.

            </p>
          </p>

          <p>
            <b>
              4. Some tips to crack coding questions
            </b>
            <br />

            <p>
              GFG for coding. Youtube, GFG, etc for concepts. College placement notes by
              Apna college and Love babbar.
            </p>
          </p>

          <br />
          <p>
            <strong>
              5. Final Tip for Juniors?
            </strong>
          </p>
          <p>
            Keep all things from linkedin to github uptodate. Many
            things other than knowledge are being tested in an interview, present yourself
            properly. At times you may feel frustrated. Just keep on improving yourself. Best
            wishes for your interviews.
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            
            <br />
            LinkedIn Handle : <a href="https://www.linkedin.com/in/yashuchandak/">Yash Chandak</a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default yash_chandak;

import React from "react";

const jash_dalal = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Jash Dalal</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Schlumberger{" "}
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />A total of 1 aptitude test and 4 interview rounds
            <li style={{ marginLeft: "2rem" }}>
              Aptitude test - Java Questions were hard given the short time span
            </li>
            <li style={{ marginLeft: "2rem" }}>
              Coding questions were moderate and solvable
            </li>
            <li style={{ marginLeft: "2rem" }}>
              Interview - Every interview was an elimination round
            </li>
            <li style={{ marginLeft: "2rem" }}>
              Interviewers were very kind and gave hints whenever you got stuck
            </li>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            It was divided into 3 sections. The aptitude test was on{" "}
            <a href="https://www.firstnaukri.com/">www.firstnaukri.com</a>
            <br />
            1. Java Based Questions on
            <li style={{ marginLeft: "2rem" }}>OOPs</li>
            <li style={{ marginLeft: "2rem" }}>Private, Protected Classes</li>
            <li style={{ marginLeft: "2rem" }}>Exceptions</li>
            <li style={{ marginLeft: "2rem" }}>Threads</li>
            <li style={{ marginLeft: "2rem" }}>
              Hash Map, Hash Set, Sorted Map
            </li>
            2. Snippet Output (All snippets were in Java)
            <br />
            3. 2 coding questions
            <li style={{ marginLeft: "2rem" }}>Easy – String Manipulation</li>
            <li style={{ marginLeft: "2rem" }}>
              Medium – Sorting(Application of Quick Sort)
            </li>
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            A total of 25 students gave the aptitude test. 11 were shortlisted
            for interviews.
            <br />
            There were 4 rounds Each interview round duration was 45-50 min
            <br />
            1. Tech 1
            <br />
            2. Tech 2
            <br />
            3. Managerial
            <br />
            4. HR
            <br />
            <br />
            <i>Tech 1 - Sorting Algorithm</i>
            <br /> Asked me to write Quick Sort, Analysis of Best Case and worst
            case time complexity, Tell me something in tech you know but I may
            not know – I told him about Tim Sort used in sort() in Python,
            Searching Techniques
            <br />
            <br />
            <i>
              Tech 2 - HTTP, Restful Web API(GET, POST, DELETE, PUT), Database
              Indexing, Normalization
            </i>
            <br /> The interviewer makes sure that you know whatever you’ve
            worked on. If you don’t you are rejected straight away. Many
            resume-based questions also were asked. He asked about my
            certifications and college projects in detail. He was very kind and
            we had a short chitchat about Pune in the end.
            <br />
            <br />
            <i>
              Managerial - Future Goals, Expectations, More Based on the
              Candidate’s Thoughts
            </i>
            <br />
            <li style={{ marginLeft: "2rem" }}>
              Why Schlumberger over Google, Amazon or Microsoft?
            </li>
            <li style={{ marginLeft: "2rem" }}>
              Do you want to study further?
            </li>
            <li style={{ marginLeft: "2rem" }}>
              {" "}
              How do you see yourself in 5 years?
            </li>
            <li style={{ marginLeft: "2rem" }}>
              Will you continue in Schlumberger if you are getting an offer
              elsewhere?
            </li>
            <br />
            <i>HR - Behavioral Round</i> <br />
            <li style={{ marginLeft: "2rem" }}>
              What are the best qualities that you have?
            </li>
            <li style={{ marginLeft: "2rem" }}>
              Was there a point in life when you failed? If so, how did you
              stand back up?
            </li>
            <li style={{ marginLeft: "2rem" }}>
              What are some of the principles you follow that make you who you
              are today?
            </li>
            <li style={{ marginLeft: "2rem" }}>
              Hobbies, Family Background and Interests
            </li>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>
            <br />
            Many questions were asked about my resume especially in Tech 2.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            i. Geeks for Geeks – Very Important Resource (Must Do) <br />
            <li style={{ marginLeft: "2rem" }}>
              <a href="https://www.geeksforgeeks.org/company-preparation/">
                Company Preparation
              </a>
            </li>
            <li style={{ marginLeft: "2rem" }}>
              <a href="https://www.geeksforgeeks.org/commonly-asked-data-structure-interview-questions-set-1/">
                Commonly Asked Data Structure Interview Questions
              </a>
            </li>
            <li style={{ marginLeft: "2rem" }}>
              <a href="https://www.geeksforgeeks.org/commonly-asked-operating-systems-interview-questions-set-1/">
                Commonly Asked Operating Systems Interview Questions
              </a>
            </li>
            ii. For aptitude questions{" "}
            <a href="https://www.geeksforgeeks.org/placements-gq/">
              Placements GQ
            </a>
            <br />
            iii. Cracking the Coding Interview
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Try to select one language and be comfortable with all libraries
            which it has. A must do for all companies is – DP, Greedy, Graph,
            Tree and String Manipulation Questions. You can practice coding
            questions on hackerrank, hackerearth and leetcode – but be sure to
            try to pass all test cases!
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            1. Be thorough with your resume
            <br />
            2. Make sure you have your basics clear
            <br />
            3. Give evidences of qualities you have in the HR Round
            <br />
          </p>

          <p>
            <i>
              This experience may not be completely accurate as I have described
              my interview experience as I remember today. My overall experience
              was great. The interview process was very smoothly carried out and
              it was all wrapped up in 9.30 – 3.30pm.
            </i>
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:jashmj17.it@coep.ac.in">jashmj17.it@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default jash_dalal;

import React from "react";

const dipak_ambhure = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Dipak Ambhure
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Oracle</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />
            <ol>
              <li>
                Online assessment held on 3rd sept 2022.
              </li>
              <li>
                Online Pre Placement Talk (PPT) followed by 2 Technical rounds and 1
                Managerial round held on 7th sept 2022
              </li>
              <li>
                Every round was elimination round..
              </li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>Apptitude test was conducted on hackerrank platform for duration of 105
              minutes, with total of 9 questions of the following pattern:
            </p>
            <p style={{ marginLeft: "20px" }}>
              Q1.  DSA question on array and sorting <br />
              Q2. Rest API question similar questions could be found on hackerrank platform <br />
              Q3. SQL query, given a company table containing names of employees and
              amounts in string format with $ symbol at start, we need to print name of the
              employees with simple interest at rate of 5 p.c.p.a <br />
              Q4 to Q9. were MCQ based on B-tree, stack, queue, time complexity, etc.
            </p>
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            There were 3 rounds
            <ol>
              <li>
                Techinal Round I (around 40 min)
                <ul style={{ listStyle: "disc" }}>
                  <li>
                    After introduction interviewer asked about my internship what I have
                    done in my internship and had a bried discussion about the
                    functionalites I have added over there.
                  </li>
                  <li>
                    DSA question:
                    <ul style={{ listStyle: "circle", paddingLeft:"40px " }}>
                      <li>
                        Sorting which sort would you prefer in certain scenarios
                      </li>
                      <li>
                        Time complexity of merge sort and quick sort how it comes
                      </li>
                      <li>
                        Can you sort using binary tree
                      </li>
                      <li>
                        Postfix , prefix and traversals of tree
                      </li>
                      <li>
                        Binary search
                      </li>
                    </ul>
                  </li>
                  <li>
                    Operating System:
                    <ul style={{ listStyle: "circle", paddingLeft:"40px " }}>
                      <li>
                        Scheduling algorithms, Starvation, Thrashing
                      </li>
                      <li>
                        Fage fault, fragmentation
                      </li>
                      <li>
                        Critical section problem, mutex
                      </li>
                    </ul>
                  </li>
                  <li>
                    Coding Question:
                    <ul style={{ listStyle: "circle", paddingLeft:"40px " }}>
                      <li>
                        Given a MxN matrix of 0’s and 1’s we have to count number of
                        peninsula formed, peninsula is 2x2 matrix having exact three
                        0s and one 1. Analogy of water and land was given to this
                        question where 0 represents water and 1 represents land
                      </li>
                    </ul>
                    <li>
                      Object Oriented Programming:
                      <ul style={{ listStyle: "circle", paddingLeft:"40px " }}>
                        <li>
                          Pillars of oop
                        </li>
                        <li>
                          Types of inheritance , polymorphism
                        </li>
                        <li>
                          What is encapsulation
                        </li>
                        <li>
                          Friend function, virtual function, interface, abstract class
                        </li>
                      </ul>
                      <li>
                        No questions on:
                        <ul style={{ listStyle: "circle", paddingLeft:"40px " }}>
                          <li>
                            Resume
                          </li>
                          <li>
                            Extracarricular activities
                          </li>
                          <li>
                            Projects
                          </li>
                        </ul>
                      </li>
                    </li>
                  </li>
                </ul>
              </li>
              <li>
                Technical Round II (around 40 min)
                <ul style={{ listStyle: "disc" }}>
                  <li>
                    Internship
                    <ul style={{ listStyle: "circle", paddingLeft:"40px " }}>
                      <li>
                        Some cross questions on internship like why postgreSQL over
                        mySQL, how to handle large data, why this is more secure etc.
                      </li>
                      <li>
                        Challenges faced during internship
                      </li>
                    </ul>
                  </li>
                  <li>
                    Coding
                    <ul style={{ listStyle: "disc" , paddingLeft:"40px "}}>
                      <li>
                        Implementation of singly linked list, reverse, rearrange
                      </li>
                      <li>
                        Questions on bit-manipulation count set bits, Is the given number
                        power of 2 or not , set nth bit of given number, XOR etc.
                      </li>
                      <li>
                        Show polymorphism : static, dynamic
                      </li>
                      <li>
                        SQL query similar to nth highest salary question
                      </li>
                    </ul>
                  </li>
                  <li>
                    No Questions on:
                    <ul style={{ listStyle: "circle", paddingLeft:"40px " }}>
                      <li>
                        Resume
                      </li>
                      <li>
                        Extracarricular activities
                      </li>
                      <li>
                        Projects
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Managerial Round
                <ul style={{ listStyle: "disc",paddingLeft:"40px" }}>
                  <li>
                    What are you doing now?
                  </li>
                  <li>
                    What you have done in your college life?
                  </li>
                  <li>
                    How was lockdown to you?
                  </li>
                  <li>
                    Tell me about your projects mentioned on resume
                  </li>
                  <li>
                    This round was completely based on what I was telling to interviewer
                    for every answer there was cross question so think before speaking
                    anything
                  </li>
                  <li>
                    Difference between windows and linux in developers perspective
                    what do you think?
                  </li>
                  <li>
                    We had brief discussion on what happen when we write printf(“hello
                    world”);
                  </li>
                  <li>
                    How gcc works, preprocessing ,compilation, linking, loading,
                    executable, file formats in between compilation
                  </li>
                  <li>
                    What is work of assembler, high level language, machien level
                    language
                  </li>
                  <li>
                    What is ment by platform dependent , difference between python
                    and cpp, cpp and java, what is JVM
                  </li>
                  <li>
                    Do you have any questions?
                  </li>
                </ul>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            All E-group branches students having 7+ CGPA was eligible for the company
            <ul style={{ listStyle: "disc" , paddingLeft:"40px " }}> 
            <li>51 were shortlisted for technical interviews</li> 
              <ol>
                <li>
                  23 for FTE+intern (GBU)
                </li>
                <li>
                  28 for FTE only (PDO)
                </li>
              </ol>
              <li>
                Don’t know the exact count for next rounds because complete interview
                process was a single zoom call just we have to switch from different rooms
                based on instructions given by interviewers.
              </li>
            </ul>
          </p>

          <p>
            <b>
              5. Some good resources you used in your Placement prep
            </b>{" "}
            <br />
            <ul style={{ listStyle: "disc", paddingLeft:"40px" }}>
              <li>geeksforgeeks</li>
              <li>interviewBit</li>
              <li>Love babbar 450 DSA questions</li>
              <li>Love babbar road maps for OOP, OS, DBMS</li>
              <li>Javatpoint, tutorialspoint, study tonight, apni kaksha notes</li>
            </ul>
          </p>
          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Practice, consistency, stuck to one or two platforms and solve as much
            questions as you can.
            <br />
          </p>

          <p>
            <b>
              7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume?
            </b>
            <br />
            <p>In Managerial round there were few questions related to projects and
              extracurricular activities apart from this no questions asked in any other round
            </p>
          </p>
          <p>
            <b>
              8. Is there something specific that you would want to tell people who try for the
              same company?
            </b>
            <br />
            <p>Make the CS fundamentals strong, if you have done something then be
              ready with the answer why and how you have done that. Decide beforehand
              what to say and what not to say for common questions on your projects and
              internship
            </p>
          </p>
          <p>
            <b>
              9.Final Tip for Juniors
            </b>
            <p>
              Don’t worry if your cs fundamentals are strong and you can make code
              work you will get placed. Believe in yourself, don’t stop practice
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            Email ID : <a href="mailto:ambhuredp19.comp@coep.ac.in">
              ambhuredp19.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default dipak_ambhure;

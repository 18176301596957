import React, { Component } from "react";
import CountUp from "react-countup";
import Fade from "react-reveal/Fade";
import "../pages/css/counter.css";
import { TiGroup } from "react-icons/ti";
import {GoGraph} from "react-icons/go"
import { FaBriefcase } from "react-icons/fa";
import { SiGooglescholar } from "react-icons/si";
import VisibilitySensor from "react-visibility-sensor";


class Counter extends Component {
  state = {
    didViewCountUp: false,
  };

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };

  render() {
    return (
      <div className="counter_wrapper">
        <Fade bottom>
          <div className="container">
            <div className="small_screen_wrapper">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <div className="count_box box_hover">
                    <SiGooglescholar
                      style={{
                        fontSize: "3rem",
                        color: "white",
                        margin: "1rem",
                      }}
                    />
                    <h3>
                      <CountUp start={0} end={97} duration={7} delay={2} />
                    </h3>
                    <h4>Internship and Placement Experiences</h4>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="count_box box_hover">
                    <TiGroup
                      style={{
                        fontSize: "3rem",
                        color: "white",
                        margin: "1rem",
                      }}
                    />
                    <h3>
                      <CountUp delay={4} end={300} duration={6} />+
                    </h3>
                    <h4>Members</h4>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="count_box box_hover">
                    <SiGooglescholar
                      style={{
                        fontSize: "3rem",
                        color: "white",
                        margin: "1rem",
                      }}
                    />
                    <h3>
                      <CountUp delay={3} start={0} end={24} duration={8} />
                    </h3>
                    <h4>Events Organized</h4>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="count_box box_hover">
                    <GoGraph
                      style={{
                        fontSize: "3rem",
                        color: "white",
                        margin: "1rem",
                      }}
                    />
                    <h3>
                      <CountUp delay={4} end={741} duration={6} />
                    </h3>
                    <h4>Monthly Page Hits</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="responsive_counter_wrapper">
              <VisibilitySensor
                onChange={this.onVisibilityChange}
                offset={{
                  top: 10,
                }}
              >
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="count_box box_hover">
                      <SiGooglescholar
                        style={{
                          fontSize: "3rem",
                          color: "white",
                          margin: "1rem",
                        }}
                      />
                      <h3>
                        <CountUp
                          start={0}
                          end={this.state.didViewCountUp ? 145 : 0}
                          duration={7}
                        />
                      </h3>
                      <h4>Internship and Placement Experiences</h4>
                    </div>
                  </div>
                 
                  <div className="col-md-6 col-sm-6">
                    <div className="count_box box_hover">
                      <TiGroup
                        style={{
                          fontSize: "3rem",
                          color: "white",
                          margin: "1rem",
                        }}
                      />
                      <h3>
                        <CountUp
                          end={this.state.didViewCountUp ? 300 : 0}
                          duration={6}
                        />
                        +
                      </h3>
                      <h4>Members</h4>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="count_box box_hover">
                      <FaBriefcase
                        style={{
                          fontSize: "3rem",
                          color: "white",
                          margin: "1rem",
                        }}
                      />
                      <h3>
                        <CountUp
                          end={this.state.didViewCountUp ? 24 : 0}
                          start={0}
                          duration={8}
                        />
                      </h3>
                      <h4>Events Organized</h4>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="count_box box_hover">
                      <GoGraph
                        style={{
                          fontSize: "3rem",
                          color: "white",
                          margin: "1rem",
                        }}
                      />
                      <h3>
                        <CountUp
                          end={this.state.didViewCountUp ? 741 : 0}
                          duration={6}
                        />
                        
                      </h3>
                      <h4>Monthly Page Hits</h4>
                    </div>
                  </div>
                </div>
              </VisibilitySensor>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}

export default Counter;

import coordinator from "../../img/team/2019/coordinator.jpg";
import hod from "../../img/team/2019/hod.jpg";
import newhod from "../../img/team/2019/newhod.jpeg";
import president from "../../img/team/2023/Snehasish Bose.jpeg";
import vicePresident from "../../img/team/2023/abhinav kurule.jpeg";
import techLead1 from "../../img/team/2023/techLead.jpg";
import techLead2 from "../../img/team/2023/Himanshu.jpg";
import financeHead1 from "../../img/team/2023/financeHead.jpg";
import financeHead2 from "../../img/team/2023/Gaurish.jpg";
import eventHead1 from "../../img/team/2023/Harshwardhan Deshmukh.jpg";
import eventHead2 from "../../img/team/2023/sanskriti pandey.jpeg";
import creativesHead1 from "../../img/team/2023/creativesHead.jpg";
import creativesHead2 from "../../img/team/2023/dhnanajay.png";

const team2023 = [
  {
    post: [
    //   {
    //     initial: "Dr. (Mrs)",
    //     name: "Vahida",
    //     surname: "Attar",
    //     desc: "Former Head, Department of Computer Engineering and Information Technology",
    //     image: hod,
    //     mail: "mailto:vahida.comp@coep.ac.in",
    //     about: "https://www.coep.org.in/mycoep/vahidacompcoepacin",
    //   },
      {
        initial: "Dr.",
        name: "P. K.",
        surname: "Deshmukh",
        desc: "Head, Department of Computer Science and Engineering",
        image: newhod,
        mail: "mailto:hod.comp@coep.ac.in",
        about: "https://www.coep.org.in/departments/computerit/aboutdep",
      },
      {
        initial: "Dr.",
        name: "Amit",
        surname: "Joshi",
        desc: "CSI COEP Tech Student Chapter Coordinator",
        image: coordinator,
        mail: "mailto:adj.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/adjcompcoepacin",
      },
    ],
  },
  {
    post: [
      {
        name: "Snehasish",
        surname: "Bose",
        position: "President",
        image: president,
        linkedin: "http://www.linkedin.com/in/snehasish-bose",
        github: "https://github.com/Snehasish09",
      },
      {
        name: "Abhinav",
        surname: "Kurule",
        position: "Vice President",
        image: vicePresident,
        linkedin: "https://www.linkedin.com/in/abhinav-kurule-5339b3252/",
        github: "https://github.com/abhi-1003",
      },
    ],
  },
  {
    post: [
      {
        name: "Atharva",
        surname: "Mutsaddi",
        position: "Technical Head",
        image: techLead1,
        linkedin:
          "https://www.linkedin.com/in/atharva-mutsaddi-a59902228/",
        github: "https://github.com/AtharvaMutsaddi",
      },
      {
        name: "Himanshu",
        surname: "Kamdi",
        position: "Technical Head",
        image: techLead2,
        linkedin: "https://www.linkedin.com/in/himanshu-kamdi-757b59290/",
        github: "https://github.com/HimanshuKamdi",
      },
      {
        name: "Anvi",
        surname: "Jamkhande",
        position: "Finance Head",
        image: financeHead1,
        linkedin: "https://www.linkedin.com/in/anvijamkhande",
        github: "https://github.com/AnviJamkhande",
      },
      {
        name: "Gaurish",
        surname: "Dodke",
        position: "Finance Head",
        image: financeHead2,
        linkedin: "https://www.linkedin.com/in/gaurish-dodke-47b812238",
        github: "https://github.com/gaurishdodke13",
      },
      {
        name: "Harshwardhan",
        surname: "Deshmukh",
        position: "Events & Publicity Head",
        image: eventHead1,
        linkedin: "http://www.linkedin.com/in/harshwardhan-deshmukh-3b0043179",
        github: "https://github.com/Harshwardhan-Deshmukh03",
      },
      {
        name: "Sanskriti ",
        surname: "Pandey",
        position: "Events & Publicity Head",
        image: eventHead2,
        linkedin: "https://www.linkedin.com/in/sanskriti-pandey-69054a231",
        github: "https://github.com/pandeysans008",
      },
      {
        name: "Pratima",
        surname: "Pore",
        position: "Design Head",
        image: creativesHead1,
        linkedin: "https://www.linkedin.com/in/pratima-pore-027555266",
        github: "https://github.com/Pratima4003",
      },
      {
        name: "Dhananjay ",
        surname: "Kokate",
        position: "Design Head",
        image: creativesHead2,
        linkedin: "https://www.linkedin.com/in/dhananjay-kokate-b32952206",
        github: "https://github.com/dsk73",
      },
    ],
  },
];

export default team2023;

import React from "react";

const avishkar_andhale = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Avishkar Rajkumar Andhale
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              Firstly, there was an aptitude round. 26 students were shortlisted
              after the aptitude test. Then there were 3 interview rounds (some
              had only 2). 14 students were selected after the first round.
              Finally, 7 students were shortlisted.{" "}
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              The aptitude test consisted of 5 sections:
              <p>
                <ul style={{ marginLeft: "1.5rem", listStyleType: "disc" }}>
                  <li>Coding Section (2 questions) – 30 min</li>
                  <li>Quantitative Aptitude (8 questions) – 25 min</li>
                  <li>Advanced Programming Section (1 question) – 45 min</li>
                  <li>Essay Section (2 questions) – 15 min</li>
                  <li>CS MCQ (7 questions) – 20 min</li>
                </ul>
              </p>
            </p>
            <p>
              The coding section was easy. The first question was to remove
              consecutive occurrences of a character from the string (aabcddeef
              -&gt; abcdef). The second question was based on 2d arrays.
            </p>
            <p>
              Quantitative Aptitude was slightly difficult. Questions were
              mostly based on maths (probability, integration, etc.).{" "}
            </p>
            <p>
              Advanced Programming Section had only 1 problem – Snake and Ladder
              game, similar to this problem -{" "}
              <a href="https://www.geeksforgeeks.org/snake-ladder-problem-2/">
                Snake and Ladder Problem 2
              </a>
            </p>
            <p>In the Essay section, we had to write 2 essays.</p>
            <p>
              CS MCQ section had questions based on DSA, OOPS, and basics of CN
              and OS.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>There were three interview rounds:</p>
            <ol>
              <li>
                <strong>
                  1<sup>st</sup> Technical Interview:
                </strong>
                <p>
                  It was a live coding round on Hackerrank’s platform called
                  CodePair. The round lasted for about 25 minutes and was
                  completely based on DSA.
                </p>
                <p>
                  <ul>
                    <li>
                      <a href="https://www.geeksforgeeks.org/write-an-efficient-c-function-to-convert-a-tree-into-its-mirror-tree/">
                        Mirror of a tree
                      </a>
                    </li>
                    <li>
                      <a href="https://www.geeksforgeeks.org/write-a-function-to-get-the-intersection-point-of-two-linked-lists/">
                        Find the intersection point of two Linked Lists
                      </a>
                    </li>
                    <li>
                      <a href="https://www.geeksforgeeks.org/swap-all-odd-and-even-bits/">
                        Swap all odd and even bits
                      </a>
                    </li>
                  </ul>
                </p>
                <p>
                  I also had to explain the time complexity of my solution and
                  how my solution was efficient.
                </p>
              </li>
              <li>
                <strong>
                  2<sup>nd</sup> Technical Interview:
                </strong>
                <p>
                  The second round was completely based on my resume. This round
                  lasted around 45 minutes. I was asked questions about every
                  project on my resume. The interviewer covered a variety of
                  topics related to my projects. I was also asked for a few
                  puzzles.
                </p>
              </li>
              <li>
                <strong>
                  3<sup>rd</sup> (Technical + HR):
                </strong>
                <p>
                  This round was partly technical and partly HR. I was asked to
                  design a solution that was based on LRU cache implementation.
                  After that, there was a discussion about clubs mentioned on my
                  resume, both technical and non-technical ones.{" "}
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <p>
              The second Round mainly was based on projects. I was asked to
              explain my projects. They asked me about the challenges I faced in
              my projects and questions based on the implementation of my
              project. I was also asked a few questions about MongoDb as I had
              mentioned it as one of the skills on my resume. So be cautious
              about what you put on your resume.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              I followed{" "}
              <a href="https://drive.google.com/file/d/1FMdN_OCfOI0iAeDlqswCiC2DZzD4nPsb/view">
                "DSA Sheet by Love Babbar"
              </a>{" "}
              for coding questions. Also solved a few questions on hackerrank as
              most companies use this platform for the aptitude test.{" "}
              <a href="https://www.indiabix.com/">Indiabix</a> is good for
              practicing quantitative aptitude. I studied oops, OS, CN, DBMS,
              and read a few puzzles from GFG. Reading frequently asked
              interview questions related to OS, CN, and DBMS is also very
              helpful.
            </p>
          </p>

          {/* <p>
            <i>
              A general suggestion for all companies: Be honest and remain calm
              and confident. It doesn’t matter even if you are unable to solve
              coding questions just make sure to explain you thought process or
              solution you have thought about to your interviewer. If you are
              not clear about any question, feel free to ask your interviewer to
              repeat it or explain a bit more about it. Also try working on your
              communication in English as it is a plus point. I wish you guys
              all the best for your future!!!
            </i>
          </p> */}
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:andhalear19.comp@coep.ac.in">
              andhalear19.comp@coep.ac.in
            </a>
            {/* <br />
            <a href="https://www.linkedin.com/in/anupn08/">LinkedIn Handle</a> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default avishkar_andhale;

import React from "react";

const falguni_patil = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Soumitra Tonapi
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <h5>General Comments</h5>
            Firstly, FinIq is a financial service / fintech company. So, a huge
            emphasis is placed on Finance for interns at the company. Not to say
            that a huge amount of prerequisite financial knowledge is required,
            but as per what the seniors say, you will learn a large amount of
            financial concepts while working at FinIq. So, after getting
            selected here, there is a great chance that you will learn a lot
            about finance, but not way too much in the technical domain.{" "}
          </p>

          <p>
            <h5>Interview Process</h5>
            Resume shortlisting takes place before the interview process, so
            after that act is over, the actual process begins.
            <br />
            The interview process contained the following stages:
            <ol style={{ marginLeft: "2rem" }} type="1">
              <li>Group Discussion</li>
              <li>HR Interview</li>
              <li>2 General Interviews</li>
            </ol>
            FinIq is one of the few companies that didn’t conduct an aptitude
            test for interns in the year 2020, but they had conducted coding
            aptitude tests when they took interns in 2019 (i.e for the batch
            passing out in 2021). So, while my report won’t include information
            about aptitude tests, refer to other diaries to get a general idea
            of how aptitude tests work and what areas you should focus on to
            pass them.
            <br />
            <br />
            So moving along, I’ll break down the rounds one by one:
            <br />
            <br />
            <h5>Group Discussion</h5>
            Students were divided into groups of about 8 people by random
            grouping amongst all the applicants. 3 statements were given out of
            which any one would be selected for the discussion round.
            <br />
            So at the time of the round, any one statement was given for
            participants to discuss and deliberate on. Around 10/15 minutes was
            the time allotted for participants to discuss amongst themselves. A
            panel of about 3/4 people were watching and judging the
            contributions made to the discussion by various people. Note that
            you aren’t forced to agree with the view held by the rest of the
            group. In fact, you are encouraged to put forth your own differing
            views in order to spur discussion.
            <br />
            At the end, approximately 40-50% people ended up passing this round.
            <br />
            <br />
            What can you do to improve your chances?
            <br />
            So any sort of discussion requires good communication skills and
            confidence with which you put forth your ideas. For this, practice
            speaking and communicating in English. Also, read up on current
            events and try to improve your general knowledge as much as
            possible.
            <br />
            Establishing yourself as a good orator and communicator makes
            companies more interested in you and helps with your own self
            confidence as well.
            <br />
            <br />
            <h5>HR Interview</h5>
            So this is just a generic HR interview, where they ask you a bunch
            of questions about yourself. It helps them gauge your personality a
            bit. Most of the time, HR interviews are very calm and try to gently
            communicate with candidates. They’ll ask you a bit about your
            background, what your parents do, and what sort of things you hope
            to accomplish / learn.
            <br />
            They’ll ask about your hobbies and interests, so make sure you have
            things to say that will keep them interested in you. Also, they’ll
            ask about your academic aspirations and technical skills and
            technical background. There won’t be much technical questioning
            involved though, that’s not really what the HR round is about.
            <br />
            <br />
            What can you do to improve your chances?
            <br />
            Again, make sure that you engage in conversation with the
            interviewer and act interested in the company. Having general
            knowledge about the company you’re applying to can only serve to
            help your case. Besides that, there isn’t much preparation for an HR
            interview, it’s more about you honestly expressing your views.
            <br />
            <br />
            <h5>Technical / General interviews</h5>
            So, I had 2 distinct interviews after this focusing on 2 specific
            distinct areas. I’ll cover both of them here.
            <br />
            <br />
            So firstly, there was a coding based technical interview.
            <br />
            They don’t ask you to code, more like checking whether or not you
            understand the logic behind code. I was also asked to explain the
            mechanisms behind the Quick and Heap Sort Algorithms. They also
            asked me what my favorite/best subject was, which was Theory of
            Computation (ToC) for me. Following which, they asked me a few
            general questions in that subject, to see if I did actually have an
            idea what I was talking about, or if I was just bluffing.
            <br />
            <br />
            After this, we moved onto my resume and the projects I had listed,
            which included my DSA/PPL projects as well as another ML based
            project I had done. They asked me general questions about my
            projects and about various techniques I had implemented in my
            project. A large amount of questions were focused on my ML project,
            they wanted me to explain what all concepts I had applied and
            libraries / data analytics tools I had used. My DSA project was in
            C, while the other two were in python.
            <br />
            <br />
            Following this, the interview slipped into more of an informal
            conversation type environment. While talking to the interviewer, the
            US - China Economic trade war came up and we had a discussion on
            this, where I was asked to put forth my own views on this matter and
            like try to come up with a compromise that could be beneficial to
            both parties. This was mostly to gauge communication skills as well
            as basic economic knowledge. The interview concluded after this.
            <br />
            <br />
            After this was another, shorter interview.
            <br />
            This one was conducted on quick thinking and quantitative skills.
            Basic mathematical problems were asked and you had to solve them and
            respond as fast as possible. So while the former interview focused
            on the coding aspect, this one focused on the quant aspect, which is
            just as important for any company operating in the financial sector.
            <br />
            <br />
            What can you do to pass these rounds?
            <br />
            Ensure that your DSA basics are cleared and you have a grasp over
            basic algorithms. Use{" "}
            <a href="https://geeksforgeeks.com">GeeksForGeeks</a> for this and
            for practice problem solving, you can use{" "}
            <a href="https://hackerrank.com">HackerRank</a> or any other similar
            competitive coding platform.
            <br />
            Build up your resume with projects that you have done yourself and
            which you understand properly. Don’t think you can fool the
            interviewer with just partial knowledge, that will never end up
            working in your favor
            <br />
            <br />
            <h5>Closing Notes</h5>
            So after these rounds concluded, I ended up getting selected for the
            intern position at FinIq. Do understand that this company is finance
            centric, as I have already covered in my general comments, so the
            sort of role you get here will surely be linked with Finance in some
            way. So make sure to try for this company if finance is something
            you’re interested in or curious about, you’ll surely get to learn a
            lot.
            <br />
          </p>

          <p>
            <i>
              All the best for your own preparations and internship process!
            </i>
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:tonapiss18.comp@coep.ac.in">
              tonapiss18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default falguni_patil;

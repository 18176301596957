import React, { useState } from "react";

import "../assets/css/testimonial.css";

import BtnSliderTestimonial from "./BtnSliderTestimonial";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";


function Testimonial({ data, eventName, eventlink }) {
  const [slideIndex, setSlideIndex] = useState(0);
  const noOfFeedBacks = data.length;

  const nextSlide = () => {
    if (slideIndex === noOfFeedBacks - 1) setSlideIndex(0);
    else setSlideIndex(slideIndex + 1);
  };

  const prevSlide = () => {
    if (slideIndex === 0) setSlideIndex(noOfFeedBacks - 1);
    else setSlideIndex(slideIndex - 1);
  };
  
  return (
    <div className="testimonial-slider">
      {data.map((feedback, index) => (
        <div
          key={feedback.id}
          className={slideIndex === index ? "slide active-anim" : "slide inactive-anim"}
        >
          <div class="card_testimonial">
            <div class="card-content_testimonial">
              <h1>{eventName + " Feedback " + (index + 1).toString()}</h1>
             

              <p class="feedback_testimonial" style={{ fontWeight: "bold" }}>
                <FaQuoteLeft style={{fontSize:"11px", marginBottom:"2%"}}/>
                <i> {feedback.description} </i> <FaQuoteRight style={{fontSize:"11px", marginBottom:"2%"}}/>
              </p>

            
               {eventlink && (<a href={eventlink} className="author_testimonial" target="_blank"  rel="noopener noreferrer">LinkedIn Post</a>)
             } 
              {feedback.eventlink && (<a href={feedback.eventlink} target="_blank"  rel="noopener noreferrer" className="author_testimonial">LinkedIn Post</a>)
             } 
             
            </div>
          </div>
          <BtnSliderTestimonial moveSlide={nextSlide} direction={"next"} />
          <BtnSliderTestimonial moveSlide={prevSlide} direction={"prev"} />

      
        </div>
        
      ))}
          
    </div>
  );
}

export default Testimonial;

import React from "react";

const ayush_shah_avaya = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Aayush Shah</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Avaya </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />
            - Aptitude Test
            <br />
            - Technical Interview Round 1
            <br />- Technical Interview Round 2
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            Aptitude test was very elaborate, exhaustive, and covered a plethora
            of topics. Among the most well crafted aptitude tests I have given
            till date. Overall difficulty was significantly higher than other
            companies. A mix of theory based questions and code snippets based
            multiple choice questions, followed by a coding test section. Higher
            difficulty means that the bar is higher for everyone, so it does not
            mean that your odds of clearing the round will go lower.
            <br />
            Theory questions covered all subjects, but questions on networking
            and operating systems outnumbered others. Few questions on
            scheduling algorithms, OOP theory, language based questions (C and
            Java), protocols in networking.
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br /> <i>Technical Interview Round 1</i>
            <br />
            Rapidfire round. 2 interviewers, lots of questions based on theory
            in the first half of the interview. I had listed Operating Systems
            as my favourite, so it invited lots and lots of questions based on
            OS. Scheduling algorithms, how GNU/Linux boots up, questions on what
            causes race conditions, methods for synchronisation, etc.
            <br />
            Second half of the interview round was live coding questions in
            language of choice. Complicatedly framed problem statements.
            <br />
            1. Synchronisation problems
            <br />
            2. Valid Parentheses problem
            <br />
            3. Code a doubly linked list (in C)
            <br />
            <br /> <i>Technical Interview Round 2</i>
            <br />
            Discussion about my work experience and knowledge. Was supposed to
            be an HR round, instead the interviewer and I discussed about
            containerisation technology. Very technical questions about how
            containerisation works, how cloud deployment works, Continuous
            Integration / Continuous Development, etc. We discussed about
            Docker, Kubernetes, Jenkins pipelines, etc. Towards the end I was
            asked a few generic HR round questions for about 5 minutes, nothing
            significant.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            Apart from projects listed on my resume that falls under OS, no
            other questions were asked based on my Resume.
            <br />
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <a href="https://www.geeksforgeeks.org/">GeeksForGeeks</a> and{" "}
            <a href="https://drive.google.com/open?id=1zWdVt8aUI5fwUR5Tls3xs8arc_8AZ7mb">
              Cracking the Coding Interview
            </a>
            , for competitive coding - pick one out of HackerRank, HackerEarth,
            TopCoder, CodeChef, LeetCode, SPOJ, Kaggle, etc and start solving
            problems daily. You eventually get good at it after solving a
            significant number of problems.
            <br /> Online Source:{" "}
            <a href="https://www.geeksforgeeks.org/placements-gq/">
              {" "}
              Aptitude Test Topics
            </a>
            , Preparation book:{" "}
            <a href="https://drive.google.com/open?id=1HchH-ZPbJ0PPYYOn4kGdxBaxvemPMgkt">
              {" "}
              R. S. Aggarwal
            </a>
            , Puzzles:{" "}
            <a href="https://www.geeksforgeeks.org/category/puzzles/">
              {" "}
              Puzzles
            </a>
            , Mock Tests:{" "}
            <a href="https://www.geeksforgeeks.org/quiz-corner-gq/#Aptitude%20Mock%20Tests">
              {" "}
              Mock Aptitude Tests
            </a>
            <br />
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Be experienced in competitive coding. Have an appetite for creating
            algorithm based solutions for problems on your own. Solve problems
            on GeeksForGeeks question banks on the necessary topics, and
            practice competitive coding websites given below.
            <br />
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            Company is quick to call you out on your bullshit, and very quick in
            acknowledging your strengths and weaknesses. They will ask you
            questions based on your strengths, and they will simply skip forward
            when you admit you don't know how to answer a question. You won't be
            able to get away with lying about anything on your resume, or
            beating around the bush for a question you don't know an answer to.
            They tend not to focus on anything specific, they will just keep
            asking follow up questions based on the current question. Expect to
            be overwhelmed with questions and get rattled even if you're well
            versed in that specific subject, that's what they will be aiming
            for.
            <br />
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:shahaayush1999@gmail.com">
              shahaayush1999@gmail.com
            </a>
            <br />
            <a href="https://www.linkedin.com/in/shahaayush1999/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ayush_shah_avaya;

import React from "react";

const umang_sakhare = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{ fontSize: "2em", position: "absolute", marginTop: "2rem" }}
          />
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Umang Sakhare
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>QuestionPro</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />
          <p>
            This is the story of how I landed my first internship in second
            year. I was looking to get better at Java development and I reached
            out to friends and family asking if they could help me look for an
            internship. Luckily, my uncle knew a company that did Java web
            application development. He referred me to the manager there and
            they called me in for an aptitude test, followed by an interview.
            The aptitude test was an hour-long test and was divided into 3
            sections: logical reasoning, math and language proficiency. Each
            section had 10 questions all of them had equal weightage. After the
            test, the manager called me in for an interview. He started by
            telling me about what the company does and asked me if I had any
            relevant experience. Then he asked me about the subjects I have
            covered in college and asked me a few questions about DSA. The
            questions were mostly related to stacks, arrays and linked lists. He
            proceeded to ask about the projects I had done so far and I
            mentioned all the relevant web development ones. I wasn’t asked any
            technical questions about web dev, only on how I went about
            developing the project and what my experience was. I wasn’t really
            asked many questions based on my resume either, just on the projects
            I spoke about. He then told me I will have to take the first few
            days learning about Java Enterprise Edition and Spring MVC
            Framework. Then we had a light conversation about my interests and
            college life at COEP.
          </p>
          <p>
            Prior to the aptitude and interview I had prepared by solving basic
            question in DSA and going through my resume thoroughly. I had also
            gone through the implementation of my projects and made a rough
            summary of it. As for the coding section, I went through some common
            questions asked during interviews that can easily be found online on
            sites like GFG.
          </p>
          <p>
            A couple of non-technical suggestions I would give, which I believe
            helped me land this internship are:
          </p>
          <ul>
            <li>
              Start by greeting the interviewers with a smile and a handshake.
            </li>
            <li>
              Take a moment before you answer. It’s common for interviewees to
              take around 20-30 seconds to form a proper answer. Although it may
              seem like a long time during an interview but the interviewers
              understand that it is normal. In case you are taking too long to
              answer they will say something, so don’t hesitate to take your
              time. In the event that you are sure of an answer as soon as you
              hear the question, take 5 seconds to frame your answer concisely.
            </li>
            <li>
              Be honest. If you don’t know something, politely say you don’t
              know. If you know something, make sure you’re confident in the way
              you’re saying it. That being said, don’t be afraid to take risks,
              try to attempt question you are unsure about, a lot of times the
              interviewers end up helping you if they see you have a rough idea.
            </li>
            <li>
              In case you haven’t understood what the interviewer is asking,
              politely ask the interviewer to repeat themselves by saying
              something like “I haven’t quite understood the question, could you
              come again please?” It is better than half understanding a
              question and answering incorrectly. If they repeat themselves and
              you still don’t understand a neat trick is to try to say what you
              have understood about the question and ask them if you have
              comprehended it correctly. If you haven’t they will try to form
              the question differently so you understand it better.
            </li>
            <li>
              Remember the interviewers are also people like you and I. Don’t
              hesitate to engage in a conversation. Don’t let the interview be a
              one sided conversation as this is off putting for the interviewer
              and affects your chances of being selected. Ask questions whenever
              an opportunity presents itself, this shows the interviewer you are
              engaged in the conversation. You don’t have to force it though. Be
              yourself and have a normal conversation with them, as you would
              with a friend (but politely of course).
            </li>
            <li>
              Make sure you end the interview by asking them if they have any
              suggestions for you. There is no one better to help correct your
              mistakes than the interviewers themselves are. It also leaves a
              good impression on them as they feel you are a candidate who is
              looking to develop himself or herself.
            </li>
          </ul>
          <p>
            As much as technical skills matter, even the interviewers know that
            all candidates go through an initial training period, and eventually
            everyone picks up the required skills, especially students from a
            college like COEP. So don’t feel like you aren’t prepared well
            enough, trust me, a lot of us aren’t. It entirely depends on how
            they want to direct the interview. As long as you make a good
            impression and do your best at answering the questions, you should
            be good.
          </p>
          <p>Best of luck!</p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15em" }}>
            <a href="mailto:umangs17.it@coep.ac.in">umangs17.it@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default umang_sakhare;

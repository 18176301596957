import React from "react";

const tanmay_patil = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
           Tanmay Sudam Patil
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
           FinIQ
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />
          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
          <li>
            <b>Round 1: </b>
            Filling up an excel sheet, which asked about academic and personal details, upon which people were shortlisted for next round.
          </li>
          <li>
            <b>Round 2: </b>
            Group Discussion (GD).
          </li>
          <li>
            <b>Round 3: </b>
            Coding
          </li>
          <li>
            <b>Round 4: </b>
            Technical / HR
          </li>
          </ul>
            
          </p>
          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            	There was No Aptitude test.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
          
            <ol>
                <li>
                    <strong><u>Group Discussion </u></strong>
                    <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                        <li><b style={{fontWeight:"600"}}>Phase I :</b>
                            <ul style={{listStyleType:"square",marginLeft:"1.5rem"}}>
                                <li>For this round, the topics for discussions were sent beforehand with the excel sheet that was supposed to be filled.</li>
                                <li>Round started after a short introduction involving a brief answer to some questions like ‘Would you like to share something that many of us don’t know about you ?’ and then after that topic was chosen by one of the person from the group and discussion over it lasted for about 8-10 min.</li>
                                <li>This round expects you to be on toes, and be conscious enough to listen to others carefully and reciprocate your thought in an easily understandable manner.</li>
                            </ul>
                        </li>

                    </ul>
                    <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                        <li><b style={{fontWeight:"600"}}>Phase II:</b>
                            <ul style={{listStyleType:"square",marginLeft:"1.5rem"}}>
                                <li>This was a personal opinion round where it was expected to speak for yourself and not for the group.</li>
                                <li>We started from where we left off in the discussion phase and each person on random was asked about his opinion on another topic from the same list of topics for GD, at the same time the reason for feeling what you felt like. On an average, 20 seconds were provided for each person to talk over.</li>
                                <li>This was then followed by a question to all ‘Explain one thing that you put on the excel sheet and why did you did so ?’, here too people were chosen at random and was expected to answer this question in the time frame of 30 seconds.</li>
                            </ul>
                        </li>

                    </ul>
                </li>
                <br />
                <li>
                <strong><u> Coding : </u></strong>
                <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                    <li>We were given two problem statements and we were asked to write code on paper and submit a pdf file to the evaluator.</li>
                    <li>Coming to the coding questions, in my opinion both were moderate questions and can be solved easily once you are able to translate your thoughts to code.</li>
                    <li>One of the questions that I remember in my case was,  ‘Print Periodic Table as it traditionally looks, given the number of rows or periods of the Periodic Table.’</li>
                    <li>Here it made sense that they only asked to print the atomic number and not the symbolic represenation of the elements else it would have been a tough nut to crack :)

</li>
                    </ul>

               
                </li>
                <br />
                <li>
                <strong><u>Technical / HR : </u></strong>
                <ul style={{listStyleType:"disc",marginLeft:"1rem"}}>
                    <li>This round started with ‘Tell me something about yourself’, as it can easily be anticipated.</li>
                    <li>Try to get prepared for this round or atleast have an answer prepared for ‘Introduce yourself’ or question like this because this is often the icebreaker of your conversation with the interviewer.</li>
                    <li>After this, he went through my resume and asked some questions like my favourite subjects, interests, favourite pastime and many such related questions before jumping to the technical aspect where we discussed some of the listed projects of the resume, and some basic / core CS questions.</li>
                    <li>He, then asked questions on Linked Lists and other linear data structures which I have used in some of the listed projects. We then discussed image processing to a considerable depth.

</li>
<li>Since I am part of COEP’s Chess Team, to end conversation, he asked me my favourite chess player to end up on a light note.</li>
<li>In my opinion, if the discussion goes to clubs or extra-curriculars, then have yourself prepared with some of the questions related to your club or extra-curricular activity, if you are involved in any.</li>
                    </ul>

               
                </li>
                
               
            </ol>
          
          </p>
          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <br />
            <p>
            <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                <li>A decent amount of questions were asked based on projects and on resume.</li>
                <li>Some questions led to detailed discussions over the underlying concepts used in projects and others were straightforward too. So remember to brush up on the concepts and tools that you have used in the projects.</li>
                </ul>
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
            <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                <li>Like everyone would say, GeeksForGeeks is one of the best for revising DSA concepts.</li>
                <li>Another resource that can be very helpful is HackerRank to practice problem solving and get familiarize with the platform as many other companies use it for aptitude test.</li>
                <li>One can also look Codechef and LeetCode if he / she has time to go around some more resources.</li>
                </ul>
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
            <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                <li>Consider using C++, Java or Python3 as they come up with many libraries that you can use directly without even bothering about their implementations.</li>
                <li>Read the question carefully as there might be something that could make your solution easy to implement.</li>
                <li>Try to get the brute force solution first and then go for optimization as sometimes brute force may work and you could clear all the testcases in the first go.</li>
                <li>If at all you are not able to pass all the testcases, try to cover the edge cases and get at least some test case passed, because this might help rather than no test cases being passed.

</li>
                </ul>


            </p>
          </p>
          
          <p>
          <b>
             7. Is there something specific that you would want to tell people who try for the same company?
            </b>
            <br />
            <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                <li>Since FinIQ is a finance based company, if you are into finance you’ll definitely learn a lot out of this oppurtunity.</li>
                <li>For something specific, I would say your communication skills matter a lot because there is a lot inclination towards the spoken rounds as we can see GD and other individual talks involved.</li>
              


                </ul>
           


          </p>
          <br />
          <br />
         
            <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:patilts19.comp@coep.ac.in">
            patilts19.comp@coep.ac.in
            </a>
           
          </div>
        </div>
      </section>
    </div>
  );
};

export default tanmay_patil;

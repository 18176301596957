import React from "react";

const anish_deshpande = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Anish Deshpande
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              After the Aptitude round, students were
              selected for the further rounds. These rounds included a Group Discussion, a
              Written round and finally the Interviews. Written round and group discussion
              round went simultaneously. Based on the performance in GD and written,
              students were selected for Interview.

            </p>

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              Since FinIQ is a Fintech company, the aptitude
              round included questions from Computer Engineering subjects, Financial
              Aptitude, Accounting, General Knowledge, Logical Reasoning and Numerical
              reasoning.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b><br />
            <p> Total <b>3</b> rounds including the aptitude round : 
            </p>
            <p style={{paddingLeft: "40px" }}>
            <p>
              First round was Aptitude. As mentioned above it contained questions from
              engineering background as well as finance questions along side basic logical and
              numerical reasoning.
            </p>
            <p>
              Second round was Group Discussion and Written round. Selected students were
              divided into groups and appeared for group discussion. Written round took place
              simultaneously. It involved open-ended questions that involved creativity and
              financial basics. The questions were designing a business plan, creating an
              Advert for a business, creating a budget for a Municipal Corporation etc. The
              written round also contained a coding question to be solved in any language the
              student deemed fit.

            </p>
            <p>
              After the screening based on GD, Interview was taken. The interview was a
              mixture of both Technical and HR. Questions were based on Computer
              Engineering subjects like Data Structures, DBMS, Computer Networks and
              Microprocessors. Since I had shown interest in Finance, some questions were
              asked from Finance as well. These questions involved domains like investment
              devices and techniques, hedging of portfolio etc. After that, questions about
              general goals and further education ensued. They asked some questions about
              my hobbies as well. That was it.

            </p>
            </p>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            <p>
              Difficult to remember the exact number after each round but 14 were selected finally for internship.
            </p>
          </p>

          <p>
            <b>
              5. Some good resources you used in your internship prep
            </b>
            <br />
            <p>
              For topics like Data
              structures and microprocessors, GFG and InterviewBit is helpful but like only for
              lasst minute revision.

            </p>
          </p>

          <p>
            <b>
              6. Some tips to crack coding questions
            </b>
            <br />

            <p>
              The only coding question asked was simple
              about nested topics like in a book, 1.1 divided into topics 1.1.1, 1.1.2 and so on. The
              headings had to be written in perfect form with indentation.
            </p>
          </p>

          <p>
            <b>7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume</b> <br />
            <p>
              During my interview, a lot of focus was put on my projects.
              Since I had projects in different domains, like data structures, web based management
              systems, cross platform application etc, a lot of the questions revolved around these
              topics only.
            </p>
          </p>
          <b>
              8. Is there something specific that you would want to tell people who try for the
              same company?
          </b>
          <p>
            If you want to explore the financial side in Technology, then i
            would suggest that you should apply for FinIQ
          </p>
          <b>
              9. Final Tip for Juniors?
          </b>
          <p>
            Your confidence covers for some of the slip-ups in the interview, but that doesn’t
            mean you should confidently mess up everything. So be confident while
            answering and don’t beat around the bush. If you don’t know an answer, say so.
            Most importantly, keep calm and dont get nervous. Prepare for the technical
            rounds well as it forms the base of your profile.
          </p>
          <br />


          
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID : <a href="mailto:deshpandeaa20.comp@coep.ac.in">
            deshpandeaa20.comp@coep.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default anish_deshpande;

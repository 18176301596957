export const InspironParticipants = [
{'team': 'Smart Solutions Squad', 'name': 'Abhinav Sundriyal', 'devfolio_username': ''},
{'team': 'Invaders', 'name': 'Yash Gaherwar', 'devfolio_username': 'Yash_Gaherwar'},
{'team': 'Invaders', 'name': 'Devanshu Dalal', 'devfolio_username': 'Devanshukd04'},
{'team': 'Invaders', 'name': 'Tanishq Deshpande', 'devfolio_username': 'tan777'},
{'team': 'Invaders', 'name': 'Avinash Dhakne', 'devfolio_username': 'avinashdhakne'},
{'team': 'ICONIC', 'name': 'HARISHANKAR PRASAD', 'devfolio_username': 'Harishankar123'},
{'team': 'ICONIC', 'name': 'BISHNU RAJ', 'devfolio_username': 'Bishnu01'},
{'team': 'ICONIC', 'name': 'TALGAT GRAMI', 'devfolio_username': 'tg16955'},
{'team': 'Dev_Ops', 'name': 'Pranav Bagal', 'devfolio_username': 'Pranav_1234'},
{'team': 'Dev_Ops', 'name': 'Shubham Dadas', 'devfolio_username': 'Shubham Dadas'},
{'team': 'Hack Mavericks', 'name': 'Anwesh Shaw', 'devfolio_username': 'anwesh_1606'},
{'team': 'Hack Mavericks', 'name': 'Arpit Pandey', 'devfolio_username': 'Arpit725'},
{'team': 'Horizon', 'name': 'Aniket Sarawgi', 'devfolio_username': 'Aniket2000'},
{'team': 'Horizon', 'name': 'Ashish Prakash Singh', 'devfolio_username': 'ashish6303'},
{'team': 'TeamInitials', 'name': 'Prakhar Kulshrestha', 'devfolio_username': 'Prakhoo3012'},
{'team': 'TeamInitials', 'name': 'Nishant Dhupia', 'devfolio_username': 'dhupianishant'},
{'team': 'TeamInitials', 'name': 'Parth Vijay', 'devfolio_username': 'vjj_parth'},
{'team': 'TeamInitials', 'name': 'Mudit Paliwal', 'devfolio_username': 'mudit171'},
{'team': 'Aya', 'name': 'Soumi Pandit', 'devfolio_username': 'Soumi_pandit'},
{'team': 'Aya', 'name': 'Shivani Kumari', 'devfolio_username': 'Shivani17'},
{'team': 'Tech Pirates', 'name': 'Atharva Banasure', 'devfolio_username': 'Atharva Banasure'},
{'team': 'Tech Pirates', 'name': 'Sahil Tiwade', 'devfolio_username': 'Sahil652002'},
{'team': 'LESSGO!!!!', 'name': 'P Karthik Manikantan', 'devfolio_username': 'ManiKP_1013'},
{'team': 'ai muse', 'name': 'Yatendra Kumar', 'devfolio_username': 'yatendra2001'},
{'team': 'ai muse', 'name': 'Fahem Ahmed', 'devfolio_username': 'fahemahmed'},
{'team': 'ai muse', 'name': 'Tanmay Yadav', 'devfolio_username': 'yadavtanmay'},
{'team': 'Immortals', 'name': 'Vedant', 'devfolio_username': 'vedxnt10'},
{'team': 'Immortals', 'name': 'Yashwant', 'devfolio_username': 'yash_3123'},
{'team': 'Immortals', 'name': 'Shripad', 'devfolio_username': 'Skdahale'},
{'team': 'Immortals', 'name': 'Ganesh', 'devfolio_username': 'ganesh_derkar'},
{'team': 'CodeBusters', 'name': 'Anish Patil', 'devfolio_username': 'Anish_47'},
{'team': 'CodeBusters', 'name': 'Pranit Patil', 'devfolio_username': 'PranitPatil3112'},
{'team': 'CodeBusters', 'name': 'Siddharth Dhodi', 'devfolio_username': 'siddharthdhodi7'},
{'team': 'CodeBusters', 'name': 'Parth Gharat', 'devfolio_username': 'ParthGharat'},
{'team': 'CropBlock', 'name': 'Raj Dhapse', 'devfolio_username': 'raj_in_a_jar'},
{'team': 'Technocrats', 'name': 'Sanket Patil', 'devfolio_username': 'sanketpatil7467'},
{'team': 'Technocrats', 'name': 'Phalesh Kolpe', 'devfolio_username': 'phalesh'},
{'team': 'Technocrats', 'name': 'Siddhesh Patil', 'devfolio_username': 'Siddhesh_patil2'},
{'team': 'Technocrats', 'name': 'Abhishek Pote', 'devfolio_username': 'abhishek_pote'},
{'team': 'FavDev', 'name': 'Mukul Yadav', 'devfolio_username': 'Rao_Mukul'},
{'team': 'FavDev', 'name': 'Nilesh Sharma', 'devfolio_username': 'OkNilesh'},
{'team': 'Solo(Kush sah)', 'name': 'kush bhargav sah', 'devfolio_username': 'kushsah12'},
{'team': 'Code Mantra', 'name': 'Piyush Bhondave', 'devfolio_username': 'thepiyush07'},
{'team': 'Code Mantra', 'name': 'Chetan Shekhar Patil', 'devfolio_username': 'unknownnnn001'},
{'team': 'Team Decoders', 'name': 'Prem Gaikwad', 'devfolio_username': 'Prem_Gaikwad'},
{'team': 'Team Decoders', 'name': 'Gopal Saraf', 'devfolio_username': 'gopal saraf'},
{'team': 'Team Decoders', 'name': 'Mangesh Salunke', 'devfolio_username': 'mangesh13'},
{'team': 'Team Decoders', 'name': 'Prasad Khatake', 'devfolio_username': 'prasad'},
{'team': 'The Spark', 'name': 'Kunal Agrawal', 'devfolio_username': 'itskunal42'},
{'team': 'Team Lakshya', 'name': 'Divya Renge', 'devfolio_username': 'divya_r20'},
{'team': 'Team Lakshya', 'name': 'Ashwini Jadhav', 'devfolio_username': 'jadhav01'},
{'team': 'Team Lakshya', 'name': 'Aniket Kumar', 'devfolio_username': 'aniketkumarr'},
{'team': 'ParkWise', 'name': 'Rohith Kumar B', 'devfolio_username': 'Rohith_kuamr_B'},
{'team': 'Quadrax', 'name': 'Cheenmaya Bore ', 'devfolio_username': 'Cheenmaya Bore '},
{'team': 'Quadrax', 'name': 'Dipti Apage', 'devfolio_username': 'Dipti Apage'},
{'team': 'Tech Geeks', 'name': 'Pratham Agrawal', 'devfolio_username': 'Pratham05'},
{'team': 'Tech Geeks', 'name': 'Anchalaa Jha', 'devfolio_username': 'anchalaa'},
{'team': 'Tech Geeks', 'name': 'Soham Dhumane', 'devfolio_username': 'soham662'},
{'team': 'Tech Geeks', 'name': 'Saurav Mohanty', 'devfolio_username': 'sauravmohanty'},
{'team': 'The Innovaters', 'name': 'Vishal Sharad Patil', 'devfolio_username': 'vishal1777'},
{'team': 'The Innovaters', 'name': 'Abhay Gajanan Shanbhag', 'devfolio_username': 'AbhiShan'},
{'team': 'The Innovaters', 'name': 'Manish Manoj Godbole', 'devfolio_username': 'ManishGodbole'},
{'team': 'The Innovaters', 'name': 'Mikhiel Sujeet Benji', 'devfolio_username': 'mikhiel_25'},
{'team': 'Spartans', 'name': 'Sahil Nitesh Gupta', 'devfolio_username': 'Sahil_Gupta8917'},
{'team': 'Spartans', 'name': 'Sanket Patil', 'devfolio_username': 'Sanket_17'},
{'team': 'Spartans', 'name': 'Pavan Gayake', 'devfolio_username': 'PavanGayake'},
{'team': 'Spartans', 'name': 'Aditya Patil', 'devfolio_username': 'aditya_patil0'},
{'team': 'WebWarriors', 'name': 'Samarth Mali', 'devfolio_username': 'SM1100'},
{'team': 'WebWarriors', 'name': 'Sarthak Nirgude', 'devfolio_username': 'Sarthak000001'},
{'team': 'WebWarriors', 'name': 'Darshan Mahajan', 'devfolio_username': 'Radar101'},
{'team': 'WebWarriors', 'name': 'Atharv Pardeshi', 'devfolio_username': 'asp21k'},
{'team': 'Thedeveloperssquad', 'name': 'Shiwangi Kumari', 'devfolio_username': 'Shiwangi14'},
{'team': 'Thedeveloperssquad', 'name': 'Ankit Chowdhury', 'devfolio_username': 'theinnovator'},
{'team': 'Thedeveloperssquad', 'name': ' Surbhi Kumari', 'devfolio_username': 'Surbhi14'},
{'team': 'Thedeveloperssquad', 'name': 'Niladri Adhikay', 'devfolio_username': 'XSeven19'},
{'team': 'Codesters', 'name': 'Phani', 'devfolio_username': 'Phani85'},
{'team': 'innovateX', 'name': 'Anvita Ajay Mahajan', 'devfolio_username': 'Anvita3502'},
{'team': 'innovateX', 'name': 'Priyal Chawda', 'devfolio_username': ' Priyal'},
{'team': 'CodeBreakers', 'name': 'Siddhesh Pawar', 'devfolio_username': 'Siddhesh000'},
{'team': 'CodeBreakers', 'name': 'Shitij Agrawal', 'devfolio_username': '21shitij'},
{'team': 'CodeBreakers', 'name': 'Anuj Mohite', 'devfolio_username': 'anujrmohite'},
{'team': 'Kali_hackers', 'name': 'Atharva Pawar', 'devfolio_username': 'Atharva Pawar'},
{'team': 'Kali_hackers', 'name': 'Aditya Vyas', 'devfolio_username': 'Aditya Vyas'},
{'team': 'Kali_hackers', 'name': 'Aakarsh Sharma', 'devfolio_username': 'Aakarsh Sharma'},
{'team': 'Kali_hackers', 'name': 'Hardik Prajapati', 'devfolio_username': 'Hardik Prajapati'},
{'team': 'Bit Lords', 'name': 'Parth Sali', 'devfolio_username': 'parthsali'},
{'team': 'Bit Lords', 'name': 'Gaurav Latpate', 'devfolio_username': 'Gaurav_Latpate'},
{'team': 'Bit Lords', 'name': 'Gaurav Patil', 'devfolio_username': 'Gaurav_Patil'},
{'team': '2Schrodingers', 'name': 'GIRISH S', 'devfolio_username': 'girish_2020'},
{'team': 'The Builders', 'name': 'Siddhi Gate', 'devfolio_username': 'siddhigate'},
{'team': 'The Builders', 'name': 'Om Gate', 'devfolio_username': 'OmGate'},
{'team': 'The Builders', 'name': 'Kedar Basutkar', 'devfolio_username': 'kedaroo'},
{'team': 'Grey Coders', 'name': 'Waqar Farooqui', 'devfolio_username': 'Waqar'},
{'team': 'Grey Coders', 'name': 'Rohan Dahibhate', 'devfolio_username': 'Rohan_Dah'},
{'team': 'Grey Coders', 'name': 'Puja Kumari ', 'devfolio_username': 'puja1233'},
{'team': 'Grey Coders', 'name': 'Rohini Kanherkar', 'devfolio_username': 'EchoR'},
{'team': 'Merge Issues', 'name': 'Mokshit Surana', 'devfolio_username': 'GigaMoksh'},
{'team': 'Merge Issues', 'name': 'Prasad Ranjane', 'devfolio_username': 'prasaddd77'},
{'team': 'Merge Issues', 'name': 'Veer Pariawala', 'devfolio_username': 'VP25'},
{'team': 'Merge Issues', 'name': 'Vaibhav Gawad', 'devfolio_username': 'Vaibhav31s'},
{'team': 'The Deadlocks', 'name': 'Ninad Naik', 'devfolio_username': 'ninadn'},
{'team': 'The Deadlocks', 'name': 'Gaurav Ghade', 'devfolio_username': 'ghade_gaurav'},
{'team': 'The Deadlocks', 'name': 'Bhuvan Sawant', 'devfolio_username': 'bhuvansawant'},
{'team': 'The Deadlocks', 'name': 'Atharva Honrao', 'devfolio_username': 'AtharvaHonrao'},
{'team': 'The developer ', 'name': 'Abhishek Hajare', 'devfolio_username': 'Abhishek Hajare'},
{'team': 'The developer ', 'name': 'Ajinkya Kshatriya', 'devfolio_username': 'Ajinkya Kshatriya'},
{'team': 'The developer ', 'name': 'Tejas Thorat ', 'devfolio_username': 'Tejas Thorat'},
{'team': 'Team Ave', 'name': 'Yashashri Gawande', 'devfolio_username': 'YashashriG'},
{'team': 'invincible', 'name': 'Anuj Santosh Barave', 'devfolio_username': 'Anujbarave99'},
{'team': 'invincible', 'name': 'Abhijeet Dhanwate', 'devfolio_username': 'abhijeet07'},
{'team': 'invincible', 'name': 'Vrushaket Chaudhari', 'devfolio_username': 'vrushaketc'},
{'team': 'Binary Brains', 'name': 'Atharva Meshram', 'devfolio_username': 'im_atharva07'},
{'team': 'Binary Brains', 'name': 'Abhishek Nagargoje', 'devfolio_username': 'abhisheksn63'},
{'team': 'Binary Brains', 'name': 'Bhavesh Patil', 'devfolio_username': 'Bhavesh29'},
{'team': 'Binary Brains', 'name': 'Saif Shaikh', 'devfolio_username': 'Saif_20'},
{'team': 'Bits and Bytes', 'name': 'Om Patil', 'devfolio_username': '0mPati1l'},
{'team': 'Bits and Bytes', 'name': 'Gauri Takarkhede', 'devfolio_username': 'gauritakarkhede'},
{'team': 'Bits and Bytes', 'name': 'Soham Rupaye', 'devfolio_username': 'QuirkyDevil'},
{'team': 'Bits and Bytes', 'name': 'Shreyas Phadnis', 'devfolio_username': 'shreyas_10'},
{'team': 'Squiggly Kraken', 'name': 'Jesher Joshua M', 'devfolio_username': 'Jesher Joshua M'},
{'team': 'Squiggly Kraken', 'name': 'Ragav V', 'devfolio_username': 'RAGAV V'},
{'team': 'Squiggly Kraken', 'name': 'Nishanth C', 'devfolio_username': 'Nishanth C'},
{'team': 'Squiggly Kraken', 'name': 'Kanishka Verma', 'devfolio_username': 'Kanishka Verma'},
{'team': 'Chain Reaction', 'name': 'Yash Pande', 'devfolio_username': 'yash797'},
{'team': 'Chain Reaction', 'name': 'Anurag Singh', 'devfolio_username': 'anuragsingh____'},
{'team': "Bits N' Bytes", 'name': 'Vaibhav Dagwal ', 'devfolio_username': 'vaibhav_sd'},
{'team': "Bits N' Bytes", 'name': 'Shubham Athawane', 'devfolio_username': 'shubhamathawane'},
{'team': "Bits N' Bytes", 'name': 'Shubham Umbarje', 'devfolio_username': 'SHUBH_UMB'},
{'team': "Bits N' Bytes", 'name': 'Kunal Kalamkar', 'devfolio_username': 'Kunal_kalamkar'},
{'team': 'Vector', 'name': 'Vishal ', 'devfolio_username': 'vishalsharma072'},
{'team': 'Vector', 'name': 'Rajneesh rana', 'devfolio_username': 'rajneeshrana072'},
{'team': 'Vector', 'name': 'Nitish kumar', 'devfolio_username': 'nitishkumar072'},
{'team': 'Vector', 'name': 'Himanshu Gupta', 'devfolio_username': 'himanshugupta27'},
{'team': 'P 4 Programmers', 'name': ' Pankaj Patil', 'devfolio_username': 'Pankaj_07'},
{'team': 'P 4 Programmers', 'name': ' Prem Deshmukh', 'devfolio_username': 'Prem_18'},
{'team': 'P 4 Programmers', 'name': 'Yasharth Sonar', 'devfolio_username': 'yasharth_2143'},
{'team': 'P 4 Programmers', 'name': 'Brahmeshwar Thakur', 'devfolio_username': 'Brahmeshwar'},
{'team': ' Team NPVS', 'name': 'Shishir Dwivedi', 'devfolio_username': 'ShishirDwi'},
{'team': ' Team NPVS', 'name': 'Nivedita Srivastava', 'devfolio_username': 'niv_12'},
{'team': ' Team NPVS', 'name': 'Varun Rawal', 'devfolio_username': 'Varun2611'},
{'team': ' Team NPVS', 'name': 'Pooja Deshwal', 'devfolio_username': 'Pooja_Deshwal'},
{'team': 'Rebooters', 'name': 'Priyam', 'devfolio_username': 'Priyam_1011'},
{'team': 'Rebooters', 'name': 'Gaurav Saraiwala', 'devfolio_username': 'gaurav_dev'},
{'team': 'Voltus 2.0', 'name': 'Pranav Kulkarni', 'devfolio_username': 'pranav2905'},
{'team': 'Voltus 2.0', 'name': 'Ritish Shelke', 'devfolio_username': 'ritish_07'},
{'team': 'neohack', 'name': 'pratik patil', 'devfolio_username': 'pratik_1732'},
{'team': 'neohack', 'name': 'ambika prasad', 'devfolio_username': 'ambika_pr'},
{'team': 'Code Of Duty', 'name': 'krish Shah', 'devfolio_username': 'pskrishshah'},
{'team': 'Code Of Duty', 'name': 'Shivam', 'devfolio_username': 'ShivamTrivedi'},
{'team': 'Code Of Duty', 'name': 'Priyansh', 'devfolio_username': 'PriyanshSalian'},
{'team': 'Code Of Duty', 'name': 'Idris', 'devfolio_username': 'IdrisRatlamwala'},
{'team': 'hackers', 'name': 'devanshu', 'devfolio_username': 'devanshu170603'},
{'team': 'hackers', 'name': 'anvi', 'devfolio_username': 'anvijam'},
{'team': 'hackers', 'name': 'bhavin', 'devfolio_username': 'bhavin'},
{'team': 'hackers', 'name': 'pranjali', 'devfolio_username': 'pranjali'},
{'team': 'broCode', 'name': 'Aditya Muzumdar', 'devfolio_username': 'Adityacodes'},
{'team': 'broCode', 'name': 'Yash Chandak', 'devfolio_username': 'yashdevfolio'},
{'team': 'broCode', 'name': 'Yash Burbure', 'devfolio_username': 'yash_burbure'},
{'team': 'broCode', 'name': 'Gourav Bangad', 'devfolio_username': 'GouravB'},
{'team': 'Team ASH', 'name': 'Abhishek Shinde', 'devfolio_username': 'abhi_shinde'},
{'team': 'Team ASH', 'name': 'Arsh Maknojia', 'devfolio_username': 'ArshM17'},
{'team': 'Team ASH', 'name': 'Swarnim Kamble', 'devfolio_username': 'SwarnimSK'},
{'team': 'Team ASH', 'name': 'Himanshu Kamdi', 'devfolio_username': 'HimanshuKamdi'},
{'team': 'House Stark', 'name': 'Ronak Nikam', 'devfolio_username': '@RonakNikam'},
{'team': 'House Stark', 'name': 'Divyansh Sawant', 'devfolio_username': '@Divyansh_Sawant'},
{'team': 'OnlyHacks', 'name': 'Atharva Ashutosh Mutsaddi', 'devfolio_username': 'AtharvaMuss'},
{'team': 'OnlyHacks', 'name': 'Mohit Apte', 'devfolio_username': 'mohitapte4'},
{'team': 'OnlyHacks', 'name': 'Abhinav Kurule', 'devfolio_username': 'Abhinavk77'},
{'team': 'OnlyHacks', 'name': 'Soham Naigaonkar', 'devfolio_username': 'wizardsn'},
{'team': 'Binary Bandits', 'name': 'Rohit Lade', 'devfolio_username': 'Rohit_Lade'},
{'team': 'Binary Bandits', 'name': 'Siddhesh Kale', 'devfolio_username': 'sidk219'},
{'team': 'Binary Bandits', 'name': 'Sachin Kumar', 'devfolio_username': 'sachin2'},
{'team': 'Binary Bandits', 'name': 'Yashraj Patil', 'devfolio_username': 'yashrajpatil253'},
{'team': 'Error_404', 'name': 'Sarah Pendhari', 'devfolio_username': 'BirdiePiper'},
{'team': 'Error_404', 'name': 'Vishal Kaira', 'devfolio_username': 'Vishal Kaira'},
{'team': 'Error_404', 'name': 'Karan Pashte', 'devfolio_username': 'Karan Pashte'},
{'team': 'Shiv', 'name': 'Shivam Kumar', 'devfolio_username': 'shivamsks219'},
{'team': 'Shiv', 'name': 'Deepak Kumar Sundram', 'devfolio_username': 'dkssundram'},
{'team': 'Ctrl', 'name': 'Prateek Chaudhary ', 'devfolio_username': 'PrateekDEV'},
{'team': 'Ctrl', 'name': 'Kanishk Arya', 'devfolio_username': 'Parzival7566'},
{'team': 'Ctrl', 'name': 'Prathraj Ghatge', 'devfolio_username': 'Prath_982'},
{'team': 'Patricia', 'name': 'Aarya Kawalay', 'devfolio_username': 'AKZYU'},
{'team': 'Patricia', 'name': 'Prutha Annadate', 'devfolio_username': 'Prutha A'},
{'team': 'Patricia', 'name': 'Tanvi Devasthali', 'devfolio_username': 'Tanvi D'},
{'team': 'ABitWise', 'name': 'Ritojnan Mukherjee', 'devfolio_username': 'Ritojnan'},
{'team': 'Super Pumped', 'name': 'Anish Agarwal', 'devfolio_username': 'anishagarwal20'},
{'team': 'Super Pumped', 'name': 'Sai Chandan Kadarla', 'devfolio_username': 'chan27'},
{'team': 'Binary Beats', 'name': 'Anurag Deshmukh', 'devfolio_username': 'anuragdeshmukh'},
{'team': 'Binary Beats', 'name': 'Vedashree Kulkarni', 'devfolio_username': 'vedashree11'},
{'team': 'Binary Beats', 'name': 'Meghana Mawale', 'devfolio_username': 'meghanamawale'},
{'team': 'Aarambh', 'name': 'Srishti Acharya', 'devfolio_username': 'SRISHTISA'},
{'team': 'Aarambh', 'name': 'Manali Jadhav', 'devfolio_username': 'MANALISJADHAV'},
{'team': 'Segmentiers', 'name': 'Akshay Jadhav', 'devfolio_username': 'Akshay7670'},
{'team': 'Segmentiers', 'name': 'Sushilkumar Dhamane', 'devfolio_username': 'Sushil2002'},
{'team': 'SEGFAULTERS', 'name': 'Aditya Naik', 'devfolio_username': '112003092'},
{'team': 'SEGFAULTERS', 'name': 'Akash Sadekar', 'devfolio_username': '112003119'},
{'team': 'SEGFAULTERS', 'name': 'Kshitij Salunke', 'devfolio_username': 'Kshitij S'},
{'team': 'Coding Commandos', 'name': 'Vaibhav Garje', 'devfolio_username': 'vaibhavg20comp'},
{'team': 'Coding Commandos', 'name': 'Ritesh Choudhari', 'devfolio_username': 'RiteshChaudhari'},
{'team': 'Coding Commandos', 'name': 'Vedant Mule', 'devfolio_username': 'vsmule02'},
{'team': 'Coding Commandos', 'name': 'Adnan Sheikh', 'devfolio_username': 'Adnan22'},
{'team': 'R-Cade', 'name': 'Shubham Jain', 'devfolio_username': 'ShubhamJain02_'},
{'team': 'R-Cade', 'name': 'Ananya Sharma', 'devfolio_username': 'ananya21csu010'},
{'team': 'R-Cade', 'name': 'Arpita Samal', 'devfolio_username': 'arpita012'},
{'team': 'Team galaxy', 'name': 'Sarvesh Kulkarni', 'devfolio_username': 'Richforever'},
{'team': 'Team galaxy', 'name': 'Sohel Bargir', 'devfolio_username': 'sohelbargir'},
{'team': 'All is well', 'name': 'Sudeep M arishingodi', 'devfolio_username': 'SUDEEP_CODE_IT'},
{'team': 'All is well', 'name': 'Mahesh', 'devfolio_username': 'MAHESH'},
{'team': 'Siuu guys', 'name': 'Hrugved Hasabnis', 'devfolio_username': 'hrugvedd'},
{'team': 'Siuu guys', 'name': 'Soham Kumbhar', 'devfolio_username': 'sohamkumbhar'},
{'team': 'Siuu guys', 'name': 'Sarthak Jamdar', 'devfolio_username': 'Sarthakj02'},
{'team': 'Siuu guys', 'name': 'Pravin Chilkawar', 'devfolio_username': 'pravin01'},
{'team': 'All Is Well', 'name': 'Sudeep M Arashingodi', 'devfolio_username': 'Sudeep M Arashingodi'},
{'team': 'All Is Well', 'name': 'S Mahesh Kumar', 'devfolio_username': 'S Mahesh Kumar'},
{'team': 'Team Invictus', 'name': 'Vaishnavi Likhe', 'devfolio_username': 'vaishnavi likhe'},
{'team': 'Team Invictus', 'name': 'Swati Nandanwar', 'devfolio_username': 'Swati Nandanwar'},
{'team': 'Team Invictus', 'name': 'Ruchika Kulkarni', 'devfolio_username': 'Ruchika Kulkarni'},
{'team': 'Ravenclaw Rooks', 'name': 'Chaitrali Ginimav', 'devfolio_username': 'chaitrali7'},
{'team': 'Ravenclaw Rooks', 'name': 'Joel Alphonso', 'devfolio_username': 'jeol2253'},
{'team': 'Ravenclaw Rooks', 'name': 'Swaraj Gosavi', 'devfolio_username': 'swarajgosavi'},
{'team': 'Ravenclaw Rooks', 'name': 'Gayatri Sawant', 'devfolio_username': 'gayatrii'},
{'team': 'Jugadu_Coder', 'name': 'Takshil Rastogi', 'devfolio_username': 'Takshil123'},
{'team': 'Jugadu_Coder', 'name': 'Parikshit Dabas ', 'devfolio_username': 'pluto0096'},
{'team': 'Jugadu_Coder', 'name': 'Yajur Tayal', 'devfolio_username': 'basedsugar'},
{'team': 'Jugadu_Coder', 'name': 'Shivam Shukla', 'devfolio_username': 'SHIVAM_SHUKLA'},
{'team': 'Harcourtian', 'name': 'Rohan Kumar Singh', 'devfolio_username': 'RohanHBTU'},
{'team': 'SPD EMERGENCY', 'name': 'Atharva  Sandip Zade', 'devfolio_username': 'atharva_23'},
{'team': 'SPD EMERGENCY', 'name': 'Sanskar waghmare', 'devfolio_username': 'Sanskar221'},
{'team': 'SPD EMERGENCY', 'name': 'yashodhan hakke', 'devfolio_username': 'boeing23'},
{'team': 'SPD EMERGENCY', 'name': 'Atharv Vengatteri', 'devfolio_username': 'Nazareth'},
{'team': 'Algorithm Alliance', 'name': 'Sadik Athanikar', 'devfolio_username': 'algoalliance'},
{'team': 'Algorithm Alliance', 'name': 'Anirudh Tripathi', 'devfolio_username': 'anirudh004'},
{'team': 'Algorithm Alliance', 'name': 'Pratik Jadhav', 'devfolio_username': 'Pratik1374'},
{'team': 'Algorithm Alliance', 'name': 'Ashish Musale', 'devfolio_username': 'vishu_'},
{'team': "DOT - Don't Over Think!", 'name': 'Kausthub K', 'devfolio_username': '\xa0Kausthub_K7'},
{'team': "DOT - Don't Over Think!", 'name': 'Smruthi Rao', 'devfolio_username': 'smruthi_sumanth'},
{'team': "DOT - Don't Over Think!", 'name': 'Abhinav Naman', 'devfolio_username': 'AbhinavNaman'},
{'team': "DOT - Don't Over Think!", 'name': 'Rahul Rudra', 'devfolio_username': '_Rudra'},
{'team': 'VEDANSHI', 'name': 'DDD', 'devfolio_username': 'DDD'},
{'team': 'Team Underdogs', 'name': 'Harshal Ingale', 'devfolio_username': 'Harshal17'},
{'team': 'Team Underdogs', 'name': 'Mayur Talewar', 'devfolio_username': 'mayur_talewar'},
{'team': 'Team Underdogs', 'name': 'Aditya Suryawanshi', 'devfolio_username': 'Adityas2003'},
{'team': 'Team Underdogs', 'name': 'Soham Tak', 'devfolio_username': 'soham_21'},
{'team': 'MinGWx86', 'name': 'Sachin Jangid', 'devfolio_username': 'Sachin_Jangid21'},
{'team': 'MinGWx86', 'name': 'Prabhmeet Oberoi', 'devfolio_username': 'PrabhmeetOberoi'},
{'team': 'MinGWx86', 'name': 'Siddesh Shetty', 'devfolio_username': 'siddesh3101'},
{'team': 'MinGWx86', 'name': 'Jash Doshi', 'devfolio_username': 'compli'},
{'team': 'CureCoders', 'name': 'Gaurav Sable', 'devfolio_username': 'gaurav1104'},
{'team': 'CureCoders', 'name': 'Vaibhav Nanne', 'devfolio_username': 'VaibhavNanne'},
{'team': 'CureCoders', 'name': 'Mandar Narkhede', 'devfolio_username': 'Mandar_06'},
{'team': 'CureCoders', 'name': 'Tanmay Goregaonkar', 'devfolio_username': 'TanmayG790'},
{'team': 'Team Anonymous', 'name': 'Abhishek Bhosale', 'devfolio_username': 'AbhishekBhosale'},
{'team': 'Team Anonymous', 'name': 'Maheshwar Bhosale', 'devfolio_username': 'Maheshwar098'},
{'team': 'Team Anonymous', 'name': 'Kishanlal Choudhary', 'devfolio_username': 'kishanlal_123'},
{'team': 'Team Anonymous', 'name': 'Aditya Mahajan', 'devfolio_username': 'AdityaM2448'},
{'team': 'MinGWx128', 'name': 'Aditya Surve', 'devfolio_username': 'ssaditya2002'},
{'team': 'MinGWx128', 'name': 'Omkar Bhostekar', 'devfolio_username': 'omkarbhostekar'},
{'team': 'MinGWx128', 'name': 'Siddharth Nachane ', 'devfolio_username': 'Sidd065'},
{'team': 'MinGWx128', 'name': 'Archit Rathod', 'devfolio_username': 'Archit17'},
{'team': 'DestructiveSquad ', 'name': 'Valiveti Swamy Naga Sai Nivas', 'devfolio_username': 'NIVASV'},
{'team': 'DestructiveSquad ', 'name': 'Chiranjeevi Sagar Kommula', 'devfolio_username': 'Chiru2306'},
{'team': 'DestructiveSquad ', 'name': 'Vishwaksen Reddy Kayala', 'devfolio_username': 'vishwak_sen28'},
{'team': 'DestructiveSquad ', 'name': 'Karthykeya Guduguntla', 'devfolio_username': 'karthykeya'},
{'team': 'TechElite', 'name': 'Sahil Agrawal', 'devfolio_username': 'SahilAgr10'},
{'team': 'TechElite', 'name': 'Akshata Thorkar', 'devfolio_username': 'akshata_thorkar'},
{'team': 'TechElite', 'name': 'Ronak kolhe', 'devfolio_username': 'Ronak_14'},
{'team': 'TechElite', 'name': 'Priyanshi Kotian', 'devfolio_username': 'priyanshikotian'},
{'team': 'Bro Code', 'name': 'Kartik Mandhan', 'devfolio_username': 'kartik_CSICOEP'},
{'team': 'Bro Code', 'name': 'Vaibhav Khating', 'devfolio_username': 'vaibhav1663'},
{'team': 'Bro Code', 'name': 'Yash Suryawanshi', 'devfolio_username': 'curiyash'},
{'team': 'Bro Code', 'name': 'Shyam Randar', 'devfolio_username': 'ShyamR12'},
{'team': 'Uniders', 'name': 'Avish Khandelwal', 'devfolio_username': 'avishk12'},
{'team': 'Uniders', 'name': 'Pawan Bhandari', 'devfolio_username': 'Ninja2oo1'},
{'team': 'Uniders', 'name': 'Divanshu Singh', 'devfolio_username': 'anshi02'},
{'team': 'Uniders', 'name': 'Surbhi Yadav', 'devfolio_username': 'Surbhi_Yadav'},
{'team': 'Foursome', 'name': 'Atharva Deshmukh', 'devfolio_username': 'Atharva10'},
{'team': 'Foursome', 'name': 'Shruti Deokar', 'devfolio_username': '22shruti'},
{'team': 'Foursome', 'name': 'Vaishnavi Katkar', 'devfolio_username': 'vaishnavi717'},
{'team': 'Foursome', 'name': 'Aditya Bhosale', 'devfolio_username': 'adityabhosale'},
{'team': 'TechHackers', 'name': 'Apurva Deshpande', 'devfolio_username': 'avd151'},
{'team': 'TechHackers', 'name': 'Divya Gaygol', 'devfolio_username': 'gaygoldg'},
{'team': 'TechHackers', 'name': 'Pooja Gulhane', 'devfolio_username': 'Poojagulhane'},
{'team': 'TechHackers', 'name': 'Sakshi Mehetre', 'devfolio_username': 'Sakshi15'},
{'team': 'Out Of Focus', 'name': 'Gokulnath R', 'devfolio_username': 'Gokul123'},
{'team': 'Out Of Focus', 'name': 'Sanjeev Kumar K S', 'devfolio_username': 'Sanjeevkumarks'},
{'team': 'Out Of Focus', 'name': 'Om Ganesh S', 'devfolio_username': 'Omganesh'},
{'team': 'HackElite', 'name': 'Rahul Gandla', 'devfolio_username': 'RahulGandla'},
{'team': 'HackElite', 'name': 'Moiz Ghansar', 'devfolio_username': 'mg123'},
{'team': 'HackElite', 'name': 'Arya Dubal', 'devfolio_username': 'arya1883'},
{'team': 'Celestial Cookies', 'name': 'Sanskriti Agarwal', 'devfolio_username': 'sanskriti27'},
{'team': 'Celestial Cookies', 'name': 'Pooja Gera', 'devfolio_username': 'poojagera0_0'},
{'team': 'Celestial Cookies', 'name': 'Gaurisha R Srivastava', 'devfolio_username': 'gaurisha'},
{'team': 'Celestial Cookies', 'name': 'Karan Taneja', 'devfolio_username': 'karantaneja01'},
{'team': 'Coding FC', 'name': 'Suraj Das', 'devfolio_username': 'surajdas'},
{'team': 'Coding FC', 'name': 'Shreyas Mekhe', 'devfolio_username': 'Shreyas_mekhe'},
{'team': 'Coding FC', 'name': 'Anshul Satbadre', 'devfolio_username': 'kody'},
{'team': 'Hustlers', 'name': 'Hasan Kamran', 'devfolio_username': 'kamran01'},
{'team': 'Hustlers', 'name': 'Rimendra Kumar Agrawal', 'devfolio_username': 'RimendraAgrawal'},
{'team': 'Hustlers', 'name': 'Shreyansh Kumar', 'devfolio_username': 'Shreyansh8184'},
{'team': 'Hustlers', 'name': 'Siddharth Pandey', 'devfolio_username': 'PensPencil'},
{'team': 'BlackSwan73', 'name': 'Sushant Kadam', 'devfolio_username': 'darkhorse73'},
{'team': 'BlackSwan73', 'name': 'Pratik Patil', 'devfolio_username': 'ppatil02'}]
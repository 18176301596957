import React from "react";

const nikhil_kokale = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Nikhil Balasaheb Kokale
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Trading Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            Aptitude + Technical interview
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            There were three categories for aptitude: Java Developer, C++ Developer, and Frontend Developer, and we had to select one among them. I chose C++ Developer, which consisted of 10 MCQs based on the basics of C++ and OOP, and one coding question. The duration of the aptitude test was one hour.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There was only one round. The interview started with an introduction, and I was asked to explain one of my projects. They also asked follow-up questions about the project. Then, they moved on to basics of C++ topics, such as pointers, unordered and ordered maps, and templates. They also asked about OOP concepts in C++, including abstraction, virtual classes, and inheritance. To crack the interview, deep knowledge of C++ is required.
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After the aptitude test, 9 students were shortlisted, and 2 students received the final offer.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        GFG,Leetcode for DSA <br />
                        450 Love babbar sheet / Striver SDE sheet <br />
                        Indiabix for aptitude <br />
                        Codehelp videos for OS and DBMS.
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practice and consistency are key. Try to solve at least one question daily.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            2-3 questions were asked about project which I mentioned in my resume.No questions were asked regarding clubs and extracurricular activities.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            You should have a strong knowledge of C++. Sometimes, advanced C++ topics are also asked.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            The internship process may be stressful, so trust the process and always remember that the best is yet to come.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:kokalenb21.comp@coeptech.ac.in">
                            kokalenb21.comp@coeptech.ac.in
                        </a>
                        <br />

                        LinkedIn : <a href="https://www.linkedin.com/in/nikhil-kokale-a5b506250/">Nikhil Kokale</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default nikhil_kokale;

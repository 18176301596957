import React from "react";

const vaishnavi_mulik = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Vaishnavi Mulik
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Siemens</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There were 2 rounds:
                            <ol>
                                <li>
                                    Aptitude round.
                                </li>
                                <li>
                                    Technical + HR round
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The online aptitude test on the Mercer Metti platform lasted for one hour.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ol>
                            <li>
                                First Round - Aptitude test: <br />
                                About 10 Questions for each of Quants,English and logical Questions were asked. 25 Computer Fundamentals Questions were asked.
                            </li>
                            <li>
                                Second Round - Technical+HR round: <br />
                                The interviewer asked about the skills listed on my resume and the subjects I enjoyed most. They then delved into my projects, asking about the specific technologies and tools (tech stack) I used, as well as the detailed process I followed to complete each project.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Around 50 students were shortlisted for Aptitude Test <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                After 1st Round - 13
                            </li>
                            <li>
                                After 2nd Round - 10
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                Love babbar playlist on os,cn
                            </li>
                            <li>
                                For recursion - aditya verma playlist
                            </li>
                            <li>
                                Striver’s SDE sheet
                            </li>
                            <li>
                                450 love babbar Questions
                            </li>
                            <li>
                                Aptitude Questions on indiabix
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Focus on quality of questions than quantity but if you feel you are not good at a topic then solve more questions on that topic.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Mostly questions were asked on the basis of resume.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Be ready to verbally explain your projects in detail, including the entire process. Have a strong grasp of computer networks and operating systems.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Practice regularly, take mock tests, and keep a diary to note and improve on mistakes.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:vaishnavimulik25@gmail.com">
                            vaishnavimulik25@gmail.com
                        </a>
                        <br />
                    </div>
                </div>
            </section >
        </div >
    );
};

export default vaishnavi_mulik;

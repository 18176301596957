import React from "react";

const vaishnavi_pabalkar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Vaishnavi Somnath Pabalkar
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />
          <p>
            <b>
              1. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b> <br />
            There were 4 rounds
            <ol>
              <li>
                <p>
                <b>Online Aptitude test:</b> <br />
                <p>
                  It consisted of MCQs based on general knowledge, programming basics, aptitude (quantitative, graph), finance basics, comprehension etc
                </p>
                </p>
              </li>
              <li>
                <b>Offline Written Test</b> <br />
                  The test consisted of 4 questions of which we had to solve any 3
                <ol>
                  <li>
                    If you were given 10,000 (some number) Rs. by the govt. how will you spend it for the development of your village.
                  </li>
                  <li>
                    Prepare an advertisement for collecting used school textbooks from the students. Government provided some funds for the activity. So basically a creative task.
                  </li>
                  <li>
                    You have to recruit 10 students from a college of 10,000 students what will be the hiring procedure?
                  </li>
                  <li>
                    Coding question: Write a code to print a index like a textbook. Where the chapter numbers are in same indentation, sub topics indented, further subtopics again indented. Basically, like a word document. <br />
                    Eg. 1. <br /> Chapter <br /> 1.1  Topic <br /> 1.1.1 Sub topic
                  </li>
                </ol>
              </li>
              <li>
                <b>Group Discussion:</b> <br />
                A group of 6-8 people was given a topic and we had to discuss in 7 min.
                Prepare general trending topics for GD mostly related to technology
              </li>
              <li>
                <b>Interview:</b> <br />
                The technical and the HR round was not held separately. HR questions
                were asked in the technical interview itself. The interviewers were a panel of 3 people. Sufficient depth questions were asked on projects and skills mentioned on resume. DSA questions were asked and had to write pseudo code on paper.
              </li>
            </ol>
          </p>
          <p>
            <b>2. How many students were shortlisted after each round?</b>
            <p>
            About 76 were selected for the written test after clearing the aptitude test. From that 40 were selected for group discussion. 24 were selected for Interview and finally 14 students got the offer.</p>
          </p>
          <p>
            <b>
              3. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <p>
            Lot of questions were asked on the projects that I had put on my resume, so make sure to put quality projects and the projects you have done well. There were very much interested in asking questions based on extracurricular activities.  </p>
          </p>

          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              <ul>
                <li>
                Geekforgeeks for coding 
                </li>
                <li>
                Indiabix for aptitude
                </li>
                <li>
                Tutorialspoint for subject wise preparation like DBMS, DSA, etc
                </li>
              </ul>
            </p>
          </p>

          <p>
            <b>5. Some tips to crack the coding questions.</b> <br />
            <p>
            Very few coding questions were there in the test, but there very good questions related to coding in the interview. Prepare from geekforgeeks and revise DSA concepts. That should be enough.
            </p>
          </p>
          <p>
            <b>
              6. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
            The tests are mostly easy. They need people with knowledge, creativity as well as people who are into extra curricular activities. So make sure to show all your sides technical as well as non technical
            </p>
          </p>
          <p>
            <strong>7. Final Tip for Juniors :</strong>
            <p>
            Be confident. Keep practicing coding questions everyday. Do the subjects in curriculum very well. As it’s the first internship or work experience, don’t be company specific or don’t look for hefty stipends, just make sure you land somewhere where you’ll get a lot of hands on work experience in your favorite domain. Don’t forget to carry a copy of resume, a blank page, a pen and most importantly your confidence! :)
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID : <a href="mailto:pabalkarvs20.comp@coep.ac.in">
              pabalkarvs20.comp@coep.ac.in
            </a>
            <br />
            LinkedIn : <a href="www.linkedin.com/in/vaishnavi-somnath-pabalkar">Vaishnavi Pabalkar</a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default vaishnavi_pabalkar;

import React from "react";

const vishwesh_pujari = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Vishwesh Pujari
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>BNY Mellon</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />
            The interviews were scheduled on 29th August 2022. At 9 AM the Pre-Placement Talk was held in the Mini Auditorium, and the shortlist for interviews was declared at 10:45 AM. The interviews began at 11 AM. There were three interview rounds. Two technical interviews and one HR/Techno-HR interview. All interviews were held in offline mode in the placement cell on the same day.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>There were four coding questions in the aptitude round. Aptitude Round was held in online mode on Hackerrank on 27th August 2022.</p>
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            There were a total of four rounds. One aptitude round, two technical interviews, one Techno-HR interview.
            <ol>
              <li>
                Aptitude Round - Online, 27th August 2022, 6 PM
                <ul style={{paddingLeft:"40px", listStyle:"disc"}}>
                  <li>
                    There were 4 coding Questions to be solved in 90 minutes.
                  </li>
                  <li>
                    1st Question: 
                    <p style={{paddingLeft:"40px"}}>
                    An array of integers ‘arr’ is given. Given a function U such that: <br />
                    Consider 1-based indexing <br />
                    U = arr[1] * arr[2] * (1 / arr[3]) * arr[4] * …. * (1 / arr[n]) (when n is even) <br />
                    U = arr[1] * arr[2] * (1 / arr[3]) * arr[4] * ….. * (1 / arr[n – 1]) * arr[n] (when n is odd) <br />
                    You can rearrange the array such that the above function U is maximized. Return the array having the minimum ordering. <br />
                    Definition of minimum ordering: [1,2,3] is said to have a lesser ordering than [1,3,2]. <br />
                    </p>
                  </li>
                  <li>
                    2nd Question: 
                    <p style={{paddingLeft:"40px"}}>
                    Don’t remember the exact problem. I couldn’t understand much after reading the problem statement. However, after observing the test cases, I found out that only sorting needed to be done and adding the maximum out of each column. <br />
                    Input: array of strings, each character of string is a digit <br />
                    “231” <br /> “713” <br /> “876” <br />
                    Sort each string independently, add the maximum from each column. <br />
                    Solved all test cases.
                    </p>
                  </li>
                  <li>
                    3rd Question: Trigonometry. 
                    <p style={{paddingLeft:"40px"}}>
                    Given 3 vertices of a triangle and 2 Points P1 and P2: <br />
                    Return 0 if triangle is degenerate (cannot be formed using 3 vertices of triangle) <br />
                    Return 1 if P1 lies in the triangle but P2 doesn’t. <br />
                    Return 2 if P2 lies in the triangle but P1 doesn’t. <br />
                    Return 3 if both P1 and P2 lie in the triangle. <br />
                    Return 4 if both P1 and P2 don’t lie in the triangle. <br />
                    Solved 17/50 test cases. <br />
                    Some useful links: <br />
                    <a href="https://www.tutorialspoint.com/possible-to-form-a-triangle-from-arrayvalues-in-cplusplus#:~:text=Non%2Ddegenerate%20triangle%20%E 2%88%92%20it%20is,%2C9%2C%204%2C%203%5D">
                      https://www.tutorialspoint.com/possible-to-form-a-triangle-from-arrayvalues-in-cplusplus#:~:text=Non%2Ddegenerate%20triangle%20%E 2%88%92%20it%20is,%2C9%2C%204%2C%203%5D</a>
                    <a href="https://www.geeksforgeeks.org/check-whether-a-given-point-lies-insi de-a-triangle-or-not/">https://www.geeksforgeeks.org/check-whether-a-given-point-lies-insi de-a-triangle-or-not/</a>
                    </p>
                  </li>
                  <li>
                    4th Question: Binary Strings 
                    <p style={{paddingLeft:"40px"}}>
                    Given an array of integers and an integer k. First convert the array of integers into binary strings. However, the length of each binary string must be equal to k. eg: if k = 2, then the binary string of 9 is 01. (9 = 1001, but as k=2, consider the last 2 bits). For every binary string, replace all combinations of 0s into 1s. Return the count of unique binary strings. <br />
                    Eg. For binary string 10010 We can replace 0s to 1s in this manner: 11010, 10110, 10011, 11110, 10111, 11111 <br />
                    Do the above for all the binary strings. Return the count of such unique strings. Didn’t solve the above problem. It can easily be solved using backtracking. <br />
                    </p>                    
                  </li>
                </ul>
              </li>
              <li>
                First Technical Interview: Offline, 29 August 2022, 1 PM
                <ul style={{paddingLeft:"40px", listStyle:"disc"}}>
                  <li>
                    It was taken by the Data Engineering Lead at BNY Mellon. She was very friendly. Firstly, while she was going through my Resume, she asked me to tell a brief about me and my skills.
                  </li>
                  <li>
                    She said that she will ask questions from all the topics that I have studied in Computer Science.
                  </li>
                  <li>
                    Firstly, she said that she will ask about DSA. She gave me a paper and pen and asked me to write on it while explaining concepts and code to her.
                    <ol>
                      <li>
                        What are Tries? How are they represented in memory? Give an example where Tries are used.
                      </li>
                      <li>
                        Explain Bubble Sort and Quicksort. Explain the difference between them.
                      </li>
                      <li>
                        Which is the best sorting algorithm (in terms of both time and space)? How can we improve the runtime of quicksort algorithm?
                      </li>
                      <li>
                        Have you written a program where there is a combination of 2 or more sorting algorithms used?  I said no, but the library sort functions contain a combination of 2 or more sorting algorithms.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Then she moved to DBMS:
                    <ul>
                      <li>
                        What is normalization? Explain it with examples
                      </li>
                      <li>
                        Gave me a table with 100+ columns. Asked me how you would normalize it.
                      </li>
                      <li>
                        What is an ER Diagram? Why do we need an ER diagram?
                      </li>
                    </ul>
                  </li>
                  <li>
                    Then she moved to my internship experience. I had worked on Spring Boot and MongoDB in my internship:
                    <ul>
                      <li>
                        What is the difference between MVC and Microservices? Explain what microservices are.
                      </li>
                      <li>
                        NoSQL vs SQL
                      </li>
                      <li>
                        What are the drawbacks of NoSQL?
                      </li>
                    </ul>
                  </li>
                  <li>
                    I had mentioned Python3 in my skills. So, she asked which python libraries you have used so far.
                  </li>
                  <li>
                    She asked me to write a code on paper in any programming language: Find whether n lies in the Fibonacci series or not.
                  </li>
                  <li>
                    Then she asked if I had any Questions for her: I asked her about her role in BNY and about the S.E.T.U.P program in BNY.
                  </li>
                  <li>
                    The round lasted for 35-40 mins.
                  </li>
                </ul>
              </li>
              <li>
                Second Technical Interview: Offline, 29 August 2022, 4:15 PM
                <ul style={{paddingLeft:"40px", listStyle:"disc"}}>
                  <li>
                    There were two interviewers in this round. One of them was impressed with the feedback of my first technical round. Asked me how much I would rate myself in DBMS.
                  </li>
                  <li>
                    Asked me about one of the german scholarships which I had received as I had mentioned it in my resume.
                  </li>
                  <li>
                    Then the second interviewer asked me about OOP concepts:
                    <ul style={{paddingLeft:"40px", listStyle:"circle"}}>
                      <li>
                        What are OOPs?
                      </li>
                      <li>
                        What are the four pillars of OOPs?
                      </li>
                      <li>
                        Explain each pillar by giving real life examples.
                      </li>
                      <li>
                        Tell difference between Abstraction and Encapsulation
                      </li>
                      <li>
                        What is Overloading? Write code for overloading (In any language – C++ or Java)
                      </li>
                      <li>
                        What is Overriding? Write code for overriding.
                      </li>
                      <li>
                        Compile Time and Runtime polymorphism
                      </li>
                    </ul>
                  </li>
                  <li>
                    Then he asked a DSA Question
                    <ul style={{paddingLeft:"40px", listStyle:"circle"}}>
                      <li>
                        Find the second maximum in an array.
                      </li>
                      <li>
                        Find kth maximum in an array. (First, I gave O(n*k). Just as in finding the second maximum we write 2 loops, similarly we can run the loop k times. Then I gave him the sorting approach: O(n*logn). He wasn’t satisfied. Then I gave the Min-Heap approach O(n*logk).
                      </li>
                    </ul>
                  </li>
                  <li>
                    Then he went through my internship experience. Asked me about SpringBoot.
                  </li>
                  <li>
                    He asked whether I had any questions for them. I asked what kind of projects the freshers would be working on.
                  </li>
                  <li>
                    The round lasted for 45-50 mins.
                  </li>
                </ul>
              </li>
              <li>
                Third Techno-HR interview: Offline, 29th August, 6:15 PM
                <ul style={{paddingLeft:"40px", listStyle:"disc"}}>
                  <li>
                    My interviewer asked 90% of technical questions in the HR round.
                  </li>
                  <li>
                    First, he asked Question on DBMS:
                    <ul style={{paddingLeft:"40px", listStyle:"circle"}}>
                      <li>
                        Write a SQL query. He gave me a table schema which contains columns like: Employee ID, Employee Name, Manager ID. The Manager ID was like a foreign key to the Employee ID. For every employee, I had to print in a single row: The employee ID, Employee Name, Manager’s name. So, I gave him the approach of joining the table with itself and selecting the appropriate column.
                      </li>
                      <li>
                        Then he asked me what’s the technical term for joining the table with itself – self join.
                      </li>
                      <li>
                        Then he asked me what outer joins are. (Left and right)
                      </li>
                    </ul>
                  </li>
                  <li>
                  Then he asked me some questions on Linux (probably because there was Linux mentioned on my resume in one of the projects) which I was unable to answer:
                  <ul style={{paddingLeft:"40px", listStyle:"circle"}}>
                    <li>
                      What is the nohup command on Linux?
                    </li>
                    <li>
                      How to set the environment variable in the shell, so that all the child shells of the current shell will be able to use it?
                    </li>
                  </ul>
                </li>
                <li>
                  What is a zombie process?
                </li>
                <li>
                  What is a garbage collector in Java?
                </li>
                <li>
                  Then he asked me whether I have received any Memory related errors (eg. segmentation faults) during my coding journey. Also asked me how I solved them. I said that during my bc project, I faced a lot of Seg Faults. And I said that I used gdb to solve them.
                </li>
                <li>
                  Then he said: why only gdb? Why not other tools : GNU, Open source.
                </li>
                <li>
                  Then he read my internship experience and read MongoDB. Then he asked what kind of database mongodb is.
                </li>
                <li>
                  What are the drawbacks of using MongoDB over SQL?
                </li>
                <li>
                  He asked me to tell an application where mongodb would be a more suitable database than SQL: I told the example of my personal project: Quora for College.
                </li>
                <li>
                  Then he asked me what my ranking or score is on platforms like hackerrank, leetcode and codechef.
                </li>
                <li>
                  What do you know about BNY Mellon?
                </li>
                <li>
                  Tell an incident in your life where you led a team and how you went about doing it.
                </li>
                <li>
                  What fascinates you about technology?
                </li>
                <li>
                  What are your interests? ML, AI, or anything else?
                </li>
                <li>
                  Have you worked on Cloud technologies like Azure, etc.?
                </li>
                <li>
                  The round lasted for more than 50 mins.
                </li>
                </ul>                
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            30 Students were shortlisted after the Aptitude Round. All 30 students faced both the technical interviews. 13 students were shortlisted for the HR interview. All 13 students received the final offer.
          </p>

          <p>
            <b>
              5. Some good resources you used in your Placement prep
            </b>{" "}
            <br />
            <ul style={{paddingLeft:"40px", listStyle:"disc"}}>
            <li>https://leetcode.com/</li>
            <li>https://takeuforward.org/interviews/strivers-sde-sheet-top-coding-interview-problems/ </li>
            <li> https://www.youtube.com/c/AdityaVermaTheProgrammingLord/playlists</li> 
            <li>Especially the Dynamic Programming Playlist.</li>
            <li>https://takeuforward.org/interviews/must-do-questions-for-dbms-cn-os-interviewssde-core-sheet/ </li>
            <li>https://www.indiabix.com/</li>
            </ul>
          </p>
          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <ol style={{ paddingLeft: "40px" }}>
              <li>
                Regular Practice. Solve daily problems on leetcode, practice.geeksforgeeks.com. Participate in leetcode contests.
              </li>
              <li>
                If you don’t know the most optimal solution to a problem, code up a brute force solution and try to pass some test cases.
              </li>
              <li>
                Thinking about edge cases of a problem can quickly help you pass one or two test cases.
              </li>
              <li>
                The coding problems are pitched to you in the form of a story. Try to extract the exact DSA problem out of it quickly. Sometimes stories try to mislead you to the wrong solution.
              </li>
            </ol>
            <br />
          </p>

          <p>
            <b>
              7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume?
            </b>
            <br />
            <p >One question about clubs/extracurricular activities was asked to me in my second and third interview. Even if questions aren’t asked, those activities are noticed by the interviewers and are a plus point. My interviewers didn’t focus much on my projects, however some of my friends were asked in-depth questions about their projects. So, it totally depends on your interviewer.
            </p>
            <p>
              <b>
                8. Is there something specific that you would want to tell people who try for the
                same company?
              </b>
              <br />
              <ul style={{paddingLeft:"40px", listStyle:"disc"}}>
                <li>
                  Questions in interviews totally depend upon the interviewer. Even if I wasn’t asked hard DSA questions on DP, trees, etc, some of my friends were asked those questions. Be prepared.
                </li>
                <li>
                  Knowing Java is a plus
                </li>
                <li>
                  Almost every interviewer asks about DBMS and SQL queries.
                </li>
                <li>
                  Having internship experience is a plus.
                </li>
                <li>
                  Online coding competitions on leetcode, codeforces, hackerrank is a plus.
                </li>
                <li>
                  Depending upon the interviewer, s/he can ask in depth questions on projects and internships. Some of my friends were even asked to code out a small part of the project and internship on paper.
                </li>
                <li>
                  Will touch upon multiple subjects like DSA, OS, DBMS, OOPs rather than going into extreme depth of one of them.
                </li>
              </ul>
            </p>
          </p>
          <p>
            <b>
              9.Final Tip for Juniors
            </b>
            <ol>
              <li>
                Focus on your English communication skills. The technical knowledge which cannot be expressed effectively to the interviewer is of no use.
              </li>
              <li>
                Efforts, Skills, Intelligence may not be directly proportional to the placement which you will get. A fair amount of luck is involved in the placement process. Taking efforts and being resilient is the only thing in our control.
              </li>
              <li>
                Give proper thought while creating your resume. Write only those projects and technical skills about which you are confident that you will be able to answer most questions.
              </li>
              <li>
                Practice mock interviews with your friends.
              </li>
              <li>
                Prepare a list of possible questions which you might be asked about your projects, internship, HR round etc.
              </li>
              <li>
                Be mentally and physically healthy.
              </li>
              <li>
                Avoid using negative phrases during an interview. If you don’t know the exact answer to any question, make sure you explain whatever you know about the answer.
              </li>
              <li>
                Don’t hesitate to ask any questions during the PPT of any company. Get all your doubts cleared regarding CTC, In-hand salary, Role, etc.
              </li>
            </ol>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email ID : <a href="mailto:pujarivv19.comp@coep.ac.in">
              pujarivv19.comp@coep.ac.in
            </a>
            <br />
            LinkedIn ID : <a href="https://www.linkedin.com/in/vishwesh-pujari-7205391b6/">Vishwesh Pujari</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default vishwesh_pujari;

import React from "react";

const vrinda_ahuja = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Vrinda Ahuja</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>
              1. What was the structure of the entire internship hiring process?
            </b>
            <br />
            The internship hiring process was in 2 phases: <br />{" "}
            <i>Aptitude Test </i> {"&"} <i>3 Interview Rounds</i>.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            It was an online aptitude test and consisted of questions from
            various sections like <br />
            <li>
              {" "}
              <b>Quantitative Section</b> - MCQs on Maths {"&"} Logical
              Reasoning.
            </li>
            <li>
              {" "}
              <b>Computer Science Concepts (Technical Section)</b> - MCQs on
              Concepts of DBMS, CN, Data Structures, and Basic Programming.
            </li>
            <li>
              <b>2 coding sections (Basic {"&"} Advanced)</b> - Basic had 2
              coding questions and Advanced had a single coding question.{" "}
            </li>
            <li>
              <b>English</b> - 2 essays.
            </li>
            There was a time limit for the complete aptitude test as well as a
            time limit for each section as well.
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />I had 3 interview rounds for the company. Two of them were
            completely technical and one was a mixture of Technical and HR.
            <br />
            <br />
            <i>First Interview</i> <br />
            The interviewer gave me 2-3 problem statements for live coding. I
            explained to him my approach for the same, firstly a basic approach
            and then an optimized one. After that, he asked some more questions
            on algorithms, dynamic programming, data structures, and unit
            testing.
            <br />
            <br />
            <i>Second Interview</i> <br />
            In this round the first 15-20 minutes, the interviewer asked me
            questions based on my resume like on the courses I had mentioned,
            the projects I did, and also on the skills I added. He also asked me
            to run one of my projects, and luckily I had it deployed so I showed
            him that. After that, he gave me a single live coding question and
            it was related to the optimization of some array manipulation. At
            first, I couldn’t figure out what exactly had to be done and told
            him my approach. He then me some hints and I was able to write a
            pseudo code for the same. After this, he asked me many more
            questions about various programming concepts, OOPs, C/C++
            programming, and algorithms.
            <br />
            <br />
            <i>Third Interview</i>
            <br /> This was a mixture of HR and Technical Round. First, he asked
            me some basic HR questions like describing myself, Why did I want to
            be at GS, etc. After that, he gave me a technical problem scenario
            to solve. It was related to DBMS and I was not so sure about how
            could it be done so I explained to him all of my approaches and he
            counter questioned almost all of them and he then explained it to
            me. I was close to the answer so he said it was okay since we were
            unaware of those concepts then.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            Quite many, as I mentioned my second Interview’s first 15-20 minutes
            were based on my resume itself.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />I used GFG for brushing up data structures and algorithms
            concepts, HackerRank to solve problems of DSA which I studied from
            GFG, Indiabix, and RS Aggrawal for the Logical Reasoning and
            Quantitative part.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Try to write a basic code first which can clear some test cases and
            then try to check for corner cases and optimization to clear more
            test cases.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            In the interviews, try to tell the interviewer whatever you’re
            thinking and what is the approach you are trying to take even if
            you’re not able to solve the question completely so that they can
            understand it and guide you and evaluate you. And Communicate with
            Confidence, they really take this into consideration.
          </p>

          <p>
            <br />
            <i>
              All The Best! Try not to take a lot of stress during this
              process:)
            </i>
            <br />
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:vrindava18.comp@coep.ac.in">
              vrindava18.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/vrinda-ahuja-01960216b/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default vrinda_ahuja;

import React from "react";

const nidhi_patel = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Nidhi Patel</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Hilti Technology Solutions
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              <ol>
                <li>Technical Round</li>
                <li>HR Round</li>
              </ol>
            </p>
          </p>

          <p>
            <b>2. Structure of Aptitude Round :</b>
            <br />6 sections - CO, CN, DBMS, OOPs, Analytical Ability, Coding
            Skills (1 coding question)
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              <ol>
                <li>
                  The assessment included the sections stated above. 30 minutes
                  were allotted for the analytical section; the other sections
                  were free navigation.
                </li>
                <li>
                  The technical interview was focused on projects and
                  elaboration of concepts used in those projects.
                </li>
                <li>
                  he HR interview consisted of the usual questions such as my
                  weaknesses, my hobbies, why I chose to study engineering and
                  why COEP, along with some additional questions about the
                  extracurricular activities included on my resume.
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>4. Some good resources you used in your internship prep</b>
            <p>
              For coding practice, leetcode is one of the best platforms.
              Another very helpful platform is Hackerrank
              <br />
              Regarding the CO, CN, OOPs and DBMS concepts, I referred to
              several Youtube videos and GFG to clear the basics
            </p>
          </p>

          <p>
            <b>5. Some tips to crack coding questions</b>
            <br />

            <p>
              Practice on leetcode is sufficient for the coding test. Stick to
              one coding language and excel in that.
              <br />
              The coding question in the online assessment was easy to medium
              level difficulty
              <br />
              The question that I got was: Mr. Myers and the Exam:
              <br />A mathematics question paper has a certain number of
              questions and each question is assigned some random maximum marks.
              Mr. Myers wants to edit the marks assigned to the questions such
              that:
              <ol>
                <li>
                  All questions in the paper should have distinct maximum marks
                </li>
                <li>
                  The total marks of all the questions should be as low as
                  possible.
                </li>
              </ol>
              Mr. Myers wants to achieve this by making minimal changes in the
              original format, assigning the question at least as much marks as
              it originally had. Find the minimum total marks that he can set
              the paper for.
            </p>
          </p>

          <p>
            <b>
              6. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>{" "}
            <br />
            <p>
              The majority of my technical interview comprised of questions on
              my projects and related concepts. Try to explain what the
              objective and conclusion of your projects are. I was also asked
              around 3-4 specific questions about the DSA and OOPs part of my
              projects.
              <br />
              <b>DSA</b>
              <ol>
                <li>
                  What are heaps in dsa? (since one of my projects used heaps
                  data structure)
                </li>
              </ol>
              <b>OOPS</b>
              <ol>
                <li>What are the 4 pillars of OOPS and define all:</li>
                <li>
                  {" "}
                  What do you know about the diamond problem in hybrid
                  inheritance?(since i listed the types of inheritances while
                  defining it)
                </li>
              </ol>
              A general tip: Prepare a few popular interview questions, such as
              "introduce yourself". These responses should correspond to the
              projects and experiences shown on your Resume
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              Take your time with each term you want to put in your resume. I'd
              also want to suggest that you conduct some research about the
              company before the interviews. It reflects your interest in the
              company and your desire to be a part of it. Inquiring about the
              company's technological trends shows your genuine intrigue
            </p>
          </p>
          <p>
            <b>8. How many students were shortlisted after each round ?</b>
            <p>
              After the 1st aptitude test, <b>26</b> people were shortlisted for
              the interview rounds. Finally <b>5</b> students were selected for
              the internship.
            </p>
          </p>
          <p>
            <strong>9. Final Tip for Juniors?</strong>
            <p>
              It is critical to communicate clearly and confidently. <br /> When
              asking technical questions, they are looking at your approach to
              solving given problems, so not need worry about whether or not the
              solution is correct.
              <br />
              Rejection can be demotivating, but view it as a learning
              experience. Attempt to analyze and improve on your mistakes. Focus
              on upcoming opportunities.
              <br />
              <br />
              Wishing you all the best!!
            </p>
          </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:nidhip20.comp@coep.ac.in">
              nidhip20.comp@coep.ac.in
            </a>
            <br />
            LinkedIn:{" "}
            <a href="https://www.linkedin.com/in/nidhi-patel02">Nidhi Patel</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default nidhi_patel;

import React from "react";
import {
  makeStyles,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import SectionHeading from "../components/SectionHeading";
import comp from "../utils/faq";
import { backGround } from "../layout/customStyle";
import "./css/faq.css";
import { TiWarningOutline } from "react-icons/ti";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    marginTop: "5rem",
    width: "100vw",
  },
  headingRoot: {
    position: "relative",
    padding: "2em 0",
    marginTop: "5em",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
    },
  },
  title: {
    position: "relative",
    marginBottom: "20px",
    paddingBottom: "0",
    fontSize: theme.typography.pxToRem(32),
    fontWeight: theme.typography.fontWeightBold,
    color: "#ffffff",
    textTransform: "uppercase",
    zIndex: "2",
    [theme.breakpoints.down("sm")]: {
      textShadow: "2px 3px #865c5c",
    },
  },
  backSplash: {
    position: "absolute",
    top: "30px",
    left: "0",
    right: "0",
    fontSize: "3em",
    fontWeight: "700",
    color: "#4a739760",
    textTransform: "uppercase",
    zIndex: "1",
    lineHeight: "0",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  expansionHeading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#0056b3",
  },
  number: {
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.typography.pxToRem(10),
    flexShrink: 0,
    color: "#e83e8c",
  },
}));

export default function FAQPage() {
  const classes = useStyles();
  return (
    <main style={backGround}>
      <Navbar faq={true} />

      <SectionHeading title="Internship Queries" />

      <section className="container mb-4">
        {comp.map((el, i) => {
          let id = i + 1;
          return (
            <ExpansionPanel
              style={{ backgroundColor: "#000" }}
              className="mt-1 mb-3"
              id="faqBox"
              key={`comp-faq-${i}`}
            >
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon color="secondary" fontSize="large" />
                }
                aria-controls={`answer-${id}`}
                id={`question-${id}`}
                style={{ color: "#ccc" }}
              >
                <Typography className={classes.number}>
                  {id < 10 ? "0" + id : id}
                </Typography>
                <Typography className={classes.heading}>
                  {el.question}
                </Typography>
              </ExpansionPanelSummary>
              <hr style={{ boxShadow: "0 0 4px #ccc" }} />
              <ExpansionPanelDetails>
                <Typography style={{ color: "#ccc" }}>{el.answer}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
        <div style={{ padding: "2rem" }}>
          <h2
            style={{
              textAlign: "center",
              color: "#FEE715FF",
              // fontFamily: "Varela Round",
              fontSize: "2.4rem",
              marginBottom: "0.75rem",
            }}
          >
            <TiWarningOutline style={{ fontSize: "3.5rem", margin: "1rem" }} />
            Disclaimer
          </h2>
          <h6
            style={{
              textAlign: "center",
              color: "white",
              // fontFamily: "Varela Round",
              fontSize: "1.3rem",
            }}
          >
            <i>
              This is to inform readers that the views, thoughts, and opinions
              expressed here belong solely to the author, and not necessarily to
              the author's employer, organization, committee or another group or
              individual.
            </i>
            <p
              style={{
                marginTop: "1.5rem",
              }}
            >
              &copy; CSI COEP
              <br />
              Date Published: 23<sup>rd</sup> April 2020
            </p>
          </h6>
        </div>
      </section>

      <Footer />
    </main>
  );
}

import React from "react";

const yadnyadeep_khadke = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Yadnyadeep Nitin Khadke
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Oracle</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ol>
              <li>Aptitude and Coding Test</li>
              <li>Technical Interview 1</li>
              <li>Technical Interview 2</li>
              <li>Technical Interview 3 ( somewhat similar to HR )</li>
            </ol>
            <b>Structure of Aptitude Round:</b>
            <br />
            Total 10 questions for 1:15 hours on HackerRank Platform, which were
            broken up as follows:
            <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
              <li>
                2 Coding questions: One question was based on Dynamic
                Programming, Graph and Set Theory and involved some mathematical
                properties. Other type of question was to perform HTTP Get
                request to API server and take problem statement and data from
                the server.
              </li>
              <li>
                1 DBMS question: Relation Schema and Relation instances were
                given, we had to write one query for given problem statement.
                The problem statement was of moderate difficulty.
              </li>
              <li>
                7 Questions Multiple Choice Questions: Moderate to high
                difficulty. Numerical based on Operating System and Computer
                Network. Removing analogies in DBMS. Output of Code Snippet:-
                OOPS based, Pointer based problems
              </li>
            </ul>
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <ol>
              <li>
                Technical Interview 1 ( 50-55 minutes)
                <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                  <li>TCP vs UDP, daily life Application</li>
                  <li>Internet Protocol Architecture</li>
                  <li>Building of Routing and Switching Table</li>
                  <li>
                    Four Principles of OOP and difference between Abstract
                    Classes and Interfaces
                  </li>
                  <li>
                    2 Coding questions ( implemented in Notepad and later to
                    share in chats )
                  </li>
                </ul>
              </li>
              <li>
                Technical Interview 2 ( 70 - 75 minutes)
                <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                  <li>Ask to explain DBMS Project mentioned on Resume</li>
                  <li>
                    To build a new relation schema from the old one with
                    additional constraints and relation tables. (Only
                    conceptual)
                  </li>
                  <li>
                    Rules of Reduction of ER diagram to Relational Schema with
                    all cases like ( multi-valued attributes, identity relation,
                    redundancy, etc..)
                  </li>
                  <li>
                    To build application using DBMS and Networking concept from
                    scratch. Improvement and Drawbacks of the implemented
                    process.
                  </li>
                </ul>
                <li>
                  Technical Interview 3 ( 50 - 55 minutes)
                  <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                    <li>Tell me about yourself</li>
                    <li>
                      System design question:- How will you design information
                      management based system and what will be the schema for it
                      ?
                    </li>
                    <li>
                      One simple coding question to return the reverse of string
                      in C++ language and only in notepad.
                    </li>
                    <li>2-3 Puzzles</li>
                    <li>Why do you want to join us?</li>
                  </ul>
                </li>
              </li>
            </ol>
          </p>

          <p>
            <b>
              3. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <p>
              In Technical Interview 2, interviewer asked me to explain DBMS
              project ( basic working of project, difficulties faced during it,
              how you taggled it, daily life use of it.)
              <br />
              In Technical Interview 3, interviewer asked me about my
              extracurricular activities while introducing myself ( what
              knowledge did you gain? , how was the experience ?)
            </p>
          </p>

          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>
            <br />
            <ol>
              <li>
                Geeks For Geeks ( Must ) , JavatPoint( for revising CS concepts
                )
              </li>
              <li>
                Reference Books for particular subjects helps a lot. They give
                detailed explanations for each concept
              </li>
              <li>HackerRank, Leetcode and CSES problem set</li>
              <li>Love Babbar sheet</li>
            </ol>
          </p>

          <p>
            <b>5. Some tips to crack the coding questions.</b> <br />
            <p>
              Try to select 2 languages and be comfortable with all libraries
              which they have. DP, Greedy, Graph, Tree, String Manipulation,
              Hash-Map questions are must to practice. Practice is only key to
              Crack Coding Questions.
            </p>
          </p>
          <p>
            <strong>
              6. Is there something specific that you would want to tell people
              who try for the same company :
            </strong>
            <p>
              They will mostly focus on Networking/DBMS/OS concepts. They are
              flexible about the language of your choice, but C++/ Java is
              preferrable. Also make sure your resume is precise and mention
              only things on which you can talk.
              <br />
              Be confident during Interview that makes more difference on our
              impression, make sure you don’t bluff any answer. If you are not
              sure about your answer clearly mention it. They might change the
              question.
            </p>
          </p>
          <p>
            <strong>7. Final Tip for Juniors :</strong>
            <p>
              Many times you will face rejections, but it’s important to keep
              trying and have patience.
              <br />
              <b>ALL THE BEST!</b>
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:khadkeyn19.comp@coep.ac.in">
            khadkeyn19.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default yadnyadeep_khadke;

import React from "react";

const harshwardhan_deshmukh = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Harshwardhan Deshmukh
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        20 students were shortlisted for the interviews <br />
                        10 were given the final offer.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                DBMS : Gate Smashers and CodeHelp -by Babbar
                            </li>
                            <li>
                                OOPS : Kunal Kushwaha and CodeHelp -by Babbar
                            </li>
                            <li>
                                CN : Kunal Kushwaha and Gate Smashers
                            </li>
                            <li>
                                OS : Gate Smashers
                            </li>
                        </ul>
                        <br />
                        Also refer Interviewbit cheat sheets for the same
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Be calm and avoid distractions. Try to break down the problem into a simpler version of something that you have already solved or try building solution of the smaller problem.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            My interview revolved around my project.
                            Make sure to go through them thoroughly
                            (At Least have a decent idea about the project you are mentioning in the resume.)

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Study about some basic financial concepts like candlestick charts.
                            The interviewers are not only interested in your technical skills but also in your professional skills and other activities. Make sure to display them in your introduction.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Stay calm and try your best. All the best!
                        </p>
                    </p>
                    <br />
                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        LinkedIn : <a href="https://www.linkedin.com/in/harshwardhan-deshmukh-3b0043179/">Harshwardhan Deshmukh</a>
                        <br />

                    </div>



                </div>
            </section >
        </div >
    );
};

export default harshwardhan_deshmukh;

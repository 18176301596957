import React from "react";

const sakshi_mehetre = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Sakshi Mehetre
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Deutsche Bank
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              There were 6 rounds in the entire hiring process and each round
              was elimination round.First round was resume shortlisting which
              was done on the basis of cgpa. Second round was coding
              round..After shortlisting through coding test there were four
              rounds of interview which were conducted in offline mode. First
              interview round was technical round-1 followed by technical
              round-2.After that professional fitness round was
              conducted.Finally there was HR round
            </p>
          </p>

          <p>
            <b>2. Structure of Coding Round :</b>
            <br />
            This round was conducted on HackerEarth platform. There were three
            coding questions. Difficulty level of the questions was from medium
            to hard. One question was based on a graph.Other two questions were
            based on array which were of medium difficulty level. Time duration
            of the round was 90 minutes.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>There were 4 rounds of interview:</p>
            <ol>
              <li>
                <strong>Technical Round 1: 45-50 mins-</strong>
                <p>
                  Interviewer introduced himself and then he asked “Tell me
                  about yourself”.After that he started asking questions about
                  my internship.He asked me some questions on devops and cloud
                  as my internship project was based on it.Then he asked me to
                  explain the internship project.As it was a team project he
                  asked me about challenges faced while developing project in a
                  team and some situation based questions on teamwork .Then he
                  moved towards the projects in my resume.He asked me to write
                  the general flow of my one of the project on paper and we had
                  discussion on project. Followed by that he asked me about how
                  I can implement new features in existing project and which
                  other algorithms I can use to implement the project.He asked
                  questions on almost each section of my resume. So be thorough
                  with what you are adding in your resume.
                </p>

                <li>
                  <strong>Technical round 2: 50 mins-</strong>
                  <br />
                  <p>
                    After giving an introduction, the interviewer started asking
                    questions on DSA.She asked me about linked list
                    implementation and asked me some coding questions on linked
                    list like reversing the linked list iteratively and
                    recursively and some more variation of problems based on
                    linked list.After that she asked the question which was
                    variation of find the pair of elements in array with given
                    sum.She also asked about time complexity of coding
                    questions. I explained my approach on paper.Then she moved
                    towards DBMS. She asked me some questions based on
                    keys,normalization etc. She asked 2 to 3 SQL
                    queries.Followed by that she started asking questions based
                    on concepts like virtual memory,paging,threads etc. in OS.At
                    the end of round she asked me to solve two puzzles.
                  </p>
                </li>
              </li>
              <li>
                <strong>Professional Fitness round:30 to 35 mins-</strong>

                <br />
                <p>
                  Interviewer asked me to introduce myself.After that he asked
                  me about my internship and internship project. After that He
                  gave me two puzzles to solve. One more puzzle was asked which
                  was based on observation.Followed by that he started asking me
                  the questions which were based on management of tasks in a
                  team. Then he asked me to suggest some changes which need to
                  be implemented in the app of the bank to improve
                  performance.After that he gave me some situations related to
                  the client and asked me some questions on that
                </p>
              </li>
              <li>
                <strong>HR round: 15 mins-</strong>

                <br />
                <p>
                  After giving an introduction she started asking questions
                  based on resume.She asked me why I want to join Deutsche bank
                  and core values of the company and how I can relate those
                  values with myself. She asked me about my hobbies.After that
                  She asked me how I kept myself positive in the corona pandemic
                  and some questions on my next career plans.Finally she asked
                  me if I had any questions. I asked about her experience in DB.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>4. Some good resources you used in your internship prep</b>
            <br />
            <p>
              For coding test preparation I used leetcode platform and followed
              the DSA sheet of love babbar and Fraz.For preparing DBMS and OS I
              referred articles from GFG. Also I solved SQL queries from
              leetcode.
              <br />
              <a href="https://leetcode.com/list/e97a9e5m/">
                Leetcode 45 SQL questions
              </a>
              <br />
              <a href="https://docs.google.com/spreadsheets/d/1-wKcV99KtO91dXdPkwmXGTdtyxAfk1mbPXQg81R9sFE/edit#gid=0">
                Fraz DSA sheet
              </a>
            </p>
          </p>

          <p>
            <b>5. Some tips to crack coding questions</b>
            <br />

            <p>
              Consistency is the key to crack coding round. Select one coding
              language and platform.Try to solve minimum 3 to 4 questions
              daily.Focus on data structures and algorithms.
            </p>
          </p>

          <p>
            <b>
              6. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>{" "}
            <br />
            <p>
              As I explained earlier, in each interview round,there were
              questions based on projects.Some questions like what was your role
              in clubs were asked.
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              Be confident about what you say as communications skills are
              equally important.During interviews, keep explaining your
              approach.Practice mock interviews with your friends as it helps a
              lot in actual interviews.Be clear with core CS concepts.I’d
              suggest solving the puzzles as well from GFG
            </p>
          </p>
          <p>
            <strong>
              8. How many students were shortlisted after each round?
            </strong>
            <p>
              After resume shortlisting <b>59</b> students were shortlisted for coding
              test .After that around <b>19</b> students shortlisted for the technical
              interview.After HR round <b>6 students received the final offer.</b> 
            </p>
          </p>
          <p>
            <strong>9. Final Tip for Juniors?</strong>
            <p>
              Give your best shot and don’t get disheartened even if you don’t
              get selected because each interview gives us a new learning
              experience. Learn from previously done mistakes and avoid them in
              next interviews.All the best!
            </p>
          </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:mehetresr19.comp@coep.ac.in">
              mehetresr19.comp@coep.ac.in
            </a>
            <br />
            LinkedIn Handle:
            <a href="https://www.linkedin.com/in/sakshi-mehetre-2b4191203?original_referer=">
              Sakshi Mehetre
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default sakshi_mehetre;

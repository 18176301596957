import React from "react";

const sneha_venk = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Sneha Venkateswaran
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              There were 3 rounds:
              <ol>
                <li>General Aptitude Test(120 minutes)</li>
                <li>Technical Round</li>
                <li>HR Round</li>
              </ol>
            </p>
          </p>

          <p>
            <b>2. Structure of Aptitude Round :</b>
            <br />
            The duration of the aptitude test was 2 hours.It was conducted on
            AMCAT platform. The aptitude test encompassed questions from various
            domains. The sections in the test were as follows:-
            <ol>
              <li>
                <b>Technical mcqs: </b>
                Consisted of objective questions based on topics of CN,DBMS,OS
                etc .Also consisted of questions based on predicting the output
                of the given code snippets in C language.The questions in this
                section were curated to span a range of difficulty levels, from
                easy to moderate.
              </li>
              <li>
                <b>Quantitative ability: </b>
                This section was curated to assess our numerical reasoning,
                problem-solving, and mathematical skills. The questions asked in
                this section covered various topics, including:
                <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                  <li>
                    Arithmetic and Algebra:percentages, ratios, proportions, and
                    averages
                  </li>
                  <li>
                    Data Interpretation: Questions that required analyzing and
                    interpreting data presented in the form of tables, charts,
                    graphs, or diagrams.
                  </li>
                  <li>
                    Probability and Statistics: probability, permutations and
                    combinations, mean, median, mode, and standard deviation.
                  </li>
                  <li>
                    Number Series and Sequences: pattern recognition and
                    completion of numerical series or sequences.
                  </li>
                  <li>
                    Profit and Loss: Calculations of profit, loss, cost price,
                    selling price, and discounts.
                  </li>
                  <li>Time, Speed, and Distance</li>
                  <li>Numerical puzzles</li>
                </ul>
              </li>
              <li>
                <b>Verbal Reasoning: </b>
                This section consisted of questions that assess your language
                skills,vocabulary , comprehension abilities, and logical
                thinking related to written passages.
              </li>
              <li>
                <b>Coding Questions: </b>
                This section consisted of 2 coding questions.
                <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                  <li>
                    The first question was based on pattern printing. The
                    difficulty level was easy.
                  </li>
                  <li>
                    The second question was based on a variation of printing
                    permutations of a given number. The difficulty level was
                    moderate.
                  </li>
                </ul>
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              There were two interview rounds the Technical Round and HR
              Round.Both the rounds were conducted in online mode:
              <ol>
                <li>
                  <b>Technical Round:</b>
                  <br />
                  The technical round lasted for 25 mins. The round started off
                  by the interviewer asking me to introduce myself.He asked me
                  about the various projects I had worked on and wanted to know
                  amongst them which ones were group projects and which ones
                  were done individually. For the group projects specifically,
                  he asked me to describe how the different aspects/components
                  of the project like frontend, backend etc were distributed
                  between me and my teammates to get an idea of my contributions
                  in the project. He then asked me about the various
                  technologies I am familiar with.He then went on to question me
                  about the different sorting algorithms I know and asked me to
                  write the pseudo code for merge sort and quick sort
                  algorithms. He followed this up with questions related to time
                  complexities and space complexities of both the algorithms and
                  asked me which one of these is more memory efficient.
                  <br />
                  He then asked me if I had any questions for him. Make sure to
                  ask questions to interviewer when given a chance.This reflects
                  your genuine interest, willingness, and enthusiasm to be a
                  part of their company.
                </li>
                <li>
                  <b>HR Round</b>
                  <br />
                  This round lasted for about 20-25 mins. This round started off
                  by the interviewer asking me to give an elevator pitch. So an
                  elevator pitch is basically a short, well crafted and
                  impactful self-introduction that summarizes your
                  qualifications and demonstrates your interest in the
                  internship. An elevator pitch should highlight your relevant
                  skills, experiences, and academic achievements, showcasing why
                  you are a strong candidate for the internship. Its purpose is
                  to convey your passion for the role, aligning your strengths
                  with the company's needs. It is typically to be delivered
                  within 1-1.5 minutes.
                  <br />
                  After that, the interviewer went through my resume and asked
                  me questions related to each and every thing that I had
                  mentioned in it. Mostly the questions he asked were based on
                  my past internship experience that I had mentioned on my
                  resume. He asked me about the work I had done in that company
                  and also about the challenges I faced while working in a team
                  and how I overcame them. He also asked me to give a brief
                  description of all the projects I had worked on so far.Towards
                  the end, he asked me questions like:
                  <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                    <li>Why do you want to be a part of Citi?</li>
                    <li>
                      What are your expectations from the Internship Programme?
                    </li>
                    <li>
                      What about Citi’s work culture did you find most
                      appealing? (Attending the Pre Placement talk helps to
                      tackle such questions)
                    </li>
                    <li>Where do you see yourself in 10 years?</li>
                  </ul>
                  <br />
                  Lastly he asked me if I had any questions for him.
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>4. Some good resources you used in your internship prep</b>
            <p>
              <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                <li>
                  <a href="https://450dsa.com/">450 DSA Cracker</a>
                </li>
                <li>
                  <a href="https://leetcode.com/">LeetCode</a>
                </li>
                <li>
                  <a href="https://www.youtube.com/watch?v=WQoB2z67hvY&list=PLDzeHZWIZsTryvtXdMr6rPh4IDexB5NIA">
                    Love Babbar DSA Playlist
                  </a>
                </li>
                <li>
                  <a href="https://www.geeksforgeeks.org/company-preparation/">
                    GFG Company Archives
                  </a>
                </li>
                <li>
                  <a href="https://www.geeksforgeeks.org/output-of-a-program-set-1/">
                    GFG Predict the Output Questions
                  </a>
                </li>
                <li>
                  <a href="https://www.geeksforgeeks.org/lmns-gq/">
                    GFG Last minute notes
                  </a>
                </li>
                <li>
                  <a href="https://www.interviewbit.com/practice/#">
                    InterviewBit{" "}
                  </a>
                  for conceptual questions related to CN, OS, DBMS, Object
                  Oriented Porgramming etc
                </li>
                <li>
                  <a href="https://www.indiabix.com/">IndiaBix</a> for
                  practicing questions based on quantitative ability, verbal
                  reasoning etc which mainly helps to tackle questions asked in
                  aptitude tests
                </li>
              </ul>
            </p>
          </p>

          <p>
            <b>5. Some tips to crack coding questions</b>
            <br />

            <p>
              <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                <li>
                  Regularly and consistently practicing questions will help a
                  lot
                </li>
                <li>
                  Make sure you are very thorough with all the DSA concepts
                </li>
                <li>
                  Even if you solve like 3-4 problems a day and in the process
                  if you are getting to learn new concepts, its more than
                  sufficient .Keep in mind that it is the quality that is
                  important not the quantity. Also while solving problems, make
                  sure you follow the time and space constraints as specified in
                  the problem
                </li>
                <li>
                  While solving each problem, try to think of multiple ways to
                  approach the solution i,e you can initially start with the
                  brute force approach and gradually work your way up to an
                  optimized approach. Also during the aptitude test or the
                  technical round, avoid directly starting to think about the
                  optimal solution for a coding question. As far as possible,
                  start with the brute force approach and then if time permits
                  implement the optimal solution. The reason is that there is a
                  specified time limit to solve a problem and the optimal
                  solution may not always strike you at that point of time.So
                  initially solving it using brute force will help you pass
                  atleast some of the test cases if not all.
                </li>
                <li>Try to stick to one programming language.</li>
                <li>
                  During practice, when trying to solve a difficult problem, do
                  not be tempted to look at the solution. Give it a go first and
                  then look at the solution.
                </li>
                <li>
                  Try to think of all the possible edge cases for the given
                  problem and test your code against the respective custom test
                  cases before final submission of the solution.
                </li>
                <li>
                  Once you have practiced enough coding problems and learnt new
                  concepts, try solving questions by setting specified time
                  limits.This will surely help you during your aptitude tests
                  where you are required to solve problems in a stipulated time
                  frame
                </li>
                <li>
                  The various platforms for practicing coding questions are
                  leetcode,geeks for geeks and hackerearth.
                </li>
                <li>
                  Lastly, go over the problems again, especially the difficult
                  ones before the internship rounds start.
                </li>
              </ul>
            </p>
          </p>

          <p>
            <b>
              6. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>{" "}
            <br />
            <p>
              During the HR round, most of the questions asked were based on my
              projects and other extracirricular activities that i had mentioned
              in my resume. The interviewer asked me to give a brief description
              of all the projects I had mentioned in my resume.He asked me about
              the challenges I faced during the implementation of the projects.
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              <ol>
                <li>
                  Make sure you do enough research about the company and attend
                  the pre placement talk before appearing for the interview
                  rounds
                </li>
                <li>
                  Be very thorough with the tech stack used and other nuances of
                  the projects that you mention in your resume
                </li>
                <li>
                  Also, to reiterate, ask as many questions as possible to the
                  interviewer when given a chance. It shows that you are
                  genuinely interested to become a part of their company and
                  reflects your willingness to explore various learning
                  opportunities
                </li>
              </ol>
            </p>
          </p>
          <p>
            <b>8. How many students were shortlisted after each round ?</b>
            <p>
              After the aptitude round,{" "}
              <b>
                45 students were selected for the technical interview Round.
              </b>{" "}
              From the 45 students, <b>22 were selected for the HR Round.</b>{" "}
              Finally,
              <b>8 students were shortlisted for the internship programme</b>
            </p>
          </p>
          <p>
            <strong>9. Final Tip for Juniors?</strong>
            <p>
              Going by my experirence:
              <ol>
                <li>
                  You may not always clear the rounds of first few companies
                  that come. Don’t get bogged down and disheartened by it
                  because there are many more companies that will come your way
                  .So just keep at it.
                </li>
                <li>
                  Be very confident and honest when appearing for
                  interviews.Give your 100%
                </li>
                <li>
                  Also, the internship process may be quite stressful and
                  draining, so during these times its important to take good
                  care of your health and maintain a sense of equanimity.
                </li>
                <li>
                  Lastly, you just need to hang in there and give your best
                  shot.
                </li>
              </ol>
              All the very Best to all !!!
            </p>
          </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:snehav20.comp@coeptech.ac.in">
            snehav20.comp@coeptech.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default sneha_venk;

import React from "react";

const pratik_patil = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Pratik Patil
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>BNY Mellon</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ol>
              <li>
                Aptitude Round (Everyone above 7 CGPA was allowed)
              </li>
              <li>
                Pre placement talk
              </li>
              <li>
                Interview (Out of 300, 18 students were selected for interview)
                <li>
                  Technical Round (30-40 mins)
                </li>
                <li>
                  HR Round (30-35 mins)
                </li>
              </li>
            </ol>
          </p>
          <p>
            <b>2. Structure of Aptitude Round :</b>
            <br />
            <p>
              Aptitude test had 4 coding questions and the time limit was 90 mins.
            </p>
            <ol>
              <li> It was some basic string manipulation question.</li>
              <li>An array consisting of both positive & negative numbers. We had to find
                the subarray of length ‘k’ whose sum is maximum. It was similar to Kadane
                Algo but in Kadane the subarray can be of any size. But here we were
                instructed to keep it strictly less than or equal to k
                arr=[-3,4,3,-2,2,5] k=4
                <br />Sol:- - 3 + (-2) + 2 + 5 = 8 <br />
                One possible approach is a sliding window with variable window length.</li>
              <li> It was similar to Shortest Path with obstacles with some minor
                modifications. <br /> Here is the link of problem: <a href="https://leetcode.com/problems/shortest-path-in-a-grid-with-obstacles-elimination/">https://leetcode.com/problems/shortest-path-in-a-grid-with-obstacles-elimination/</a>
              </li>
              <li>
                We were given lower and higher limits of lottery tickets. All the tickets are
                present sequentially between these ranges. The power of ticket[i] is the
                sum of digits of ticket[i]. The organizers would announce the power and all
                those who had the tickets with that power would get the prize. We were
                asked to find the power so that maximum people can earn a prize. Also the
                number of people who earn the prize. <br />
                LowerLimit=11 &nbsp; HigherLimit=20 <br />
                ticket=[11,12,13,14,15,16,17,18,19,20] <br />
                power=[1+1,1+2,1+3,.....2+0] &nbsp; (Sum of their digits) <br />
                &nbsp; &nbsp; =[2,3,4,5,6,7,8,9,10,2]
                For power ‘2’ there are maximum winners ie. ticket 11 & ticket 20

              </li>
            </ol>
          </p>
          <p>
            <b>3. How many rounds were there for the company, can you elaborate about each round in brief :</b>
            <p>
              The <b>1st Round</b>  was the Coding Round. <br />
              The <b>2nd Round</b>  was the Technical Interview: <br />
              They mainly focused on DBMS, OOPS and development.
              <ul style={{ listStyleType: "circle", paddingLeft: "40px" }}>
                <li>
                  I was asked to write a few SQL queries. It consisted of Joins and Nested
                  queries. (TIP: Even while doing rough work write appropriate comments
                  which helps the interviewer to understand your approach)
                </li>
                <li>
                  Different types of indexing and their real life examples.
                </li>
                <li>
                  Keys in SQL.
                </li>
                <li>
                  Structure of Procedure in SQL. When to use Procedure and Views. Major
                  difference between procedure and views. Interviewer will try to confuse you
                  but one must stick to a particular answer.
                </li>
                <li>
                  MDI / SDI is related to some SQL server.
                </li>
                <li>
                  SPI (Server Programming Interface)
                </li>
                <li>
                  Inheritance. Virtual functions. Diamond problem
                </li>
                <li>
                  Overriding, Overloading, Encapsulation
                </li>
                <li>
                  Programing paradigms, Procedural, Event Driven
                </li>
              </ul>
              The <b>3rd Round</b> was HR interview: <br />
              The main focus was how one approaches solving problems. So whatever you
              think, just think out loud so that the interviewer can also understand.
              <ul style={{ listStyleType: "circle", paddingLeft: "40px" }}>
                <li>I was asked to implement different types of queue on a blank sheet of
                  paper. So one should be aware of how data structures are made.
                </li>
                <li>
                  Scheduling Algo
                </li>
                <li>
                  Some random talks to understand the person’s psychology. Like my future
                  goals and so on.
                </li>
                <li>
                  He asked which part of the interview process did I enjoy much? (The main
                  aim was to know whether the candidate has sincerely attended the
                  pre-placement talks. So it plays an important roles)
                </li>
                <li>
                  In the end he asked if I had any doubts (It is always advisable to ask some
                  doubts. It shows that you are interested in the company)
                </li>
              </ul>
            </p>
          </p>
          <p>
            <b>
              4. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <ul style={{ listStyleType: "square", paddingLeft: "40px" }}>
              <li>
                PROJECTS <br />
                Be thorough on whatever project you put in superset. They cannot be
                changed later and most of the time the interviewer has a deep
                understanding of that tech stack so he will ask questions in depth.
                Since I had implemented TEXT EDITOR in C which runs on linux terminal,
                he asked me a question about linux - Differences between Linux and
                Windows terminals. Also some string matching algorithms like Rabin Karp,
                KMP.
              </li>
              <li>
                CLUBS & EXTRACURRICULAR ACTIVITIES <br />
                They play an important role in diverting the interview from technical side to
                personal achievements. Because it is easy to speak abouts clubs rather
                than on some technical questions.
                He wanted to know whether I can work in a team or not. At that time your
                experiences in the clubs/fests will help you. If you are a part of a team that
                has achieved something or won some competition it will definitely give you
                an upper edge over other candidates. However it is not at all the deciding
                factor.
              </li>
            </ul>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              Some famous channels on YT
              <ul style={{ listStyleType: "circle", paddingLeft: "40px" }}>
                <li>
                  Luv
                </li>
                <li>
                  Aditya Verma
                </li>
                <li>
                  Abdul Bari
                </li>
                <li>
                  Gate Smashers
                </li>
                <li>
                  Neso
                </li>
                Strivers & GFG last minute revision notes of DBMS, CN, OS are sufficient since
                companies expect only basic understanding of these topics
                Love Babbar <b>FINAL 450</b> is the ultimate roadmap which I followed. It covers all
                standard questions.
              </ul>
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                <li>
                  Practice daily. <br />
                  Leetcode & GFGs POTD is the best resource to keep in touch with DSA
                  questions. They provide 1 random question which revises that particular
                  concept. Standard questions get repeated so revision is the key.
                </li>
                <li>
                  Live Contest on codechef, Codeforce <br />
                  Usually we have a time limit to solve 1 question in roughly 10-15 mins. So
                  speed is a must. Don’t waste too much time on 1 single question.
                </li>
                <li>
                  Language <br />
                  For solving questions stick with 1 particular language and learn all the
                  predefined functions of it. It really saves time while coding. One mistake
                  which I made was using multiple languages for solving questions. Since all
                  languages have different syntax, most of my time was wasted in correcting
                  the syntax.
                </li>
              </ul>
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              Usually other companies focus on the aptitude round. But BNY mainly focuses
              on the coding part. So one must have a good hold on solving coding problems
              and should have a strong understanding of DBMS. Just carry a positive attitude
              in the interview and it will be more than sufficient.

            </p>
          </p>
          <p>
            <strong>8. Final Tip for Juniors :</strong>
            <p>
              Life has its own plan. So keep patience even if you don't get selected in your
              dream company. I know stress and frustration builds up a lot and even I have
              gone through that process. But trust me a single company is not worth one's
              health. Everything will happen at its own time.
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID:
            <a href="mailto: patilpv20.comp@coep.ac.in">
            patilpv20.comp@coep.ac.in
            </a>
            <br /> <br />
            LinkedIn Handle:
            <a href="https://www.linkedin.com/in/pratik-patil-b44a18202">Pratik Patil </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default pratik_patil;

import React from "react";

const pallavi_buwa = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Pallavi Buwa</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            DE Shaw &amp; Co.
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />
          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>The structure of the entire process was as follows:
            </p>
            <ol>
              <li>
                <strong>Aptitude test on Hackerrank</strong>
                <p>80 minutes in total - 1 coding question and 26 aptitude questions divided into 3 sections</p>
              </li>
              <li>
                <strong>2 Technical Interviews</strong>
              </li>
            </ol>
          </p>
          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <ol>
            <li>
            <p>
              Coding question – The question was to be solved using the concept of dynamic programming. 
              A hypothetical apocalyptic scenario was presented where you were to battle aliens in one to one matches. Each alien has a
              certain value of health and damage potential(both represented as numbers),
              as do you. In a battle, you deal a blow to the alien and his heath gets
              decreased by the value of your damage potential and vice versa. You
              continue to battle different aliens with different values of health and damage
              till your health becomes 0. You are to find the maximum points you can
              score before you die (points earned = sum of healths of all the aliens you
              defeated).
            </p>
            </li>
            <li>
            Aptitude Test – 20 minutes arithmetic aptitude questions 20 minutes for quesions based on DSA and general coding concepts 10 minutes for systems specific questions
            </li>
            </ol>
          </p>
          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>There were 2 rounds, both of them technical. The HR round was cancelled for us.</p>
            <ol>
              <li>
                <p>
                The
              <strong>
                {" "}
                <em>first round</em>{" "}
              </strong>
                </p>
                Started with the standard “tell us about yourself” question. This round was a warm up session of sorts. I was asked general questions about myself
                and a few details about my projects. I had put up a web dev project for
                managing passwords which used hashing. I was asked to explain the
                concept and why I had chosen hashing over other techniques. I was also
                questioned on my choice of tech stack. This was followed by a brief
                discussion on different operating systems and I was asked for my option on
                the ones that i was familiar with. I was asked to justify my choice of
                language for coding (C++) and was told to compare and contrast all the
                languages i knew (java, C, C++ and python) in terms of speed and
                performance. Another project on my resume was an improved version of tar
                for text file compression. I was asked how my project worked and i had to
                explain the encryption algorithm used and the possible improvements to its
                functionality.
              </li>
              <br />
              <li>
                <p>
                The
              <strong>
                {" "}
                <em>second round</em>{" "}
                </strong>
                </p>
                  This round was a bit more technical. I was asked to go through the concepts of hashing, encryption, tech stacks and databases as used in my
                  projects again. I was questioned on various linux commands ie what
                  command would you use to accomplish a particular task. This was followed
                  by questions on computer networks. Basics of routing, routing table, circuit
                  vs packet switching, routers vs switches, various layers and protocols like
                  tcp vs udp, http vs https, etc. I was also asked about the basics of OS like
                  semaphores, multithreading and multiprogramming.
              </li>
            </ol>
          </p>
          <p>
            <b>4. How many questions were asked based on the projects done?</b>
            <br />
              I was questioned about 2 of the 3 projects i had put up on the resume in both the interviews. I had to justify my choice of techstack and encryption techniques. I
              was asked follow up questions about all the skills i had mentioned in my resume.
              (for example, one of the skills was android develeopment so I was asked what
              software I had used, for how long I had been working on android dev and I was
              asked to draw comparisons between android and other operating systems and my
              experience of working with them). I was also asked about the concepts I was
              learning at the time (MERN stack and ML) and what projects I intended to do with
              these new skills. I would advice you to be completely honest on the resume as it is
              their main focus and all that you claim to know will be asked and tested.
          </p>
          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              The resources I used were hackerrank, indiabix and various playlists on youtube for learning DBMS, CN and OS. Daily practice of solving coding
              questions, aptitude questions and learning about a new subject proved to be helpful for me.
            </p>
          </p>
          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Try to learn dynamic programming if possible as this will help you with other
              companies as well. In my case, I had no knowledge of DP so I used brute
              force to pass a fraction of the test cases. So even if you are unable to come
              up with a solution that passes all test cases, try to write a piece of code that
              passes a few atleast.
            </p>
          </p>
          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            D.E Shaw will focus on your projects and resume so try to include all the
            skills you are proficient in and be sure you know your project in depth as you
            will be questioned a lot about it.
          </p>
          <br />
          <p>
            <i>
              A general suggestion for all companies: Be Honest. Even if you need to tell
              them that you do not know something that they ask you or if you need to ask
              for clarification for a question, do not hesitate. Be confident about your
              answers. Try to work on your communication skills as they help you express
              your work better. If you are asked to solve a problem or coding question, be
              sure to tell the interviewer your thought process-loud thinking helps!
            </i>
          </p>
        </div>
      </section>
    </div>
  );
};

export default pallavi_buwa;

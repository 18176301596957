import React from "react";

const nishant_badgujar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Nishant Suresh Badgujar
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Veritas Technologies LLC
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              <ol>
                <li>
                  The Pre-Placement Talk (PPT) and the online assessment was
                  held on 7th September 2022.
                </li>
                <li>
                  Technical Round, Managerial Round and HR Round on 8th
                  September 2022.
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>2. Structure of Aptitude Round :</b>
            <br />
            <ul>
              <li>
                The aptitude test was of 1 hour, with a total of 22 questions on
                HackerRank
              </li>
              <li>
                There were 20 mcqs of moderate difficulty level on CS subjects
                like os, dbms, cn, oops and basic programming like pointers,
                finding the output of a program etc. There was no negative
                marking for MCQs
              </li>
              <li>
                Also 2 coding questions on array & string are of medium level as
                follows:
                <br />
                1.Missing Words problem
                <br />
                2. Activate fountain problem
              </li>
              <li>
                Only the optimal solution would pass all test cases, otherwise
                TLE error. Try to submit as early as possible. All languages
                allowed
              </li>
            </ul>
          </p>

          <p>
            <b>
              3. How many rounds were there for the company, can you elaborate
              about each round in brief?
            </b>
            <p>There were 3 rounds of interview:</p>
            <ol>
              <li>
                <strong>Technical Interview: 1 hr-</strong>
                <p>
                  The round started with the basic introduction of myself and
                  asked following some questions:
                  <br />
                  1.As I mentioned git in my intro and resume, he asked me two
                  questions on git - which git command is used to see history of
                  previous commits and how to clone git repo.
                  <br />
                  2.Write a code to reverse a string and print it using variable
                  name at once without using in-built functions and extra space
                  by screen sharing.
                  <br />
                  3. How to merge two sorted linked lists into one sorted linked
                  list and second was how to delete the 3rd node of the linked
                  list containing a total 6 nodes.
                  <br />
                  4. Questions on os like what is semaphore, what is mutex lock,
                  what is critical section, what is interprocess communication
                  and also on deadlock.
                  <br />
                  5. He asked that are you comfortable in c++ and then asked
                  questions on oops like what is virtual function in c++ and
                  when it’s uses?
                  <br />
                  6.What are joints in dbms and its types.
                  <br />
                  Gave answers of almost all the questions and selected for
                  further round in 9 students
                </p>

                <li>
                  <strong>Managerial Interview (Time: 45mins)</strong>
                  <br />
                  <p>
                    <ul>
                      <li>
                        He introduced himself and told me about the different
                        domains of the company in which he is working and then
                        he told me to introduce myself.
                      </li>
                      <li>
                        This was pretty much a project and their concept based
                        interview. The interviewer was very friendly and gave
                        hints in different parts of the interview.
                      </li>
                      <li>
                        He told me to explain my project in the operating system
                        i.e. shared memory in xv6 which I prepared very well.
                        During explanation he asked different situational-based
                        questions like what will happen in shared memory if the
                        total ram of the system gets occupied. What is virtual
                        memory in os. What is the difference between virtual
                        memory and swapping?
                      </li>
                      <li>
                        Then he told me to explain my other project in computer
                        networking i.e. DNS client and server in which I
                        explained what it is, what I did in this and different
                        functionalities of the project. He also asked me some
                        questions on the project and showed some interest.
                      </li>
                      <li>
                        This interview was nothing like discussion and we both
                        actively participated in this discussion. Selected in 4
                        out of 9 students for the HR round.
                      </li>
                    </ul>
                  </p>
                </li>
              </li>
              <li>
                <strong>HR round: 20-25 mins-</strong>

                <br />
                <p>
                  <ul>
                    <li>
                      The HR was very friendly and asked me to introduce myself.
                    </li>
                    <li>
                      He asked me about my experience with the whole process.
                    </li>
                    <li>Asked about Family background.</li>
                    <li>
                      Other simple HR questions like Why veritas? Your unique
                      qualities & asked me what I knew about Veritas?
                    </li>
                    <li>
                      Finally he asked me that are you ok to take this offer
                      from veritas and without a doubt I replied “yes”.
                    </li>
                    <li>
                      Then he described the salary breakup of FTE and
                      internship.
                    </li>
                  </ul>
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>4. Some good resources you used in your internship prep</b>
            <br />
            <p>
              Prepared from gfg and leetcode for dsa coding problems. Revised
              concepts of CS subjects like Operating Systems, Computer Networks,
              Database Management System and Object oriented programming from
              Gfg, Love babbar road map and from other websites.
            </p>
          </p>

          <p>
            <b>5. Some tips to crack coding questions</b>
            <br />

            <p>
              Practice and only practice of CP problems and regular revision of
              various concepts.
            </p>
          </p>

          <p>
            <b>
              6. How many questions were asked about projects, clubs and
              extracurricular activities in your resume :
            </b>{" "}
            <br />
            <p>
              <ul>
                <li>
                  In the Managerial Interview, he told me to explain my project
                  in the Operating System and Computer Networking. Also asked
                  some cross-questions on these projects and related topics.
                </li>
                <li>
                  My experience as head helped me to describe my qualities like
                  teamwork and leadership.
                </li>
              </ul>
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              <ul>
                <li>
                  Veritas focuses a lot on OS and CN, so please have more focus
                  on it during your preparation.
                </li>
                <li>
                  Veritas visited our college for campus hiring for a FTE + 6
                  months Internship.
                </li>
              </ul>
            </p>
          </p>
          <p>
            <strong>
              8. How many students were shortlisted after each round?
            </strong>
            <p>
              <ol>
                <li>
                  Total 189 students appeared for the test and out of which 21
                  were selected for technical interview.
                </li>
                <li>
                  Out of 21 students, 9 students got selected for further
                  managerial round.
                </li>
                <li>Out of 9 students, 4 got selected for the HR round.</li>
                <li>
                  All students from the HR round got an offer from Veritas.
                </li>
              </ol>
            </p>
          </p>
          <p>
            <strong>9. Final Tip for Juniors?</strong>
            <p>
              Basic concepts in depth should be clear with proper understanding.
              Don’t panic during the interview if you fail to answer any of the
              question. Always use the opportunity to ask the question whenever
              the interviewer asks that, Do you have any questions? This shows
              how interested you are in a particular organisation. In Managerial
              round, I asked two questions to the interviewer.
            </p>
          </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:badgujarns19.comp@coep.ac.in">
            badgujarns19.comp@coep.ac.in
            </a>
            <br />
            LinkedIn Handle:
            <a href="https://www.linkedin.com/in/nishant-badgujar-870b821b1">
              Nishant Badgujar
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default nishant_badgujar;

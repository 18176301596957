import React from "react";

const kartik_mandhan = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Kartik Naresh Mandhan
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Arcesium</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              Arcesium had an online hiring process in College of Engineering
              Pune. Computer, ExTc and IT branches were allowed with a CGPA
              criteria of 7. The structure of the entire process was as follows:
            </p>
            <ol>
              <li>Coding and Aptitude Test</li>
              <li>Technical Interview Round 1</li>
              <li>Technical Interview Round 2</li>
              <li>Technical Interview Round 3</li>
            </ol>
            <p>Yeah, you read it right 3 Technical rounds!</p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              The Test was conducted on HackerRank platform and had 3 sections:
              <ol>
                <li>Quant MCQs (20 questions - 20 min)</li>
                <li>Technical MCQs (15 questions - 15 min)</li>
                <li>Coding Section (2 Questions - 45 min)</li>
              </ol>
            </p>
            <p>
              Comparing with DeShaw, Duetsch Bank, Goldman Sachs, I felt the
              aptitude round of Arcesium was the toughest to crack.
            </p>
            <p>
              The test was timed for 80 minutes and each section had their own
              time limits with no rollover.Quant questions needed a lot of
              practise to be solved. Technical MCQ had predict the output kind
              of questions from OOP in C and Java. While for the Coding Round,
              one of the coding question was "You are given a 2*n chocolate bar
              that can be filled with dark/white chocolate, with a restriction
              that white chocolate cannot be placed in adjacent columns. Now
              find the number of permutations possible for the chocolate bar.".
              It was an dynamic programming problem and by trying many test
              cases you could figure out the solution and I could pass many of
              the test cases, while the other question was based on Piano and
              had so many terms that understanding that question took me a lot
              of time.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              Arcesium was the only company to have 3 Technical rounds and all
              of them included live coding on Hackkeran’s Codepair.
            </p>
            <p>
              <ol>
                <li>
                  <strong>First Round:</strong>
                  <p>
                    It began with my Introduction, followed by some of the
                    follow up question on the intro as well, so that gained me a
                    confidence that the interview liked my profile. Then he
                    quickly jumped to CodePair and gave me a question to solve.
                    The question was a variation of{" "}
                    <a href="https://www.geeksforgeeks.org/minimum-time-required-so-that-all-oranges-become-rotten/">
                      Minimum time required so that all oranges become rotten
                    </a>
                  </p>
                  <p>
                    I didn’t had solved this question before. So I took time to
                    understand it properly and explained two of the approaches
                    to the interviewer, then once he seemed satisfied with my
                    solution, he told me to write the code. I wrote the code and
                    while writing I simultaneously again explained him while dry
                    running through the test case, that made him realise I have
                    solved the problem correctly. So he didn’t bother to run any
                    further test cases and asked me to write a query in SQL and
                    follow up questions on that query.
                  </p>
                </li>
                <li>
                  <strong>Second Round:</strong>
                  <p>
                    The interviewer didn’t even bother to ask me introduction,
                    and directly jumped to Code Pair. He initially gave me a DP
                    problem which I had solved before, which was{" "}
                    <a href="https://www.geeksforgeeks.org/coin-change-dp-7/">
                      Coin Change
                    </a>{", "}
                    I explained my approach and then dry run through one of the
                    sample test case and finally coded the solution, The
                    interviewer this time didn’t gave any feedback this time,
                    whether I was on the right track or not. Finally when I
                    finished coding. Then he ran arround 6-7 cases on the code,
                    and when all of them were correct, then finally he uttered
                    "Good Work!". He also asked me SQL queries and some
                    concept about dbms, SQL joins, which I answered correctly
                    and then gave me another problem of array for which I was
                    only asked the approach and didn’t told me to code.
                  </p>
                </li>
                <li>
                  <strong>Third Round:</strong>
                  <p>
                    It began with introduction, followed by some of the
                    questions on my projects and then he mentioned " I can see
                    so many technologies in you resume, lets test how much of
                    them you really know and how deep?"(So make sure guys only
                    list the tech you really know) Actually I was happy
                    listening this sentence, as I wanted this interview to be
                    more verbal rather than again a codepair round. So he
                    started asking about React, then databases noSQL vs SQL,
                    then few more questions and then what are singleton classes?
                    (Question of Java, I didn’t mention Java in my resume. But
                    still he checked whether I knew about it) I could answer
                    that and give him an example too, so he was impressed. Then
                    finally he shifted to CodePair. A DP problem again! It was a
                    variation of{" "}
                    <a href="https://leetcode.com/problems/best-time-to-buy-and-sell-stock/">
                      Best time to buy and sell stock
                    </a>{" "}
                    he also added a constraint that I cannot buy and sell on the
                    same day and consider losses too. I explained him the
                    approach but he wasn’t quite sure whether this would work o
                    not, but I was confident so I wrote the code and then
                    manually ran the code on a sample case loudly in order for
                    him to understand how this works, then he was finally
                    satisfied and this was followed by I asking him a couple of
                    questions.
                  </p>
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <br />
            <p>
              The third round had many questions on resume as compared to the
              first two rounds. So make sure the technology that you list in the
              skills section is well known to you.Don’t just watch a YouTube
              tutorial and mention that as your skill, make sure you understand
              it well and you are confident to answer questions related to it,
              before mentioning it!
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              Initially, I started with Problem Solving Section on Hackerrank,
              solved it till 5 stars then I moved to GFG Practise Section,
              solved a new question and then solve a question that is similar to
              it, this way I think you can understand concepts better. I also
              used LeetCode for solving problems and purchased Cracking th
              Coding Interview Book and referred it too. For aptitude, I solved
              questions on IndiaBix and CareerRide. In short I used a plethora
              of resources and tried to extract the best from each of them.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              One suggestion from my side would be that, read the coding
              questions properly first, and make a map like imagination of the
              problem in your mind. It might appear, that the question is pretty
              difficult at first, so try breaking down it into small problems,
              and start solving it (this might help to pass few test cases at
              least), and remember that each test case does have some weightage.
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
            <p>
              My interview with Arcesium had many questions from SQL, DP, I even
              asked my friends and they too had most of the questions on the same
              topics. So, I would suggest practise Dynamic Programming well and
              try to learn SQL too.
            </p>
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
             <a href="mailto:mandhankn19.comp@coep.ac.in">
             mandhankn19.comp@coep.ac.in


            </a>
            <br />
            <a href="https://www.linkedin.com/in/kartik-mandhan-13aa4b192/">LinkedIn Handle</a> 
          </div>
        </div>
      </section>
    </div>
  );
};

export default kartik_mandhan;

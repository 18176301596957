import React from "react";

const chitra_sangle = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Chitra Sangle</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Barclays</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            It was conducted in phases.
            <ol>
              <li>Resume shortlisting</li>
              <li>Aptitude test (online)</li>
              <li>Interview (Tech + HR)</li>
            </ol>
            
          </p>
          <p>
          <b>2. Structure of Aptitude Round :</b>
            <br />
            <p>
            Aptitude test consisted of some multiple choice questions and two coding questions. One of the coding questions was based on the concept of dynamic programming and the other one was a basic one (I don't remember the exact questions). For solving these types of coding questions you need to practice coding questions on a daily basis and keep the concepts handy. MCQs were mainly based on predicting the output and correcting the error in the code. The language of the programming questions was Java. Some questions were present in languages like C and C++. Some questions were even based on concepts of OOPs and DBMS( Basic SQL queries).
            </p>
          </p>

          <p>
            <b>
              3. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <br />
            <p>There was only one round which consisted of both HR and Technical questions. It lasted for about 45-50 minutes.</p>
            <p>There was only one interviewer and the interview was conducted online on MS Teams.</p>
            <p>It started off with a 2-3 min ice-breaking session. My interview started with HR questions ( It can be different with other students). She asked me to introduce and tell her a bit about myself. And on every thing I said about myself, she cross-questioned me on the same. So make sure you don't lie when you answer such questions.</p>
            <p>After some basic HR questions she gave a puzzle to solve - <a href="https://www.geeksforgeeks.org/puzzle-30-last-palindrome-data/"> Last Palindrome date before this date puzzle</a> </p>
            <p>Later I was given a basic coding question to code which you can find and solve on Leetcode
            <a href="https://leetcode.com/problems/two-sum/">Two sum question</a>  

            </p>
            <p>She then started with some DSA and OOPs questions.
              <ol style={{paddingLeft:"40px"}}>
                <li>
                Binary search tree concepts, time complexities,
                </li>
                <li>
                Red Black Tree insertion and deletion.
                </li>
                <li>
                Virtual function concept in OOPs, four pillars of OOPs.
                </li>
                <li>
                Depth First Search and Breadth First Search.
                </li>
              </ol>
            </p>
            <p>
            Later on she asked me to give an overview of the projects mentioned on my resume. And asked some basic questions on it.
            </p>
            
          </p>

          <p>
            <b>4. How many students were shortlisted after each round?</b>
            <p>
            In total 80 students gave the aptitude test. 30 students were selected for Interview round. From those 30 11 students were shortlisted for the final offer.
            </p>
          </p>
          <p>
            <b>
              5. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <p>
              Not a lot of questions were asked on the projects. Question on
              extracurricular activities and position of responsibility of clubs
              were asked
            </p>
          </p>

          <p>
            <b>
              6. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
            For coding practice I used Leetcode and Hackerrank. <br /> For solving puzzles - interview puzzles from geeks for geeks. Barclays Archives from geeks for geeks.
            </p>
          </p>

          
          
          <p>
            <strong>7. Final Tip for Juniors :</strong>
            <p>
            You won’t get the result you desire but just stay focused and determined. Have a good day :)
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:sanglecr20.comp@coep.ac.in">
            sanglecr20.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default chitra_sangle;

import React from "react";

const viraj_jadhav = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Viraj Jadhav</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Schlumberger</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            Total, there were 5 rounds (1 Online Aptitude test, 2 Technical
            Rounds, 1 Managerial Round, 1 Behavioral Round). These rounds
            (except for online aptitude test) were scheduled in random order for
            everyone, e.g. One might have Behavioral as his / her first round
            whereas the other candidate might have Technical Round - I as first,
            and all were elimination rounds (i.e. Even though if your first
            round was Managerial round, there was an equal chance that you might
            get eliminated after this round without even appearing for technical
            or Behavioral round).
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test had purely Java based MCQs and one coding
            question. MCQs were really well formed and ranged from medium and
            above medium difficulty compared to coding question. Coding question
            was medium and below medium in difficulty and it was solvable easily
            once you figured out the logic or algorithm used (i.e these may /
            may not require use of STLs / collections, etc). MCQs were
            challenging and required at least good knowledge of core Java
            concepts, especially OOPs in Java.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />I have ordered these rounds below according to my schedule.
            <ol type="1">
              <b>
                <li>Online Aptitude Test:</li>
              </b>
              Platform: firstnaukri
              <br />
              Pattern: MCQs based purely on Java and one coding question (random
              for all). We were allowed to code in any available language and
              not necessarily Java.
              <br />
              The following rounds were held on Microsoft Teams and began with a
              quick introduction of both of us, i.e. me and the interviewer.
              <b>
                <li>Technical Round - I:</li>
              </b>
              This round focused on testing your understanding of concepts in
              data structures, OOPs, programming language of your choice,
              algorithms, etc. In between the interview, I was asked to select a
              OOP language from the mentioned ones in my resume, which I would
              be comfortable in answering questions. I chose Java as my primary
              language, so I was asked few questions on Java (mostly OOP
              questions, exceptions and collections) and also to code brute
              force approach for finding if two given strings are anagrams or
              not. Later I was asked to optimize this code to best case
              acceptable solution (the interviewer then tested my code with some
              of the sample strings he had). I was also asked to explain my
              approach and not code for another problem related to strings.
              Apart from this other questions were mostly theory related
              questions, some were from DBMS as well.
              <b>
                <li>Behavioral Round:</li>
              </b>
              After the introduction, the interviewer asked me about my day and
              took feedback on my previous interview. He then asked me about
              some of my projects and the difficulties I faced and how I tackled
              those while completing the project. The interviewer was quite
              friendly and easy to talk to, so the rest of interview was just an
              interactive conversation and general Behavioral questions, e.g. my
              idea and approach / decision if given a particular work related
              situation, what will be my approach if one of the team member
              couldn't complete the given work and deadline is nearby, my future
              goals, why Schlumberger, etc.
              <b>
                <li>Managerial Round:</li>
              </b>
              The interviewer began with asking me about hobbies, and what I do
              my in free time that has nothing to do with "technical stuff". He
              then asked me to point out a major problem that I came across in
              my day-to-day activity to which I chose Public Transportation as a
              domain, and pointed out few problems in local trains and
              organization. Following this, he asked me how can I improve the
              current scenario of mentioned problem, if given the appropriate
              authority. To this I gave my view points and the interviewer
              seemed satisfied. He then asked me to rate my algorithm solving
              skills on scale from 1 to 10. Later to which he added a question
              similar to - "find how many characters you need to add or delete
              from a given string in order to make that string a palindrome" and
              with some modifications and asked my approach on solving this. The
              interview continued with some work related situation based
              questions.
              <b>
                <li>Technical Round - II:</li>
              </b>
              This round was a little more challenging than the Technical Round
              - I. I was asked various theoretical questions in the domain of
              Data structures, DBMS, Java (I chose this as my preferred
              language), OOPs, etc. considering corner cases as well. Apart from
              this I was asked to write a SQL query for finding the tuple with
              employee having second highest salary from the given table.
              Following this one coding question with optimization (again tested
              with sample data), then a pseudo code involving REST API for
              finding average of all the salaries present in a table column
              provided in file and sent over HTTP to the API (Because my resume
              had a project related to REST API). Apart from few questions in
              DBMS, I remember being asked about composite key and it's
              significance. There were few questions on Java OOPs, interfaces,
              default keyword (it's scope and use case), exceptions, etc.
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            Only few questions were asked on my projects in depth. The questions
            based on projects were majorly based on the idea behind choosing the
            project, technologies used, some of the problems that I faced and
            how I encountered them, etc. In one of the interviews I was asked if
            currently I am working on any project or not.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />I highly recommend using GeeksforGeeks for brushing up your
            data structures and algorithms, also improving your OOPs concept.
            For aptitude test, I solved questions from india bix website,
            GeeksforGeeks and R.S. Agarwal book (Highly recommend). As the
            pattern for aptitude test for all the companies is different, focus
            of common topics more, e.g quantitative questions, output prediction
            for code snippets, etc. and also practice and improve your coding
            skills from platform such as - HackerRank (especially, Interview
            preparation kit), Leetcode, etc.
          </p>

          <p>
            <b>
              6. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            Brush up your Java basics (at least), as it is highly required for
            you in order to crack the aptitude test (for Schlumberger). Improve
            your understanding of data structures and algorithms along with
            OOPs, DBMS and may be CN concepts. The interviewer doesn't except
            you to provide the most efficient approach in first try, so if you
            don't know about how you should go in solving the question, think
            for sometime, talk with the interviewer about your thought process
            and tell him your approach.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:jadhavvs18.comp@coep.ac.in">
              jadhavvs18.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/viraj-jadhav-436497ab/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default viraj_jadhav;

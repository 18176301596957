import React from "react";

const sarvesh_mankar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Sarvesh Mankar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The Entire Process comprised four rounds:
                            <br />
                        </p>
                        <p>
                            <ol>
                                <li>Aptitude Test</li>
                                <li>Technical Round - 1</li>
                                <li>Technical Round - 2 + Managerial</li>
                                <li>HR Round</li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Two domains were available for the intern position, and I applied for ICG Team [Institutional Client’s Group]. This Round was divided into 4 sections:
                            <br />
                        </p>
                        <p>
                            <ol>
                                <li>
                                    Verbal Reasoning (Difficulty: Easy)
                                </li>
                                <li>Arithmetical Reasoning Mathematical Problems (Difficulty: Medium)
                                </li>
                                <li>Logical Reasoning Mental Ability Based Questions (Difficulty: Medium)</li>
                                <li>Coding Problem. I had 2 easy questions, one on bit manipulation and the other on strings.(Difficulty: Easy)
                                </li>
                            </ol></p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 3 rounds after the aptitude round. The first round was completely Technical, the second was Technical + Managerial, and the last was HR Round. <br />
                        <br />
                        The First round is completely based on my Resume and Data Structures. I was questioned regarding the distinction between Trees and Graphs and the various applications for which they may be used. Next, I was asked to write the pseudocode of the sorting algorithm I would prefer to use. I answered Merge sort, so I was asked to give a detailed explanation of it. The following section was about relational Databases, as it was a part of my Skillset. I was asked to write some queries.
                        <br />
                        Further, some basic questions on OOPS (Polymorphism, Encapsulation, Inheritance, Abstraction) were asked. In the last section, I was asked about my projects. I explained two of my projects, and Every question concerned the two projects I described in detail. This round finally came to a close with questions about my most recent internship and community work.
                        <br />
                        The second round was similar to the first round. I was asked to explain my projects and their significance in my overall growth as a Developer. I was then asked some open-ended questions like:
                        <br />
                        <ol>
                            <li>What does it mean to be a good programmer?</li>
                            <li>Which is my Favourite Domain?
                                <br />I expressed interest in DevOps and Cloud Computing and had a rapid-fire discussion on specific conceptual terms.</li>
                            <li>What improvements should I have made in my projects?</li>
                        </ol>
                        <br />
                        The third and final round was an HR Round wherein I spoke about my hobbies and extracurricular activities. We discussed chess openings, notably the Queen's Gambit, because I enjoy solving chess puzzles. I was asked about the 8 Ball Puzzle. I expressed my interest in working at Citi. I also questioned them extensively regarding the workplace.
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After the aptitude test, 54 students were shortlisted (15 for PBWMT and 39 for ICG).  Around 20 students were shortlisted after the first Technical Round. After the second round, around 15 students were shortlisted for the HR Round, and they ultimately decided to provide internships to 13 of them, including 10 students from the ICG Team and 3 from the PBWMT Team.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        For the coding round, I prepared myself by solving LeetCode problems. Almost all the coding rounds I gave for the other companies also mirrored LeetCode problems. I prefer to use Python in Competitive Programming as it provides us with a lot of built-in tools. The finest YouTube channel I've found to comprehend the reasoning behind challenges is Neetcode. You may follow <a href="https://leetcode.com/studyplan/top-sql-50/">SQL</a> and <a href="https://leetcode.com/studyplan/leetcode-75/">LeetCode-75</a> roadmap. For the Technical Rounds, just know your Resume well. All the questions are about the skill set and projects mentioned in your resume.
                        Also, go through the <a href="https://www.geeksforgeeks.org/lmns-gq/">GFG-LMNs</a> to prepare for DBMS, CN, OOPS, and OS concepts.
                        Practice on <a href="https://www.indiabix.com/">indiabix.com</a> for the Online Aptitude Round.
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Consistency is the key to Success. Regularly solve some coding challenges and participate in LeetCode’s Weekly and Bi-weekly challenges. Look for different approaches for every question and try to optimize the code as much as possible.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            Both the Technical Rounds included questions based on my projects. I described my DSA Project, a Vim Plugin: Version Controller, since it had a variety of concepts, including data structures and OS principles. The panelist questioned me on each of those topics. I was questioned regarding the improvements I could have made to my project. I was also asked about my prior internship experience.      </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Just be confident! The company needs confident, enthusiastic, and explorative candidates who are always keen to contribute towards development.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            It’s never too late. You are exactly where you are supposed to be. You only have to work for it; the rest will automatically fall in place. Life will throw curve balls at you, so you better learn to drift!
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:mankarsa22.comp@coeptech.ac.in">
                            mankarsa22.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default sarvesh_mankar;

import React from "react";

const ayush_ambhorkar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Ayush Ambhorkar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Arcesium</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>

                            <ol>
                                <li>
                                    Round 1 - Technical Round(40 mins)
                                </li>
                                <li>
                                    Round 2 - Technical Round(50 mins)
                                </li>
                                <li>
                                    Round 3 - Technical Round(70 mins due to some technical glitch)
                                </li>
                                <li>
                                    Round 4- HR Round(20-30 mins)
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude round had a few aptitude questions and 2-3 questions of DSA which included DP, Tree and  related algorithms.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ol>
                            <li>
                                <b>Round 1:</b> Basic questions of DSA like tree and its reversal and few questions on SQL queries were asked and also major questions on OOPs and pillars were asked as well.
                            </li>
                            <li>
                                <b> Round 2:</b> The round was 50 mins but the entire round was spent on DSA questions. The question was a completely new question. A few days before the interview Chandrayaan had landed and so the question was that you have a station on earth and you wish to connect to Chandrayaan but that cannot be done directly but indirectly through communication with many geostationary satellites in between. I was asked to tell what kind of inputs you will require and what should be the algorithm that you will follow. We had a discussion on this and the round went on until we were not able to reach on a possible solution.
                            </li>
                            <li>
                                <b>Round 3:</b> It was a full pack round. It covered DSA, a little bit of my projects, OOPS, DBMS and CN. Questions were just basics as they knew few of these topics were not covered in the college in the 2nd year but still a few basics that they expect from everyone were asked.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                Round 1 - 15
                            </li>
                            <li>
                                Round 2 - 7-8
                            </li>
                            <li>
                                Round 3 - 2
                            </li>
                            <li>
                                HR - 2
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                For DSA - InterviewBit - https://www.interviewbit.com/practice/
                            </li>
                            <li>
                                FOR OOPs - geekforgeeks
                            </li>
                            <li>
                                FOr OS and DBMS- https://www.youtube.com/playlist?list=PLDzeHZWIZsTrd4HFQ4-z8TmacD0OQxkmx
                            </li>
                            <li>
                                For CN - https://www.youtube.com/playlist?list=PLxCzCOWd7aiGFBD2-2joCpWOLUrDLvVV_
                            </li>

                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            The Interviews are not that scary when you are actually into one the period before and after that might become a bit scary. Before an interview distract yourself by just enjoying a few DSA questions (easy ones) they boost up your morale which is very important before an interview. After an interview do not think much about the previous interview you can't do anything about it now. So just start preparing for the next company. Finally during an interview never stop interacting. Even when you are thinking think it out loud cause the interviewers always gives his input when he knows what you are thinking which makes reaching the solution easier.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Not much focus was on these, a few questions were asked but none went very deep.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            As I already mentioned focus on DSA, OOPs, DBMS, CN and OS and during the interview think out loud and don’t hesitate asking questions if you have some, Interview gets to know that you are in the question when you start asking interesting questions.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            <b>Work Hard, Don’t Be Stressed and have fun during the process because it is!!!! </b>
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:ayushsa21.comp@coeptech.ac.in">
                            ayushsa21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default ayush_ambhorkar;

import React from "react";

const soham_naigaonkar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Soham Naigaonkar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Trading Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Aptitude round
                                </li>
                                <li>
                                    Single Interview round
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    10 questions on programming concepts
                                </li>
                                <li>
                                    2 coding problems
                                </li>
                            </ol>
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ol>
                            <li>
                                In the aptitude round, 10 questions were asked based on various programming concepts like Object-Oriented Programming, completing the program, predicting the output, etc. At the end, two coding problems were posed, which were based on core Java concepts like streams.
                            </li>
                            <li>
                                In the coding round, the interview focused on Java programming. No specific data structures and algorithms questions were asked. Instead, I was questioned about core Java concepts such as generics, OOP, interfaces, inheritance in java, the collections framework, multithreading, string manipulation, memory management in Java, and many other concepts. I had no clue about some concepts like multithreading, so my interviewer explained them to me briefly and then asked me to answer some questions logically. The interviewer suggested I read the book "Head First Object-Oriented Design," which helped me strengthen my understanding of some concepts before starting the internship.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        <ol>
                            <li>
                                After first aptitude round 9 were shortlisted.
                            </li>
                            <li>
                                After interview round 2 were selected for the internship
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Coding platforms like Leetcode and GeeksForGeeks <br />
                        For Java preparation - Kunal Kushwaha’s OOPS playlist on youtube <br />
                        For DSA - Strivers SDE sheet

                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Be thorough with basic data structures like arrays, linked lists, stacks, queues, hash maps, and trees.< br />
                            Understand algorithms, including sorting, searching, and dynamic programming. <br />
                            Learn basic techniques or data structures that can be used for multiple questions of similar pattern <br />
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            My job description was based on Java, and I had one project that I developed using Java. The interviewer asked me about that project, including the technologies used, databases, frontend-backend frameworks, and some questions about the implementation of the project to check its authenticity.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Include at least one project in your resume that aligns with your job description, and prepare to discuss it in detail during the interview.
                        </p>
                    </p>
                    <br />

                </div>
            </section >
        </div >
    );
};

export default soham_naigaonkar;

import React from "react";

const gaurish_dodke = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Gaurish Dodke
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>AlgoQuant</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    CGPA shortlisting: First students above 8.5 CGPA were shortlisted for the aptitude round. Students from the Computer and ENTC branch were considered for the role.
                                </li>
                                <li>
                                    PPT: On the day of interviews, in the morning we had PPT about the company, the role they were offering and other details.
                                </li>
                                <li>
                                    Aptitude Round: They held an offline aptitude round because they knew that students cheat for aptitude.
                                </li>
                                <li>
                                    Technical interview rounds: After aptitude rounds they held two interview rounds. Each selected candidate was interviewed twice no matter the outcome of the first interview round.
                                </li>
                                <li>
                                    Group Activity: After the interview round they gave a real world problem from their company that they wanted us to solve (or at least try to solve) in 1 hr.
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    The Aptitude round for this company was offline unlike any company before so this was a first for us.
                                </li>
                                <li>
                                    They gave us a question paper and we were supposed to solve the questions in the paper itself.
                                </li>
                                <li>
                                    There were 7-8 questions like predict the output, find errors, write one-line code for executing a particular function. These questions carried less marks.
                                </li>
                                <li>
                                    Then after that there were 3 DSA questions. For these we were supposed to write full code or atleast pseudocode/approach to solve the questions. DSA questions were standard, having easy to medium difficulty. They carried more marks.
                                </li>
                            </ol>
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ol>
                            <li>
                                Including Aptitude round there were 4 rounds (all technical).
                            </li>
                            <li>
                                After the test they released a list of students selected for interviews.
                            </li>
                            <li>
                                There were 2 interview rounds (both technical). All the selected students had 2 rounds regardless of the result of the first interview round.
                            </li>
                            <li>
                                In the first round, the interviewer reviewed the aptitude test and grilled me on why my answers were correct and wanted the thought process behind the solution. He reviewed every question and sometimes refused with me so as to know if my concepts were concrete or not. This round went on for 45-50 mins.
                            </li>
                            <li>
                                In the second round, the interviewer was a bit chill with me and started asking questions not to get answers but to discuss alternative solutions. This round was comparatively easier for me because all i was doing was discussing with the guy. At times if I was going in the wrong direction he would hint towards the correct approach but not give me the solution so as to keep the discussion going. This round went on for about 1.5 hrs (this is based on what others said, i genuinely don't know how much time he took but it was longer than the first round).
                            </li>
                            <li>
                                After the interview rounds, they shortlisted students for the last group activity. In this activity we were supposed to work together in a group like it happens in companies to solve a real world problem. They gave us 1 hr to do it. After the round they had questions for us which we answered based on our thought process and code (we had to code the solution).
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        <ol>
                            <li>
                                For aptitude they shortlisted about 20 students.
                            </li>
                            <li>
                                After the aptitude round they selected 8 students.
                            </li>
                            <li>
                                After interview rounds they selected 4 students.
                            </li>
                            <li>
                                After group activity they selected 3 students for the offer.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                DSA from code help, striver, aditya verma
                            </li>
                            <li>
                                Computer fundamentals from gfg
                            </li>
                            <li>
                                Review your second year projects.
                            </li>
                            <li>
                                Practice for aptitude questions and puzzles from indiabix, gfg.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practice as much as you can by solving questions. I wont tell you to do it daily because i know its hard but still work for it because many students lose out on good opportunities because of failing in aptitude rounds.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            No questions were asked about projects, clubs or any activity. They just didn't give a damn. Only thing they wanted to know is if I can code in cpp or not and how strong are my concepts with the language. PS: my role was Quantitative Developer which needs good knowledge of C++.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            When the listing of the company came out all i did was type the role of the company and what all i needed to do for its preparation on youtube. I got a video listing the concepts for the role and I started doing those things.<br />
                            Video: <a href="https://youtu.be/y6teK4Ma3iw?si=lau-lngRmiiaNP0Q">https://youtu.be/y6teK4Ma3iw?si=lau-lngRmiiaNP0Q</a> <br />
                            I found another channel and I started watching the videos of it and as it turned out majority questions in the aptitude test came from the videos I watched from the channel (pretty lucky huh). <br />
                            Channel: <a href=" https://youtube.com/@codinginterviewprep?si=cJpyYjW-XQdb1wF_"> https://youtube.com/@codinginterviewprep?si=cJpyYjW-XQdb1wF_</a> <br />
                            From these videos I was able to solve a pretty hard question (template meta-programming). I was the only one out of the 20 who was able to do it.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Have hope that you will be presented with an opportunity. I was rejected from 6-7 companies before I got offered. It's hard to face rejection but believe me it's a blessing in disguise. Everytime i got rejected i learnt from my mistakes in interviews and build on that. Rejections play a part in your success more than you would think. And yes luck plays a part but so does hard work. And when you are presented with an opportunity, take it.
                            Have faith in yourself and try for every company you get (on-campus/off-campus). I got the offer just before T2 of 6th sem. I also saw people get internship during endsems just before summer break. Have confidence and believe that you will get through this.
                            Talk to parents, friends but most importantly yourself.
                            All the best to you!
                        </p>
                    </p>
                    <br />
                </div>
            </section >
        </div >
    );
};

export default gaurish_dodke;

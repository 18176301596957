import React from "react";

const purav_desai = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
           Purav Uday Desai
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
           Barclays
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />
          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            
            <p>The internship hiring process for Barclays took place in two stages:</p>
            
            <ol style={{marginLeft: "2rem"}}>
                <li>Online Assessment</li>
               <li>Interview Round </li>
            </ol>
            <strong><u>Pre-Placement Talk (26th October 2021 9:00AM):</u></strong>
            <p>There was a Pre-Placement Talk conducted at 9:00 PM for 1 hour on the same day as the online assessment.</p>
            During the Pre-Placement Talk, the following things were discussed:
            <ol style={{marginLeft: "2rem"}}>
                <li>
                Values (Respect, Integrity, Service, Excellence, Stewardship) and mindset (Empower, Challenge, Drive) of Barclays.
                </li>
                <li>
                BGSC: Barclays Global Service Centre
                </li>
                <li>
                Major areas of work:
                    <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                        <li>Machine Learning/Artificial Intelligence</li>
                        <li>Data Platform</li>
                        <li>Data Analytics</li>
                        <li>Marketing Technology</li>
                        <li>Digital</li>
                        <li>Strategy and Execution</li>

                    </ul>
                </li>
                <li>Value Proposition (Globally Connected, Inspired, Impactful, Valued)</li>

            </ol>
          </p>
           <strong><u>Online Assessment (26th October 2021 7:00PM):</u></strong>
         <p>This assessment was conducted on HackerEarth over the course of 1 hour 30 minutes.
          </p>
          <strong><u>Interview Round (29th October 2021):</u></strong>
          <p>In this round, 15 candidates were shortlisted for interview. Each interview lasted 30 minutes.</p>




          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <ol>
                <li>The entire test was conducted on HackerEarth online.</li>
                <li>There were 30 MCQ questions and 2 programming questions in the online assessment. It lasted 1 hour and 30 minutes.</li>
                <li>The MCQs and programming questions were moderate in difficulty and dealt with Technology and Logical Reasoning.</li>
                <li>Most MCQ questions were theoretical and predict the output.</li>
                <li>
                To be on the safe side, I recommend that you learn the basic syntax and semantics of C, C++, Java and Python
                </li>
                <li>Try to read as many archives as you can from the<a href="https://www.geeksforgeeks.org/tag/barclays/"> GeeksForGeeks Archive for Barclays</a>. Although I don't remember exactly, I think one programming question from the archive was asked in the test. (Not only for Barclays for any company do read the GeeksForGeeks Archive).</li>
                <li>In my experience, Barclays does not ask for arithmetic aptitude or data interpretation skills or logical, verbal, nonverbal reasoning.</li>
            </ol>
            <strong><u>MCQ Questions primarily consisted of:</u></strong>
            <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                <li>C++ and Java (predict the output)</li>
                <li>DBMS</li>
                <li>OOP</li>
            </ul>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
           <p> The company conducted only one interview round, involving HR and technical.</p>
            <ol style={{marginLeft:"2rem"}}>
                <li>My interview was conducted online using WebEx, and only one interviewer was present; however, some other candidates had two or more interviewers with them.</li>
                <li>The interviewer first asked me to introduce myself.</li>
                <li>Since I had previously done an internship, the interviewer then asked me about my job role there as well as about the work I did there.</li>
                <li>I was then asked to describe the project that I had listed on my resume.</li>
                <li>Then I was asked to demonstrate any of my projects live during the interview.</li>
            </ol>
            <strong style={{marginLeft:"1rem"}}><u>NOTE:</u></strong>
            <ol style={{marginLeft:"2rem"}}>
                <li>Do good projects and list them in your resume.<b style={{fontWeight:"500"}}><i> Putting small projects on a resume just to make your resume appear bigger does not count</i></b>, but it can land you in trouble in some cases.</li>
                <li>Create a good readme including <b style={{fontWeight:"500"}}><i>Tech Stack, Project Workflow, Software Architecture, Project Design, Need/Motivation of project, Prototype of project, Working demo video, and screenshots</i></b>. (Don't copy openly available architectures and plans; make them on your own).</li>
                <li>DO NOT copy any available project and paste that on your resume that you haven't done.</li>
                <li>Make sure you understand the complete working of the projects listed on your resume. </li>
                <li>At the time of the interview, <b style={{fontWeight:"500"}}><i>be ready with your projects, the interviewer could ask you to give a demo live in front of him/her</i></b></li>
            </ol>
            <strong style={{marginLeft:"1rem"}}><u>INTERVIEW 101:</u></strong>
            <ol style={{marginLeft:"2rem"}}>
                <li>Do not feel stressed or tense or nervous,<b style={{fontWeight:"500"}}><i>be comfortable.</i></b> Interviewers are also humans, and especially in 3rd-year interviews, they will probably ask about your projects, internships, etc. so you just need to summarize what you accomplished.</li>
                <li>Dress professionally when attending interviews or group discussions.</li>
                <li>Instead of speaking in circles, summarize in a way that the interviewer understands the complete picture in 1/2 paragraphs.</li>
                <li>You should prepare 1 paragraph for each project. This way, if someone asks, you will be able to describe it clearly and precisely. (Be human while speaking this, it should not sound like you have learned the whole paragraph and you are just blabbering it in front of them). </li>
                
            </ol>
          </p>
          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <br />
            <p>
            A majority of the interview questions were centred around projects.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
             <b style={{fontWeight:"600",fontSize:"17px"}}><u>For Barclays specially:</u></b>
             <ol style={{marginLeft:"2rem"}}>
                <li>
                    GeeksForGeeks
                    <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                        <li>Predict the outcome problems</li>
                        <li>Puzzle problems</li>
                        <li>Barclays GeeksForGeeks Archive</li>
                        <li>Theory questions</li>

                    </ul>
                    </li>
                    <li>HackerEarth
                    <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                        <li>Practice some problems there so that you get comfortable with the platform</li>
                        <li><a href="https://assessment.hackerearth.com/challenges/test/programming-practice-challenge/)">Attend Practice Tests </a></li>
                       
                        

                    </ul>

                    </li>

             </ol>
             <b style={{fontWeight:"600",fontSize:"17px"}}><u>In General: </u></b>
             <ol style={{marginLeft:"2rem"}}>
                <li>Indiabix (aptitude and logical problems)</li>
                <li>HackerRank (coding problems)</li>
             
             </ol>
         
          


            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
            <ol>
                <li>GeeksForGeeks Barclays Archive</li>
                <li>HackerEarth problem statements</li>
                <li>Be good with Data Structures and Algorithms</li>
                <li>Practice on coding platforms(HackerRank, HackerEarth, LeetCode, CodeChef, etc)</li>
             
             </ol>
             <strong><u>NOTE:</u></strong>
             <p>Choose any one platform, practice there daily, don’t switch between platforms each day due to peer pressure, or because your friend practices there.</p>
          


            </p>
          </p>
          
          <p>
          <b>
             7. Is there something specific that you would want to tell people who try for the same company?
            </b>
            <br />
            <ol>
                <li>Try to learn Java, Client-Server programming, Web services, DBMS, basis of Cloud computing. </li>
                <li>In addition, try to build some nice projects using the above Tech Stack (and if not possible, build something you feel good about, like MERN, Django, React Native, Flutter, etc).</li>
            </ol>


          </p>
          <br />
          <br />
          <p>
            <i>
                <strong>Resume Building</strong>
                <ol style={{marginLeft:"2rem"}}>
                    <li>Your resume is the most important aspect of an internship/placement, if it's not good, your placement won't be good </li>
                    <li>The description of the projects/work experience should be in bullet points, not in a paragraph.</li>
                    <li>A resume should ONLY be one page in length, an extra line also is not allowed.</li>
                    <li>Use action verbs in your resume like: <a style={{maxWidth: "50px"}} href="https://www.themuse.com/advice/185-powerful-verbs-that-will-make-your-resume-awesome">Visit here</a></li>
                    <li>You can scan and get feedback on your resume from here: <a href="https://www.vmock.com/">https://www.vmock.com/</a> </li>
                    <li>Try to get more than 75% in the resume scan on vmock (<span>&#62;</span>=75% is good).</li>
                </ol>

                <strong>Project Presentation:</strong>
                <ol style={{marginLeft:"2rem"}}>
                    <li>Name the GitHub projects nicely (not anything random or illogical), add description, tags, etc in your projects. </li>
                    <li>
                    README.md file should include:
                    <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                        <li>Tech Stack </li>
                        <li>Project Workflow </li>
                        <li>Software Architecture</li>
                        <li>Project Design</li>
                        <li>Need/Motivation of project</li>
                        <li>Prototype of project</li>
                        <li>Working demo video</li>
                        <li>Screenshots</li>
	

                       
                    </ul>
                    </li>
                    <li>If you(monetarily and technically)can host your application live, then better.</li>
                  
                   
                </ol>
                <strong>Ending Note:</strong>
                <p>I apologize for anything you thought that offended you or sounded inappropriate to you. Be Happy and keep smiling. </p>

            </i>
            </p>

            <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:puravud20.comp@coep.ac.in">
               puravud20.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/PuravUdayDesai/">
              LinkedIn Handle
            </a>
            <br />
            <a href="https://github.com/PuravUdayDesai/">
                GitHub Profile
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default purav_desai;

import React from "react";

const rohit_magar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Rohit Magar</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>BNY Mellon</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              It was a 3-round process in total and allowed branches were
              Computer, EnTC, Electrical, and Mechanical with CGPA criteria of
              7.
            </p>
            <ol>
              <li>Coding round (4 coding questions)</li>
              <li>Technical Interview Round</li>
              <li>HR cum Technical (for me)</li>
            </ol>
            <b>Structure of Aptitude Round :</b>
            <br />
            The aptitude test was conducted on Hackerrank on 27 August 2022. It
            had <b>4 coding questions</b> that needed to be solved in 90
            minutes. The questions were quite easy but required optimizations to
            pass all test cases with the given constraints. I solved the first
            one completely and others were partially accepted. There was no
            section on Quantitative and Technical MCQs.
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <ol>
              <li>
                <b>Coding round: </b>It was the aptitude test as explained
                above. The questions were:
                <ol>
                  <li>
                    It was related to finding the number of pairs of strings that
                    satisfy a certain condition. Here the catch was to use the
                    nC2 formula after finding the feasible number of strings for
                    each string instead of iterating with 2 loops
                  </li>
                  <li>
                    Given 2 vectors of strings, return a boolean array if it is
                    possible to convert the ith string of the first vector to
                    that of the second. Operations allowed were:
                    <br />
                    1.You can swap 2 adjacent characters.
                    <br />
                    2.Increment every character if it is not ‘z’ in a substring
                    of size k (this is to be fixed for that particular ith
                    string)
                  </li>
                  <li>
                    Given were an int x and an array and some queries. In each
                    query you have L and R, you have to find the number of
                    divisors of x in the range [L,R] of given array.
                  </li>
                  <li>
                    Given an integer x, an array, and 2 types of queries in an
                    array. The size of the array and query array could go up to
                    105. Queries were-
                    <br />
                    1. Type 1: Update the value at an index
                    <br />
                    2. Type 2: Given k, L and R, all integers less than x in the
                    range [L,R] should be updated to k.
                    <br />
                    Return the final array after these operations.
                  </li>
                </ol>
              </li>
              <li>
                <b>Technical interview: </b> This round was more inclined
                towards Database concepts and writing some queries. I was also
                asked about OOPS concepts, more specifically about
                Encapsulation, Inheritance, and Polymorphism. Asked me to write
                classes using inheritance and some basic questions to check my
                fundamentals.
                <br />
                Some of the questions he asked were: what are .exe and .dll
                files, queries on joins, why BNY(started with this), indexing in
                database.
              </li>
              <li>
                <b>HR interview: </b>It was an HR round but I was asked all
                technical questions here too. Starting with writing code for
                Quicksort on-page, he asked some questions about my projects and
                also if I have worked in a team before. One interesting question
                he asked was if you are given an array how would you encrypt it?
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              289 students had appeared for the aptitude test. Out of which{" "}
              <b>18</b> were shortlisted for technical interviews.
              <br />
              <b>14 were given the final offer.</b>
            </p>
          </p>
          <p>
            <b>
              4. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <p>
              I was asked 1-2 questions on my projects and a small talk on the
              BHAU’s E-cell of which I was a member, in the HR round.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              I started with competitive programming in the first semester
              itself(though not regular). This has helped me over the period of
              time in bringing consistency, not giving up on a problem, and
              solving it in a limited time frame. There is still a lot more for
              me to improve. I practice it on codeforces, codechef mainly. Then,
              I shifted to Leetcode in my 4th semester and was quite consistent
              over there.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Be calm and avoid distractions. Try to break down the problem into
              a simpler version of something that you have already solved or try
              building solution of the smaller problem.
            </p>
          </p>
          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company :
            </strong>
            <p>
              Having sound knowledge about DBMS would help and it’s just the
              fundamentals that they test in your interview. Clarify with the
              interviewer if something is not clear and it is good to say ‘NO’
              rather than answering false and thinking that it would work.
            </p>
          </p>
          <p>
            <strong>8. Final Tip for Juniors :</strong>
            <p>
              You should be well aware of the standard DSA questions for that
              you can try solving questions from Striver’s SDE sheet or the
              Final 450 by Love Babbar. Apart from this, continuous practice
              will make a difference in your preparation. Don’t regret not
              preparing OOPS after your first interview, this is a must-do topic
              and you should be clear with all in and out of it. If you are
              doing it in C++ Saurabh Shukla’s playlist is all you need for
              OOPS.
              <br />
              You can follow this sequence for learning theory topics-
              <br />
              <br />
              1. OOPS
              <br />
              2. DBMS, CN
              <br />
              3. OS
              <br />
              Also, make sure to read 50-60 popular questions asked on the above
              topics from the web.
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:magarrr20.comp@coep.ac.in">
              magarrr20.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default rohit_magar;

import React from "react";

const mudit_bapna = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Mudit Bapna</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              There was a total of 3 rounds in the entire hiring process after
              about 50 candidates were shortlisted based on their resume. The
              rounds were as follows:
            </p>
            <ol>
              <li>
                <p>
                  <strong>Group discussion round:</strong>
                  <p>
                    50 students were divided into 5 groups of 10 students each
                    and topics were given for group discussion. The topic that
                    my group received was “What matters in professional success
                    in early career, <strong>A.</strong> Details or{" "}
                    <strong>B.</strong> Big Picture?
                  </p>
                  <ul style={{ marginLeft: "1.5rem", listStyleType: "disc" }}>
                    <li>
                      25 seconds per head, non-resume listed content about us{" "}
                    </li>
                    <li>
                      8 minutes in all, group discussion on the said topic,
                      aggression was fine.{" "}
                    </li>
                    <li>
                      20 seconds per head, where our opinion was asked on
                      another topic. We had to speak for ourselves, not a GD{" "}
                    </li>
                    <li>
                      30 second per head, why round, explain one item from
                      resume in terms of Why you did it?
                    </li>
                  </ul>
                </p>
              </li>
              <li>
                <p>
                  <strong>Written Test:</strong>
                  <p>
                    After the first round 25 candidates were shortlisted for the
                    written test in which we were given two coding questions. We
                    had to write the code on paper and upload it.
                  </p>
                </p>
              </li>
              <li>
                <p>
                  <strong>Interview:</strong>
                  <p>
                    This was the 3<sup>rd</sup> and final round which was a mix
                    of both Technical and HR questions
                  </p>
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              There was no aptitude test taken. The first selection was based on
              resume of the candidates.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              As mentioned above we had only one Interview round. My interview
              went on for about 30 minutes. The interviewer was quite friendly.
              He started of with some basic “break the ice” kind of questions
              and made me feel comfortable. Then he proceeded with tech
              questions on trees and graphs. Then as he had seen my resume and
              one of my project was ‘Algorithm Visualizer’ he started asking
              questions on the project. He asked me the difference between BFS
              and DFS and told to explain its pseudocode. Then he told me to
              explain the pseudocode on how I will detect a cycle in a graph.
              Further he asked questions on time-complexities of some search
              algorithms. He then told me to explain Dijkstra’s Algorithm in
              detail. He then asked some questions on the second project which I
              had mentioned i.e. Implementation of FIND command. This was
              basically the Tech round part of my Interview which ran for about
              25-30 minutes.
            </p>
            <p>
              Then he basically started asking HR questions based on leadership
              and team management. Being HEAD of the Creatives Portfolio in CSI
              helped me explain my leadership skills. I was also a volunteer in
              the Robin Hood Army which basically works to get surplus food from
              restaurants and the community to serve less fortunate people. So
              mentioning this kind of impressed the interviewer.
            </p>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <p>
              As answered above my interview was wholly based on my resume and
              projects mentioned.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <p>
              I used Geeks for Geeks for my coding practice and IndiaBIX for
              quantitative and reasoning questions.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Practice as many questions as possible. Practice as many types of
              questions as possible. Practice Practice Practice.
            </p>
          </p>
          <p>
            <i>
              <p>
                Chances are there that you may get rejected at various stages
                for different companies Please don’t get disheartened if you
                don’t get selected in initial few companies. Be confident and
                most importantly KEEP SMILING.{" "}
              </p>
              <p>
                Great things are in store for you so keep hope. Best of luck!
              </p>
            </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            {/* <a href="mailto:nairas18.comp@coep.ac.in">
              nairas18.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/anupn08/">LinkedIn Handle</a> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default mudit_bapna;

import React from "react";

const simran_kucheria = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Simran Kucheria
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>
              1. What was the structure of the entire internship hiring process?
            </b>
            <br />
            Goldman Sachs started their aptitude and interview procedures in the
            last week of August. The aptitude test was conducted on 27th August
            and the interviews were held on 31st August after the pre placement
            talk.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test was 135 minutes long and divided into 5 sections -
            Coding, Computer Science MCQs, Quant MCQs, Advanced Programming and
            a Subjective section(essay). The Coding section was pretty straight
            forward with 2 questions based on application of the distance
            formula. The Computer Science MCQs had 7 questions and was based on
            general questions from DSA, MPT etc. There were 8 Quant MCQs based
            on topics like - probability, permutations and combinations,
            derivatives etc. The Advanced Programming question was a Dynamic
            Programming problem with a time limit of 45 minutes.
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            The interview rounds were different for different people but most of
            us had atleast 3 interviews. I gave 2 Tech and 1 HR/Tech interviews.
            All 3 interviews were conducted via code pair and some questions did
            involve live coding. The tech interviews touched on a lot of
            fundamentals from DSA and some questions from DBMS and OS. There
            were questions on efficiency of string searching algorithms and how
            to improve them, implementation of Min Heap from scratch, Linked
            list, SQL queries etc. The HR/Tech intervew had some questions on
            recursive programming followed by math puzzles. Each interview was
            50 to 70 minutes long.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            For all my interviews very few questions were asked based on my
            resume, but the interviewers did give me a chance to talk about
            certain projects listed my resume. One interviewer in particular
            chose to ask about the database project on my resume and then asked
            DBMS questions using that as an opening. In another interview they
            asked me about a Deep Learning Project that I had done and wanted to
            know what was the core idea and how I approached the issue.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />I went through{" "}
            <a href="https://hackerrank.com">HackerRank's</a> interview
            Preparation Kit and looked up concepts from{" "}
            <a href="https://geeksforgeeks.com">GeeksforGeeks</a>. I also went
            through a lot of my code from DSA assignments and tutorials that
            demonstrated most of the important data structures and how to write
            them from scratch.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Practice as many coding questions as you can, that will help to
            improve your speed and hone your thinking skills. Try to solve a lot
            of Dynamic Programming questions as that was something that wasn't
            really covered in classes at that point of time. Get familiarised
            with platforms like HackerRank. Also do practice all your languages
            as some aptitude tests were language specific. Sometimes a program
            might not be able to pass all the test cases, if you're really
            running short of time, brute-forcing your way to pass some of the
            test cases might be a good option.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            Goldman Sachs was the first company that came to the college and my
            experience with them was very pleasant. The interviewers were very
            nice and even when I was struggling with some of the questions, they
            gave me enough time to think it through. They are usually very open
            to giving feedback as well, so if time permits you could ask them
            about that.
          </p>

          <p>
            <br />
            <i>
              I understand that giving your first interviews would make you feel
              very nervous, but the most important thing is to remain calm
              during interviews. In most cases how you respond sets the tone for
              the rest of the interview and if you respond smartly you can lead
              the interviewer to the topics that you're well versed in. Keep on
              vocalising your thoughts/feelings and approaches regarding
              problems even if you aren't sure about the solutions. Best of luck
              and hope you all ace it!!
            </i>
            <br />
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:kucherias18.comp@coep.ac.in">
              kucherias18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default simran_kucheria;

import React from "react";

const mihika_dravid = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Mihika Dravid
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Schlumberger</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              Aptitude Test, 2 Technical Rounds, 1 Managerial Round and 1 HR Round
            </p>

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              2 Coding Questions, DSA questions and predict the
              output MCQs(in Java)
            </p>
          </p>

          <p>
            <b>3. How many rounds were there for the company, can you elaborate about each
              round in brief</b>
            <p> Aptitude Test was 1.5 hours with some basic DSA mcqs and
              predict the output questions along with 2 coding questions of easy - moderate
              difficulty. Then there are 4 interview rounds that happen in a random order
            </p>
            <ol>
              <li>
                The 2 Technical interview rounds consist of questions about your projects,
                code/pseudocode of some problems and DBMS, CN questions
              </li>
              <li>
                The Managerial interview consists of questions about your resume and is
                supposed to test whether you are well suited for the company.
              </li>
              <li>
                The HR round consists of questions about your future goals, situational
                questions, strength and weaknesses, etc
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            <p>
              17 people were
              shortlisted for the interview rounds and 2 students received the internship offer
            </p>
          </p>

            <p>
              <b>
                5. Some good resources you used in your internship prep
              </b>
              <br />
              Leetcode for coding
              questions, indibix for aptitude and Cracking the Coding Interview
            </p>

            <p>
              <b>
                6. Some tips to crack coding questions
              </b>
              <br />

              <p>
                Try to practice as many questions as you
                can and go through previous questions specific to that company
              </p>
            </p>

            <p>
              <b>7. How many questions were asked about projects, clubs and extracurricular
                activities in your resume</b> <br />
              <p>
                All of the interview rounds to a certain extent revolve
                around your resume and a lot of in-depth questions were asked about my
                projects
              </p>
            </p>
            <p>
              <strong>
                8. Is there something specific that you would want to tell people who try for the
                same company
              </strong>
              <p>
                The interviewers are really helpful and a lot of times don’t care if
                your answer is correct on the first attempt, so try to explain your thinking process
                to them and don’t be afraid to ask them for help.
              </p>
            </p>
            <br />

            <h3>Contact Info:</h3>
            <div style={{ color: "gray", fontSize: "1.15rem" }}>
              <br />
              Email-ID : <a href="mailto:dravidms20.comp@coep.ac.in">
                dravidms20.comp@coep.ac.in
              </a>
              <br />
              LinkedIn : <a href="https://www.linkedin.com/in/mihika-dravid-20a909205/">Mihika Dravid</a>
              <br />
            </div>
        </div >
      </section >
    </div >
  );
};

export default mihika_dravid;

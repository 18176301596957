import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import CSILogo from "../assets/img/logo/csi.png";
import COEPLogo from "../assets/img/logo/coep_dark2.png";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.typography.pxToRem(30),
    textAlign: "center",
  },
  heading: {
    paddingBottom: "0",
    // fontFamily: "Roadtest, Varela Round",
    // fontFamily: "Nerko One",
    // letterSpacing: "3px",
    fontSize: theme.typography.pxToRem(55),
    // fontWeight: theme.typography.fontWeightBold,
    color: "#ffffff",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
        fontSize: theme.typography.pxToRem(30),
    },
  },
  logo: {
    width: "135px",
    height: "135px",
    margin: "0 1.25rem",
    [theme.breakpoints.down("md")]: {
      width: "80px",
    height: "80px",
  },
      },
}));

const HeroHeading = () => {
  const classes = useStyles();

  const PcHeading = () => (
    <>
      <Grid item>
        <a
          href="https://www.coep.org.in/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={classes.logo} src={COEPLogo} alt="COEP CSI logo" />
        </a>
      </Grid>

      <Grid item>
        <div>
          <div className={classes.root}>
            <h2 className={classes.heading}>
              COMPUTER SOCIETY OF INDIA<br></br>COEP Tech STUDENT CHAPTER
            </h2>
          </div>
        </div>
      </Grid>

      <Grid item>
        <a
          href="http://csiindia.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={classes.logo} src={CSILogo} alt="CSI logo" />
        </a>
      </Grid>
    </>
  );

  const MobileHeading = () => (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <img className={classes.logo} src={COEPLogo} alt="COEP logo" />
          <img className={classes.logo} src={CSILogo} alt="CSI logo" />
        </Grid>
      </Grid>

      <Grid item>
        <div className={classes.root}>
          <h2 className={classes.heading}>
            Computer Society of India<br></br>COEP Tech Student Chapter
          </h2>
          {/* <h2 className={classes.heading}>CSI COEP Student Chapter</h2> */}
        </div>
      </Grid>
    </>
  );

  const TabletHeading = () => (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <img className={classes.logo} src={COEPLogo} alt="COEP logo" />
        </Grid>

        <Grid item>
          <img className={classes.logo} src={CSILogo} alt="CSI logo" />
        </Grid>

        <Grid item>
          <div className={classes.root}>
            <h2 className={classes.heading}>
              Computer Society of India<br></br>COEP Tech Student Chapter
            </h2>
          </div>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="row"
      style={{ marginTop: "1rem" }}
    >
      {isMobile ? (
        <MobileHeading />
      ) : window.innerWidth > 768 && window.innerWidth < 1280 ? (
        <TabletHeading />
      ) : (
        <PcHeading />
      )}
    </Grid>
  );
};

export default HeroHeading;

const comp = [
  {
    question:
      "How much importance do I give to projects and what amount is considered good?",
    answer:
      "Projects play an important role, and most of the interviewers will definitely ask about the projects done. Quality matters more than quantity. Be thorough with the projects you have done. You have to have at least a few (2-3) decent projects in order to pass standard screening. Only mention those projects on which you can answer all sorts of questions. If your interviewer asks you about your project, pitch it like a simple story. If any of your projects is related to the kind of projects the company is offering, you have a good chance of getting in. Fewer (around 2-3) good projects has much greater value over many small projects.",
  },
  {
    question:
      "Should I go for online certifications for courses and programmes?",
    answer:
      "You can have online courses in free time for your personal development but it is not as important for now. In case you have completed any course and mentioned it in your CV then you must have in depth knowledge about that. Focus more on acquiring skills, than getting certificates. If you have time, go for it! Just be very careful while choosing a course. There is an ocean of courses out there and not all are meant for you. Read the prerequisites and course outline well before going about it. Choose those that have a hands-on approach and give assignments.",
  },
  {
    question: "How much do extracurricular activities matter?",
    answer:
      "They do matter in subtle ways. Speaking skills, body language, greetings, conversation starters, etc. Not having any extracurriculars is not a deal breaker; however having them is a good bonus. Depends highly on the interviewer and having at least one activity separating you from academics is a plus point. Having them is definitely a plus but that does not mean they have a point over technical knowledge. Co-curricular activities matter more than extracurricular. As a notable exception, quite a few interviewers care about activities that demonstrate your leadership skills.",
  },
  {
    question: "Resources for preparation in summer?",
    answer:
      "Geeks for geeks, Build one strong language for yourself for competitive programming. You need to do competitive programming. Revise DSA, OOPs and DC. Geeks for geeks for aptitude questions (they have questions banks). HackerRank Interview Prep Kit for coding related questions. RS Aggarwal book for logical reasoning, CTCI (Cracking the coding interviews book). Minimum five questions for each topic from CTCI.",
  },
  {
    question: "What are some pointers for resume building?",
    answer:
      "No resume more than 1 page, avoid fancy colors, etc. Bold out keywords that you want to emphasize on. Personalize your resume as per each company’s requirements or the job you are applying for. If you know the company is into ML work, mention more ML stuff in your summary, objective and projects sections. The order of resume is Objective (A little info about you and what you aspire to do), Education, Experience (Curricular) [Tech related clubs, Internships], Experience (Extracurricular) [Club/Event work, other extracurricular achievements], Projects, Contact Details. Make use of bold, caps, italics properly. Choose an attractive and legible font like Verdana, Arial, Helvetica, and Cambria. Name your file properly (Eg. ‘John_Smith_Resume’). Always share the pdf file of your resume. Use tools like Latex or Adobe Illustrator over MS Word.",
  },
  {
    question:
      "How many students are able to get internships through college? Should we try for companies outside as well?",
    answer:
      "Almost 70 to 80 percent (from Comp & IT) get Internships from College. If you want, you can definitely try from outside for companies which do not come to campus. Companies that come to college have generally been coming before and you can talk to seniors regarding the work and culture. Try for outside only if you do not end up getting through college. Unless the outside company is a reputed company which is used to taking interns, your experience may not turn out to be very good.",
  },
  {
    question:
      "What were the companies that visited campus for internships last year (2019-20)?",
    answer:
      "DE Shaw, Arcesium, Goldman Sachs, Credit Suisse, Standard Chartered Bank, FinIQ, MasterCard, Citi Bank, Schlumberger, Gilbarco, Bajaj Finance, UBS, Barclays, Persistent Systems, Wolters Kluwer, NTT Data.",
  },
  {
    question:
      "What is the general structure followed by the companies for shortlisting candidates?",
    answer:
      "The performance in the Aptitude tests matters for going forward to more rounds. The general structure is Aptitude Test, Technical Round(s) and finally HR Round(s). This can vary from company to company.",
  },
  {
    question: "Are aptitude tests specific language oriented?",
    answer:
      "It may happen in extremely rare cases. However, having knowledge of a modern OOPs-based language, specifically Java, C++ or Python will save you A LOT of time with their in-built libraries and functions. Use Python only if you are already really good at it, but it tends to be slower than the first two for similar algorithms.",
  },
  {
    question: "Is there any CGPA criteria?",
    answer:
      "There is a CGPA criteria for sitting for Aptitude test. Usually it is 7, but try to maintain > 7.5 The more, the better :)",
  },
  {
    question: "What platform is used to conduct the tests?",
    answer:
      "Predominantly HackerRank, but some of them have their own interfaces as well. 99% of them have similar layouts however, so do not fret much about the particular platform. Before the aptitude however, make sure you know whether using documentation is allowed. Some companies allow this, while others treat any attempt to switch tabs as a copy case.",
  },
  {
    question: "Are there any specific requirements for top-tier companies?",
    answer:
      "There is no specific requirement for top tier companies. To make yourself familiar with what skills companies are looking for, check out their job offers page for the internship listing you are attempting.",
  },
  {
    question:
      "What is the importance of soft-skills and communication during further rounds of interviews?",
    answer:
      "Very Important, if you cannot showcase/explain your solutions, then it's gonna be difficult. The bigger companies especially do not take anyone who is not able to communicate fluently or get his point across easily. They are important from the very first round. If you cannot convey what you have in mind, in a proper, concise manner, you can create a bad impression on the interviewer. It’s important factor for many banks that come to hire interns. Additionally, even though it does not have much to do with programming, they do care about your English. Make sure you prepare yourself for the commonly asked questions before going for the interview.",
  },
];

export default comp;

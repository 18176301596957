import React from "react";

const shruti_ravichandran = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Shruti Ravichandran
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>mastercard</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The interview process comprised 4 rounds in total.                        </p>
                        <p>
                            <ol>
                                <li>Coding test</li>
                                <li>Technical round 1</li>
                                <li>Technical round 2</li>
                                <li>HR round</li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The 1st online shortlisting round just consisted of 2 questions.
                            It was held on ‘HirePro’.<br />
                            One of which was an easy string manipulation question which involved usage of maps,while the second was of higher difficulty and involved a Dynamic programming approach(it was some variation of knapsack problem).

                            <br />
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 3 rounds post the online assessment shortlists.
                        <br />
                        <ol>
                            <li>1st technical round:<br />
                                I was asked  to share a bit about myself and the coding languages I'm familiar with. After that, I was tasked with writing simple code, like reversing a string or extracting words from a sentence.
                                <br />I faced a range of questions covering fundamental concepts in OOP, DBMS, SDLC, OS, and Java basics (I made it clear that my Java knowledge is basic, so they didn't probe too deeply into it). Additionally, there were basic questions on data structures like stack, queue, and I was asked to solve a puzzle.
                                <br />I also had to share details about one of my projects, outlining what I did. They also asked about any issues I faced during the project, both technically and as part of the team.
                            </li>
                            <li>Technical Round 2:<br />
                                Round 2 felt much like Round 1 but with a focus on computer networks. I had to dive deep into my project, explaining thoroughly a part of its working on paper with an example.
                                <br />There were questions on concepts like authorization versus authentication, encryption and decryption, and details about encryption algorithms,because of  the usage of the authentication module in my django based project. The key was to be mindful of my answers, stay confident, and be ready for follow-up questions building on what I said earlier.
                            </li>
                            <li>HR round:<br />
                                This round was much more relaxed compared to the first 2 rounds—He asked the usual questions like "Tell me about yourself," "Why Mastercard?" ,a self-rating question,strengths and weaknesses and so on .
                                <br />Paying attention during the PPT and having straightforward answers for these common questions helped. Just be confident, and you're good to go in the HR round.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After OA 62 students were shortlisted.
                        <br />Around 30 students qualified for technical round  2.
                        <br />And finally 13 were selected for internship.

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Love babbar(450 questions sheet)<a href="https://450dsa.com/"> https://450dsa.com/</a>
                        <br />Striver’s(Dynamic programing and binary search playlist)
                        <br />GFG notes for DBMS,CN,OS
                        <br />aptitude:<a href="https://www.indiabix.com/">https://www.indiabix.com/</a>
                        <br />Leetcode 75
                        <br />Puzzles <a href="https://www.geeksforgeeks.org/puzzles/">https://www.geeksforgeeks.org/puzzles/</a>

                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Make a habit of consistent practice, categorising questions into specific patterns. Most problems follow a particular logic.Prioritise writing pseudocode before diving into coding.
                            <br /> Also, be aware that some platforms may not support the latest version of your preferred coding language, so keep that in mind during problem-solving.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            Technical rounds focused heavily on project-related questions, so ensure you're well-versed with your projects. However, there weren't specific inquiries about clubs or extracurricular activities.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Check out the past interview experiences of the company on GeeksforGeeks (GFG) and practice company-specific questions on sites like Coding Ninjas.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Make sure that you fill all the details in your resume with utmost care and only put in the things you are 100% confident about.
                            <br />Don’t fake it if you don’t know something, admit it and try to divert the question to something that you know.
                            <br />Ask questions to your interviewer after every round. It gives a good impression as interviews are not supposed to be one way.
                            <br />The internship season is a stressful time so be prepared, you might face a lot of rejections but the best is always yet to come.
                            <br />Apart from hard work,luck also plays a huge role so don’t doubt your capability and just keep working towards your goal.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:shrutir21.comp@coeptech.ac.in">
                            shrutir21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default shruti_ravichandran;

import React from "react";

const rukmini_nazre = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Rukmini Nazre
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>TCS Research </h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            Resume shortlisting followed by an online interview.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            There was no aptitude round.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ol>
                            <li>
                                Resume shortlisting: 6 students were shortlisted based on their profiles and projects. They were looking for students who had ML/DL related experience.
                            </li>
                            <li>
                                Online interview:
                                The interview lasted for around half an hour. I was asked questions based on my projects. I had two projects involving CNNs, so I was asked to explain the architecture of Unet, FPN etc. I also had a project related to text summarisation, so I was asked to explain the algorithm we had used and about different state of the art summarisation algorithms and NLP libraries in python.
                                I was also asked a few questions about LLMs and my experience with generative AI as well as whether I had any previous research experience.Then I was asked to solve this puzzle : <br />
                                <a href="https://www.geeksforgeeks.org/puzzle-1-how-to-measure-45-minutes-using-two-identical-wires/">https://www.geeksforgeeks.org/puzzle-1-how-to-measure-45-minutes-using-two-identical-wires/</a>
                                After this, there were a few generic HR questions about my further plans, availability, fields of interest for project topics etc.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        6 students were shortlisted based on their resumes and 3 were given the final offer after online interviews.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        For aptitude questions - <a href="https://www.interviewbit.com/coding-interview-questions/">https://www.interviewbit.com/coding-interview-questions/</a>
                        <br />
                        <br />
                        For coding problems - LeetCode, Striver’s playlist, NeetCode
                        <br />
                        <br />
                        Overall concepts and theory - Geeksforgeeks, Abdul Bari’s DSA playlist
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practice regularly with time constraints. Focus on dynamic programming, linked lists, arrays etc.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Most of the questions were about my projects and technical clubs. There weren't any questions about my non-technical extracurriculars.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Since there was no aptitude round, the shortlisting was done purely based on previous projects mentioned in the resume. So having a few good machine learning or deep learning based projects and being able to answer questions related to those would help.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Start preparing as early as you can, believe in yourself and don't lose hope if things don't go as planned early on
                            Best of luck!
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:nazrera21.comp@coeptech.ac.in ">
                            nazrera21.comp@coeptech.ac.in
                        </a>
                        <br />

                        LinkedIn : <a href="https://www.linkedin.com/in/rukmini-nazre-10832225b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">Rukmini Nazre</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default rukmini_nazre;

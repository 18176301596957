import React from "react";

const dhanashree_mahadeo = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Dhanashree Mahadeo Pawar
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>Hiring process for Citi had three rounds</p>
            <ol>
              <li>Coding and Aptitude Test</li>
              <li>Technical Interview Round</li>
              <li>HR Interview Round</li>
            </ol>
            <b>Structure of Aptitude Round :</b>
            <br />
            <p>Aptitude test had following sections</p>
            <ol>
              <li>
                Quantitative: This section had Basic Mathematics and Reasoning
                MCQs. However time given for this section is less so questions
                need to be solved fast.
              </li>
              <li>
                Technical Interview Round: This section had technical MCQs based
                on DSA,DBMS,OOP and C concepts.
              </li>
              <li>
                Verbal : This section had questions based on vocabulary,grammar
                and comprehension.
              </li>
              <li>
                Coding Questions : Two coding questions were included in this
                section Both questions were easy and solvable 1. GCD Related 2.
                Find number of Permutations
              </li>
            </ol>
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <br />
            <p>
              There were two Interview rounds - Technical Interview, HR
              Interview
            </p>
            <ol>
              <li><b>Technical Interview</b></li>
              <p>
                Technical Interview was 25 - 30 minutes long. My interview was
                based on OOPs concepts and project that I included in my resume.
                Interviewer started off by asking me about the subjects I was
                studying in 5th semester and also ones that were part of the
                curriculum till 4th Semester.When asked which subjects I found
                interesting I mentioned DSA,CN and CO so some related concepts
                were asked .Interviewer asked me some basic concepts related to
                DSA. Even some basic concepts of CN such as the OSI model were
                asked. Then a major part of Interview was focused on OOPs and its
                real life applications. I had implemented a tree command as a DSA
                project, questions were asked based on the project
              </p>
              <li><b>HR Interview</b></li>
              <p>
                HR Interview went for around 20 minutes.To start with she asked me
                “Tell me about yourself” precisely in 1 to 1.5 minutes. So be
                prepared for this question. It is obvious to think that it is an
                easy question but this question sets the pitch for the next 15
                minutes and as they say “First impression is important”. After
                that Interviewer asked me about how I deal with time management
                and how to manage tasks. Then he asked me about my project, “what
                challenges I faced in the whole process of the project” and “How I
                dealt with situations when the things in the project didn’t go as
                planned ? ”. Then he asked me to solve 3 missionaries and 3
                cannibals puzzle. It is important that you keep explaining your
                approach to the interviewer while solving. In the end, he asked me
                “Why do you want to join Citi” so do read about the company's
                values and background.
              </p>
            </ol>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              After the Aptitude test <b>45</b> students were selected for
              Technical Interview. Then <b>22</b> students were selected for the
              HR Interview.
              <br />Final offer was given to <b>8</b> students.
            </p>
          </p>
          <p>
            <b>
              4. How many questions were asked about projects, clubs and
              extracurricular activities in your resume ?
            </b>
            <br />
            <p>
              Many questions were asked related to the project .While discussing
              the project (tree command Implementation) Interviewer even asked
              some questions related to Linux and Windows end users.Make sure
              you know everything about the project. So while including projects
              in your resume include those about which you can confidently
              answer and can explain in detail.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              I practised coding questions from Leetcode,Hackerrank and GFG.
              Even InterviewBit is good for preparation. For topics related to
              CN,DBMS I referred to Javatpoint and GFG.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Practicing coding questions regularly is the only key to crack
              coding questions. Consistency is important in coding. Make sure to
              do at least one question a day.
            </p>
          </p>

          <p>
            <strong>7. Final Tip for Juniors :</strong>
            <p>
              <ol>
                <li>
                  Be careful while adding skills and projects in your resume
                </li>
                <li>
                  Internship season right from the beginning is overwhelming. Be
                  mentally prepared and don’t get disheartened if you aren’t
                  selected in the company you are aiming for. Give your best
                  efforts
                </li>
                <li>
                  Although at some point of time when all around you will get an
                  offer it might lead to self doubt, don’t give up. Believe in
                  yourself and keep going.
                </li>
                <li>All the best and feel free to reach out to us</li>
              </ol>
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID : <a href="mailto:pawardm20.comp@coep.ac.in">
              pawardm20.comp@coep.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default dhanashree_mahadeo;

import React from "react";

const varun_taneja = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Varun Taneja
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />
            There were two interviews, one technical
            interview and one Managerial Interview.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>It was an one hour forty five minute exam, which
              had an English section, one Maths/Finance section and two coding questions.</p>
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            There were three rounds altogether
            <ol>
              <li>
                First round was the written test ie. Online assessment. This one was taken on the
                AMCAT’s SHL platform and was for 1hr 45mins. <br />
                This has 3 sections,
                <ol >
                  <li>
                    English ( this section was pretty easy, basic grammar questions, replace with
                    correct tense, find the mistakes, synonym/antonyms, give meaning for the
                    following word and two comprehension questions)
                  </li>
                  <li>
                    Maths/Finance section ( this had medium difficulty, the questions aren't very
                    difficult but they are lengthy given the time crunch, it had graph based questions
                    related to stocks, interest rates and finance sector)
                  </li>
                  <li>
                    Coding questions ( there were two coding questions, this section falls under
                    the difficult category, the questions will come under hard ones on the leetcode
                    platform questions. The problem here was that we had to make the data
                    structures ourselves and type the code for the input as well unlike some other
                    tests for companies hence this takes more time. I could do one question
                    completely and one partially. One question was an array dynamic programming
                    problem and one graph question. When attempting this section be careful about
                    the time)
                  </li>
                </ol>
              </li>
              <li>
                Second round was the Technical Interview
                <p>This interview was moderate in difficulty, the interviewers are people who work in
                  the company and are very experienced. They are very soft-spoken and make you
                  feel comfortable. The key is being respectful, down to earth and being very
                  genuine. If you don’t know an answer, try to tell them whatever you know closest
                  about the topic. Firstly I was asked about my internship experience and what
                  tech stacks I used, then I was asked about my projects. Be very careful about
                  which projects you put on your resume, you should know them inside out. Don’t
                  put projects for the sake of putting them. They were impressed by my python
                  machine learning and AI projects. After this I was asked which domain would I
                  like to be questioned on, OS or DBMS or OOPs or CN, I kinda made a mistake of
                  saying I wouldn’t mind any. I was asked questions on OS, not a very strong
                  subject of mine, I could answer 3/5 questions and it showed a lack of thorough
                  knowledge on my part. My advice - tell them your strongest subject, in my case
                  my projects and internship made up for my OS shortcomings.
                </p>
              </li>
              <li>
                Last round :
                <p>Managerial round : This was the best round for me personally,
                  the interviewer was the Vice President of technology (cloud) in Wells Fargo. This
                  round is all about your confidence and how convincing you are when you answer
                  questions. Firstly I was asked about my projects, my interests in the technology
                  field, what my inspiration is and what is my end goal in life. He then asked me
                  that if I had to highlight only two things on my resume what would those be. Do
                  not oversell yourself and don’t be too humble too. Its an interview and you have
                  to say good things about yourself. Ask the interviewer questions too when given
                  a chance. Ask about the projects you will get, what technologies you will be
                  working on, the work life balance offered by the company etc. basically any
                  doubts you have. Be confident and respectful and I think you can ace this
                  interview for sure.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            There were 190+
            students who gave the aptitude test, around 27 students got selected for the
            interviews and from those 8-9 were there for the final round and 7 students got
            the offer.
          </p>

          <p>
            <b>
              5. Some good resources you used in your Placement prep
            </b>{" "}
            <br />
            If your best programming language is C++, try out the placement course provided
            by ApniKaksha on Youtube. They also have concise notes for all placement
            related subjects which are helpful for last minute revision.
            Use leetcode for solving problems.
            Use geeksforgeeks for concepts.
            Refer your DSA, CN, DBMS and OS college notes, they are very helpful too.
            Be aware of stocks a little, watch youtube videos for the same (for banks/finance
            based companies)
          </p>
          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Choose one programming language, my personal favorite is C++. Once chosen,
            be thorough with it and practice a lot of questions of varying difficulty. Array
            based and bfs, dfs based questions are very common in exams.
            Have a backup language ready as well, preferably python and it’s fairly easy to
            code in python and some tests they specifically ask us to code in it too.

            <br />
          </p>

          <p>
            <b>
              7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume?
            </b>
            <br />
            <p>In both interviews I was asked a lot of questions about my projects because they
              were on interesting topics and not many students would have had these topics
              on their resume. Sometimes it is good to stand out and have 1 or 2 projects
              which no-one would have in their resumes.
            </p>
            <p>In both interviews I was asked a lot of questions about my projects because they
              were on interesting topics and not many students would have had these topics
              on their resume. Sometimes it is good to stand out and have 1 or 2 projects
              which no-one would have in their resumes.
            </p>
          </p>
          <p>
            <b>
              8. Is there something specific that you would want to tell people who try for the
              same company?
            </b>
            <br />
            <p>Wells Fargo doesn’t always look at your coding skills only, they like people who
              are willing to learn and who are into things other than studies as well. Do not
              portray yourself as someone who studies ONLY. As it is a bank, one should know
              about basics of finance, your interest in stocks etc will definitely give you brownie
              points.</p>
          </p>
          <p>
            <b>
              9.Final Tip for Juniors
            </b>
            <p>
              Be confident first and foremost, the placements are grueling, they mount on a lot
              of stress. It's normal to be anxious, back your abilities and never give up till the
              end. There are hundreds of companies coming to campus and I’m each one of
              you will get a job. Start preparing from April or atleast latest by June. Have a
              target in mind and ehn you get the schedule of companies, look at what salaries
              they are offering before applying as there are many rules from the tnp office
              regarding salary brackets. Look at the location, job description and company’s
              reviews before applying. This is a very crucial time of your life, don’t take it lightly.
              There will be times when your friends get a better package or a bigger company,
              don’t be disheartened, if you’re capable enough you’ll be on a level field or better
              in a few years.
            </p>
            <p>
              All the very best. Keep putting in the hard yards and I'm sure you’ll definitely
              achieve your goals.
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email ID : <a href="mailto:tanejavp19.comp@coep.ac.in">
            tanejavp19.comp@coep.ac.in
            </a>            
          </div>
        </div>
      </section>
    </div>
  );
};
export default varun_taneja;

import React from "react";

const rohan_advani = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Rohaan Advani
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>NCS-Singapore </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            {'Resume Shortlist -> Aptitude Round -> Technical Interview -> Managerial Interview -> HR Interview '}
          </p>
          <p>
            <b>2.What was the structure of Aptitude Round?</b>
            <br />
            Mcqs + Coding Questions
          </p>

          <p>
            <b>
              3. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b> <br />
              There were 4 rounds
            <ol>
              <li>
                Aptitude test - Online test which mcqs and coding ques.
              </li>
              <li>
                Technical Interviews - Coding Questions (String Algorithms), Theory questions in Oop, OS, CN, DSA, Python Programming, Questions on Projects in Resume

              </li>
              <li>
                Managerial Interview - Questions or Projects / Internships/ Research Experience mentioned in the resume, managerial situation-based-questions teamwork/leadership, Questions on project management
              </li>
              <li>
                4.	HR interview - Why NCS, My strengths and weaknesses, and How can I contribute?
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            <p >
              Aptitude round - 2 <br />
              Technical rounds - 2 <br />
              Managerial and HR - 1 <br />
            </p>
          </p>

          <p>
            <b>
              5. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <p>
              They asked about projects in the resume in detail and checked the code on Git and asked me to explain, they asked about my involvement and role in Impressions - marketing and finance head core team 2021. Also in-depth discussion about my research activities.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Stick to one programming language and master its algorithms, I used Python and list data structure to solve most coding problems for most companies' aptitude tests.
            </p>
          </p>
          <p>
            <strong>
              6. Is there something specific that you would want to tell people
              who try for the same company :
            </strong>
            <p>
              The job offer is in Singapore City a great opportunity will be hired along with graduates from NUS and NTU in Singapore.
            </p>
          </p>
          <p>
            <strong>7. Final Tip for Juniors :</strong>
            
            &nbsp;  ALL THE VERY BEST!
            
          </p>
          <br />
        </div>
      </section>
    </div>
  );
};

export default rohan_advani;

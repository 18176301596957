import React from "react";

const archisha_shukla = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Archisha Shukla
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              The internship hiring process consisted of two parts. First, an
              aptitude test was conducted then the shortlisted students had to
              sit for interviews, which consisted of 2-3 rounds.
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              The aptitude test was conducted on HackerRank and was 2 hours 15
              minutes long. The test was divided into five sections as follows:
            </p>
            <ol>
              <li>
                <p>
                  Coding Section - This round was 30 mins long and consisted of
                  two coding questions of medium-level difficulty.
                </p>
              </li>
              <li>
                <p>
                  Quantitative Section - This round was around 25 minutes long
                  and had questions on coordinate geometry, probability,
                  integration, etc. It had negative marking.
                </p>
              </li>
              <li>
                <p>
                  HR section - This round was 15 minutes long and had 2
                  questions to which we had to write descriptive answers.
                </p>
              </li>
              <li>
                <p>
                  Coding Section - This round was 45 minutes long and consisted
                  of a single coding question of a higher difficulty level than
                  the first section.
                </p>
              </li>
              <li>
                <p>
                  Technical Section - This round was 20 minutes long and had
                  questions on data structures, computer networks, computer
                  organization. It had negative marking.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              The interview was divided into three rounds - 2 technical and 1
              HR.
            </p>
            <ol>
              <li>
                <p>
                  <strong>Technical Interview 1:</strong>
                </p>
                <p>
                  This round started off with my interviewer asking me to
                  introduce myself and told me to give a summary of my resume.
                  When I mentioned my projects, he asked me to explain each of
                  them in detail and asked some questions on each of them. I had
                  done a project on the tree command so I was asked to explain
                  all the flags I had included and was asked about the various
                  standard libraries I used like dirent.h, sys/stat.h, etc. I
                  was asked about my contribution to the android development
                  group project I mentioned on my resume and he asked a few
                  questions on this project as well. Lastly, he asked about the
                  dataset I used and the number of epochs I ran on the machine
                  learning project I mentioned. After a long discussion on my
                  projects, he asked me about the data structures I’ve learned
                  and asked a few questions on arrays and linked lists. Then I
                  was given a question on linked lists to live code on Code
                  Pair, I explained my approach and coded it, I was asked to run
                  3-4 dry runs on this code. After this, I was given a puzzle.
                  This was followed by questions on object-oriented programming.
                  I was given one more coding question after this, which I
                  managed to code but it was not the optimal solution so I was
                  asked to optimize the code. It took some time but I managed to
                  write the optimized code. After this, I was asked if I had any
                  questions for him. This round was around 75 minutes long.
                </p>
              </li>
              <br />
              <li>
                <p>
                  <strong>Technical Interview 2:</strong>
                </p>
                <p>
                  In this round I was asked to introduce myself first then I was
                  given a coding question to live code on code pair. The first
                  question was a bit confusing at first but I kept sharing my
                  ideas and he gave small hints, make sure you listen to your
                  interviewer carefully. I managed to come up with a solution
                  but it was not optimal so I was asked to optimize my code,
                  after discussing my ideas with him I managed to reach the
                  optimal solution. He asked me to calculate the time complexity
                  of both approaches. After this, I was given another coding
                  question on hashing.
                </p>
                <p>This round was 40-45 minutes long.</p>
              </li>
              <br />
              <li>
                <p>
                  <strong>HR round:</strong>
                </p>
                <p>
                  I was not asked to give HR round. I think around 3-4 people
                  had to give this round.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <p>
              In the first technical interview, I was asked lots of questions
              about the projects I mentioned on my resume. 15-20 minutes of my
              first technical interview were dedicated to the projects, so make
              sure you add projects you are confident with.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              For quantitative section I practiced questions from indiabix. I
              revised data structures and object-oriented programming from
              geeksforgeeks and practiced coding questions on HackerRank. For
              the puzzles you can go through the puzzles available on gfg.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Make sure you revise your basic concepts. Try familiarizing
              yourself with dynamic programming and practice questions on this
              on platforms like HackerRank/LeetCode. I used gfg and HackerRank
              to prepare for the coding section. If you aren’t sure how to solve
              a question try using brute force and passing some corner cases.
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            Make sure you are calm and confident during your interviews, if you
            don’t understand a question get your doubts cleared before you
            proceed. For the live coding questions don’t start coding
            immediately, explain your approach and tell them what you are
            thinking, this helps if you are stuck, they give you small hints
            that help you reach the solution. Also if they ask you whether you
            have any questions for them make sure you ask questions (pay
            attention in the pre-placement talk)!!
          </p>
          <br />
          <p>
            <i>
              It’s a good idea to talk to your seniors who have given interviews
              for the same company as they know the flow and the type of
              questions the interviewers ask, this really helped me. All the
              best guys!!
            </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:shuklaa19.comp@coep.ac.in">
              shuklaa19.comp@coep.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default archisha_shukla;

import React from "react";

const savan_nahar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{ fontSize: "2em", position: "absolute", marginTop: "2rem" }}
          />
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Savan Nahar</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Arcesium</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '20
            </p>
          </div>
          <br />
          <p>
            <strong>
              Q. What was the structure of the entire internship hiring process?{" "}
            </strong>
            <br />
            A. Arcesium came to COEP for Internship Interviews on 27th July 2018{" "}
            <br />
            Total: An Online Test + 5 Subsequent Rounds.
          </p>
          <p>
            <strong>
              Q. What was the structure of Online test and how it was conducted?
            </strong>
            <br />
            A. Online test – Had a total of 80 minutes and was conducted on
            hacker rank’s code pair platform. The section was divided into 3
            parts: <br /> <i>1. Quantitative Aptitude:</i> 15Q - 20 minutes{" "}
            <br />
            <i>2. Technical Aptitude:</i> 15Q - 15 minutes <br />
            <i>3. Programming test 1:</i> 1Q - 15 minutes <br />
            <i>4. Programming test 2:</i> 1Q - 30 minutes.
            <br />
            <br />
            Each question had equal weightage.There was no barrier of time in
            any section and you had the freedom to jump back and forth between
            the sections.Each wrong answer in Sections 1 and 2 carried a
            negative marking of 25% of the marks for that question and every
            question has four choices with only one correct answer.The
            Quantitative had really good Questions and was tough for most for
            us, the technical was more about object-oriented.Questions in Java
            and C++ with few questions related to basic of C, stack, Binary
            Search Tree.The technical was decently easy if you are good at
            OOP(5-6 Code Snippets were given and we had to mark the appropriate
            answer).
            <br />
            <br />
            <b>Coming to Code section.</b>
            <br />
            These are some questions which were asked: <br />
            1.There is a river of N unit and there are K stones across the
            river, each stone will be in one of the N units.Starting from 0th
            location your first jump will always be 1 unit (consider previous
            jump as L units), now your next subsequent jump can be either L + 1
            or L – 1.Note few of the stoes can be missing.
            <br />
            You have to output the sum of square of Jump which you’ll take from
            each stone. Ex: let the river be of N = 5 units and there are 4
            stones placed shown in array[0, 1, 3, 4].
            <br />
            So if it is possible to reach the last stone output 6 else output
            -1. <br />
            <br />
            2.You have been given a m*n chess board and there were black pawns
            on the board(represented by 1).You have to put your white pawn on
            all possible position’s where it can be and the calculate how many
            kills are possible from each location and sum it up.You can only
            move straight (x+1, y) and for a kill diagonal move (x+1, y+1) &amp;
            (x+1, y-1). Ex: 3*3 matrix is given. <br />
            0 0 0<br />
            0 1 0<br />
            1 1 1 <br />
            Output: 6
          </p>
          <p>
            <strong>
              Q. How many interview rounds were there for the company, and in
              brief could you elaborate each round?{" "}
            </strong>
            <br />
            A. There were 5 rounds of interviews: <br />
            <b>Round 1:</b> This was a technical round, there were 2 different
            interviewers for each technical round. They asked me about myself,
            then looked at my resume and asked about PHP, then they asked few
            questions on Java, PHP and C++. <br />
            <b>Round 2:</b> More kind of general round for everyone, asked 2
            puzzles to everyone. <br />
            <b>Round 3:</b> Technical round again, I was welcomed and made
            comfortable, he asked me nearly 4-5 complex code questions on
            concepts like hashing. Also asked me to explain the programs that I
            had in Programming Test 1 &amp; 2 from the online test. <br />
            <b>Round 4:</b> It was HR round, basic HR questions, like: Introduce
            yourself, place where you stay, family, how were all the interviews,
            how was my day and many more. Also asked one puzzle.
            <br />
            <b>Round 5:</b> This round was most challenging one. You are given
            COEP network, you have 25 telephones and each telephone have to
            strictly link to other 7 telephones(applied to all) You have to find
            if given the telephones number N and telephone’s exact link limit,
            just return if network can be made(true) or not(Not).He asked some
            Design questions like, Design excel spreadsheet from scratch. write
            a code for malloc and recursive code for permutation. The round
            lasted nearly 1.30 hrs.
          </p>
          <p>
            <strong>
              Q. What kind of questions were asked in technical round?{" "}
            </strong>
            <br />
            A. They asked if I know Java(I told it’s been 2 years since I
            haven’t coded).So the cool guy was like let’s see how much have you
            forgot. So he asked questions based on java and other concepts like:
          </p>
          <ul>
            <li>What are interfaces?</li>
            <li>
              Why to use interfaces when you can do the declaration inside class
              itself where you have to use it?
            </li>
            <li>What is virtual memory?</li>
            <li>What is polymorphism?</li>
            <li>How does C++/C is executed?</li>
            <li>How does the compiler know where your int xyz is stored?</li>
            <li>What actually happens in linking and loading?</li>
            <li>Asked few questions on tree data structure.</li>
            <li>How can you restrict the class to make just one object?</li>
            <li>What is deadlock?</li>
            <li>Few questions were based on DBMS</li>
            <li>What is hashing?</li>
          </ul>
          <p />
          <p>Cheers and Happy Coding!</p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15em" }}>
            <a href="mailto:naharsn17.comp@coep.ac.in">
              naharsn17.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default savan_nahar;

import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import SectionHeading from "../components/SectionHeading";
import { backGround } from "../layout/customStyle";
import { ArrowBack } from "@material-ui/icons";
import { TiWarningOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import { data as TyData } from "../../src/assets/diary-data/TY_diaryData";
import { data as BtechData } from "../../src/assets/diary-data/BTech_diaryData";
import { data as PlacementData } from "../../src/assets/placements-data/PlacementsData";
import { Redirect } from "react-router-dom";

const InternshipCardPage = (props) => {
 
  let { date, title } = props;
  let currentCompany = {};
  
 
  // if (!html) {

  if (title === "BTech Internship Diaries") {
    
    [currentCompany] = BtechData.filter(
      (comp) =>  comp.company.toLocaleLowerCase().replace(/\s/g,`${'_'}`) === props.match.params.company||
      comp.company.toLocaleLowerCase().replace(/\s/g,`${'-'}`) === props.match.params.company||
      comp.company.toLocaleLowerCase().replace(/\s/g,`${' '}`) === props.match.params.company||
      comp.company.toLocaleLowerCase().replace(/\s/g,`${''}`)=== props.match.params.company
    );
    console.log(currentCompany);
  } else if (title === "TY Internship Diaries") {

   
    [currentCompany] = TyData.filter(
      (comp) =>  comp.company.toLocaleLowerCase().replace(/\s/g,`${'_'}`) === props.match.params.company||
      comp.company.toLocaleLowerCase().replace(/\s/g,`${'-'}`) === props.match.params.company||
      comp.company.toLocaleLowerCase().replace(/\s/g,`${' '}`) === props.match.params.company||
      comp.company.toLocaleLowerCase().replace(/\s/g,`${''}`)=== props.match.params.company
    );
  }
  else if (title === "Placement Diaries") {

   
    [currentCompany] = PlacementData.filter(
      (comp) =>  comp.company.toLocaleLowerCase().replace(/\s/g,`${'_'}`) === props.match.params.company||
      comp.company.toLocaleLowerCase().replace(/\s/g,`${'-'}`) === props.match.params.company||
      comp.company.toLocaleLowerCase().replace(/\s/g,`${' '}`) === props.match.params.company||
      comp.company.toLocaleLowerCase().replace(/\s/g,`${''}`)=== props.match.params.company
    );
  }


  const { interns } = currentCompany;
    const [currentIntern] = interns.filter(
    (intrn) =>
      ((intrn.firstname.toLocaleLowerCase() + "-" + intrn.lastname.toLocaleLowerCase() === props.match.params.intern.replaceAll("_","-"))||
      (intrn.firstname.toLocaleLowerCase() + "-" + intrn.lastname.toLocaleLowerCase() === props.match.params.intern.replaceAll(" ","-")) )
  );
  if(!currentIntern){
    return(<Redirect to="/"/>)
  }
  date = currentIntern.date;
  let html = currentIntern.html;
  // }
  return (
    <>
      <main style={backGround}>
        {/* <Paper style={{ background: "rgba(135, 135, 135,0.5)" }}> */}
        <Navbar diary={title === "TY Internship Diaries"} placements={title === "Placement Diaries"} />
        <div className="mb-5">
          <SectionHeading title={title} />
          
          <div className="container-fluid">
            <Grid container justify="center" alignItems="center">
              <h4
                style={{
                  textAlign: "center",
                  color: "#4dc7f7",
                  // fontFamily: "Varela Round",
                  fontSize: "1.75rem",
                  marginBottom: "0.75rem",
                }}
              >
                 {props.match.params.company.toUpperCase().replaceAll('-',' ').replaceAll('_',' ').replace('',' ')}
              </h4>
            </Grid>
            <div className="container mb-5">
              <h6
                style={{
                  textAlign: "center",
                  color: "white",
                  // fontFamily: "Varela Round",
                  fontSize: "1.3rem",
                }}
              >
                <i>
                  In case of further queries, visit our{" "}
                  <Link to="/faqPage"> FAQ Page</Link> to find out the answers
                  to commonly asked questions.
                </i>
              </h6>
            </div>
            <Grid container justify="center">
              <Button
                color="primary"
                onClick={() => {
                  if(title==="TY Internship Diaries"){

                    props.history.push(
                      `/ty-diaries/${props.match.params.company}/${props.match.params.batch}`
                  );


                  }
                  else if(title==="BTech Internship Diaries"){
                   
                    props.history.push(
                    `/btech-diaries/${props.match.params.company}/${props.match.params.batch}`);

                  }
                   else if(title==="Placement Diaries"){
                
                    props.history.push(
                    `/placement-diaries/${props.match.params.company}/${props.match.params.batch}`);

                  }
                 

                  
                }}
                startIcon={<ArrowBack />}
                style={{
                  color: "white",
                  fontSize: "25px",
                }}
              >
                Go back
              </Button>
            </Grid>
            <br />
            <Grid container justify="center" alignItems="center">
            <Paper
                variant="outlined"
                elevation={3}
                style={{
                  padding: "1rem",
                  
                  width: "52rem",
                  boxShadow: "1em 1em 1em #040404b8",
                }}
              >

                {html() || props.html}
                {props.date !== undefined ? (
                  <div
                    style={{
                      // display: "flex",
                      // justifyContent: "center",
                      textAlign: "center",
                      marginTop: "1.5rem",
                    }}
                  >
                    <Typography variant="h6" color="textPrimary">
                      &copy; CSI COEP
                      <br />
                      Date Published: {date.day}
                      <sup>{date.super}</sup> {date.rest}
                    </Typography>
                  </div>
                ) : null}
              </Paper>
            </Grid>
            <div className="container mb-5 mt-5">
              <h2
                style={{
                  textAlign: "center",
                  color: "#FEE715FF",
                  fontSize: "2.4rem",
                  marginBottom: "0.75rem",
                  marginRight: "1rem",
                }}
              >
                <TiWarningOutline
                  style={{
                    fontSize: "3.5rem",
                    margin: "1rem",
                  }}
                />
                Disclaimer
              </h2>
              <h6
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "1.3rem",
                }}
              >
                <i>
                  This is to inform readers that the views, thoughts, and
                  opinions expressed here belong solely to the author, and not
                  necessarily to the author's employer, organization, committee
                  or another group or individual.
                </i>
              </h6>
            </div>
          </div>
        </div>
        <Footer />
        {/* </Paper> */}
      </main>
    </>
  );
};

export default InternshipCardPage;

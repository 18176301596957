import React from "react";

const anvay_joshi = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Anvay Joshi
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Hilti Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            Technical round followed by HR round
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Questions on DBMS, CN, OS, OOP and one easy coding questions
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        3 rounds. First was coding/aptitude. Second was technical interview and third was HR interview.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Can't list exact resources. But do prepare core concepts like OS, CN and DBMS for online test
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            coding questions were easy. Nothing to worry about it much
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            most questions from technical interview were related to project only. And most questions in HR round were related to clubs and extracurricular. Try not to bluff in resume for the same reason.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Most people from my batch didn't apply for Hilti thinking it is a construction company so they will be assigned construction related work (which is obviously not true). It's a great company if you want exposure to niche web technologies. Don't miss the opportunity to work here. This is not a paid promotion.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Covered everything above I guess

                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:joshiad21.comp@coeptech.ac.in">
                            joshiad21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default anvay_joshi;

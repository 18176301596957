import React from "react";

const anvita_mahajan = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Anvita Mahajan
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Hilti Technology Solutions
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>The entire process was divided into 3 rounds:</p>
            <ol>
              <li>Aptitude test</li>
              <li>Technical Interview Round</li>
              <li>HR Round</li>
            </ol>
            <b>Structure of Aptitude Round :</b>
            <br />
            <p>Aptitude round was divided in 7 rounds:</p>
            <ol>
              <li>Coding question (1 question)</li>
              <li>OOPs (6-7 questions)</li>
              <li>CN (6-7 questions)</li>
              <li>DBMS (6-7 questions)</li>
              <li>DBMS (6-7 questions)</li>
              <li>OS (6-7 questions)</li>
              <li>Mathematical reasoning (25 questions, 30 minutes)</li>
              <li>Verbal Reasoning</li>
            </ol>
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <br />
            <p>There were 3 rounds:</p>
            <ol>
            <p>
              <li><b>Online Aptitude Test: </b></li>
            <p>
              As mentioned, the aptitude test was divided in 7 sections. The
              coding question was based on array manipulation and was
              comparatively easy. Section 2 to 5 mainly consisted of computer
              fundamentals and was theory based. Section 6 i.e Mathematical
              reasoning was time bounded and back navigation was not allowed. It
              mainly included questions from topics like profit and loss, find
              pattern, sequences, etc.
            </p>
            <li><b>Technical Interview </b></li>
            <p>
              Technical interview went for around 40-45 minutes. The interview
              started with a brief introduction and then he asked me about the
              projects and their techstack. After that he asked some questions
              related to oops and some basic sql queries. At the end the
              interviewer asked me if I had any questions for them.
            </p>
            <li><b>HR Round</b></li>
            <p>
              This round lasted for 10-15 minutes. It started with my
              introduction, hobbies and extracurricular activities mentioned in
              the resume. And then questions like why do you want to join our
              company, what are your future plans etc
            </p>
            </p>
            </ol>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              Around <b>250</b> students appeared for the online aptitiude test,
              out of which <b>26</b> were selected for Technical Interview. From
              26 around <b>18</b> students were selected for the HR round, and
              <b> 5 students got the final offer</b>
            </p>
          </p>
          <p>
            <b>
              4. How many questions were asked about projects, clubs and
              extracurricular activities in your resume ?
            </b>
            <br />
            <p>
              Many questions were asked about the projects so make sure you know
              the projects inside-out. Also try to find alternative
              technologies/data structures that may improve the performance of
              the projects.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              <ol>
                <li>
                  <b>Coding</b> - GFG{" "}
                </li>
                <li>
                  <b>Aptitude</b>- Indiabix and GFG
                </li>
                <li>
                  {" "}
                  <b>CS Fundamentals</b> - Last-Minute Notes available on GFG
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Consistent practice is the key for cracking the coding questions.
              Stick to 1-2 platforms and try solving questions in a timed
              manner.
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              Be thorough with basics and prepare for your projects well. Also
              be thorough with your resume. Be confident throughout the process.
            </p>
          </p>
          <p>
            <strong>8. Final Tip for Juniors :</strong>
            <p>
              Be calm throughout the process and don't get bogged down by the
              failures. Always remember the best is yet to come!
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID : <a href="mailto: mahajanaa20.comp@coep.ac.in">
              mahajanaa20.comp@coep.ac.in
            </a>
            <br />
            LinkedIn Handle : <a href="https://www.linkedin.com/in/anvita-mahajan-232814200">Anvita Mahajan</a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default anvita_mahajan;

import React from "react";

const parth_mane = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Parth Mane
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>mastercard</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There were four rounds that were conducted.
                            <br />
                        </p>
                        <p>
                            <ol>
                                <li>The first round was the Aptitude round</li>
                                <li>The second and the third rounds were the Technical interview rounds</li>
                                <li>The last round was HR interview</li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude round consisted of two DSA coding questions. The first question was of easy to medium difficulty level. The second question was ranging in medium to hard level. As much as I remember the first question was of binary search which was very similar to the standard questions. The second question was quite challenging though I am not able to recall the exact question.
                            <br />
                        </p>


                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        A total of four rounds were there for the recruitment process .
                        <br />The first round was the aptitude round which consisted of the two DSA questions with the difficulty level as mentioned above.
                        <br />The second round was the technical interview round . I was asked a lot of questions about Java and DSA as it was mentioned on my resume. The questions included topics such as microservices, stream API , string buffers, string builder , etc. Also some general questions were asked, for example
                        What is SAAS ? . Questions related to CN, DBMS were also asked.
                        <br />The third round was the second technical round . I was asked questions regarding the projects mentioned in my resume. The questions regarding web development were also asked as I had mentioned MERN in my resume.

                        <br />The fourth round was the HR interview round.

                    </p>

                    <br />
                    <p>
                        <b>4. Some good resources you used in your internship prep? </b>
                        <br />
                        Striver’s Youtube playlists, GFG, Leetcode
                    </p>
                    <br />
                    <p>
                        <b> 5. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Keep practising variety of questions so that you are able to apply concepts learnt previously to the questions asked in interviews and aptitude rounds.                         </p>
                    </p>

                </div>
            </section >
        </div >
    );
};

export default parth_mane;

import React, {useEffect} from "react";
import { HashRouter as Router, Switch, Route, Redirect, Link } from "react-router-dom";
import HomePage from "./pages/HomePage";
import EventPage from "./pages/EventPage";
import RecentsPage from "./pages/RecentsPage";
import TeamPage from "./pages/TeamPage";
import FAQPage from "./pages/FAQPage";
// import DiaryPage from "./pages/TY_DiaryPage";
import Diaries from "./pages/Diaries";
// import Placements from "./pages/Placements";
import TestimonialPageEvent from "./pages/TestimonialPageEvent";
// import TestimonialPageInformal from "./pages/TestimonialPageInformal";
import { data as TYData } from "./assets/diary-data/TY_diaryData";
import { data as BtechData } from "./assets/diary-data/BTech_diaryData.js";
import { data as PlacementData } from "./assets/placements-data/PlacementsData.js";
import CompanyWiseBatchless from "./pages/CompanyWiseBatchless";
// import Testimonial from "./pages/Testimonial";

import CompanyWise from "./pages/CompanyWise";
import CompanyWise_Placement from "./pages/CompanyWise_Placement";
import CompanyWise_Btech from "./pages/CompanyWise_Btech";
import InternshipCardPage from "./pages/InternshipCardPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Codequestviewmore from "./components/Codequestviewmore";
import InspironCertificates from "./components/InspironCertificates";
import trophy from './assets/img/toast/trophy.png'

//import InternshipCardPageBtech from "./pages/InternshipCardPage_Btech";
//import PlacementCard from "./pages/PlacementCard";

// import { CssBaseline } from "@material-ui/core"

function App() {

//   const showToast = () => {
//     toast.success('Do check out our Hackathon Website!', {
//         position: toast.POSITION.TOP_CENTER,
//         icon: ({theme, type}) => <img alt='' src={trophy}/>
//     });
// };
// useEffect(() => {
//   showToast();  
//   }
// );

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noreferrer');
};
  
  return (
    <Router basename={process.env.PUBLIC_URL}>
      {/* <CssBaseline /> */}
      <Link onClick={() => openInNewTab('https://csi.coep.org.in/hackathon-23/#/')}>
        <ToastContainer theme="dark"/>
      </Link>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route
          exact
          path="/ty-diaries"
          render={(props) => (
            <Diaries
              data={TYData}
              title="TY Internship Diaries"
              TY={true}
              diary={true}
              {...props}
            />
          )}
        />
        <Route exact path="/ty-diaries/:company" component={CompanyWiseBatchless} />
        <Route exact path="/ty-diaries/:company/:batch" component={CompanyWise}/>
        <Route exact path="/ty-diaries/:company/:batch/:intern" render={(props) => (
          <InternshipCardPage {...props} title={"TY Internship Diaries"}/>
        )}/>
         <Route exact path="/btech-diaries/:company" component={CompanyWiseBatchless} />
        <Route exact path="/btech-diaries/:company/:batch" component={CompanyWise_Btech}/>
        <Route exact path="/btech-diaries/:company/:batch/:intern" render={(props) => (
          <InternshipCardPage {...props} title={"BTech Internship Diaries"}/>
        )}/>
         <Route exact path="/placement-diaries/:company" component={CompanyWiseBatchless} />
        <Route exact path="/placement-diaries/:company/:batch" component={CompanyWise_Placement}/>
        <Route exact path="/placement-diaries/:company/:batch/:intern" render={(props) => (
          <InternshipCardPage {...props} title={"Placement Diaries"}/>
        )}/>
        <Route exact path="/eventPage/:year" component={EventPage} />
        <Route path="/hackathon" component={() => { 
                    window.open('https://csi.coep.org.in/hackathon/', '_blank');
                    return null;
          }}/>
        <Route exact path="/recentsPage" component={RecentsPage} />
        <Route exact path="/teamPage" component={TeamPage} />
       <Route exact path="/faqPage" component={FAQPage} /> 
       <Route exact path="/testimonials" component={TestimonialPageEvent} />
       {/* <Route exact path="/testimonials/informal-events" component={TestimonialPageInformal} /> */}
       <Route exact path="/faqPage" component={FAQPage} />
       {/* CODE QUEST VIEW MORE */}
       <Route exact path="/Codequestviewmore" component={Codequestviewmore} />
       <Route exact path="/Inspiron2.0viewmore" component={InspironCertificates} />
        
        <Route
          exact
          path="/btech-diaries"
          render={(props) => (
            <Diaries
              data={BtechData}
              title="BTech Internship Diaries"
              BTech={true}
              diary={true}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/placement-diaries"
          render={(props) => (
            <Diaries
              data={PlacementData}
              title="Placement Diaries"
              diary={false}
              Placement={true}
              {...props}
            />
          )}
        />
        <Redirect to="/" />
      
      </Switch>
    </Router>
  );
}

export default App;

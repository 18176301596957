import React from "react";

const anushka_more = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Anushka More                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>CITI</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There was one aptitude round, one technical round and 2 HR rounds.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            For PBWMT domain, the Aptitude test had a difficulty level of easy/moderate. It consisted of sections like Quantitative ability, Logical Reasoning, Technical MCQs and 2 coding questions. The coding questions had easy/moderate difficulty and was based on basic concepts like arrays and linked lists. All sections were individually timed.

                            <br />
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 3 rounds, one technical and 2 HR rounds<br />
                        In the technical round I was asked a lot of questions on my projects and skills that I had mentioned in my resume. I had used Trie data structure in one of my projects, so the interviewer asked me about its working and some conditional questions related to it.<br />
                        The first HR round lasted about 15-20 minutes.The interviewer asked me about my work in various college clubs and fests then I was asked some generic questions about my skills, interests, subjects etc . <br />
                        The last round was about 10-15 minutes. Some basic HR questions were asked in this round like where do you see yourself in 5 years and what is your biggest achievement till now and then he asked me about my strengths and weaknesses.<br />
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        For the PBWMT domain, about 15 students were shortlisted for the technical interview. 7 students were shortlisted for the second round and 4 students were selected for the third round.
                        <br />Final offer was given to 3 students for the PBWMT domain.
                        <br />For the ICG domain 10 students were given the offer.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        GeeksForGeeks for understanding concepts, GeeksForGeeks and LeetCode for coding practice, IndiaBix and InterviewBit for aptitude.

                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            It is not necessary to solve all the questions but make sure you solve all types of questions. And analyse the question once you solve it.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            A lot of questions were asked about my projects, so make sure you have a deep understanding of your projects and the tech stack used in them.
                            In the HR rounds, a few questions were asked about clubs and extracurricular activities.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Make sure you are thorough with whatever you have mentioned in your resume be it your projects skills or even hobbies. And have a basic knowledge about the company you are applying for.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Stay confident and be yourself. Focus on understanding concepts rather than just memorizing them. Believe in your abilities and stay positive.
                            <br />All the best!
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:anushkavm21.comp@coeptech.ac.in">
                            anushkavm21.comp@coeptech.ac.in                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default anushka_more;

import React from "react";

const akhil_harikumar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Akhil Harikumar
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>CrowdStrike</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            CrowdStrike had a coding round with 3 coding questions. Selected
            students had 2 rounds of interviews after which they declared the
            results.
            <br />
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The online test had just a coding round and not any aptitude-based
            questions. The coding test, conducted on hackerrank, had 3 questions
            for 1 hour 40 mins. First one was fairly simple as the question was
            to find the Longest increasing subsequence (LIS). The next two
            questions were based on string manipulation and were a bit tricky.
            One was to count the number of substrings with exactly k distinct
            characters, and the other was to split a given string into 3
            different palindromes with the earliest possible cuts.
            <br />
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            We had 2 rounds of interviews. Everyone had different interviews and
            interviewers. Both my rounds were technical with the second round
            having a 5 min HR part to it.
            <br />
            <i>Round One</i> <br />
            Stared off by getting to know each other. The Interviewer then asked
            me if I remembered my coding questions that I attempted and how many
            test cases I had cleared. He then asked me to explain the logic
            behind the three codes I wrote for the test. After some cross
            questioning, he then moved on to my resume. I was asked to pick a
            favourite project out of the ones that I had mentioned and explain
            it in detail. Like always, a few questions he then asked were on
            data structures, out of which one was to explain the logic of
            building a stack out of linked list and doubly linked list. He then
            moved on to basic OS questions mostly related to synchronisation
            primitives (like difference between condition variables and
            semaphores. etc.). The last question he asked me was to explain how
            I would create a Heap or a dictionary with the traditional data
            structures. The round lasted almost 40-45 mins and later I got the
            link for Round 2.
            <br />
            <br />
            <i>Round Two</i>
            <br /> For this my interviewer was the Head of technology of Pune
            branch. He was quite friendly and we started off by talking about
            the subjects I had learned over the course of my engineering. The
            first few mins, he quizzed me on DS and time complexity. He then
            proceeded to quiz me on my knowledge of both python and C, to see
            which would suit me better. I was then again asked to explain all my
            projects. The next 10 mins I was asked questions from DBMS (Inner
            Join, outer join, normal forms), CN (DNS, OSI) and Cryptography
            (RSA, Firewalls, Intrusion detection). After a few Puzzles, he then
            started to ask me HR questions. He explained the different
            departments which I could work under, if I get in. I was then asked
            for my preferences and my strengths and weaknesses. The round lasted
            around 50 mins and we received the results the next day.
            <br />
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on the
              resume?
            </b>
            <br />
            A LOT. Majority of my interviews were based on my project and
            subjects written on my resume. It’s really important to be thorough
            about everything that you mention on your resume.
            <br />
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            I mostly relied on GFG for my preparation because I couldn’t find
            any archives for CrowdStrike. I also referred to my notes from 3rd
            year for OS, DBMS and CN. That would pretty much give you a standing
            ground to face the interviewer’s questions.
            <br />
          </p>

          <p>
            <b>6. Some tips to crack the coding questions. </b>
            <br />
            Try to make use of Python to code as it has a lot of collections and
            libraries to help you reduce your code and manage time. Basic
            Dynamic Programing questions (LIS, LCS etc.) should be at your
            fingertips as these highly occur in coding tests. Also try to think
            and tackle all boundary cases for the given problem as a lot of the
            test cases are made specifically to fail for these conditions.
            <br />
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            Have a good knowledge about network security and coding in Python.
            Both my interviewers preferred Python over C.
            <br />
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:harikumara17.comp@coep.ac.in">
              harikumara17.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default akhil_harikumar;

import React from "react";

const tarun_tapadiya = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Tarun Radheshyam Tapadiya</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>BNY Mellon</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />
          <p>
            <b>1. What questions were asked in the aptitude test?</b>
            <br />
            <p>
            There was no aptitude test. We had a coding round of 4 questions. There were 1 easy question, 2 medium questions and 1 tricky coding question. 1 easy, 1 medium and partial case of the other two questions is necessary to shortlist for the interview process.
            </p>
          </p>
          <p>
            <b>
              2. How many interview rounds were there for the company, can you
              elaborate about each round in brief ?
            </b>
            <br />
            <p>
            Interview procedure consisted of 3 rounds (2 technical and 1 hr + technical).
              <br />
              <ul style={{paddingLeft:"40px",listStyle:"disc"}}>
                <li>During the first technical round, the interviewer introduced himself and then asked me to introduce myself. He then started with DSA concepts. He asked to differentiate between DFS and BFS. He also asked me about stack and queue. Then he asked some questions on the singly linked list and asked me the real life applications of doubly linked lists. <br /> After this, he moved to DBMS concepts. Firstly, he asked me, what is the difference between the primary key and the unique key. As I mentioned indexing in my answer, he then went to indexing concepts and asked me to elaborate indexing briefly. He then asked me if I know different normalization forms in DBMS. He also asked me questions on the ACIDproperties of the databases. After this, he moved to OS. He asked me to differentiate between mutex and semaphore, process and threads. He then asked if I know any thread-related functions like thread_join, clone, thread_exit, etc. After this, he moved to the deadlocks, what are remedies for deadlocks, how does it happen, and any algorithm you know to prevent deadlocks. <br /> After completing OS, we moved to CN. He asked me what is the difference between OSI Layer Model and TCP/IP Layer Model. I had an HTTP Server project on my resume, so he then asked some questions about that project like what is HTTP, different HTTP response status codes, how a request is sent and response is received, etc. With this, he asked me which programming language I prefer. I told him C++, so he started with OOPS in C++. What is virtual function, how can we implement them, pure virtual functions, pillars of OOPS, etc. At last, we moved to the coding section where he asked me to write code for the fibonacci series and merge sort. This interview lasted for around 45-50 min.</li>
                
                <li>In the senior technical interview, we started with our introduction. There were two interviewres. As my interview was the last one, I was a bit nervous so at first they both made me comfortable by asking questions on college and academic life. I don’t remember all of my questions but will try to note as much as possible. We started with my DBMS project. They asked me to draw the architecture of my project like how it works, what are tables, primary keys, etc. Then we went on normalization. They asked me if I knew normalization or not. One of the interviewers then asked me if I could expand my architecture of the project and could find some flaws in it. As I had completed the certification of SQL from hackerrank, they then asked me questions on SQL. Difference between Delete and Truncate, what is join, types of join, asked me to explain left join, right join using venn diagrams. They also asked me to do a SQL query on the employee table (I don’t remember the actual question :( ). In between, they used to ask me coding questions. The coding questions were basically related to increment & decrement operators and on strings.</li>
                <p>
                One of the question was: <br />
                string s = “one” + 1 + 2 + “two”; <br />
                cout {'<< s << endl;'} <br/>
                return; <br />
                What will be the output of this code? <br />
                Then we moved to OS, where they asked me to differentiate between process and threads. How do threads communicate with each other, how IPC is carriedout, etc. Then they went to the OOPS part, asked me if I knew polymorphism, abstraction, inheritance and asked me to give one example of each. Then they asked me about types of polymorphism and told me to explain function overloading and function overriding. This interview lasted for 1hr.
                </p>
                <li>As I was the last person whose interview was scheduled for the HR round, and I had just completed my senior technical interview, I was quite disturbed and nervous. So, the interviewer made me comfortable by saying you did a great job till now, it’s just a basic talk we are having. We started with our introduction. He then asked me a question on Self join. I was able to tell him my approach but was unable to make a query. But he was impressed with my approach. Then he asked me about my internship at American Axle Manufacturing Pvt India, which project I completed there, which technologies I learnt. He was quite impressed with my internship and internship project. Then he asked me what I did as Events and Operations Head in MindSpark’21. Then the interview was over and we had talks on the normal life of a software developer. He said he had 25+ years of experience in this field, his experience in IT field, in BNY MELLON. The interview ended on a good note.</li>
              </ul>
            </p>
          </p>
          <p>
            <b>
              3. How many students were shortlisted after each round?
            </b>
            <br />
            <p>
            After the coding test, 30 students were shortlisted for the interview procedure. After the first interview, around 17 students were shortlisted for the senior technical round and 14 were selected for the HR (HR + Technical for some students) Round. And, finally, 13 were selected for the final offer.  </p>
          </p>

          <p>
            <b>4. Some good resources you used in your Placement prep</b>
            <br />I solved{" "}
            For coding practice, I followed geeks for geeks as they have a variety of questions. For SQL queries, I solved questions from hackerrank.
            </p>
          <p>
            <b>5. Some tips to crack coding questions :</b>
            <br />
            Consistency is the key. Keep practicing the coding questions and don’t forget your basics.
          </p>

          
          <p>
            <b>7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume</b> <br />
            <p>
            As you can see, only relatable questions asked on the projects and extracurricular activities. </p>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:tapadiyatr19.comp@coep.ac.in">tapadiyatr19.comp@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default tarun_tapadiya;

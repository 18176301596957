import React from "react";

const aniket_jayateerth = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Chetas Borse</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Mastercard</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>
              1. What was the structure of the entire internship hiring process?
            </b>
            <br />
            There were 2 rounds:
            <ol type="1" style={{ marginLeft: "2rem" }}>
              <li>
                First Round was an aptitude test which unlike had coding
                questions.
              </li>
              <li>
                Second Round was the Interview round which was taken online.
              </li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            Unlike the aptitude tests of other companies, Mastercard had 2
            coding questions of 100 marks each. One question was pretty
            straightforward and easy while the second question was tricky but
            solvable.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            Well again unlike other companies Mastercard just had 1 interview
            round. My interview round was mostly technical. They asked me
            questions based on the projects which I had mentioned in my resume.
            Questions related to OOPs, Data Structures were also asked.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            Projects mentioned in the resume were of main focus in the interview
            round. Most of the questions they asked me were based on my projects
            and the technologies that I had used to implement them. So it would
            be good if you know all your projects inside out. There is always a
            room for improvement. So be sure you know what improvements can be
            done in your project.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <a href="https://hackerrank.com">HackerRank</a> has the “Interview
            Preparation Kit” section which has good coding questions for many
            topics which are asked in interviews and also the aptitude tests.
            Make sure to check it out once. Also refer{" "}
            <a href="https://geeksforgeeks.com">GeeksforGeeks</a> to clear basic
            concepts of Data Structures and OOPs.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <b>Coding</b>: When there are 2 coding questions given, one of them
            is always easy. So try to solve that question in the beginning. If
            you are unable to pass all the test cases or if you are running out
            of time, try clearing as many test cases as possible.
            <br />
            <b>Interview</b>: While answering the questions, be confident. Do
            not bluff or take a guess if you don’t know any answer. At the end
            of your interview make sure you ask a good question related to the
            internship or projects the interns work. Also ask about the
            improvements that the interviewer feels you should make so that it
            helps you to be better in the next interview.
          </p>

          <p>
            <i>
              During our internship period Mastercard was the 5th company to
              come to the campus. Except one, I had managed to clear the
              aptitude round of all the companies but was getting rejected
              during the interview process. It was really frustrating and
              difficult to handle such rejections but learning from the mistakes
              and continuing for the next company is necessary. It is important
              to note that luck also plays a bigger role in this. It may happen
              that you answer most of the questions in an interview correctly,
              solve the coding questions and still not qualify for the next
              round. Staying mentally strong and patient is really important in
              this period.
            </i>

            <br />
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:mailto:borsecm18.comp@coep.ac.in">
              borsecm18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default aniket_jayateerth;

import React from "react";

const snehasish_bose = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Snehasish Bose
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>mastercard</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            <ol>
                                <li>Online Assessment</li>
                                <li>Interviews:
                                    <ol>
                                        <li>2 Technical Interviews</li>
                                        <li>1 HR Interview</li>
                                    </ol></li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The Online Assessment was conducted on HirePro platform and everyone who had a CGPA above 7.5 was eligible was the assessment.
                            <br />The test had 2 Coding Questions:<br />
                            <br />
                        </p>
                        <p>
                            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                <li>The first one was a String Question, it was a pretty easy question.</li>
                                <li>The second one was a DP question and had many edge cases. It was a tough one and no one was able to pass all the test cases</li>
                            </ul>
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 3 rounds, 2 Technical Rounds and a HR round.
                        <br />
                        In the Technical Rounds I was asked mostly on my projects, and a kind of deep dive on those.
                        <br />I was also asked on DBMS in my second Technical Round and an Arrays question and a puzzle.
                        <br />In the HR Round, it was more of a relaxed conversation, it is always good to read up on the company before appearing in the HR Round, and also prepare a good non-generic thought provoking question because they usually ask at the end that if we have any questions for them, and a good question sure makes a lasting impression.

                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        60-70 were shortlisted after the Online Assessment and 13 were offered internships.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                For DSA, I practised on geeksforgeeks and Leetcode and on Youtube referred to Striver and NeetCode.                            </li>
                            <li>
                                For OOPS, DBMS, CN, OS I referred to Interview questions sheet of InterviewBit and also Last Minute Notes and interview question on geeksforgeeks                            </li>
                            <li>
                                For Aptitude referred to IndiaBix                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practise Regularly on geeksforgeeks on Leetcode, also after solving a question don’t just jump to the next question try to solve it using other optimised methods.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            I was asked questions pertaining to my projects mainly in my technical interviews as for the clubs and extracurricular we touched upon it in the HR Round.  </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Have a extremely good grasp on your project so that you could answer any question regarding it, also not mandatory but as mastercard mainly uses java, it’s a tad advantageous if you know java, but they won't hold it against you if you don't, just be clear about in the start itself if the interviewer asks you something on java.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            The Internship Hiring period is stressful one, with companies coming one after the another, and it gets very frustrating if one does not gets the desired outcome but keep on grinding and preparing for the next company, always talk to a friend or family members and don’t lose hope,
                            As Andy from Shawshank Redemption said: “hope is a good thing, maybe the best of things, and no good thing ever dies.”                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:bosess21.comp@coeptech.ac.in">
                            bosess21.comp@coeptech.ac.in                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default snehasish_bose;

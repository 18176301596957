import React from "react";

const anshul_kulkarni = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Anshul Kulkarni
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>British Petroleum</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />
            British Petroleum had the criteria of 7.5+ CGPA and CS branch. The entire
            process was held online.
            The entire process was divided as follows:
            <ol>
              <li>(i) Coding round on HireVue. (90 minutes)</li>
              <li>(ii) Technical Interview. (60 minutes)</li>
              <li>(iii) HR Interview. (30 minutes)</li>
            </ol>

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>The aptitude round consisted of 3 coding questions, 2 of which were given 15
              minutes each to solve and the final question had 40 minutes to solve. The
              questions were based on graphs and regular expressions. A good knowledge
              of DSA will help in cracking the coding questions.</p>
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            There were a total of <b>2</b> interview rounds (Technical & HR).
            <ol>
              <li>
                First round was the Technical Interview
                <p>
                  In the Technical Interview which was for about an hour, there was a quick
                  introduction between me and the 2 interviewers who were senior members of
                  the company, following which I was asked a lot of questions on DSA. I was
                  also asked to implement Stack using Linked List and questions related to it
                  like time complexity and memory leak, etc. Then followed a lot of questions on
                  theory concepts of DBMS and CN which I didn’t answer that well but I kept
                  talking about concepts related to those and they led me to what it actually
                  was. Then I was asked managerial-level questions about difficult situations in
                  my life, how I worked in a team, and conflict handling to which I told them
                  about my project team experience.
                </p>
              </li>
              <li>
                Second round was the HR Round
                <p>The HR round was for about 30 minutes which was also taken by senior
                  members of the company. It basically focused on why I applied, my
                  expectations from the role. I mentioned to them that I really liked Data
                  Science, following which they asked me what I would do as a Data Science
                  Project that would benefit the world. The questions asked were much similar
                  to the managerial questions asked to me in the previous round.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            There were a total of 42 students for the Aptitude Test out of which 11
            students went through for the Technical Interview and finally 6 students for the
            HR Interview and Final Offer.

          </p>

          <p>
            <b>
              5. Some good resources you used in your Placement prep
            </b>
            <br />
            For CP, I used Leetcode, HackerRank and GeeksForGeeks.
            For theory, I used Abdul Bari and GeeksForGeeks.

          </p>
          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            The best way to crack the coding questions is regularly solving questions from
            websites like Leetcode, GeeksForGeeks, HackerRank etc. Start by solving
            easier ones and then make your way up to the harder questions. Also if you
            find that you are unable to solve the coding question, do a bit of hard coding to
            pass the given test cases.

            <br />
          </p>

          <p>
            <b>
              7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume?
            </b>
            <br />
            <p>There weren’t specific directed questions on those topics. However I was
              asked to explain to them about my 1-2 favourite projects. Always make sure to
              keep only those projects on which you can speak well and answer every
              possible question posed.
            </p>
          </p>
          <p>
            <b>
              8. Is there something specific that you would want to tell people who try for the
              same company?
            </b>
            <br />
            <p>British Petroleum focuses on many aspects so firstly be thorough with basic
              DSA that will ensure your coding round pass. Also you might be questioned
              on a few projects on your resume. Just be confident during the interviews and
              continuously engage the interviewer(s).
            </p>
          </p>
          <p>
            <b>
              Final Tip for Juniors
            </b>
            <p>
              Don’t ever lose hope even if you get rejected by multiple companies. Always
              keep the CP grind on and keep motivating yourself. With high self belief and
              efforts, luck will surely follow!
            </p>
          </p>
          <br />
          
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email ID : <a href="mailto:kulkarniaa19.comp@coep.ac.in">
            kulkarniaa19.comp@coep.ac.in
            </a>
            <br />
            LinkedIn Handle : <a href="https://www.linkedin.com/in/anshul-k-977582129/">Anshul Kulkarni</a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};
export default anshul_kulkarni;

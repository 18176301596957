import React from "react";

const ankita_vaid = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Eesha Kurode</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Mastercard</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />
          <p>
            The first step was aptitude test. The questions asked were quant and
            a technical MCQs. Technical questions were mainly about DSA
            (especially Trees and Graphs) and a few from DBMS. The questions
            were mostly easy to medium on difficulty level. There were few
            questions with high difficulty.
          </p>

          <p>
            The interview process took place in their office. All the students
            selected from the aptitude were invited for interviews in one day.
            Few students were called for the second round on the same day. Most
            had a one-round interview only. Some students had a HR-like
            interview with 1 or 2 technical questions. All interviewers started
            with the projects or other things mentioned in the resume.
          </p>

          <p>
            My interview was purely technical and was done in one round. The
            interviewer started off with my resume and asked me to brief about
            the on-going networking project (Proxy Server in Python). He also
            asked about the DSA project.
          </p>
          <p>
            I had done a research internship in Natural Language Processing at
            IIT Kharagpur and I was asked about it. I explained very briefly
            first but the interviewer was interested to know more and I figured
            they did have projects for ML/DL. So, I elaborated the entire work
            and the project that I had been a part of. The interviewer too had a
            lot of questions on this. I did convince him that I had a good
            experience in NLP and I sort of confused him with his own questions
            in the end.
          </p>
          <p>
            Then I was asked about basic OOP concepts. In the end, he asked me
            to write a pseudocode for Fibonacci Series recursively and
            iteratively. I really don’t know why he asked me to write it, I
            could tell he was already convinced. He said it was okay halfway
            through my writing. Basically, most of the interview was on the
            basis of my resume.
          </p>

          <p>
            For quantitative aptitude, I tried to figure out what sort of
            problems do I have trouble in solving and worked on those from R S
            Agarwal. If you are a person who has given all the scholarship exams
            and Olympiads, your quantitative aptitude is likely to be strong,
            it’s just a matter of speed. I did the technical part from GFG. I
            did coding practice on HackerRank. (MasterCard did not have coding
            questions anyway)
          </p>

          <p>
            For MasterCard, if you have one thing you are really really good at,
            you’re in once you prove it to them. Also, they do assign projects
            based on what you are good at, is what I have heard from seniors.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:kurodeer17.it@coep.ac.in">
              kurodeer17.it@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ankita_vaid;

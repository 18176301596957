import React from "react";

const aditya_naik = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
           Aditya Naik 
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ol style={{ padding: "40px" }}>
              <li>
                Aptitude + Coding
              </li>
              <li>
                Technical Interview
              </li>
              <li>
                HR Interview
              </li>
            </ol>

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              Aptitude round was moderate.Aptitude was based on Verbal ability,Logical
              reasoning and Graph interpretation and stock data.
            </p>
            <p>Platform- amcat</p>
            Coding Questions:
            <ol style={{ paddingLeft: "40px" }}>
              <li>
                Consider a NxN square board. Each square consists of a number. You assign each row to a friend.You make pairs of these friends. Each pair combines their rows such that the sum of products of squares (1 from both rows) is minimum. <br />
                So what is the maximum possible sum?
              </li>
              <li>
                Consider a round table with n guests (numbered from 0 to n-1). A parcel is passed starting from 0 passed to the next guest every 1 sec. A player with the parcel is eliminated after every k seconds.  <br />Return the number of the last guest.
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b><br />
            There were 2 rounds :
            <p style={{ paddingLeft: "40px" }}>
              2 Rounds after aptitude : Technical Interview and HR Interview.
            </p>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            <p>
              For technical Interview: 10 <br /> For HR interview: 8 <br /> After HR interview: 4
            </p>
          </p>

          <p>
            <b>
              5. Some good resources you used in your internship prep
            </b>
            <br />
            <p>
              Many online resources and coding platforms are available.
            </p>
          </p>

          <p>
            <b>
              6. Some tips to crack coding questions
            </b>
            <br />

            <p>
              Questions are lengthy but easy to do once understood. <br /> Practice coding regularly. <br /> Competitive Coding. <br /> Practice DP.
            </p>
          </p>

          <p>
            <b>7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume</b> <br />
            <p>
              Technical Interview was based on Projects completely and some general
              concepts.
              HR Interview was based on Logical problems(like 7 balls problems,etc)
            </p>
          </p>
          <p>
            <strong>
              8. Is there something specific that you would want to tell people who try for the
              same company
            </strong>
            Study the concepts specified in your resume. <br /> Be sure that you can explain your complete projects and provide any
            improvement asked.
          </p>
          <b>
            9. Final Tip for Juniors?
          </b>
          <p>
            This phase is very difficult, especially when you keep getting
            rejected. Just try to stay calm, talk to your friends, and family, and keep working.
            Keep realistic targets, don't overburden yourself, and please don't compare
            yourself with others as everyone has a different journey and story.
          </p>
          <br />



          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID : <a href="mailto:naikas20.comp@coep.ac.in">
            naikas20.comp@coep.ac.in
            </a>
            <br />
          </div>
        </div>
      </section >
    </div >
  );
};

export default aditya_naik;

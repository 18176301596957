import React from "react";

const darshan_shah = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
           Darshan Shah
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
          Arcesium
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
          
            <ol>
              <li>
                <p>First round was an aptitude test for 1 and half hour on HackerRank on 18th August 2021.
</p>
              </li>
              <li>
                <p>There were 3 technical rounds on 20th August 2021.</p>
              </li>

            </ol>
         
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
            <p>
                  Aptitude test was divided into 3 sections.

                  </p>
              <ol>
                
                 
                <li>
                  <p>
                  	<b>Quant Section:</b> 20 minutes were given for 20 questions.
                  </p>
                </li>
                <li>
                  <p>
                  <b>Technical MCQs:</b> Consisted of questions on the concepts of DSA, OOP, Networking, OS, DBMS along with some predict the output type questions in C language. 15 questions with the time limit of 15 minutes.
                  </p>
                </li>
                <li>
                  <p>
                  <b>Coding Questions:</b> 2 Coding questions were given to solve in the time limit of 45 minutes.
                  </p>
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
            There were 3 technical rounds for Arcesium.

            </p>
            <ol>
              <li>
                <strong>Technical Round 1 (45-50 minutes):
</strong>
                <p>
                This round was mainly focused on problem solving skills. It started with my introduction and some questions on OOP. A problem on binary trees 
was asked to me.

                </p>
               
              </li>
              <li>
                <p>
                  <strong>Technical Round 2 (45-50 minutes):
</strong>
                
                  <p>
                  This round was mainly focused on problem solving skills along with some core CS subjects.  </p>
	<p>A problem on dynamic programming was asked to me. After reading the problem, I came up with the recursive approach and further optimised it using dynamic programming.</p>
<p>	Then  some concepts of DBMS and OS were asked.</p>


                 
                </p>
              </li>
              <li>
                <p>
                  <strong>Technical Round 3 (1 hour):
 </strong>
                </p>
                <p>
                This round was purely based on the concepts of core CS subjects.
    Questions on CN,DBMS , OS and OOP were asked.

                </p>
              </li>
             
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <p>
            
            Very few questions were asked about the projects mentioned on the resume.
            
            </p>
         
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
            <li>
            For aptitude:  indiabix and GeeksforGeeks

            </li>
            <li>
            For core CS subjects: GeeksforGeeks. 

            </li>
            <li>
            For coding:  GFG .
            </li>
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
            Practise solving the problems.

            </p>
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
         
          <p>
          Problem solving skills along with communication skills are important.
          </p>
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:shahdh19.comp@coep.ac.in ">
            shahdh19.comp@coep.ac.in 
            </a>
            
            
          </div>
        </div>
      </section>
    </div>
  );
};

export default darshan_shah;

import React from "react";

const vadiraj_joshi = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Vadiraj Joshi
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            1st round was the online aptitude test.<br />
                            2nd round was the interview for the shortlisted students from online test. <br />
                            Due to time constraints we did not have two separate interviews i.e technical and HR. We gave only a single interview in which they asked technical as well as HR questions.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            So, the online Aptitude round consisted of questions of different domain.
                            First set consisted of questions related to English proficiency. It basically had questions like what is the synonym/antonym of a word, complete the sentence, identify grammatical error, etc. They had also given 3 comprehension paragraphs which we had to read and answer 4-5 questions based on those paragraphs.
                            Second set had questions related to business analytics. Questions related to studying the candlestick graphs, predicting the stock prices, etc were included in this section.
                            Third set included the coding questions. In total there were 3 questions, first two questions were easy questions and the last was a medium (for me). Actually the last question was different for everyone so, some of us got a medium level question while others got a hard one.

                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Initially, we were told that there would be three rounds : Aptitude round, technical interview and HR interview.
                        Due to time constraints, out technical and HR interview rounds were combined into a single interview round.
                        Details about the aptitude round are mentioned already.
                        Talking about the interview, since there were many panels of interviewers, each one of us were asked different kinds of questions.
                        Talking about myself, firstly I was asked about things I had mentioned in my resume. I was asked about the clubs that I had mentioned in my resume like what work I did there, why I joined the club, etc. Then I was asked about the projects that I had done. Firstly I was asked how I came up with the idea of developing the project and also why I chose that project. Since it was a group project, he also asked me about how we divided the work among the team members and which team member handled what part of the project. Then he asked me to explain how we came up with the solution for the problem statement we had chosen. I was asked to explain the solution in detail like what data structure we used, why did we use that, their efficiency, etc. Then I was asked “You did this project in 2nd year and now you are in third year. So, keeping in mind whatever you have learned in this period of 1 year, what changes would you make in your project to make it better?”. I feel that you should prepare a good answer to this question to impress the interviewer.


                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Around 150 students were shortlisted for the aptitude round.
                        20 students were selected for the interviews and 10 were selected finally for the internship.

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Personally, for concepts of dynamic programming I found the playlist of Love Babbar pretty useful. Not only for dynamic programming but for other DSA concepts as well I found them useful. I don’t recommend binge watching the entire playlist but it would be good if you watch one video for each concept in order to get the gist of the topic. Do solve atleast the blind 75 questions. You can also solve the 450 DSA questions and the striver sheet(If you can).
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Keep your DSA concepts clear and solve different types of questions on them. Leetcode is the best source and many a times questions directly come from there. So, I recommend solving atleast the famous questions on all the topics that you have learnt.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            For me, I was asked few questions on all these topics mentioned in my resume. But, many of my friends who also got selected were asked more about their projects as well.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            First and foremost, make a hella good project and know your project inside out. Keep your DSA concepts strong as few people were asked DSA questions in the interviews as well.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Prepare well, keep calm and don’t waste your vacation after 2nd year. Make good use of it and solve as many types of questions as you can. Once you become used to solving different kinds of questions, aptitude round will be a piece of cake and all you have to do is handle the interviews well. Other than that, don’t freak out before or during the interviews and keep your calm. Also, all these interviews and stuff highly depend on your luck so don’t be disappointed if things don’t go as planned. All the best ;)
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:joshivs21.comp@coeptech.ac.in">
                            joshivs21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default vadiraj_joshi;

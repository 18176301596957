import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const vishwesh_pujari = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Vishwesh Pujari
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Persistent Systems
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b id="part1">
              1. What was the structure of the entire interview process?
            </b>
            <br />
            Persistent visited COEP in August 2021. Surprisingly, only 1 round
            (the aptitude test) was there. There weren’t any interviews!
            Persistent selected 11 students for final offer after the 1st round.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test was Computer Based Online Recruitment Test (AMCAT)
            for 2 hours on 18th August 2021 from 7AM to 9AM.
            <br />
            <br/>
            <div >
            <Table >
      <Thead>
        <Tr>
          <Th style={{textAlign:"center"}}>Test</Th>
          <Th style={{textAlign:"center"}}>Module</Th>
          <Th style={{width:"200px",textAlign:"center"}}>Sections Covered</Th>
          <Th style={{textAlign:"center"}}>Duration(mins)</Th>
          <Th style={{textAlign:"center"}}>Questions</Th>


        </Tr>
      </Thead>
      <Tbody style={{textAlign:"center"}} >
        <Tr>
          <Td>Stage-1</Td>
          <Td>Computer Science

</Td>
          <Td>Operating Systems and Computer Architecture, DBMS, Computer Networks

</Td>
          <Td>20</Td>
          <Td>20</Td>
        </Tr>
<br/>


        <Tr>
        <Td></Td>
          <Td>English Comprehension



</Td>
          <Td>Vocabulary, grammar, comprehension



</Td>
          <Td>15</Td>
          <Td>12</Td>
        </Tr>
        <br/>
        <Tr>
        <Td></Td>
          <Td>Logical Ability




</Td>
          <Td>Inductive, Deductive and Abductive reasoning





</Td>
          <Td>15</Td>
          <Td>12</Td>
        </Tr>
        <br/>
        <Tr>
        <Td></Td>
          <Td>Automata Basic






</Td>
          <Td>Coding Ability in C, C++, Java, Python, Ruby, Pearl, C#






</Td>
          <Td>45</Td>
          <Td>2</Td>
        </Tr>
       
       
      </Tbody>
    </Table>
            </div>
            <br/>
            <p>Questions were accessible in a linear fashion and each question was compulsory to solve. There was no negative marking. Backward Navigation wasn’t allowed.
You need to study OS, CN, DBMS a little bit because there were a lot of questions on these topics in Technical MCQ (Computer Science) section and these subjects aren’t taught to us in the Second Year. I couldn’t solve all those questions but tried to solve as much as possible.
There were 2 coding questions which were very very easy to solve. I do not remember the questions, but their solution was not more than 10-15 lines of code.
</p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
            
                We didn’t have any interview round! But it is always better to be prepared. Maybe from next year persistent may conduct interviews.
            </p>
          </p>

          <p>
            <b>4. How many questions were asked based on the projects done?</b>
           
            
                <p>
                N.A.

                </p>
             
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <ol>
              <li>
                <p>
                Quantitative Aptitude - <a href="https://www.indiabix.com/">indiabix</a>
                </p>
              </li>
              <li>
                <p>
                Basics of OS, CN, DBMS - <a href="https://www.geeksforgeeks.org/lmns-gq/"> gfg-last-minute-notes
</a>
                </p>
              </li>
              <li>
                <p>
                Coding –  <a href="https://drive.google.com/file/d/1FMdN_OCfOI0iAeDlqswCiC2DZzD4nPsb/view">Love-Babbar-450 
</a>
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <br/>
            <ul>
              <li>
                <p>
                  <strong>
                    {" "}
                    <em><u>The Hard Way</u></em>{" "}
                  </strong>
                </p>
                <ol>
              <li>
                <p>
              Practice, Practice and Practice. And start practicing right now. (Like right now! Don’t move on to read the next diary. Solve a question and then come back). And trust me, it’s never too late. 
              </p>
              </li>

              <li>
              <p>
              Choose any one platform – Leetcode / Interviewbit / Love-Babbar-450 questions / Hackerrank, etc. and stick to that platform! I have done the mistake of switching platforms continuously just to realize that all platforms offer similar content. So, trust your platform! 
              </p>
              </li>
              <li>
              <p>
              Give a thought on each question for at least 30-35 mins. If you can’t solve, then refer to the solution. It’s perfectly okay if you have to refer to the solution for almost all the questions. It happens with everyone so make peace with it and try to understand the solution so next time you can solve a similar problem.
              </p>
              </li>
              </ol>
              </li>
              <li>
                <p>
                  <strong>
                    {" "}
                    <em><u>During your coding test:</u></em>{" "}
                  </strong>
                </p>
                <ol>
              <li>
                <p>
                Even if you have practiced a lot of questions, it might happen that you aren’t able to solve a question in the coding test. In this situation, try to apply some logic, some brute force technique so that you pass some test cases. 

              </p>
              </li>

              <li>
              <p>
              In Arcesium’s Coding test, I had only written a single if-else statement (to handle corner cases like returning -1 etc.) in 1 coding problem and had passed 2 test cases.


              </p>
              </li>
              <li>
              <p>
              In Goldman Sachs, one coding problem was  <a href="https://www.geeksforgeeks.org/snake-ladder-problem-2/">Snake-and-Ladder</a>. This is to be solved using Graphs. But the Brute-Force solution that I wrote was nowhere related to graphs. But I could pass 5/13 test cases.
              </p>
              </li>
              <li>
                <p>
                The coding problems are pitched to you in the form of a story. Try to extract the exact DSA problem out of it quickly. Sometimes stories try to mislead you to a wrong solution. Beware of it.


                </p>
              </li>
              </ol>
               
              </li>
            </ul>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
           
          The coding questions for persistent are very easy to solve. So, CS, English Comprehension and Logical Ability MCQs are the deciding factor. So, make sure to prepare for them.

          </p>
          <br />
           <p>
            <i>
             
               
               <li>
               
               Be mentally prepared for this Internship season once your third year starts. Keep all the content to be written in Resume ready at least 2 weeks before your TY Starts. College announces that xyz company is coming just 1-2 days before the aptitude test. For our batch Companies started coming from 15th August 2021. And we were told about this on 13th August Night.
               
               </li>
               <br/>
              
               <li>
               
               Give proper thought while creating your resume. Write only those projects and technical skills about which you are confident that you will be able to answer most questions
               
               </li>
               <br/>
               <li>
               
               Keep checking your mails! For some companies college just gives 6-7 hours to apply. Some of my friends didn’t see the mail and couldn’t apply because of the short span. Even if you go outside, keep your internet on and keep checking mails!
If you feel demotivated, do talk to your Parents, Seniors Friends(They too are in the same situation as you are).

               
               </li>
               <br/>
               <li>
               
               Abhijit Sir from COEP once said during a Lab that “Pain Teaches You”. The rejections from companies are going to teach you a lot, more than success would do. They are going to help you analyse where you are lacking and what you need to improve. So don’t get disheartened if you are rejected. Instead, be happy that you got a chance to improve yourself! You were rejected in this company because you are going to be selected in a better company later!

               
               </li>
               <br/>
             
            </i>
          </p> 
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
             <a href="mailto: Pujarivv19.comp@coep.ac.in
">
              Pujarivv19.comp@coep.ac.in

            </a>
            <br />
            <a href=" https://www.linkedin.com/in/vishwesh-pujari-7205391b6/
">LinkedIn Handle</a> 
          </div>
        </div>
      </section>
    </div>
  );
};

export default vishwesh_pujari;

import React from "react";

const shreya_vaidya = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Shreya Vaidya
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            The recruitment process for Citi was divided into three rounds –
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>Aptitude Test (19th August 2021)</li>
              <li>Technical Interview (24th August 2021)</li>
              <li>HR Interview (24th August 2021)</li>
            </ul>
            350+ students appeared for the aptitude test out of which 38
            students were shortlisted for the technical interview. Finally,{" "}
            <b>10 students were selected</b>.
          </p>
          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The eligibility criteria to appear for the aptitude test was a
            minimum of 6.5 CGPA for students of all branches. It was divided
            into five sections –
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>
                <u>Technical MCQs (25 questions, 22 minutes)</u>
                <ul style={{ listStyleType: "circle", marginLeft: "3rem " }}>
                  <li>Difficulty: Easy</li>
                  <li>Topics covered: C Basics, OOPs, DSA, OS, DBMS</li>
                </ul>
              </li>
              <li>
                <u>Quantitative Ability (16 questions, 16 minutes)</u>
                <ul style={{ listStyleType: "circle", marginLeft: "3rem " }}>
                  <li>Difficulty: Medium</li>
                  <li>
                    Topics covered: Combinatorics, Profit and Loss, Train
                    problems, etc.
                  </li>
                  <li>
                    The questions asked were easy, however, the time provided
                    was less, so each problem needed to be solved in around one
                    minute.
                  </li>
                </ul>
              </li>
              <li>
                <u>Logical Reasoning (14 questions, 14 minutes)</u>
                <ul style={{ listStyleType: "circle", marginLeft: "3rem " }}>
                  <li>Difficulty: Easy</li>
                  <li>
                    Topics covered: Logical Deduction, Letter and Symbol Series,
                    etc.
                  </li>
                </ul>
              </li>
              <li>
                <u>Verbal Reasoning (22 questions, 18 minutes)</u>
                <ul style={{ listStyleType: "circle", marginLeft: "3rem " }}>
                  <li>Difficulty: Easy</li>
                  <li>
                    Topics covered: Vocabulary, Grammar, and Comprehension
                  </li>
                </ul>
              </li>
              <li>
                <u>Coding Questions (2 questions, 60 minutes)</u>
                <ul style={{ listStyleType: "circle", marginLeft: "3rem " }}>
                  <li>Difficulty: Easy/Medium</li>
                  <li>
                    Questions asked:{" "}
                    <a href="https://www.geeksforgeeks.org/program-to-print-the-trapezium-pattern/">
                      Trapezium Pattern Printing
                    </a>{" "}
                    and{" "}
                    <a href="https://www.geeksforgeeks.org/find-longest-sequence-1s-binary-representation-one-flip/">
                      Bit Manipulation
                    </a>
                  </li>
                  <li>
                    I was able to pass all test cases in both the questions.
                  </li>
                </ul>
              </li>
            </ul>
          </p>
          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?{" "}
            </b>
            <br />
            There were two interview rounds which took place on the same day.
            <br />
            <br />
            <b>
              <u>Technical Interview</u>
            </b>{" "}
            <br />
            The first round was the Technical Interview. After the initial
            introductions, the interviewer started off by asking me about the
            technologies I was learning at that point. Based on my answer, I was
            asked questions on Typescript and Javascript (How are they
            different? Which one do you prefer and why?). Next, I was asked to
            code two basic programs – <b>Reverse a linked list</b> and{" "}
            <b>Delete a node from nth index in a linked list</b>. The
            interviewer then asked me about the projects I had mentioned on my
            resume and their use cases. She also asked me what changes I would
            make to one of my projects given a set of requirements. The duration
            of this interview was around 25-30 minutes.
            <br />
            <br />
            <b>
              <u>HR Interview</u>
            </b>
            <br />
            Around half an hour after the tech interview, I was asked to join
            the HR Interview. There were two interviewers for this round. After
            I introduced myself, the first question I was asked was{" "}
            <i>
              “Why do you want to work for Citi coming from a technical
              background?”
            </i>
            . I was also given a few situational questions based on teamwork and
            deadlines. Make sure you do your research on the company and pay
            attention in the Pre-Placement Talk before this round. Finally, I
            was asked whether I had any questions for them. This is your chance
            to show your interest in the company and the work they do, so
            refrain asking questions about the stipend and details which have
            already been provided to you.
          </p>
          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            For the coding round, I practiced daily on{" "}
            <a href="https://www.hackerrank.com/interview/interview-preparation-kit">
              HackerRank
            </a>{" "}
            and{" "}
            <a href="https://leetcode.com/problem-list/top-interview-questions/">
              Leetcode
            </a>
            . Going through{" "}
            <a href="https://www.geeksforgeeks.org/must-coding-questions-company-wise/">
              GFG’s company-wise archive
            </a>{" "}
            of previously asked coding questions is also very helpful. I
            practiced questions from{" "}
            <a href="https://www.indiabix.com/">Indiabix</a> for quant and
            logical reasoning. For topics like CN, DBMS, and OS (not covered in
            the syllabus by that point), I went through the{" "}
            <a href="https://www.geeksforgeeks.org/lmns-gq/">
              Last-Minute Notes
            </a>{" "}
            available on GFG.
          </p>
          <p>
            <b>5. Some tips to crack the coding questions.</b> <br />I know
            every single diary you’ve read says this but practicing regularly is
            the only way you can get a hang of the coding questions. Stick to
            one or two coding platforms and set a goal to solve a certain number
            of questions every single day. Also, understanding concepts like
            Dynamic Programming can be challenging at first, so go through a few
            videos by{" "}
            <a href="https://www.youtube.com/playlist?list=PL_z_8CaSLPWekqhdCPmFohncHwz8TY2Go">
              Aditya Verma
            </a>{" "}
            or{" "}
            <a href="https://www.youtube.com/playlist?list=PLJULIlvhz0rE83NKhnq7acXYIeA0o1dXb">
              Abdul Bari
            </a>{" "}
            for better clarity.
          </p>
          <br />
          <i>
            The entire internship recruitment period can be very exhausting. So,
            it is very important to stay calm and trust the process. Even if you
            don’t get selected in a particular company, don’t be disheartened
            and give it your best. All the best and feel free to contact me if
            you need any help!
          </i>
          <br />
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:vaidyas19.comp@coep.ac.in">
              vaidyas19.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/shreya-vaidya/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default shreya_vaidya;

// Importing Student Profile pictures
import tanmay_patil from "../diaries-info/PlacementDiaries_2023/tanmay_patil.jpg";
import aniket_warhade from "../diaries-info/PlacementDiaries_2023/Aniket Warhade.jpg";
import abhay_gaikwad from "../diaries-info/PlacementDiaries_2023/Abhay Gaikwad.jpg";
import rohan_advani from "../diaries-info/PlacementDiaries_2023/Rohan Advani.jpg";
import yadnyadeep_khadke from "../diaries-info/PlacementDiaries_2023/yadnyadeep_khadke.jpg";
import shubham_somawanshi from "../diaries-info/PlacementDiaries_2023/shubham_somawanshi.jpg";
import nishant_badgujar from "../diaries-info/PlacementDiaries_2023/nishant_badgujar.jpg";
import sakshi_mehetre from "../diaries-info/PlacementDiaries_2023/sakshi_mehtre.png";
import dipak_ambhure from "../diaries-info/PlacementDiaries_2023/dipak ambhure.jpg";
import suraj_yerkal from "../diaries-info/PlacementDiaries_2023/suraj_yerkal.png";
import aayush from "../diaries-info/BTech_data/ayush_shah.jpg";
import naishadh from "../diaries-info/naishadh_vora.jpg";
import jash from "../diaries-info/BTech_data/jash_dalal.png";
import umang from "../diaries-info/umang_sakhare.jpg";
import naman from "../diaries-info/naman_modi.jpg";
import yash from "../diaries-info/yash_gangwar.jpg";
import falguni from "../diaries-info/falguni_patil.jpg";
import piyusha from "../diaries-info/BTech_data/piyusha_vidhale.png";
import rupali from "../diaries-info/BTech_data/rupali_bawale.jpg";
import varun_taneja from "../diaries-info/PlacementDiaries_2023/Varun Taneja.jpg";
import anshul_kulkarni from "../diaries-info/PlacementDiaries_2023/Anshul Kulkarni.jpg";
import pallavi_buwa from "../diaries-info/PlacementDiaries_2023/Pallavi Buwa.jpg";
import vishwesh_pujari from "../diaries-info/PlacementDiaries_2023/Vishwesh Pujari.jpg";
import tarun_tapadiya from "../diaries-info/PlacementDiaries_2023/Tarun Tapadiya.jpg";

// Importing Company logos
// import TcsLogo from "../img/company-logos/tcs_logo.png";
import SocieteLogo from "../img/company-logos/sociate_general.png";
import OracleLogo from "../img/company-logos/oracle.png";
import PhonePeLogo from "../img/company-logos/PhonePe.png";
import SchlumbergerLogo from "../img/company-logos/Schlumberger-logo.png";
import AvayaLogo from "../img/company-logos/Avaya-logo.png";
import TallyLogo from "../img/company-logos/tally-logo.png";
import ToshibaLogo from "../img/company-logos/toshiba-logo.png";
import CitiLogo from "../img/company-logos/citi-logo.png";
import VeritasLogo from "../img/company-logos/veritas-logo.png";
import DeutscheBankLogo from "../img/company-logos/deutsche-logo.png";
import GainsightLogo from "../img/company-logos/gainsight-logo.png";
import WellsFargo from "../img/company-logos/wells_fargo_logo.png";
import BritishPetroleum from "../img/company-logos/british_petroleum_logo.png";
import BNYM from "../img/company-logos/BNYM.png";
import NCS from "../img/company-logos/ncs_logo.png";

// Importing Diaries
import tanmay_patil_html from "../diaries-info/PlacementDiaries_2023/tanmay_patil";
import yadnyadeep_khadke_html from "../diaries-info/PlacementDiaries_2023/yadnyadeep_khadke";
import shubham_somawanshi_html from "../diaries-info/PlacementDiaries_2023/shubham_somawanshi";
import nishant_badgujar_html from "../diaries-info/PlacementDiaries_2023/nishant_badgujar";
import sakshi_mehetre_html from "../diaries-info/PlacementDiaries_2023/sakshi_mehetre";
import dipak_ambhure_html from "../diaries-info/PlacementDiaries_2023/dipak_ambhure";
import suraj_yerkal_html from "../diaries-info/PlacementDiaries_2023/suraj_yerkal";
import jash_html from "./../diaries-info/BTech_data/jash_dalal.js";
import aayush_html_avaya from "../diaries-info/BTech_data/ayush_shah_avaya.js";
import naishadh_html_toshiba from "../diaries-info/BTech_data/naishadh_vora_toshiba.js";
import umang_sakhare_tally from "./../diaries-info/BTech_data/umang_sakhare_tally";
import naman_html from "../diaries-info/BTech_data/naman_modi_veritas";
import falguni_html from "../diaries-info/BTech_data/falguni_patil_citi";
import yash_html from "../diaries-info/BTech_data/yash_gangwar_citi";
import piyusha_html from "../diaries-info/BTech_data/piyusha_vidhale";
import rupali_html from "../diaries-info/BTech_data/rupali_bawale";
import varun_taneja_html from "../diaries-info/PlacementDiaries_2023/varun_taneja";
import anshul_kulkarni_html from "../diaries-info/PlacementDiaries_2023/anshul_kulkarni";
import pallavi_buwa_html from "../diaries-info/PlacementDiaries_2023/pallavi_buwa";
import vishwesh_pujari_html from "../diaries-info/PlacementDiaries_2023/vishwesh_pujari";
import tarun_tapadiya_html from "../diaries-info/PlacementDiaries_2023/tarun_tapadiya";
import rohan_advani_html from "../diaries-info/PlacementDiaries_2023/rohan_advani";
import abhay_gaikwad_html from "../diaries-info/PlacementDiaries_2023/abhay_gaikwad";
import aniket_warhade_html from "../diaries-info/PlacementDiaries_2023/aniket_warhade";

export const data = [
  // NCS 
  {
    company: "NCS ",
    logo: NCS,

    isUpdated: false,
    batches:["2023"],
   interns: [
      {
        image: rohan_advani,
        firstname: "Rohaan",
        lastname: "Advani",
        html: rohan_advani_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2023",
        },
        batch:"2023",
      },
    ],
  },
  // Soecite General
  {
    company: "Societe Generale",
    logo: SocieteLogo,

    isUpdated: false,
    batches:["2023"],
   interns: [
    {
      image: aniket_warhade,
      firstname: "Aniket",
      lastname: "Warhade",
      html: aniket_warhade_html,
      isNew: false,
      date: {
        day: "12",
        super: "th",
        rest: "July, 2023",
      },
      batch:"2023",
    },
      {
        image: shubham_somawanshi,
        firstname: "Shubham",
        lastname: "Somwanshi",
        html: shubham_somawanshi_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2023",
        },
        batch:"2023",
      },
    ],
  },
  // BNY Mellon
  {
    company: "BNY Mellon",
    logo: BNYM,

    isUpdated: false,
    batches:["2023"],
   interns: [
    {
      image: abhay_gaikwad,
      firstname: "Abhay",
      lastname: "Gaikwad",
      html: abhay_gaikwad_html,
      isNew: false,
      date: {
        day: "12",
        super: "th",
        rest: "July, 2023",
      },
      batch:"2023",
    },
    {
      image: tarun_tapadiya,
      firstname: "Tarun",
      lastname: "Tapadiya",
      html: tarun_tapadiya_html,
      isNew: false,
      date: {
        day: "12",
        super: "th",
        rest: "July, 2023",
      },
      batch:"2023",
    },
      {
        image: vishwesh_pujari,
        firstname: "Vishwesh",
        lastname: "Pujari",
        html: vishwesh_pujari_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2023",
        },
        batch:"2023",
      },
    ],
  },
    // Oracle
    {
      company: "Oracle",
      logo: OracleLogo,
  
      isUpdated: false,
      batches:["2023"],
     interns: [
      {
        image: yadnyadeep_khadke,
        firstname: "Yadnyadeep",
        lastname: "Khadke",
        html: yadnyadeep_khadke_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2023",
        },
        batch:"2023",
      },
        {
          image: dipak_ambhure,
          firstname: "Dipak",
          lastname: "Ambhure",
          html: dipak_ambhure_html,
          isNew: false,
          date: {
            day: "12",
            super: "th",
            rest: "July, 2023",
          },
          batch:"2023",
        },
      ],
    },
  // Phone Pe
  {
    company: "Phone Pe",
    logo: PhonePeLogo,

    isUpdated: false,
    batches:["2023"],
   interns: [
      {
        image: suraj_yerkal,
        firstname: "Suraj",
        lastname: "Yerkal",
        html: suraj_yerkal_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2023",
        },
        batch:"2023",
      },
    ],
  },
  // British Pertroleum 
  {
    company: "British Petroleum",
    logo: BritishPetroleum,

    isUpdated: false,
    batches:["2023"],
   interns: [
      {
        image: anshul_kulkarni,
        firstname: "Anshul",
        lastname: "Kulkarni",
        html: anshul_kulkarni_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2023",
        },
        batch:"2023",
      },
    ],
  },
  // Wells Fargo
  {
    company: "Wells Fargo",
    logo: WellsFargo,

    isUpdated: false,
    batches:["2023"],
   interns: [
    {
      image: pallavi_buwa,
      firstname: "Pallavi",
      lastname: "Buwa",
      html: pallavi_buwa_html,
      isNew: false,
      date: {
        day: "12",
        super: "th",
        rest: "July, 2023",
      },
      batch:"2023",
    },
      {
        image: varun_taneja,
        firstname: "Varun",
        lastname: "Taneja",
        html: varun_taneja_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2023",
        },
        batch:"2023",
      },
    ],
  },
  //Veritas
  {
    company: "Veritas",
    logo: VeritasLogo,

    isUpdated: false,
    batches:["2021","2023"],
   interns: [
    {
      image: nishant_badgujar,
      firstname: "Nishant",
      lastname: "Badgujar",
      html: nishant_badgujar_html,
      isNew: false,
      date: {
        day: "12",
        super: "th",
        rest: "July, 2023",
      },
      batch:"2023",
    },
      {
        image: naman,
        firstname: "Naman",
        lastname: "Modi",
        html: naman_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2021",
        },
        batch:"2021",
      },
    ],
  },
  //Gainsight
  {
    company: "Gainsight",
    logo: GainsightLogo,

    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: rupali,
        firstname: "Rupali",
        lastname: "Bawale",
        html: rupali_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2021",
        },
        batch:"2021",
      },
    ],
  },
  //Citi
  {
    company: "Citi",
    logo: CitiLogo,

    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: falguni,
        firstname: "Falguni",
        lastname: "Patil",
        html: falguni_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2021",
        },
        batch:"2021",
      },
      {
        image: yash,
        firstname: "Yash",
        lastname: "Gangwar",
        html: yash_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2021",
        },
        batch:"2021",
      },
    ],
  },
  //Deutsche Bank
  {
    company: "Deutsche Bank",
    logo: DeutscheBankLogo,

    isUpdated: false,
    batches:["2021","2023"],
    interns: [
      {
        image: tanmay_patil,
        firstname: "Tanmay",
        lastname: "Patil",
        html: tanmay_patil_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2023",
        },
        batch:"2023",
      },
      {
        image: sakshi_mehetre,
        firstname: "Sakshi",
        lastname: "Mehetre",
        html: sakshi_mehetre_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2023",
        },
        batch:"2023",
      },
      {
        image: piyusha,
        firstname: "Piyusha",
        lastname: "Vidhale",
        html: piyusha_html,
        isNew: false,
        date: {
          day: "12",
          super: "th",
          rest: "July, 2021",
        },
        batch:"2021",
      },
     
    ],
  },
  //Toshiba
  {
    company: "Toshiba",
    logo: ToshibaLogo,
    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: naishadh,
        firstname: "Naishadh",
        lastname: "Vora",
        html: naishadh_html_toshiba,
        isNew: false,
        date: {
          day: "5",
          super: "th",
          rest: "July, 2021",
        },
        batch:"2021",
      },
    ],
  },
  //Avaya
  {
    company: "Avaya",
    logo: AvayaLogo,

    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: aayush,
        firstname: "Aayush",
        lastname: "Shah",
        html: aayush_html_avaya,
        isNew: false,
        date: {
          day: "5",
          super: "th",
          rest: "July, 2021",
        },
        batch:"2021",
        
      },
    ],
  },
  //Schlumberger
  {
    company: "Schlumberger",
    logo: SchlumbergerLogo,
    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: jash,
        firstname: "Jash",
        lastname: "Dalal",
        html: jash_html,
        isNew: false,
        date: {
          day: "5",
          super: "th",
          rest: "July, 2021",
        },
        batch:"2021",
      },
    ],
  },

  //Tally
  {
    company: "Tally",
    logo: TallyLogo,
    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: umang,
        firstname: "Umang",
        lastname: "Sakhare",
        html: umang_sakhare_tally,
        isNew: false,
        date: {
          day: "5",
          super: "th",
          rest: "July, 2021",
        },
        batch:"2021",
      },
    ],
  },
];

import React from "react";

const vrinda_ahuja = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Viren Patil</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>
              1. What was the structure of the entire internship hiring process?
            </b>
            <br />
            The total process was divided into 3 parts. The first round was an
            Aptitude Test. Around 80 students were shortlisted after the first
            round. The next round was a Technical Interview Round, after which
            11 students were shortlisted. The final round was an HR Interview
            which comprised of general questions and a little touch of technical
            flavour to them. Finally all the 11 students were selected.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test was conducted on AMCAT platform. The test was
            sub-divided into following sections:
            <ol>
              <li>
                <b>Technical MCQs</b> : This section had questions based on
                <b> DSA</b> and also some questions related to <b>C</b>, and
                make sure you prepare well about the concepts of processor,
                compilers in C, many times such questions are also asked.
                Solving quesions from{" "}
                <a href="https://www.indiabix.com/technical/interview-questions-and-answers/">
                  Indiabix
                </a>{" "}
                was much beneficial for this particular section of the test.
              </li>
              <li>
                <b>Quantitative Ability</b> : This section comprised of basic
                maths and logical reasoning questions. Solving the quant
                questions from{" "}
                <a href="https://www.geeksforgeeks.org/quantitative-aptitude-gq/">
                  GeeksForGeeks
                </a>{" "}
                will be very benificial as these question sets are made from the
                perspective of such aptitude tests. It helped me a lot!
              </li>
              <li>
                <b>Verbal</b> : This section tested your vocabulary and ability
                to answer comprehension questions quickly and accurately.
              </li>
              <li>
                <b>Coding Questions</b> : This round had 2 coding questions to
                be solved in <b>an hour</b>. The available language options did
                not have <b>Python</b> as an option and after noticing that, I
                was shocked for a moment, but since I had my core Data
                Structures prepared very well in C language, so I was able to do
                it. One of the questions that I remember was asked was to
                perform bit manipulation and get some desired output. So the
                trick here lies in just the basics of <b>DS</b>.
              </li>
            </ol>
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            There were total 2 interview rounds (in these rounds, the number of
            interviewers may vary from 1 to 2, in my case for both the
            interviews I had 1 interviewer):
            <ol>
              <li>
                <b>Technical Interview</b> : In this round, I was asked to tell
                him about myself. The answer which he expects is not right or
                wrong. He/She expects us to tell them how interesting you are.
                This was followed by a couple of questions on <b>linked list</b>
                . Keep this in mind, <b>Linked List</b> is a hot topic in
                interviews so make sure to get your concepts right!
              </li>
              <li>
                <b>HR Interview</b> : In this round, I was interviewed by their
                HR Head, I knew about him beforehand as he had introduced
                himself in the company presentation which happens before this
                whole hiring process begins. Since, I knew his name and
                designation I had gone through his LinkedIn profile to get to
                know more about him. This helped me a lot in this particular
                interview, especially during the end of the interview when he
                asked me if I had any questions for him. That's when you turn
                the game on your side. Never ever deny by saying that{" "}
                <b>No I don't have any questions</b>, they want to see how
                inquisitive you are so make sure to ask them anything! Of course
                you shouldn't ask questions like, "What work will I be getting
                if I get the internship, when will the stipend be credited to my
                account". <b>Never ask such questions.</b> I feel this HR round
                was the deciding factor for me. So make sure you leverage it and
                make the most of this particular round. To add to this, during
                the interview I was asked about how "Google Maps" detects
                traffics, congestion etc. What data structures could probably be
                Google using in it's Google Maps application. So this tells you
                that the question can be completely out of the box and
                unexpected. Make sure to stay calm and answer smartly!
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            Maybe 1 or 2 and that too in the Technical Interview round.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <ol>
              <li>
                <a href="https://geeksforgeeks.org">Geeks for Geeks</a> is the
                best (both for coding questions as well as aptitude questions)
              </li>
              <li>
                <a href="https://indiabix.com">Indiabix</a>
              </li>
            </ol>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Keep one thing in mind, coding questions are not so difficult to
            crack, it's just about not losing your cool. Revise the already
            solved questions in the data structures course. Most importantly,
            solve the previously asked questions which are available on Geeks
            for Geeks under the internship experience section which is divided
            into sections (company-wise). Your aim should only be to pass as
            many test cases as possible. Don't waste your time about just
            thinking about a fool-proof solution. Just keep on writing a code.
            Follow the brute-force approach first (this gets you through at
            least 2-3 tests almost always), then proceed to enhancing your
            solution and improvising on it. Secondly think about basic corner
            cases that you can think of, many a times writing code for 2-3
            corner cases gets you through many test cases and that's a very big
            plus point.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            As you can clearly observe, the amount of explanation I gave in HR
            round, it's very clear that Citi emphasises on HR Interviews. You
            should be very confident while you answer in HR Round. Never be
            afraid to ask anything if given a chance. So, do your research on HR
            Rounds and go crack it!
          </p>

          <p>
            <br />
            <i>
              Don't get disheartened, if you don't get selected, instead learn
              from these failures, you won't have the taste of success unless
              and until you taste the failures! So keep calm and don't stress
              yourself a lot. In the interviews, remember that you are the{" "}
              <b>controller</b> and not the interviewer, so always answer in
              such a way that the next question will always be the one you
              expected, or we could say be in your favour!
              <br />
              <br />
              All the best to you!!
            </i>
            <br />
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:patilvr18.comp@coep.ac.in">
              patilvr18.comp@coep.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default vrinda_ahuja;

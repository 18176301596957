import React from "react";
import "../assets/css/testimonial.css";
import leftArrow from "../assets/img/testimonial_btnslider_arrows/left-arrow.svg";
import rightArrow from "../assets/img/testimonial_btnslider_arrows/right-arrow.svg";

export default function BtnSliderTestimonial({ direction, moveSlide }) {
  return (
    <button
      onClick={moveSlide}
      className={direction === "next" ? "btn-slide next" : "btn-slide prev"}
    >
      <img src={direction === "next" ? rightArrow : leftArrow} alt="slider"/>
    </button>
  );
}
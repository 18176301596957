import React from "react";

const ishan_patwardhan = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Ishan Patwardhan
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
            Aptitude test, Written Test, Group discussion, Technical Interview
            </p>

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
            Programming, Aptitude and General Knowledge questions were asked
            </p>
          </p>

          <p>
            <b>3. How many rounds were there for the company, can you elaborate about each
              round in brief</b> <br />
              Total 4 rounds were conducted:
              <ol>
                <li>
                The first round was an Aptitude test which consisted of 50 questions. It was divided into 4 sections, General Programming, English, Quant, and General Knowledge. It was not too easy, not too difficult. 
                </li>
                <li>
                The next round was a written paper. It consisted of 3 questions, 1 coding question, one business analytics related question and one general aptitude related question.
                </li>
                <li>
                The third round was a group discussion where we were given varied topics like metaverse, digital payments and other such.
                </li>
                <li>
                The final round was a technical interview in which I was asked about the projects that I had mentioned in my resume followed by DSA questions. They also asked me to solve one of the algorithms on a sheet of paper. The technical questions were followed by some HR questions. There was no separate HR round due to time constraint as all the rounds were conducted in a single day.
                </li>
              </ol>
            
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            <p>
            Around 50 students were shortlisted after 1st round for the 2nd round.All were qualified for the third round. However, around 20 were shortlisted for the final technical interview out of which 14 were selected.
            </p>
          </p>

            <p>
              <b>
                5. Some good resources you used in your internship prep
              </b>
              <br />
              I used Striver’s DSA sheet to keep a track of my progress. NeetCode was helpful too. Leetcode’s company specific questions and interview prep helped me boost my internship preparation. I had solved aptitude questions on Indiabix as well.Apart from that I referred to Interviewbit’s last minute interview preparation guide to quickly revise a few concepts every now and then.
            </p>

            <p>
              <b>
                6. Some tips to crack coding questions
              </b>
              <br />

              <p>
              Practice is the key. After you solve a select few questions from each category you become confident enough to tackle any problem so I would not suggest that you should solve a specific number of problems, but solve a few problems from each category. Make sure that you solve one leetcode question daily. Also time yourself so that you are used to coding under some pressure.When you are too bored to actually solve a question, you might watch the leetcode coding solutions available on youtube and attempt the problems sometime later.
              </p>
            </p>

            <p>
              <b>7. How many questions were asked about projects, clubs and extracurricular
                activities in your resume</b> <br />
              <p>
              Majority of the questions in the final round were based on the projects and extracurriculars I had mentioned in my resume. So, I would suggest that you mention only those projects in your resume that you are confident about and also be careful in the extracurriculars that you mention. Only mention authentic information. They asked me a few detailed questions on my project and asked me about alternate approaches than what I had implemented, so you should have an overall understanding of the projects. Don’t mention too many projects in your resume, 2 or 3 good quality projects is perfect. Same goes for extracurriculars.
              </p>
            </p>
            <p>
              <strong>
                8. Is there something specific that you would want to tell people who try for the
                same company
              </strong>
              <p>
              The company focuses on general aptitude as well as coding equally, So I’d suggest that you should be well versed with General Knowledge too. Just keep a track of the current events along with your coding practice.
              </p>
            </p>
            <br />
            <p>
              <strong>
                Final Tip for Juniors:
              </strong>
              <p>
              Start preparing well in advance. Follow a roadmap to track your progress. The quality of coding questions that you practice matters and not the quantity. Have a clear idea which companies you really are interested in and only apply to those.
              </p>
            </p>

            <h3>Contact Info:</h3>
            <div style={{ color: "gray", fontSize: "1.15rem" }}>
              <br />
              Email-ID : <a href="mailto:ishanpatwardhan01@gmail.com">
              ishanpatwardhan01@gmail.com
              </a>
              <br />
              LinkedIn : <a href="https://www.linkedin.com/in/ishan-patwardhan-a8b989209/">Ishan Patwardhan</a>
              <br />
            </div>
        </div >
      </section >
    </div >
  );
};

export default ishan_patwardhan;

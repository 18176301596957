import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import SectionHeading from "../components/SectionHeading";
import { backGround } from "../layout/customStyle";
import Testimonial from "../components/Testimonial_slider";
import { TestimonialEvents } from "../assets/js/testimonial";
//import Feedback from "../components/Feedback";
/*import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core"*/


class TestimonialPage extends Component {
   
  render() {
  
   /* const eventType = this.props.match.params.eventType;
    console.log(eventType);
    let data = [];
    let sectionHeading = "";*/
   /* if (eventType === "formal-events") {
      data = formalEvents;
      sectionHeading = "FORMAL EVENTS";
    }
    else if (eventType === "informal-events") {
      data = informalEvents;
      sectionHeading = "INFORMAL EVENTS";
    }*/
 
   let data=[];
   let sectionHeading="";
   data=TestimonialEvents;
   sectionHeading="EVENT TESTIMONIALS";
   
    
    return (
      <div>
        <main style={backGround}>
          <Navbar testimonial={true} />
         {/* <SectionHeading title="CSI Student Membership Form for FY Computer COEP"/>
          
          <Grid
  container
  id="fybtn"
  justifyContent="center"
  alignItems="center"

>
<Grid item>
<Button color="primary" variant="contained" id="regbutton"style={{wordWrap:"break-word",lineHeight:"2.5em",fontSize:"1rem"}}> Register for CSI membership </Button>
</Grid>
<Grid item>
<Button color="primary" variant="contained" id="downbtn" style={{wordWrap:"break-word",lineHeight:"2.5em",fontSize:"1rem"}}> Download Brochure</Button>
</Grid>
    </Grid>*/}
   {/*  <SectionHeading title="EVENT FEEDBACK" />
    <Feedback/>*/}
        
          <SectionHeading title={sectionHeading} />

          {data.map((event, index) => (
            <>
              <SectionHeading title={event.eventName} />
              <div>
                <Testimonial data={event.feedBacks} eventName={event.eventName} eventlink={event.eventlink}/>

              </div>
            </>
          ))}

          {/* <SectionHeading title="Event 2" />

          <div>
            <Testimonial data={codequest3} />
          </div>

          <SectionHeading title="Event 3" />
          <div>
            <Testimonial data={codequest4} />
          </div> */}

          <div className="mt-5" />
          <Footer />
        </main>
      </div>
    );
  }
}

export default TestimonialPage;

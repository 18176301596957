import React from "react";

const hrishikesh_athalye = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Hrishikesh Athalye
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>
              1. What was the structure of the entire internship hiring process?
            </b>
            <br />
            For Citi, there were 3 rounds in total. The first round was a 3-hour
            aptitude test. There was no branch restriction for the test, with a
            minimum CGPA criterion of 6.5. Post this round, 82 people were
            shortlisted for the next round. This round was a technical
            interview, after which 11 people were shortlisted. The final round
            was an interview conducted by 2 interviewers and had a mix of HR and
            technical questions. Finally, 11 people were selected.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test was conducted on a platform by Aspiring Minds. The
            test had the following sections:
            <ol type="A" style={{ marginLeft: "2rem" }}>
              <li>
                <b>Technical MCQs</b> - these were MCQs based mostly on core CS
                subjects like DSA and also some C language specific questions.
                Solving MCQs from GFG was helpful for this section.
              </li>
              <li>
                <b>Quantitative Ability</b> - This section had questions related
                to basic maths and logical reasoning. Solving select topics
                about which you aren’t very confident from RS Aggarwal can be
                very helpful for this section as this section is a part of most
                aptitude tests on campus.
              </li>
              <li>
                <b>English Language</b> - This section had MCQs based on English
                language like vocabulary and reading comprehension.
              </li>
              <li>
                <b>2 Coding Questions</b> - This round had 2 coding questions to
                be solved in an hour. The first question was to sort array
                elements by frequency while maintaining order among elements
                with similar frequency. The second was an easy question related
                to finding number of permutations. Something very important to
                highlight about the platform is that STL was not available, so
                any data structures needed had to be written which consumed some
                time. Also, Python was not a part of the available languages.
                For reasons like these, it is best to practice writing all basic
                data structures as a part of preparation because it is possible
                that libraries like STL or your choice of language won’t be
                available. I was able to solve both questions completely in this
                round.
              </li>
            </ol>
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?{" "}
            </b>
            <br />
            There were 3 rounds. The first being the aptitude test. This was
            followed by a technical interview. I was asked a variety of
            questions in this round. Most questions were based on DSA and DBMS
            concepts. I was also asked some questions on topics like NoSQL
            databases which aren’t covered directly in the curriculum. Exposure
            to these topics due to projects helped me answer most questions
            correctly. Resume or projects weren’t seen in this round.
            <br />
            Following this round, there was the final round. This was conducted
            by 2 interviewers, one focusing on HR based questions and the other
            on technical questions. As a part of technical questions, I was
            asked to share my resume and was asked a lot of questions on
            projects. I was also asked 2 puzzles. The HR part included questions
            like – “Why do you want to work at Citi?”, “Give some examples where
            you handled failure”, “What is your biggest weakness?”, etc.
            Questions like these can be puzzling to answer if you haven’t
            already given them some thought before, so doing some research on
            the company and writing down responses to commonly asked HR
            questions can be very helpful.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            In the final round, most of the discussion revolved around projects.
            I was asked to explain each project in detail and was asked
            questions on technologies used in the projects. For some projects, I
            was also asked if that project was a team project and what was my
            contribution to it. For this reason, if you are including a group
            project on your resume, make sure you have a sizable contribution
            you can speak about. Also, only include a project if you know it
            inside out since the interviewers may ask in depth questions.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />I used <a href="https://geeksforgeeks.com">GFG</a> for
            revising concepts related to DSA, OOP, etc. and{" "}
            <a href="https://hackerrank.com">HackerRank</a> to practice coding
            as it has a good topic wise and difficulty wise collection of
            questions. I also completed some 30-day challenges on{" "}
            <a href="https://leetcode.com">LeetCode</a> to practice questions
            with progressive difficulty levels. The GFG puzzles section is also
            a great resource that was very helpful.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            What I realized as a part of my preparation was that as the time for
            companies arriving comes closer, it is more important to go back and
            revise already solved questions than to solve more new questions.
            Reviewing and solving questions asked by a particular company from
            GFG question archives a few days before the aptitude test of that
            company can also be helpful. It is also very important to not loose
            your cool if you can’t think of a solution immediately when solving
            the coding questions or even in the interview. Passing even a few
            test cases is much better than panicking and losing out on the
            entire question.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            From my interview experience, I felt that Citi places a lot of
            emphasis on the last round. I was asked a lot of HR questions and
            due to the fact that this round also had technical questions again,
            it seemed like the “make it or break it” round of the entire
            interview process. So, my suggestion would be to not underestimate
            the HR round and take it as seriously as any other round. Also,
            knowing a few key numbers, like the number of technology centers
            Citi has globally or the number of countries Citi operates in can be
            helpful when answering HR related questions. They also cover this
            during their presentation, so paying attention during the
            presentation is important. The interviewer may also ask if you have
            some questions for them, so you should keep a few good questions
            ready.
            <br />
          </p>

          <p>
            <i>
              The entire internship period is competitive and can get a little
              stressful, especially if you face instances where you aren’t
              selected even after clearing a few rounds. It is important to
              learn from these and not lose hope since there will be many more
              good companies coming to campus. If you are well prepared, you
              will get a good internship eventually.
            </i>
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:hrishikeshja18.comp@coep.ac.in">
              hrishikeshja18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default hrishikesh_athalye;

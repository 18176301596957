import React from "react";

const chinmay_sheth = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Chinmay Milind Sheth
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>CarrotSoft Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />
                    <p>
                        <b>1. What was the Job Role?</b>
                        <br />
                        <p>
                            Android Developer Intern, The Company’s product is an Android Application which is a stock market research application for retail investors. My job was to implement various market Indicators using MP Android Chart Library in Kotlin and XML for Frontend Development.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The entire process was completed in just one day for all the students, each student having an average interview time of 15 minutes. The interview process for all the students started at around 11 am, and was completed approximately at 8 pm. There were a total of 62-63 students for the interview.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            There was no aptitude round for this company.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There was only one round of Interview directly, which was a Technical + HR round by the owner of the company itself. The interview began with a brief introduction about myself and my educational background. Without wasting much time, he asked technical questions regarding Android Application Development, Kotlin Language, etc. I was not asked, but make sure to prepare for OOPS, DBMS and Web Development Fundamentals. He then moved toward some typical HR questions such as “Why would you like to join this company?”, “What would you bring us to the table?”, “Have you developed any android application before?”, etc. The whole interview took around 15 minutes.
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Since there was only one final round, out of 62 students, 5 students (including me) were selected for the internship.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Since questions were asked related to Android Application only, I would suggest to have a brief overview of android fundamentals on Google’s Official Website for Android Development Using Kotlin, Introduction to kotlin programming language on Android Developers Website.
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            No coding round was conducted.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            As i mentioned about my course completion on “Android Using Kotlin”, he asked me if i have developed any android projects before during my course duration. He then asked to show me those applications if downloaded on the phone too. The interviewer knows mostly about all the fields, so do not bluff while answering the questions, be confident and answer only if you are confident about it.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Since this is a startup, don’t have much extra expectations from the company. We were working on weekends and getting leaves on Monday and Friday, which may seem odd a little bit. But the work here is good, you get to know about lots of stuff, and not only technical knowledge, but about stock market and financial knowledge too. The Company does not offer PPO to the students. So prepare for the Placement Cycle alongside. Maintain a work-life balance.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Don’t get upset if you do not get selected in the company, study harder and try to achieve another company! Work honestly, but don’t get pressurized if you are not up to mark. Anticipate common interview questions and practice your responses.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:chinmayms22.comp@coeptech.ac.in">
                            chinmayms22.comp@coeptech.ac.in
                        </a>
                        <br />

                        LinkedIn : <a href="https://www.linkedin.com/in/chinmay-sheth-6597201a1/">Chinmay Sheth</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default chinmay_sheth;

import React, { Component } from "react";
import "../pages/css/teams.css";
import { Grid } from "@material-ui/core";
import team24 from "../assets/js/teams/team2024";
import SectionHeading from "./SectionHeading";
import { LinkedIn, GitHub, Mail, Person } from "@material-ui/icons";
import { LazyLoadImage } from "react-lazy-load-image-component";

class Team24 extends Component {
  render() {
    return (
      <div className="mb-5">
        <SectionHeading title="Meet the Team" />

        <div className="container-fluid">
          <section className="speakers-section">
            <Grid container justify="center" spacing={8}>
              {team24[0].post.map((item, key) => (
                <Grid item key={`team-20-first-${key}`}>
                  <div
                    className="speaker-block wow fadeInUp"
                    style={{ height: "27rem", width: "15rem" }}
                  >
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <LazyLoadImage
                            effect="blur"
                            src={item.image}
                            alt=""
                            key={key}
                            style={{ height: "15rem", width: "15rem" }}
                            // height="15rem"
                            // width="12rem"
                          />
                          {/* <img
                            style={{ height: "15rem", width: "15rem" }}
                            src={item.image}
                            alt=""
                          /> */}
                          <div className="social-links">
                            <ul>
                              <li>
                                <a
                                  href={item.mail}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Mail style={{ fontSize: "2rem" }} />
                                </a>
                              </li>
                              <li>
                                <a
                                  href={item.about}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Person style={{ fontSize: "2rem" }} />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </figure>
                      </div>
                      <div className="caption-box text-center">
                        <h4 className="name">
                          {item.initial} {item.name} {item.surname}
                        </h4>
                        <span className="designation">{item.desc}</span>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
            <SectionHeading title="Core Team for the Academic Year 2024-25" />
            <Grid container justify="center" spacing={8}>
              {team24[1].post.map((item, key) => (
                <Grid key={`team-20-second-${key}`}>
                  <div
                    className="speaker-block wow fadeInUp"
                    style={{
                      height: "23rem",
                      width: "15rem",
                      margin: "2rem",
                      marginBottom: "3rem",
                    }}
                  >
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <LazyLoadImage
                            effect="blur"
                            src={item.image}
                            alt=""
                            key={key}
                            style={{ height: "15rem", width: "15rem", objectFit: "cover" , objectPosition: "center top"}}
                            // height="15rem"
                            // width="15rem"
                          />
                          {/* <img src={item.image} alt="" /> */}
                          <div className="social-links">
                            <ul>
                              <li>
                                <a
                                  href={item.linkedin}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <LinkedIn style={{ fontSize: "2rem" }} />
                                </a>
                              </li>
                              <li>
                                <a
                                  href={item.github}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <GitHub style={{ fontSize: "2rem" }} />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </figure>
                      </div>
                      <div className="caption-box text-center">
                        <h4 className="name">
                          {item.initial} {item.name} {item.surname}
                        </h4>
                        <span className="designation">{item.position}</span>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
            <Grid container justify="center" spacing={8}>
              {team24[2].post.map((item, key) => (
                <Grid
                  container
                  item
                  key={`team-20-third-${key}`}
                  justify="center"
                  lg={4}
                >
                  <div
                    className="speaker-block wow fadeInUp"
                    style={{ height: "22rem", width: "16rem"}}
                  >
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <LazyLoadImage
                            effect="blur"
                            src={item.image}
                            alt=""
                            key={key}
                            style={{ height: "15rem", width: "15rem", objectFit: "cover", objectPosition: "center top" }}
                            // height="15rem"
                            // width="15rem"
                          />
                          {/* <img src={item.image} alt="" /> */}
                          <div className="social-links">
                            <ul>
                              <li>
                                <a
                                  href={item.linkedin}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <LinkedIn style={{ fontSize: "2rem" }} />
                                </a>
                              </li>
                              <li>
                                <a
                                  href={item.github}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <GitHub style={{ fontSize: "2rem" }} />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </figure>
                      </div>
                      <div className="caption-box text-center">
                        <h4 className="name">
                          {item.initial} {item.name} {item.surname}
                        </h4>
                        <span className="designation">{item.position}</span>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </section>
        </div>
      </div>
    );
  }
}

export default Team24;

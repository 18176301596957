import React from "react";

const sanika_kulkarni = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Sanika Dilip Kulkarni
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The interview process consisted of three rounds: One technical, One Managerial followed by an HR round.
                            <br />
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude test was conducted on the AMCAT platform on September 6th. Among the 2 domains available for Interns, I opted for ICG Tech.
                            <br />The assessment consisted of 4 sections:
                            <br />
                        </p>
                        <p>
                            <ol>
                                <li>
                                    Verbal Reasoning: Easy<br />
                                    This section was all about understanding written stuff, like passages, reordering sentences, fixing grammar, etc.

                                </li>
                                <li>Arithmetical Reasoning: Medium <br />
                                    Topics covered in this section included Probability, Ratio Proportion, Permutation and Combinations, and fundamental arithmetic concepts.
                                </li>
                                <li>Logical Reasoning: Medium <br />
                                    This was standard aptitude stuff – questions about sequences, analogies, directions, and some code-breaking
                                </li>
                                <li>Coding Questions: Easy<br />
                                    The questions might sound confusing at that moment, but are very easy. There were two questions:<br />
                                    (i) A new dictionary application for mobile users is created which will auto-learn the user’s text and detect the word that appears more than once. The app then stores these words to give suggestions in future. Identify those words.
                                    In simple words, Given a string of space separated words we had to find words that appear more than once and arrange them in a lexicographical order.
                                    <br />
                                    (ii) A company wishes to bucketize their item ID’s. The bucket for item ID is chosen on the basis of the maximum value of digit in the item ID. Write an algorithm to find the bucket to which the item ID will be assigned.
                                    Basically, Given a long integer we had to find the largest digit amongst all its digits.

                                </li>
                            </ol></p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 3 rounds:
                        <br />
                        <ol>
                            <li>Technical: The round started with my introduction. They inquired about the projects listed on my resume, with a focus on the technologies used and reasons for their selection. Then I was asked to write pseudocode for LinkedLists, AVL tree rotations and patterns. This round focused on the deep understanding of data structures. Additionally, they inquired about my involvement in the technical club and the insights gained from it.
                            </li>
                            <li>Managerial: It was a short conversational round where they tried to analyse my way of thinking. They asked me about the position of responsibilities that I have handled, The different clubs and my roles in it. It went on for about 10 mins.
                            </li>
                            <li>HR: This one went on for about 15-20 minutes. I started with an introduction of myself. Then, they asked me about my favourite project and why I liked it. There were questions about why I chose engineering, my pick of COEP over other colleges, and even some family and hobby talk. There also were usual HR questions like goals, why Citi, and why ICG Tech.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Following the aptitude test, 54 students made it through (15 for PBWMT and 39 for ICG). 20 students were selected after the initial Technical Round. After the second round, 14 students were chosen for the HR Round, and ultimately, 13 of them were offered internships, with 10 from the ICG Tech and 3 from PBWMT.                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>Aptitude: Indiabix</li>
                            <li>Puzzles: <a href="https://www.geeksforgeeks.org/puzzles/">GFG</a></li>
                            <li>DSA: <a href="https://takeuforward.org/strivers-a2z-dsa-course/strivers-a2z-dsa-course-sheet-2/">Striver’s A2Z DSA course</a> and <a href="https://takeuforward.org/interviews/strivers-sde-sheet-top-coding-interview-problems/">Striver’s SDE Sheet</a></li>
                            <li>Coding Practice: Leetcode</li>
                            <li>Core Concepts: GFG’s Last Minute Notes For <a href="https://www.geeksforgeeks.org/last-minute-notes-dbms/">DBMS</a>, <a href="https://www.geeksforgeeks.org/last-minute-notes-computer-network/">CN</a> and <a href="https://www.geeksforgeeks.org/last-minute-notes-operating-systems/">OS</a></li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Follow a structured DSA course that guides you step by step. Focus on one topic at a time, practice until you feel sure about it, then proceed to the next one. This approach ensures you learn and practice every topic thoroughly. Set daily goals and make solving questions a regular habit. Being consistent will greatly assist you.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            In the technical round, there were numerous questions regarding my projects, and the HR also inquired about them. I discussed my involvement in clubs during all three rounds. Furthermore, the HR asked about my extracurricular activities.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Ensure that you thoroughly research the company and attend the pre-placement talk before your interview rounds. Additionally, have a deep understanding of the tech stack used and the finer details of the projects you include on your resume. It's important to answer confidently, even when the interviewer asks rhetorical questions, it's a chance to show your capabilities and turn the conversation into a positive direction.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Be Confident and optimistic. Even if you encounter a question you don't know the answer to, you can still convince the interviewer.
                            The internship process can be tough and may not always feel motivating. Limited spots create pressure. But, it's important to keep trying, learn, and grow from the experience. You might not succeed in the first few tries, but more opportunities will come. Stay confident and honest in interviews, give your best, take care of your health during stressful times, and keep trying your hardest.
                            <br />All the Best!
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:kulkarnisd22.comp@coeptech.ac.in">
                            kulkarnisd22.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default sanika_kulkarni;

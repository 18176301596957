import React from "react";

const saurabh_jog = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Saurabh Jog</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}> BNY Mellon</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>There were three rounds:</p>
            <ol>
              <li>Online coding round</li>
              <li>Technical interview</li>
              <li>HR interview</li>
            </ol>
            <b>Structure of Aptitude Round :</b>
            <br />
            The first round was purely a coding round. There were no aptitude
            questions. The test was on HackerRank and it had four coding
            questions - one easy, two medium and one with hard difficulty level.
            Duration of the test was 90 minutes.
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <p>There were two rounds: Technical and HR</p>
            <ol>
              <li>
                The first round was a technical round which started with the
                questions based on my projects. The interviewer asked me
                questions about all aspects of my project to check whether I had
                a deep understanding of the concepts which were used in the
                project. After that she asked me questions based on OOP which
                were mainly focused on inheritance.Then she wrote a code on the
                paper and asked me to predict the output which was again about
                inheritance. The round ended with a coding question:
                <br />
                <br />A linked list was given where each node of the linked list
                represented a digit of the number. The task was to add two such
                linked lists and store the result into a third list and return
                that list.
                <br />I wrote the code on paper and then explained it to her.
                This round lasted for about 40 min.
              </li>
              <li>
                The second round was an HR round where I was asked questions
                like:
                <ol>
                  <li>
                    What do you think are the benefits of online education?
                  </li>
                  <li>Which are your favourite subjects?</li>
                  <li>
                    Tell me about the toughest situation you have ever faced?
                  </li>
                  <li>Have you done any course apart from academics?</li>
                  <li>
                    Which coding platforms do you use and what are your ratings?
                  </li>
                </ol>
                <br />
                This round lasted for about 30 min.
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              <b>18</b> students were shortlisted after the online test. Among
              them <b>14</b>
              were shortlisted after the technical round and{" "}
              <b>
                all of them were selected for the internship after the HR
                interview.
              </b>
            </p>
          </p>

          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              For coding questions I used GeeksForGeeks, LeetCode and CodeChef.
              For aptitude questions I solved problems on indiabix. I also
              watched YouTube videos for DSA.
            </p>
          </p>
          <p>
            <strong>5. Some tips to crack coding questions :</strong>
            <p>
              The most important thing I would say is you need to practice a lot
              to crack the coding questions. You have to practice regularly
              because you cannot crack the test after studying one day before
              the exam. During the test, even if you are not able to pass all
              the testcases for a particular question, try to pass some of them
              to get partial marks and move to the next question.
            </p>
          </p>
          <p>
            <strong>
              6. How many questions were asked about projects, clubs and
              extracurricular activities in your resume :
            </strong>
            <p>
              Many questions were asked about projects in the technical
              interview. No questions were asked on clubs and extracurricular
              activities.
            </p>
          </p>
          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company :
            </strong>
            <p>
              Make sure that you mention only those projects and skills in the
              resume about which you have complete knowledge because that is
              what the interviewer expects from you. In the technical interview,
              confidently answer the question even if you are not 100% sure
              about the answer. If you don’t know the answer at all, tell that
              honestly without making random guesses.
            </p>
          </p>
          <p>
            <strong>8. Final Tip for Juniors :</strong>
            <p>
            Even if you are not shortlisted after a particular round, don’t get frustrated.
Everyone faces rejections in this process of interviews. Get over the failure and
try to give your best in the next attempt.
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:jogsb20.comp@coep.ac.in">
            jogsb20.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default saurabh_jog;

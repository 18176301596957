import React from "react";

const anushka_naik = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Anushka Vijaykumar Naik
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>CITI</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>The entire process was divided into 3 rounds:</p>
            <ol>
              <li>Aptitude test</li>
              <li>Technical Interview Round</li>
              <li>HR Round</li>
            </ol>
            <b>Structure of Aptitude Round :</b>
            <br />
            <ol>
              <li>
                Technical MCQs (25 questions, 22 minutes)-
                <br />
                <b>Easy Difficulty</b>
                <br />
                <b>Topics covered:</b>C Basics, OOPs, DSA, OS, DBMS
              </li>
              <li>
                Quantitative Ability (16 questions, 16 minutes)
                <br />
                <b>Difficulty: Medium</b>
                <br />
                <b>Topics covered:</b>Combinatorics, Profit and Loss, Train
                problems, etc
                <p>
                  The questions asked were easy, however, the time provided was
                  less, so each problem needed to be solved in around one
                  minute.
                </p>
              </li>
              <li>
                Logical Reasoning (14 questions, 14 minutes)
                <br />
                <b>Difficulty: Easy</b>
                <br />
                <b>Topics covered: </b>Logical Deduction, Letter and Symbol
                Series, etc
              </li>
              <li>
                Verbal Reasoning (22 questions, 18 minutes)-
                <br />
                <b>Difficulty: Easy</b>
                <br />
                <b>Topics covered: </b>Vocabulary, Grammar, and Comprehension
              </li>
              <li>
                Coding Questions (2 questions, 60 minutes)
                <br />
                <b>Difficulty: Easy/Medium</b>
              </li>
            </ol>
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <p>
              There were three rounds of interviews after the aptitude test. Two
              of these were technical / managerial while the last round was an
              HR round. The first technical round started off with introductions
              and immediately we deep dived into the tech stack I was familiar
              with, they asked me several questions on those and then we went
              through my projects and I was asked several questions on them as
              well.
            </p>
            <p>
              The second technical round was more code based, wherein, he asked
              me several logical reasoning questions as well as questions on
              OOPs concepts, DBMS concepts, etc. It was a rapid fire sort of
              round wherein, I was asked the logic behind certain programming
              questions and several short concept based questions.
            </p>
            <p>
              The third and final round was an HR+ managerial round wherein I
              spoke to one of the interviewers candidly about why I wanted to
              work here, what motivated me, etc. I also asked them several
              questions about their work environment and told them about why I
              was inclined to work at citi, etc
            </p>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              After the aptitude test, around <b>45</b> students were
              shortlisted, out of which most passed the first round. Post the
              technical round, around <b>22</b> students were shortlisted and
              surprisingly, while 22 went through till the last round,ie, HR
              round, they ended up offering an internship to about <b>8</b> of
              them.
            </p>
          </p>
          <p>
            <b>
              4. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <p>
              They asked me questions mainly related to my tech projects and
              extracurriculars were only briefly discussed initially during
              introduction. It only acted as a method for them to understand a
              person is an allrounder, while the interview completely boiled
              down to communication skills and technical knowledge.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              I used leetcode to practice programming questions. I personally
              prefer to code in python due to the ease of inbuilt functions
              however, some companies provided only rudimentary versions of
              python and C. Neetcode is a good resource to get an idea of how to
              approach programming questions. I used gfg for understanding
              several dbms, os, cn concepts as these were yet not taught to us,
              as well as revise oops concepts and went through their interview
              question banks. I also practiced logical reasoning questions from
              gfg and indiabix.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Consistency and calmness is key, try understanding the approach
              and data structures used instead of memorising code and you will
              be able to adjust to any situation.
            </p>
          </p>
          <p>
            <strong>7. Final Tip for Juniors :</strong>
            <p>Stay calm and try your best. All the best!</p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:anushkavn20.comp@coep.ac.in">
            anushkavn20.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default anushka_naik;

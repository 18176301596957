import React from "react";

const aman_patil = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
          Aman Jitendra Patil
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Deutsche Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
            	There were four rounds:
            <li>
            	First round was an aptitude and coding round for 90 min 

            </li>
            <li>
            And three interview rounds 1 technical, 1 pro-fit round and 1 HR round.


            </li>
            </p>
            
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
            Aptitude test was divided into two sections.
            <ol>
                <li>
                    <p>
                    <b>Technical section: </b> There were 10 questions on DSA, OOPS, DBMS, Networking  and OS. 
                    </p>
                </li>
                <li>
                    <p>
                   <b>Coding section:</b> 2 coding question were given to solve.
 
                    </p>
                </li>
               
            </ol>
            	Navigations was on so we can solve any question first. 
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>There were 3 interview rounds: 1 Technical + 1 pro-fit + 1 HR</p>
            <ol>
              <li>
                <p>
                  <strong>Technical Round (last for 45 minutes)</strong>
                </p>
                <ul style={{listStyleType: "disc"}}>
                <li>This round was mainly focused on DSA concepts. Interviewer asked me to introduce myself. Then he asks questions on graph, explain difference between DFS and BFS.</li>
<li>He gave me a question on array and told me to tell approach.</li>
<li>He asked me to optimize the approach. Then he asked me some questions regarding quick sort and its worst- and best-case time complexity.</li>
<li>Then he asked me to optimize again. Then he told me to code it. Then he asked some 7-8 questions, and I must answer them in 10 seconds. </li>
</ul>
</li>
                
              
              <br />
              <li>
                <p>
                  <strong>Pro-fit Round (Vice President Round)</strong>
                </p>
                <ul style={{listStyleType: "disc"}}>
                <li>This round was at 2:15 pm and for 30 minutes.</li>
<li>I was asked to introduce myself. In my introduction I talked about My technical club and then interviewer asked me about my own contribution to the club and asked me to explain which project I did in club. I explained my project and what difficulty and challenges I faced and how I solved them. 
Then he asked me more general question regarding teamwork.</li>
<li>He then asked me what I think about online mode of learning and physical mode. Why do you want to enter the finance sector?</li>
<li>Just be confident about what you answer, think once before answer.</li>
<li>This round is basically for testing that you adopt to work environment easily or not and you can bear the workload or not.   </li>
</ul>
              </li>
              <br />
              <li>
                <p>
                  <strong>HR Round</strong>
                </p>
                
             
<ul>
                <li>This round last for 20 minutes.</li>
<li>It started with my introduction. It was more like question-answer than conversation.</li>
<li> I don’t remember all the questions, some of them were: </li>
</ul>
<ul style={{listStyle:"disc", marginLeft: "2rem"}}>
<li>How was your day till now?</li>
<li>Various scenarios including teamwork.</li>
<li>Why DB?</li>
<li>What challenges I faced in my life and how I deal with it.</li>
<li>Asked about my family background.</li>
<li>Any future Plans.</li>
</ul>

              </li>
              <br />
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <br />
            <p>
         <li>Interviewer in Profit and HR round asked me about difficulty and challenges I faced in my project and how I solved them, what approach I used to optimize my code.</li>
         <li>Regarding resume, there were no questions. </li>
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            
            <p>
            <li><b>For aptitude:</b> R.S Agrawal is best. Do only selected topics.</li>
            <li><b>For DSA: </b>GeeksforGeeks is great resource. </li>
            <li><b>For Coding:</b>There are many platforms like GeeksforGeeks, leetcode, Hacker rank. I preferred GeeksforGeeks ...</li>
            <li><b>For CS core:</b> Before going for interview, revise question from interview bit for OS, CN and OOPS .</li>
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
             <li>Practice is key to crack any coding question. Practice many questions on DSA, start problem solving. Try to learn approaches, because there are many ways you can solve that problem, then just try to find which is most efficient according to time and space complexity.</li>
             <li>Regarding coding round, don’t go for optimized solution try brute force solution first, then try to optimize the code.</li>
             <li>Same for interview round, if you are asked to tell approach to DSA question then tell brute force solution first and then optimized your approach.</li>
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            <li>From my experience, just focus on DSA first. Study all DSA concepts, after that focus on OOPS, OS, CN and DBMS</li>
            <li>They don’t expect any fancy project from you.</li>
          </p>
          <br />
          <p>
            <i>
            This process is very stressful. So don’t give up until it's done. Everyone have their own success story, mine will not be the same as yours ,  but yes it will definitely help you to success . Remember, “MIRACLES DO HAPPEN KEEP FAITH AND GO ON “. 
            
            </i>
          </p>
         
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:patilaj19.comp@coep.ac.in">
             patilaj19.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default aman_patil;

import React from "react";

const shubham_gandhi = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Shubham Gandhi
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            JPMorgan Chase & Co.
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />
          <p>
            <b>1. What was the Application Procedure for off-campus internship?</b>
            <br />
            <p>
              The application was sent to every student in SY and TY through the
              placement cell in Spring. Regularly check your outlook for such
              opportunities.
            </p>
          </p>
          <p>
            <b>2. What was the structure of the entire interview process?</b>
            <br />
            <p>
              Every participant was put through multiple rounds of interviews.
              Technical skills were assessed by team mentors during the
              hackathon and the initial coding test.
            </p>
          </p>
          <p >
            <b>3.What question were asked in the aptitude round :</b>
            <br />
            <p>
              There was no such aptitude round.During the hackathon, 2
              interviews were conducted with the mentors, they asked questions
              based on real world problems faced by the company
            </p>
          </p>

          <p>
            <b>
              4. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <br />
            <p>The overall process consisted of 3 rounds:</p>
            <ol>
              <li><b>Technical Coding Round: </b></li>
              <p >
                We were given 75mins to solve two coding questions on Hackerrank,
                the test was proctored and focused on graphs and Dynamic
                programming
              </p>
              <li><b>HireVue interview:</b></li>
              <p>
                HireVue interviews are video-based.We were given two attempts for
                each of the two questions.
              </p>
              <li><b>"Code for Good" Hackathon:</b></li>
              <p>
                Code For Good is an annual Hackathon conducted by JP Morgan Chase
                for students in their second and third years of Engineering. It is
                aimed at providing technology-based solutions to real-world
                problems faced by NGOs, NPOs.
                <br />
                Over 900 students across India are randomly formed into groups of
                6-7 and made to tackle a non profits challenge .Web development
                projects using MERN stack and Django are very common. Try to be
                familiar with these tech stacks before the hackathon.
              </p>
            </ol>
          </p>

          <p>
            <b>5. How many students were shortlisted after each round?</b>
            <p>
              <ol>
                <li>
                  Initially around <b>7</b> students were shortlisted for the
                  HireVue interview
                </li>
                <li>
                  Out of them <b>3</b> were selected to take part in the
                  hackathon
                </li>
                <li>
                  About 2-3 months later the results were announced and <b>2 </b>
                  students were offered the 2023 summer internship.
                </li>
              </ol>
            </p>
          </p>
          <p>
            <b>
              6. How many questions were asked about projects, clubs and
              extracurricular activities in your resume ?
            </b>
            <br />
            <p>
              I did not have a technical interview as such, we were asked to
              upload our resumes during the application process.All technical
              skills were assessed by our team mentors during the hackathon.
            </p>
          </p>

          <p>
            <b>
              7. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              I personally referred to a book called Cracking the coding
              interview by Gayle Laakmann McDowell and Leetcode. The best
              resource I used was <a href="https://neetcode.io/">neetcode.io </a>
              which is a set of 150 problems which strongly strengthen your data
              structures and problem solving skills. I also used Striver's 150
              list as a test to find my strengths and weaknesses, although I
              could not complete all of them it did boost my morale.
            </p>
          </p>

          <p>
            <b>8. Some tips to crack the coding questions.</b> <br />
            <p>
              Become a good problem solver instead of by hearting answers.Break
              the questions into smaller sub problems you have solved before and
              preferably stick to one language.
            </p>
          </p>
          <p>
            <b>
              9. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              Your result in the hackathon is irrelevant, just focus on the task
              given to you.Communication skills are very important and try to
              sell yourself as much as possible during the 24hrs.
            </p>
          </p>
          <p>
            <strong>10. Final Tip for Juniors :</strong>
            <p>
              Focus on developing yourself and be consistent. Shake free of
              internalized expectations. Appreciate your accomplishments and
              experiences because life has no correct order. All the Best!
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID : <a href="mailto: shubhammg20.comp@coep.ac.in">
              shubhammg20.comp@coep.ac.in
            </a>
            <br />
            LinkedIn Handle : <a href="https://www.linkedin.com/in/shubham-gandhi-349627169/">Shubham Gandhi</a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default shubham_gandhi;

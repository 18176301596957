import React from "react";

const sayali_mate = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Sayali Mate
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>BNY Mellon</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              The interview process was divided into three rounds –
              <ol>
                <li>
                  Aptitude Test
                </li>
                <li>
                  Technical Interview
                </li>
                <li>
                  Technical + HR Interview
                </li>
              </ol >
            </p>

          </p>

          <p>
            <b>2. What was the structure of the aptitude test?</b>
            <br />
            <p>
              The test was timed for 90 minutes. It was conducted on HackerRank platform and had 4 coding questions(1 Easy, 2 Medium and 1 Hard)
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p> There were two rounds,Technical and Technical+HR
            </p>
            <ol>
              <li>
                <p>
                  <strong>Technical round:
                  </strong>
                </p>
                <p style={{ paddingLeft: "40px" }}>
                  The interviewer first asked me to introduce myself.Then he asked which subjects I was most comfortable with.I was asked the following questions: Write a pseudo code to determine whether given number is a palindrome or not. Given an array of size n and numbers from 1 to n-1,find the missing number. Explain binary search.
                  <br />What is hashing? <br />
                  How are exceptions handled in C++? <br />
                  He also asked me to give a brief overview of my projects. <br />
                  Then he asked 2 "find the output" questions ,followed by a sql query.
                </p>
              </li>
              <li>
                <p>
                  <strong>Technical + HR Round :</strong>
                  <p style={{ paddingLeft: "40px" }}>
                    The interviewer started off by asking me the subjects I had in my second year. Then he asked me to tell about a time where I had displayed leadership skills.Then he asked what all I knew about BNY Mellon,which mode of learning do you find better...online or offline and why,which was the most thrilling thing in your life,followed by some general HR questions. <br /> Basic questions of oops were also asked. <br /> Finally he asked whether I had any questions for him.
                  </p>
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            300+ students appeared for the aptitude test out of which 18 students were shortlisted for the technical interview. Finally, 14 students were selected.
          </p>

          <p>
            <b>
              5. Some good resources you used in your internship prep
            </b>
            <br />
            For coding I practiced questions on Geeks for geeks,Hackerrank.For aptitude I referred Indiabix.For technical I referred Geeks for Geeks.
          </p>

          <p>
            <b>
              6. Some tips to crack coding questions
            </b>
            <br />

            <p>
            Practice is the key to crack the coding questions.Try to solve as many DSA questions as possible.Take your own time to understand the question and think upon it.First try to solve the question using brute force and then optimize it.</p>
          </p>

          <p>
            <b>7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume</b> <br />
            <p>
            Talking about projects,they just asked me to give an overview of the projects. I was asked about my role in CSI in the HR round.</p>
          </p>
          <p>
            <b>8. Is there something specific that you would want to tell people who try for the same company :</b> <br />
            <p>
            Be thorough with DSA and OOPS concepts.Listen to the pre-placement talk carefully and note down some points regarding the company which will be helpful in the HR round.</p>
          </p>

          <p>
            <strong>
              9. Final Tip for Juniors?
            </strong>
            <p>
            Just be calm and composed and give your best
            </p>
          </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID : <a href="mailto:matesa20.comp@coep.ac.in">
              matesa20.comp@coep.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default sayali_mate;

import React from "react";

const vaishnavi_badgujar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Vaishnavi Badgujar

                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Deutsche Bank</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of selection process?</b>
                        <br />
                        <p>
                            There were four rounds:
                            <br />
                            <ol>
                                <li>
                                    Aptitude round (online)
                                    <br />
                                    Aptitude round had 2 sections:
                                    <br />
                                    <ol>
                                        <li>
                                            MCQs : It had 10 Questions based on Computer Networks, DBMS , Operating Systems, OOPS and code snippets in JAVA to predict output .
                                        </li>
                                        <li>
                                            Coding Round included 2 coding questions ranging from easy to medium. Duration of Aptitude round was 90 min
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Three interview rounds which included
                                    <ol>
                                        <li>
                                            Technical round
                                        </li>
                                        <li>
                                            Professional Fitness Round
                                        </li>
                                        <li>
                                            HR Round
                                        </li>
                                    </ol>

                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There were 3 rounds other than Aptitude round.
                            <ol>
                                <li>
                                    Technical Round (50 minutes):
                                    Initially I was asked questions based on my resume. A lot of questions were asked based on the projects such as why you chose this idea, what tech tasks were used,I was asked to explain the data structures and their algorithm and code of some.
                                    Many OOPS and DBMS questions were asked, in case of OOPS I was asked few concepts in cpp(as it my was primary language) and how they are similar or different in JAVA.
                                    Many questions on clubs and responsibilities were also asked.
                                </li>
                                <li>
                                    Professional fitness round (15 - 20 minutes):
                                    This round was quite chill. I was again asked about the clubs and the responsibilities that I performed there.
                                    Some general questions about DB such what is the logo, what does it signify were asked.
                                </li>
                                <li>
                                    HR round (20-25 minutes):
                                    I was asked to introduce myself, I was again asked about the clubs and responsibilities, I was asked to explain my projects in brief, which city do I like hometown or Pune. General HR questions were asked
                                </li>
                            </ol>
                        </p>
                    </p>
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        10 students were selected for Technical round of which 4-5 were selected for pro-fit. 3 were selected for HR round. Finally, 2 were given internship offer.

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                For practicing DSA : strivers sde sheet,leetcode
                            </li>
                            <li>
                                Core subjects : gfg
                            </li>
                            <li>
                                Additionally for dbms concepts Love Babbar playlist is good
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            At first try applying brute force and then try to optimize it. The main focus must be to pass as much test cases as possible.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            A lot of questions were asked about project. I was asked to explain the concepts that were used in the project,why that particular topic was chosen,a brief about project etc. So be thorough about the project you have done.
                            To specify many questions were asked about clubs and extracurricular about  the club,role played, positions.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Focus more on DSA, OOPs, DBMS, and if you are not JAVA programmer then just try to cover some very important concepts in JAVA such as diamond problem etc.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    If you are interested in particular company reach out to your seniors who are placed in the company, they can help you a lot to achieve goal.
                                </li>
                                <li>
                                    Internship cycle is quite hectic, so make sure you don’t stress out.
                                </li>
                                <li>
                                    Be patient and believe yourself and remember there’s always something great waiting for you out there and you can nail it ;)
                                </li>
                            </ol>
                            <br />
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:badgujarvr21.comp@coeptech.ac.in">
                            badgujarvr21.comp@coeptech.ac.in
                        </a>
                    </div>
                </div>
            </section >
        </div >
    );
};

export default vaishnavi_badgujar;

import React from "react";

const harshal_patil = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Harshal Patil
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Garrett Motion
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <li style={{ marginLeft: "2em" }}>Aptitude Test of 1 hour</li>
            <li style={{ marginLeft: "2em" }}>
              Two interview rounds: A Technical and a HR
            </li>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude round consisted of 45 MCQ’s . The duration of the test
            was 1 hour.
            <br />
            There were 2 sections – Logical Reasoning and Technical.
            <br />
            The logical reasoning part included quant, permutation and
            combination, probability, etc.
            <br />
            Technical had questions based on OOPs, Data Structures, basics of
            DBMS and also had predict the output type questions.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <li style={{ marginLeft: "2em" }}>Round 1 - Technical</li>
            <li style={{ marginLeft: "2em" }}>Round 2 - HR</li>
          </p>

          <p>
            <b>4. What sort of questions were asked in the interviews?</b>{" "}
            <br />
            <i>Round 1 - Technical</i>
            <br />
            It started with my introduction. Then the interviewer started going
            through my resume and asked me about my last Summer Internship and
            some of my projects. Rather than focusing more on the code part of
            the project, I would suggest you explain more about it’s working and
            features, tell them why you chose the project and how you could
            improve it or the future scope of the project.
            <br />
            I was then asked about some OOPs principles, data structures and
            python related questions.
            <br />
            <br />
            <i>Round 2 - HR</i>
            <br />
            This time again, it started with my introduction, project related
            questions, previous internship and about my co-curricular
            activities. Then, I was asked if I ever had an experience of leading
            a group project and if yes, “What were the problems and challenges I
            faced? How did I overcome them? Anything I feel I could have done
            better?” were some of the questions asked. Towards the end, the HR
            asked me why I was interested in joining Garrett Motion Technologies
            and why I think I am a perfect match for them.
          </p>

          <p>
            <b>5. How many questions were asked based on the projects done?</b>{" "}
            <br />
            Both of my interviews commenced by questions based on my resume. Be
            prepared with the ins and outs of projects you mentioned in the
            resume so that you should be fully prepared to answer anything
            they’d ask.
          </p>

          <p>
            <b>6. How many questions were based on the resume?</b> <br />
            Both of my interviews commenced by questions based on my resume.
          </p>

          <p>
            <b>
              7. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />I used <a href="http://indiabix.com/">Indiabix</a> to brush up
            my quant and logical reasoning.
            <br />
            <a href="https://www.geeksforgeeks.org/">GeeksforGeeks</a> is a
            great platform to revise data structures, algorithms and OOPs
            concepts.
            <br />
            For competitive coding practice I preferred{" "}
            <a href="https://www.codechef.com/">CodeChef</a> and{" "}
            <a href="https://www.hackerrank.com">HackerRank</a>.
          </p>

          <p>
            <b>8. Some tips to crack the coding questions.</b> <br />
            Practice is the key for solving coding questions.
            <br />
            During interviews, understand the question clearly and take your
            time answering it, just don’t rush into it.
          </p>

          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:patilhn18.comp@coep.ac.in">
              patilhn18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default harshal_patil;

import React from "react";

const pratyay_dhond = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Pratyay Dhond
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Arcesium</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '25
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
                Aptitude Test + 4 rounds
            </p>
            <ol>
              <li><b>Structure of Aptitude Round </b></li>
              <p>
                2 coding Questions + general aptitude questions
              </p>
              <li><b>How many rounds were there for the company?</b></li>
              <p>
                4 rounds (2 DSA + 1 OOP/Systems + 1 HR)
              </p>
            </ol>
          </p>
          <p>
          <b>2. Some good resources you used in your internship prep :</b>
            <br />
            <p>
                Striver’s 455 Sheet and Cracking the Coding Interview book
            </p>
            
          </p>
          <p>
            <b>3. Some tips to crack coding questions </b>
            <p>
              <ol>
                <li>
                    <p>
                    Keep trying and come up with a bruteforce solution. Your code execution is important but even if it doesn’t work, what matters is how you maintain your calm demeanour and try to figure out different approaches.
                    </p>
                </li>
                <li>
                    <p>
                    Think out loud and let the interviewer know what you are thinking, they are there to help and not scare you
                    </p>
                </li>
              </ol>
            </p>
          </p> 
          <p>
            <b>
              4. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <p>
            Personally not many, but be prepared on the topics and technologies you have on your resume
            </p>
          </p>

          <p>
            <b>
              5. Is there something specific that you would want to tell people who try for the same company 
            </b>
            <br />
            <p>
            DSA is very crucial. They can ask you from greedy, DP, graph, trees, etc.
            </p>
          </p> 
          
          <p>
            <strong>6. Final Tip for Juniors :</strong>
            <p>
            The complete internship process depends on your skills as well as luck! So don’t give up and keep trying.
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID:
            <a href="mailto: dhondpratyay@gmail.com">
            dhondpratyay@gmail.com
            </a>
            <br />
          </div>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            LinkedIn : <a href="https://www.linkedin.com/in/pratyaydhond?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">Pratyay Dhond</a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default pratyay_dhond;

import React from "react";

const soham_gupte = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Soham Gupte</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Hilti Technology Solutions
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '25
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              <ol>
                <li>Technical Round</li>
                <li>HR Round</li>
              </ol>
            </p>
          </p>

          <p>
            <b>2. Structure of Aptitude Round :</b>
            <br />6 sections - Coding, OOP, CN, OS, DBMS, Mathematical and Logical Reasoning.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              <ol>
                <li>Aptitude Round :
                <br />
                This round was conducted on an online platform (off campus).
                <br/>
                Total test time was 90 minutes, with 30 minutes dedicated to the mathematical and logical reasoning section, while one could freely navigate between the other sections.
                <br/>
                Coding question: K Most Recently Used (MRU) Apps - Given an integer K and an array arr[] of N integers which contains the ids of the opened apps in a system, the task is to print the contents of the array when the user using the system presses Alt + Tab exactly K number of times.
                </li>
                <li>Technical Round :
                    <br/>
                    Duration: 25-30 minutes.
                    <br/>
                    The interview started off with my introduction, in which I included my projects that I worked on during my academic tenure. The interviewer asked me brief questions about my project, such as “What data structures did I use in my project” and to explain the working and implementation of some of my projects.
                    <br/>The interview then proceeded towards an aptitude test. Questions based on number series/ sequences and days of the week (eg. if the day before yesterday was Saturday, what day is it 79 days later?). The focus of this part of the interview was mainly on observing how quick I could think and how well I could explain my approach to the interviewer.
                    <br/>The interviewer then asked me a few questions on DSA. Some of them are as follows:
                    <br/> a.	Explain the deletion of nodes in a Binary Search Tree.
                    <br/> b.	Given an array, what is the best sorting method that could be applied and what is its time complexity? Write pseudo code.
                    <br/>To conclude the DSA part of the interview, the interviewer asked me to explain my approach to the following question: Check whether two Strings are anagram of each other - I gave 2 approaches to solving this problem. 
                    <br/>   a.	Find all possible strings that could be formed by jumbling the original string and check whether the other string fits into that set (Brute force approach)
                    <br/>  b.	Create a list and store each alphabet of the original string and pop the alphabets as you encounter them in the other string that you have to check.
                    <br/>Lastly, a theoretical question on OOPs was asked to conclude the technical interview:
                    <br/>What is the difference between Abstraction and Interface?

                </li>
                <li>HR Interview :
                <br/>Duration: 20-25 minutes.
                <br/>The interview started off with my introduction, in which I talked about myself, my projects, my current co-curricular and extra-curricular activities in college and my hobbies. The interviewer also showed interest in learning my family culture and was keen on sharing their side as well.
                <br/>The interviewer asked me about my activities in the clubs and fests of the college. They also asked me to rate my proficiency in each programming language that I mentioned in my resume.
                <br/>A few questions were also asked like:
                <br/> a.	What are 3 things that would make you stay longer with a company?
                    <br/> b.	What are you most passionate about that helps you go through the day?
                    <br/>Overall, the HR interview tested my communication skills.

                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>4. How many students were shortlisted after each round ?</b>
            <p>
              <ol>
                <li>Technical Interview : 20 students.</li>
                <li>HR Interview : 12 students.</li>
                <li>Final Offer : 4 students.</li>
              </ol>
            </p>
          </p>

          <p>
            <b>5. Some good resources you used in your internship prep</b>
            <p>
              1. GeeksforGeeks.
              <br/>2. Leetcode.
              <br/>3. Striver's SDE sheet - Top Interview Questions.
              <br/>4. Apna College Notes - for OOPs, OS, CN and DBMS (can also find their playlists on YouTube).
              <br/>5. Indiabix - Aptitude.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack coding questions</b>
            <br />

            <p>
            Practise at least 1-2 Leetcode questions a day (1 Medium level question at least)
              <br />
              When you solve a problem, compare your solution with the other available solutions and analyse how you could make your solution more efficient.
              <br />
              If you want to focus on a specific area of problem solving (for eg. problems using recursion, etc.), I suggest using the Striver’s SDE Sheet.
            </p>
          </p>

          <p>
            <b>
              7. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>{" "}
            <br />
            <p>
            In the technical interview, I had included my projects as part of the introduction so along with a brief description about my projects, the interviewer asked me questions like what programming languages and tools were used to implement the project.
              <br />
            The HR interviewer asked me for a brief description of some of my projects as well, but along with that, I was asked about my involvement in college club and fest activities. I was also asked about my interests in sports and athletics. 
            </p>
          </p>
          <p>
            <b>
              8. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
            Understanding what HILTI is known for and what products/ services they have been working on in recent times shows the interviewers that you are thorough about your goal and are a good candidate for them.
            </p>
          </p>
          
            <strong>9. Final Tip for Juniors?</strong>
            <p>
            Knowing more than one solution to a problem shows that you have a deeper understanding of the problem's complexities and a broader range of strategies.
              <br />
              <br />
              Wishing you all the best!!
            </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:guptesm21.comp@coeptech.ac.in">
            guptesm21.comp@coeptech.ac.in
            </a>
            <br />
            LinkedIn:{" "}
            <a href="https://www.linkedin.com/in/soham-gupte-b238a9226/">Soham Gupte</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default soham_gupte;

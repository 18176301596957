import React from "react";

const atharva_muglikar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Atharva Muglikar
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              There was an Aptitude Test first , it was followed by 2 interview
              rounds (technical and hr rounds)
            </p>
          </p>

          <p>
            <b>2. Structure of Aptitude Round :</b>
            <br />
            The test had 3 sections:
            <ol>
              <li>
                Verbal section: In this section efficiency in english language
                was checked. It had some 12 - 15 questions and 20 - 25 mins to
                solve them. Difficulty was easy to medium
              </li>
              <li>
                Business section: This section had questions related to data
                analysis , statistics (basic ones) and a bit on finance.The
                difficulty level was medium.This section also had 12-15
                questions but 40 mins of time to solve them.
              </li>
              <li>
                Coding section: This section had 2 questions . Everyone had
                different questions . I had the first one on basic array
                manipulation . The second one was on finding whether the numbers
                in the array are square and increasing their count.
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              The process had 3 rounds:
              <ol>
                <li>Aptitude test (as described above)</li>
                <li>
                  Technical interview : I was asked questions on sorting
                  algorithms and was given two coding questions to solve . First
                  was to check if the given string (which consists of only
                  different types of brackets) was syntactically correct ,and
                  the second question was finding a pattern in the given string
                  and counting the number of occurrences in the pattern. I was
                  not able to give a good answer to this question . I was asked
                  a little about the events I did in this round itself . The
                  interviewer also asked if I knew dbms which I didn’t as we
                  were just starting with the subject . I honestly told him
                  about this and he didn’t ask further questions about the
                  subject. Then he asked if I had something that I would like to
                  know about the company. I asked a few things and we concluded
                  the interview.
                </li>
                <li>
                  HR interview: This was less of an HR interview and more of a
                  technical interview . I was again asked questions on different
                  data structures and sorting algorithms . Questions on time
                  complexities were asked . Binary search was also asked in
                  detail. There was only one question that seemed like an HR
                  question . The interviewer asked if I preferred city life or
                  village life . I answered the question and we concluded the
                  interview after that.
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>4. Some good resources you used in your internship prep</b>
            <p>
              For DSA Babbar cpp course is a great resource. The 450 DSA
              questions sheet is also a good way to clear concepts . The GFG
              archive of the company is also useful.
            </p>
          </p>

          <p>
            <b>5. Some tips to crack coding questions</b>
            <br />

            <p>
              Practice is the only key . You can solve some leetcode questions
              everyday and be consistent with it so that it pays off in the end.
            </p>
          </p>

          <p>
            <b>
              6. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>{" "}
            <br />
            <p>
              In the HR round questions related to my projects were asked .
              There were 2 - 3 questions on the projects I did , not much on it.
              Some questions were asked about my hobbies in the last round and a
              bit on my clubs in the technical round. I had Web Dev on my resume
              so some questions were also asked on that.
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              One has to be well versed with the sorting algorithms as many
              questions were asked on it . Apart from that, normal DSA was
              sufficient for me.
            </p>
          </p>
          <p>
            <b>8. How many students were shortlisted after each round ?</b>
            <p>
              For technical interview round nearly 12 students were shortlisted
              and 6 were shortlisted for HR round and 4 were selected in the
              end.
            </p>
          </p>
          <p>
            <strong>9. Final Tip for Juniors?</strong>
            <p>
              The internship process can be a bit off the schedule and new
              companies pop off out of nowhere so it is very important that you
              start early and are ready for the interview when the first company
              comes. Rejection is difficult to handle but keeping high spirits
              and trying hard until you get an internship is all we can do.
              Don’t give up , I didn’t clear aptitude tests of 4 companies and
              was rejected in the interviews by 2 companies . In one of the
              companies out of 15 people only me and two more people were not
              selected rest all were selected but I didn’t give up . So don’t
              give up, work and All the Best !!!
            </p>
          </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:muglikaraa20.comp@coep.ac.in">
              muglikaraa20.comp@coep.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default atharva_muglikar;

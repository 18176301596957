import React from "react";

const harsh_marke = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Harsh Dipak Marke
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Marvell</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Round 1: Data Structures and Algorithms (DSA) and DBMS
                                    This 45-50 minute round focused on solving easy to medium level DSA problems using C language. I presented multiple approaches and also answered basic DBMS questions.
                                </li>
                                <li>
                                    Round 2: Computer Networking and Linux Commands
                                    This round tested my knowledge of computer networking concepts and Linux commands.
                                </li>
                                <li>
                                    Round 3: HR Round
                                    The HR round explored my personal and professional background, including previous project experiences, hobbies, interests, and to understand my personality and suitability for potential job placements.
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude round consisted of a mix of basic questions on Python and C programming, along with some data structures and algorithms (DSA) problems. Additionally, it included questions on Database Management Systems (DBMS). This round aimed to assess my foundational knowledge and problem-solving abilities across these areas.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 3 rounds for the company.
                        <br />
                        <br />
                        <b>Round 1:</b>
                        <br />
                        The first round focused on assessing my proficiency in data structures and algorithms, with a particular emphasis on solving problems using C language. It lasted for about 45-50 minutes. The questions included checking if a number is divisible by 2 using bit manipulation, reversing the first k elements of an array, and finding prime numbers within a specified range. I approached each problem with multiple solutions and discussed these approaches in detail. Additionally, basic questions on Database Management Systems (DBMS) were asked to gauge my understanding of fundamental database concepts.
                        <br />
                        <br />
                        <b>Round 2:</b>
                        <br />
                        The second round delved into my knowledge of computer networking and Linux commands, reflecting the company's reliance on Linux for their operations. The questions covered various aspects of networking and required familiarity with Linux commands, including fdisk among others. This round was designed to test my practical skills and theoretical knowledge in managing and operating within a Linux environment.
                        <br />
                        <br />
                        <b>Round 3: HR Round</b>
                        <br />
                        The third round was an HR interview that explored my personal and professional background. It included questions about my previous projects, allowing me to discuss my experiences and achievements in detail. The interviewer also inquired about my hobbies and interests to understand my personality better. Additionally, they asked about my location preferences, likely to assess my flexibility and suitability for potential job placements.
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        As the whole interview process was counducted online there is no such specific information about how many students were shortlisted after each round.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        For my internship preparation, I relied on several excellent resources. To cover all types of data structures and algorithms (DSA) problems, I followed the Striver SDE Sheet and the 450 DSA Sheet. For both the aptitude round and technical interview preparation, I used IndiaBIX. To learn dynamic programming, I followed Aditya Verma's playlist on YouTube. Additionally, I watched Operating Systems (OS) and Database Management Systems (DBMS) tutorials from Love Babbar's YouTube channel. These resources comprehensively prepared me for the various rounds of the interview process.
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            To crack coding questions, start by thoroughly understanding the problem statement and planning your approach before coding. Practice common problem-solving patterns like dynamic programming and recursion. Optimize your code for time and space complexities, write clean and readable code, and test it with various test cases. Learn from your mistakes, practice regularly on platforms like LeetCode, GeeksforGeeks and stay calm and focused during interviews, communicating your thought process clearly to the interviewer.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            I was asked about projects more than extracurricular activities during the interview. Specifically, I discussed the auto-completion project I implemented using Prefix Trie, highlighting my proficiency in DSA in C. While there were some questions about extracurricular activities, the focus remained primarily on my project experiences.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            For those applying to Marvell, it's essential to focus on mastering C programming and familiarizing yourself with Linux and its basic commands. Since Marvell primarily uses C for coding and operates on Linux, having a strong foundation in these areas is crucial. Additionally, dedicate time to coding practice in C to sharpen your skills and prepare for technical interviews effectively. This preparation will greatly enhance your chances of success in the application process.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Utilise resources like online coding platforms and tutorials to strengthen your problem-solving skills and understanding of data structures and algorithms. Remember to also highlight any projects or experiences relevant to the position you're applying for, as interviewers often look for practical application of your skills. Stay calm, focused, and confident during interviews.
                        </p>
                    </p>
                    <br />



                </div>
            </section >
        </div >
    );
};

export default harsh_marke;

import React from "react";

const om_khare = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Om Khare</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Deutsche Bank
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              <b>Aptitude Round: </b>On HackerEarth.
              <br />
              There were 3 rounds: 1. Technical round 2. Pro-Fitness round 3. HR
              round
            </p>
          </p>

          <p>
            <b>2. Structure of Aptitude Round :</b>
            First there was an aptitude test on HackerEarth with 10 MCQ’s on
            Predict the output in Java, CN, DBMS and 2 coding questions of
            moderate difficulty.
            <br />
            <ul>
              <li>
                First coding question was a variation of
                <a href="https://www.geeksforgeeks.org/next-greater-element/">
                  {" "}
                  Next Greater Element
                </a>
              </li>

              <li>Second coding question was on XOR (Bit manipulation)</li>
            </ul>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>There were 3 rounds:</p>
            <ol>
              <li>
                <strong>Technical Round </strong>
                <p>
   
                  Initially he asked me to rate myself in DSA out of 10. I said
                  9. He then asked me 3 coding questions for which I had to
                  write the code on paper and tell the time complexity. The
                  questions were:
                  <br />
                  
                    1.Find Least Common Ancestor in a binary tree and then in a
                    BST.
                    <br />
                 
                  2.Find all subarrays of an array
                <br />
                    {" "}
                    <a
                      href="https://www.geeksforgeeks.org/minimum-removals-required-to-make-ranges-no
n-overlapping/"
                    >
                      3.Minimum number of removals such that there are no
                      overlapping intervals
                    </a>{" "}
             
                  <p>
                    After this he asked questions on DBMS like explain what is
                    normalization, state different types of normalization with a
                    real life example where you will use it. He then asked me
                    questions about my projects. He asked me questions on OOP
                    like what is polymorphism, difference between run-time and
                    compile time polymorphism, what is overloading, overriding,
                    which operators cannot be overloaded. At last he asked me a
                    puzzle. I asked him one question.
                  </p>
                </p>
       
                <li>
                <strong>Pro-fitness round:</strong>
                <br />
                <p>
                  The interviewer asked me questions about my projects, about my
                  experience in different clubs/ fests. He gave me a table and
                  asked me 4 SQL queries on self-join, group by, where and
                  limit. He asked me in depth about when to use normalization
                  and when not to. He gave me two simple puzzles to solve. He
                  asked me how do you think you would stand out amongst the rest
                  of the candidates and why should we hire you. At the end I
                  asked him two questions.
                </p>
                </li>
                
              </li>
              <li>
 
                  <strong>HR round:</strong>

                <br />
                <p>
                  He asked basic HR questions like why DB, explain the most
                  interesting project that you did, what do you know about
                  finance etc.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>4. Some good resources you used in your internship prep</b>
            <br />
            <p>
              I used GeeksForGeeks, Leetcode, HackerRank for coding. For DBMS,
              CN, OS I watched video lectures from youtube playlists and
              referred to GFG articles for notes. Indiabix for aptitude
              questions.
            </p>
          </p>

          <p>
            <b>5. Some tips to crack coding questions</b>
            <br />

            <p>
              Once you are well-versed with standard problems try to solve
              problems which are in the form of stories. The questions in the
              aptitude have a long story from which you have to identify the
              standard problem.
            </p>
          </p>

          <p>
            <b>
              6. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>{" "}
            <br />
            <p>
              I was asked a lot about the projects and the position of
              responsibilities. Interviewers like those projects which have a
              significance in real life
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people who
              try for the same company
            </b>
            <br />
            <p>
              Deutsche bank asks a lot of questions on OOP, DBMS so prepare
              those topics well.
            </p>
          </p>
          <p>
            <strong>8. Final Tip for Juniors?</strong>
            <p>
              Luck is a major factor. Just believe in yourself. Keep talking
              with your friends and family during the internship process.
            </p>
          </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:khareom20.comp@coep.ac.in">
            khareom20.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default om_khare;

import React from "react";

const shreeya_ganji = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Shreeya  Ganji
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Siemens Technology and services</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The interview consisted of single round ( Technical + HR round )
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The  Aptitude round consists of - Mettl Placement Papers have these sections in the exam - <br />
                            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                <li>
                                    Quants -  Number of Questions - 10 Questions
                                </li>
                                <li>
                                    English -  Number of Questions - 10 Questions
                                </li>
                                <li>
                                    Logical -  Number of Questions - 10 Questions <br />
                                    Time Allotted in this Section -30 mins (Shared)
                                </li>
                                <li>
                                    Computer Fundamentals - Number of Questions -25 <br />
                                    Time Allotted in this Section-30 mins
                                </li>
                            </ul>

                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There is only one interview round consisting of technical and HR round consisting of 40-45 minutes  which mostly based on resume / CV . For me atleast he asked on technical subjects like TOC , two three dsa questions , some extra circular activities like clubs , events, etc .Overall it was entire resume based .
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        20 students
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        geeks for geeks for technical rounds last minute notes , Love babbar 450 dsa sheet , etc
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practice  each  dsa  question from 450 dsa sheet  daily atleast of Arrays, strings , trees , linked list, stacks.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            2-3 questions on technical subjects like TOC, DSA , Mostly questions were about clubs and extra curricular activities
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Mostly clear the aptitude test since the interview round isnt much difficult . Even the cgpa can be consideration for the selection process
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Always give your 100 % in every interview process no matter of the outcome .
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        LinkedIn : <a href="https://www.linkedin.com/in/shreeya-ganji-b3009b22b">Shreeya Ganji</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default shreeya_ganji;

import React from "react";

const umang_sakhare_tally = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Umang Sakhare
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Tally Solutions{" "}
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p class="has-line-data" data-line-start="16" data-line-end="17">
              Aptitude test followed by 2 interview rounds.
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p class="has-line-data" data-line-start="20" data-line-end="21">
              There were 3 coding questions. They focused on hash-maps, trees,
              and arrays(which could be solved better using dynamic programming
              but an unoptimized solution also worked). They asked a few
              students on linked list so I suggest you get well-versed with that
              as well. Total time was 1.5 hours.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <p class="has-line-data" data-line-start="24" data-line-end="25">
              There were 2 interview rounds and they were both technical.
            </p>
            <p class="has-line-data" data-line-start="26" data-line-end="27">
              The interviewer of the first round asked what domain of computer
              science I was interested in and I answered computer networks. The
              next 20 mins of the interview I was quizzed on CN. He dived deep
              into the topics and asked tricky questions to make me second guess
              myself. So make sure whatever topic you mention, you are
              well-versed in it. He then moved on to 3 coding questions. First
              two were simple: Reverse a string, Check a loop in the linked list
              and if the loop is present find it’s starting node. The third
              question was on hash-maps and that took some thinking. One
              suggestion I will give is keep vocalizing whatever you are
              thinking while solving these questions and the interviewer will
              help you along the way. Then he asked me 3 puzzles which are easy
              to tackle if you have solved the puzzles archive on GeeksForGeeks.
              5 people were selected for this round.
            </p>
            <p class="has-line-data" data-line-start="28" data-line-end="30">
              The second round focused more on my projects and past technical
              experience. He questioned me in detail about all of these and was
              interested in knowing what challenges I faced and how I tackled
              them. Then I was asked about the domains I was interested. I was
              again questioned on computer networks. He then proceeded to ask
              questions on operating systems: multithreading, process
              synchronization, deadlock and CPU scheduling. Finally I was
              questioned on DBMS: normalization and ACID properties.
              <br />
              The key to cracking this round is to be thorough about everything
              in your resume and to know the basics of CN, OS, DBMS. I was the
              only one selected for this round.
            </p>
            <p class="has-line-data" data-line-start="31" data-line-end="32">
              Although there were just 2 rounds they both lasted 1.5 hours each.
              At the end of each round I was asked a few personal questions like
              where I see myself in 5 years, future education plans, why I want
              to work for Tally etc.
            </p>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            <p class="has-line-data" data-line-start="35" data-line-end="36">
              Second round of interview was majorly focused on this. So prepare
              well. Only mention projects and technical experiences that you can
              talk about in great detail.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <p class="has-line-data" data-line-start="39" data-line-end="40">
              <a href="https://geeksforgeeks.com">GeeksForGeeks </a>
              and{" "}
              <a href="https://www.crackingthecodinginterview.com/">
                Cracking the coding interview{" "}
              </a>
              helped me the most.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p class="has-line-data" data-line-start="43" data-line-end="44">
              Questions weren’t too hard, they just wanted to test your basic
              understanding of these data structures so prepare the easy/medium
              difficulty level questions rather than the hard ones. Focus on
              trees, hash-maps, linked lists, arrays.
            </p>
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            <p class="has-line-data" data-line-start="47" data-line-end="48">
              Be as interactive with the interviewer as possible. Make it a
              conversation rather than a one sided interview.
            </p>
          </p>

          <i>
            They want to hire an individual who understands the basics of data
            structures, OS, DBMS and CN well. I personally believe to make a
            good impression, you need to be confident, curious and show quick
            thinking skills. Ask them questions as well, keep the conversation
            flowing. Show curiosity in the work they do and the work Tally does.
            Vocalize everything you are thinking so the interviewer understands
            your thought process. The interviewers are very kind and helpful
            people who will guide you if you aren’t sure of the answer so don’t
            panic.
          </i>

          <br />
        </div>
      </section>
    </div>
  );
};
export default umang_sakhare_tally;

import React from "react";

const tanmay_patil = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Tanmay Sudam Patil
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            {" "}
            Deutsche Bank
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />
            Entire Interview Process had total of four rounds after the Aptitude
            Test listed as follows:
            <ol>
              <li>Aptitude Test</li>
              <li>Tech Interview 1</li>
              <li>Tech Interview 2</li>
              <li>Professional Fitness Interview</li>
              <li>HR Interview</li>
            </ol>
            <b>Structure of Aptitude Round :</b>
            <br />
            <p>
              <ol>
                <li>Consisted a total of three coding questions.</li>
                <li>
                  No questions were asked touching the topics like Numerical
                  Reasoning, Verbal Ability or Logical Reasoning.
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>
              2. How many interview rounds were there for the company, can you
              elaborate about each round in brief ?
            </b>
            <br />
            <p>
              Following the aptitude exam, there were four rounds of interviews.
              <br />
              <ol>
                <li>
                  <b>Tech Round 1 (35 mins approx.) :</b>
                  <p>
                    It began with an introduction and a thorough examination of
                    my résumé. There was a panel of two interviewers, one of
                    whom was dedicated to discussing my work and the other to
                    asking DSA questions. They started with simple questions to
                    get me comfortable, then gradually increased the difficulty
                    of the questions. I recall a question: build a C function to
                    swap two integers without utilizing a temporary variable or
                    a bitwise operator. We also had a lengthy conversation about
                    the security of an email client I created for a networking
                    project, as well as queries about queuing delays and other
                    networking issues. Finally, he instructed me to solve a
                    classic pizza(or cake) cutting puzzle, which can be found
                    <a href="https://www.geeksforgeeks.org/puzzle-3-cuts-cut-round-cake-8-equal-pieces/">
                      {" "}
                      here
                    </a>
                  </p>
                </li>
                <li>
                  <b>Tech Round 2 (45 mins approx.) :</b>
                  <p>
                    The interviewer then asked me what my favorite subject was
                    following the introduction. After a lengthy talk about
                    database management systems, I was asked to quickly create a
                    library database schema and then to create an optimized
                    query to obtain data from a specified field. OOPS was then
                    the subject of a lengthy discussion that concluded in the
                    coding of some associated code.
                  </p>
                </li>
                <li>
                  <b>Professional Fit Round (25 mins approx.) :</b>
                  <p>
                    Compared to the previous two rounds, this one started off in
                    a different direction. I was asked interview-related
                    questions on how I would react in certain situations. We
                    also got into the topic of the value of both a team working
                    on a tiny feature and an individual contributor. We then get
                    into some questions about my internship, my time working for
                    the company, and my preferences and dislikes. The final set
                    of questions covered topics of personal interest, such as
                    preferred programming languages and strengths and
                    weaknesses.
                  </p>
                </li>
                <li>
                  <b>HR Round (15 mins approx.) :</b>
                  <p>
                    He asked me why I wanted to join DB and what intrigued me
                    about working here, as one may anticipate from ahead of HR
                    rounds. After providing a reasonable reply, he merely
                    verified the availability of documents that they would need
                    prior to onboarding. At the conclusion of the longest day of
                    my undergraduate career, he just requested me to spell out
                    my name before saying goodbye.
                  </p>
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>3. Some good resources you used in your Placement prep</b>
            <br />I personally would suggest DSA sheets out there on the
            internet if you have ample amount of time to prepare. If you are
            late to start, I would suggest filtering out company specific
            questions for companies you are targeting using GFG and Leetcode
            (sounds vague but works sometimes). For platforms to practice, try
            to get a hang of HackerRank, as many of the aptitude tests you may
            face will be held there. Other than that, if one feels this is
            insufficient, go for codechef and codeforces.
          </p>

          <p>
            <b>4. Some tips to crack coding questions :</b>
            <br />
            Try to grasp the question first before moving on to the coding part.
            Create a clear image of your proposed solution, determine whether it
            addresses the problem at hand, and then code it.
          </p>
          <p>
            <b>5. How many students were shortlisted after each round :</b>
            <br />A total of 6 people made it to the final shortlist after the
            HR round.
          </p>
          <p>
            <b>
              6. How many questions were asked about projects, clubs and
              extracurricular activities in your resume :
            </b>
            <br />
            Many questions about the projects I worked on were posed at each
            round. There were fewer questions on clubs and extracurricular
            activities. Since I was a member of the COEP Chess Team, I was even
            willing to play a game over the board if asked, just kidding :)
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company ?
            </b>
            <br />I don’t believe there is something specific which one needs to
            take care of while preparing for DB. A solid understanding of core
            concepts and a decent ability to solve problems would land you an
            offer here, or in general anywhere.
          </p>
          <p>
            <b>Final Tip for Juniors</b>
            <br />
            <p>
            Sometimes the whole process of placement can be hectic and could demand you
stay calm and grind all day long. Just hold down your nerve and you know
everything else that has to be done.
            </p>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:mailsforme2468@gmail.com">mailsforme2468@gmail.com</a>
            <br />
            LinkedIn: 
            <a href="https://www.linkedin.com/in/tanmay-patil-0785501b4/">Tanmay Patil</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default tanmay_patil;

import React from "react";

const himanshu_kamdi = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Himanshu Kamdi
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The Interview Process consisted of 3 rounds:
                            <br />
                        </p>
                        <p>
                            <ol>
                                <li>Technical Interview</li>
                                <li>Managerial Interview</li>
                                <li>HR Interview</li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The Aptitude Round were conducted online on AMCAT platform for 3 portfoilios:
                            <ul style={{listStyle:"disc", paddingLeft: "40px"}}>
                                <li>Personal Banking Wealth Management Technology (PBWMT)</li>
                                <li>Institutional Clients Group (ICG) Tech</li>     
                                <li>Institutional Clients Group (ICG) Production</li>                              
                            </ul>
                            There was a EIOT portfoilios earlier mentioned in the application but they were instructed to give ICG Production test.
                            <br />
                        </p>
                        <p>
                            The aptitude test for ICG Tech consisted of 4 sections:
                            <ol>
                                <li><b>SQL:</b> 12 Questions</li>
                                <li><b>React:</b> 12 Questions</li>
                                <li><b>Angular:</b> 12 Questions</li>
                                <li><b>Coding Questions:</b>2 Questions</li>
                            </ol>
                            <p>
                                The questions for SQL were basic and based on fundamental concepts while questions on React and Angular were based on the concepts of the respective frameworks and required knowledge of various concepts.
                                <br/>
                                The Coding Questions were random for each student but the questions were very easy.
                            </p>
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were total 4 rounds: 1 Aptitude Round and 3 Interview Rounds.
                        <ol>
                            <li><b>Technical Round</b>
                            <p>                                
                                The technical round began with a discussion about my resume. The interviewer asked me to summarize it without looking at it. Then, he asked about my projects and cross-questioned me about the technologies used and the concepts related to them. From one of the projects, the interviewer transitioned to asking questions about DBMS. He started by asking why I used MongoDB, followed by common basic questions. Finally, he asked a SQL query related to JOINS and aggregate functions. The interview ended with him asking about my role as Tech Head at CSI (He was also a head at CSI during his college).
                            </p>
                            </li>
                            <li><b>Managerial Round</b>
                            <p>
                                In this round typical HR questions were asked like Tell me about yourself, Why Citi, Where do you see yourself in 5 years.
                                </p></li>
                            <li><b>HR Round</b>
                            <p>
                                In this question the interviewer asked me about my family background, my hobbies and interests. He also asked me about my strengths and weaknesses and I try to overcome them. He also asked a weird question that I had many of my projects and course certificates related to ML but if they don't have any role related to ML how will I adjust.
                            </p>
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                            Total 54 students were selected for interview. 15 for PBWMT and 39 for ICG (ICG Tech and ICG Production were combined). For ICG 16 Students were selected for Round 2 and 12 students were selected for HR.
                            <br/>
                            Finally 10 students were selectd for ICG and 3 for PBWMT.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        For Coding I used to follow Striver and Love Babbar playlist on YouTube. Then solve the problems on LeetCode, GFG, Coding Ninjas.
                        <br/> I solved IQ (aptitude) questions on indiabix or any random website on Google. 
                        <br/> For core CS topics I used to refer articles on GeeksforGeeks.  
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Consistency is the key to Success. Regularly solve some coding problems. If you are not able to solve a question see the solution and solve the question after sometime. Understand the approach and try to solve the question again, do not try to remember the solution.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            Questions were asked about the projects mentioned in the resume. So know your projects well and try to have answers prepared for common questions asked for concepts or technologies used. 
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            There was nothing much special about the company. Just be confident and answer the questions properly.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            The interview process is also much based on luck. So if get rejected do not lose hope and stay focused on your goal.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:kamdihs21.comp@coeptech.ac.in">
                            kamdihs21.comp@coeptech.ac.in
                        </a>
                        <br />
                        LinkedIn : <a href="https://www.linkedin.com/in/himanshu-kamdi-757b59290/">Himanshu Kamdi</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default himanshu_kamdi;

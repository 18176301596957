import React from "react";

const naishadh_vora_toshiba = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Naishadh Vora
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Toshiba </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p class="has-line-data" data-line-start="20" data-line-end="21">
              It was pretty standard - Aptitude test followed by 2 tech rounds,
              1 managerial round and 1 HR round.
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p class="has-line-data" data-line-start="24" data-line-end="25">
              Aptitude test consisted of MCQs and a single coding question.
              There were about 15 quant MCQs, followed by 15 technical MCQs -
              these were mostly related to C. The coding question was pretty
              basic, just required knowledge of 1D arrays. However the catch was
              that the coding question could only be written in C, no other
              language. This is pretty unique, but it was because the role was
              for writing firmware, thus they needed proficient C developers.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <p class="has-line-data" data-line-start="28" data-line-end="29">
              The first technical round lasted around an hour, and basic
              concepts of OS were tested. A couple of questions regarding
              pointer manipulation and structs in C, followed by a simple coding
              question.
            </p>
            <p class="has-line-data" data-line-start="30" data-line-end="31">
              The second technical interview was pretty gruelling, and it lasted
              almost 2 hours. I was asked the following coding questions, all to
              be written in C - reverse a linked list, find if a list has a
              loop, set, reset bit at position X and finally print the bit
              representation for a number. Apart from this, I was asked to
              predict the output for a few C code snippets, and then theory
              questions related to OS. I was also asked about advanced C
              concepts like functions pointers and the way padding works for
              structs (the difference between int/char/int and int/int/char
              while defining a struct).
            </p>
            <p class="has-line-data" data-line-start="32" data-line-end="33">
              The third round was a managerial round, and I was asked about my
              past projects and why I wanted to join Toshiba. I hadn’t done any
              hardware projects and the manager wanted to make sure that my
              interest lay in the same. I was asked to generate skeleton code
              for my own function to generate a random number, and low level GDB
              concepts. A couple of compiler questions were also asked, like how
              to make gcc prioritize space/time during compilation and so on.
            </p>
            <p class="has-line-data" data-line-start="34" data-line-end="35">
              The HR round was more of a formality and I was asked about my
              background, future plans, etc.
            </p>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            <p class="has-line-data" data-line-start="38" data-line-end="39">
              Around 2-3, and only in the manager round. He asked my what the
              project was, a short demo (one was a mobile app), and what
              considerations I had made during the design phase.
            </p>
          </p>

          <p>
            <b>
              5. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            <p class="has-line-data" data-line-start="42" data-line-end="43">
              Look carefully at the role being offered, and prepare accordingly
              (they will mention in the pre placement talk). They had told us
              that they were looking for C developers to write firmware and the
              entire process was geared toward exactly that. They did not ask
              DBMS/Networking questions, it was all about OS and C. The manager
              too was more interested in hardware or OS level projects than
              higher layer application layer ones.
            </p>
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:voranaishadh@gmail.com">voranaishadh@gmail.com</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default naishadh_vora_toshiba;

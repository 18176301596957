//import { CompareTwoTone } from "@material-ui/icons";

// export const webscape_23 = [
//   {
//     title: "WebScape - Event in Association with MindSpark COEP",
//     date: "27-28 October 2023",
//     linkedin: "https://www.linkedin.com/posts/csicoep_csi-coeptech-webdevelopment-activity-7125747768169512961-9q-I?utm_source=share&utm_medium=member_desktop",

//     details: [
//       {
//         item: 0,
//         image: "WebScape_23/poster.jpeg",
//         description: "Round 2 - Top 15 scorers from Round 1"
//       },
//       {
//         item: 1,
//         image: "WebScape/WebScape_new.png",
//         description: "WebScape - Code, Compete and Re-imagine"
//       },
//       {
//         item: 2,
//         image: "WebScape/Winner1.png",
//         description: "Winners - Abhishek Shinde & Himanshu Kamdi"
//       },
//       {
//         item: 3,
//         image: "WebScape/Winner2.png",
//         description: "First Runner Up - Kartik Mandhan & Vivek Patil"
//       },
//       {
//         item: 4,
//         image: "WebScape/Winner3.png",
//         description: "Second Runner Up - Atharva Mutsaddi & Soham Naigaonkar"
//       },
//       {
//         item: 5,
//         image: "WebScape/Round1.jpg",
//         description: "Round 1"
//       },
//       {
//         item: 6,
//         image: "WebScape/Round2 - img1.jpg",
//         description: "Round 2"
//       }
//     ]
//   }
// ]

// export const inspiron2 = [
//   {
//     title: "Inspiron2.0 - National Level Hackathon",
//     date: "24 March 2023",


//     details: [
//       {
//         item: 0,
//         image: "WebScape/Round2 - img2.jpg",
//         description: "Round 2 - Top 15 scorers from Round 1"
//       },
//       {
//         item: 1,
//         image: "WebScape/WebScape_new.png",
//         description: "WebScape - Code, Compete and Re-imagine"
//       },
//       {
//         item: 2,
//         image: "WebScape/Winner1.png",
//         description: "Winners - Abhishek Shinde & Himanshu Kamdi"
//       },
//       {
//         item: 3,
//         image: "WebScape/Winner2.png",
//         description: "First Runner Up - Kartik Mandhan & Vivek Patil"
//       },
//       {
//         item: 4,
//         image: "WebScape/Winner3.png",
//         description: "Second Runner Up - Atharva Mutsaddi & Soham Naigaonkar"
//       },
//       {
//         item: 5,
//         image: "WebScape/Round1.jpg",
//         description: "Round 1"
//       },
//       {
//         item: 6,
//         image: "WebScape/Round2 - img1.jpg",
//         description: "Round 2"
//       }
//     ]
//   }
// ]

// export const webscape = [
//   {
//     title: "WebScape - Event in Association with MindSpark COEP",
//     date: "14 January 2023",
//     linkedin: "https://www.linkedin.com/feed/update/urn:li:activity:7020720651262930944",

//     details: [
//       {
//         item: 0,
//         image: "WebScape/Round2 - img2.jpg",
//         description: "Round 2 - Top 15 scorers from Round 1"
//       },
//       {
//         item: 1,
//         image: "WebScape/WebScape_new.png",
//         description: "WebScape - Code, Compete and Re-imagine"
//       },
//       {
//         item: 2,
//         image: "WebScape/Winner1.png",
//         description: "Winners - Abhishek Shinde & Himanshu Kamdi"
//       },
//       {
//         item: 3,
//         image: "WebScape/Winner2.png",
//         description: "First Runner Up - Kartik Mandhan & Vivek Patil"
//       },
//       {
//         item: 4,
//         image: "WebScape/Winner3.png",
//         description: "Second Runner Up - Atharva Mutsaddi & Soham Naigaonkar"
//       },
//       {
//         item: 5,
//         image: "WebScape/Round1.jpg",
//         description: "Round 1"
//       },
//       {
//         item: 6,
//         image: "WebScape/Round2 - img1.jpg",
//         description: "Round 2"
//       }
//     ]
//   }
// ]

// export const codequest3 = [
//   {
//     title: "Codequest 3.0 - A Hunt for COEP's Coding Champion",
//     date: "22 October 2022 - 2 January 2023",
//     downloadables: "CodeQuest3_Brochure.pdf",
//     linkedin: "https://www.linkedin.com/feed/update/urn:li:activity:7016417724515946496/",
//     instagram: "https://www.instagram.com/p/Cm_473Qj6PA/?igshid=Yzg5MTU1MDY%3D",
//     details: [
//       {
//         item: 0,
//         image: "Codequest3/img1.jpeg",
//         description: "The finalists of Codequest 3.0",
//       },
//       {
//         item: 1,
//         image: "Codequest3/img2.jpeg",
//         description: "The winners of Codequest 3.0",
//       },
//       {
//         item: 2,
//         image: "Codequest3/img3.jpeg",
//         description: "The winners of Codequest 3.0",
//       }
//     ]
//   }
// ]

// export const codetocompete2022 = [
//   {
//     title: "Code to Compete",
//     date: "8th October 2022",
//     details: [
//       {
//         item: 0,
//         image: "Code_to_Compete_2022/img6.png",
//         description: "Internship experience by Sameer Kavthekar",
//       },
//       {
//         item: 1,
//         image: "Code_to_Compete_2022/img1.png",
//         description: "Introduction to Competitive Programming",
//       },
//       {
//         item: 2,
//         image: "Code_to_Compete_2022/img2.png",
//         description: "Demonstration of Codechef",
//       },
//       {
//         item: 3,
//         image: "Code_to_Compete_2022/img3.png",
//         description: "Demonstration of Codechef",
//       },
//       {
//         item: 4,
//         image: "Code_to_Compete_2022/img4.png",
//         description: "Past experience by Codequest 2.0 Sr. Winner",
//       },
//       {
//         item: 5,
//         image: "Code_to_Compete_2022/img5.png",
//         description: "Past experience by Codequest 2.0 Jr. Winner",
//       }
//     ]
//   }
// ]

// export const engg_is_awesome = [
//   {
//     title: "Engineering is Awesome Session by Raghavan Koli",
//     date: "1st September 2022",
//     downloadables: "engineering_is_awesome.pdf",
//     details: [
//       {
//         item: 0,
//         image: "Engg_awesome/img2.jpeg",
//         description: 'Raghavan Koli : Renowned Author and Motivational Speaker'
//       },
//       {
//         item: 1,
//         image: "Engg_awesome/img1.jpg",
//         description: 'Engineering is Awesome session'
//       },
//       {
//         item: 2,
//         image: "Engg_awesome/img3.jpeg",
//         description: 'Session for Computer branch students'
//       },

//     ]
//   }
// ]

// export const intel = [
//   {
//     title: "Parallel Programming Through DPC++",
//     date: "27 August 2022",
//     downloadables: "Parallel_Programming_Through_DPC++.pdf",
//     details: [
//       {
//         item: 0,
//         image: "Intel/img5.png",
//         description: `Writing First Program in DPC++`,
//       },
//       {
//         item: 1,
//         image: "Intel/intel.png",
//         description: `Parallel Programming Through DPC++ Intel Supported Workshop`,
//       },
//       {
//         item: 2,
//         image: "Intel/img1.png",
//         description: `Introduction to DPC++`,
//       },
//       {
//         item: 3,
//         image: "Intel/img2.jpeg",
//         description: `Parallel Programming Tools`,
//       },
//       {
//         item: 4,
//         image: "Intel/img3.jpeg",
//         description: `Parallel Programming Tools`,
//       },
//       {
//         item: 5,
//         image: "Intel/program.jpeg",
//         description: `Writing First Program in DPC++`,
//       },

//     ]

//   }
// ]
// export const lectureseries = [
//   {
//     title: "Lecture Series",
//     date: "12 March 2022 - 10 April 2022",
//     downloadables: "Lecture_Series_Brochure.pdf",
//     details: [
//       {
//         item: 0,
//         image: "LectureSeries/LectureSeriesImg4.PNG",
//         description: `Track 2 - System Software and Computer Architecture`,
//       },
//       {
//         item: 1,
//         image: "LectureSeries/LectureSeriesPoster.png",
//         description: `Lecture Series in association with NVIDIA`,
//       },
//       {
//         item: 2,
//         image: "LectureSeries/LectureSeriesImg1.jpeg",
//         description: `Track 1 - Deep Learning and Neural Networks`,
//       },
//       {
//         item: 3,
//         image: "LectureSeries/LectureSeriesImg2.jpeg",
//         description: `Track 1 - Deep Learning and Neural Networks`,
//       },
//       {
//         item: 4,
//         image: "LectureSeries/LectureSeriesImg3.jpeg",
//         description: `Track 2 - System Software and Computer Architecture`,
//       },

//     ],
//   },
// ];


// export const inspiron = [
//   {
//     title: "Inspiron '22",
//     date: "12 March 2022 - 13 March 2022",
//     downloadables: "Inspiron22Brochure.pdf",
//     details: [
//       {
//         item: 0,
//         image: "Inspiron/Inspiron1.png",
//         description: `Winners of Inspiron '22`,
//       },
//       {
//         item: 1,
//         image: "Inspiron/Inspiron2.png",
//         description: `Inspiron '22 - National Level Hackathon`,
//       },
//       {
//         item: 2,
//         image: "Inspiron/Inspiron8.png",
//         description: `CSI COEP Student Chapter Core Team members and faculty present on campus`,
//       },
//       {
//         item: 3,
//         image: "Inspiron/Inspiron3.png",
//         description: `Team Speculo`,
//       },
//       {
//         item: 4,
//         image: "Inspiron/Inspiron4.png",
//         description: `Team Safetify`,
//       },
//       {
//         item: 5,
//         image: "Inspiron/Inspiron5.png",
//         description: `Team RookieCoders`,
//       },
//       {
//         item: 6,
//         image: "Inspiron/Inspiron6.png",
//         description: `Team Full Slack Developers`,
//       },
//       {
//         item: 7,
//         image: "Inspiron/Inspiron7.png",
//         description: `Team Code Force`,
//       },
//     ],
//   },
// ];

// export const chromedevtools = [
//   {
//     title: "Chrome DevTools and Extensions Workshop",
//     date: "22 January 2022",
//     details: [
//       {
//         item: 0,
//         image: "DevTools/Dev1.png",
//         description: ` Exploring and understanding use of Chrome DevTools`,
//       },
//       {
//         item: 1,
//         image: "DevTools/Dev2.png",
//         description: `Hands-on session on Chrome DevTools and Extensions`,
//       },
//       {
//         item: 2,
//         image: "DevTools/Dev3.png",
//         description: ` Creating a dark mode extension for COEP Moodle`,
//       },
//     ],
//   },
// ];

// export const codequest2 = [
//   {
//     title: "CodeQuest 2.0 - A Hunt for COEP's Coding Champion",
//     date: "20 Oct 2021 - 19 Dec 2021",
//     downloadables: "CodeQuest2.0_Brochure.pdf",
//     details: [
//       {
//         item: 0,
//         image: "CodeQuest2/5.jpg",
//         description: `Prize distribution ceremony, presided by the HOD`,
//       },
//       {
//         item: 1,
//         image: "CodeQuest2/4.jpg",
//         description: `The winners of CodeQuest 2.0`,
//       },
//     ],
//   },
// ];
// export const webdesign = [
//   {
//     title: "Web Design - in collaboration with MindSpark 21 ",
//     date: "30 October 2021",
//     details: [
//       {
//         item: 0,
//         image: "Web_Design/w1.png",
//         description: `Webpage created by runner up team`,
//       },
//       {
//         item: 1,
//         image: "Web_Design/w2.png",
//         description: `Event Details`,
//       },
//       {
//         item: 2,
//         image: "Web_Design/w3.png",
//         description: `Webpage created by winning team`,
//       },
//     ],
//   },
// ];
// export const codetocompete = [
//   {
//     title: "Code to Compete",
//     date: "17th Oct 2021",
//     details: [
//       {
//         item: 0,
//         image: "Code_to_Compete_2021/1.png",
//         description: `Guidance by CodeQuest 2020 winners`,
//       },
//       {
//         item: 1,
//         image: "Code_to_Compete_2021/2.png",
//         description: `Introduction to Competitive Programming`,
//       },
//       {
//         item: 2,
//         image: "Code_to_Compete_2021/3.png",
//         description: `Demonstration on dynamic programming`,
//       },
//     ],
//   },
// ];

// export const eventsJson = [
//   {
//     event: "CodeQuest",
//     title: "CodeQuest - a coding standoff to test the best!",
//     startDate: "11 Oct 2020",
//     endDate: "5 Dec 2020",
//     imgCaptions: [
//       "Prize distribution ceremony, presided by the HOD",
//       "The winners of inaugural CodeQuest",
//     ],
//   },

//   {
//     event: "Scrapping",
//     startDate: "1 Mar 2020",
//     title: "One Day Workshop on Web Crawling and Scraping",
//     downloadType: "pdf",
//     imgCaptions: [
//       "Dr. V. Z. Attar ma'am felicitating Mr. Anand Sharma.",
//       "Mr. Anand Kulkarni highlighting the applications of Web Crawling.",
//       "Mr. Amey Gat discussing few of the common tools used for scraping.",
//       "CSI COEP Student Chapter Members with guests and faculty.",
//     ],
//   },

//   {
//     event: "Green",
//     title:
//       "Expert Talk on Green Computing through Adaptive Multi-core Architectures",
//     startDate: "14 Feb 2020",
//     downloadType: "pdf",
//     imgCaptions: [
//       "Faculty and guest on the dias.",
//       "Dr. S.L Patil felicitating Prof. Israel Koren.",
//       "Prof. Koren addressing the students.",
//       "CSI COEP Student Chapter Members with guests and faculty.",
//     ],
//   },

//   {
//     event: "MERN",
//     title: "CSI-SDS MERN Stack Workshop (Internal)",
//     startDate: "24 Jan 2020",
//     endDate: "25 Jan 2020",
//     imgCaptions: [
//       "MERN vs LAMP",
//       "Explaining the flow of MERN.",
//       "Explaining code.",
//     ],
//   },

//   {
//     event: "ODLC",
//     title: "One Day Lecture Series",
//     startDate: "10 Nov 2019",
//     downloadType: "pdf",
//     imgCaptions: [
//       `Mr. Pranit Kothari, NVIDIA giving a talk on "Introduction to Deep Learning".`,
//       `Ms. Neeti, C-DAC delivering a talk on "Blockchain - An introduction and Industry View".`,
//       `Mr. Abhinav Bhagwat, VMWARE giving a talk on "Virtualization of Compute, Storage and Networking".`,
//       `CSI COEP Student Chapter Members with Guest and Faculty.`,
//     ],
//   },

//   {
//     event: "Git",
//     title: "Git Hands-on Workshop (Internal)",
//     startDate: "20 Oct 2019",
//     imgCaptions: [
//       "Demonstration of the steps to do a task were done, so students could follow along better.",
//       "Students were quizzed on the outputs that could be expected on running different commands.",
//       "Immense response from students for the session.",
//       "Student attendees with the CSI COEP Student Chapter team.",
//     ],
//   },

//   {
//     event: "ODLCpython",
//     title: "One Day Workshop on Python Programming",
//     startDate: "3 Mar 2019",
//     downloadType: "pdf",
//     imgCaptions: [
//       "CSI COEP Student Chapter Members with guests and faculty.",
//       "Overwhelming response from students for the workshop.",
//       "Hands on workshop on industrial applications of Python, conducted by Mr. Prabhakar Gadupudi.",
//       "Mr. Prabhakar Gadupudi addressing the audience",
//     ],
//   },

//   {
//     event: "ExpertTalk",
//     title:
//       "Expert talk on Supercomputing and Its Applications To Atmospheric Science",
//     startDate: "23 Oct 2018",
//     downloadType: "pdf",
//     imgCaptions: [
//       "Dr. Bipin Kumar, Scientist E - IITM Pune, conducting Expert Talk on 'Supercomputing and its Applications to Atmospheric Science'.",
//       "CSI COEP Student Chapter team with the guests.",
//     ],
//   },

//   {
//     event: "Inaugration",
//     title: "Inaugural Ceremony of CSI COEP Student Chapter",
//     startDate: "23 Oct 2018",
//     downloadType: "pdf",
//     imgCaptions: [
//       "CSI COEP Student Chapter Members with guests and faculty.",
//       "Mr. Abhay Pendse, CSI Pune Chapter Chairman, addressing the audience",
//     ],
//   },
// ];

// export const scrappingEvent = [
//   {
//     date: "1 Mar 2020",
//     title: "One Day Workshop on Web Crawling and Scraping",
//     downloadables: "Scrapping.pdf",
//     details: [
//       {
//         item: 0,
//         image: "Scrapping/1.jpg",
//         description: "Dr. V. Z. Attar ma'am felicitating Mr. Anand Sharma.",
//       },
//       {
//         item: 1,
//         image: "Scrapping/2.jpg",
//         description:
//           "Mr. Anand Kulkarni highlighting the applications of Web Crawling.",
//       },
//       {
//         item: 2,
//         image: "Scrapping/3.jpg",
//         description:
//           "Mr. Amey Gat discussing few of the common tools used for scraping.",
//       },
//       {
//         item: 3,
//         image: "Scrapping/4.jpg",
//         description:
//           "CSI COEP Student Chapter Members with guests and faculty.",
//       },
//     ],
//   },
// ];

// export const greenEvent = [
//   {
//     title:
//       "Expert Talk on Green Computing through Adaptive Multi-core Architectures",
//     date: "14 Feb 2020",
//     downloadables: "Green.pdf",
//     details: [
//       {
//         item: 0,
//         image: "Green/1.jpg",
//         description: "Faculty and guest on the dias.",
//       },
//       {
//         item: 1,
//         image: "Green/2.jpg",
//         description: "Dr. S.L Patil felicitating Prof. Israel Koren.",
//       },
//       {
//         item: 2,
//         image: "Green/3.jpg",
//         description: "Prof. Koren addressing the students.",
//       },
//       {
//         item: 3,
//         image: "Green/4.jpg",
//         description:
//           "CSI COEP Student Chapter Members with guests and faculty.",
//       },
//     ],
//   },
// ];

// export const mernEvent = [
//   {
//     title: "CSI-SDS MERN Stack Workshop (Internal)",
//     date: "24 Jan 2020 - 25 Jan 2020",
//     details: [
//       {
//         item: 0,
//         image: "MERN/1.jpg",
//         description: "MERN vs LAMP",
//       },
//       {
//         item: 1,
//         image: "MERN/2.jpg",
//         description: "Explaining the flow of MERN.",
//       },
//       {
//         item: 2,
//         image: "MERN/3.jpg",
//         description: "Explaining code.",
//       },
//     ],
//   },
// ];

// export const odlcEvent = [
//   {
//     title: "One Day Lecture Series",
//     date: "10 Nov 2019",
//     downloadables: "ODLC.pdf",
//     details: [
//       {
//         item: 0,
//         image: "ODLC/1.jpg",
//         description: `Mr. Pranit Kothari, NVIDIA giving a talk on "Introduction to Deep Learning".`,
//       },
//       {
//         item: 1,
//         image: "ODLC/2.jpg",
//         description: `Ms. Neeti, C-DAC delivering a talk on "Blockchain - An introduction and Industry View".`,
//       },
//       {
//         item: 2,
//         image: "ODLC/3.jpg",
//         description: `Mr. Abhinav Bhagwat, VMWARE giving a talk on "Virtualization of Compute, Storage and Networking".`,
//       },
//       {
//         item: 3,
//         image: "ODLC/4.jpg",
//         description: `CSI COEP Student Chapter Members with Guest and Faculty.`,
//       },
//       //   {
//       //     item: 4,
//       //     image: "/5.jpg",
//       //     description: "Explaining code.",
//       //   },
//     ],
//   },
// ];

// export const gitEvent = [
//   {
//     title: "Git Hands-on Workshop (Internal)",
//     date: "20 Oct 2019",
//     details: [
//       {
//         item: 0,
//         image: "Git/1.jpg",
//         description: `Demonstration of the steps to do a task were done, so students could follow along better.`,
//       },
//       {
//         item: 1,
//         image: "Git/2.jpg",
//         description: `Students were quizzed on the outputs that could be expected on running different commands.`,
//       },
//       {
//         item: 2,
//         image: "Git/3.jpg",
//         description: `Immense response from students for the session.`,
//       },
//       {
//         item: 3,
//         image: "Git/4.jpg",
//         description: `Student attendees with the CSI COEP Student Chapter team.`,
//       },
//     ],
//   },
// ];

// export const odlcPythonEvent = [
//   {
//     title: "One Day Workshop on Python Programming",
//     date: "3 Mar 2019",
//     downloadables: "ODLCpython.pdf",
//     details: [
//       {
//         item: 0,
//         image: "ODLCpython/1.jpg",
//         description: `CSI COEP Student Chapter Members with guests and faculty.`,
//       },
//       {
//         item: 1,
//         image: "ODLCpython/2.jpg",
//         description: `Overwhelming response from students for the workshop.`,
//       },
//       {
//         item: 2,
//         image: "ODLCpython/3.jpg",
//         description: `Hands on workshop on industrial applications of Python, conducted by Mr. Prabhakar Gadupudi.`,
//       },
//       {
//         item: 3,
//         image: "ODLCpython/4.jpg",
//         description: `Mr. Prabhakar Gadupudi addressing the audience`,
//       },
//     ],
//   },
// ];

// export const expertTalk = [
//   {
//     title:
//       "Expert talk on Supercomputing and Its Applications To Atmospheric Science",
//     date: "23 Oct 2018",
//     downloadables: "ExpertTalk.pdf",
//     details: [
//       {
//         item: 0,
//         image: "ExpertTalk/1.jpg",
//         description: `Dr. Bipin Kumar, Scientist E - IITM Pune, conducting Expert Talk on 'Supercomputing and its Applications to Atmospheric Science'.`,
//       },
//       {
//         item: 1,
//         image: "ExpertTalk/2.jpg",
//         description: `CSI COEP Student Chapter team with the guests.`,
//       },
//     ],
//   },
// ];

// export const inaugration = [
//   {
//     title: "Inaugural Ceremony of CSI COEP Student Chapter",
//     date: "23 Oct 2018",
//     downloadables: "Inaugration.pdf",
//     details: [
//       {
//         item: 0,
//         image: "Inaugration/1.jpg",
//         description: `CSI COEP Student Chapter Members with guests and faculty.`,
//       },
//       {
//         item: 1,
//         image: "Inaugration/2.jpg",
//         description: `Mr. Abhay Pendse, CSI Pune Chapter Chairman, addressing the audience`,
//       },
//     ],
//   },
// ];

// export const codequest = [
//   {
//     title: "CodeQuest - a coding standoff to test the best!",
//     date: "11 Oct 2020 - 5 Dec 2020",
//     downloadables: "#",
//     details: [
//       {
//         item: 0,
//         image: "CodeQuest/1.jpg",
//         description: `Prize distribution ceremony, presided by the HOD`,
//       },
//       {
//         item: 1,
//         image: "CodeQuest/2.jpg",
//         description: `The winners of inaugural CodeQuest`,
//       },
//     ],
//   },
// ];

// export const artSciencePublication = [
//   {
//     title: `Webinar on ' Mastering the Art of Scientific Publication '`,
//     date: "30 Jan 2021",
//     downloadables: "PublicationBrochure.pdf",
//     details: [
//       {
//         item: 0,
//         image: "ArtSciencePublication/1.png",
//         description: `Importance of Publication`,
//       },
//       {
//         item: 1,
//         image: "ArtSciencePublication/2.png",
//         description: `Process of Publication`,
//       },
//       {
//         item: 2,
//         image: "ArtSciencePublication/3.png",
//         description: `Pointers for a good paper`,
//       },
//       {
//         item: 3,
//         image: "ArtSciencePublication/4.png",
//         description: `Some precautions`,
//       },
//     ],
//   },
// ];

// export const algoWar = [
//   {
//     title: `Algowar`,
//     date: `7 Mar 2021`,
//     downloadables: `Algowar.pdf`,
//     details: [
//       {
//         item: 0,
//         image: "Algowar/1.jpg",
//         description: "Registration Information",
//       },
//       {
//         item: 1,
//         image: "Algowar/2.jpg",
//         description: "Winners",
//       },
//     ],
//   },
// ];

// export const git2020 = [
//   {
//     title: `Git Workshop`,
//     date: `2 Oct 2020`,
//     details: [
//       {
//         item: 0,
//         image: "git2020/1.jpg",
//       },
//       {
//         item: 1,
//         image: "git2020/2.jpg",
//       },
//     ],
//   },
// ];

// export const hackTheHackathon = [
//   {
//     title: `Hack the Hackathon`,
//     date: `24 Oct 2020`,
//     downloadables: `hackathon_brochure.pdf`,
//     details: [
//       {
//         item: 0,
//         image: "hack-the-hackathon/1.png",
//       },
//       {
//         item: 1,
//         image: "hack-the-hackathon/2.png",
//       },
//       {
//         item: 2,
//         image: "hack-the-hackathon/3.png",
//       },
//       {
//         item: 3,
//         image: "hack-the-hackathon/4.png",
//       },
//     ],
//   },
// ];

// export const symposiumEvent = [
//   {
//     title: `One Day Symposium on Recent Trends in AI, GPU Computing & IoT`,
//     date: `18 Sep 2021`,
//     downloadables: `Sympo.pdf`,
//     details: [
//       {
//         item: 0,

//         image: "Symposium/ODS1.jpeg",
//         description:
//           "Session on 'Powering the Next Era of Analytics and AI with GPU' by Mr. Bharat Kumar, NVIDIA",
//       },
//       {
//         item: 1,
//         image: "Symposium/ODS2.jpeg",
//         description:
//           "Talk on 'Emerging Trends in Artificial Intelligence' by Dr. Brijraj Singh, Samsung Research Labs",
//       },
//       {
//         item: 2,
//         image: "Symposium/ODS3.jpeg",
//         description:
//           "Lecture on 'Demystifying IoT: Connect, Collect and Visualize' along with live demo by Mr. Bhupendra Pratap Singh, Accenture",
//       },
//       {
//         item: 3,
//         image: "Symposium/ODS4.jpeg",
//         description:
//           "CSI COEP Student Chapter Core Team members and faculty present on campus",
//       },
//     ],
//   },
// ];
// export const byjus = [
//   {
//     title: `Webinar on Profile Building by BYJU'S - Careerlabs`,
//     date: `29 Sep 2020`,
//     downloadables: `byjus-webinar.pdf`,
//     details: [
//       {
//         item: 0,
//         image: "byjus/1.png",
//       },
//       {
//         item: 1,
//         image: "byjus/2.png",
//       },
//       {
//         item: 2,
//         image: "byjus/3.png",
//       },
//       {
//         item: 3,
//         image: "byjus/4.png",
//       },
//     ],
//   },
// ];

export const events = {
  expert_session: [
    {
      title: "Research opportunities at IIT Bombay and expert session on Video Stabilization",
      date: "23rd October 2024",
      year: "2024-25",
      linkedin: "https://www.linkedin.com/posts/csicoep_researchopportunities-videostabilization-activity-7255073100076122112-Dl13?utm_source=share&utm_medium=member_desktop",

      details: [

        {
          item: 0,
          image: "Ajit_Rajwade/1.jpg",
          description: "Research Opportunities at IIT Bombay"
        },
        {
          item: 1,
          image: "Ajit_Rajwade/poster.jpg",
          description: "Expert Session by Dr. Ajit Rajwade Sir"
        },
        {
          item: 2,
          image: "Ajit_Rajwade/grp_pic.jpg",
          description: "With the CSI Core Team"
        },
        {
          item: 3,
          image: "Ajit_Rajwade/2.jpg",
          description: "Flavours of Video Stabilization"
        },
        {
          item: 4,
          image: "Ajit_Rajwade/3.jpg",
          description: "Exploring the Computer Science Field"
        },
        {
          item: 5,
          image: "Ajit_Rajwade/vahida_maam.jpg",
          description: "Highlights of the Session"
        }
      ]
    }
  ],
  webscape_24: [
    {
      title: "Webscape - Event in Association with MindSpark'24",
      date: "18th-19th October 2024",
      year: "2024-25",
      linkedin: "https://www.linkedin.com/posts/csicoep_csi-mindspark24-webscape-activity-7254022045972262915-FyRF?utm_source=share&utm_medium=member_desktop",

      details: [

        {
          item: 0,
          image: "Webscapes_24/judge.jpg",
          description: "Webscape 2024 Judge"
        },
        {
          item: 1,
          image: "Webscapes_24/poster_2.png",
          description: "WEBSCAPE 2024"
        },
        {
          item: 2,
          image: "Webscapes_24/webscape_24_round-1.jpg",
          description: "Webscape 2024 round 1"
        },
        {
          item: 3,
          image: "Webscapes_24/webscape_24_final.jpg",
          description: "Webscape 2024 round 2"
        },
        {
          item: 4,
          image: "Webscapes_24/webscape_24_winner.jpg",
          description: "Webscape 2024 winners : Satej Sawant and Tanmay Shingde"
        },
        {
          item: 5,
          image: "Webscapes_24/webscape_24_runner_up.jpg",
          description: " Webscape 2024 runners up: Yadnesh Firake and Samarth Dol"
        },
        {
          item: 6,
          image: "Webscapes_24/round-1_2.jpg",
          description: "Webscape 2024 round 1 highlights"
        }
      ]
    }
  ],
  SyDummies24: [{
    title: "Surviving SY For Dummies",
    date: "9th October 2024",
    year: "2024-25",
    linkedin: "https://www.linkedin.com/posts/csicoep_csicoeptech-learningtogether-syfordummies-activity-7251449174825590785-8R5Q?utm_source=share&utm_medium=member_desktop",
    details: [
      {
        item: 0,
        image: "TY&SYForDummies/dummies_1.jpg",
        description: "Surviving SY For Dummies Mini-Auditorium"
      },
      {
        item: 1,
        image: "TY&SYForDummies/dummies_slide_1.png",
        description: "Surviving SY For Dummies 2024-25 Batch "
      },
      {
        item: 2,
        image: "TY&SYForDummies/handover.jpg",
        description: "Handover ceremony for the new core team"
      },
      {
        item: 3,
        image: "TY&SYForDummies/sy_dummies_ppt_pic_1.png",
        description: "Microprocessor Techniques"
      },
      {
        item: 4,
        image: "TY&SYForDummies/sy_dummies_ppt_pic_2.png",
        description: "Principles of programming languages"
      },
      {
        item: 5,
        image: "TY&SYForDummies/sy_dummies_ppt_pic_3.png",
        description: "DSA Project"
      }
      
    ]
  }
  ],
  codequest4: [{
    title: "Codequest 4.0 - Embarking on the Search for COEP's Coding Conqueror",
    date: "13 November 2023 - 7 January 2024",
    year: "2023-24",
    linkedin: "https://www.linkedin.com/posts/csicoep_csi-coeptech-webdevelopment-activity-7125747768169512961-9q-I?utm_source=share&utm_medium=member_desktop",
    details: [
      {
        item: 0,
        image: "CodeQuest4/award_ceremony.jpeg",
        description: "Highlights from Award Ceremony"
      },
      {
        item: 1,
        image: "CodeQuest4/CodeQuest4.0_Winners.jpeg",
        description: "Winners of Codequest 4.0"
      },
      
      {
        item: 2,
        image: "CodeQuest4/live_event1.jpeg",
        description: "Captured Moments of the Final Round"
      },
      {
        item: 3,
        image: "CodeQuest4/live_event3.jpeg",
        description: "Captured Moments of the Final Round"
      }
      
    ]
  }
  ],
  SyTyForDummies: [{
    title: "Surviving TY & SY For Dummies",
    date: "14 September 2023",
    year: "2023-24",
    linkedin: "https://www.linkedin.com/posts/csicoep_csi-coeptech-webdevelopment-activity-7125747768169512961-9q-I?utm_source=share&utm_medium=member_desktop",
    details: [
      {
        item: 0,
        image: "TY&SYForDummies/survivingForDummiesSY.jpeg",
        description: "SY Surviving For Dummies 2023-24 Batch"
      },
      {
        item: 1,
        image: "TY&SYForDummies/SY for dummies.jpeg",
        description: "SY Dummies Session For Even Sem 2023-24 "
      },
      {
        item: 2,
        image: "TY&SYForDummies/SY DSA II.jpeg",
        description: "SY Dummies Session DSA-II"
      },
      {
        item: 3,
        image: "TY&SYForDummies/survivingForDummiesTY.jpeg",
        description: "TY Surviving For Dummies 2023-24 Batch"
      },
      {
        item: 4,
        image: "TY&SYForDummies/TY for dummies.jpeg",
        description: "TY Dummies Session For Even Sem 2023-24"
      },
      {
        item: 5,
        image: "TY&SYForDummies/TY OS.jpeg",
        description: "TY Dummies Session OS"
      },{
        item: 6,
        image: "TY&SYForDummies/Doubt session.jpeg",
        description: "Doubt Session !!"
      }
      
    ]
  }
  ],
  codetocompete2023 : [
    {
      title: "Code To Compete '23",
      date: "6th November 2023",
      year:"2023-24",
      linkedin: "https://www.linkedin.com/posts/csicoep_coding-competitiveprogramming-internship-activity-7127668178771488768-UL2L?utm_source=share&utm_medium=member_android",
  
      details: [
        
        {
          item: 0,
          image: "Code_to_Compete_2023/ctc_event_0.jpeg",
          description: "Online Seminar"
        },
        {
          item: 1,
          image: "Code_to_Compete_2023/ctc_event.jpg",
          description: "Code to Compete 2023"
        },
        {
          item: 2,
          image: "Code_to_Compete_2023/ctc_event_2.jpeg",
          description: "Pratik Patil providing an introduction to CP"
        },
        {
          item: 3,
          image: "Code_to_Compete_2023/ctc_event_3.jpeg",
          description: "Abhinav Kurule sharing Insights on Problem-Solving "
        }
      ]
    }
  ],
  webscape_23: [
    {
      title: "WebScape - Event in Association with MindSpark'23",
      date: "27-28 October 2023",
      year: "2023-24",
      linkedin: "https://www.linkedin.com/posts/csicoep_csi-coeptech-webdevelopment-activity-7125747768169512961-9q-I?utm_source=share&utm_medium=member_desktop",

      details: [

        {
          item: 0,
          image: "WebScape_23/first_winner.jpg",
          description: "Winners- Abhishek Shinde & Arsh Maknojia"
        },
        {
          item: 1,
          image: "WebScape_23/poster.jpeg",
          description: "WEBSCAPE 2023"
        },
        {
          item: 2,
          image: "WebScape_23/second_winner.jpg",
          description: "First Runner Up - Deepak Shitole & Disha Mogre"
        },
        {
          item: 3,
          image: "WebScape_23/third_winner.jpg",
          description: "Second Runner Up - Khulage Chetan & Abhishek Nale"
        },
        {
          item: 4,
          image: "WebScape_23/round2.jpeg",
          description: "Round 2 Top 15 teams from Round 1"
        },
        {
          item: 5,
          image: "WebScape_23/round2_1.jpeg",
          description: "Round 2"
        }
      ]
    }
  ],  
  TY_Dummies: [{
    title: "Surviving TY & SY For Dummies",
    date: "14 September 2023",
    year: "2023-24",
    linkedin: "https://www.linkedin.com/posts/csicoep_csi-coeptech-webdevelopment-activity-7125747768169512961-9q-I?utm_source=share&utm_medium=member_desktop",
    details: [
      {
        item: 0,
        image: "ty_dummies_ppt.jpeg",
        description: "TY Dummies Session For 2023-24 Batch"
      },
      {
        item: 1,
        image: "TY_dummies_23.jpg",
        description: "TY Dummies Session For ODD Sem 2023-24 "
      },
      {
        item: 2,
        image: "sy_dummies_ppt.jpeg",
        description: "SY Dummies Session For 2023-24 Batch"
      },
      {
        item: 3,
        image: "SY_dummies_23.jpg",
        description: "SY Dummies Session For ODD Sem 2023-24 "
      },
    ]
  }
  ],
  // SY_Dummies: [{
  //   title: "Surviving SY For Dummies",
  //   date: "13 September 2023",
  //   year: "2023-24",

  //   details: [

  //     {
  //       item: 0,
  //       image: "SY_dummies_23.jpg",
  //       description: "SY Dummies Session For 2023-24 Batch"
  //     },
  //   ]
  // }
  // ],
   inspiron2: [
    {

      title: "Inspiron 2.0",
      date: "24-25 March 2023",
      linkedin: "https://www.linkedin.com/posts/csicoep_csi-coep-computerscience-activity-7046456692607258624-Z_fe?utm_source=share&utm_medium=member_desktop",
      year: "2022-23",
      viewmore: "https://youtube.com",
      details: [
        {
          item: 0,
          image: "Inspiron2/second_image.png",
          description: "Domain - IOT"
        },
        {
          item: 1,
          image: "Inspiron2/Inspiron.png",
          description: "Inspiron 2.0 - National Level Hackathon"
        },

        {
          item: 2,
          image: "Inspiron2/first_image.png",
          description: "Domain - Web Development"
        },
        {
          item: 3,
          image: "Inspiron2/third_image.png",
          description: "Domain - AI / ML"
        },
        {
          item: 4,
          image: "Inspiron2/fourth_img.png",
          description: "Domain - AI / ML"
        },

      ]
    }
  ],

  webscape: [
    {
      title: "WebScape - Event in Association with MindSpark COEP",
      date: "14 January 2023",
      linkedin: "https://www.linkedin.com/feed/update/urn:li:activity:7020720651262930944",
      year: "2022-23",
      details: [
        {
          item: 0,
          image: "WebScape/Round2 - img2.jpg",
          description: "Round 2 - Top 15 scorers from Round 1"
        },
        {
          item: 1,
          image: "WebScape/WebScape_new.png",
          description: "WebScape - Code, Compete and Re-imagine"
        },
        {
          item: 2,
          image: "WebScape/Winner1.png",
          description: "Winners - Abhishek Shinde & Himanshu Kamdi"
        },
        {
          item: 3,
          image: "WebScape/Winner2.png",
          description: "First Runner Up - Kartik Mandhan & Vivek Patil"
        },
        {
          item: 4,
          image: "WebScape/Winner3.png",
          description: "Second Runner Up - Atharva Mutsaddi & Soham Naigaonkar"
        },
        {
          item: 5,
          image: "WebScape/Round1.jpg",
          description: "Round 1"
        },
        {
          item: 6,
          image: "WebScape/Round2 - img1.jpg",
          description: "Round 2 - Top 15 scorers from Round 1"
        }
      ]
    }
  ],
  codequest3: [
    {
      title: "Codequest 3.0 - A Hunt for COEP's Coding Champion",
      date: "22 October 2022 - 2 January 2023",
      downloadables: "CodeQuest3_Brochure.pdf",
      linkedin: "https://www.linkedin.com/feed/update/urn:li:activity:7016417724515946496/",
      instagram: "https://www.instagram.com/p/Cm_473Qj6PA/?igshid=Yzg5MTU1MDY%3D",
      year: "2022-23",
      viewmore: "https://youtube.com",
      details: [
        {
          item: 0,
          image: "Codequest3/img1.jpeg",
          description: "The finalists of Codequest 3.0",
        },
        {
          item: 1,
          image: "Codequest3/img2.jpeg",
          description: "The winners of Codequest 3.0",
        },
        {
          item: 2,
          image: "Codequest3/img3.jpeg",
          description: "The winners of Codequest 3.0",
        }
      ]
    }
  ],
  codetocompete2022: [
    {
      title: "Code to Compete",
      date: "8th October 2022",
      year: "2022-23",
      details: [
        {
          item: 0,
          image: "Code_to_Compete_2022/img6.png",
          description: "Internship experience by Sameer Kavthekar",
        },
        {
          item: 1,
          image: "Code_to_Compete_2022/img1.png",
          description: "Introduction to Competitive Programming",
        },
        {
          item: 2,
          image: "Code_to_Compete_2022/img2.png",
          description: "Demonstration of Codechef",
        },
        {
          item: 3,
          image: "Code_to_Compete_2022/img3.png",
          description: "Demonstration of Codechef",
        },
        {
          item: 4,
          image: "Code_to_Compete_2022/img4.png",
          description: "Past experience by Codequest 2.0 Sr. Winner",
        },
        {
          item: 5,
          image: "Code_to_Compete_2022/img5.png",
          description: "Past experience by Codequest 2.0 Jr. Winner",
        }
      ]
    }
  ],
  engg_is_awesome: [
    {
      title: "Engineering is Awesome Session by Raghavan Koli",
      date: "1st September 2022",
      downloadables: "engineering_is_awesome.pdf",
      year: "2022-23",
      details: [
        {
          item: 0,
          image: "Engg_awesome/img2.jpeg",
          description: 'Raghavan Koli : Renowned Author and Motivational Speaker'
        },
        {
          item: 1,
          image: "Engg_awesome/img1.jpg",
          description: 'Engineering is Awesome session'
        },
        {
          item: 2,
          image: "Engg_awesome/img3.jpeg",
          description: 'Session for Computer branch students'
        },

      ]
    }
  ],
  intel: [
    {
      title: "Parallel Programming Through DPC++",
      date: "27 August 2022",
      downloadables: "Parallel_Programming_Through_DPC++.pdf",
      year: "2022-23",
      details: [
        {
          item: 0,
          image: "Intel/img5.png",
          description: `Writing First Program in DPC++`,
        },
        {
          item: 1,
          image: "Intel/intel.png",
          description: `Parallel Programming Through DPC++ Intel Supported Workshop`,
        },
        {
          item: 2,
          image: "Intel/img1.png",
          description: `Introduction to DPC++`,
        },
        {
          item: 3,
          image: "Intel/img2.jpeg",
          description: `Parallel Programming Tools`,
        },
        {
          item: 4,
          image: "Intel/img3.jpeg",
          description: `Parallel Programming Tools`,
        },
        {
          item: 5,
          image: "Intel/program.jpeg",
          description: `Writing First Program in DPC++`,
        },

      ]

    }
  ],
  lectureseries: [
    {
      title: "Lecture Series",
      date: "12 March 2022 - 10 April 2022",
      downloadables: "Lecture_Series_Brochure.pdf",
      year: "2021-22",
      details: [
        {
          item: 0,
          image: "LectureSeries/LectureSeriesImg4.PNG",
          description: `Track 2 - System Software and Computer Architecture`,
        },
        {
          item: 1,
          image: "LectureSeries/LectureSeriesPoster.png",
          description: `Lecture Series in association with NVIDIA`,
        },
        {
          item: 2,
          image: "LectureSeries/LectureSeriesImg1.jpeg",
          description: `Track 1 - Deep Learning and Neural Networks`,
        },
        {
          item: 3,
          image: "LectureSeries/LectureSeriesImg2.jpeg",
          description: `Track 1 - Deep Learning and Neural Networks`,
        },
        {
          item: 4,
          image: "LectureSeries/LectureSeriesImg3.jpeg",
          description: `Track 2 - System Software and Computer Architecture`,
        },

      ],
    },
  ],
  inspiron: [
    {
      title: "Inspiron '22",
      date: "12 March 2022 - 13 March 2022",
      downloadables: "Inspiron22Brochure.pdf",
      year: "2021-22",
      details: [
        {
          item: 0,
          image: "Inspiron/Inspiron1.png",
          description: `Winners of Inspiron '22`,
        },
        {
          item: 1,
          image: "Inspiron/Inspiron2.png",
          description: `Inspiron '22 - National Level Hackathon`,
        },
        {
          item: 2,
          image: "Inspiron/Inspiron8.png",
          description: `CSI COEP Student Chapter Core Team members and faculty present on campus`,
        },
        {
          item: 3,
          image: "Inspiron/Inspiron3.png",
          description: `Team Speculo`,
        },
        {
          item: 4,
          image: "Inspiron/Inspiron4.png",
          description: `Team Safetify`,
        },
        {
          item: 5,
          image: "Inspiron/Inspiron5.png",
          description: `Team RookieCoders`,
        },
        {
          item: 6,
          image: "Inspiron/Inspiron6.png",
          description: `Team Full Slack Developers`,
        },
        {
          item: 7,
          image: "Inspiron/Inspiron7.png",
          description: `Team Code Force`,
        },
      ],
    },
  ],
  chromedevtools: [
    {
      title: "Chrome DevTools and Extensions Workshop",
      date: "22 January 2022",
      year: "2021-22",
      details: [
        {
          item: 0,
          image: "DevTools/Dev1.png",
          description: ` Exploring and understanding use of Chrome DevTools`,
        },
        {
          item: 1,
          image: "DevTools/Dev2.png",
          description: `Hands-on session on Chrome DevTools and Extensions`,
        },
        {
          item: 2,
          image: "DevTools/Dev3.png",
          description: ` Creating a dark mode extension for COEP Moodle`,
        },
      ],
    },
  ],
  webdesign: [
    {
      title: "Web Design - in collaboration with MindSpark 21 ",
      date: "30 October 2021",
      year: "2021-22",
      details: [
        {
          item: 0,
          image: "Web_Design/w1.png",
          description: `Webpage created by runner up team`,
        },
        {
          item: 1,
          image: "Web_Design/w2.png",
          description: `Event Details`,
        },
        {
          item: 2,
          image: "Web_Design/w3.png",
          description: `Webpage created by winning team`,
        },
      ],
    },
  ],
  codequest2: [
    {
      title: "CodeQuest 2.0 - A Hunt for COEP's Coding Champion",
      date: "20 Oct 2021 - 19 Dec 2021",
      downloadables: "CodeQuest2.0_Brochure.pdf",
      year: "2021-22",
      details: [
        {
          item: 0,
          image: "CodeQuest2/5.jpg",
          description: `Prize distribution ceremony, presided by the HOD`,
        },
        {
          item: 1,
          image: "CodeQuest2/4.jpg",
          description: `The winners of CodeQuest 2.0`,
        },
      ],
    },
  ],
  codetocompete: [
    {
      title: "Code to Compete",
      date: "17th Oct 2021",
      year: "2021-22",
      details: [
        {
          item: 0,
          image: "Code_to_Compete_2021/1.png",
          description: `Guidance by CodeQuest 2020 winners`,
        },
        {
          item: 1,
          image: "Code_to_Compete_2021/2.png",
          description: `Introduction to Competitive Programming`,
        },
        {
          item: 2,
          image: "Code_to_Compete_2021/3.png",
          description: `Demonstration on dynamic programming`,
        },
      ],
    },
  ],
  symposiumEvent: [
    {
      title: `One Day Symposium on Recent Trends in AI, GPU Computing & IoT`,
      date: `18 Sep 2021`,
      downloadables: `Sympo.pdf`,
      year: "2021-22",
      details: [
        {
          item: 0,

          image: "Symposium/ODS1.jpeg",
          description:
            "Session on 'Powering the Next Era of Analytics and AI with GPU' by Mr. Bharat Kumar, NVIDIA",
        },
        {
          item: 1,
          image: "Symposium/ODS2.jpeg",
          description:
            "Talk on 'Emerging Trends in Artificial Intelligence' by Dr. Brijraj Singh, Samsung Research Labs",
        },
        {
          item: 2,
          image: "Symposium/ODS3.jpeg",
          description:
            "Lecture on 'Demystifying IoT: Connect, Collect and Visualize' along with live demo by Mr. Bhupendra Pratap Singh, Accenture",
        },
        {
          item: 3,
          image: "Symposium/ODS4.jpeg",
          description:
            "CSI COEP Student Chapter Core Team members and faculty present on campus",
        },
      ],
    },
  ],
  algoWar: [
    {
      title: `Algowar`,
      date: `7 Mar 2021`,
      downloadables: `Algowar.pdf`,
      year: "2020-21",
      details: [
        {
          item: 0,
          image: "Algowar/1.jpg",
          description: "Registration Information",
        },
        {
          item: 1,
          image: "Algowar/2.jpg",
          description: "Winners",
        },
      ],
    },
  ],
  artSciencePublication: [
    {
      title: `Webinar on ' Mastering the Art of Scientific Publication '`,
      date: "30 Jan 2021",
      downloadables: "PublicationBrochure.pdf",
      year: "2020-21",
      details: [
        {
          item: 0,
          image: "ArtSciencePublication/1.png",
          description: `Importance of Publication`,
        },
        {
          item: 1,
          image: "ArtSciencePublication/2.png",
          description: `Process of Publication`,
        },
        {
          item: 2,
          image: "ArtSciencePublication/3.png",
          description: `Pointers for a good paper`,
        },
        {
          item: 3,
          image: "ArtSciencePublication/4.png",
          description: `Some precautions`,
        },
      ],
    },
  ],
  hackTheHackathon: [
    {
      title: `Hack the Hackathon`,
      date: `24 Oct 2020`,
      downloadables: `hackathon_brochure.pdf`,
      year: "2020-21",
      details: [
        {
          item: 0,
          image: "hack-the-hackathon/1.png",
        },
        {
          item: 1,
          image: "hack-the-hackathon/2.png",
        },
        {
          item: 2,
          image: "hack-the-hackathon/3.png",
        },
        {
          item: 3,
          image: "hack-the-hackathon/4.png",
        },
      ],
    },
  ],
  codequest: [
    {
      title: "CodeQuest - a coding standoff to test the best!",
      date: "11 Oct 2020 - 5 Dec 2020",
      downloadables: "#",
      year: "2020-21",
      details: [
        {
          item: 0,
          image: "CodeQuest/1.jpg",
          description: `Prize distribution ceremony, presided by the HOD`,
        },
        {
          item: 1,
          image: "CodeQuest/2.jpg",
          description: `The winners of inaugural CodeQuest`,
        },
      ],
    },
  ],
  git2020: [
    {
      title: `Git Workshop`,
      date: `2 Oct 2020`,
      year: "2020-21",
      details: [
        {
          item: 0,
          image: "git2020/1.jpg",
        },
        {
          item: 1,
          image: "git2020/2.jpg",
        },
      ],
    },
  ],
  byjus: [
    {
      title: `Webinar on Profile Building by BYJU'S - Careerlabs`,
      date: `29 Sep 2020`,
      downloadables: `byjus-webinar.pdf`,
      year: "2020-21",
      details: [
        {
          item: 0,
          image: "byjus/1.png",
        },
        {
          item: 1,
          image: "byjus/2.png",
        },
        {
          item: 2,
          image: "byjus/3.png",
        },
        {
          item: 3,
          image: "byjus/4.png",
        },
      ],
    },
  ],
  scrappingEvent: [
    {
      date: "1 Mar 2020",
      title: "One Day Workshop on Web Crawling and Scraping",
      downloadables: "Scrapping.pdf",
      year: "2019-20",
      details: [
        {
          item: 0,
          image: "Scrapping/1.jpg",
          description: "Dr. V. Z. Attar ma'am felicitating Mr. Anand Sharma.",
        },
        {
          item: 1,
          image: "Scrapping/2.jpg",
          description:
            "Mr. Anand Kulkarni highlighting the applications of Web Crawling.",
        },
        {
          item: 2,
          image: "Scrapping/3.jpg",
          description:
            "Mr. Amey Gat discussing few of the common tools used for scraping.",
        },
        {
          item: 3,
          image: "Scrapping/4.jpg",
          description:
            "CSI COEP Student Chapter Members with guests and faculty.",
        },
      ],
    },
  ],
  greenEvent: [
    {
      title:
        "Expert Talk on Green Computing through Adaptive Multi-core Architectures",
      date: "14 Feb 2020",
      downloadables: "Green.pdf",
      year: "2019-20",
      details: [
        {
          item: 0,
          image: "Green/1.jpg",
          description: "Faculty and guest on the dias.",
        },
        {
          item: 1,
          image: "Green/2.jpg",
          description: "Dr. S.L Patil felicitating Prof. Israel Koren.",
        },
        {
          item: 2,
          image: "Green/3.jpg",
          description: "Prof. Koren addressing the students.",
        },
        {
          item: 3,
          image: "Green/4.jpg",
          description:
            "CSI COEP Student Chapter Members with guests and faculty.",
        },
      ],
    },
  ],
  mernEvent: [
    {
      title: "CSI-SDS MERN Stack Workshop (Internal)",
      date: "24 Jan 2020 - 25 Jan 2020",
      year: "2019-20",
      details: [
        {
          item: 0,
          image: "MERN/1.jpg",
          description: "MERN vs LAMP",
        },
        {
          item: 1,
          image: "MERN/2.jpg",
          description: "Explaining the flow of MERN.",
        },
        {
          item: 2,
          image: "MERN/3.jpg",
          description: "Explaining code.",
        },
      ],
    },
  ],
  odlcEvent: [
    {
      title: "One Day Lecture Series",
      date: "10 Nov 2019",
      downloadables: "ODLC.pdf",
      year: "2019-20",
      details: [
        {
          item: 0,
          image: "ODLC/1.jpg",
          description: `Mr. Pranit Kothari, NVIDIA giving a talk on "Introduction to Deep Learning".`,
        },
        {
          item: 1,
          image: "ODLC/2.jpg",
          description: `Ms. Neeti, C-DAC delivering a talk on "Blockchain - An introduction and Industry View".`,
        },
        {
          item: 2,
          image: "ODLC/3.jpg",
          description: `Mr. Abhinav Bhagwat, VMWARE giving a talk on "Virtualization of Compute, Storage and Networking".`,
        },
        {
          item: 3,
          image: "ODLC/4.jpg",
          description: `CSI COEP Student Chapter Members with Guest and Faculty.`,
        },
        //   {
        //     item: 4,
        //     image: "/5.jpg",
        //     description: "Explaining code.",
        //   },
      ],
    },
  ],
  gitEvent: [
    {
      title: "Git Hands-on Workshop (Internal)",
      date: "20 Oct 2019",
      year: "2019-20",
      details: [
        {
          item: 0,
          image: "Git/1.jpg",
          description: `Demonstration of the steps to do a task were done, so students could follow along better.`,
        },
        {
          item: 1,
          image: "Git/2.jpg",
          description: `Students were quizzed on the outputs that could be expected on running different commands.`,
        },
        {
          item: 2,
          image: "Git/3.jpg",
          description: `Immense response from students for the session.`,
        },
        {
          item: 3,
          image: "Git/4.jpg",
          description: `Student attendees with the CSI COEP Student Chapter team.`,
        },
      ],
    },
  ],
  odlcPythonEvent: [
    {
      title: "One Day Workshop on Python Programming",
      date: "3 Mar 2019",
      downloadables: "ODLCpython.pdf",
      year: "2018-19",
      details: [
        {
          item: 0,
          image: "ODLCpython/1.jpg",
          description: `CSI COEP Student Chapter Members with guests and faculty.`,
        },
        {
          item: 1,
          image: "ODLCpython/2.jpg",
          description: `Overwhelming response from students for the workshop.`,
        },
        {
          item: 2,
          image: "ODLCpython/3.jpg",
          description: `Hands on workshop on industrial applications of Python, conducted by Mr. Prabhakar Gadupudi.`,
        },
        {
          item: 3,
          image: "ODLCpython/4.jpg",
          description: `Mr. Prabhakar Gadupudi addressing the audience`,
        },
      ],
    },
  ],
  expertTalk: [
    {
      title:
        "Expert talk on Supercomputing and Its Applications To Atmospheric Science",
      date: "23 Oct 2018",
      downloadables: "ExpertTalk.pdf",
      year: "2018-19",
      details: [
        {
          item: 0,
          image: "ExpertTalk/1.jpg",
          description: `Dr. Bipin Kumar, Scientist E - IITM Pune, conducting Expert Talk on 'Supercomputing and its Applications to Atmospheric Science'.`,
        },
        {
          item: 1,
          image: "ExpertTalk/2.jpg",
          description: `CSI COEP Student Chapter team with the guests.`,
        },
      ],
    },
  ],
  inaugration: [
    {
      title: "Inaugural Ceremony of CSI COEP Student Chapter",
      date: "23 Oct 2018",
      downloadables: "Inaugration.pdf",
      year: "2018-19",
      details: [
        {
          item: 0,
          image: "Inaugration/1.jpg",
          description: `CSI COEP Student Chapter Members with guests and faculty.`,
        },
        {
          item: 1,
          image: "Inaugration/2.jpg",
          description: `Mr. Abhay Pendse, CSI Pune Chapter Chairman, addressing the audience`,
        },
      ],
    },
  ]
}
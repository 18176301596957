import React from "react";

const ved_dandekar_vmware = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Ved Dandekar</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>VMware</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ol type="1">
              <li>Aptitude Test</li>
              <li>Technical Interview</li>
              <li>Managerial Interview</li>
              <li>HR Interview</li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test comprised of intermediate-level quantitative
            questions, theory and numericals from core subjects - DS, OS, CN and
            DBMS, one coding question and two sections of output prediction
            based on your choice of programming language from C, C++, Java and
            Python.
          </p>

          <p>
            <b>
              3. How many questions were asked based on the projects and on
              resume?
            </b>
            <br />
            Although no round was specifically about resume and project
            discussion, in each round I discussed at least one project that I
            had completed in the past. No questions as such were asked about the
            code or technology itself but I was asked to discuss the motivation
            for the project, it's scope, current status and some details
            relating to deployment and maintenance.
          </p>

          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />I did my preparation was almost entirely from{" "}
            <a href="https://www.geeksforgeeks.org/">GeeksForGeeks</a>. Instead
            of studying random topics from GeeksForGeeks or binging YouTube
            courses, search for archives of the company you are targeting on
            GeeksForGeeks. Read through as many archives and experiences as
            possible and you will begin to notice a pattern of similar questions
            or questions based on particular topics. Focus your preparation
            primarily on these topics. If you manage to finish these, then move
            on to exploring other topics that you find relevant.
          </p>

          <p>
            <b>5. Some tips to crack the coding questions.</b> <br />
            Particular to VMware - although an efficient time complexity will
            fetch you 100%, remember that an accurate but naive solution will
            also be accepted. Instead of directly trying to write the most
            efficient solution, first code the trivial approach and submit it.
            This way you have a fallback option in case your efficient algorithm
            fails or you run out of time. I found the coding question we were
            provided rather simple. Because of this, I directly moved to writing
            the most efficient (but harder to code) approach. I was unable to
            debug my failing algorithm and was forced to write the (what I
            initially thought to be) trivial approach in the last 5 mins. That
            algorithm ended up passing 11/12 provided test casing without timing
            out.
          </p>
          <p>
            <b>
              6. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            Do not try to strive for perfection in your first try. Start at the
            baseline and work up from there in every round - be it coding or
            verbal interview. Not only will it help you organize your approach
            and thoughts but also help the interviewer better assess your
            capability.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="https://www.linkedin.com/in/ved-dandekar/">
              LinkedIn Handle
            </a>
            <br />
            <a href="mailto:dandekarvs17.it@coep.ac.in">
              dandekarvs17.it@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ved_dandekar_vmware;

import React from "react";

const utkarsha_gore = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Utkarsha Gore
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The Citi recruitment procedure involved an online aptitude test first, followed by three interview rounds:
                            <br />
                        </p>
                        <p>
                            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                <li>Aptitude Test(6th September, 2023)</li>
                                <li>Technical Interview Round(9th September, 2023)</li>
                                <li>Managerial Round(9th September, 2023)</li>
                                <li>HR Round(9th September, 2023)</li>
                            </ul>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Prior to the interview, the aptitude test was conducted on the AMCAT platform. Interns had a choice between two domains, and I selected ICG Tech.<br />
                            The assessment included four sections:<br />
                        </p>
                        <p><ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>Verbal Reasoning:- Difficulty:Easy </li>
                            <li>Arithmetical Reasoning:- Difficulty: Medium </li>
                            <li>Logical Reasoning:- Difficulty:Medium </li>
                            <li>Coding Questions:- Difficult:Easy/Medium(2 questions) </li>
                        </ul></p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were three rounds of interviews after the aptitude test.The first technical round began with an introduction. The interviewer reviewed my resume and discussed my involvement in clubs and extracurricular activities. I was then asked to choose a project from my resume and provide an overview. We had an in-depth discussion about the project, including its system architecture, technologies used, drawbacks, and future enhancements.While this round was project-centric for me, the focus might vary depending on the interviewer, with some balancing project discussion with technical questions.
                        <br />
                        The second round was a short interactive round.The interviewer asked about the reasons for choosing my college, the academic journey, and challenges faced along the way. This round aimed to evaluate thought process and confidence and was mainly about how confidently I could present myself.
                        The HR round also began with a brief introduction. Along with typical HR questions about my background, interests, and reasons for wanting to join Citi, I was asked why I thought I was a good fit for the job role I was applying for.

                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                Technical interview: 54 students                            </li>
                            <li>
                                Managerial Round: 20 students                            </li>
                            <li>
                                HR Round: 14 students                            </li>
                            <li>
                                Final offer: 13 students
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                LeetCode (coding questions)
                            </li>
                            <li>
                                GFG (for understanding DBMS,CN,OS and OOPs concepts)
                            </li>
                            <li>
                                Indiabix (aptitude questions)
                            </li>
                            <li>
                                GFG (commonly asked interview questions)
                            </li>
                            <li>
                                Abdul Bari’s DSA Course
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Try to practise as many questions as possible on platforms like GFG, HackerRank, or LeetCode.Solve problems patiently without rushing for solutions. If you struggle, understand the solution on your own. Over time, work on solving questions faster.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            In the technical round, the main focus was on my projects, with detailed questions asked about them. There were also questions about involvement in clubs and extracurricular activities to understand my skills beyond academics.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Go through previous year’s interview questions available online to better prepare for the technical and managerial rounds.Also,understand the company’s values, culture, and recent developments to perform well in the HR round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Don’t panic before interviews. Be honest about your skills,only include what you truly understand on your resume. Even if you're asked questions you weren’t expecting, focus on your approach and thought process. Guide the interview by highlighting the areas where you have strong expertise. Be confident, keep trying, and don’t give up. Your hard work will pay off in the end.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:goreuu21.comp@coeptech.ac.in">
                            goreuu21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default utkarsha_gore;

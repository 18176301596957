import React from "react";
import { Redirect } from "react-router-dom";

function redirectUtil(props, item, type) {
  const internName = props.match.params.batch;

  const intern = item.interns.find((intrn) => ((intrn.firstname+"-"+intrn.lastname).toLocaleLowerCase() === internName.toLocaleLowerCase().replace("_","-")
  || (intrn.firstname+"-"+intrn.lastname).toLocaleLowerCase() === internName.toLocaleLowerCase().replace(" ","-") ));

  if (!intern) return <Redirect to="/" />

  const companyName = props.match.params.company;

  const newUrl = `/${type}/${companyName}/${intern.batch}/${intern.firstname.toLocaleLowerCase()+"-"+intern.lastname.toLocaleLowerCase()}`;

  return <Redirect to={newUrl}/>;
}

export default redirectUtil;

import React from "react";

const samruddhi_kamthe = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Samruddhi Amol Kamthe
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Deutsche Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              Three interview rounds-
            </p>
            <ol>
              <li><b>Technical Round-</b></li>
              <p>
                I was asked questions mostly based on my resume, internships and projects. A lot of questions based on personal experiences and life were asked in this round as well.
              </p>
              <li><b>Profit(Professional Fitment) Round-</b></li>
              <p>
                I was asked to solve 2-3 coding questions in this round and give multiple approaches for each one.I was also asked questions based on DSA and DBMS(SQL).
              </p>
              <p>
                After that, questions like 'Explain your idea of Dream Job', 'Why DB?',etc. were asked.
                <br /> Situation-based questions highlighting the importance of team work, work culture, etc. were also asked.
              </p>
              <li><b>HR Round-</b></li>
              <p>
              It was a general conversation. General questions like 'Why DB?', 'Internship Experiences/learnings',etc. were asked
              </p>
            </ol>
          </p>
          <p>
            <b>2. Structure of Aptitude Round :</b>
            <br />
            <p>
            MCQs based on OS, DBMS, DSA & CN <br />
            2 Coding questions
            </p>
          </p>
          <p>
            <b>
              3. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b> <br />
            4 rounds as explained above ( 1 aptitude test + 3 interview rounds)
          </p>
          <p>
            <b>4. How many students were shortlisted after each round?</b>
            <p>
            For interview 14 students were shortlisted. <br /> After technical round, 12 students were shortlisted. <br /> After Profit and HR , finally 11 students got the offer.
            </p>
          </p>
          <p>
            <b>
              5. How many questions were asked about projects, clubs and
              extracurricular activities in your resume 
            </b>
            <br />
            <p>
            Quite a lot in first round.
            </p>
          </p>

          <p>
            <b>
              6. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
            GFG, leetcode, Abdul Bari DP playlist
            </p>
          </p>

          <p>
            <b>7. Some tips to crack the coding questions.</b> <br />
            <p>
            Practising the questions is the only way to crack them. Consistently solving questions from any platform will help.
            </p>
          </p>
          <p>
            <b>
              8. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
            For aptitude test, get your OS,DSA, DBMS concepts cleared. <br />
            For Coding questions, first write a rough idea of the solution on paper and then implement it and try to optimize the solution.
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID:
            <a href="mailto: kamthesa20.comp@coep.ac.in">
              kamthesa20.comp@coep.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default samruddhi_kamthe;

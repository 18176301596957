import coordinator from "../../img/team/2019/coordinator.jpg";
import hod from "../../img/team/2019/hod.jpg";
import newhod from "../../img/team/2019/newhod.jpeg";
import president from "../../img/team/2024/Arnav_Prasad.JPG";
import vicePresident from "../../img/team/2024/Aditya_Deshmukh.png";
import techLead1 from "../../img/team/2024/Aditya_Gund.png";
import techLead2 from "../../img/team/2024/Prajwal_Bhosale.jpg";
import financeHead2 from "../../img/team/2024/Isha_Khutafale.jpg";
import financeHead1 from "../../img/team/2024/Khushi_Pattanshetti.jpeg";
import eventHead1 from "../../img/team/2024/Suyog_Arlimar.png";
import eventHead2 from "../../img/team/2024/Sarthak_Pawar_2.jpg";
import creativesHead1 from "../../img/team/2024/Atharva_Sardesai_2.jpeg";
import creativesHead2 from "../../img/team/2024/Sanchit_Shahare.jpeg";

const team2024 = [
  {
    post: [
    //   {
    //     initial: "Dr. (Mrs)",
    //     name: "Vahida",
    //     surname: "Attar",
    //     desc: "Former Head, Department of Computer Engineering and Information Technology",
    //     image: hod,
    //     mail: "mailto:vahida.comp@coep.ac.in",
    //     about: "https://www.coep.org.in/mycoep/vahidacompcoepacin",
    //   },
      {
        initial: "Dr.",
        name: "P. K.",
        surname: "Deshmukh",
        desc: "Head, Department of Computer Science and Engineering",
        image: newhod,
        mail: "mailto:hod.comp@coep.ac.in",
        about: "https://www.coep.org.in/departments/computerit/aboutdep",
      },
      {
        initial: "Dr.",
        name: "Amit",
        surname: "Joshi",
        desc: "CSI COEP Tech Student Chapter Coordinator",
        image: coordinator,
        mail: "mailto:adj.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/adjcompcoepacin",
      },
    ],
  },
  {
    post: [
      {
        name: "Arnav",
        surname: "Prasad",
        position: "President",
        image: president,
        linkedin: "https://www.linkedin.com/in/arnav-prasad-82264b296/",
        github: "https://github.com/Arnav2Prasad",
      },
      {
        name: "Aditya",
        surname: "Deshmukh",
        position: "Vice President",
        image: vicePresident,
        linkedin: "http://linkedin.com/in/adityasd314",
        github: "http://github.com/adityasd314",
      },
    ],
  },
  {
    post: [
      {
        name: "Aditya",
        surname: "Gund",
        position: "Technical Head",
        image: techLead1,
        linkedin:
          "https://www.linkedin.com/in/adityagund14/",
        github: "https://github.com/AdityaGund",
      },
      {
        name: "Prajwal",
        surname: "Bhosale",
        position: "Technical Head",
        image: techLead2,
        linkedin: "https://www.linkedin.com/in/prajwal-bhosale-83706a258/",
        github: "https://github.com/PrajwalBhosale2004",
      },
      {
        name: "Khushi",
        surname: "Pattanshetti",
        position: "Finance Head",
        image: financeHead1,
        linkedin: "https://www.linkedin.com/in/khushi-pattanshetti-a9228a246/",
        github: "https://github.com/KhushiPattanshetti",
      },
      {
        name: "Isha",
        surname: "Khutafale",
        position: "Finance Head",
        image: financeHead2,
        linkedin: "http://www.linkedin.com/in/isha-khutafale",
        github: "https://github.com/ishaKhutafale",
      },
      {
        name: "Suyog",
        surname: "Arlimar",
        position: "Events & Publicity Head",
        image: eventHead1,
        linkedin: "http://www.linkedin.com/in/suyog-arlimar-001065290",
        github: "https://github.com/suyog4898",
      },
      {
        name: "Sarthak",
        surname: "Pawar",
        position: "Events & Publicity Head",
        image: eventHead2,
        linkedin: "http://www.linkedin.com/in/sarthak-pawar-268825291",
        github: "https://github.com/sarthakpawa21",
      },
      {
        name: "Atharva",
        surname: "Sardesai",
        position: "Design Head",
        image: creativesHead1,
        linkedin: "https://www.linkedin.com/in/atharva-sardesai-46653a23a",
        github: "https://github.com/AtharvaSardesai1",
      },
      {
        name: "Sanchit",
        surname: "Shahare",
        position: "Design Head",
        image: creativesHead2,
        linkedin: "https://www.linkedin.com/in/sanchit-shahare-81345a2b2/",
        github: "https://github.com/sanchits11",
      },
    ],
  },
];

export default team2024;

// Importing Student Profile pictures
import aayush from "../diaries-info/BTech_data/ayush_shah.jpg";
import akhil from "../diaries-info/BTech_data/akhil_harikumar.png";
import falguni from "../diaries-info/BTech_data/falguni_patil.jpg";
import mayank from "../diaries-info/BTech_data/mayank_jain.jpg";
import akanksha from "../diaries-info/BTech_data/akanksha_mahajan.jpg";
import bhaskar from "../diaries-info/BTech_data/bhaskar_pardeshi.jpg";
import naishadh from "../diaries-info/naishadh_vora.jpg";
import ved from "../diaries-info/ved_dandekar.jpg";
import yash_g from "../diaries-info/yash_gangwar.jpg";

// Importing Company logos
import CrowdstrikeLogo from "../img/company-logos/crowdstrike-logo.png";
import NvidiaLogo from "../img/company-logos/nvidia-logo.png";
import SmartservLogo from "../img/company-logos/smartserv-logo.png";
import GarrettMotionLogo from "../img/company-logos/GarrettMotion-logo.png";
import VeritasLogo from "../img/company-logos/veritas-logo.png";
import VMWareLogo from "../img/company-logos/vmware-logo.png";

// Importing Diaries
import aayush_html from "../diaries-info/BTech_data/aayush_shah_nvidia.js";
import akhil_html from "../diaries-info/BTech_data/akhil_harikumar.js";
import falguni_html from "../diaries-info/BTech_data/falguni_patil_smartserv.js";
import mayank_html from "../diaries-info/BTech_data/mayank_jain_nvidia.js";
import akanksha_html from "../diaries-info/BTech_data/akanksha_mahajan.js";
import ved_html from "../diaries-info/BTech_data/ved_dandekar_vmware.js";
import bhaskar_html from "../diaries-info/BTech_data/bhaskar_pardeshi.js";
import naishadh_html from "../diaries-info/BTech_data/naishadh_vora_vmware.js";
import yash_g_html from "../diaries-info/BTech_data/yash_gangwar_veritas.js";

export const data = [
  //Veritas
  {
    company: "Veritas",
    logo: VeritasLogo,
    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: yash_g,
        firstname: "Yash",
        lastname: "Gangwar",
        html: yash_g_html,
        isNew: false,
        date: {
          day: "28",
          super: "th",
          rest: "June, 2021",

        },
        batch:"2021",
      },
      
    ],
  },

  //VMWare
  {
    company: "VMWare",
    logo: VMWareLogo,
    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: naishadh,
        firstname: "Naishadh",
        lastname: "Vora",
        html: naishadh_html,
        isNew: false,
        date: {
          day: "28",
          super: "th",
          rest: "June, 2021",
        },
        batch:"2021",
      },
      {
        image: ved,
        firstname: "Ved",
        lastname: "Dandekar",
        html: ved_html,
        isNew: false,
        date: {
          day: "28",
          super: "th",
          rest: "June, 2021",
        },
        batch:"2021",
      },
      {
        image: bhaskar,
        firstname: "Bhaskar",
        lastname: "Pardeshi",
        html: bhaskar_html,
        isNew: false,
        date: {
          day: "28",
          super: "th",
          rest: "June, 2021",
        },
        batch:"2021",
      },
    ],
  },

  //Garrett Motion
  {
    company: "GARRETT MOTION",
    logo: GarrettMotionLogo,
    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: akanksha,
        firstname: "Akanksha",
        lastname: "Mahajan",
        html: akanksha_html,
        isNew: false,
        date: {
          day: "28",
          super: "th",
          rest: "June, 2021",
        },
        batch:"2021",
      },
    ],
  },
  //Crowdstrike
  {
    company: "CrowdStrike",
    logo: CrowdstrikeLogo,
    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: akhil,
        firstname: "Akhil",
        lastname: "Harikumar",
        html: akhil_html,
        isNew: false,
        date: {
          day: "21",
          super: "st",
          rest: "June, 2021",
        },
        batch:"2021",
      },
    ],
  },

  //Nvidia
  {
    company: "NVIDIA",
    logo: NvidiaLogo,
    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: mayank,
        firstname: "Mayank",
        lastname: "Jain",
        html: mayank_html,
        isNew: false,
        date: {
          day: "21",
          super: "st",
          rest: "June, 2021",
        },
        batch:"2021",
      },
      {
        image: aayush,
        firstname: "Aayush",
        lastname: "Shah",
        html: aayush_html,
        isNew: false,
        date: {
          day: "21",
          super: "st",
          rest: "June, 2021",
        },
        batch:"2021",
      },
    ],
  },

  //Smartserv
  {
    company: "SmartServ",
    logo: SmartservLogo,
    isUpdated: false,
    batches:["2021"],
    interns: [
      {
        image: falguni,
        firstname: "Falguni",
        lastname: "Patil",
        html: falguni_html,
        isNew: false,
        date: {
          day: "21",
          super: "st",
          rest: "June, 2021",
        },
        batch:"2021",
      },
    ],
  },
];

import React from "react";

const naman_modi = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{ fontSize: "2em", position: "absolute", marginTop: "2rem" }}
          />
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Naman Modi</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Standard Chartered Bank
          </h5>
          <div style={{ color: "grey", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />
          {/* <p> */}
          <p>
            <strong>PLEASE NOTE:</strong> I have answered most of the questions
            about my interview experience with Standard Chartered Bank, but more
            importantly at the end, I have also{" "}
            <em> mentioned about a few points/experiences of my rejection</em>{" "}
            after being selected till the final interview rounds of Goldman
            Sachs, Credit Suisse and Arcesium.{" "}
            <strong>
              <em>
                I would request you to kindly read the important note at the
                end!
              </em>
            </strong>
          </p>
          <br />
          <p>
            <strong style={{ color: "mediumblue" }}>
              Q1. What was the structure of the entire internship hiring
              process?
            </strong>
            <br />
            Standard Chartered had one of the most elaborate and diverse rounds,
            as mentioned here:
          </p>
          <ul style={{ fontSize: "1.2em", color: "black" }}>
            <li>
              Strengths Assessment Round – 5:00PM to 5:30PM – 24th Sept 2019
            </li>
            <li>
              Logical Reasoning Test – 5:40 PM to 6:10 PM – 24th Sept 2019
            </li>
            <li>
              Numerical Reasoning Test – 6:30PM to 7:00PM – 24th Sept 2019
            </li>
            <li>Coding Test – 5:00PM to 6:30 PM – 25th Sept 2019</li>
            <li>PPT/Group Discussion/Interviews – 26th Sept 2019</li>
          </ul>
          <br />
          <p>
            <strong style={{ color: "mediumblue" }}>
              Q2. What questions were asked in the aptitude test?
            </strong>
            <br />
            a. The Strength assessment round was a basic non-elimination round,
            in which few situations were given and asked on how would we react
            on those. <br />
            b. Logical reasoning was an elimination round, which had basic
            questions of letter &amp; symbol series, and asked to complete this.
            It was like general knowledge test. <br />
            c. Numerical Reasoning was an elimination round, with elaborate
            questions on mean/median/mode and many other concepts, and the time
            constraint made it more difficult.
            <br />
            d. The coding test consisted of following parts:
          </p>
          <ul style={{ fontSize: "1.2em", color: "black" }}>
            <li>
              Computer programming MCQs, which covered input/outputs, few
              concepts of networking, OS, DBMS. Time constraint made it
              difficult to solve as only 45 secs per question.
            </li>
            <li>
              One coding question in SQL, which included concepts of “by group”,
              count, aggregate, sort, and mean
            </li>
            <li>One competitive coding question related to string</li>
          </ul>
          {/* </p> */}
          <br />
          <p>
            <strong style={{ color: "mediumblue" }}>
              Q3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </strong>
            <br />
            After all the previous elimination rounds, 36 students were selected
            for the Group Discussion on 26th September.
            <br />3 groups of 12 students each were made, and in each interview
            room, there were 12 students with 3 observers (i.e. company’s hiring
            team). <br />
            <b>GD (Group Discussion)</b> – 5 mins reading time, 20 mins for
            discussion <br />
            The discussion was on: Whether Standard Chartered Bank should
            continue keeping a company in Bangladesh as a client or not? (There
            were various sets having various operations/pros/cons of that
            company given to 12 of us) <br />
            <strong>
              <em>Keep in mind the following points in any GD:</em>
            </strong>
          </p>
          <ul style={{ fontSize: "1.2em", color: "black" }}>
            <li>Always be calm, and never be aggressive</li>
            <li>You will get extra points if you start or give a conclusion</li>
            <li>
              Always motivate others to speak, for example if you see someone in
              the group extremely quiet, ask that person’s opinion
            </li>
            <li>
              Try to be factual/statistical when you try to convey a point, with
              proper justification
            </li>
          </ul>
          <br />
          <p>
            Around 20 students were selected for the Personal Interview round,
            in which few had only 1 interview, and few had 2 interviews. I had
            just one interview by a technical lead in the company, so that
            almost 85% on technical stuff, and remaining 15% on general
            questions
            <br />
            <br />
            <b>Personal Interview:</b> <br />
            <b>
              <i>Interviewer</i>
            </b>
            : Hi Naman, tell me something about yourself. <br />(
            <b style={{ color: "mediumblue" }}>TIP:</b> make sure you are
            prepared for this question, and the basic answer structure should be
            you name, background, why COEP, then more onto your academic side,
            projects, something you want to let the interviewer know)
            {/* <br /> */}
            <br />
            <b>
              <i>Me</i>
            </b>
            : Hi, I am Naman, born and bought up in Mumbai………….joined COEP in IT
            stream………. have projects……..have a 5 star profile in problem solving
            on HackerRank………also lead CSI in college.
            {/* <br /> */}
            <br />
            <b>
              <i>Interviewer</i>
            </b>
            : So you said something about 5 star profile, what exactly is it?
            {/* <br /> */}
            <br />
            <b>
              <i>Me</i>
            </b>
            : (He knew about HackerRank, so just explained him about various
            badges and all)
            {/* <br /> */}
            <br />
            <b>
              <i>Interviewer</i>
            </b>
            : Okay, lets see if you can be a 5-star solver now! How many types
            of sorts do you know?
            {/* <br /> */}
            <br />
            <b>
              <i>Me</i>
            </b>
            : The basics ones bubble, selection, insertion and few optimized
            like quick, merge, heap, and count sort.
            {/* <br /> */}
            <br />
            <b>
              <i>Interviewer</i>
            </b>
            : Okay so you said 7 sorts, right?
            {/* <br /> */}
            <br />
            <b>
              <i>Me</i>
            </b>
            : Yes
            {/* <br /> */}
            <br />
            <b>
              <i>Interviewer</i>
            </b>
            : So quickly, using pen and paper can you run through the algorithms
            of ALL OF THESE!
            {/* <br /> */}
            <br />
            <b>
              <i>Me</i>
            </b>
            : Sure! (Pretty confident about bubble, selection, insertion, merge,
            heap. So, did that instantly, was somewhat hesitant about quick and
            count sort, but recollected it and explained the algo and logic of
            all 7 sorts)
            {/* <br /> */}
            <br />
            <b>
              <i>Interviewer</i>
            </b>
            : Great, so are you aware of topological sorting?
            <a href="https://www.geeksforgeeks.org/topological-sorting/">
              (https://www.geeksforgeeks.org/topological-sorting/)
            </a>
            {/* <br /> */}
            <br />
            <b>
              <i>Me</i>
            </b>
            : (Was aware that it was used in Directed Acyclic Graphs, and its
            basic algo, but wasn’t exactly sure, so answered whatever I knew
            about it, and had a brief chat with him , and along with him, WE
            derived the algo) <b style={{ color: "mediumblue" }}>TIP</b>: Keep
            talking to your interviewer, let him/her know what you are thinking.
            {/* <br /> */}
            <br />
            <b>
              <i>Interviewer</i>
            </b>
            : So, as we talked about graphs, could you write basic code for BFS,
            and DFS in a non-directed graph
            {/* <br /> */}
            <br />
            <b>
              <i>Me</i>
            </b>
            : Along with writing the code, I explained every line I was writing,
            and explained the differences and the respective algos they are used
            in (like for minimum spanning tree).
            {/* <br /> */}
            <br />
            <b>
              <i>Interviewer</i>
            </b>
            : So, you have written about “diff” project you have made, can you
            tell me something about it?
            {/* <br /> */}
            <br />
            <b>
              <i>Me</i>
            </b>
            : (Already a story-pitch for my project ready…and covered mainly
            about LCS
            <a href="https://www.geeksforgeeks.org/longest-common-subsequence-dp-4/">
              (https://www.geeksforgeeks.org/longest-common-subsequence-dp-4/)
            </a>
            , so he asked me to explain LCS in detail, and wrote its code and
            explained) <br />
            <br />
            Then he asked about the next ML project I had made regarding Object
            Detection using SSD Caffe framework. <br />
            So, the interviewer asked in detail about the project and ML, like:
            <br />
            1. What is base-variance tradeoff? <br />
            2. What were the various algo’s you considered before selecting SSD?
            (I answered - YOLO, RCNN)
            <br />
            3. Why didn’t you use YOLO, and RCNN? (I talked about the difference
            in layers) <br />
            4. What exactly do you mean by the layers?
            <br />
            5. What about the dataset, what did you use, how did you decide to
            divide into train-validation-test?
            <br /> ML questions went on for a while as I had an internship as
            well as a project on it. He asked few basic questions on SQL joins
            as it was asked in aptitude test, and I had correctly coded it. Then
            he asked me basic puzzle, and the technical questions were over. He
            then asked me a few things about Karate, and CSI, basically what is
            my role in it. This interview went on for approximately 45-50 mins.
          </p>
          {/* </p> */}
          <br />
          <p>
            <strong style={{ color: "mediumblue" }}>
              Q4. How many questions were asked based on the projects and on
              resume?
            </strong>
            <br />
            As you can see above, most of the questions started from my resume,
            that is previous internship, project, other activities etc.
            <br />
            Resume is basically a starter, and then the interview takes course.
          </p>
          <br />
          <p>
            <strong style={{ color: "mediumblue" }}>
              Q5. What are some good resources used by you in your preparation?
            </strong>
            <br />
            For coding preparation: HackerRank, Codechef, and CTCI.
            <br />
            indiabix.com for basic quant questions/aptitude. <br />
            DSA Notes for revising concepts <br />
            Basic OS and Networking from GFG
          </p>
          <br />
          <p>
            <strong style={{ color: "mediumblue" }}>
              Q6.Some tips to crack the coding questions.
            </strong>
            <br />
            A. Understand the question clearly, and do not directly start
            answering it
            <br />
            B. Clarify the question with the interview <br />
            C. Build few sample inputs and outputs of the question if possible
            and verify with the interviewer <br />
            D. It is okay to start with a brute force approach, and then try
            optimizing it <br />
            E. Keep talking to the interviewer about what you are thinking, what
            would your approach be like, as it is effectively said that “Speak
            your code in interviews”
          </p>
          <br />
          <p>
            <strong style={{ color: "mediumblue" }}>
              Q7. If there is anything more you want to add, please add here:
            </strong>
            <br />
            Few questions/topics from my previous interviews:
            <br />
            Arcesium Tech Round 1: No resume, directly started with DSA. Linked
            List questions (similar in CTCI) to Stacks to BSTs and at the end
            about low level architecture. <br />
            Goldman Sachs Round 1, 2, 3 (All technical): Linked Lists, about
            O(1) search and store questions in HashTable, design questions
            related to mesh in networks (i.e. graph questions), various puzzles
            in different rounds.
          </p>
          <br />
          <p>
            <strong style={{ color: "maroon" }}>IMPORTANT NOTE:</strong>
            <br />
            A very important part to be noted during this time when companies
            come for internship is to stay strong mentally as well as
            physically. The first 3 to 4 companies collectively take only 15
            students, and numbers go increasing as days pass by. I was worried
            myself when I made it to final rounds of Arcesium, Goldman Sachs,
            Credit Suisse but got rejected. Its difficult to handle such
            rejections but stay strong and stay hopeful. Once you have completed
            a company’s rounds, try to analyze what went wrong from your side,
            and after the final list is announced, try to ask for feedback from
            the interviewers on where you could improve. Try implementing it for
            the next company, and always go with a positive mindset. Its also
            important to be physically fit as in span of 3 days you might have 4
            aptitude tests, and around 6 interviews. In this phase, please keep
            talking to your friends and families, and remember to help your
            friends once you are done with your internships! Its an extremely
            different experience, and I wish you all the best!
            <br />
          </p>
          <div style={{ color: "grey", fontSize: "1.15em" }}>
            {/* <a href="LinkedIn:https://www.linkedin.com/in/naman-modi/"
        >https://www.linkedin.com/in/naman-modi/</a> */}
            <br />
            <a href="mailto:modind17.it@coep.ac.in">modind17.it@coep.ac.in</a>
          </div>
        </div>
        {/* </div> */}
      </section>
    </div>
  );
};

export default naman_modi;

import React from "react";

const yash_gangwar_veritas = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Yash Gangwar</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Veritas </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ol type="1">
              <li>Aptitude Test</li>
              <li>Technical Interview</li>
              <li>Managerial Interview</li>
              <li>HR Interview</li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            Analytical questions were easier. Just basic logical questions which
            one should be able to answer without any special preparation. There
            were some good theory questions. You’d need good knowledge of
            programming languages as well as theory courses like networking and
            OS to answer them.
            <br />
            Coding questions were somewhat easy. Could solve them without any
            extra preparation.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <i>Aptitude Test</i>
            <br />
            Good - easy aptitude test. Technical questions were a bit tricky.
            Should brush up on OS and networking concepts.
            <br />
            <br />
            <i>Technical Interview</i>
            <br />
            Thoroughly enjoyed this round. Interviewer asked very well versed
            questions about tough technical concepts. Kept asking follow-up
            questions and one needed to know the concepts in detail to be able
            to answer them all. It was okay to tell the interviewer that you
            didn’t know the answer as the questions were really hard. He’d then
            ask me to speculate and guess the answer based on what I already
            knew. Operating system was one course that he wanted to test the
            interviewees on. He also asked basic questions based on my CV like
            the technologies I’m aware of and the projects I had worked on.
            <br />
            <br />
            <i>Managerial Interview </i>
            <br />
            Much easier round. It was kind of a combination of technical and
            managerial interview. Pretty straightforward and simple. Technical
            part was based on mostly what I had on my CV and quite simple
            compared to tech interview.
            <br />
            <br />
            <i>HR Interview</i>
            <br />
            Wasn’t exactly an interview. HR asked me some simple questions about
            my background. He then proceeded to explain the process of hiring
            for Veritas. They were the first company this year that offered
            internship as well as full time offer. He explained how we can opt
            for internship if we don’t get a full time offer. He then asked some
            basic questions about the projects I had worked on and my
            experience.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            They didn’t dive too deep into my resume and projects. My technical
            interviewer just asked about the kind of projects I had worked on
            and technologies I knew. He based his evaluation mostly on the
            technical questions he had asked rather than points written on my
            resume.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />I had been using <a href="https://leetcode.com/">Leetcode</a>{" "}
            for a while to prepare for coding questions in general. It helped
            answering the coding questions for aptitude. Go through your notes
            on OS and CN to be able to answer technical interview questions. I
            read ‘
            <a href="https://drive.google.com/open?id=1zWdVt8aUI5fwUR5Tls3xs8arc_8AZ7mb">
              Cracking the Coding Interview
            </a>{" "}
            - Gayle MacDowell’ for preparation.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Coding questions very particularly easy for Veritas if you’ve
            practiced well. Read the questions well and plan out your approach
            before typing anything as it would save a lot of time you’d
            otherwise spend debugging. Stick to languages that offer multiple
            libraries and know them well allowing you to code faster. I’d
            recommend C++ (as a lot of us already know C) or python.
            <br />
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            It’s a great company if you want to do some good technical work.
            Their interview process makes sure you have good technical knowledge
            to work with them. Check if they are offering a 6 month internship
            which might lead to full time offer when you’re applying. I opted
            for internship instead as it is a great way to learn about the
            working culture and technologies they work with before committing
            for a full time offer.
          </p>
          <i>
            We had a lot trouble getting to know about the college policies
            about the 6 month internship. Veritas was the first company in
            campus to offer one. Get in touch with placement officials and
            understand the college perspective on it.
          </i>
          <br />
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="https://www.linkedin.com/in/yashgangwar/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default yash_gangwar_veritas;

import React from "react";

const vishwajit_kadam = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Vishwajit Vilas Kadam
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            Goldman Sachs had the hiring process divided into an online aptitude
            test on 17 August, followed by interview rounds on 19 August. All
            branches were eligible for the aptitude test. After the aptitude
            test, around 26 students were shortlisted for the interview round.
            There were 2 technical rounds and an additional combined HR and
            technical round for some people. In the end, 7 people were offered
            internships.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              The aptitude test was conducted on Hackerrank. It was divided into
              5 sections: Coding Section, Quantitative Aptitude, General
              Computer Science, Advanced Programming Question Section, Essay
              Section
            </p>
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li>
                The Coding Section had 2 problems. The difficulty was easy to
                medium. The total time given was 30 minutes.
              </li>
              <li>
                Quantitative Aptitude was mathematics-oriented with difficulty
                comparable to JEE Mains Medium difficulty. (But the fact that
                you abandoned Math for 2 years will make you find these
                questions more difficult)
              </li>
              <li>
                Questions in the General Computer Science section were based on
                data structures, Computer Networking, OOPs, and OS. (Both of the
                above sections had a marking scheme of +5/-2)
              </li>
              <li>
                The Advanced Coding Round had a question on graphs and BFS. If
                prepared well, it was solvable in the allotted time of 45
                minutes.
              </li>
              <li>
                In the essay section, we had to write two essays on given topics
                with around 200 – 250 words each. They were to check qualities
                like grit, perseverance, and teamwork. The time limit for the
                same was 15 minutes.{" "}
              </li>
            </ul>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              I had two technical rounds each lasting for 50 minutes to 1 hour.
              Interviews were conducted on Zoom with live coding on Hackerrank's
              CodePair platform.
            </p>
            <ol>
              <li>
                <p>
                  <strong>Round 1:</strong>
                </p>
                <p>
                  This round was focused on Data Structures and Algorithms.
                  After a brief introduction about myself, he discussed loops in
                  linked lists and asked me to write C code to detect and remove
                  the loop. I explained the algorithm and implemented it. In
                  order to understand a missing edge case, he pointed out a test
                  case for which my algorithm wasn’t working. After I made the
                  necessary changes, we moved further. The second question was
                  about searching for an element in a rotated sorted array.
                  Though this is a standard question, I couldn’t recall the
                  approach. So I explained my crude approach as we went through
                  a few test cases. After struggling for some time, he asked me
                  if finding the pivot element would help. This hint helped me
                  come up with a solution. I quickly explained the approach and
                  implemented it. In the third and final question, I had to find
                  the maximum possible profit given the prices of a stock over a
                  time period if we are allowed to buy and sell at most 2 times.
                  He was satisfied with my approach and didn't ask me to write
                  the code.
                </p>
                <p>
                  In the end, he discussed the hobbies and achievements
                  mentioned on my resume. He asked how much time I spend on each
                  of them. In some cases, he even went into technical aspects of
                  that particular hobby, for instance, he asked me how a long
                  castle differs from a short castle and when to prefer one over
                  another.
                </p>
              </li>
              <br />
              <li>
                <p>
                  <strong>Round 2:</strong>
                </p>
                <p>
                  A different panel of interviewers conducted the second round.
                  Unlike the first interview, I was asked to give a brief
                  overview of my projects. I had a project related to Molecular
                  Dynamics Simulation. Another one was about Algorithmic Trading
                  in live markets and the third was the MERN-stack-based web
                  development project. Except for a few questions about the web
                  dev project, mostly we discussed AlgoTrade. I was asked about
                  the challenges in implementation, testing of software before
                  deploying in live markets and concepts of Finance involved in
                  the project like Calendar Spread Strategy.
                </p>
                <p>
                  Next, he asked me questions based on stack-queue and binary
                  trees. This was followed by the Water Jug puzzle. After I
                  solved the puzzle, he asked me in what condition the solution
                  exists. From my experience and what I've heard from others,
                  Goldman Sachs Interviews always include puzzles and if you can
                  solve one, it definitely leaves a good impression. So prepare
                  by solving as many puzzles as you can. You can refer to
                  GeeksForGeeks, they've got a good collection of puzzles. He
                  then asked me a few questions about OOPs, Databases, Computer
                  Networks, and Operating systems.{" "}
                </p>
                <p>
                  To conclude, Unlike Aptitude tests, most of the questions
                  discussed in the interviews were standard problems or their
                  slight variations. The interviewers were helpful and provided
                  hints whenever I got stuck. Interviews can be anxious
                  sometimes but remember to stay calm and answer confidently.
                  Take them through test cases. Explain the logic as you're
                  coding. When thinking about the solution, think aloud, let
                  them know where you are heading so that they can guide you.{" "}
                </p>
              </li>
            </ol>
          </p>
          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              I followed the list of 450 questions shared by CSI called
              Final450. It is the compilation of standard DSA problems. While
              solving the problems, whenever I got stuck, I read the
              corresponding articles on GFG. LeetCode is also a good resource to
              practice a variety of problems. Even if you solve the problem on
              your own, go through the article and top solutions on the
              discussion forum. You'll surely discover another exciting approach
              that you hadn't thought of. For OOPs and other core subjects, I
              referred to GFG. While revising, going through the top 50
              questions of each topic will help in the interviews. (you can get
              multiple such articles on the web, just search top 50 interview
              questions on XYZ).
            </p>
          </p>

          <p>
            <b>5. Some tips to crack the coding questions.</b> <br />
            <p>
              First of all, be good at any one language. Most people use CPP and
              switch to Python whenever relevant. This will make sure you can
              easily convert an algorithm into code and implementation will not
              be an issue. Now the challenging part is devising an algorithm.
              For this, I would suggest you solve as many standard problems as
              possible and also their variations. In aptitude tests, these
              standard problems are rephrased with some long scary stories. Try
              to reduce it to the underlying standard problem, which you can
              easily beat.
            </p>
            <p>
              If you have enough time for preparation, spend time thinking about
              the solution before referring to the solution. Try competitive
              coding on CodeChef and CodeForces. The more time you spend
              thinking, the stronger that muscle becomes!
            </p>
          </p>
          <p>
            <i>
              <ol style={{ marginLeft: "-1rem" }}>
                <li>
                  Similar to every other thing, having a group of good friends
                  who are equally motivated helps a lot. It makes the
                  preparation easier and even more exciting.
                </li>
                <li>
                  Don't stress too much about one thing. Many people think it's
                  necessary to have extraordinary projects on the resume. Having
                  such projects helps, but the time and effort put into the
                  project, how well you know it and how comfortable you are with
                  technologies used hold more importance.
                </li>
                <li>
                  Don't ignore aptitude tests in the preparation stage. Even if
                  you are good at DSA and have impressive projects, you could
                  get rejected from the aptitude test itself because of ignoring
                  that part.
                </li>
                <li>
                  No matter how good you are, you may get rejected by some
                  company. Don't lose hope, prepare for the next company with
                  the same enthusiasm. If you've put in the effort, you'll
                  definitely succeed.
                </li>
              </ol>
            </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:kadamvv19.comp@coep.ac.in">
              kadamvv19.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/vishwajitkadam/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default vishwajit_kadam;

import * as React from "react";
// import Grid from "@mui/material/Grid";
import { useState } from "react";
import "../assets/css/testimonial.css";
import { Button } from "@material-ui/core";
import { Box } from "@mui/material";
// import editorial_2022 from "../assets/js/editorial";
// import PropTypes from 'prop-types';
// import { styled } from '@mui/material/styles';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import IconButton from '@mui/material/IconButton';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { codeQuestParticipants } from "../assets/js/CodeQuestParticipants";
import { InspironParticipants } from "../assets/js/InspironParticipants";
import { PDFDocument, StandardFonts, PDFFont } from "pdf-lib";
import poster from "../assets/img/gallery/Inspiron.png";
import InspironParticipant from "../assets/Certificate/InspironParticipant.pdf";
import { backGround } from "../layout/customStyle";
import Navbar from "../layout/Navbar";
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-outlined": {
      color: "white",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "white",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",
    },
  },
});
export default function InspironCertificates() {
  // const onDownload = (linkName, title) => {
  //     let a = document.createElement("a");
  //     a.href = linkName;
  //     console.log(linkName);
  //     a.setAttribute("download", title);
  //     a.click();
  // };
  const classes = useStyles();
  const [name, setName] = useState("");
  const [devfolio, setDevfolio] = useState("");
  const [team, setTeam] = useState("");
  // const [pdf,setPDF]=useState('')
  async function downloadCertificate() {
    if (name === "" || devfolio === "" || team === "") {
      alert("Please Enter all the values");
    } else {
      var flag = 0;
      var index = 0;
      for (var i = 0; i < InspironParticipants.length; i++) {
        if (
          InspironParticipants[i].devfolio_username.toLowerCase() ===
            devfolio.toLowerCase() &&
          InspironParticipants[i].team.toLowerCase() === team.toLowerCase()
        ) {
          flag = 1;
          index = i;
          break;
        }
      }
      if (flag === 0) {
        alert("The details entered are not correct");
      } else {
        const existingPdfBytes = await fetch(InspironParticipant).then((res) =>
          res.arrayBuffer()
        );
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const TimesRomanBoldItalic = await pdfDoc.embedFont(
          StandardFonts.TimesRomanBoldItalic
        );
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const { width } = firstPage.getSize();
        var n = name.length;
        firstPage.drawText(name, {
          x: width / 2 - 13 * n,
          y: 335,
          size: 50,
          font: TimesRomanBoldItalic,
        });
        const pdfBytes = await pdfDoc.save();
        var blob = new Blob([pdfBytes], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var filename = `${name}_Inspiron2.0_Participation_Certificate.pdf`;
        link.download = filename;
        link.click();
      }
      setName("");
      setTeam("");
      setDevfolio("");
    }
  }

  return (
    <>
      <Navbar event={true}/>
      <div style={backGround}>
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "30px",
            }}
          >
            <div class="card_testimonial">
              <div class="card-content_testimonial">
                <img src={poster} alt="poster" />
                <h4
                  style={{
                    color: "white",
                    textAlign: "left",
                    marginBottom: "30px",
                    marginTop: "30px",
                  }}
                >
                  Enter Your Name
                </h4>
                <TextField
                  className={classes.root}
                  value={name}
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                />
                <h4
                  style={{
                    color: "white",
                    textAlign: "left",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  Enter Your Devfolio Username
                </h4>
                <TextField
                  type="text"
                  className={classes.root}
                  value={devfolio}
                  id="outlined-basic"
                  label="Devfolio Username"
                  variant="outlined"
                  onChange={(e) => setDevfolio(e.target.value)}
                />
                <h4
                  style={{
                    color: "white",
                    textAlign: "left",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  Enter Your Team Name
                </h4>
                <TextField
                  className={classes.root}
                  value={team}
                  id="outlined-basic"
                  label="Team Name"
                  variant="outlined"
                  onChange={(e) => setTeam(e.target.value)}
                />
                <div style={{ marginTop: "30px" }}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#003399", color: "white" }}
                    onClick={downloadCertificate}
                    size="large"
                  >
                    Download
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
}

import React from "react";
import trophy from "../assets/img/Trophy/trophy.png";
import { Typography, Grid, Paper, makeStyles } from "@material-ui/core";
import "../assets/css/leaderboard.css";

const useStyles = makeStyles((theme) => ({
  winners: {
    // fontFamily: "'Varela Round', cursive",
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(26),
    marginBottom: theme.spacing(10),
  },
}));

const Leaderboard = () => {
  const classes = useStyles();
  return (
    <Paper
      style={{
        backgroundColor: "transparent",
        color: "#fff",
        padding: "50px",
      }}
    >
      <Grid item md={7} align="center">
        <img src={trophy} id="trophyup" alt="trophy" />
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        // spacing={3}
        direction="row"
      >
        <Grid item md={4} align="center">
          <Typography
            style={{
              fontSize: "35px",
              textTransform: "uppercase",
              fontWeight: "500",
              marginBottom: "5px",
              // fontFamily: "Gotham",
            }}
          >
            #1
          </Typography>
          {/* <hr className="divider" /> */}
          <Typography className={classes.winners}>
            Shubham Baban Pagire
          </Typography>
          <Typography
            style={{
              fontSize: "35px",
              textTransform: "uppercase",
              fontWeight: "500",
              marginBottom: "5px",
              // fontFamily: "Gotham",
            }}
          >
            #2
          </Typography>
          <Typography className={classes.winners}>
            Syed Mujtaba Jafri
          </Typography>
        </Grid>
        <Grid item md={4}>
          <img src={trophy} id="trophymiddle" alt="trophy" />
        </Grid>
        <Grid item md={4} align="center">
          <Typography
            style={{
              fontSize: "35px",
              textTransform: "uppercase",
              fontWeight: "500",
              marginBottom: "5px",
              // fontFamily: "Gotham",
            }}
          >
            #3
          </Typography>
          {/* <hr className="divider" /> */}
          <Typography className={classes.winners}>Ayush Shah</Typography>
          <Typography
            style={{
              fontSize: "35px",
              textTransform: "uppercase",
              fontWeight: "500",
              marginBottom: "5px",
              // fontFamily: "Gotham",
            }}
          >
            #4
          </Typography>
          <Typography className={classes.winners}>Shreyas Mante</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Leaderboard;

import React from "react";

const umang_sakhare_tally = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Naman Modi</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Veritas </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ol>
              <li>
                The Pre-Placement Talk (PPT) and the online assessment was held
                on 10th September 2020.
              </li>
              <li>
                Interviews:
                <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
                  <li>
                    Technical Round & Managerial Round on 11th September 2020.
                  </li>
                  <li>Behavioural Round on 12th September 2020.</li>
                </ul>
              </li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test was of 1 hour, with a total of 22 questions, of
            the following pattern:
            <br />
            <ol>
              <li>
                Q.1 to Q.19: Multiple Choice Single Correct Questions
                <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
                  <li>Moderate difficulty level</li>
                  <li>
                    Based on C, OS numericals, and theoretical understanding of
                    OS, CN, and DBMS.
                  </li>
                  <li>
                    Output based questions were also asked for code snippets in
                    C.
                  </li>
                  <li>
                    For C, concepts around function pointers, memory layout,
                    then for OS, virtual memory related questions, with
                    numericals on scheduling, and for DBMS, few queries related
                    questions were convered. (This will change from test to
                    test.)
                  </li>
                  <li>No negative markings.</li>
                  <li>
                    No quant related questions. All related to CS Subjects.
                  </li>
                </ul>
              </li>
              <li>Q. 20: Multiple Choice Multiple Correct Questions</li>
              <li>
                Q. 21 to Q.22: Coding questions
                <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
                  <li>
                    <li>First question was easy.</li>
                    <li>
                      Second question was moderate, and based on string
                      manipulation. Only the optimal solution would pass all
                      test cases, otherwise TLE error.
                    </li>
                    <li>All languages allowed.</li>
                  </li>
                </ul>
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            There were 3 interview rounds.
            <br />
            <ol>
              <li>
                Technical Round (1hr 10 mins)
                <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
                  <li>
                    No questions on CV or on projects, directly started with CS
                    questions.
                  </li>
                  <li>
                    Started with basic OOPs questions:
                    <ul style={{ marginLeft: "1rem", listStyleType: "square" }}>
                      <li>
                        Pillar of OOPs, and explanation with real-life examples.
                      </li>
                      <li>
                        With main focus on C++, discussion on virtual base
                        classes, and friend classes and functions.
                      </li>
                      <li>
                        Constructors and deconstructors with specific case of
                        what happens for child classes, etc.
                      </li>
                    </ul>
                  </li>
                  <li>
                    {" "}
                    Memory Layout in C, with examples and uses of different
                    storage classes for various segments.
                  </li>
                  <li>
                    {" "}
                    Examples and differences between structs, unions, enums.
                  </li>
                  <li>
                    {" "}
                    Stages of compilation:
                    <ul style={{ marginLeft: "1rem", listStyleType: "square" }}>
                      <li>
                        Explained verbally, and also screen share and showed on
                        terminal with a sample code.
                      </li>
                      <li>
                        {" "}
                        Difference between late, dynamic, static, and linking,
                        loading.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Operating Systems questions:
                    <ul style={{ marginLeft: "1rem", listStyleType: "square" }}>
                      <li>
                        {" "}
                        Critical Section Problem, with focus on mutex,
                        semaphores (binary and counting). Contiued this
                        discussion on how is it implemented via kernel objects.
                      </li>
                      <li>
                        Multitasking, multiprocessing, multithreading, and
                        threading vs processes.
                      </li>
                      <li>
                        Page faults, Page table, thrashing, TLB, and
                        fragmentation.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Shared the screen for coding questions:
                    <ul style={{ marginLeft: "1rem", listStyleType: "square" }}>
                      <li>
                        Finding the kth largest element in array. Here on{" "}
                        <a href="https://www.geeksforgeeks.org/kth-smallestlargest-element-unsorted-array/">
                          Geeks for Geeks
                        </a>
                      </li>
                      <li>
                        At first, gave basic solution of descending sort and
                        returning the (n-1) index.
                      </li>
                      <li>
                        Discussion revolved on various kind of sorts, with
                        complexities.
                      </li>
                      <li>Wrote code for Merge Sort.</li>
                      <li>
                        For a more optimal solution, suggested we could use
                        maxheap.
                      </li>
                      <li>Explained the working of key via pseudo code.</li>
                      <li>
                        Given a tree, return all elements for a level l. (For
                        example, root is level 0, the child nodes are level 1,
                        and so on.)
                      </li>
                      <li>
                        Its a standard question. Wrote code, using recursive
                        stratergy. (using inorder traversal)
                      </li>
                      <li>
                        Given a tree, return all elements for a width w. (Please
                        check this{" "}
                        <a href="https://leetcode.com/problems/maximum-width-of-binary-tree">
                          link
                        </a>{" "}
                        to undersand width of a binary tree)
                      </li>
                      <li>
                        This question required some time, but continuous
                        discussion, and "loud thinking" with the interviewer
                        helped me come with a solution based on a recursion,
                        similar to previous question.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Managerial Round (1 hr 20 mins)
                <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
                  <li>
                    This was the hiring manager round, where in 85% of time was
                    devoted to technical discussion, and remaining were based on
                    behavioural questions.
                  </li>
                  <li>Started with mine and the interviewer's introduction.</li>
                  <li>
                    Discussion on one of my full-stack projects (related to
                    group video conferencing), with focus on databases, and the
                    choice of the architecture, future enhancements, flaws in
                    the current design etc.
                  </li>
                  <li>
                    Discussion on another project, completely in C language
                    (implementation of linux commands diff/patch), with focus on
                    the algorithms used.
                  </li>
                  <li>
                    System Design of WhatsApp:
                    <ul style={{ marginLeft: "1rem", listStyleType: "square" }}>
                      <li>This discussion went on for around 40 mins.</li>
                      <li>
                        In the first few minutes, I clarified the requirement,
                        had feature discussions, on what are the most critical
                        features we require, etc.
                      </li>
                      <li>
                        Started with basic discussion on one-to-one messaging
                        feature, with focus on data redundancy, database schema,
                        and messaging queues.
                      </li>
                      <li>
                        Continued the discussion for group messaging features.
                      </li>
                      <li>
                        Discussion on how to add features like keeping a count
                        of forwards, and when to fetch the data for receiver
                        side, and corner cases, when the internet is off, etc.
                      </li>
                      <li>
                        Image storage using a media server, and various ways for
                        implementing this.
                      </li>
                      <li>
                        It was a high-level overview and discussion. All
                        verbally, and I used "virtual whiteboard" to draw the
                        architecture. Nothing to code.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Asked what are the "values" of my life, and examples of when
                    I followed those. Few follow-up questions based on
                    situations for these values. (This was the only behavioural
                    question of this round.)
                  </li>
                  <li>
                    Pointers, mallocs, callocs.
                    <ul style={{ marginLeft: "1rem", listStyleType: "square" }}>
                      <li>
                        Discussion on how malloc is implemented internally
                        (mmap, sbrk), its return values, type casting.
                      </li>
                      <li>
                        Also touched upon more "memory related" system calls
                        like shmget.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Discussion on sizeof() operator.
                    <ul style={{ marginLeft: "1rem", listStyleType: "square" }}>
                      <li>
                        Wrote code to implement sizeof() operator, using
                        template classes, and bitwise manipulation.
                      </li>
                      <li>
                        Discussion went on further on the "lifecycle" of how
                        data like "int x = 4;" stored on the disk, and how it is
                        retrieved etc.
                      </li>
                      <li>This was an intense "low-level" discussion.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Behavioural Round (1hr)
                <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
                  <li>This was mainly a HR round.</li>
                  <li>Started with an introduction.</li>
                  <li>
                    Selected a project on my resume and asked the challenges I
                    overcame, learning out of those, and what could be done
                    better.
                  </li>
                  <li>
                    Had a brief discussion on the co-curricular activites (CSI),
                    my role and learnings from it.
                  </li>
                  <li>
                    Solved 2 puzzles. (
                    <a href="https://www.geeksforgeeks.org/puzzles/">
                      GFG Puzzles
                    </a>
                    )
                  </li>
                  <li>
                    General questions on Why Veritas, and also discussion about
                    my previous internships.
                  </li>
                  <li>
                    Major discussion on role preferences in Veritas (FTE SDE or
                    other roles possibility).
                  </li>
                </ul>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            Placement interviews are different from internship interviews.
            Please prepare for Operating Systems, Computer Networks, Database
            Management System diligently.
          </p>

          <p>
            <b>5. Some tips to crack the coding questions.</b> <br />
            Practice.
          </p>

          <p>
            <b>
              6. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            Veritas had come for 2 roles:
            <br />
            <ol>
              <li>FTE + Intern (only SDE)</li>
              <li>
                Intern (then conversion based on performance later, could be SDE
                or SQA).
              </li>
            </ol>
            It depends on your performance on interviews, and please be clear
            when asked for a choice in the HR round. Veritas focuses a lot on
            OS, so please have more focus on it during your preparation.
          </p>

          <i>
            For those who did not get a PPO, please don't be disheartened. It's
            completely fine. Also be prepared for the question on "What about
            any conversions from previous internships?" <br />
            Usually the placement rounds for good, and core companies are very
            gruelling and tiring - please be prepared for that. <br />
            Please be clear on whether you want to continue in-house B.Tech
            project for Sem 8, or want to take an internship. Have a discussion
            with your B.Tech project guides priorly.
            <br />
          </i>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:modind17.it@coep.ac.in">modind17.it@coep.ac.in</a>
            <br />
            <a href="https://www.linkedin.com/in/naman-modi/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default umang_sakhare_tally;

import React from "react";

const sakshi_mahajan = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Sakshi Mahajan
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Corporation</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The interview consisted of three rounds:                        <br />
                        </p>
                        <p>
                            <ol>
                                <li>Technical round</li>
                                <li>Managerial round</li>
                                <li>HR round</li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Two domains were available for intern roles: Public Banking and Wealth Management (PBWMT) and Institutional Clients Group (ICG). I had chosen ICG as a preference.
                            <br />The aptitude round was conducted on AMCAT platform and was role-specific. For ICG role, the aptitude round consisted of the following four sections:

                            <br />
                        </p>
                        <p>
                            <ol>
                                <li>
                                    Verbal Reasoning<br />
                                    This section was to assess your ability to analyse and comprehend written information, correct grammar, reorder sentences, draw conclusions from the provided text, etc.

                                </li>
                                <li>Quantitative Reasoning<br />
                                    This section tests your ability to solve mathematical and numerical questions based on basic algebra, word problems, percentage and ratio, probability and statistics, etc.
                                </li>
                                <li>Logical Reasoning - <br />
                                    This section consisted of questions like number series, matching definitions, letter and symbol series, making conclusions, etc.
                                </li>
                                <li>Coding Section - <br />
                                    This section consisted of two coding questions which were pretty easy to understand. I had the following two questions:
                                    (i) Given a number in decimal (base 10), convert it to its equivalent binary number (base 2) and return it as a list.
                                    (ii) Given a list of alphabets which consisted of many repeating alphabets, we needed to find such alphabets which only appeared once in the list and return them.
                                </li>
                            </ol></p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were three rounds for the interview process:<br />
                        <ol>
                            <li>Technical Round: <br />
                                This round was completely resume based and lasted for about 25-30 minutes. It started off with my introduction and then the interviewer asked me about the various projects that I had listed in my resume. He asked me to draw and explain the structure and workflow of one of my projects (DSA) and then asked counter questions on it like why this data structure, what was the inspiration for this project idea, what are some other alternative data structures that can be used to optimise the time complexity, etc and some basic DSA related concepts. After that, he gave me an example of a database and asked me to write 2-3 basic SQL queries on the given tables which mostly revolved around primary key and foreign key concepts and also asked about normalization concept. Make sure to tell the interviewer your thought process to approach the query and also tell them alternative ways to write a single query. He then asked me about some basic OOPs concepts and also about the various technologies I was familiar with (frontend and backend). Towards the end, the interviewer asked me if I had any questions for him. Do ask questions when you are given the opportunity to as it reflects your interest in the company.
                            </li>
                            <li>Managerial Round: <br />
                                This was a short one-to-one interaction round which was mostly focused on the communication skills and thought process. The interviewer started by asking me how I thought I performed in the first round and what were my emotions throughout the day. He asked some general questions like why do you want to join Citi, how would you make your parents proud, what are your soft skills, etc. This round lasted for around 10 minutes.
                            </li>
                            <li>HR Round: <br />
                                This was the last round and lasted for around 10-15 minutes. It started with my introduction and then the interviewer asked me what I expected out of this internship and other general HR questions like why do you want to join Citi, where do you see yourself in 5 years, what are your goals, etc and then asked me if I had any questions for him.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After Aptitude, 39 students for ICG and 15 students for PBWMT were shortlisted for the interview.
                        <br />After the 1st technical round, around 20 students made it to the managerial round. Out of which, 12 students from ICG were shortlisted for the HR round.
                        <br />Final internship offer was given to 13 students in total, 10 students for ICG and 3 students for PBWMT.

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                For Aptitude: <a href="https://www.indiabix.com/">IndiaBix</a>
                            </li>
                            <li>
                                DSA: <a href="https://takeuforward.org/strivers-a2z-dsa-course/strivers-a2z-dsa-course-sheet-2/#">Striver’s A2Z DSA course/sheet</a> and Luv Babbar’s 450 DSA sheet.                            </li>
                            <li>
                                Coding: Leetcode, GFG, HackerRank
                            </li>
                            <li>
                                DBMS: <a href="https://www.youtube.com/playlist?list=PLxCzCOWd7aiFAN6I8CuViBuCdJgiOkT2Y">Gate Smashers</a> for clearing concepts.
                            </li>
                            <li>
                                For basic core concepts like CN and OS: GFG last minute notes and <a href="https://takeuforward.org/interviews/must-do-questions-for-dbms-cn-os-interviews-sde-core-sheet/">Striver’s Interview Prep CS sheet</a>.
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Start as early as possible. Try to follow a well-defined DSA roadmap. Study one topic at a time rather than overwhelming yourself with the entire load of topics. As you learn a topic, try solving enough questions on that topic and when you feel confident enough, then move to the next topic. Remember, practising regularly is the key to cracking coding questions.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            A lot of questions were asked on my projects in the technical round which revolved around the tech-stack that I had used. As in my case, I was not asked any questions regarding clubs and extracurricular activities but most of my friends were asked about their roles and responsibilities in the clubs that they had mentioned on their resume.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Research enough about the company and the role that you are applying for. Do attend the pre-placement talk as it will give you insights about the company. And most importantly, be confident about yourself and focus on developing your overall personality along with strong foundations in academics.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            The internship process is quite overwhelming from the very start. Do not lose hope when you might not get an opportunity in the first few tries. Keep working hard and learn from your downfalls, remember to maintain your perspective and always believe in yourself. Know that what is meant for you will find its way to you ! All the best ! :)
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:sakshim22.comp@coeptech.ac.in">
                            sakshim22.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default sakshi_mahajan;

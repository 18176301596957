import React from "react";

const nimit_jain = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Nimit Jain</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b id="part1">
              1. What was the structure of the entire interview process?
            </b>
            <br />
            <ol>
              <li>
                <p>
                  There were 4 rounds from which the latter 3 happened on the
                  same day.
                </p>
              </li>
              <li>
                <p>
                  Form Filling and Resume Selection – The form asked about
                  yourself and some academic details – about 50 are shortlisted.
                </p>
              </li>
              <li>
                <p>
                  Group Discussion Round – Topics were given beforehand, we had
                  to discuss on any one topic decided by the invigilator. They
                  also ask us about our Personal Opinions on any one of those
                  topics. – 25 were shortlisted from here.
                </p>
              </li>
              <li>
                <p>
                  Pen-Paper (Coding Round) - They gave two easy coding
                  questions, but we had to write them on paper, which we had to
                  mail them while waiting for our interviews.
                </p>
              </li>
              <li>
                <p>
                  Interview – Happened along with the pen-paper round. I was
                  asked no technical question. I was only asked about myself, a
                  small puzzle and what I knew about the company(pay attention
                  on ppts or look up about the company beforehand)
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <ol>
              <li>
                <p>No Aptitude test for FinIQ.</p>
              </li>
              <li>
                <p>For other companies, they may have similar styles.</p>
              </li>
              <li>
                <p>
                  Quantitative; English Section; Technical MCQs based on C, OS,
                  DBMS, DSA, OOPS; Coding section (generally 1-2 easy questions
                  and 1 difficult question).
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              <a
                href="#part1"
                style={{ color: "#000", textDecoration: "underline" }}
              >
                Already mentioned above in part 1.
              </a>
            </p>
          </p>

          <p>
            <b>4. How many questions were asked based on the projects done?</b>
            <ol>
              <li>
                <p>
                  None in this interview but can be asked in other interviews.
                  Be prepared to answer anything related with your project.
                </p>
              </li>
              <li>
                <p>
                  A suggestion would be to only mention the projects which you
                  are confident about (in your resume) – they will mainly ask
                  about these projects only.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <ol>
              <li>
                <p>
                  For any company it is important to be confident and speak in
                  English. I would suggest preparing yourself to answer some
                  generic questions which can be expected in any interview e.g.
                  “Tell me about yourself.”
                </p>
              </li>
              <li>
                <p>
                  Be prepared to answer some technical questions based on DSA
                  (as it is probably the only core CS subject you have studied
                  so far), so make sure your DSA concepts are solid.
                </p>
              </li>
              <li>
                <p>
                  Please note, you may need to prepare on some topics like DBMS
                  and OS as they can ask questions on these topics in the
                  aptitude rounds.
                </p>
              </li>
              <li>
                <p>
                  For coding practice, I recommend practicing the company
                  question on GFG-GeeksforGeeks. You can also practice on
                  leetcode, hackerank etc.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Though no such coding round was conducted by FinIQ. However, I
              managed to clear the aptitude rounds for Goldman Sachs and
              Arcesium with a few tips from my seniors.
            </p>
            <p>Read and understand the question, the input and the output.</p>
            <p>
              (Make sure to submit whatever code you have written in well within
              the time limit else it won’t be counted, also make sure you only
              submit the code you are confident about in case of multiple
              submissions)
            </p>
            <p>
              When unsure how to solve a question, try and move on the next one
              (if there is) otherwise try to thing of some base cases with which
              you can pass some test cases.
            </p>
            <p>
              Practice company questions on GFG, stick to 2 or 3 programming
              languages you feel comfortable with, its better to be comfortable
              with at least 2 so you have a backup in case on language isn’t
              available.
            </p>
            <p>
              During the coding interviews, (where they will ask you to code and
              solve a problem in front of them), make sure you vocalise your
              thoughts. Look out for any hints the interviewers give if you are
              stuck.
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            Though it may feel disheartening that you don’t get select for some
            company that you really wanted to join, its important to try and
            remain calm and do your best in your next tests and interviews
            ahead. Carry your previous experience Also, if you (are confident on
            cracking the interviews) be a bit selective while choosing which
            company you would like to join, once cleared all rounds, and
            accepted the letter, you cannot give further interviews. Wishing
            everyone all the best!
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            {
              <a href="mailto:jainn19.comp@coep.ac.in">
                jainn19.comp@coep.ac.in
              </a>
            }
            <br />
          </div>

          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default nimit_jain;

import React from "react";

const sakshi_pawar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
          Sakshi Bapu Pawar
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
          FinIQ
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
            The basic workflow was as follows:
            <ol style={{marginLeft:"2rem"}}>
                <li>Resume shortlisting </li>
                <li>Group discussion</li>
                <li>Paper Pen Assignment</li>
                <li>Technical Interview </li>
                <li>HR interview</li>

            </ol>
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
            There was no aptitude test. 
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
           <p>There were a total of 4 rounds. In the first round they sent a separate resume(excel sheet) format to fill. The format contained academic details, personal details, and some personal response questions like, what do you think is the country’s biggest problem today, what are your strengths and weaknesses etc. The shortlisting was done based on this for the 2nd round.</p>
           <p>The second round was group discussion. Here they sent around 6-7 topics via mail the day before the interview. Most topics were based on social issues and India will benefit more from what. This allowed us to prepare and practice about these subjects in advance. You may get any of these topics for GD. The topic which I got for GD was “One year Military service/education before Graduation, is it essential for Indians?”. In the mail they shared what to be discussed in GD. Like, 25 seconds per head were given for non resume listed content about you (NR), 8 minutes in all, group discussion, aggression is fine (GD), 20 seconds per head, personal opinion, speak for yourself, not a GD (PO), 30 second per head, Why round, explain one item from resume in terms of Why you did it (WH). The total time for this was about 30 minutes. The shortlisting was done from this for the next round.</p>
           <p>In the paper pen assignment, they gave 2 coding questions and we had to write it’s solution on paper. The full code was not expected here but the algorithm and the solution you choose to solve the question was tested. This round was about 150 minutes long and after completion we had to mail our solutions to them. The questions were based on graph, 2D arrays. One which I can remember was to print the periodic table. And the second one was to read data from a file, store it in a data structure and print it. The data in the file was scores of 5 teams against each other. </p>
           <p>The next round was a combined technical + HR round. Here they asked me to tell them about myself. (They expect you to tell them what is not mentioned in your resume.) Then they asked me to write code in the notepad for the above questions(paper pen assignment questions). They asked what can be some different approaches to solve those questions. Why you choose this approach etc. Here they asked more questions on the algorithm I wrote in the previous round.

</p>
          </p>

          <p>
            <b>4. How many questions were asked based on the projects done?</b>
            <br />
          <p>
There were no questions asked about projects. Very few questions were asked based on the resume. 
</p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            
           <p>I referred to HackerRank/LeetCode for solving coding questions. GeeksForGeeks is a very good resource to use for preparation. One can easily get access to company based (coding/interview ) questions. Also I would like to add that there are many channels on youtube which provide a great source for learning, I referred to them for revisiting some DSA concepts.

</p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> 
            <p>
            Practice is the key to crack coding questions. The more you practice the more it gets easier. It may happen that you are able to solve questions but you need to optimize the solution to pass all the test cases. So choose coding language accordingly. Always read the question properly. Some questions may look bigger but if you read carefully their solutions might be easier. And be consistent in solving the questions.


            </p>
          </p>

         
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:pawarsb20.comp@coep.ac.in">
            pawarsb20.comp@coep.ac.in
            </a>
          
          </div>
        </div>
      </section>
    </div>
  );
};
export default sakshi_pawar;

import React from "react";

const onkar_wagh = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Onkar Wagh
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>mastercard</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The interview process consisted of three rounds: two technical rounds followed by an HR round.                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude test was conducted on the HirePro platform on September 3rd. In contrast to other companies, this assessment consisted of only two coding questions:

                            <br />
                        </p>
                        <p>
                            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                <li>NLP : The task was to ensure that all the alphabets appear at least once in the given string</li>
                                <li>For a journey from source to destination, distances between intermediate petrol stations and prices at different stations were provided. The objective was to find the minimum cost of petrol required. (I don’t recall the exact question in detail, but it involved considering a lot of conditions.)
                                </li>
                            </ul></p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>Technical Round 1: <br />
                                In this round, I was questioned about Data Structures, Database Management System, and my projects.
                                For Data Structures, I was asked about Trees and LinkedLists. Regarding Database Management Systems, I answered questions about joins, normalization and  its types. Additionally, I was given a table and was asked to normalize it comprehensively.
                                About my projects, he asked about specific choices of technologies and everything mentioned in my resume.
                            </li>
                            <li>Technical Round 2: <br />
                                This round focused entirely on software development and deployment.
                                I was asked about Java constructs like Garbage Collection, dynamic memory allocation/deallocation, and how the detection of full memory space is managed.
                                Additionally, we discussed the architecture behind software development and deployment. Topics included in this discussion were servers utilised at each stage, potential threats to data, and the role of proxy servers.
                            </li>
                            <li>HR Round: <br />
                                The HR round was relaxed. I commenced with an introduction of myself. Subsequently, I was asked about  my family background, short-term and long-term goals, and the qualities I possess that others would appreciate. This round consisted of the standard questions one can expect from an HR interview.
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After the aptitude test, around 60 students were shortlisted. Following the first technical round, the count reduced to 30. After the second technical round, approximately 20 were shortlisted for the HR round. Finally, 13 students were offered internships.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                Aptitude: Indiabix
                            </li>
                            <li>
                                Puzzles and Concepts: Geeks for Geeks                            </li>
                            <li>
                                Data Structures and Algorithms (DSA): Striver’s Course and Love Babbar's SDE Sheet                            </li>
                            <li>
                                Coding Practice: Leetcode and Geeks for Geeks                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Follow a well-structured DSA (Data Structures and Algorithms) course to gain a comprehensive understanding of each concept. It is essential to practice a sufficient number of questions in order to recognize patterns and effectively solve problems.                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            Regarding projects, I was only asked to explain the project and the tech stack used in the first technical round. As for extracurricular activities, I was extensively questioned about them during the HR round.  </p>
                    </p>
                    <br />

                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            This internship process can be tough and sometimes not very motivating. It's because there are usually only a few spots available, so you might feel a lot of pressure to get one. But even though it's tough, it's important to keep going, learn as much as you can, and grow from the experience.                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:waghos22.comp@coeptech.ac.in">
                            waghos22.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default onkar_wagh;

// Importing Student Profile pictures
import apurva_haspe from "../diaries-info/TyDiaries_2024/Apurva_Haspe.jpg"
import snehasish_bose from "../diaries-info/TyDiaries_2024/Snehasish_Bose.jpg"
import shruti_ravichandran from "../diaries-info/TyDiaries_2024/Shruti_Ravichandran.jpg"
import shreya_bhatkhande from "../diaries-info/TyDiaries_2024/Shreya_Bhatkhande.jpg"
import sharvari_phand from "../diaries-info/TyDiaries_2024/Sharvari_Phand.jpg"
import samarth_kanwate from "../diaries-info/TyDiaries_2024/Samarth_Kanwate.jpg"
import parth_mane from "../diaries-info/TyDiaries_2024/Parth_Mane.jpg"
import onkar_wagh from "../diaries-info/TyDiaries_2024/Onkar_Wagh.jpg"
import himanshu_kamdi from "../diaries-info/TyDiaries_2024/Himanshu_Kamdi.jpg"
import soham_kumthekar from "../diaries-info/TyDiaries_2024/Soham_Kumthekar.jpg"
import sanika_kulkarni from "../diaries-info/TyDiaries_2024/Sanika_Kulkarni.jpg"
import sakshi_mahajan from "../diaries-info/TyDiaries_2024/Sakshi_Mahajan.jpg"
import rujuta_budke from "../diaries-info/TyDiaries_2024/Rujuta_Budke.jpg"
import bhagyashree_patil from "../diaries-info/TyDiaries_2024/Bhagyashree_Patil.jpg"
import arsh_maknojia from "../diaries-info/TyDiaries_2024/Arsh_Maknojia.jpg"
import anushka_more from "../diaries-info/TyDiaries_2024/Anushka_More.jpg"
import utkarsha_gore from "../diaries-info/TyDiaries_2024/Utkarsha_Gore.jpg"
import shriya_kale from "../diaries-info/TyDiaries_2024/Shriya_Kale.jpg"
import sarvesh_mankar from "../diaries-info/TyDiaries_2024/Sarvesh_Mankar.jpg"
import ketan_kale from "../diaries-info/TyDiaries_2024/Ketan_Kale.jpg"
import vaishnavi_badgujar from "../diaries-info/TyDiaries_2024/Vaishnavi_Badgujar.jpg"
import chinmay_sheth from "../diaries-info/TyDiaries_2024/Chinmay_Sheth.jpg"
import aditya_choudhary from "../diaries-info/TyDiaries_2024/Aditya_Choudhary.jpg"
import vaishnavi_mulik from "../diaries-info/TyDiaries_2024/Vaishnavi_Mulik.jpg"
import shreeya_ganji from "../diaries-info/TyDiaries_2024/Shreeya_Ganji.jpg"
import sanchit_rajmane from "../diaries-info/TyDiaries_2024/Sanchit_Rajmane.jpg"
import vedang_khedekar from "../diaries-info/TyDiaries_2024/Vedang_Khedekar.jpg"
import aman_sayyad from "../diaries-info/TyDiaries_2024/Aman_Sayyad.jpg"
import rukmini_nazre from "../diaries-info/TyDiaries_2024/Rukmini_Nazre.jpg"
import nachiket_deshmukh from "../diaries-info/TyDiaries_2024/Nachiket_Deshmukh.jpg"
import shirshak_tiple from "../diaries-info/TyDiaries_2024/Shirshak_Tiple.jpg"
import gaurish_dodke from "../diaries-info/TyDiaries_2024/Gaurish_Dodke.jpg"
import shubham_gupta from "../diaries-info/TyDiaries_2024/Shubham_Gupta.jpg"
import harsh_marke from "../diaries-info/TyDiaries_2024/Harsh_Marke.jpg"
import nikhil_kokale from "../diaries-info/TyDiaries_2024/Nikhil_Kokale.jpg"
import soham_naigaonkar from "../diaries-info/TyDiaries_2024/Soham_Naigaonkar.jpg"
import soham_dombale from "../diaries-info/TyDiaries_2024/Soham_Dombale.jpg"
import sandesh_sahane from "../diaries-info/TyDiaries_2024/Sandesh_Sahane.jpg"
import renuka_padalkar from "../diaries-info/TyDiaries_2024/Renuka_Padalkar.jpg"
import parth_yewale from "../diaries-info/TyDiaries_2024/Parth_Yewale.jpg"
import sohel_bargir from "../diaries-info/TyDiaries_2024/Sohel_Bargir.png"
import avdhut_kamble from "../diaries-info/TyDiaries_2024/Avdhut_Kamble.jpg"
import vadiraj_joshi from "../diaries-info/TyDiaries_2024/Vadiraj_Joshi.jpg"
import simran_veer from "../diaries-info/TyDiaries_2024/Simran_Veer.jpg"
import preet_trivedi from "../diaries-info/TyDiaries_2024/Preet_Trivedi.jpg"
import omkar_oak from "../diaries-info/TyDiaries_2024/Omkar_Oak.jpg"
import omkar_nagwekar from "../diaries-info/TyDiaries_2024/Omkar_Nagwekar.jpg"
import harshwardhan_deshmukh from "../diaries-info/TyDiaries_2024/Harshwardhan_Deshmukh.jpg"
import atharva_satav from "../diaries-info/TyDiaries_2024/Atharva_Satav.jpg"
import anuksha_koul from "../diaries-info/TyDiaries_2024/Anuksha_Koul.jpg"
import aditya_raskar from "../diaries-info/TyDiaries_2024/Aditya_Raskar.jpg"
import abhinav_kurule from "../diaries-info/TyDiaries_2024/Abhinav_Kurule.jpg"
import kaustubh_virkar from "../diaries-info/TyDiaries_2024/Kaustubh_Virkar.jpg"
import anagha_mahure from "../diaries-info/TyDiaries_2024/Anagha_Mahure.jpg"
import anvay_joshi from "../diaries-info/TyDiaries_2024/Anvay_Joshi.jpg"
import atharva_mutsaddi from "../diaries-info/TyDiaries_2024/Atharva_Mutsaddi.jpg"
import ayush_ambhorkar from "../diaries-info/TyDiaries_2024/Ayush_Ambhorkar.jpg"
import jia_johnson from "../diaries-info/TyDiaries_2024/Jia_Johnson.jpg"
import pratyay_dhond from "../diaries-info/TyDiaries_2024/Pratyay_Dhond.jpeg";
import soham_gupte from "../diaries-info/TyDiaries_2024/soham_gupte.jpg";
import rohit_shidid from "../diaries-info/TyDiaries_2023/rohit_shidid.jpeg";
import harshmohan_kulkarni from "../diaries-info/TyDiaries_2023/harshmohan_kulkarni.jpeg";
import harsh_pande from "../diaries-info/TyDiaries_2023/Harsh Pande.jpg";
import vedanshi_shah from "../diaries-info/TyDiaries_2023/Vedanshi Shah.jpg";
import sayali_mate from "../diaries-info/TyDiaries_2023/Sayali Mate.jpeg";
import saurabh_jog from "../diaries-info/TyDiaries_2023/saurabh_jog.jpg";
import rohit_magar from "../diaries-info/TyDiaries_2023/rohit_magar.jpg";
import advait_karmalkar from "../diaries-info/TyDiaries_2023/advait_karmalkar.jpg";
import ninad_barve from "../diaries-info/TyDiaries_2023/Ninad Barve.jpg";
import samruddhi_kamthe from "../diaries-info/TyDiaries_2023/Samruddhi Kamthe.jpeg";
import vaishnavi_pabalkar from "../diaries-info/TyDiaries_2023/Vaishnavi Pabalkar.jpeg";
import quasim_shaikh from "../diaries-info/TyDiaries_2023/quasim_shaikh.png";
import mayur_k from "../diaries-info/TyDiaries_2023/Mayur Kongutte.jpg";
import siddhi_shinde from "../diaries-info/TyDiaries_2023/Siddhi Shinde.jpg";
import chitra_sangle from "../diaries-info/TyDiaries_2023/Chitra Sangle.jpg";
import aditya_naik from "../diaries-info/TyDiaries_2023/Aditya Naik.jpg";
import neha_pawar from "../diaries-info/TyDiaries_2023/neha_pawar.png";
import sehajdeep_singh from "../diaries-info/TyDiaries_2023/sehajdeep_singh.jpg";
import mihika_sanghvi from "../diaries-info/TyDiaries_2023/mihika_sanghvi.jpg";
import rachana_gade from "../diaries-info/TyDiaries_2023/rachana_gade.jpg";
import shitij_agrawal from "../diaries-info/TyDiaries_2023/Shitij Agrawal.jpg";
import ishan_patwardhan from "../diaries-info/TyDiaries_2023/Ishan Patwardhan.png";
import ritesh_chaudhari from "../diaries-info/TyDiaries_2023/Ritesh Chaudhari.jpeg";
import nidhi_patel from "../diaries-info/TyDiaries_2023/nidhi_patel.jpg";
import sneha_venk from "../diaries-info/TyDiaries_2023/sneha_venk.png";
import atharva_muglikar from "../diaries-info/TyDiaries_2023/atharva_muglikar.jpeg";
import harshal_kausadikar from "../diaries-info/TyDiaries_2023/harshal_kausadikar.jpg";
import anushka_naik from "../diaries-info/TyDiaries_2023/anushka_naik.jpg";
import mihika_dravid from "../diaries-info/TyDiaries_2023/Mihika Dravid.jpg";
import shyam_aradhye from "../diaries-info/TyDiaries_2023/Shyam Aradhye.png";
import christy_biju from "../diaries-info/TyDiaries_2023/christy_biju.png";
import om_khare from "../diaries-info/TyDiaries_2023/om_khare.jpeg";
import pratik_patil from "../diaries-info/TyDiaries_2023/Pratik Patil.jpg";
import yash_suryawanshi from "../diaries-info/TyDiaries_2023/yash_suryawanshi.jpg";
import shubham_gandhi from "../diaries-info/TyDiaries_2023/shubham_gandhi.png";
import anvita_mahajan from "../diaries-info/TyDiaries_2023/anvita_mahajan.jpg";
import yash_chandak from "../diaries-info/TyDiaries_2023/Yash Chandak.jpg";
import anish_deshpande from "../diaries-info/TyDiaries_2023/Anish Deshpande.jpg";
import prerna_tulsiani from "../diaries-info/TyDiaries_2023/Prerna Tulsiani.jpg";
import dhanashree_mahadeo from "../diaries-info/TyDiaries_2023/dhanashree_mahadeo.jpg";
import deepika from "../diaries-info/deepika_goyal.jpeg";
import vasu from "../diaries-info/vasu_sharma.jpeg";
import rhugaved from "../diaries-info/rhugaved_narmade.jpeg";
import soumitra from "../diaries-info/soumitra_tonapi.jpeg";
import jinit from "../diaries-info/jinit_sanghvi.jpg";
import abhinav from "../diaries-info/abhinav_roy.jpeg";
import ankita from "../diaries-info/ankita_vaid.jpg";
import mayank from "../diaries-info/mayank_jain.jpg";
import naman from "../diaries-info/naman_modi.jpg";
import yash_shah from "../diaries-info/yash_shah.png";
import shreya from "../diaries-info/shreya_singhal.jpg";
import omkar from "../diaries-info/omkar_thorat.jpg";
import yash_g from "../diaries-info/yash_gangwar.jpg";
import savan from "../diaries-info/savan_nahar.jpg";
import falguni from "../diaries-info/falguni_patil.jpg";
import naishadh from "../diaries-info/naishadh_vora.jpg";
import aayush from "../diaries-info/ayush_shah.jpg";
import esha from "../diaries-info/eesha_kurode.jpg";
import umang from "../diaries-info/umang_sakhare.jpg";
import manas from "../diaries-info/manas_nighrunkar.jpg";
import chinmay from "../diaries-info/chinmay_dixit.jpg";
import harshal from "../diaries-info/harshal_patil.jpg";
import rohit from "../diaries-info/rohit_choudhari.jpeg";
import mugdha from "../diaries-info/mugdha_bapat.jpg";
import aniket from "../diaries-info/aniket_jayateerth.jpg";
import anup from "../diaries-info/anup_nair.jpg";
import chetas from "../diaries-info/chetas_borse.jpg";
import soham from "../diaries-info/soham_parekh.jpg";
import swebert from "../diaries-info/swebert_correa.jpg";
import viraj from "../diaries-info/viraj_jadhav.jpg";
import vrinda from "../diaries-info/vrinda_ahuja.jpeg";
import atharv from "../diaries-info/atharva_phand.jpg";
import gaurav from "../diaries-info/gaurav_wawdhane.jpg";
import arya from "../diaries-info/arya_gandhi.jpg";
import simran from "../diaries-info/simran_kucheria.jpeg";
import hrishikesh from "../diaries-info/hrishikesh_athalye.png";
import muskan from "../diaries-info/muskan_paryani.png";
import viren from "../diaries-info/viren_patil.jpg";
import vasvi from "../diaries-info/vasvi_gupta.jpg";
import pallavi_buwa from "../diaries-info/TyDiaries_2022/pallavi_buwa.jpg";
import anjali_dofe from "../diaries-info/TyDiaries_2022/anjali_dofe.jpg";
import prajakta_ghatage from "../diaries-info/TyDiaries_2022/prajakta_ghatage.jpeg";
import nimit_jain from "../diaries-info/TyDiaries_2022/nimit_jain.jpg";
import atharva_marathe from "../diaries-info/TyDiaries_2022/atharva_marathe.jpg";
import ritik from "../diaries-info/TyDiaries_2022/ritik_naik.jpg";
import vishwesh from "../diaries-info/TyDiaries_2022/vishwesh_pujari.jpg";
import riddhi from "../diaries-info/TyDiaries_2022/riddhi_tharewal.jpg";
import aditya from "../diaries-info/TyDiaries_2022/aditya_verma.png";
import harish from "../diaries-info/TyDiaries_2022/harish_choudhary.jpg";
import sayali_patil from "../diaries-info/TyDiaries_2022/sayali_patil.jpg";
import archisha_shukla from "../diaries-info/TyDiaries_2022/archisha_shukla.png";
import anushka_tadphale from "../diaries-info/TyDiaries_2022/anushka_tadphale.jpg";
import kartik_mandhan from "../diaries-info/TyDiaries_2022/kartik_mandhan.jpg";
import apurva_deshpande from "../diaries-info/TyDiaries_2022/apurva_deshpande.png";
import nikita_tipule from "../diaries-info/TyDiaries_2022/nikita_tipule.png";
import darshan_shah from "../diaries-info/TyDiaries_2022/darshan_shah.jpg";
import tanmayee from "../diaries-info/TyDiaries_2022/tanmayee.jpg"
import ketaki_jadhav from "../diaries-info/TyDiaries_2022/ketaki_jadhav.jpg";
import jayesh from "../diaries-info/TyDiaries_2022/jayesh.jpg";
import sameer_kavthekar from "../diaries-info/TyDiaries_2022/sameer_kavthekar.jpg";
import aman_patil from "../diaries-info/TyDiaries_2022/aman_patil.jpg";
import sanmeet from "../diaries-info/TyDiaries_2022/sanmeet.jpg";

import ashutosh_pardeshi from "../diaries-info/TyDiaries_2022/ashutosh_pardeshi.png";
import kunal_chaudhari from "../diaries-info/TyDiaries_2022/kunal_chaudhari.png";
import vishwajit_kadam from "../diaries-info/TyDiaries_2022/vishwajit_kadam.jpg";
import avishkar_andhale from "../diaries-info/TyDiaries_2022/avishkar_andhale.jpg";
import mudit_bapna from "../diaries-info/TyDiaries_2022/mudit_bapna.jpg";
import purav_desai from "../diaries-info/TyDiaries_2022/purav_desai.jpg"
import sakshi_pawar from "../diaries-info/TyDiaries_2022/sakshi_pawar.jpg";
import siya_dhokane from "../diaries-info/TyDiaries_2022/siya_dhokane.jpg";
import shreya_vaidya from "../diaries-info/TyDiaries_2022/shreya_vaidya.jpg";
import tanmay_patil from "../diaries-info/TyDiaries_2022/tanmay_patil.png";
// import ved from "../diaries-info/ved_dandekar.jpg";

// Importing Company logos
import CarrotSoftlogo from "../img/company-logos/CarrotSoft-logo.png";
import Siemenslogo from "../img/company-logos/Siemens-logo.png"
import Algoquantlogo from "../img/company-logos/Algoquant-logo.png"
import bplogo from "../img/company-logos/british_petroleum_logo.png"
import MarvellLogo from "../img/company-logos/Marvell-logo.png"
import TradingLogo from "../img/company-logos/Trading-logo.png"
import PatternLogo from "../img/company-logos/pattern-logo.png"
import HiltiLogo from "../img/company-logos/hilti.jpg";
import BarclaysLogo from "../img/company-logos/barclays-logo.png";
import DEShawLogo from "../img/company-logos/DEShaw-logo.png";
import ArcesiumLogo from "../img/company-logos/Arcesium-logo.png";
import GoldmanSachsLogo from "../img/company-logos/GoldmanSachs-logo.png";
import WoltersKluwerLogo from "../img/company-logos/WoltersKluwer-logo.png";
import StandardCharteredLogo from "../img/company-logos/StandardChartered-logo.png";
import CreditSuisseLogo from "../img/company-logos/CreditSuisse-logo.png";
import FinIQLogo from "../img/company-logos/FinIQ-logo.png";
import MastercardLogo from "../img/company-logos/Mastercard-logo.png";
import QuestionProLogo from "../img/company-logos/QuestionPro-logo.png";
import GarrettMotionLogo from "../img/company-logos/GarrettMotion-logo.png";
import eQLogo from "../img/company-logos/eQ-logo.png";
import NuanceLogo from "../img/company-logos/Nuance-logo.png";
import CitiLogo from "../img/company-logos/citi-logo.png";
import GoogleLogo from "../img/company-logos/google-logo.png";
import SchlumbergerLogo from "../img/company-logos/Schlumberger-logo.png";
import DeutscheLogo from "../img/company-logos/deutsche-logo.png";
import Persistent from "../img/company-logos/persistent.png";
import WellsFargo from "../img/company-logos/wells_fargo_logo.png";
import TcsLogo from "../img/company-logos/tcs_logo.png";
import InxiseLogo from "../img/company-logos/Inxise-logo.png";
import Colgate_palmolive from "../img/company-logos/colPalm.png";
import JPMLogo from "../img/company-logos/JPM.png"
import BNYLogo from "../img/company-logos/BNYM.png"



// Importing Diaries
import apurva_haspe_html from "../diaries-info/TyDiaries_2024/apurva_haspe.js"
import snehasish_bose_html from "../diaries-info/TyDiaries_2024/snehasish_bose.js"
import shruti_ravichandran_html from "../diaries-info/TyDiaries_2024/shruti_ravichandran.js"
import shreya_bhatkhande_html from "../diaries-info/TyDiaries_2024/shreya_bhatkhande.js"
import sharvari_phand_html from "../diaries-info/TyDiaries_2024/sharvari_phand.js"
import samarth_kanwate_html from "../diaries-info/TyDiaries_2024/samarth_kanwate.js"
import parth_mane_html from "../diaries-info/TyDiaries_2024/parth_mane.js"
import onkar_wagh_html from "../diaries-info/TyDiaries_2024/onkar_wagh.js"
import himanshu_kamdi_html from "../diaries-info/TyDiaries_2024/himanshu_kamdi.js"
import soham_kumthekar_html from "../diaries-info/TyDiaries_2024/soham_kumthekar.js"
import sanika_kulkarni_html from "../diaries-info/TyDiaries_2024/sanika_kulkarni.js"
import sakshi_mahajan_html from "../diaries-info/TyDiaries_2024/sakshi_mahajan.js"
import rujuta_budke_html from "../diaries-info/TyDiaries_2024/rujuta_budke.js"
import bhagyashree_patil_html from "../diaries-info/TyDiaries_2024/bhagyashree_patil.js"
import arsh_maknojia_html from "../diaries-info/TyDiaries_2024/arsh_maknojia.js"
import anushka_more_html from "../diaries-info/TyDiaries_2024/anushka_more.js"
import utkarsha_gore_html from "../diaries-info/TyDiaries_2024/utkarsha_gore.js"
import shriya_kale_html from "../diaries-info/TyDiaries_2024/shriya_kale.js"
import sarvesh_mankar_html from "../diaries-info/TyDiaries_2024/sarvesh_mankar.js"
import ketan_kale_html from "../diaries-info/TyDiaries_2024/ketan_kale.js"
import vaishnavi_badgujar_html from "../diaries-info/TyDiaries_2024/vaishnavi_badgujar.js"
import chinmay_sheth_html from "../diaries-info/TyDiaries_2024/chinmay_sheth.js"
import aditya_choudhary_html from "../diaries-info/TyDiaries_2024/aditya_choudhary.js"
import vaishnavi_mulik_html from "../diaries-info/TyDiaries_2024/vaishnavi_mulik.js"
import shreeya_ganji_html from "../diaries-info/TyDiaries_2024/shreeya_ganji.js"
import sanchit_rajmane_html from "../diaries-info/TyDiaries_2024/sanchit_rajmane.js"
import vedang_khedekar_html from "../diaries-info/TyDiaries_2024/vedang_khedekar.js"
import aman_sayyad_html from "../diaries-info/TyDiaries_2024/aman_sayyad.js"
import rukmini_nazre_html from "../diaries-info/TyDiaries_2024/rukmini_nazre.js"
import nachiket_deshmukh_html from "../diaries-info/TyDiaries_2024/nachiket_deshmukh.js"
import shirshak_tiple_html from "../diaries-info/TyDiaries_2024/shirshak_tiple.js"
import gaurish_dodke_html from "../diaries-info/TyDiaries_2024/gaurish_dodke.js"
import shubham_gupta_html from "../diaries-info/TyDiaries_2024/shubham_gupta.js"
import harsh_marke_html from "../diaries-info/TyDiaries_2024/harsh_marke.js"
import nikhil_kokale_html from "../diaries-info/TyDiaries_2024/nikhil_kokale.js"
import soham_naigaonkar_html from "../diaries-info/TyDiaries_2024/soham_naigaonkar.js"
import soham_dombale_html from "../diaries-info/TyDiaries_2024/soham_dombale.js"
import sandesh_sahane_html from "../diaries-info/TyDiaries_2024/sandesh_sahane.js"
import renuka_padalkar_html from "../diaries-info/TyDiaries_2024/renuka_padalkar.js"
import parth_yewale_html from "../diaries-info/TyDiaries_2024/parth_yewale.js"
import sohel_bargir_html from "../diaries-info/TyDiaries_2024/Sohel_Bargir.js"
import avdhut_kamble_html from "../diaries-info/TyDiaries_2024/avdhut_kamble.js"
import vadiraj_joshi_html from "../diaries-info/TyDiaries_2024/vadiraj_joshi.js"
import preet_trivedi_html from "../diaries-info/TyDiaries_2024/preet_trivedi.js"
import simran_veer_html from "../diaries-info/TyDiaries_2024/simran_veer.js"
import omkar_oak_html from "../diaries-info/TyDiaries_2024/omkar_oak.js"
import omkar_nagwekar_html from "../diaries-info/TyDiaries_2024/omkar_nagwekar.js"
import harshwardhan_deshmukh_html from "../diaries-info/TyDiaries_2024/harshwardhan_deshmukh.js"
import anuksha_koul_html from "../diaries-info/TyDiaries_2024/anuksha_koul.js"
import atharva_satav_html from "../diaries-info/TyDiaries_2024/atharva_satav.js"
import aditya_raskar_html from "../diaries-info/TyDiaries_2024/aditya_raskar.js"
import abhinav_kurule_html from "../diaries-info/TyDiaries_2024/abhinav_kurule.js"
import kaustubh_virkar_html from "../diaries-info/TyDiaries_2024/kaustubh_virkar.js"
import anagha_mahure_html from "../diaries-info/TyDiaries_2024/anagha_mahure.js"
import anvay_joshi_html from "../diaries-info/TyDiaries_2024/anvay_joshi.js"
import atharva_mutsaddi_html from "../diaries-info/TyDiaries_2024/atharva_mutsaddi.js"
import ayush_ambhorkar_html from "../diaries-info/TyDiaries_2024/ayush_ambhorkar.js"
import jia_johnson_html from "../diaries-info/TyDiaries_2024/jia_johnson.js"
import pratyay_dhond_html from "../diaries-info/TyDiaries_2024/Pratyay_Dhond";
import soham_gupte_html from "../diaries-info/TyDiaries_2024/soham_gupte";
import rohit_shidid_html from "../diaries-info/TyDiaries_2023/rohit_shidid";
import harshmohan_kulkarni_html from "../diaries-info/TyDiaries_2023/harshmohan_kulkarni";
import saurabh_jog_html from "../diaries-info/TyDiaries_2023/saurabh_jog";
import rohit_magar_html from "../diaries-info/TyDiaries_2023/rohit_magar";
import advait_karmalkar_html from "../diaries-info/TyDiaries_2023/advait_karmalkar";
import quasim_shaikh_html from "../diaries-info/TyDiaries_2023/quasim_shaikh";
import neha_pawar_html from "../diaries-info/TyDiaries_2023/neha_pawar";
import sehajdeep_singh_html from "../diaries-info/TyDiaries_2023/sehajdeep_singh";
import mihika_sanghvi_html from "../diaries-info/TyDiaries_2023/mihika_sanghvi";
import rachana_gade_html from "../diaries-info/TyDiaries_2023/rachana_gade";
import nidhi_patel_html from "../diaries-info/TyDiaries_2023/nidhi_patel";
import sneha_venk_html from "../diaries-info/TyDiaries_2023/sneha_venk";
import atharva_muglikar_html from "../diaries-info/TyDiaries_2023/atharva_muglikar";
import harshal_kausadikar_html from "../diaries-info/TyDiaries_2023/harshal_kausadikar";
import anushka_naik_html from "../diaries-info/TyDiaries_2023/anushka_naik";
import christy_biju_html from "../diaries-info/TyDiaries_2023/christy_biju";
import om_khare_html from "../diaries-info/TyDiaries_2023/om_khare";
import yash_suryawanshi_html from "../diaries-info/TyDiaries_2023/yash_suryawanshi";
import shubham_gandhi_html from "../diaries-info/TyDiaries_2023/shubham_gandhi";
import anvita_mahajan_html from "../diaries-info/TyDiaries_2023/anvita_mahajan";
import dhanashree_mahadeo_html from "../diaries-info/TyDiaries_2023/dhanashree_mahadeo";
import deepika_html from "../diaries-info/deepika_goyal.js";
import vasu_html from "../diaries-info/vasu_sharma.js";
import rhugaved_html from "../diaries-info/rhugaved_narmade.js";
import soumitra_html from "../diaries-info/soumitra_tonapi.js";
import jinit_html from "../diaries-info/jinit_sanghvi.js";
import abhinav_html from "../diaries-info/abhinav_roy";
import savan_nahar from "../diaries-info/savan_nahar";
import shreya_singhal from "../diaries-info/shreya_singhal";
import umang_sakhare from "../diaries-info/umang_sakhare";
// import ved_dandekar from "../diaries-info/ved_dandekar";
import yash_gangwar from "../diaries-info/yash_gangwar";
import yash_shah_js from "../diaries-info/yash_shah";
import ankita_html from "../diaries-info/ankita_vaid";
import aayush_html from "../diaries-info/ayush_shah";
import eesha_html from "../diaries-info/eesha_kurode";
import falguni_html from "../diaries-info/falguni_patil";
import mayank_html from "../diaries-info/mayank_jain";
import naishadh_html from "../diaries-info/naishadh_vora";
import naman_html from "../diaries-info/naman_modi";
import omkar_html from "../diaries-info/omkar_thorat";
import manas_html from "../diaries-info/manas_nighrunkar";
import rohit_html from "../diaries-info/rohit_chaudhari";
import mugdha_html from "../diaries-info/mugdha_bapat";
import harshal_html from "../diaries-info/harshal_patil";
import chinmay_html from "../diaries-info/chinmay_dixit";
import aniket_html from "../diaries-info/aniket_jayateerth.js";
import anup_html from "../diaries-info/anup_nair.js";
import chetas_html from "../diaries-info/chetas_borse.js";
import soham_html from "../diaries-info/soham_parekh.js";
import swebert_html from "../diaries-info/swebert_correa.js";
import viraj_html from "../diaries-info/viraj_jadhav.js";
import vrinda_html from "../diaries-info/vrinda_ahuja";
import atharv_html from "../diaries-info/atharva_phand";
import gaurav_html from "../diaries-info/gaurav_wawdhane";
import arya_html from "../diaries-info/arya_gandhi";
import simran_html from "../diaries-info/simran_kucheria";
import hrishikesh_html from "../diaries-info/hrishikesh_athalye";
import muskan_html from "../diaries-info/muskaan_paryani.js";
import viren_html from "../diaries-info/viren_patil.js";
import vasvi_html from "../diaries-info/vasvi_gupta.js";
import pallavi_html from "../diaries-info/TyDiaries_2022/pallavi_buwa";
import anjali_html from "../diaries-info/TyDiaries_2022/anjali_dofe";
import prajakta_html from "../diaries-info/TyDiaries_2022/prajakta_ghatage";
import nimit_html from "../diaries-info/TyDiaries_2022/nimit_jain";
import atharva_marathe_html from "../diaries-info/TyDiaries_2022/atharva_marathe";
import ritik_html from "../diaries-info/TyDiaries_2022/ritik_naik";
import vishwesh_html from "../diaries-info/TyDiaries_2022/vishwesh_pujari";
import riddhi_html from "../diaries-info/TyDiaries_2022/riddhi_tharewal";
import aditya_html from "../diaries-info/TyDiaries_2022/aditya_verma";
import harish_html from "../diaries-info/TyDiaries_2022/harish_choudhary";
import sayali_html from "../diaries-info/TyDiaries_2022/sayali_patil";
import archisha_html from "../diaries-info/TyDiaries_2022/archisha_shukla";
import anushka_tadphale_html from "../diaries-info/TyDiaries_2022/anushka_tadphale";
import kartik_mandhan_html from "../diaries-info/TyDiaries_2022/kartik_mandhan";
import sameer_kavthekar_html from "../diaries-info/TyDiaries_2022/sameer_kavthekar";
import apurva_deshpande_html from "../diaries-info/TyDiaries_2022/apurva_deshpande";
import nikita_tipule_html from "../diaries-info/TyDiaries_2022/nikita_tipule";
import darshan_shah_html from "../diaries-info/TyDiaries_2022/darshan_shah";
import tanmayee_html from "../diaries-info/TyDiaries_2022/tanmayee_bharati";
import ketaki_jadhav_html from "../diaries-info/TyDiaries_2022/ketaki_jadhav";
import jayesh_html from "../diaries-info/TyDiaries_2022/jayesh";
import aman_patil_html from "../diaries-info/TyDiaries_2022/aman_patil";
import sanmeet_html from "../diaries-info/TyDiaries_2022/sanmeet";
import ashutosh_pardeshi_html from "../diaries-info/TyDiaries_2022/ashutosh_pardeshi";
import kunal_chaudhari_html from "../diaries-info/TyDiaries_2022/kunal_chaudhari";
import vishwajit_kadam_html from "../diaries-info/TyDiaries_2022/vishwajit_kadam";
import mudit_bapna_html from "../diaries-info/TyDiaries_2022/mudit_bapna";
import avishkar_andhale_html from "../diaries-info/TyDiaries_2022/avishkar_andhale";
import purav_desai_html from "../diaries-info/TyDiaries_2022/purav_desai";
import sakshi_pawar_html from "../diaries-info/TyDiaries_2022/sakshi_pawar";
import siya_dhokane_html from "../diaries-info/TyDiaries_2022/siya_dhokane";
import shreya_vaidya_html from "../diaries-info/TyDiaries_2022/shreya_vaidya";
import tanmay_patil_html from "../diaries-info/TyDiaries_2022/tanmay_patil";
import prerna_tulsiani_html from "../diaries-info/TyDiaries_2023/prerna_tulsiani";
import anish_deshpande_html from "../diaries-info/TyDiaries_2023/anish_deshpande";
import yash_chandak_html from "../diaries-info/TyDiaries_2023/yash_chandak";
import pratik_patil_html from "../diaries-info/TyDiaries_2023/pratik_patil"
import shyam_aradhye_html from "../diaries-info/TyDiaries_2023/shyam_aradhye"
import mihika_dravid_html from "../diaries-info/TyDiaries_2023/mihika_dravid"
import ritesh_chaudhari_html from "../diaries-info/TyDiaries_2023/ritesh_chaudhari"
import ishan_patwardhan_html from "../diaries-info/TyDiaries_2023/ishan_patwardhan"
import shitij_agrawal_html from "../diaries-info/TyDiaries_2023/shitij_agrawal"
import siddhi_shinde_html from "../diaries-info/TyDiaries_2023/siddhi_shinde"
import aditya_naik_html from "../diaries-info/TyDiaries_2023/aditya_naik"
import mayur_kongutte_html from "../diaries-info/TyDiaries_2023/mayur_kongutte"
import chitra_sangle_html from "../diaries-info/TyDiaries_2023/chitra_sangle"
import ninad_barve_html from "../diaries-info/TyDiaries_2023/ninad_barve";
import samruddhi_kamthe_html from "../diaries-info/TyDiaries_2023/samruddhi_kamthe";
import vaishnavi_pabalkar_html from "../diaries-info/TyDiaries_2023/vaishnavi_pabalkar";
import harsh_pande_html from "../diaries-info/TyDiaries_2023/harsh_pande";
import sayali_mate_html from "../diaries-info/TyDiaries_2023/sayali_mate";
import vedanshi_shah_html from "../diaries-info/TyDiaries_2023/vedanshi_shah";


export let data = [
  // Siemens
  {
    company: "Siemens",
    logo: Siemenslogo,
    isUpdated: true,
    batches: ["2025"],
    interns: [
      {
        image: vedang_khedekar,
        firstname: "Vedang",
        lastname: "Khedekar",
        html: vedang_khedekar_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: sanchit_rajmane,
        firstname: "Sanchit",
        lastname: "Rajmane",
        html: sanchit_rajmane_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: aman_sayyad,
        firstname: "Aman",
        lastname: "Sayyad",
        html: aman_sayyad_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: shreeya_ganji,
        firstname: "Shreeya",
        lastname: "Ganji",
        html: shreeya_ganji_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: vaishnavi_mulik,
        firstname: "Vaishnavi",
        lastname: "Mulik",
        html: vaishnavi_mulik_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
    ]
  },
  // TCS Research
  {
    company: "TCS Research",
    logo: TcsLogo,
    isUpdated: true,
    batches: ["2025"],
    interns: [
      {
        image: shirshak_tiple,
        firstname: "Shirshak",
        lastname: "Tiple",
        html: shirshak_tiple_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: nachiket_deshmukh,
        firstname: "Nachiket",
        lastname: "Deshmukh",
        html: nachiket_deshmukh_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: rukmini_nazre,
        firstname: "Rukmini",
        lastname: "Nazre",
        html: rukmini_nazre_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
    ]
  },
  
  //CITI
  {
    company: "CITI",
    logo: CitiLogo,
    isUpdated: true,
    batches: ["2022", "2023", "2024", "2025"],
    interns: [
      {
        image: himanshu_kamdi,
        firstname: "Himanshu",
        lastname: "Kamdi",
        html: himanshu_kamdi_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: arsh_maknojia,
        firstname: "Arsh",
        lastname: "Maknojia",
        html: arsh_maknojia_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: sarvesh_mankar,
        firstname: "Sarvesh",
        lastname: "Mankar",
        html: sarvesh_mankar_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: soham_kumthekar,
        firstname: "Soham",
        lastname: "Kumthekar",
        html: soham_kumthekar_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: sanika_kulkarni,
        firstname: "Sanika",
        lastname: "Kulkarni",
        html: sanika_kulkarni_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: sakshi_mahajan,
        firstname: "Sakshi",
        lastname: "Mahajan",
        html: sakshi_mahajan_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: shriya_kale,
        firstname: "Shriya",
        lastname: "Kale",
        html: shriya_kale_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: bhagyashree_patil,
        firstname: "Bhagyashree",
        lastname: "Patil",
        html: bhagyashree_patil_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: anushka_more,
        firstname: "Anushka",
        lastname: "More",
        html: anushka_more_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: utkarsha_gore,
        firstname: "Utkarsha",
        lastname: "Gore",
        html: utkarsha_gore_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: rujuta_budke,
        firstname: "Rujuta",
        lastname: "Budke",
        html: rujuta_budke_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: advait_karmalkar,
        firstname: "Advait",
        lastname: "Karmalkar",
        html: advait_karmalkar_html,
        isNew: false,
        date: {
          day: "8",
          super: "th",
          rest: "May, 2023",
        },
        batch: "2024",
      },
      {
        image: siddhi_shinde,
        firstname: "Siddhi",
        lastname: "Shinde",
        html: siddhi_shinde_html,
        isNew: false,
        date: {
          day: "8",
          super: "th",
          rest: "May, 2023",
        },
        batch: "2024",
      },
      {
        image: sneha_venk,
        firstname: "Sneha",
        lastname: "Venkateswaran",
        html: sneha_venk_html,
        isNew: false,
        date: {
          day: "8",
          super: "th",
          rest: "May, 2023",
        },
        batch: "2024",
      },
      {
        image: anushka_naik,
        firstname: "Anushka",
        lastname: "Naik",
        html: anushka_naik_html,
        isNew: false,
        date: {
          day: "8",
          super: "th",
          rest: "May, 2023",
        },
        batch: "2024",
      },
      {
        image: dhanashree_mahadeo,
        firstname: "Dhanashree",
        lastname: "Pawar",
        html: dhanashree_mahadeo_html,
        isNew: false,
        date: {
          day: "8",
          super: "th",
          rest: "May, 2023",
        },
        batch: "2024",
      },
      {
        image: shreya_vaidya,
        firstname: "Shreya",
        lastname: "Vaidya",
        html: shreya_vaidya_html,
        isNew: false,
        date: {
          day: "22",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023",
      },
      {
        image: ketaki_jadhav,
        firstname: "Ketaki",
        lastname: "Jadhav",
        html: ketaki_jadhav_html,
        isNew: false,
        date: {
          day: "7",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023"
      },
      {
        image: atharva_marathe,
        firstname: "Atharva",
        lastname: "Marathe",
        html: atharva_marathe_html,
        isNew: false,
        date: {
          day: "18",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023"
      },
      {
        image: viren,
        firstname: "Viren",
        lastname: "Patil",
        html: viren_html,
        isNew: false,
        date: {
          day: "28",
          super: "th",
          rest: "June, 2021",
        },
        batch: "2022"
      },
      {
        image: hrishikesh,
        firstname: "Hrishikesh",
        lastname: "Athalye",
        html: hrishikesh_html,
        isNew: false,
        date: {
          day: "30",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022"
      },

      {
        image: swebert,
        firstname: "Swebert",
        lastname: "Correa",
        html: swebert_html,
        isNew: false,
        date: {
          day: "23",
          super: "rd",
          rest: "April, 2021",
        },
        batch: "2022"
      },
    ],
  },
  //DEUTSCHE BANK
  {
    company: "DEUTSCHE BANK",
    logo: DeutscheLogo,
    isUpdated: true,
    batches: ["2023", "2024", "2025"],
    interns: [
      {
        image: vaishnavi_badgujar,
        firstname: "Vaishnavi",
        lastname: "Badgujar",
        html: vaishnavi_badgujar_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: ketan_kale,
        firstname: "Ketan",
        lastname: "Kale",
        html: ketan_kale_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: samruddhi_kamthe,
        firstname: "Samruddhi",
        lastname: "Kamthe",
        html: samruddhi_kamthe_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },

      {
        image: ritesh_chaudhari,
        firstname: "Ritesh",
        lastname: "Chaudhari",
        html: ritesh_chaudhari_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: harshal_kausadikar,
        firstname: "Harshal",
        lastname: "Kausadikar",
        html: harshal_kausadikar_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: om_khare,
        firstname: "Om",
        lastname: "Khare",
        html: om_khare_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: prerna_tulsiani,
        firstname: "Prerna",
        lastname: "Tulsiani",
        html: prerna_tulsiani_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {

        image: aman_patil,
        firstname: "Aman",
        lastname: "Patil",
        html: aman_patil_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023",
      },

      {
        image: apurva_deshpande,
        firstname: "Apurva",
        lastname: "Deshpande",
        html: apurva_deshpande_html,
        isNew: false,
        date: {
          day: "7",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023",
      },
      {
        image: anjali_dofe,
        firstname: "Anjali",
        lastname: "Dofe",
        html: anjali_html,
        isNew: false,
        date: {
          day: "18",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },
    ],
  },
  // Bp Global
  {
    company: "Bp Global",
    logo: bplogo,
    isUpdated: true,
    batches: ["2025"],
    interns: [
      {
        image: shubham_gupta,
        firstname: "Shubham",
        lastname: "Gupta",
        html: shubham_gupta_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
    ]
  },
  // Hilti
  {
    company: "HILTI",
    logo: HiltiLogo,
    isUpdated: true,
    batches: ["2024", "2025"],
    interns: [
      {
        image: soham_gupte,
        firstname: "Soham",
        lastname: "Gupte",
        html: soham_gupte_html,
        isNew: true,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2025",
      },
      {
        image: anvay_joshi,
        firstname: "Anvay",
        lastname: "Joshi",
        html: anvay_joshi_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: anagha_mahure,
        firstname: "Anagha",
        lastname: "Mahure",
        html: anagha_mahure_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: kaustubh_virkar,
        firstname: "Kaustubh",
        lastname: "Virkar",
        html: kaustubh_virkar_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: quasim_shaikh,
        firstname: "Qasim",
        lastname: "Shaikh",
        html: quasim_shaikh_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2024",
      },
      {
        image: nidhi_patel,
        firstname: "Nidhi",
        lastname: "Patel",
        html: nidhi_patel_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2024",
      },
      {
        image: anvita_mahajan,
        firstname: "Anvita",
        lastname: "Mahajan",
        html: anvita_mahajan_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2024",
      }
    ]
  },
  // D.E. Shaw
  {
    company: "D.E. SHAW INDIA PVT. LTD",
    logo: DEShawLogo,
    isUpdated: true,
    batches: ["2021", "2022", "2023", "2024", "2025"],
    interns: [
      {
        image: jia_johnson,
        firstname: "Jia",
        lastname: "Johnson",
        html: jia_johnson_html,
        isNew: true,
        date: {
          day: "21",
          super: "st",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: atharva_mutsaddi,
        firstname: "Atharva",
        lastname: "Mutsaddi",
        html: atharva_mutsaddi_html,
        isNew: true,
        date: {
          day: "21",
          super: "st",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: vedanshi_shah,
        firstname: "Vedanshi",
        lastname: "Shah",
        html: vedanshi_shah_html,
        isNew: false,
        date: {
          day: "7",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: sameer_kavthekar,
        firstname: "Sameer",
        lastname: "Kavthekar",
        html: sameer_kavthekar_html,
        isNew: false,
        date: {
          day: "7",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023",
      },
      {
        image: pallavi_buwa,
        firstname: "Pallavi",
        lastname: "Buwa",
        html: pallavi_html,
        isNew: false,
        date: {
          day: "18",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },
      {
        image: anup,
        firstname: "Anup",
        lastname: "Nair",
        html: anup_html,
        isNew: false,
        date: {
          day: "23",
          super: "rd",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: soham,
        firstname: "Soham",
        lastname: "Parekh",
        html: soham_html,
        isNew: false,
        date: {
          day: "23",
          super: "rd",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: mayank,
        firstname: "Mayank",
        lastname: "Jain",
        html: mayank_html,
        isNew: false,
        date: {
          day: "1",
          super: "st",
          rest: "July, 2020",
        },
        batch: "2021",
      },
    ],
  },
  //Arcesium
  {
    company: "ARCESIUM INDIA",
    logo: ArcesiumLogo,
    isUpdated: true,
    batches: ["2020", "2021", "2022", "2023", "2024", "2025"],
    interns: [
      {
        image: pratyay_dhond,
        firstname: "Pratyay",
        lastname: "Dhond",
        html: pratyay_dhond_html,
        isNew: true,
        date: {
          day: "18",
          super: "th",
          rest: "July, 2024",
        },
        batch: "2025",

      },
      {
        image: ayush_ambhorkar,
        firstname: "Ayush",
        lastname: "Ambhorkar",
        html: ayush_ambhorkar_html,
        isNew: true,
        date: {
          day: "21",
          super: "st",
          rest: "July, 2024",
        },
        batch: "2025",

      },
      {
        image: yash_suryawanshi,
        firstname: "Yash",
        lastname: "Suryawanshi",
        html: yash_suryawanshi_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2024",

      },
      {
        image: darshan_shah,
        firstname: "Darshan",
        lastname: "Shah",
        html: darshan_shah_html,
        isNew: false,
        date: {
          day: "7",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023",

      },
      {
        image: kartik_mandhan,
        firstname: "Kartik",
        lastname: "Mandhan",
        html: kartik_mandhan_html,
        isNew: false,
        date: {
          day: "29",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },
      {
        image: rohit,
        firstname: "Rohit",
        lastname: "Chaudhari",
        html: rohit_html,
        isNew: false,
        date: {
          day: "9",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: mugdha,
        firstname: "Mugdha",
        lastname: "Bapat",
        html: mugdha_html,
        isNew: false,
        date: {
          day: "9",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: ankita,
        firstname: "Ankita",
        lastname: "Vaid",
        html: ankita_html,
        isNew: false,
        date: {
          day: "1",
          super: "st",
          rest: "July, 2020",
        },
        batch: "2021",
      },
      {
        image: savan,
        firstname: "Savan",
        lastname: "Nahar",
        html: savan_nahar,
        isNew: false,
        date: {
          day: "21",
          super: "st",
          rest: "May, 2020",
        },
        batch: "2020",
      },
    ],
  },
  // WELLS FARGO
  {
    company: "WELLS FARGO",
    logo: WellsFargo,
    isUpdated: true,
    batches: ["2023", "2024", "2025"],
    interns: [
      {
        image: atharva_satav,
        firstname: "Atharva",
        lastname: "Satav",
        html: atharva_satav_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: vadiraj_joshi,
        firstname: "Vadiraj",
        lastname: "Joshi",
        html: vadiraj_joshi_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: simran_veer,
        firstname: "Simran",
        lastname: "Veer",
        html: simran_veer_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: preet_trivedi,
        firstname: "Preet",
        lastname: "Trivedi",
        html: preet_trivedi_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: omkar_oak,
        firstname: "Omkar",
        lastname: "Oak",
        html: omkar_oak_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: omkar_nagwekar,
        firstname: "Omkar",
        lastname: "Nagwekar",
        html: omkar_nagwekar_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: harshwardhan_deshmukh,
        firstname: "Harshwardhan",
        lastname: "Deshmukh",
        html: harshwardhan_deshmukh_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: aditya_raskar,
        firstname: "Aditya",
        lastname: "Raskar",
        html: aditya_raskar_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: anuksha_koul,
        firstname: "Anuksha",
        lastname: "Koul",
        html: anuksha_koul_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: abhinav_kurule,
        firstname: "Abhinav",
        lastname: "Kurule",
        html: abhinav_kurule_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: ninad_barve,
        firstname: "Ninad",
        lastname: "Barve",
        html: ninad_barve_html,
        isNew: false,
        date: {
          day: "18",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2024",
      },
      {
        image: aditya_naik,
        firstname: "Aditya",
        lastname: "Naik",
        html: aditya_naik_html,
        isNew: false,
        date: {
          day: "18",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2024",
      },
      {
        image: atharva_muglikar,
        firstname: "Atharva",
        lastname: "Muglikar",
        html: atharva_muglikar_html,
        isNew: false,
        date: {
          day: "18",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2024",
      },
      {
        image: ritik,
        firstname: "Ritik",
        lastname: "Naik",
        html: ritik_html,
        isNew: false,
        date: {
          day: "18",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },
    ],
  },
  //Mastercard
  {
    company: "MASTERCARD",
    logo: MastercardLogo,
    isUpdated: true,
    batches: ["2021", "2022", "2025"],
    interns: [
      {
        image: apurva_haspe,
        firstname: "Apurva",
        lastname: "Haspe",
        html: apurva_haspe_html,
        isNew: true,
        date: {
          day: "24",
          super: "th",
          rest: "July, 2024",
        },
        batch: "2025"
      },
      {
        image: shreya_bhatkhande,
        firstname: "Shreya",
        lastname: "Bhatkhande",
        html: shreya_bhatkhande_html,
        isNew: true,
        date: {
          day: "24",
          super: "th",
          rest: "July, 2024",
        },
        batch: "2025"
      },
      {
        image: snehasish_bose,
        firstname: "Snehasish",
        lastname: "Bose",
        html: snehasish_bose_html,
        isNew: true,
        date: {
          day: "24",
          super: "th",
          rest: "July, 2024",
        },
        batch: "2025"
      },
      {
        image: onkar_wagh,
        firstname: "Onkar",
        lastname: "Wagh",
        html: onkar_wagh_html,
        isNew: true,
        date: {
          day: "24",
          super: "th",
          rest: "July, 2024",
        },
        batch: "2025"
      },
      {
        image: parth_mane,
        firstname: "Parth",
        lastname: "Mane",
        html: parth_mane_html,
        isNew: true,
        date: {
          day: "24",
          super: "th",
          rest: "July, 2024",
        },
        batch: "2025"
      },
      {
        image: shruti_ravichandran,
        firstname: "Shruti",
        lastname: "Ravichandran",
        html: shruti_ravichandran_html,
        isNew: true,
        date: {
          day: "24",
          super: "th",
          rest: "July, 2024",
        },
        batch: "2025"
      },
      {
        image: sharvari_phand,
        firstname: "Sharvari",
        lastname: "Phand",
        html: sharvari_phand_html,
        isNew: true,
        date: {
          day: "24",
          super: "th",
          rest: "July, 2024",
        },
        batch: "2025"
      },
      {
        image: samarth_kanwate,
        firstname: "Samarth",
        lastname: "Kanwate",
        html: samarth_kanwate_html,
        isNew: true,
        date: {
          day: "24",
          super: "th",
          rest: "July, 2024",
        },
        batch: "2025"
      },
      {
        image: vasvi,
        firstname: "Vasvi",
        lastname: "Gupta",
        html: vasvi_html,
        isNew: false,
        date: {
          day: "28",
          super: "th",
          rest: "June, 2021",
        },
        batch: "2022"
      },
      {
        image: chetas,
        firstname: "Chetas",
        lastname: "Borse",
        html: chetas_html,
        isNew: false,
        date: {
          day: "23",
          super: "rd",
          rest: "April, 2021",
        },
        batch: "2022"
      },
      {
        image: omkar,
        firstname: "Omkar",
        lastname: "Thorat",
        html: omkar_html,
        isNew: false,
        date: {
          day: "26",
          super: "th",
          rest: "June, 2020",
        },
        batch: "2021"
      },
      {
        image: esha,
        firstname: "Eesha",
        lastname: "Kurode",
        html: eesha_html,
        isNew: false,
        date: {
          day: "5",
          super: "th",
          rest: "May, 2020",
        },
        batch: "2021"
      },
    ],
  }, 
  // Algoquant
  {
    company: "Algoquant Fintech",
    logo: Algoquantlogo,
    isUpdated: true,
    batches: ["2025"],
    interns: [
      {
        image: gaurish_dodke,
        firstname: "Gaurish",
        lastname: "Dodke",
        html: gaurish_dodke_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
    ]
  },
  //Marvell
  {
    company: "Marvell",
    logo: MarvellLogo,
    isUpdated: true,
    batches: ["2025"],
    interns: [
      {
        image: harsh_marke,
        firstname: "Harsh",
        lastname: "Marke",
        html: harsh_marke_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
    ]
  },   
  //Trading Technologies
  {
    company: "Trading Technologies",
    logo: TradingLogo,
    isUpdated: true,
    batches: ["2025"],
    interns: [
      {
        image: soham_naigaonkar,
        firstname: "Soham",
        lastname: "Naigaonkar",
        html: soham_naigaonkar_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: nikhil_kokale,
        firstname: "Nikhil",
        lastname: "Kokale",
        html: nikhil_kokale_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
    ]
  },
  // Pattern
  {
    company: "Pattern Technologies",
    logo: PatternLogo,
    isUpdated: true,
    batches: ["2025"],
    interns: [
      {
        image: avdhut_kamble,
        firstname: "Avdhut",
        lastname: "Kamble",
        html: avdhut_kamble_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: parth_yewale,
        firstname: "Parth",
        lastname: "Yewale",
        html: parth_yewale_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: renuka_padalkar,
        firstname: "Renuka",
        lastname: "Padalkar",
        html: renuka_padalkar_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: sandesh_sahane,
        firstname: "Sandesh",
        lastname: "Sahane",
        html: sandesh_sahane_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: soham_dombale,
        firstname: "Soham",
        lastname: "Dombale",
        html: soham_dombale_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: sohel_bargir,
        firstname: "Sohel",
        lastname: "Bargir",
        html: sohel_bargir_html,
        isNew: true,
        date: {
          day: "22",
          super: "nd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
    ]
  },
  // BNY MEllon
  {
    company: "BNY Mellon",
    logo: BNYLogo,
    isUpdated: false,
    batches: ["2024"],
    interns: [
      {
        image: sayali_mate,
        firstname: "Sayali",
        lastname: "Mate",
        html: sayali_mate_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: saurabh_jog,
        firstname: "Saurabh",
        lastname: "Jog",
        html: saurabh_jog_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: rohit_magar,
        firstname: "Rohit",
        lastname: "Magar",
        html: rohit_magar_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: mayur_k,
        firstname: "Mayur",
        lastname: "Kongutte",
        html: mayur_kongutte_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2024",
      },
      {
        image: sehajdeep_singh,
        firstname: "Sehajdeep",
        lastname: "Singh",
        html: sehajdeep_singh_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2024",
      },
      {
        image: rachana_gade,
        firstname: "Rachana",
        lastname: "Gade",
        html: rachana_gade_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2024",
      },
      {
        image: pratik_patil,
        firstname: "Pratik",
        lastname: "Patil",
        html: pratik_patil_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2024",
      }
    ]
  },
  // JPM
  {
    company: "JP Morgan",
    logo: JPMLogo,
    isUpdated: false,
    batches: ["2024"],
    interns: [
      {
        image: harshmohan_kulkarni,
        firstname: "Harshmohan",
        lastname: "Kulkarni",
        html: harshmohan_kulkarni_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2024",
      },
      {
        image: shubham_gandhi,
        firstname: "Shubham",
        lastname: "Gandhi",
        html: shubham_gandhi_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2024",
      }
    ]
  },  
  //Barclays
  {
    company: "BARCLAYS",
    logo: BarclaysLogo,
    isUpdated: false,
    batches: ["2022", "2023", "2024"],

    interns: [
      {
        image: chitra_sangle,
        firstname: "Chitra",
        lastname: "Sangle",
        html: chitra_sangle_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: shitij_agrawal,
        firstname: "Shitij",
        lastname: "Agrawal",
        html: shitij_agrawal_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: shyam_aradhye,
        firstname: "Shyam",
        lastname: "Aradhye",
        html: shyam_aradhye_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: yash_chandak,
        firstname: "Yash",
        lastname: "Chandak",
        html: yash_chandak_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: purav_desai,
        firstname: "Purav",
        lastname: "Desai",
        html: purav_desai_html,
        isNew: false,
        date: {
          day: "22",
          super: "nd",
          rest: "June, 2022",
        },
        batch: "2023"
      },
      {
        image: jayesh,
        firstname: "Jayesh",
        lastname: "Gadilohar",
        html: jayesh_html,
        isNew: false,
        date: {
          day: "7",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023"
      },
      {
        image: sayali_patil,
        firstname: "Sayali",
        lastname: "Patil",
        html: sayali_html,
        isNew: false,
        date: {
          day: "29",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023"
      },
      {
        image: muskan,
        firstname: "Muskan",
        lastname: "Paryani",
        html: muskan_html,
        isNew: false,
        date: {
          day: "28",
          super: "th",
          rest: "June, 2021",
        },
        batch: "2022"
      },
      {
        image: gaurav,
        firstname: "Gaurav",
        lastname: "Wawdhane",
        html: gaurav_html,
        isNew: false,
        date: {
          day: "30",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022"
      },
      {
        image: rhugaved,
        firstname: "Rhugaved",
        lastname: "Narmade",
        html: rhugaved_html,
        isNew: false,
        date: {
          day: "16",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022"
      },
    ],
  },  
  //Goldman Sachs
  {
    company: "GOLDMAN SACHS",
    logo: GoldmanSachsLogo,
    isUpdated: false,
    batches: ["2021", "2022", "2023"],
    interns: [
      {
        image: siya_dhokane,
        firstname: "Siya",
        lastname: "Dhokane",
        html: siya_dhokane_html,
        isNew: false,
        date: {
          day: "22",
          super: "nd",
          rest: "June, 2022",
        },
        batch: "2023",
      },
      {
        image: avishkar_andhale,
        firstname: "Avishkar",
        lastname: "Andhale",
        html: avishkar_andhale_html,
        isNew: false,
        date: {
          day: "22",
          super: "nd",
          rest: "June, 2022",
        },
        batch: "2023",
      },
      {
        image: vishwajit_kadam,
        firstname: "Vishwajit",
        lastname: "Kadam",
        html: vishwajit_kadam_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023",
      },
      {
        image: nikita_tipule,
        firstname: "Nikita",
        lastname: "Tipule",
        html: nikita_tipule_html,
        isNew: false,
        date: {
          day: "7",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023",
      },
      {
        image: archisha_shukla,
        firstname: "Archisha",
        lastname: "Shukla",
        html: archisha_html,
        isNew: false,
        date: {
          day: "29",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },
      {
        image: prajakta_ghatage,
        firstname: "Prajakta",
        lastname: "Ghatage",
        html: prajakta_html,
        isNew: false,
        date: {
          day: "18",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },
      {
        image: vrinda,
        firstname: "Vrinda",
        lastname: "Ahuja",
        html: vrinda_html,
        isNew: false,
        date: {
          day: "30",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: simran,
        firstname: "Simran",
        lastname: "Kucheria",
        html: simran_html,
        isNew: false,
        date: {
          day: "30",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: deepika,
        firstname: "Deepika",
        lastname: "Goyal",
        html: deepika_html,
        isNew: false,
        date: {
          day: "16",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: vasu,
        firstname: "Vasu",
        lastname: "Sharma",
        html: vasu_html,
        isNew: false,
        date: {
          day: "16",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: jinit,
        firstname: "Jinit",
        lastname: "Sanghvi",
        html: jinit_html,
        isNew: false,
        date: {
          day: "16",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: abhinav,
        firstname: "Abhinav",
        lastname: "Roy",
        html: abhinav_html,
        isNew: false,
        date: {
          day: "1",
          super: "st",
          rest: "July, 2020",
        },
        batch: "2021",
      },
      {
        image: shreya,
        firstname: "Shreya",
        lastname: "Singhal",
        html: shreya_singhal,
        isNew: false,
        date: {
          day: "26",
          super: "th",
          rest: "June, 2020",
        },
        batch: "2021",
      },
    ],
  },
  // Inxise
  {
    company: "INXISE DATALABS",
    logo: InxiseLogo,
    isUpdated: false,
    batches: ["2023"],

    interns: [
      {
        image: ashutosh_pardeshi,
        firstname: "Ashutosh",
        lastname: "Pardeshi",
        html: ashutosh_pardeshi_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023",
      }
    ]
  },
  //Credit Suisse
  {
    company: "CREDIT SUISSE",
    logo: CreditSuisseLogo,
    isUpdated: false,
    batches: ["2021", "2022", "2023"],
    interns: [
      {
        image: harish,
        firstname: "Harish",
        lastname: "Choudhary",
        html: harish_html,
        isNew: false,
        date: {
          day: "29",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },

      {
        image: atharv,
        firstname: "Atharva",
        lastname: "Phand",
        html: atharv_html,
        isNew: false,
        date: {
          day: "30",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: falguni,
        firstname: "Falguni",
        lastname: "Patil",
        html: falguni_html,
        isNew: false,
        date: {
          day: "21",
          super: "st",
          rest: "May, 2020",
        },
        batch: "2021",
      },
      {
        image: aayush,
        firstname: "Aayush",
        lastname: "Shah",
        html: aayush_html,
        isNew: false,
        date: {
          day: "5",
          super: "th",
          rest: "May, 2020",
        },
        batch: "2021",
      },
    ],
  },

  //FinIQ 
  {
    company: "FINIQ",
    logo: FinIQLogo,
    isUpdated: false,
    batches: ["2021", "2022", "2023", "2024"],
    interns: [
      {
        image: rohit_shidid,
        firstname: "Rohit",
        lastname: "Shidid",
        html: rohit_shidid_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: harsh_pande,
        firstname: "Harsh",
        lastname: "Pande",
        html: harsh_pande_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: vaishnavi_pabalkar,
        firstname: "Vaishnavi",
        lastname: "Pabalkar",
        html: vaishnavi_pabalkar_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: ishan_patwardhan,
        firstname: "Ishan",
        lastname: "Patwardhan",
        html: ishan_patwardhan_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: christy_biju,
        firstname: "Christy",
        lastname: "Biju",
        html: christy_biju_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: anish_deshpande,
        firstname: "Anish",
        lastname: "Deshpande",
        html: anish_deshpande_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: tanmay_patil,
        firstname: "Tanmay",
        lastname: "Patil",
        html: tanmay_patil_html,
        isNew: false,
        date: {
          day: "22",
          super: "nd",
          rest: "June, 2022",
        },
        batch: "2023",
      },
      {
        image: sakshi_pawar,
        firstname: "Sakshi",
        lastname: "Pawar",
        html: sakshi_pawar_html,
        isNew: false,
        date: {
          day: "22",
          super: "nd",
          rest: "June, 2022",
        },
        batch: "2023",
      },
      {
        image: mudit_bapna,
        firstname: "Mudit",
        lastname: "Bapna",
        html: mudit_bapna_html,
        isNew: false,
        date: {
          day: "22",
          super: "nd",
          rest: "June, 2022",
        },
        batch: "2023",
      },
      {
        image: sanmeet,
        firstname: "Sanmeet",
        lastname: "Wakchaure",
        html: sanmeet_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023",
      },

      {
        image: anushka_tadphale,
        firstname: "Anushka",
        lastname: "Tadphale",
        html: anushka_tadphale_html,
        isNew: false,
        date: {
          day: "29",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },
      {
        image: nimit_jain,
        firstname: "Nimit",
        lastname: "Jain",
        html: nimit_html,
        isNew: false,
        date: {
          day: "18",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },
      {
        image: arya,
        firstname: "Arya",
        lastname: "Gandhi",
        html: arya_html,
        isNew: false,
        date: {
          day: "30",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: soumitra,
        firstname: "Soumitra",
        lastname: "Tonapi",
        html: soumitra_html,
        isNew: false,
        date: {
          day: "16",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
      {
        image: yash_g,
        firstname: "Yash",
        lastname: "Gangwar",
        html: yash_gangwar,
        isNew: false,
        date: {
          day: "26",
          super: "th",
          rest: "June, 2020",
        },
        batch: "2021",
      },
    ],
  },
  //Google
  {
    company: "Google",
    logo: GoogleLogo,
    isUpdated: false,
    batches: ["2022"],
    interns: [
      {
        image: aniket,
        firstname: "Aniket",
        lastname: "Jayateerth",
        html: aniket_html,
        isNew: false,
        date: {
          day: "23",
          super: "rd",
          rest: "April, 2021",
        },
        batch: "2022",
      },
    ],
  },  

  //Schlumberger
  {
    company: "Schlumberger",
    logo: SchlumbergerLogo,
    isUpdated: false,
    batches: ["2022", "2024"],
    interns: [
      {
        image: mihika_sanghvi,
        firstname: "Mihika",
        lastname: "Sanghvi",
        html: mihika_sanghvi_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: mihika_dravid,
        firstname: "Mihika",
        lastname: "Dravid",
        html: mihika_dravid_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2024",
      },
      {
        image: viraj,
        firstname: "Viraj",
        lastname: "Jadhav",
        html: viraj_html,
        isNew: false,
        date: {
          day: "23",
          super: "rd",
          rest: "April, 2021",
        },
        batch: "2022",
      },
    ],
  },
 
  //Garrett Motion
  {
    company: "GARRETT MOTION",
    logo: GarrettMotionLogo,
    isUpdated: false,
    batches: ["2022"],
    interns: [
      {
        image: harshal,
        firstname: "Harshal",
        lastname: "Patil",
        html: harshal_html,
        isNew: false,
        date: {
          day: "9",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
    ],
  },
  //Nuance
  {
    company: "NUANCE",
    logo: NuanceLogo,
    isUpdated: false,
    batches: ["2022", "2023"],
    interns: [
      {
        image: kunal_chaudhari,
        firstname: "Kunal",
        lastname: "Chaudhari",
        html: kunal_chaudhari_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023",
      },
      {
        image: riddhi,
        firstname: "Riddhi",
        lastname: "Tharewal",
        html: riddhi_html,
        isNew: false,
        date: {
          day: "29",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },
      {
        image: manas,
        firstname: "Manas",
        lastname: "Nighrunkar",
        html: manas_html,
        isNew: false,
        date: {
          day: "9",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
    ],
  },
  //EQ Technologic
  {
    company: "EQ TECHNOLOGIC",
    logo: eQLogo,
    isUpdated: false,
    batches: ["2022"],
    interns: [
      {
        image: chinmay,
        firstname: "Chinmay",
        lastname: "Dixit",
        html: chinmay_html,
        isNew: false,
        date: {
          day: "9",
          super: "th",
          rest: "April, 2021",
        },
        batch: "2022",
      },
    ],
  },
  //Wolters Kluwer
  {
    company: "WOLTERS KLUWER",
    logo: WoltersKluwerLogo,
    isUpdated: false,
    batches: ["2020"],
    interns: [
      {
        image: yash_shah,
        firstname: "Yash",
        lastname: "Shah",
        html: yash_shah_js,
        isNew: false,
        date: {
          day: "26",
          super: "th",
          rest: "June, 2020",
        },
        batch: "2020",
      },
    ],
  },
  //Standard Chartered Bank
  {
    company: "STANDARD CHARTERED BANK",
    logo: StandardCharteredLogo,
    isUpdated: false,
    batches: ["2021"],
    interns: [
      {
        image: naman,
        firstname: "Naman",
        lastname: "Modi",
        html: naman_html,
        isNew: false,
        date: {
          day: "1",
          super: "st",
          rest: "July, 2020",
        },
        batch: "2021",
      },
      {
        image: naishadh,
        firstname: "Naishadh",
        lastname: "Vora",
        html: naishadh_html,
        isNew: false,
        date: {
          day: "21",
          super: "st",
          rest: "May, 2020",
        },
        batch: "2021",
      },
    ],
  },

  //QuestionPro
  {
    company: "QUESTIONPRO",
    logo: QuestionProLogo,
    isUpdated: false,
    batches: ["2021"],
    interns: [
      {
        image: umang,
        firstname: "Umang",
        lastname: "Sakhare",
        html: umang_sakhare,
        isNew: false,
        date: {
          day: "5",
          super: "th",
          rest: "May, 2020",
        },
        batch: "2021",
      },
    ],
  },  
  {
    company: "PERSISTENT SYSTEMS",
    logo: Persistent,
    isUpdated: false,
    batches: ["2023"],
    interns: [
      {
        image: vishwesh,
        firstname: "Vishwesh",
        lastname: "Pujari",
        html: vishwesh_html,
        isNew: false,
        date: {
          day: "18",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },
    ],
  },
  {
    company: "TCS RESEARCH",
    logo: TcsLogo,
    isUpdated: false,
    batches: ["2023"],
    interns: [
      {
        image: aditya,
        firstname: "Aditya",
        lastname: "Verma",
        html: aditya_html,
        isNew: false,
        date: {
          day: "29",
          super: "th",
          rest: "May, 2022",
        },
        batch: "2023",
      },
    ],
  },
  {
    company: "Colgate Palmolive",
    logo: Colgate_palmolive,
    isUpdated: false,
    batches: ["2023"],
    interns: [
      {
        image: tanmayee,
        firstname: "Tanmayee",
        lastname: "Bharati",
        html: tanmayee_html,
        isNew: false,
        date: {
          day: "7",
          super: "th",
          rest: "June, 2022",
        },
        batch: "2023",
      },
    ],
  },
  // CarrotSoft
  {
    company: "CarrotSoft Technologies Pvt.Ltd.",
    logo: CarrotSoftlogo,
    isUpdated: true,
    batches: ["2024", "2025"],
    interns: [
      {
        image: aditya_choudhary,
        firstname: "Aditya",
        lastname: "Choudhary",
        html: aditya_choudhary_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: chinmay_sheth,
        firstname: "Chinmay",
        lastname: "Sheth",
        html: chinmay_sheth_html,
        isNew: true,
        date: {
          day: "23",
          super: "rd",
          rest: "July, 2024",
        },
        batch: "2025",
      },
      {
        image: neha_pawar,
        firstname: "Neha",
        lastname: "Pawar",
        html: neha_pawar_html,
        isNew: false,
        date: {
          day: "15",
          super: "th",
          rest: "June, 2023",
        },
        batch: "2024",
      },

    ]
  },
];

const newDiaries = data.filter((d) => d.interns[0].isNew === true);
const oldDiaries = data.filter((d) => d.interns[0].isNew === false);
data = [...newDiaries, ...oldDiaries];

import React from "react";

const ketan_kale = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Ketan Kale

                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Deutsche Bank</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the selection process?</b>
                        <br />
                        <p>
                            Structure of Entire Interview Process :
                            <br />
                            <ol>
                                <li>
                                    1st round was the online aptitude test conducted on hackerearth platform.
                                    <br />
                                    After the shortlisting was done, there were another 3 rounds.
                                    <br />
                                </li>
                                <li>
                                    2nd round was the technical round.
                                </li>
                                <li>3rd round was the Professional Fitness Round.</li>
                                <li>4th round was the HR Round</li>
                            </ol>
                        </p>
                        <p>
                            Structure of Aptitude Round :
                            <br />
                            The Aptitude Round consisted of 12 questions, of which 2 questions were coding questions. One of the coding questions was similar to the painter’s partition problem(a classic problem of binary search) and the other was a greedy problem(similar to job scheduling problem). Other than the coding questions, there were 10 MCQs without any negative marking. They were based on topics like subnetting, sql queries, time complexity, and some concepts of operating systems.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Technical Round -
                                    <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                        <li>This round started with the introduction.</li>
                                        <li>Then both of my projects were discussed in great detail. There were questions related to the algorithms used, why a particular algorithm/technology was used, improvements possible in the project, the motivation behind choosing the project etc.
                                        </li>
                                        <li>Then he asked some basic questions on DSA. Some of the questions were difference between DFS and BFS, difference between stack and queue, etc.
                                        </li>
                                        <li>Then he gave one coding question - Valid Parentheses (available on Leetcode). I was asked to explain the thought process, write the code on paper, and explain the time and space complexity.
                                        </li>
                                        <li>DBMS queries related to group by, find maximum salary were asked.</li>
                                        <li>Some questions on Computer Networks like what is subnet, what is firewall, what is VPN were also asked.
                                        </li>
                                        <li>At last, he asked me the difference between method overloading and method overriding, and in the process asked me various OOPS concepts like constructor etc.
                                        </li>
                                        <li>This round lasted for 60-70 minutes.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Professional Fitness Round -
                                    <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                        <li>This round started with various concepts of Microprocessor Techniques. Some of the questions were -
                                            <ol>
                                                <li>What is Interrupt Vector Table(IVT)?</li>
                                                <li>What is difference between RET and IRET?</li>
                                                <li>Explain the process when an interrupt service subroutine is called?</li>
                                                <li>Difference between ISR and normal subroutine?</li>
                                            </ol>
                                            <br />
                                            Cross questioning was done after every answer.
                                        </li>
                                        <li>Then he asked me to list down the data structures which can be used to optimize the performance of operating systems, and why certain data structures could be used and why some could not be used.
                                        </li>
                                        <li>A OOPS question was asked. What is the difference between encapsulation and abstraction with example.
                                        </li>
                                        <li>Then he asked me to explain the RPPOOP project. He asked to detail down the steps to build the project step by step. He also asked what challenges I faced during the completion of the project. He then asked me to detail down the algorithm used for the project, and then asked many questions related to it. Also some statistics questions like what is normal distribution and where is it used were also asked.
                                        </li>
                                        <li>Then he asked me questions on Operating Systems. Some of the questions were -
                                            <ol>
                                                <li>4 necessary conditions for deadlock</li>
                                                <li>Difference between 32 bit and 64 bit operating systems.
                                                </li>
                                                <li>Some situational questions related to user space and kernel space.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>This round lasted for 60-65 minutes.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    HR Round -
                                    <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                        <li>This round started with my introduction.
                                        </li>
                                        <li>Then I was asked to tell something about myself which is not in my resume.
                                        </li>
                                        <li>Some situation-based questions were asked related to team work vs individual work and what I prefer.
                                        </li>
                                        <li>Then she asked me to tell the most difficult decision I have made in my life till now followed by some normal conversation.
                                        </li>
                                        <li>This round lasted for 20-25 minutes.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </p>
                    </p>
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After the coding test, 10 students were shortlisted for interviews.
                        After the first technical round, around 5-6 students were shortlisted for the professional fitness round.
                        After the professional fitness round, 3-4 students were shortlisted for the HR Round.
                        After the HR Round, 2 students got the final offer.

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                Striver’s SDE Sheet - Frequently asked questions asked during interview                            </li>
                            <li>
                                Love Babbar’s one shot lecture for Operating Systems and DBMS.
                            </li>
                            <li>
                                Leetcode and GFG for practicing questions.
                            </li>
                            <li>Indiabix - Aptitude
                            </li>
                            <li>GFG last minute notes for theoretical subjects
                            </li>
                            <li>GFG articles for puzzles
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practice on a consistent basis. Make sure to revise the concepts after a few days. It is okay to look at solutions if you are just starting off your journey but make sure to note the approach somewhere and revise as needed. Also work on speed during online tests. You can practice in a timely manner by giving leetcode contests.Also you can solve leetcode question of the day. It will help you be in touch with problem solving.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            A lot of questions were asked about projects in both the interview rounds.Regarding extracurricular activities, they were briefly discussed in the HR round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            For Deutsche Bank, in the online assessment, there were only MCQs related to CN,OS,DBMS,DSA. There were no questions on quantitative/logical aptitude.Hence knowledge of the technical subjects would be beneficial. Other than that, focus on DBMS and OOPS and if possible CN/OS for the technical interviews.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Start early. Be mentally prepared that the internship season will start right after your third year starts.
                                </li>
                                <li>
                                    Be clear as to what you want to add to your resume. Do not add something of which you do not have knowledge. Resume is often the starting point of discussion in the interviews.
                                </li>
                                <li>
                                    Work on communication skills. Make sure to engage in a discussion with the interviewer instead of it being a viva session.
                                </li>
                                <li>Also think before you speak, especially in the HR Round. It is beneficial to prepare answers for standard HR questions beforehand.
                                </li>
                                <li>If you are stuck during an interview, make sure to think from the basics and then build further answers upon it.
                                </li>
                                <li>Luck plays a major part apart from your hard work. Accept it and prepare for further interviews/aptitude rounds.
                                </li>
                            </ol>
                            <br />
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:kalekp21.comp@coeptech.ac.in">
                            kalekp21.comp@coeptech.ac.in

                        </a>
                    </div>
                </div>
            </section >
        </div >
    );
};

export default ketan_kale;

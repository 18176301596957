import coordinator from "../../img/team/2019/coordinator.jpg";
import creativeshead from "../../img/team/2020/creativeshead.jpg";
import creativeshead2 from "../../img/team/2020/creativeshead2.jpg";
import eohead from "../../img/team/2020/e&ohead.jpg";
import eohead2 from "../../img/team/2020/e&ohead2.jpg";
import financehead from "../../img/team/2020/financehead.jpg";
import financehead2 from "../../img/team/2020/financehead2.jpg";
import hod from "../../img/team/2019/hod.jpg";
import president from "../../img/team/2020/president.jpg";
import techlead from "../../img/team/2020/techlead.jpg";
import techlead2 from "../../img/team/2020/techlead2.jpg";
import vicepresident from "../../img/team/2020/vicepresident.jpg";

const team2020 = [
  {
    post: [
      {
        initial: "Dr. (Mrs)",
        name: "Vahida",
        surname: "Attar",
        desc:
          "Head, Department of Computer Engineering and Information Technology",
        image: hod,
        mail: "mailto:hod.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/vahidacompcoepacin",
      },
      {
        initial: "Mr.",
        name: "Amit",
        surname: "Joshi",
        desc: "CSI COEP Student Chapter Coordinator",
        image: coordinator,
        mail: "mailto:adj.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/adjcompcoepacin",
      },
    ],
  },
  {
    post: [
      {
        name: "Soham",
        surname: "Parekh",
        position: "President",
        image: president,
        linkedin: "https://www.linkedin.com/in/sohamparekh/",
        github: "https://github.com/parekh0711",
      },
      {
        name: "Anup",
        surname: "Nair",
        position: "Vice President",
        image: vicepresident,
        linkedin: "https://www.linkedin.com/in/anupn08/",
        github: "https://github.com/AnupNair08",
      },
    ],
  },
  {
    post: [
      {
        name: "Swebert",
        surname: "Correa",
        position: "Technical Head",
        image: techlead,
        linkedin: "https://www.linkedin.com/in/swebert-correa/",
        github: "https://github.com/correaswebert",
      },
      {
        name: "Viraj",
        surname: "Jadhav",
        position: "Technical Head",
        image: techlead2,
        linkedin: "https://www.linkedin.com/in/viraj-jadhav-436497ab/",
        github: "https://github.com/VirajJadhav",
      },
      {
        name: "Deepika",
        surname: "Goyal",
        position: "Finance Head",
        image: financehead,
        linkedin: "https://www.linkedin.com/in/deepika9294/",
        github: "https://github.com/deepika9294",
      },
      {
        name: "Soumitra",
        surname: "Tonapi",
        position: "Finance Head",
        image: financehead2,
        linkedin: "https://www.linkedin.com/in/soumitra-tonapi-5b6699145/",
        github: "https://github.com/SoumitraTonapi",
      },
      {
        name: "Mohit",
        surname: "Baviskar",
        position: "Events & Operations Head",
        image: eohead,
        linkedin: "https://www.linkedin.com/in/mohit-baviskar-9005b7151/",
        github: "https://github.com/mohitbaviskar",
      },
      {
        name: "Amisha",
        surname: "Mala",
        position: "Events & Operations Head",
        image: eohead2,
        linkedin: "https://www.linkedin.com/in/amisha-mala-115428192/",
        github: "",
      },
      {
        name: "Manas",
        surname: "Nighrunkar",
        position: "Creatives Head",
        image: creativeshead,
        linkedin: "https://www.linkedin.com/in/manas-nighrunkar-1685a6192/",
        github: "https://github.com/Manas-nighrunkar",
      },
      {
        name: "Rhugaved",
        surname: "Narmade",
        position: "Creatives Head",
        image: creativeshead2,
        linkedin: "https://www.linkedin.com/in/rhugaved-narmade-010a94171/",
        github: "https://github.com/Rhugaved12",
      },
    ],
  },
];

export default team2020;

import React from "react";

const harshal_kausadikar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Harshal Abhay Kausadikar
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Deutsche Bank
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              There was one aptitude test. After aptitude test, there were 3
              Interview rounds:
              <ol>
                <li>Technical Round</li>
                <li>Professional Fitness Round</li>
                <li>HR Round</li>
              </ol>
            </p>
          </p>

          <p>
            <b>2. Structure of Aptitude Round :</b>
            <br />
            In the Aptitude Test, there were 10 MCQ questions and 2 coding
            questions. The test was online and the timing was 90 minutes. MCQ
            section consisting questions of SQL, Operating Systems, Computer
            Networks and some code snippets were given in Java/ Cpp and we had
            to predict the output.
            <br />
            Out of two Coding Questions, one question was similar to -
            <a
              href="https://www.codingninjas.com/codestudio/problems/799354?topList=striver-sde-s
heet-problems&utm_source=striver&utm_medium=website"
            >
              Next Greater Element
            </a>
            <br />
            The second question was based on XOR operations. I was not able to
            solve the second question.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              The first was an aptitude test about which I have already
              mentioned. The information about interview rounds is as follows:
              <br />
              <ol>
                <li>
                  Technical Round - The interviewer first asked me to introduce
                  myself. He asked me about the positions and responsibilities
                  in the club and about my work experience that I have mentioned
                  in my resume. Then he asked me to explain my DSA project
                  (Cryptography using Binary Trees ADT) in brief on paper. He
                  asked me to dry run some cases. Then he asked me many
                  questions on oops, some of them were:
                  <ul style={{ listStyle: "circle", paddingLeft:"40px " }}>
                    <li>What is meant by OOP?</li>
                    <li>
                      How is it different from other programming paradigms?
                    </li>
                    <li>What are the four pillars of oops?</li>
                    <li>What is encapsulation?</li>
                    <li>How abstraction is achieved?</li>
                    <li>Write down the example of Run time polymorphism?</li>
                    <li>What are abstract base classes?</li>
                  </ul>
                  He almost asked me everything about oops. After that he said
                  your oops is good now let's check your DSA and gave me this
                  question to solve : He asked me to explain different
                  approaches and write the optimized code on paper. He also
                  asked me to dry run some cases. This round lasted for{" "}
                  <b>50 </b>
                  minutes.
                </li>
                <li>
                  Professional Fitness Round: This round is mainly to check your
                  personality and thinking ability. Interviewer first introduced
                  himself and then asked me to introduce myself. He asked me
                  about my hobbies. Then he asked me some questions like below:
                  <ul style={{ listStyle: "circle", paddingLeft:"40px " }}>
                    <li>
                      Why have you chosen this technology for a project over
                      another?
                    </li>
                    <li>
                      What challenges did you face during your project and how
                      did you fix them?
                    </li>
                    <li>How do you manage your time?</li>
                    <li>How do you handle your stress and failures?</li>
                    <li>Why do you want to be at Deutsche Bank?</li>
                    <li>What are your strengths and weaknesses?</li>
                    <li>Have you done anything to overcome your weakness?</li>
                  </ul>
                  At last he asked me if I had any questions for him. I asked
                  him two questions. After that he concluded the interview. This
                  round lasted for 40-45 minutes.
                </li>
                <li>
                  HR Round: This round was online. I was asked to join the
                  meeting. Again the interviewer asked me to introduce myself.
                  It was a very relaxed round. She asked me some common HR
                  questions like what are your hobbies, tell me about your
                  family background, why you want to join DB. At last she asked
                  me if I had any questions for her. I asked her two questions.
                  This round lasted for 20-25 minutes.
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>4. Some good resources you used in your internship prep</b>
            <p>
              <ol>
                <li>
                  For Coding:
                  <a href="https://takeuforward.org/interviews/strivers-sde-sheet-top-coding-interview-problems/">
                    Striver’s SDE Sheet – Top Coding Interview Problems
                  </a>
                </li>
                <li>
                  For DBMS:
                  <a href="https://www.w3schools.com/sql/default.asp">
                    W3 Schools
                  </a>
                </li>
                <li>
                  For OS:
                  <a href="https://www.youtube.com/playlist?list=PLDzeHZWIZsTr3nwuTegHLa2qlI81QweYG">
                    Love Babbar
                  </a>
                </li>
                <li>For Computer Networks, read last minute notes on gfg.</li>
              </ol>
            </p>
          </p>

          <p>
            <b>5. Some tips to crack coding questions</b>
            <br />

            <p>
              Solve at least 2-3 coding questions daily. Don’t solve questions
              randomly, take any good SDE sheet and solve it consistently. Even
              after solving a question, try to solve that question in multiple
              ways. Always remember it doesn’t matter the number of questions
              you solve, but how you solve them. So just don’t do questions to
              increase the count.
            </p>
          </p>

          <p>
            <b>
              6. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>{" "}
            <br />
            <p>
              There were so many questions on my project in first two
              interviews. So make sure that you are attaching only those
              projects with which you are comfortable. I advise everyone to do
              projects seriously and by yourself. In pro-fit round, I was asked
              about my position and responsibility in clubs.
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              If you are preparing for Deutsche Bank, try to cover the basics of
              CN, DBMS and if possible OS. Because in aptitude round MCQ’s were
              based on these concepts. It might be possible that the interviewer
              can ask you about this in an interview. Also in the Professional
              Fitness round, answer the questions confidently and thoughtfully.
              Because the interviewer will always ask you questions depending on
              your previous answers
            </p>
          </p>
          <p>
            <strong>8. Final Tip for Juniors?</strong>
            <p>
              My first advice to all the juniors is that don’t ignore CGPA
              because it plays an important role in On-campus placements and
              internships. Even if you don’t get selected in your dream company,
              don’t lose hope. There are some factors which we don’t have
              control over, so don’t stress too much. Try to give your best and
              most important believe in yourself. Talk with seniors, parents and
              friends. Best of luck !!
            </p>
          </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:kausadikarha20.comp@coep.ac.in">
              kausadikarha20.comp@coep.ac.in
            </a>
            <br />
            LinkedIn:{" "}
            <a href="https://www.linkedin.com/in/harshal-kausadikar-805b55204/">
              Harshal Kausadikar
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default harshal_kausadikar;

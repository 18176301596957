import React from "react";

const soham_parekh = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Soham Parekh</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            DE Shaw &amp; Co.
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />
          <p>
            <h4 id="stage-1-aptitude-test">Stage 1 - Aptitude Test</h4>
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>
                The process began with an aptitude test conducted on Hackerrank.
              </li>
              <li>
                Judging by the attendance of the presentation, around 260
                students were shortlisted for this test.
              </li>
              <li>
                It consisted of 4 sections, with a fixed amount of time allotted
                to each. They were:
                <ul style={{ listStyleType: "circle", marginLeft: "3rem " }}>
                  <li>Coding Section (20 minutes)</li>
                  <li>Quantitative Section (20 minutes)</li>
                  <li>Technical Section (20 minutes)</li>
                  <li>Systems Section (15 minutes)</li>
                </ul>
              </li>
              <li>
                Each of the MCQ sections had +2 for correct answers and -0.5 for
                incorrect answers.{" "}
              </li>
            </ul>
            <h5 id="-coding-section-">
              <em>Coding Section</em>
            </h5>
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>
                There was just one question, which was relatively easy. The
                question was as follows:
                <blockquote>
                  <p>
                    There are 9 stones with weight 1-9. The input consists of
                    two integers M and S, the output needs to be an M sized
                    string consisting of the given stones such that the sum of
                    the weights equals S. The stones should be arranged in non
                    increasing order, and the combination should be such that
                    the sums of squares of the weights of the stones (which was
                    the stability of the stones) should be maximised.
                  </p>
                  <li>
                    It seemed like a DP problem at first, but the stability
                    condition, the existence of the stone with weight 1 along
                    with the M size constraint meant a greedy approach would
                    work, and I was able to pass all test cases.
                  </li>
                </blockquote>
              </li>
              <li>
                This section is definitely the <strong>most important</strong>,
                if you ace this, you have a headstart.
              </li>
            </ul>
            <h5 id="-quantitative-section-">
              <em>Quantitative Section</em>
            </h5>
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>
                I was a bit overconfident about this section, and so I messed
                up.
              </li>
              <li>
                The questions weren&#39;t tough, but they were pretty lengthy,
                so I ended up leaving a lot of them.{" "}
              </li>
              <li>
                Practice them before the test from{" "}
                <em>
                  <a href="https://www.indiabix.com/aptitude/questions-and-answers/">
                    Indiabix
                  </a>
                </em>
                . This website is used by pretty much everyone.
              </li>
              <li>
                Try to figure out which questions are easier than the others and
                prioritise. Attempt as many as you can.
              </li>
            </ul>
            <h5 id="-technical-section-">
              <em>Technical Section</em>
            </h5>
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>This section was relatively easy.</li>
              <li>
                Most of the questions were output questions and were doable.
              </li>
              <li>Be familiar with C type syntax and you can get them.</li>
            </ul>
            <h5 id="-systems-section-">
              <em>Systems Section</em>
            </h5>
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>
                This section was very easy if you are good at answering GK
                questions on Computer Science and have some basic Computer
                Networking knowledge.
              </li>
              <li>
                Some of the questions I remember:
                <blockquote>
                  <p>
                    Which of the following is not a Cloud Computing Service?
                    (Alibaba, Godaddy, Azure, AWS) What is the meaning of
                    phishing? Which of the following statements are true about
                    DHCP? (I don&#39;t remember the options) What do you mean by
                    CORS?
                  </p>
                </blockquote>
              </li>
              <li>
                This section had a lot of time, I had 5-8 minutes to spare.
              </li>
            </ul>
            <h4 id="stage-2-technical-interview">
              Stage 2 - Technical Interview
            </h4>
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>16 students were shortlisted from Stage 1.</li>
              <li>
                My interview was conducted by two people, and it was sort of
                like good cop bad cop.
              </li>
              <li>
                The interview began with questions related to my projects
                (though not as in depth as I had expected)
              </li>
              <li>
                To crack DE Shaw, you need to have really good projects on your
                resume, and you need to know them as well as you possibly could,
                possible questions could be:
                <blockquote>
                  <p>
                    How does this improve existing technology? What could you
                    have done better? If you started this project today, How
                    would you approach it? etc etc etc
                  </p>
                </blockquote>
              </li>
              <li>
                However, the questions in my interview were based on OS and CN,
                even though my interviewer was aware I hadn&#39;t taken those
                courses. Some of them were:
                <blockquote>
                  <p>
                    Name some differences between Linux and Windows (NOT basic
                    ones) Which processor does your laptop have? What do you
                    know about it? Do you have any idea about the architecture
                    of your processor? Why can you access Windows files from
                    Linux on a dual booted laptop, but not the other way around?
                  </p>
                </blockquote>
              </li>
              <li>
                This was the toughest round in the entire process for me as I
                was criticised on pretty much all my answers (even the ones I
                was 100% sure about!).{" "}
              </li>
            </ul>
            <h4 id="stage-3-technical-interview">
              Stage 3 - Technical Interview
            </h4>
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>
                After my disappointing first interview, being one of the 8
                students shortlisted for Stage 3 was an unexpected and pleasant
                surprise.
              </li>
              <li>
                My guess would be that I was selected because of the coding
                section (I didn&#39;t know anyone else who scored full), so{" "}
                <strong>make sure you ace that</strong>.
              </li>
              <li>
                This interview began with a discussion on my projects, and it
                was a long one. My DSA project was Chess Engine. I was asked all
                the questions I mentioned earlier, and more:
                <blockquote>
                  <p>
                    What algorithms could you have implemented on top of what
                    you did? Why are those algorithms better? Which other
                    engines do you know? What algorithms do they use?
                  </p>
                </blockquote>
              </li>
              <li>
                Be prepared for any sort of questions on your projects. It is
                extremely important.
              </li>
              <li>
                After projects, we moved on to DSA questions. I was asked
                questions on Abstraction, Encapsulation, Inheritance and
                Polymorphism (in as much detail as possible).{" "}
              </li>
              <li>
                I had to answer in detail about how I could implement these
                concepts, which languages supported them, etc. This went on for
                about 15 minutes.
              </li>
              <li>
                The interview ended with a general discussion on my resume, the
                courses I took online and why I chose them, and the experience I
                had as President of CSI COEP.
              </li>
              <li>
                After an hour or so, we were eagerly waiting for the selected
                students for the HR round, instead we got the final results,
                they had selected 2 students and I was lucky enough to be one of
                them.
              </li>
            </ul>
            <h4 id="miscellaneous">Miscellaneous</h4>
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>
                I practiced Python and Problem Solving on Hackerrank (almost all
                night long for a month). After Hackerrank I referred some
                questions on other websites like codewars, leetcode, codechef,
                etc.{" "}
              </li>
              <li>
                I completed a few courses and a specialisation on Coursera
                (These are an added bonus on you resume), all courses were on
                Python. However, do them only if you have extra time.
              </li>
              <li>
                I had 5 projects on my resume, I would suggest you do atleast
                two big ones, and one small one, if not more.
              </li>
              <li>
                Before the interviews, make sure you see the previous questions
                on GeeksforGeeks, they have archives for almost every company.
              </li>
              <li>
                For DE Shaw, do go through the basics of OS and CN from Cracking
                the Coding Interview, but try to direct your interviewer away
                from those questions, as you would be better off talking about
                DSA or your projects.
              </li>
              <li>
                Don&#39;t keep a bias in your head for any company, don&#39;t
                think you do not have a chance because your CGPA isn&#39;t in
                the top 10, or even top 50. As long as your fundamentals are
                strong, and your projects are great, you are just as likely to
                be selected.
              </li>
              <li>
                Stay motivated if you mess up your interview (like I did), or
                even if you lose out on the company, there&#39;s a lot of them
                out there, and you will get what you deserve if you work hard
                enough.
              </li>
              <li>Feel free to message me for any doubts you might have.</li>
            </ul>
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:parekhsk18.comp@coep.ac.in">
              parekhsk18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default soham_parekh;

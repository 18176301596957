import React from "react";

const preet_trivedi = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Preet Trivedi
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There was one interview of 25 minutes.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The test was conducted on the AMCAT platform. It consisted of a Verbal Ability & Comprehension Section(~12 questions), Graphical & Statistical Interpretation(~12 questions), 3 coding questions. All questions were compulsory and there was no negative marking. <br />
                            Difficulty Level: Easy
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There was only one round which included a mix of Technical as well as HR questions. I was asked a couple questions relating to the subjects from my second year as the interview process took place at the beginning of the 5th Semester. I was also asked about my knowledge on Information Security and a Cloud certification I was preparing for.
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        20 students were selected for the Interview Round after clearing the AMCAT.
                        10 students were selected for the internship after the interview.

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        IndiaBix (for quantitative questions), EduGorilla (for Mock AMCATs), GeeksForGeeks (for Technical Interview Questions), LeetCode (‘ApnaCollege DSA Questions’)
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Make sure to have your basics for DSA clear. Focus your efforts on a single language instead of practising multiple languages (I studied Java). Most questions are based on arrays and string implementation.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            I was asked to explain all my projects in detail, right from the ideation phase to the implementation. I was also asked a couple questions on my volunteering and extracurricular activities, though not in detail. I was asked in detail about the solution I presented for a Hackathon I had participated in. I was also asked about my certifications.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Go through their website for their vision, values, principles. Also make sure to read the job description posted on Superset as it highlights the qualities they look for in an intern, which you can strengthen to gain an advantage. Go through the basics of a Candlestick graph and know how to read and interpret basic graphs like Bar chart, Line graph,etc.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Try to maintain a CGPA above 8.0. Besides coding, also focus on your quantitative aptitude as it is very scoring and most aptitude tests have negative marking for the same.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:trivedipd21.comp@coeptech.ac.in">
                            trivedipd21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default preet_trivedi;

import React from "react";

const nikita_tipule = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Nikita Tipule
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              Goldman Sachs was the third company to visit COEP. There was no
              branch restriction and not any CGPA criteria. Around 550 students
              from all the branches sat for the selection process.
            </p>
            <p>
              The entire internship hiring process was structured into two
              phases i.e Aptitude test and Interview rounds ( most probably two
              to three rounds ). After the aptitude test around 26 students were
              shortlisted for the first interview round.{" "}
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              The entire internship hiring process was structured into 2 phases.
            </p>
            <ul
              style={{
                marginLeft: "1rem",
                listStyleType: "disc",
              }}
            >
              <li>
                <p>
                  Aptitude test which consists of 5 sections
                  <ol>
                    <li>Quantitative section</li>
                    <li>
                      Coding section – 2 questions (easy/medium difficulty
                      level)
                    </li>
                    <li>Technical section</li>
                    <li>
                      Coding section – 1 question (medium/hard difficulty level)
                    </li>
                    <li>Essay writing – 2 Essays </li>
                  </ol>
                </p>
              </li>
              <li>Interview rounds usually consists of two to three rounds.</li>
            </ul>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              Total 3 rounds were there for the interview. Round 1 and 2 were
              Technical rounds and Round 3 was Technical plus HR round
            </p>
            <ol>
              <li>
                <p>
                  <strong>Round 1 (Technical Round):</strong>
                </p>
                This round started with asking me the questions on the projects
                that I had mentioned on my resume. I was asked to explain each
                and every project and how it works. I was given some situations
                or inputs on my project and was asked to explain how my program
                would interpret it and will it give the correct, required
                output. After that I was given coding question to solve and
                asked me how many ways we can solve that particular problem.
                Questions on searching techniques was asked. Some question were
                on binary trees and heap. After that some of the questions were
                based on OOPs. This round lasted for about 35-40 minutes.
              </li>
              <br />
              <li>
                <p>
                  <strong>Round 2 (Technical Round):</strong>
                </p>
                This round started with live coding on hacker rank. This round
                was mostly based on coding questions. I was given some problem
                statements and was asked to write code. After that they asked
                questions on the projects which I had mentioned on my resume.
                Questions like which technology I worked with and questions
                related to it. Few basic questions on DBMS and the layers in OSI
                model. At last he asked me about the position of
                responsibilities that I mentioned on my resume and the questions
                like what have you done in this position and so on.
              </li>
              <br />
              <li>
                <p>
                  <strong>Round 3 (Technical Round + HR Round):</strong>
                </p>
                This round started with the questions like my interests,
                hobbies. My interviewer asked me to explain any difficult
                situation you have faced in your life. Then I was given some
                situations and was asked what I would do in this situation.
                Later few basic questions on Data structure and one coding
                question. I was only asked to explain the approach.
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <br />
            <p>
              I would say a lot of questions were asked to me based on my
              projects. I would suggest that be wise when you are putting your
              projects on resume. In every round, I was asked questions based on
              my resume.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li>I referred Indiabix for aptitude questions.</li>
              <li>I referred GeeksForGeeks for DSA.</li>
              <li>
                I practiced coding on hackerrank and leetcode. I would suggest
                to practice a lot of coding questions.
              </li>
              <li>I would suggest to use C++ or Python for coding. </li>
            </ul>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Practice lots of Questions. When you are solving, you will feel
              that the questions are very big. But then try to break it into
              small parts and try to think of basic approach and then try to
              optimise it.
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            Prepare DSA well and solve as many coding questions. You should have
            at least basic knowledge of DBMS and CN. Keep the interview
            interactive. Do not hesitate to ask questions to the interviewer. Be
            confident in what you speak. If you are not able to give the proper
            solution to the coding question, keep suggesting solutions,
            interviewer gives hints, then you can reach to the final one.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:tipulens19.comp@coep.ac.in">
              tipulens19.comp@coep.ac.in
            </a>
            {/* <br />
            <a href="https://www.linkedin.com/in/anupn08/">LinkedIn Handle</a> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default nikita_tipule;

import React from 'react'
import SectionHeading from './SectionHeading'
import Certificates from "../components/Certificates";
import Editorial from './Editorial';
import { backGround } from "../layout/customStyle";
import Navbar from "../layout/Navbar";
import { ArrowBack } from "@material-ui/icons";
import Footer from "../layout/Footer";
import {Button} from "@material-ui/core";
const Codequestviewmore = (props) => {
  return (
    <>
    <Navbar event={true}/>
    <div style={backGround}>
      <center>
    <Button
                color="primary"
                onClick={() => {
                 

                    props.history.push(
                      `/eventPage/2022-23`
                    );


                  }}
                  
                 

                  
              
                startIcon={<ArrowBack />}
                style={{
                  color: "white",
                  fontSize: "25px",
                  marginTop:"2rem"
                }}
              >
                Go back
              </Button>
              </center>
      <SectionHeading title="CODEQUEST 3.0: CERTIFICATES"/>
      <Certificates></Certificates>
      <SectionHeading title = "CODEQUEST 3.0 : EDITORIAL"/>
      <Editorial></Editorial>
      <Footer />
    </div>
    
    </>
  )
}

export default Codequestviewmore

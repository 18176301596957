import React from "react";

const rhugaved_narmade = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Rhugaved Narmade
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Barclays</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            There were only 2 rounds, the first round was a written/aptitude
            test followed by an interview of the shortlisted candidates from the
            first round.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The questions were all technical, and mostly on Java with a few
            questions on Java Servlet. There were questions on DBMS, Data
            Structures, OS, etc. Quite direct questions and the level of
            difficulty was moderate to tough.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            We were told that there will be 2 rounds, but the selection process
            was completed in just one. It was a mixed round, including technical
            and HR. After my introduction, I was asked about my projects and I
            was asked to explain a couple of the projects followed by questions.
            After that, I was asked some basic questions on Python and Data
            Structures. Lastly, some HR level questions to end up the interview.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            The interviewer had looked up my resume and then asked me to explain
            a couple of my projects and asked few particular questions on them.
            There were no specific questions on my resume as such.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <a href="https://geeksforgeeks.com">GFG</a>. Let me tell you that
            clearly: Use GEEKS FOR GEEKS. That's all.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions</b> <br />
            Consistency is key. You need to spend at least some time every day
            preparing for the internship process.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            To crack the Aptitude test you need to have extensive knowledge of
            Java and the 4 pillars of computer science: DSA, CN, DBMS, and OS.
            Also, be thorough on your projects, and most importantly be
            confident and SMILE!
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:narmaderr18.comp@coep.ac.in">
              narmaderr18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default rhugaved_narmade;

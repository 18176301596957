import React from "react";

const harish_choudhary = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
        Harish Choudhary
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Credit Suisse</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
           <p> The entire process was divided into four phases : Resume Shortlisting, Coding Round, Technical Round, HR Round.</p>
Coding Round was conducted on 16th November 2021, while other two rounds were conducted on around 19th November 2021.

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            Coding Round was having total five questions. Out of which first two were theory MCQ questions while last three were coding questions. First Coding question was kind of easy compared to other two. 2nd question was of moderate type while 3rd question was of hard difficulty.

          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
           <p>
           There were total 2 rounds : 
           <p>1 Technical Interview and 1 HR Interview.</p>
<b>Technical Interview : </b> It was started with my brief introduction. Then interviewers asked me about OOPS Concepts. They also asked me about my favourite subject in college. I said Computer Networks and DBMS. Then they started asking questions on DBMS like what does select query do, what’s its complexity, etc. They also asked me about HTTP Protocol, DNS, Application Server, etc.




           </p>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>
            <br />
            <p class="has-line-data" data-line-start="48" data-line-end="51">
            While answering regarding HTTP Protocol, I plugged in that I recently made HTTP Server as a semester Project. Then they started asking leading questions on that project like what technologies have you used, What’s it’s purpose, etc.
After that, they started asking questions on HTML, CSS and Javascript as it was mentioned on my resume. Like they were giving me some cases and asking how can you implement this in HTML and Javascript.


            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <p class="has-line-data" data-line-start="54" data-line-end="56">
            <li><b>Technical Interview :</b> GeeksForGeeks, Javatpoint, Tutorials Point</li>
            <li><b>HR Interview  :</b> InterviewBit </li>


            </p>
          </p>

          <p class="has-line-data" data-line-start="57" data-line-end="58">
            <strong>6. Some tips to crack the coding questions.</strong>
            <p class="has-line-data" data-line-start="59" data-line-end="64">
            Solve questions consistently.

            </p>
          </p>
          <p class="has-line-data" data-line-start="57" data-line-end="58">
            <strong>7. Is there something specific that you would want to tell people who try for the same company?
</strong>
            <p class="has-line-data" data-line-start="59" data-line-end="64">
            Always lead to your strong points. As they mostly asked leading questions to me on the technologies mentioned on my resume.

            </p>
          </p>

         
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:choudharyhf19.comp@coep.ac.in">
            choudharyhf19.comp@coep.ac.in

            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default harish_choudhary;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
// import axios from "axios";

const useStyles = makeStyles((theme) => ({
  terminal_root: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
    },

    // height: "500px",
    // width: "100vw",
  },

  terminal_inner: {
    // position: "relative",
    display: "flex",
    flexDirection: "column",

    color: "#e0def4",
    background: "#212529",
    borderColor: "#54708a",
    // background: "rgba( 178, 254, 250, 0.80 )",
    width: "45rem",
    // height: "calc(0.666 * 85vw)",
    maxWidth: "95vw",
    maxHeight: "fit-content",

    padding: "0.5rem 1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
    borderRadius: "5px",
    border: "1px solid #333",
    // fontFamily: "monospace",
    fontSize: "14px",
    // lineHeight: "1.5",
    // whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  },

  terminal_header: {
    display: "flex",
    alignItems: "center",
    height: "34px",
    marginBottom: "0.5rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0.25rem",
      height: "16px",
    },
  },

  icon1: {
    // marginLeft: "12px",
    backgroundColor: "#ff5f56",
  },
  icon2: {
    marginLeft: "8px",
    backgroundColor: "#ffbd2e",
  },
  icon3: {
    marginLeft: "8px",
    backgroundColor: "#27c93f",
  },

  terminal_icon: {
    borderRadius: "50%",
    display: "inline-block",
    width: "12px",
    height: "12px",
  },
  anchorTag: {
    color: "#b2bbbd",
    textDecoration: "none",
    fontWeight: "bolder",
    "&:hover": {
      textDecoration: "none",
      color: "#fff",
    },
  },
}));

const Terminal = () => {
  const classes = useStyles();
  const [tip, setTip] = useState({});

  useEffect(() => {
    async function getTip() {
      try {
        let data = JSON.parse(sessionStorage.getItem("tip"));
        if (!data) {
          const result = await fetch(
            `https://csi.coep.org.in/tipoftheday/tipoftheday`
          );
          // const result = await fetch(
          //   `https://gnews.io/api/v4/search?q=Microsoft%20OR%20Google%20OR%20Intel&lang=en&token=${process.env.REACT_APP_API_KEY}`
          // );
          data = await result.json();
          sessionStorage.setItem("tip", JSON.stringify(data));
         // console.log(data);
         // console.log("S", data.id);
        } else {
          //console.log("R", data.id);
        }
        // let i = 0;
        // while (
        //   data?.articles[i].title == null ||
        //   data?.articles[i].description == null ||
        //   data?.articles[i].url == null
        // )
        //   i++;
        setTip(data);
      } catch (error) {
        console.error(error);
        setTip({ title: "M1 is a RISC based processor." });
      }
    }
    getTip();
    // axios
    //   .get("http://34.207.220.189:4000/getTip", {
    //     headers: { "Acess-Control-Allow-Origin": "*" },
    //   })
    //   .then((res) => console.log(res))
    //   .catch((err) => console.error(err));
  }, []);

  return (
    <div className={classes.terminal_root}>
      <div className={classes.terminal_inner}>
        <div className={classes.terminal_header}>
          <span className={clsx(classes.terminal_icon, classes.icon1)}></span>
          <span className={clsx(classes.terminal_icon, classes.icon2)}></span>
          <span className={clsx(classes.terminal_icon, classes.icon3)}></span>
        </div>
        <div className={classes.terminal_body__3TQCw}>
          <p
            style={{
              fontSize: "1.2rem",
              whiteSpace: " pre-wrap",
              wordBreak: " break-word",
            }}
          >
            <span
              style={{
                color: "#27c93f",
                fontSize: "1.2rem",
              }}
            >
              <b>csi@coep:$ </b>
            </span>
            <b>{tip.title}</b>
            <br />
            <SyntaxHighlighter language={"" + tip.language} style={atomDark}>
              {"" + tip.description}
            </SyntaxHighlighter>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terminal;


import React from "react";

const shreya_singhal = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{ fontSize: "2em", position: "absolute", marginTop: "2rem" }}
          />
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Shreya Singhal
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <p>
            <br />
            Goldman Sachs was the third company, following DE Shaw and Arcesium,
            to visit CoEP for internship recruitment. It was probably the only
            company to not have any CGPA or branch criteria. Students from all
            branches sat for the selection process. The process consisted of an
            aptitude test followed by technical and HR interview rounds.
          </p>
          <br />
          <h5>THE APTITUDE TEST</h5>
          <p>
            The Aptitude test was conducted on Hackerrank
            <br />
          </p>
          <ul>
            <li>
              The duration of the test was 2 hours approximately.
              <br />
              There were 4 sections - aptitude, technical, coding and writing,
              each section being timed separately.
            </li>
            <br />
            <li>
              There were around 20 aptitude questions to begin with. These
              questions were based on basic logical topics like time and work,
              speed distance and time, ratio and proportion, simple and compound
              interest, data interpretation, probability, permutation and
              combination and basic math.
            </li>
            <br />
            <li>
              Next, there were 15 technical questions on basic programming
              principles, predicting the output questions and data structure
              questions based on binary tree operations, stack and queue
              principles.
            </li>
            <br />
            <li>
              The coding section had 2 problem statements based on data
              structures. One was a string manipulation question and the other
              was a dynamic programming question. There was also a bonus coding
              question. There were multiple test cases in each of these
              questions and you will receive partial marks for each test case
              that you pass. You are free to use any programming language of
              your choice.
            </li>{" "}
            <br />
            <li>
              The last section was the essay writing section. We were given two
              essays to write:
              <ul style={{ fontSize: "1em" }}>
                <li>
                  “Describe an incident where you faced a difficult
                  time/situation and narrate how you tackled this situation and
                  what you learned from this experience”
                </li>
                <li>
                  {" "}
                  “If the dates of your college internal exams and Goldman Sachs
                  interview clash, what would you do?”
                </li>
              </ul>
            </li>
          </ul>
          <p>
            This section was not graded but the student was evaluated for his
            thinking process and decision making ability.
          </p>
          <p />
          <h5>THE INTERVIEW ROUNDS</h5>
          <p>
            <strong>ROUND 1</strong>
            <br />
          </p>
          <p>
            A total of 23 students were selected after the results of the
            aptitude test. Few students had a technical round and some had a HR
            round. For me, the HR round was first which was taken by the Vice
            President of Goldman Sachs of the Asia Pacific region.
          </p>
          <p>
            The minute I got to know she would take my interview, I was very
            scared. She seemed very intimidating during her talk in the Pre
            Placement Talk. But when I entered the room, surprisingly there was
            an air of comfort and within 2 mins, I felt relaxed. Initially we
            started speaking about the weather, you know the basic weather talk
            and I think it was in that weather talk itself that I had made
            myself very comfortable and confident. There was not a sign of
            fluster on my face or hitch in my voice. <br />
            She started off by asking me to introduce and talk about myself. The
            important part of this process is to emphasize that part of your
            life that you are most proud and confident about. The interviewer
            questions you based on what material you give them to ask. So this
            part of the interview is in your hands. You can choose what you want
            to talk about. Next she scanned through my resume and asked me
            questions specific to my resume.
          </p>
          <p>
            She asked me questions like, “Why did you opt for the Finance for
            Engineers course?”, She asked me questions related to the projects
            that I had listed in my resume. Not very technically but to make
            sure I knew what I had listed. In every project that you list, make
            sure you know how it can be improved. For eg - I had a machine
            learning project which performed with an accuracy of 85%. But I knew
            methods that I could implement to improve the accuracy. That was one
            very useful tip given to me by my dad and it seemed to have
            impressed the interviewer. <br />
            She then asked me questions related to team work and basic
            etiquettes. To quote a few - “If you are a part of a team of 5 and
            each one of you are given two tasks to be completed in a span of 10
            hours. But at the end of 8th hour, you haven’t even completed the
            first, what would you do? The completion of second depends on the
            first” Lastly she asked me, “Why Goldman Sachs?”. A very very
            important and tricky question. Make sure you have some knowledge of
            the company before you sit for the interview.
          </p>
          <p>
            In all it was a fun interview and I actually seemed to have liked
            talking to her. This is a very rare case with most HR interviews but
            yeah, it went well for me.
          </p>
          <p>
            <strong>ROUND 2</strong>
            <br />
          </p>
          <p>
            At the end of the 1st round, 15 candidates were shortlisted. In this
            round, those who had a technical round previously, now had a HR
            round and vice versa. Though some even had a technical round again.
            <br />
            <br />
            During my technical round, I was given two DSA questions and I had
            to first explain my logic verbally. If that didn’t satisfy the
            interviewer, I had to write the pseudo code on paper.
            <br />
            The questions were: <br />
            “How would you sort an array of 0s and 1s in ascending order in O(n)
            time”.
            <br />
            “How would you sort an array of 0s, 1s and 2s in ascending order in
            O(n) time."
            <br />
            Then he asked me application based questions like - “Design the
            database of what you think Linkedin is using to store all its users’
            data”. There were two-three more questions on similar lines.
            <br />
            <br />
            Next he questioned me on my resume and projects. Before ending the
            interview he asked me if I had any questions for him. Apart from
            asking typical questions like, “What work would I be given?” etc, it
            would be very nice if you asked them something related to the PPT
            that they show before the interviews. It gives them an opportunity
            to boast about their company and at the same time show that you are
            actually interested in the work that they do.
          </p>
          <p>
            <strong>ROUND 3</strong>
            <br />
          </p>
          <p>
            After Round 2, they started calling each student individually for
            another round. They didn’t call me thankfully.
            <br />
            <br /> Round 3 was the last round and after waiting impatiently for
            45 min, they finally announced the results. They selected 5 students
            from our college and I was fortunate enough to be one of them.
            <br />
            <br /> It was one hell of a memorable experience but I would do all
            it takes to not have to go through this again!
          </p>
          <br />
          <p></p>
          <h5>TIPS FOR PREPARATION</h5>
          <br />
          <ul>
            <li>
              I referred to RS Agarwal and
              <a href="https://www.indiabix.com/aptitude/questions-and-answers/">
                {" "}
                Indiabix{" "}
              </a>{" "}
              for aptitude questions.
            </li>
            <br />
            <li>
              I practiced coding on HackerRank which is also what most companies
              will use for their coding section.
            </li>
            <br />
            <li>
              {" "}
              I preferred to use C++ for all my practice since it was less
              troublesome compared to C and faster when compared to python.
              Python is very easy to code in but it is slow and so test cases
              might timeout. C++ is fast, less syntax and many beautiful STL
              libraries make your life simple.
            </li>
            <br />{" "}
            <li>
              I thoroughly went through all Geek for Geeks material on DSA
            </li>
            <br />
            <li>
              Databases is also one very important topic that interviewers like
              to question on. So I had my basics of SQL ready.
            </li>
            <br />{" "}
            <li>
              Have a general knowledge on topics like Networking and Operating
              Systems.
            </li>
            <br />{" "}
            <li>
              Keep in frequent touch with your seniors and ask them questions
              specific to the company.
            </li>
            <br />{" "}
            <li>
              Have mock interviews at home with parents or friends. The practice
              will reflect on the final day.
            </li>
            <br />{" "}
            <li>
              I used my entire summer vacation to prepare for this process. So
              utilize this lockdown period well and get started.
            </li>
          </ul>
          <p />
          <br />
          <p>
            Feel free to ask me queries and discuss any topic in detail. All the
            best!
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15em" }}>
            <a href="mailto:shreyas17.it@coep.ac.in">shreyas17.it@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default shreya_singhal;

import React from "react";

const harsh_pande = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Harsh Pande
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              <ol>
                <li>
                Aptitude Test
                </li>
                <li>
                  Written Test
                </li>
                <li>
                  Group Discussion
                </li>
                <li>
                  Technical Interview + HR
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>2. What was the sturcture of aptitude round?</b>
            <br />
            <ol>
              <li>
                Technical Section
              </li>
              <li>
                Quant Section
              </li>
              <li>
                Language Section
              </li>              
            </ol>
          </p>

          

        

          <p>
            <b>
              3. Some good resources you used in your internship prep
            </b>
            <br />
            For this particular company, basic DSA knowledge will be sufficient (No need of deep DSA concepts). Normal programming skills and in general mathematical knowledge (school level) will be sufficient. <br />
            For other companies, I would recommend – <br />
For aptitude test: <a href="https://www.indiabix.com">https://www.indiabix.com</a> <br /> Love Babbar's 450 DSA sheet: <a href="https://drive.google.com/file/d/1FMdN_OCfOI0iAeDlqswCiC2DZzD4nPsb/view">450 DSA sheet</a> <br /> For other Core subjects (OS, DBMS, CN, OOPS) GFG: <a href="https://www.geeksforgeeks.org">https://www.geeksforgeeks.org</a> Have a look at Love Babbar’s Youtube channel - <a href="https://www.youtube.com/channel/UCQHLxxBFrbfdrk1jF0moTpw">Love Babbar</a>
          </p>

          <p>
            <b>
              4. Some tips to crack coding questions
            </b>
            <br />

            <p>
            For this company, basic programming knowledge (like arrays, strings, loops, functions, etc) is sufficient</p>
          </p>

          <p>
            <b>5. How many questions were asked about projects, clubs and extracurricular
              activities in your resume</b> <br />
            <p>
            They ask deeply on projects that I mentioned in the resume. Just asked to give overall idea and structure (in general) about the projects.and few question on data structure and algorithm</p>
          </p>

          <p>
            <strong>
              8. Is there something specific that you would want to tell people who try for the same company
            </strong>
            <p>
            You need to have good communication skills for GD. Deliver your thoughts confidently. Keep your academic cgpa as good as possible, because it may add value to your performance. Prepare beforehand for some common questions like your introduction, strengths and weaknesses, your future plans, etc.
            </p>
          </p>

          <br />
          
        </div>
      </section>
    </div>
  );
};

export default harsh_pande;

import React from "react";

const vasu_sharma = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Vasu Sharma</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            Firstly, there was an aptitude round. Goldman Sachs has no branch
            restrictions, so students from all branches can apply. Though they
            did some random shortlisting for the aptitude test, based on some
            unknown criteria for aptitude test. 27 students were shortlisted
            after aptitude test. Then, there were 3 interview rounds. In the
            first round, they selected 16 out of 27. In the second round, they
            selected 10 out of 16. Finally, in the 3rd round, 6 out of 10 got
            the offer. All the three rounds were technical rounds. There was no
            HR round.
            <br />
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            Aptitude test was conducted on Hackerrank.
            <br />
            <br />
            It consisted of 5 sections : Coding Section, Quantitative Aptitude,
            General Computer Concepts, Essay Section, Advanced Programming
            Question Section.
            <br />
            <br />
            Coding Round had 2 questions and we got 1 hr to complete those.
            <br />
            One question was pretty easy, based on just the concept of pairs and
            loops. Second Question was tough, mainly based on graphs. I wasn’t
            able to pass most of the test cases.
            <br />
            <br />
            Quantitative Aptitude was tougher than I had thought, largely maths
            oriented.
            <br />
            Questions on Computer Science concepts were based on data
            structures, OS, Networking concepts.
            <br />
            <br />
            Advanced Coding Round had one question on graphs. It was of medium
            to high level, particularly based on graph search algorithms. 45
            minutes were given for this, which I think was quite justifiable.
            <br />
            <br />
            In the Essay section, we had to write 2 essays. Topics were quite
            general, consisting of real life situations, generally to test team
            work and other qualities
            <br />
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            There were 3 Rounds. All were Technical Interviews.
            <br />
            First round was a live coding round on Hackerrank’s platform called
            CodePair. The interviewer gave me 2 questions to code. First
            question was based on Arrays. He asked me to do this question using
            4-5 different approaches. Everytime I wrote code, he asked me to
            show some other approach. Second question was based on Recursive
            functions. He confused me with some simple looking but tricky
            questions. He focused mainly on time complexity of the algorithms I
            was using.
            <br />
            <br />
            Second Round was also a live coding round. I was given questions to
            code based on string manipulation, recursive functions, hash maps,
            loaded with a lot of data structures. Then I was asked 3 puzzles, on
            which I brainstormed and I was able to answer all correctly. Solving
            puzzles inspires the interviewer a lot.
            <br />
            <br />
            Third Round, I was expecting it to be an HR round but it was again a
            tech-loaded round. The interviewer began with asking a quick
            overview of my resume. He was quite fascinated by my projects. He
            asked a lot of technical questions based on tech-stacks I have used
            in my projects. He particularly asked a lot of questions on my
            project MILAAP, a decentralised audio-video calling application on
            which I had worked throughout my summer.
            <br />
            Then he again shifted to data structures and algorithms. He asked me
            a lot of questions on priority queues and trees.
            <br />
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on the
              resume?
            </b>
            <br />
            I was surprisingly not asked much about my resume. Only in the last
            round, I was asked to describe my resume. Then, the interviewer
            specifically asked some good technical questions on some of my
            projects which I loved to answer. He looked quite impressed by that.
            <br />
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            I studied OS, DBMS and Computer Networks concepts from
            GeeksForGeeks. I solved a lot of coding questions on Hackerrank and
            GeeksForGeeks. Daily Coding Problems on Leetcode are also good to
            maintain rhythm. I studied Data Structures, Algorithms, OOPs
            thoroughly from GeeksForGeeks (GFG). For puzzles, I also went with
            GFG and every single puzzle asked in interviews can be found on GFG.
            <br />
          </p>

          <p>
            <b>6. Some tips to crack the coding questions. </b>
            <br />
            For coding questions, focus on solving different types of problems.
            Solve ample problems on all the important topics such as trees,
            dynamic programming, strings, graphs etc. which are asked. Just take
            up one or two coding platforms and stick to them. On geeksforgeeks,
            there are questions grouped by company in which they were asked, so
            solve company specific questions also.
            <br />
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:sharmav18.comp@coep.ac.in">
              sharmav18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default vasu_sharma;

import React from "react";

const aayush_shah = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Aayush Shah</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Credit Suisse
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            I am Aayush Shah, COEP - 21, and this document contains my opinions
            on the campus placement / internship selection process for the
            branches Computer Engg, Information Technology and EnTC for a
            Software Engineering role. All information is given in a question -
            answer format, divided into sections and in digestible chunks.
          </p>
          <p>
            Broadly speaking, this document breaks down the selection process,
            covers how to crack the aptitude test, coding test and the
            interview, and answers what are some good or bad practices as a
            Software Engineer. First we focus on what an interviewer looks for
            in a candidate, then we try to reverse engineer the type of profiles
            we can build for ourselves. Relevant resources are linked wherever
            applicable.
          </p>
          <p>
            <strong>FAQs covered:</strong>
          </p>
          <ul>
            <li>THE CAMPUS PLACEMENT / INTERNSHIP SELECTION PROCESS</li>
            <li>INTERVIEWER’S POINT OF VIEW</li>
            <li>STUDENT’S POINT OF VIEW</li>
            <li>THE APTITUDE TEST</li>
            <li>THE CODING TEST</li>
            <li>THE TECHNICAL INTERVIEW ROUND</li>
            <li>THE HR INTERVIEW ROUND</li>
            <li>HOW TO BUILD A RESUME</li>
          </ul>
          <h5>1. THE CAMPUS PLACEMENT / INTERNSHIP SELECTION PROCESS</h5>
          <p>
            <strong>
              Q. What were the companies that visited campus for TY internships
              in 2019?
            </strong>
            <br />
            A. DE Shaw, Arcesium, Goldman Sachs, Credit Suisse, Standard
            Chartered Bank, FinIQ, MasterCard, Citi Bank, Schlumberger,
            Gilbarco, Bajaj Finance, UBS, Barclays, Persistent Systems, Wolters
            Kluwer, NTT Data
          </p>
          <p>
            <strong>
              Q. What is the campus placement/internship selection structure?
            </strong>
            <br />
            A. Each section is detailed further in the document. Broadly, the
            structure is as follows: - Aptitude test - Coding test - Technical
            interview round - HR interview round
          </p>
          <p>
            <strong>Q. What is an Aptitude test?</strong>
            <br />
            A. You will be tested in areas where you have no prior training or
            knowledge. The tests each have a standardised method of
            administration and scoring, with the results quantified and compared
            with all other test takers. You are tested on quantitative aptitude,
            data interpretation and logical reasoning, and English proficiency.
          </p>
          <p>
            <strong>Q. What is a coding test?</strong>
            <br />
            A. Analogous to competitive coding, problems related to Data
            Structures and algorithms are given to you, to be solved in a given
            time limit. Number of questions and structure depend from company to
            company.
          </p>
          <p>
            <strong>Q. What is a technical interview round?</strong>
            <br />
            A. You are tested on your concepts in computer science, and
            questions will be based on Data Structures and Algorithms. Third
            year onwards, questions based on Computer Networks, Database
            Management Systems, Operating Systems are included as well.
          </p>
          <p>
            <strong>Q. What is an HR interview round?</strong>
            <br />
            A. Good academic records and sound technical knowledge alone are not
            sufficient to guarantee you a Job. Every company conducts an HR
            interview round to judge your personality, your strengths, your
            weaknesses, your capability to handle the role, to check your
            background, and to understand if you’re the right fit for this job.
            Usually the last round in the process.
          </p>
          <p>
            <strong>Q. How accurate is the selection process?</strong>
            <br />
            A. Not completely accurate. Certain good candidates can get
            eliminated in the process.
          </p>
          <p>
            <strong>
              Q. Good resource for an overview on the entire process?
            </strong>
            <br />
            A.
            <ul>
              <li>
                <a href="https://www.geeksforgeeks.org/a-complete-step-by-step-guide-for-placement-preparation-by-geeksforgeeks/">
                  A Step by Step Guide for Placement Preparation | Set 1
                </a>
              </li>
              <li>
                <a href="https://www.geeksforgeeks.org/a-complete-step-by-step-guide-for-placement-preparation-by-geeksforgeeks-set-2/?ref=rp">
                  A Step by Step Guide for Placement Preparation | Set 2
                </a>
              </li>
            </ul>
          </p>

          <h5>2. INTERVIEWER’S POINT OF VIEW</h5>
          <p>
            First, there are certain things you have to keep in mind about WHAT
            an interviewer is looking for in a candidate. You can reverse
            engineer what kind of profile you have to build using these
            pointers.
          </p>
          <p>
            <strong>Q. What are the interviewers looking for?</strong>
            <br />
            <ul>
              <li>Coding Proficiency</li>
              <li>
                {" "}
                Coding Proficiency, again - Specifically directed towards
                students who crack aptitudes without attempting coding sections.
              </li>
              <li>
                Competence, commitment, management skills, damage control skills
                - Position/s of responsibility in Fests, clubs, participation in
                sports, etc
              </li>
              <li>Effective communication skills, Body language</li>
              <li>Your contribution in coding projects</li>
              <li>Your contribution as a member of a technical club</li>
              <li>Comfort using unfamiliar, new technologies</li>
              <li> Minimal, to-the-point Resumes</li>
              <li>
                What value you can add to the company. And not how the company
                helps you go in the direction you want in your career
              </li>
              <li>Decent CGPA</li>
              <li>Optional Letter of recommendations.</li>
              <li>
                Optional Research publications 13.Optional Previous internship /
                job experience
              </li>
            </ul>
            1. Coding Proficiency <br />
            2. Coding Proficiency, again - Specifically directed towards
            students who crack aptitudes without attempting coding sections.
            <br /> 3. Competence, commitment, management skills, damage control
            skills - Position/s of responsibility in Fests, clubs, participation
            in sports, etc <br />
            4. Effective communication skills, Body language <br />
            5. Your contribution in coding projects <br />
            6. Your contribution as a member of a technical club <br />
            7. Comfort using unfamiliar, new technologies <br />
            8. Minimal, to-the-point Resumes <br />
            9. What value you can add to the company. And not how the company
            helps you go in the direction you want in your career.
            <br /> 10.Decent CGPA <br />
            11.Optional Letter of recommendations.
            <br />
            12.Optional Research publications 13.Optional Previous internship /
            job experience
          </p>
          <p>
            <strong>Q. What the interviewers are NOT looking for:</strong>
          </p>
          <ul>
            <li>Cutting edge CGPA</li>
            <li>
              Arrogance / Stubbornness about coding proficiency - NO! They are
              looking to get work done by an entry level engineer, and the last
              thing they would want is advice on how to do their job.
            </li>
            <li>Certificates of participation in college events</li>
            <li>
              Certificates of coordinator, volunteer, heads, etc in fests. NO!
              Multiple fests don’t get you extra points
            </li>
            <li>Certificates of seminars, lectures, workshops attended</li>
            <li>
              Multiple online courses or certifications - Viewing video lectures
              does not imply that you are a good coder
            </li>
            <li>Colorful, shiny Resumes / CVs with confusing vocabulary</li>
            <li>Resumes with spelling mistakes</li>
            <li>
              Your plans for further studies MS, MBA, MTech, etc - NO! They
              don’t want a candidate who may reject the offer or leave the job
              to pursue studies
            </li>
            <li>
              Hobbies - Unless you’re competing on a significant platform, they
              don’t care
            </li>
          </ul>
          <h5>3. STUDENT’S POINT OF VIEW</h5>
          <p>
            <strong>
              Q. What kind of profiles can I build as a student in computer
              engineering?
            </strong>
            <br />
            A. These are the different profiles you can target to build, or have
            a mix of each.
            <br /> 1. Projects Centric <br />
            2. Competitive coding centric <br />
            3. Extra-curricular centric Fests, Sports, Non-technical clubs{" "}
            <br />
            4. Technical clubs centric <br />
            5. Academics centric <br />
            6. Online courses/certifications centric:
          </p>
          <p>
            <strong>
              Q. How beneficial is each of the profiles mentioned above?
            </strong>
            <br />
            A. As follows:
          </p>
          <ul>
            <li>
              <b>Projects Centric:</b> Having multiple projects on your resume
              SHOWS that you have done sufficient coding. Be it two or three big
              significant/interesting projects, or be it multiple mini projects
              using different technology stacks, you have to demonstrate that
              you are either proficient in one language, or are
              versatile/flexible and can work with new technologies relatively
              at ease. This will also help you breeze through your interview
              rounds as your interviewer can ask about your work in these
              projects. Majority of the companies are looking for engineers who
              can work on multiple platforms and adapt to new technologies.
              Proficiency is a plus only for core software companies, where you
              will be dealing with algorithms. You can steer the conversation
              during the interview to talk about what your contribution towards
              the project was, and what new technologies you learnt/mastered by
              doing that project. With a good hold over what you have EXACTLY
              done, you can impress the interviewer with how you tackled
              challenges in your project.
            </li>
            <li>
              <b>Competitive coding centric</b>: If you are building a profile
              based on competitive coding, you need to have pretty significant
              ranks on competitive coding websites A quantitative metric like
              number of stars, worldwide rank, or rank in a competition. Pretty
              interesting to boast about, and often invites more algorithm based
              questions during the interview. A big plus point for core software
              companies.
            </li>
            <li>
              <b>
                Extra-curricular centric Fests, Sports, Non-technical clubs:
              </b>{" "}
              Although a coding job, there is more to your job as an engineer
              rather than just outputting lines of code per day. A candidate
              with effective communication skills is of more value than a
              candidate with top notch coding but poor communication skills.
              This aspect is often underappreciated by students too focused on
              academics, and often over appreciated by students who are a part
              of 10 fests at once.
            </li>
            <li>
              <b>Technical clubs centric:</b> Beneficial only if you have played
              a significant role. It helps to be able to elaborate exactly what
              you have worked on so far, the challenges you have faced, and how
              YOU tackled the challenge.
            </li>
            <li>
              <b>Academics centric:</b> Beneficial only upto the cutoff CGPA. If
              you clear the cutoff, they probably don’t care, unless the cutoff
              is really low where they will ask for an explanation. Having a
              decent CGPA implies that you can put in effort where necessary,
              but does not imply that you are a good coder.
            </li>
            <li>
              <b> Online courses/certifications centric:</b> Having developed
              new skills in different domains definitely helps. But, having
              knowledge helps only if you can put it to use. Quoting “Andrew NG”
              or Coursera or Udemy on your resume does not mean anything if you
              haven’t put in abundant hours building or working on a project in
              the same domain. Don’t be an online course junkie, it doesn’t
              help.
            </li>
          </ul>
          <p>
            <strong>Q. How to get multiple projects on your resume?</strong>
            <br />
            A. Focus on the mini-projects in your curriculum, the syllabus
            covers a vast number of domains where you will be asked to work on
            mini projects as a part of academics. If you take it very seriously,
            you have the chance to cover a vast variety of domains as well as
            technology stacks.
          </p>
          <p>
            <strong>
              Q. How to be good at competitive coding/competitions? What are the
              resources for the same?
            </strong>
            <br />
            A. Pick one out of HackerRank, HackerEarth, TopCoder, CodeChef,
            LeetCode, SPOJ, Kaggle, etc and start solving problems daily. You
            eventually get good at it after solving a significant number of
            problems.
          </p>
          <p>
            <strong>Q. How much to focus on extracurricular activities?</strong>
            <br />
            A. As much you can manage to put in, as long as you can balance
            academics. Not a deal breaker, but there is no substitute for the
            soft and hard skills you develop. What most people fail to realize
            is that after the “fun” ends, hard work begins. If you are competing
            on a significant platform, you are demonstrating that you are a
            competent person, and have commitment. Be it college fests, or
            sports, or non-technical clubs, your efforts and achievements is
            what matters. There is a significant skill set you develop after
            working long hours, and this gives you an edge over most other
            candidates who don’t realize its importance. Extracurriculars teach
            you effective communication skills, management skills, commitment,
            team work, etc. A very important aspect in HR rounds, where several
            good candidates get rejected due to lack of extracurriculars.
          </p>
          <p>
            <strong>Q. How much to focus on academics?</strong>
            <br />
            A. Number one priority. It is the most important aspect of your
            career. It will decide what kind of job or internship you will get.
            No, I am not talking about your CGPA. While you need to maintain
            CGPA above the cutoffs, having a clarity of whatever concepts you
            have studied so far in your academics goes a long way. You often get
            tested in your interviews on topics you have studied so far, and
            having a clear understanding helps more than you can imagine.
          </p>
          <p>
            <strong>Q. How much CGPA to maintain?</strong>
            <br />
            A. There is no ideal CGPA, although the more the better. With
            respect to the companies coming for placement and internships in
            COEP, having a CGPA of 7.5+ is absolutely necessary and 8+ is
            decent. Almost no companies pick candidates based on CGPA alone, so
            one should not bank on it.
          </p>
          <p>
            <strong>
              Q. Are internships compulsory? Do I need to do an internship after
              SY? What if I don’t get an internship in TY? Are there people who
              don’t get an internship or don’t opt for one?
            </strong>
            <br />
            A. No, internships are not compulsory for graduation. Coming to the
            second question, having an internship in SY summer vacation does not
            matter in the long term in affecting your chances of getting a TY
            internship or placement. Coming to the third question, if you do not
            get an internship by the end of the 5th semester, you should start
            searching for internships on various portals or through personal
            contacts. Although not compulsory, internships are crucial. However,
            there are students who do not opt for an internship too Students
            preparing for entrance exams for MBA, UPSC, etc
          </p>
          <p>
            <strong>
              Q. Should I apply elsewhere outside college for internships?
            </strong>
            <br />
            A. Yes, why not. Campus placement is preferable because you are
            competing with your batchmates students of similar caliber so you
            have an approximate idea about where you stand. And since you are
            coming from a decent college, you have the opportunity to start your
            career with good companies.
          </p>
          <p>
            <strong>Q. Are internships THAT important?</strong>
            <br />
            A. An internship experience is invaluable as the two months will
            teach you what you couldn’t learn in the two or three years of
            engineering. You have something to talk about in your future
            interviews, and working a real job is valued more than online
            courses or projects in the selection process. Regarding internship
            opportunities in TY, the companies that have hired you may offer you
            a Pre Placement Offer PPO, thus very important. It is also important
            in the sense that your first real job will make you realize if you
            are comfortable in a desk job, coding for the rest of your future or
            not. Quite often, students do realize that they would rather shift
            domains if they see their worth elsewhere.
          </p>

          <h5>4. THE APTITUDE TEST</h5>
          <p>
            <strong>Q. What platform is used to conduct the tests?</strong>
            <br /> A. Usually HackerRank
          </p>
          <p>
            <strong>
              Q. What kind of questions are asked in the aptitude test?
            </strong>
            <br />
            A. Follow the resources given below
          </p>
          <p>
            <strong>Q. Good resources to prepare for the aptitude test?</strong>
            <br />
            A. Online Source:{" "}
            <a href="https://www.geeksforgeeks.org/placements-gq/">
              {" "}
              Aptitude Test Topics
            </a>
            , Preparation book:{" "}
            <a href="https://drive.google.com/open?id=1HchH-ZPbJ0PPYYOn4kGdxBaxvemPMgkt">
              {" "}
              R. S. Aggarwal
            </a>
            , Puzzles:{" "}
            <a href="https://www.geeksforgeeks.org/category/puzzles/">
              {" "}
              Puzzles
            </a>
            , Mock Tests:{" "}
            <a href="https://www.geeksforgeeks.org/quiz-corner-gq/#Aptitude%20Mock%20Tests">
              {" "}
              Mock Aptitude Tests
            </a>
          </p>

          <h5>5. THE CODING TEST</h5>
          <p>
            <strong>Q. Preferred languages for coding round?</strong>
            <br /> A. C++, Java or Python.
          </p>
          <p>
            <strong>
              Q. What are some tips for cracking the coding round?
            </strong>
            <br />
            A. Be experienced in competitive coding. Have an appetite for
            creating algorithm based solutions for problems on your own. Solve
            problems on GeeksForGeeks question banks on the necessary topics,
            and practice competitive coding websites given below.
          </p>
          <p>
            <strong>Q. What are necessary topics for the coding round?</strong>
            <br />
            A. As follows:
          </p>
          <ul>
            <li>
              Data Structures - trees, tries, linked lists, graphs, stack,
              queue, heaps, vectors, arraylists, hash tables
            </li>
            <li>
              Algorithms - Breadth first search, Depth first search, binary
              search, sorting algorithms merge sort, quick sort
            </li>
            <li>Design questions - Object Oriented Programming</li>
            <li>Recursion</li>
            <li>Dynamic programming</li>
            <li>Greedy Programming</li>
            <li>Backtracking</li>
            <li>Complex Database queries Database management systems in SQL</li>
          </ul>
          <p>
            <strong>Q. What are the resources for the same?</strong>
            <br />
            A. For the given necessary topics:{" "}
            <a href="https://www.geeksforgeeks.org/">GeeksForGeeks</a> and{" "}
            <a href="https://drive.google.com/open?id=1zWdVt8aUI5fwUR5Tls3xs8arc_8AZ7mb">
              Cracking the Coding Interview
            </a>{" "}
            For competitive coding - pick one out of HackerRank, HackerEarth,
            TopCoder, CodeChef, LeetCode, SPOJ, Kaggle, etc and start solving
            problems daily. You eventually get good at it after solving a
            significant number of problems.
          </p>
          <h5>6. THE TECHNICAL INTERVIEW ROUND</h5>
          <p>
            <strong>
              Q. Are there any specific requirements for top-tier companies?
            </strong>
            <br /> A. An extremely good hold over data structures and
            algorithms, apart from the necessary topics mentioned in the coding
            round
          </p>
          <p>
            <strong>
              Q. What are the necessary topics for a technical interview round?
            </strong>
            <br />
            A. A good clarity of concepts and ability to write code on paper for
            the following topics:
          </p>
          <ul>
            <li>
              Theory subjects as covered in academics - Data structures,
              Algorithms, Data Communication, Database management systems,
              Computer Networking, Operating Systems
            </li>
            <li>
              Data Structures - trees, tries, linked lists, graphs, stack,
              queue, heaps, vectors, arraylists, hash tables
            </li>
            <li>
              Algorithms - Breadth first search, Depth first search, binary
              search, sorting algorithms merge sort, quick sort
            </li>
            <li>
              Time and space complexity concepts - Remember for ALL sorting
              algorithms
            </li>
            <li>Design questions - Object Oriented Programming</li>
            <li>Recursion</li>
            <li>Dynamic programming</li>
            <li>Greedy Programming</li>
            <li>Backtracking</li>
            <li>Complex Database queries Database management systems in SQL</li>
          </ul>
          <p>
            <strong>
              Q. What sort of questions were asked in the interviews?
            </strong>
            <br />
            A. Nicely covered in the book linked in the resource.
          </p>
          <p>
            <strong>
              Q. How many questions are asked based on the projects done? How
              many questions were based on the resume?
            </strong>
            <br />
            A. Depends on how well you can steer the conversation during the
            interview. If you have several interesting projects on your profile,
            it is a big plus if you are able to convey the effort you have put
            in on your projects. However, there are chances that you may not get
            asked about it at all. And regarding questions based on your resume,
            you should not have put anything on your resume that you may not be
            able to answer in the first place.
          </p>
          <p>
            <strong>
              Q. What are the resources for preparing for a technical interview?
            </strong>
            <br />
            A. Preparation book: Cracking the Coding Interview <br />
            Online resource - A Step by Step Guide for Placement Preparation |
            Set 1 <br />
            Online resource - A Step by Step Guide for Placement Preparation |
            Set 2
          </p>

          <h5>7. THE HR INTERVIEW ROUND</h5>
          <p>
            <strong>
              Q. What are some tips on how to prepare for the HR interview?
            </strong>
            <br /> A. Keep an answer ready for <i>“Tell me about yourself” </i>-
            Eventually every follow up question is based on this. This is the
            time to tell the interviewer about your extracurriculars and
            co-curriculars, and your achievements. The interviewer may ask
            questions regarding your family background, political affiliations,
            or a controversial/sensitive question that might put you off. They
            are asked to check your integrity, answer confidently. In the
            interview rounds, good communication skills will shine, as a
            candidate who does work and is not able to describe his work is of
            no use at all.
          </p>
          <p>
            <strong>
              Q. What are some resources to prepare for an HR round?
            </strong>
            <br />
            A.
            <a href="https://www.geeksforgeeks.org/how-to-prepare-for-hr-interviews/">
              https://www.geeksforgeeks.org/how-to-prepare-for-hr-interviews/
            </a>
          </p>

          <h5>8. HOW TO BUILD A RESUME</h5>
          <p>
            <strong>
              Q. Should I use the template given by college, or prepare my own
              resume format?
            </strong>
            <br /> A. Prepare both, but avoid submitting the college template as
            far as possible. Your address, weight, caste, religion, sexual
            preferences or washing machine brand is way too much information,
            and they probably don’t need it.
          </p>
          <p>
            <strong>
              Q. What are some basic do’s and don’ts for resume building?
            </strong>
            <br />
            A. As follows:
          </p>
          <ul>
            <li>
              No spelling mistakes. As silly as this sounds, it is the most
              common error found on resumes.
            </li>
            <li>
              Do not lie on your resume. Anything and everything written is
              subject to questions during the interview.
            </li>
            <li>
              Do not use online templates. As far as possible, use either Latex,
              Adobe Illustrator, or Microsoft Word. All of them allow you to
              customize your resume to a very good extent.
            </li>
            <li>
              Do not use bright, colorful, flashy text or layouts for your
              resume.
            </li>
            <li>
              Do not overuse confusing vocabulary to the point where the reader
              does not understand what’s going on.
            </li>
            <li>
              No more than one page. A very common beginner mistake is to give a
              very verbose description about every single thing on the document,
              which is unnecessary. Leave some details for discussion during the
              interview.
            </li>
            <li>To the point, minimal resumes shine among others.</li>
            <li>
              Personalize the resume for every company you’re applying for,
              according to the requirements or the job profile.
            </li>
            <li>
              Highlight the sections which show what you are good at, and shift
              the remaining sections down.
            </li>
            <li>
              Do not include these sections in the resume: hobbies, spoken
              languages proficiency, subjects undertaken
            </li>
          </ul>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:aayushbs17.comp@coep.ac.in">
              aayushbs17.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default aayush_shah;

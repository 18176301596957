import React from "react";

const sohel_bargir = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Sohel Bargir
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Pattern Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                        Entire process was divided into 3 rounds:
                        <br/>
                            <ol>
                                <li>
                                    Round 1 : Online Aptitude Test (3 DSA questions).
                                </li>
                                <li>
                                    Round 2 : Technical Interview.
                                </li>
                                <li>
                                    Round 3 : HR Interview.
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                        Aptitude round had 3 DSA questions which included Arrays, DP and Greedy.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ol>
                            <li>
                                Aptitude Round : The test was conducted online on DoSelect platform. It had 3 Coding questions with difficulty ranging from moderate to hard. The questions. The questions were about Dynamic Programming, Greedy Algorithms, and Arrays. 
                            </li>
                            <li>
                            The round started with an introduction. In this round, I discussed my projects and the technologies I used. I was given two DSA questions, starting with a brute-force solution and then asked to optimise it, which I explained. The interviewer also asked about normalization in DBMS, including why it’s used and how to apply it, which I explained clearly. I wrote SQL queries for given problems and answered questions about the difference between authentication and authorization, since I used Google OAuth 2.0 in my project. There were also many questions about REST APIs, microservices, git, github ,CN and OOPS. The round lasted for 45 minutes.
                            </li>
                            <li>
                            This round was conducted by the Managing Director of the company. He asked me general HR questions, such as why do I want to join the company, my career goals, and where I see myself in the coming years. I was also asked about my extracurricular activities, hobbies, and family background. This round lasted for 25 minutes.
                            </li>
                        </ol>

                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After the aptitude round, a total of 21 students were shortlisted for interviews. After two interview rounds, 7 students received internship offers.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Striver's A2Z and SDE Sheet.
                        <br/>
                        GFG for Core Subjects.

                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            1)Rather than chasing a high number of questions on LeetCode, focus on solving a diverse range of problems.
                            <br/>
                            2)Follow Striver's A2Z Sheet to start your coding journey. You can use any programming language you're comfortable with. Focus on developing a strong intuition for solving the problems
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                        Lot of questions were asked about my projects and technologies used in the Technical Interview. 
                            <br />
                            I was asked about my extracurricular activities and clubs in the HR round itself. 
                            
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        The Aptitude round consisted of just 3 Coding questions. Solve a variety of coding questions on leetcode and GFG. Put your best projects on your resume to capture the interviewer's interest during the interview.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Don’t get discouraged if you don’t succeed in an interview or aptitude test. Believe in yourself, and use the experience to prepare for the next opportunity.
                            <br />
                            
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="sohelbargir2@gmail.com">
                        sohelbargir2@gmail.com
                        </a>
                        <br />
                        LinkedIn : <a href="https://www.linkedin.com/in/sohel-bargir-8a9b59211/">Sohel Bargir</a>
                        <br />
                    </div>
                </div>
            </section >
        </div >
    );
};

export default sohel_bargir;

import React from "react";

const aayush_shah_nvidia = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Aayush Shah</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>NVIDIA</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <li style={{ marginLeft: "10px" }}>Aptitude Test</li>
            <li style={{ marginLeft: "10px" }}>Technical Interview Round</li>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              Overall difficulty was moderate as compared to other companies. A
              mix of theory based questions and code snippets based multiple
              choice questions, followed by a coding test. Coding section was
              relatively tough as compared to the theory section.
            </p>
            <p>
              Theory questions covered questions on data structures, networking
              and operating systems. Few questions on OOP theory, language based
              questions (C++), protocols in networking. No questions on Database
              Management Systems.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              <i>Technical Interview Round</i>
            </p>
            <p>
              The interviewers were friendly and energetic. They asked me about
              my likes and dislikes, hobbies, etc. A very casual start to the
              interview. They even asked me a few completely unrelated questions
              on statistics when I mentioned that finance is one of my
              interests.
            </p>
            <p>
              In the beginning, the focus was on what I had stated on my Resume,
              they went through the entire document and asked a few questions
              regarding a few projects they found interesting.
            </p>
            <p>
              Later, I was asked about how I would go about system design of an
              e-commerce website. I proceeded to design the entire website with
              the choice of appropriate tech stack. I was typing everything down
              in a shared document, where they questioned each and every choice.
            </p>
            <p>
              This was followed by some intense theory questions on Operating
              Systems. Everything from interrupts, kernel, context switching,
              scheduling algorithms, process groups, etc. This went on for a
              while. Multiple questions related to Unix.
            </p>
            <p>
              This was followed by live coding, where they framed a problem
              statement and expected me to identify the correct type of problem
              we were dealing with (Producer Consumer Problem) and write
              compilable code for it (in C) along with the supporting data
              structures, since we don&#39;t have any in C.
            </p>
            <p>
              The final question was how to multiply a number with 15 without
              using multiplication or division operator. I came up with multiple
              answers, the expected one being{" "}
              <a href="https://www.geeksforgeeks.org/multiply-a-number-by-15-without-using-and-operators/">
                this
              </a>
              .
            </p>
            <p>
              They concluded the interview by asking where I was from, and that
              was it, no HR round.
            </p>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <p>
              They scan the entire resume to search for your strengths, and will
              question you on projects they find interesting.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <a href="https://www.geeksforgeeks.org/">GeeksForGeeks</a> and{" "}
            <a href="https://drive.google.com/open?id=1zWdVt8aUI5fwUR5Tls3xs8arc_8AZ7mb">
              Cracking the Coding Interview
            </a>
            , for competitive coding - pick one out of HackerRank, HackerEarth,
            TopCoder, CodeChef, LeetCode, SPOJ, Kaggle, etc and start solving
            problems daily. You eventually get good at it after solving a
            significant number of problems.
            <br /> Online Source:{" "}
            <a href="https://www.geeksforgeeks.org/placements-gq/">
              {" "}
              Aptitude Test Topics
            </a>
            , Preparation book:{" "}
            <a href="https://drive.google.com/open?id=1HchH-ZPbJ0PPYYOn4kGdxBaxvemPMgkt">
              {" "}
              R. S. Aggarwal
            </a>
            , Puzzles:{" "}
            <a href="https://www.geeksforgeeks.org/category/puzzles/">
              {" "}
              Puzzles
            </a>
            , Mock Tests:{" "}
            <a href="https://www.geeksforgeeks.org/quiz-corner-gq/#Aptitude%20Mock%20Tests">
              {" "}
              Mock Aptitude Tests
            </a>
            <br />
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Be experienced in competitive coding. Have an appetite for creating
            algorithm based solutions for problems on your own. Solve problems
            on GeeksForGeeks question banks on the necessary topics, and
            practice competitive coding websites given below.
            <br />
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <p>
              The panel is really knowledgeable and will overwhelm you with
              questions in a short span. Key is to stay calm and try to keep up
              with the pace and making sure you aren&#39;t bluffing in the heat
              of the moment.{" "}
            </p>
            <p>
              Be very thorough in all the topics you list in your resume, they
              will get into extremely granular details of each and every
              concept. Strong emphasis on using the correct terminology while
              giving an answer.{" "}
            </p>
            <p>
              Expect a lot of questions about UNIX and GPUs. Do some strong
              reading beforehand on these two topics.
            </p>
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:shahaayush1999@gmail.com">
              shahaayush1999@gmail.com
            </a>
            <br />
            <a href="https://www.linkedin.com/in/shahaayush1999/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default aayush_shah_nvidia;

import React from "react";

const suraj_yerkal = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Suraj Yerkal</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>PhonePe</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />
            <ol>
              <li>Coding Test</li>
              <li>Tech Interview 1</li>
              <li>Tech Interview 2</li>
              <li>HR + Tech Interview</li>
            </ol>
            <b>Structure of Coding Round :</b>
            <br />
            <p>
              Test was held on 23rd Aug on the “doselect” platform. It had 5
              coding questions with 2 hrs of time limit. Input had to be taken
              by the user. Some of the problems are mentioned{" "}
              <a href="https://drive.google.com/file/d/1BaHpcOZJuAAJIQ_bdc2Y5vmL2pM1JQLv/view">
                here
              </a>
              .
              <br />
              Over 350+ students from all branches appeared for the coding test
              out of which only 7 were selected for the interview rounds. I was
              able to solve 3 questions completely and 2 questions partially.
              <br />
              <b>Tip</b> : If the problem cannot be solved then hardcode the
              sample testcases and also for other hidden testcases try to return
              some values based on input arguments. Sometimes it additionally
              solves some more testcases.
            </p>
          </p>

          <p>
            <b>
              2. How many interview rounds were there for the company, can you
              elaborate about each round in brief ?
            </b>
            <br />
            <p>
              There were 3 interview rounds
              <br />
              <ol>
                <li>Tech Interview 1 (nearly 1 hr)</li>
                <p>
                  This round was mainly focused on live coding (easy - medium
                  level questions).
                  <br />
                  Interview started with a general introduction and discussion
                  on some projects from my resume. This lasted for nearly 15
                  mins.
                  <br />
                  He asked me 3 coding questions:
                  <br />
                  <a href="https://www.geeksforgeeks.org/search-an-element-in-a-sorted-and-pivoted-array/">
                    1.Search in Rotated Sorted Array
                  </a>
                  <br />
                  <a href="https://www.geeksforgeeks.org/search-in-row-wise-and-column-wise-sorted-matrix/">
                    2. Search in row wise and column wise sorted matrix
                  </a>
                  <br />
                  <a href="https://www.geeksforgeeks.org/diameter-of-a-binary-tree/">
                    3. Small modification of Diameter of a binary tree
                  </a>
                  <br />
                  I was able to solve all the three questions.
                  <br />
                  Out of 7, <b>5</b> were selected for the further rounds.
                </p>
                <li>Tech Interview 2 (45 mins)</li>
                <p>
                  This round was also focused on live coding (medium - hard
                  level questions). I was asked two coding questions.
            <br />
                    1. First question was based on string and DP. I took too much
                    time on this question. The interviewer has also given me
                    some hints but due to time constraints, he moved to the
                    second question.
             
          <br />    2.
                    <a href="https://leetcode.com/problems/dungeon-game/">
                       Dungeon Game
                    </a>{" "}
                    : As I had solved this question earlier I was able to solve
                    the question in less amount of time. After this question we
                    moved back to the first question and I was able to solve it.
       
                </p>
                <li>HR + Tech Interview (1-1:30 hrs) :</li>
                <p>
                  This was a hiring manager round. Interview started with
                  typical HR questions like why PhonePe, give me an example when
                  you managed a team during any work or project etc. Then the
                  interview shifted towards technical questions. He asked me
                  about my contribution to the CSAT club.
                  <br />
                  After this, he asked me for a startup idea. I put forth an
                  idea and we had a detailed discussion about it like, what will
                  be the possible constraints / problems and their solutions,
                  how will you scale this system?, etc. As this was related to
                  the Networking domain the interview continued with questions
                  on CN and network security. Questions were, how SSL works end
                  to end? Hashing, types of authentication, etc.
                  <br />
                  Then he asked some system design questions like:
                  <br />
                   1. How would you
                  implement some features of YouTube? 
                  <br />
                  2. How will you store video
                  with multiple resolutions? 
                  <br />
                  3. Will you store data in a single data
                  center, if not how will you divide the data into multiple data
                  centers? 
                  <br />
                  4. How does youtube dynamically change video quality?
                  <br />
                  5. Some questions on CDN concepts, etc. 
                  <br />
                  After this final interview
                  we both got FTE and a 6 months internship offer from PhonePe.
                </p>
              </ol>
            </p>
          </p>

          <p>
            <b>3. Some good resources you used in your Placement prep</b>
            <br />I solved{" "}
            <a href="https://drive.google.com/file/d/1FMdN_OCfOI0iAeDlqswCiC2DZzD4nPsb/view">
              450 Sheet by Love Babbar
            </a>{" "}
            for coding questions. Also solved questions on Hackerrank (as many
            companies use hackerrank for aptitude test). For quantitative
            aptitude I followed <a href="https://www.indiabix.com/">Indiabix</a>{" "}
            . I studied OS, CN, DBMS, OOPs,
            <a href="https://www.youtube.com/watch?v=xpDnVSmNFX0&list=PLMCXHnjXnTnvo6alSjVkgxV-VH6EPyvoX">
              System design
            </a>{" "}
            and <a href="https://www.geeksforgeeks.org/puzzles/">Puzzles</a>{" "}
            from GFG and youtube playlists.
          </p>

          <p>
            <b>4. Some tips to crack coding questions :</b>
            <br />
            During coding preparation, don’t pick the questions randomly. Use
            some tried and tested sheets. I strongly recommend 450 Sheet by Love
            Babbar and Striver’s SDE sheet is also good
          </p>

          <p>
            <b>
              5. Is there something specific that you would want to tell people
              who try for the same company ?
            </b>
            <br />
            For PhonePe, competitive coding, DSA and System design is important.
            Be prepared for questions on innovative ideas.
          </p>
          <p>
            <b>Final Tip for Juniors</b>
            <br />
            <p>
              Go through frequently asked questions from
              GFG/Interviewbit/edureka etc on various topics like OS, CN, DBMS,
              etc Along with coding, focus on aptitude tests(it is a major
              elimination round). Don’t waste too much time in finding the
              optimal solution, you have to make a decision whether to go with
              the optimal solution(will take more time) or go for a brute force
              approach. I recommend giving leetcode virtual contests as practice
              to make such decisions. Be confident and if something goes wrong
              don’t panic during the interview there is always a chance to
              compensate for it.
              <br />
              <b>All the best!!</b>
            </p>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:yerkal.suraj@gmail.com">yerkal.suraj@gmail.com</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default suraj_yerkal;

import React from "react";

const ashutosh_pardeshi = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Ashutosh Singh Pardeshi
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Inxise Datalabs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li>
                First the shortlisting round takes place. If they like your
                application, they notify you and then you can sit for their next
                rounds. There were total of 4 rounds.
              </li>
              <li>
                Aptitude test hosted on the AMCAT platform. The questions were
                available in a linear fashion and could not be revisited.
              </li>
              <li>
                Group discussion – The topic was announced before giving us some
                time to prepare. They also asked each candidate their own
                opinion on the given topic.
              </li>
              <li>Technical Round (Details covered later)</li>
              <li>HR+Tech mix round</li>
            </ul>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              <ol>
                <li>Technical Section</li>
                <li>Quant Section</li>
                <li>Language section</li>
                <li>Coding questions (2)</li>
              </ol>
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <ol>
              <li>
                <strong>GD</strong>
                <ul
                  style={{
                    marginLeft: "1.5rem",
                    listStyleType: "disc",
                  }}
                >
                  <li>
                    As mentioned above, we were given some amount of time to
                    prepare.
                  </li>
                  <li>
                    Listening to others opinion is very important, but at the
                    same time make sure your points are also noted.
                  </li>
                  <li>Try to be assertive in your statements </li>
                  <li>
                    Be honest when they ask you are asked questions personally
                    and try to answer them to the best of your capacity. Do not
                    under any situation lie or try to make something up to
                    support your previous statements. This gives a bad
                    impression.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Technical Round</strong>
                <ul
                  style={{
                    marginLeft: "1.5rem",
                    listStyleType: "disc",
                  }}
                >
                  <li>
                    It started with the classic “Tell us about yourself”. Make
                    sure you are prepared for this answer and mention your
                    strong points. For me, the discussion continued based on
                    that itself. Then a few basic DSA and Networking questions
                    were asked.
                  </li>
                  <li>
                    After a few mins, they discussion shifted to my projects. A
                    lot of time was spent on it. One thing I learned is that
                    it’s better to have 1 or 2 good projects than to have a mix
                    of a few good and many average projects.
                  </li>
                  <li>
                    They asked me if asked, how would I re-work on them and try
                    to make them better. This was the part they were more
                    interested in, so prepare that in advance.
                  </li>
                </ul>
              </li>
              <li>
                <strong>HR + Technical (mix round)</strong>
                <ul
                  style={{
                    marginLeft: "1.5rem",
                    listStyleType: "disc",
                  }}
                >
                  <li>
                    This was a short round. The HR presented me with questions
                    like where you see yourself in 10 years from now and so.{" "}
                  </li>
                  <li>
                    This is a round where you also have an opportunity to ask
                    them questions, so make sure you have them ready. It looks
                    really bad if you say, “I have no questions for you”.
                  </li>
                </ul>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <br />
            <p>I have covered that part in the above section</p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              <ul
                style={{
                  marginLeft: "2.5rem",
                  listStyleType: "disc",
                }}
              >
                <li>
                  GFG was a major resource that you will find almost everyone
                  referring to. It has a wide range of questions for you to
                  solve. Try to make best use of it.
                </li>
                <li>
                  Some of the YouTube channels were helpful in getting
                  introduced to topics like OS
                </li>
              </ul>
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              <ol>
                <li>
                  First of all, do not panic when you see the questions. Try to
                  break them down into smaller problems and make a rough
                  flowchart in your head about it.
                </li>
                <li>Try to pass as many as test cases you can</li>
                <li>
                  Practicing is the only thing that will help u in this section
                  as sometimes you can come up with your own tips/tricks as and
                  when you solve questions.{" "}
                </li>
              </ol>
            </p>
          </p>
          <br />
          <p>
            <i>
              Try and read as many diaries as you can. I personally got some
              good tips by reading them. This whole process is a huge step so be
              wise and try to remain calm. Good communication skills are
              important, and make sure you don’t rush into anything. Also, CGPA
              matters in off-campus interviews as well.
            </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:pardeshiaj19.comp@coep.ac.in">
              pardeshiaj19.comp@coep.ac.in
            </a>
            <br />
            {/* <a href="https://www.linkedin.com/in/kartik-mandhan-13aa4b192/">
              LinkedIn Handle
            </a> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ashutosh_pardeshi;

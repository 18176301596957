import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.typography.pxToRem(30),
    textAlign: "center",
  },
  heading: {
    marginBottom: "20px",
    paddingBottom: "0",
    fontSize: theme.typography.pxToRem(40),
    // fontWeight: theme.typography.fontWeightBold,
    letterSpacing: "2px",
    color: "#ffffff",
    // textTransform: "uppercase",
    // fontFamily: "Kuvas",
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.pxToRem(28),
  }
  },
}));

const SectionHeading = ({ title }) => {
  const classes = useStyles();
  return (
    <div style={{ padding: "3rem 0" }}>
      <div className={classes.root}>
        <h2 className={classes.heading}>{title}</h2>
      </div>
    </div>
  );
};

export default SectionHeading;

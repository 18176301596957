import React from "react";

const prajakta_ghatage = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Prajakta Sajjanrao Ghatage
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />
          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              There were two phases in internship process. In 1<sup>st</sup>{" "}
              phase, there was online test of 135 minutes and in 2<sup>nd</sup>{" "}
              phase there were two rounds of technical interview.
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              As I mentioned, there was a test 135 minutes long, which was included
              of 5 section: Coding section, Quantitative aptitude, General
              computer technical questions, Essay section, Advanced Programming
              Question section.
            </p>
            <p>
              The 1<sup>st</sup> coding section had 2 coding questions for 30
              minutes, complexity of questions were quite easy.
            </p>
            <p>Quantitative Aptitude was largely maths oriented.</p>
            <p>
              Technical Section consist of some basic DSA questions, some OS and
              DBMS related questions.
            </p>
            <p>
              In essay section we had 2 essay questions related to real life
              situations
            </p>
            <p>
              Advance Coding section had 1 coding question related to snake and
              ladder game and time for this question was of 45 minutes.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>There were 2 technical rounds.</p>
            <ol>
              <li>
                <p>
                  The
                  <strong>
                    {" "}
                    <em>first round</em>{" "}
                  </strong>
                </p>
                  In 1<sup>st</sup> technical round, first of all the interviewer
                  asked me to introduce myself and asked some questions on my
                  projects which I included in my resume then we were given one
                  hacker rank link where we have to do live coding. The
                  interviewer gave me 2 coding questions, at first he explained me
                  the problem statement on the whiteboard and asked me for its
                  logic and how you going to implement Optimizely. Then he asked
                  me for time complexity of my approach. And same for 2
                  <sup>nd</sup> question also. The questions basically related to
                  arrays and nested loops but little bit tricky.
              </li>
              <br />
              <li>
                <p>
                  The
                  <strong>
                    {" "}
                    <em>second round</em>{" "}
                  </strong>
                </p>
                In 2<sup>nd</sup> technical round, again I was asked to
                introduce myself, some project-based questions and then, they
                had again explained the problem statement on the same link of
                hacker rank and told me to implement completely. Basically in 2
                <sup>nd</sup> round I was asked to implement one of Binary Tree
                question. This round was quite difficult compared to 1<sup>st</sup> one.
                Then the interviewer gave me 1-2 java code snippets and asked me
                the outputs of that code snippets
              </li>
              <br />
            </ol>
          </p>
          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <br />
            <p>
              As I mentioned above, in both technical interview rounds I was
              asked for my projects which I included in resume. They asked me
              some basic questions like which technology/data structure you used
              and why, if it is a group project then they asked for my
              contribution.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              GeeksForGeeks for data structure, OS, and DBMS also Competitive
              coding questions from HackerRank and CodeChef GFG puzzles. I also
              went through my DSA notes and coding from DSA1 and DSA2 subject
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              There is only tip to crack the coding questions is practice.
              Practice as many coding questions as you can from competitive
              coding, it will definitely help you.
            </p>
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            {
              <a href="mailto: prajktaghatage6244@gmail.com">
                prajktaghatage6244@gmail.com
              </a>
            }
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default prajakta_ghatage;

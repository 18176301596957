import React, { Component } from "react";
import { Button, Grid } from "@material-ui/core";
import { data as TyData } from "../../src/assets/diary-data/TY_diaryData";
import { backGround } from "../layout/customStyle";
import Navbar from "../layout/Navbar";
import { ArrowBack } from "@material-ui/icons";
import TyDiaries from "./Companies/TyDiaries";
import redirectUtil from "../utils/redirectUtil";
import Footer from "../layout/Footer";
class CompanyWise extends Component {

  render() {
       const [item] = TyData.filter(
        
      (companies) => companies.company.toLocaleLowerCase().replace(/\s/g,`${'_'}`)=== this.props.match.params.company||
      companies.company.toLocaleLowerCase().replace(/\s/g,`${'-'}`)=== this.props.match.params.company||
      companies.company.toLocaleLowerCase().replace(/\s/g,`${' '}`)=== this.props.match.params.company ||
      companies.company.toLocaleLowerCase().replace(/\s/g,`${''}`)=== this.props.match.params.company

    );
      const batch = this.props.match.params.batch;
      const found = item.batches.indexOf(batch);
      if (found === -1)
        return redirectUtil(this.props, item, "ty-diaries");

    return (
      <main style={{ ...backGround, overflow: "hidden" }}>
        <Navbar diary={true} />
        <div className="mb-5">
          <div style={{ marginTop: "40px", marginBottom: "40px" }}>
            <h1
              style={{
                textAlign: "center",
                color: "white",
                // fontFamily: "Gotham",
                fontSize: "2.4rem",
                marginBottom: "1.2rem",
              }}
            >
              TY Internship Diaries
            </h1>
          </div>
          <div className="container-fluid">
            <Grid container justify="center" alignItems="center">
              <Grid container justify="center" alignItems="center">
                <h4
                  style={{
                    textAlign: "center",
                    color: "#4dc7f7",
                    // fontFamily: "Varela Round",
                    fontSize: "1.75rem",
                    marginBottom: "0.75rem",
                  }}
                >
                    {this.props.match.params.company.toUpperCase().replaceAll('-',' ').replaceAll('_',' ').replace('',' ')}
                </h4>
              </Grid>
              <Grid container justify="center" alignItems="center">
                <Button
                  color="primary"
                  onClick={() => {
                    this.props.history.push("/ty-diaries");
                  }}
                  className="my-2"
                  startIcon={<ArrowBack />}
                  style={{
                    color: "white",
                    fontSize: "25px",
                  }}
                >
                  Go back
                </Button>
              </Grid>

              <TyDiaries
                item={item}
                interns={item.interns}
                title={"TY Internship Diaries"}
                company={item.company}
                {...this.props}
                // id={id}
              />
            </Grid>
          </div>
        </div>
        <Footer></Footer>
      </main>
    );
  }
}

export default CompanyWise;

import React from "react";

const piyusha_vidhale = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Piyusha Vidhale
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Deutsche Bank
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '21
            </p>
          </div>
          <br />
          <p>
            Deutsche Bank conducted one test and four interview rounds for
            placement. Each was an elimination round.
          </p>
          <p>
            <ol type="1">
              <li>Coding Test</li>
              <li>Technical Interview 1</li>
              <li>Technical Interview 2</li>
              <li>Professional-fitness interview </li>
              <li>HR</li>
            </ol>
          </p>
          <p>
            <i>Coding Test:</i>
            <br />
            <br />
            This round was conducted on HackerEarth platform. There were three
            coding questions. The questions were based on Dynamic Programming,
            Graph theory and involved some mathematics. The time was 90 mins.
            The difficulty level was both moderate and hard. Please get your
            hands on data structures, string manipulation, all
            merging-sorting-searching algorithms and of course keep practicing
            the competitive programming, quants and all other stuff like that.
            Also, involve yourself in platforms like HackerRank, HackerEarth,
            GeeksForGeeks, CodeChef, etc you would have already understood its
            significance as all the seniors mention that.
          </p>
          <p>
            <i>Technical interview 1 : 45 mins</i>
            <br />
            <br />
            The interviewer introduced himself and then asked me to do the same.
            I was quite nervous initially but after the introduction, I felt
            comfortable and confident.
            <br /> He started off by the giving me the real life problems and
            asked me to apply the programming concepts for each situation like
            functions, threads, conditional statements, multiprocessing,
            multiprogramming, arrays, etc. <br />
            Then he asked me to write code for sorting the elements based on
            conditions including time complexities. Followed by that he asked me
            the different merging, searching algorithms, their similarities and
            differences. After that he moved towards the concepts of the
            technical skills which I had mentioned in my resume. He asked me to
            write code based on the concept of reusability.
            <br /> After that the discussion moved towards RDBMS. He asked me to
            write some sql queries. The questions were based on nested queries
            and joins. Then he asked me a few questions on normalization.
            <br /> At the end of the round we discussed my projects which were
            mentioned in my resume. My projects were based on multiple domains,
            technologies and concepts which unbolted an extensive prospect of
            questions.
          </p>
          <p>
            <i>Technical interview 2 : 45 – 50 mins</i>
            <br />
            <br />
            First the Introduction Then he asked me about my interview
            experience, contribution, core concepts of the technologies and
            programming that I used during internship.
            <br /> The interviewer asked me to write a program to do some string
            manipulation by arranging the elements then to search a specific
            pattern and then to reverse it and given a condition for the lowest
            or the minimum time.
            <br /> Followed by that he asked to me select a data structure to
            store and then to efficiently retrieve the data of employees
            according to their joining. After that he gave me some tables and to
            arrange them in 2NF, 3NF, BCNF, etc (normalization in DBMS). <br />
            Deep discussion on technical skills mentioned on resume. Questions
            were based on min-heap, ma heap, RDBMS, JavaScript function call,
            inheritance, abstract methods, constructors, overloading,
            overriding, polymorphism, virtual class python concepts
            dictionaries, tuples, indexing, slicing, lambda function, recursion,
            etc. I don’t remember all the questions ..just make sure you have
            core knowledge, implementation and your concepts are clear about
            everything that you mention in your resume.
            <br /> Followed by that he gave me some programs and asked me what
            will be the output of those programs with justification.Then he
            asked me the operating system concepts like deadlock, threads,
            semaphores, mutex. Followed by that he asked me questions on hashing
            and indexing. Then we had discussion on Projects. The interviewer
            then asked me if I had any questions. I had some doubts regarding
            the technology department structure so I asked and he cleared them.
          </p>
          <p>
            <i>Professional fitness interview : 30 – 40 mins</i>
            <br />
            <br />
            The interviewer started with a birthright question “Tell me about
            yourself.” <br />
            Then the interviewer asked me to explain my DSA project. Along with
            that he gave me a problem statement relevant to that project and
            asked me explain how my project will resolve that problem statement
            with all the technicalities, approach, steps, and with efficient
            results.
            <br /> After that he asked me some math puzzles. Followed by that he
            asked me some puzzles which were based on observation skills. The
            interviewer asked me what I have learnt or what skills I have gained
            in COEP apart from the technical skills and an incident justifying
            them.
            <br /> Then he asked me to express myself on a topic “Diversity”. We
            had a good discussion on that. The interviewer then asked me if I
            had any questions. I genuinely had a few questions related to the
            segments mentioned in pre-placement talk so I asked and he explained
            them.
          </p>
          <p>
            <i>HR round : 20 - 30mins</i>
            <br />
            <br />
            This time also the interviewer introduced himself and then asked me
            to do the same. <br />
            The first question he asked me was “Why bank?” Then he asked “Why
            Deutsche Bank?”After that he asked me to give top 5 reasons to hire
            me. The next question was “What are the most important elements of a
            Bank?”
            <br /> Then he asked me “If you have given a choice what will you
            be: A team leader or A team player” and we had a very good
            discussion on that as well. Finally he asked me if I had any
            questions. I asked about the different entities of the bank and he
            explained those.
          </p>
          <br />
          In the complete process, most of the interview questions stared off
          based on the resume. Sometimes the next question is based on your
          response to the previous one. The interviewer can ask some impromptu
          questions or puzzles which test your other skills like observation,
          decision making, your approach to stressful situations, etc. <br />
          <br />
          For placement preparation I used HackerRank, HackerEarth,
          GeeksForGeeks IndiaBix, R.S Aggrawal , online mock aptitude tests,
          mock interviews with family, friends and sometimes with myself :)
          <br />
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:vidhaleps18.comp@coep.ac.in">
              vidhaleps18.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/piyusha-vidhale-99a754136/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default piyusha_vidhale;

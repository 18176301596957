import * as React from "react";
// import Grid from "@mui/material/Grid";
import { useState } from "react";
import "../assets/css/testimonial.css";
import { Button } from "@material-ui/core";
import { Box } from "@mui/material";
// import editorial_2022 from "../assets/js/editorial";
// import PropTypes from 'prop-types';
// import { styled } from '@mui/material/styles';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import IconButton from '@mui/material/IconButton';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { makeStyles } from "@material-ui/core/styles";
// import { codeQuestParticipants } from "../assets/js/CodeQuestParticipants";
import codeQuestParticipants from "../assets/js/CodeQuestParticipants2024.json"
import { PDFDocument, StandardFonts } from 'pdf-lib';
import particpantCertificate from "../assets/Certificate/CODEQUEST4.pdf"
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    },
    "& .MuiOutlinedInput-input": {
      color: "white"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white"
    },
    "& .MuiInputLabel-outlined": {
      color: "white"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "white"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white"
    }
  }
});
export default function Editorial() {
  // const onDownload = (linkName, title) => {
  //     let a = document.createElement("a");
  //     a.href = linkName;
  //     console.log(linkName);
  //     a.setAttribute("download", title);
  //     a.click();
  // };
  const classes = useStyles();
  const [HackerRankId, setHackerRankId] = useState('');
  const [MIS, setMIS] = useState('');
  const [email, setEmail] = useState('');
  // const [pdf,setPDF]=useState('')
  async function downloadCertificate() {

    if (HackerRankId === '' || HackerRankId === null || HackerRankId === NaN || MIS === '' || MIS === null || MIS === NaN || email === '' || email === null || email === NaN) {
      alert("Please Enter all the values");
    }
    else {
      var flag = 0;
      var index = 0;
      for (var i = 0; i < codeQuestParticipants.length; i++) {
        if (codeQuestParticipants[i].uname === HackerRankId && codeQuestParticipants[i].MIS == MIS && codeQuestParticipants[i].email === email) {
          flag = 1;
          index = i;
          break;
        }
      }
      if (flag === 0) {
        alert("The details entered are not correct");
      }
      else {
        const existingPdfBytes = await fetch(particpantCertificate).then(res => res.arrayBuffer())
        // var bytes = new Uint8Array(existingPdfBytes)
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic)

        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        const { width } = firstPage.getSize()
        var n = codeQuestParticipants[index].Name.length;

        firstPage.drawText(codeQuestParticipants[index].Name, {
          x: width / 2 - 13 * (n),
          y: 380,
          size: 50,
          font: helveticaFont,
        })
        const pdfBytes = await pdfDoc.save();
        var blob = new Blob([pdfBytes], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filename = `${codeQuestParticipants[index].Name}_CodeQuest3.0_Certificate.pdf`;
        link.download = filename;
        link.click();
      }
      setHackerRankId('');
      setEmail('');
      setMIS('');
    }
  }
  return (
    <Box >
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "30px", }}>
        <div class="card_testimonial">
          <div class="card-content_testimonial">
            <h1 style={{ textAlign: "center", marginBottom: "30px" }}>
              Download Certificates
            </h1>
            <h4 style={{ color: "white", textAlign: "left", marginBottom: "30px" }}>
              Enter Your HackerRank Id
            </h4>
            <TextField className={classes.root} value={HackerRankId} id="outlined-basic" label="HackerRank Id" variant="outlined" onChange={e => setHackerRankId(e.target.value)} />
            <h4 style={{ color: "white", textAlign: "left", marginTop: "30px", marginBottom: "30px" }}>
              Enter Your College MIS
            </h4>
            <TextField type="number" className={classes.root} value={MIS} id="outlined-basic" label="MIS" variant="outlined" onChange={e => setMIS(e.target.value)} />
            <h4 style={{ color: "white", textAlign: "left", marginTop: "30px", marginBottom: "30px" }}>
              Enter the Email Id you signed up with
            </h4>
            <TextField className={classes.root} value={email} id="outlined-basic" label="Email Id" variant="outlined" onChange={e => setEmail(e.target.value)} />
            <div style={{ marginTop: "30px" }}>
              <Button variant="contained" style={{ backgroundColor: "#003399", color: "white" }} onClick={downloadCertificate} size="large">Download</Button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

import React from "react";

const sharvari_phand = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Sharvari Phand
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>mastercard</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The entire process was divided into 4 rounds:                        <br />
                        </p>
                        <p>
                            <ol>
                                <li>Online Aptitude Test</li>
                                <li>Tech Interview Round-1</li>
                                <li>Tech Interview Round-2</li>
                                <li>HR Round</li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The Aptitude test consisted of 2 coding questions only. The duration of the test was 60 minutes. Difficulty level of the first question was moderate to high, while that of the second question was easy to moderate.

                            The first question was similar to <a href="https://www-geeksforgeeks-org.cdn.ampproject.org/v/s/www.geeksforgeeks.org/minimize-refills-to-reach-end-of-path/amp/?amp_gsa=1&amp_js_v=a9&usqp=mq331AQIUAKwASCAAgM%3D#amp_ct=1693739013205&amp_tf=From%20%251%24s&aoh=16937389792339&referrer=https%3A%2F%2Fwww.google.com&ampshare=https%3A%2F%2Fwww.geeksforgeeks.org%2Fminimize-refills-to-reach-end-of-path%2F">this one</a>.

                            <br />
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ol>
                            <li>Aptitude test: As described above.</li>
                            <li>Tech Interview Round-1:
                                This round lasted for around 40 minutes. The interviewer asked me to introduce myself and then I was asked to explain any one of my projects in detail and was asked several questions on that. The interviewer also asked me to write down the code for two questions(Difficulty- easy). I was also asked several HR oriented questions in this round like:
                                <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                    <li>What do you think, people think of the IT industry?
                                    </li>
                                    <li>What are my strengths and weaknesses and how am I tackling them?
                                    </li>
                                    <li>What is my biggest achievement till date?
                                    </li>
                                    <li>What causes stress in the IT industry?</li>
                                    <li>Describe myself in 3 words.</li>
                                    <li>What qualities in me, I think will be best suited for the Company?</li>
                                    <li>What did I like the most about MasterCard?</li>
                                    <li>Some situational questions</li>
                                </ul>
                                While some students were also asked questions based on OS,Java,etc.
                            </li>
                            <li>Tech Interview Round-2:<br />
                                This round lasted for around 30 minutes. The interviewer asked me some logical reasoning questions, puzzles, tech skills, etc. I was also asked to explain all my projects, technologies and algorithms used in them, my role in the group projects, etc. The interviewer also asked about my favourite subject(OOPs) and to explain the key concepts.
                            </li>
                            <li>HR Round: <br />
                                This round lasted for about 15 minutes. The interviewer asked me to introduce myself(Make sure to include your hobbies here). It was a candid talk, wherein I was asked about what motivated me to join MasterCard, extracurriculars, hobbies, projects(why did I choose a particular topic?), family background, future plans(Any plans for masters? If yes, where? If not, why?), what drives me?, etc. Just remember to be firm on your opinion and what you say. They may try to confuse you, by asking the same questions, indirectly! Be honest, confident and realistic while answering the questions here.
                            </li>
                        </ol>
                        In each round I was asked if I had any questions for the interviewer.
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Around 64 students were shortlisted after the Online test.
                        <br />Around 30 students were selected for tech interview-2.
                        <br />20 students were shortlisted for the HR round.
                        <br />Finally, 13 students were offered the internship.

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <a href="https://450dsa.com">450 DSA Cracker</a>
                        <br /><a href="https://www.geeksforgeeks.org/dsa-sheet-by-love-babbar/">DSA sheet by Love Babbar</a>
                        <br /><a href="https://www.ambitionbox.com/topics/aptitude/questions-and-answers">Ambition Box</a>
                        <br /><a href="https://www.indiabix.com/aptitude/questions-and-answers/">IndiaBix</a>

                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Try to be consistent. Practice coding daily, as much as possible. Try to understand the concepts and approach used to solve the question rather than memorising the code.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            I was asked about my project in the tech rounds. A few questions were asked about clubs and extracurricular activities in the HR round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Do not miss the Presentation. They ask a lot of questions like why mastercard, etc. Be thorough with your projects and whatever you mention in your resume. Be confident while answering.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            The entire process can be frustrating and tiring. But all you need is to stay calm and optimistic. It is highly luck based. Just keep trying and don’t give up . All the best!
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:phandsh21.comp@coeptech.ac.in">
                            phandsh21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default sharvari_phand;

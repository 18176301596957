import coordinator from "../../img/team/2019/coordinator.jpg";
import hod from "../../img/team/2019/hod.jpg";
import newhod from "../../img/team/2019/newhod.jpeg";
import president from "../../img/team/2022/president.jpeg";
import vicePresident from "../../img/team/2022/vicePresident.jpg";
import techLead1 from "../../img/team/2022/techLead.png";
import techLead2 from "../../img/team/2022/techLead2.jpg";
import financeHead1 from "../../img/team/2022/financeHead.jpg";
import financeHead2 from "../../img/team/2022/financeHead2.jpg";
import eventHead1 from "../../img/team/2022/e&oHead.jpg";
import eventHead2 from "../../img/team/2022/e&oHead2.jpg";
import creativesHead1 from "../../img/team/2022/creativesHead.jpg";
import creativesHead2 from "../../img/team/2022/creativesHead2.jpg";

const team2022 = [
  {
    post: [
      {
        initial: "Dr. (Mrs)",
        name: "Vahida",
        surname: "Attar",
        desc: "Former Head, Department of Computer Engineering and Information Technology",
        image: hod,
        mail: "mailto:vahida.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/vahidacompcoepacin",
      },
      {
        initial: "Dr.",
        name: "Pradeep",
        surname: "Deshmukh",
        desc: "Head, Department of Computer Engineering and Information Technology",
        image: newhod,
        mail: "mailto:hod.comp@coep.ac.in",
        about: "https://www.coep.org.in/departments/computerit/aboutdep",
      },
      {
        initial: "Mr.",
        name: "Amit",
        surname: "Joshi",
        desc: "CSI COEP Student Chapter Coordinator",
        image: coordinator,
        mail: "mailto:adj.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/adjcompcoepacin",
      },
    ],
  },
  {
    post: [
      {
        name: "Vedanshi",
        surname: "Shah",
        position: "President",
        image: president,
        linkedin: "https://www.linkedin.com/in/vedanshi-shah-2835501b8",
        github: "https://github.com/Vedanshi-Shah",
      },
      {
        name: "Prerna",
        surname: "Tulsiani",
        position: "Vice President",
        image: vicePresident,
        linkedin: "https://www.linkedin.com/in/prerna-tulsiani-00b894202/",
        github: "https://github.com/pt3002",
      },
    ],
  },
  {
    post: [
      {
        name: "Sneha",
        surname: "Venkateswaran",
        position: "Technical Head",
        image: techLead1,
        linkedin:
          "https://www.linkedin.com/in/sneha-venkateswaran-048793214/",
        github: "https://github.com/sneha-0723",
      },
      {
        name: "Qasim",
        surname: "Shaikh",
        position: "Technical Head",
        image: techLead2,
        linkedin: "https://www.linkedin.com/in/qasim-shaikh-444217221/",
        github: "https://github.com/shaikhmq20",
      },
      {
        name: "Christy",
        surname: "Biju",
        position: "Finance Head",
        image: financeHead1,
        linkedin: "https://www.linkedin.com/in/christy-biju-32bb22228",
        github: "https://github.com/ChristyBiju",
      },
      {
        name: "Ishan",
        surname: "Patwardhan",
        position: "Finance Head",
        image: financeHead2,
        linkedin: "https://in.linkedin.com/in/ishan-patwardhan-a8b989209",
        github: "https://github.com/ishan-pat",
      },
      {
        name: "Anushka",
        surname: "Naik",
        position: "Events & Operations Head",
        image: eventHead1,
        linkedin: "https://in.linkedin.com/in/anushka-naik-b09494207",
        github: "https://github.com/anushka-naik",
      },
      {
        name: "Niruppreet",
        surname: "Kour",
        position: "Events & Operations Head",
        image: eventHead2,
        linkedin: "https://in.linkedin.com/in/niruppreet-kour-3765b4207",
        github: "https://github.com/niruppreetkour",
      },
      {
        name: "Neha",
        surname: "Pawar",
        position: "Creatives Head",
        image: creativesHead1,
        linkedin: "https://www.linkedin.com/in/neha-pawar-987b17211",
        github: "https://github.com/Neha151200",
      },
      {
        name: "Ananya",
        surname: "Patil",
        position: "Creatives Head",
        image: creativesHead2,
        linkedin: "https://www.linkedin.com/in/ananya-patil-4395401a9",
        github: "https://github.com/patil08ananya",
      },
    ],
  },
];

export default team2022;

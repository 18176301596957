import React from "react";

const kunal_chaudhari = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Kunal Chaudhari
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Nuance Communications
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              In the hiring process, some students directly got an interview
              call (depending on some unknown criteria) while some got an
              interview call after giving an aptitude test.{" "}
            </p>
            <p>The hiring process consists of two rounds.</p>
            <ol>
              <li>
                <strong>Aptitude Test</strong>
              </li>
              <li>
                <p>
                  <strong>Technical + HR round</strong>s
                </p>
              </li>
            </ol>
            <p>
              After the complete process, a total of 9 students were selected
              for the final offer.
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              An aptitude test was conducted on the CoderByte platform. It
              consisted of total 20 questions divided into 3 sections and to be
              solved in 90 mins.
            </p>
            <p>The structure of the aptitude test was as follows:</p>
            <ol>
              <li>
                <strong>Coding Questions:</strong>
                <p>
                  There were two coding questions.
                  <ol
                    style={{
                      listStyleType: "lower-alpha",
                    }}
                  >
                    <li>
                      Based on Graphs. The problem was much similar to finding
                      the number of islands in a 2D matrix. You can find it here
                      -{" "}
                      <a href="https://www.geeksforgeeks.org/find-number-of-islands/">
                        Find Number of Islands
                      </a>
                    </li>
                    <li>
                      String Manipulation - Find hamming distance between two
                      strings.
                    </li>
                  </ol>
                </p>
              </li>
              <li>
                <strong>Subjective Questions:</strong>
                <p>
                  There were total 3 subjective questions
                  <ol
                    style={{
                      listStyleType: "lower-alpha",
                    }}
                  >
                    <li>What are your strengths and weaknesses?</li>
                    <li>Why do you want to join Nuance? </li>
                    <li>Where do you see yourself in 5 years?</li>
                  </ol>
                </p>
              </li>
              <li>
                <strong>Objective Questions:</strong>
                <p>
                  Total 15 objective questions based on concepts of DSA, DBMS,
                  OS, CN, and quantitative aptitude.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              There was 1 round which is a combination of both Technical and HR
              (Approx. 1hr).{" "}
            </p>
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li>
                The interview started with an introduction then the interviewer
                asked me to tell brief info about all my projects. Then he told
                me to elaborate more about my recent project and asked some
                technical questions based on it and also some general questions
                like the motivation behind the project, challenges in the
                project, etc.
              </li>
              <li>
                Then he switched to questions based on concepts in OOPs like
                virtual functions, 4 pillars of OOPs, overloading, overriding,
                etc.{" "}
              </li>
              <li>
                One question was given for live coding. The coding Question was
                based on dynamic programming – John running up a staircase with
                n steps. He can hop at most m steps at a time. Find the total
                number of ways to reach the end. Ex. If n = 5 and m = 3
                <br />
                Then total ways = 13
                <br />
                (1,1,1,1,1), (1,1,1,2), (1,1,2,1), (1,2,1,1), (2,1,1,1),
                (1,1,3), (1,3,1), (3,1,1), (1,2,2), (2,1,2), (2,2,1), (2,3),
                (3,2)
              </li>
              <li>
                After that, he asked me a puzzle -{" "}
                <a href="https://www.geeksforgeeks.org/puzzle-heaven-hell/">
                  Heaven and Hell
                </a>
              </li>
              <li>
                Also, some questions were based on DBMS related to ER diagrams,
                Normalization.
              </li>
              <li>
                We also had a good discussion on “What is better and why? –
                Desktop app or Web app or Android app”
              </li>
              <li>
                <strong>HR Questions:</strong>
                <ol>
                  <li>What are your strengths and weaknesses?</li>
                  <li>How do you handle conflict with your co-worker?</li>
                  <li>Why do you want to join Nuance? </li>
                  <li>What are your future goals?</li>
                </ol>
              </li>
            </ul>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <br />
            <p>
              A lot of questions were based on my resume. In my case, resume was
              starting point for my interview. Many questions were based on my
              projects, competitions that I had participated in, skills that I
              had mentioned in my resume. So be careful while mentioning
              anything on your resume, mention only those things about which you
              are confident.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              <ul
                style={{
                  marginLeft: "2rem",
                  listStyleType: "disc",
                }}
              >
                <li>
                  For coding practice: Hackerrank, Leetcode, GeeksForGeeks.
                </li>
                <li>I also solved Love Babbar’s 450 questions on DSA. </li>
                <li>OS, DBMS, CN, OOPS: GeeksForGeeks, studytonight.com</li>
                <li>
                  Any puzzle that is asked by the interviewer in an interview
                  can be found on GeeksForGeeks. So prepare puzzles from GFG.
                </li>
                <li>Aptitude: Indiabix</li>
                <li>
                  Also, before any interview read past interview experiences
                  from GeekForGeeks and CSI COEP Website.{" "}
                </li>
              </ul>
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Practice is the key to crack any coding question. Try to solve
              different types of coding questions. Also cover all different
              topics like dynamic programming, tree, graphs, bit manipulation,
              etc. I will strongly recommend you to solve Love Babbar’s 450
              questions on DSA since it covers all different types of problems
              on different topics. Remember, you have to give time to it. It is
              fine if you are unable to solve questions initially, try to
              understand solutions and keep practicing.
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            Solving live coding questions and puzzles will surely impress
            interviewers. Also, sometimes it is difficult to come up with
            answers to HR questions in that situation. So, prepare for such
            typical HR questions beforehand and be confident while answering.
          </p>
          <br />
          <p>
            <i>
              <p>
                Stay motivated during the overall process. Sometimes things will
                not happen according to you. Keep analyzing your mistakes and
                learn from them. Reach out to your friends, seniors in case of
                any difficulty. It is just about that one right time, which
                makes all your days of hard work and struggle seem worth it. So,
                keep trying.
              </p>
              <p>All the best <span role={"img"} aria-label="happy">😊</span></p>
            </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:chaudhariks19.comp@coep.ac.in">
              chaudhariks19.comp@coep.ac.in
            </a>
            <br />
            {/* <a href="https://www.linkedin.com/in/anupn08/">LinkedIn Handle</a> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default kunal_chaudhari;

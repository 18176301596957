import React from "react";

const ketaki_jadhav = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Ketaki Jadhav
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
           Citi Bank
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
            For Citi Bank, we had a total of 3 rounds.


            </p>
            <ol>
              <li>
                <p>Aptitude test</p>
              </li>
              <li>
                <p>Technical Interview</p>
              </li>
              <li>
                <p>HR Interview</p>
              </li>
            </ol>
           
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
            In the aptitude test we had different sections:
            <ol>
                <li>
                    <p>
                    <b>Quantitative:</b> This section had basic math and logical reasoning questions. 
                    </p>
                </li>
                <li>
                    <p>
                   <b>Techincal:</b> This section had some MCQs based on DSA, time-complexity 
of algorithms, C language questions, etc. Some code snippets from the C language were given and we had to predict the output
 
                    </p>
                </li>
                <li>
                    <p>
                    <b>Verbal:</b> This section had questions based on paragraphs to test our vocabulary. 
                    </p>
                </li>
                <li>
                    <p>
                    <b>Programming:</b> This section had two coding questions. One was based on GCD 
and the other question included array manipulation. We had to solve them in C/C++ language. These questions were pretty easy and doable. 

                    </p>
                </li>
            </ol>
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
          
            <ol>
              <li>
                <strong>Technical Interview Round</strong>
                <p>
                This round was heavily based on OOPs concepts for me. She asked me about different OOPs concepts like shadowing, virtual functions, different levels of inheritance, polymorphism and its real-life examples, etc. She gave me one code snippet regarding multi-level inheritance and asked me what will be the output. Then she looked at my resume and asked some questions about the skills that I included in it. Also at the end, she asked me one simple math question.
So prepare OOPs concepts and also keep in mind some real-life examples related to those concepts. Also include only those things in your resume about which you will be confident to answer any related questions. Don’t panic during the interview and be frank with him/her if you didn’t understand something asked by him/her.

                </p>
               
              </li>
              <li>
                <p>
                  <strong>HR-Round </strong>
                  <p>So first of all she asked me to introduce myself as in a typical HR interview. She asked me about my interests/hobbies. Then we discussed about the projects that I had included in my resume. She asked me why I chose to do a particular project and also asked me to explain the whole process in which I completed my projects. As I had done a project in Deep Learning; she asked why did I choose that particular model and what could be the other possible approaches.
At the end she asked me “Where do you see yourself in 5 years?”. Try to be as specific and reasonable as you can while answering such questions.

</p>

                </p>
              </li>
              
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <p>
            There were few questions asked by them based on projects/resume in both the interview rounds.
            </p>
           
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
           <li><a href="https://www.interviewbit.com/">InterviewBit</a></li>
           <li><a href="https://www.geeksforgeeks.org/a-complete-step-by-step-guide-for-placement-preparation-by-geeksforgeeks/">Geeks for Geeks</a></li>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
            Practice as many questions of DSA as you can from different coding platforms like GFG, Hackerrank, Leetcode etc. 450 questions by Babbar will help you a lot. 
            </p>
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <p>
          During coding rounds, for us, there were only two choices for a programming language(C and C++). So be sure to practice basic concepts in these languages before the coding round. But keep in mind that this may change at your time.
          </p>
          </p>
         
          <br />
          <p>
              <i>
                  <p>
                  Be calm and patient during the whole process. All the Best !!
                  </p>
              </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:jadhavkk19.comp@coep.ac.in">
            jadhavkk19.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/ketaki-jadhav-27b5591b4">LinkedIn Handle</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ketaki_jadhav;

import React from "react";

const shubham_somawanshi = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Shubham Rajkumar Somwanshi
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Societe Generale
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              Structure of the overall process was almost similar like:
              <ol>
                <li>Aptitude test</li>
                <li>Technical Interview</li>
                <li>Managerial Interview</li>
                <li>HR Round</li>
              </ol>
            </p>
          </p>

          <p>
            <b>2. Structure of Aptitude Round :</b>
            <br />
            There were a around total of 35 questions in 90 minutes.
            <br />
            <b>Some questions on:</b>
            <ol>
              <li>Aptitude questions: Ratios, Blood realations, Quants etc</li>
              <li>
                Computer Basics output: predicting output, basic questions on
                core subject English, Reasoning
              </li>
              <li>Two questions on coding questions</li>
            </ol>
          </p>

          <p>
            <b>
              3. How many rounds were there for the company, can you elaborate
              about each round in brief?
            </b>
            <p>There were 3 rounds after aptitude:</p>
            <ol>
              <li>
                <strong>Technical Round:</strong>
                <p>
                  The interview started with introduction like “Tell me about
                  yourself”, followed by questions on my internship(role,
                  contribution,technologies used, etc), and extracurricular
                  activities.
                  <br />
                  Then he started asking questions.
                  <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                    <li>
                      He started from Database, asked to explain about DDL
                      commands, list down which you have used in project.
                    </li>
                    <li>
                      Then he asked about joins, normal forms in DBMS with
                      example.
                    </li>
                    <li>
                      Asked to write queries for finding maximum salary (1st
                      max, 2nd max, 3rd max) and optimize it.
                    </li>
                    <li>
                      Then he asked me difference between delete and
                      truncate(answer expected was related to memory used). I
                      was a confused here but he gave me a hint and I did it.
                    </li>
                    <li>
                      Then he moved on OOPs, asked me to explain oops, and asked
                      more questions on overloading and overriding
                    </li>
                    <li>
                      After that he asked me code for sorting algorithm. Asked
                      to tell all sorting algorithms and implement any one
                      sorting algorithm.and time complexity for all
                    </li>
                    <li>
                      Then he asked me about my projects (on web development)
                      and explain how i implemented a particular feature, and
                      also flow of the project.
                    </li>
                    <li>
                      In the end he said that he will linked me for the second
                      round shortly.
                    </li>
                  </ul>
                </p>

                <li>
                  <strong>Managerial Round</strong>
                  <br />
                  <p>
                    <ul
                      ul
                      style={{ listStyle: "circle", paddingLeft: "40px " }}
                    >
                      <li>
                        The interviewer started with introduction of both, then
                        he stated that he is interested on my project, and asked
                        me to explain all projects.
                      </li>
                      <li>
                        Then he asked me to explain what what unique feature i
                        have (he want to know that what is the difference
                        between my project and the similar application present
                        on the internet) and how exactly have i Implemented it
                      </li>
                      <li>
                        After that he asked me the contribution in group project
                        and some questions on same, why this? Why not other?
                        Etc.
                      </li>
                      <li>
                        Then he moved on to internship project, what was the
                        difficulties faced, how did i overcome it, team bonding
                        in company, problem faced with mentor, problem faced
                        during debugging, etc.
                      </li>
                      <li>
                        After that, he gave me different situation, like:
                        <ul
                          style={{ listStyle: "circle", paddingLeft: "40px " }}
                        >
                          <li>
                            Suppose you have many tasks to complete, how will
                            you tackle this situation
                          </li>
                          <li>
                            Suppose you are a Leader and want to assign a taks
                            to group which includes interns as well as FTE, how
                            will assign a tasks, how will you keep track of work
                            done, how will you help who is stuck or not able to
                            complete tasks, and one guy is not working what will
                            you do?, etc
                          </li>
                          <li>
                            You are good at the front end and if Your TL gives
                            you the work on the back end then what will you do?
                          </li>
                          <li>Where do I want to be after some years?</li>
                          <li>
                            Where do I want to prefer work In a team or
                            Individually? (explanation for both).
                          </li>
                          <li>
                            After this he asked whether i had faced some
                            conflict while working in team environment and how I
                            dealt with it.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Then he inquired about my free time, and what I do in
                        free time.
                      </li>
                      <li>
                        He asked me whether I had questions and I asked
                        something about the work culture. (always ask questions)
                      </li>
                    </ul>
                  </p>
                </li>
              </li>
              <li>
                <strong>HR round:</strong>

                <br />
                <p>
                  HR round was most easy round, where she asked me about my day
                  and introduction.
                  <ul>
                    <li>Then she asked about my family background.</li>
                    <li>Why Societe generale? Why should we select you?</li>
                    <li>
                      What location will you prefer? Any problems in
                      relocations?
                    </li>
                    <li>Are you comfirtable to work in shifts?</li>
                    <li>
                      After that she explains about work culture and facilities
                      they provide in company.
                    </li>
                    <li>
                      At last she asked do I have passport, aadhar, pan etc
                    </li>
                  </ul>
                  The interview ended with my questions.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>4. Some good resources you used in your internship prep</b>
            <br />
            <p>
              Refer any resource which you like and follow the same till end (
              this will help you to gain confidence).
              <br />
              Some of resources I followed were:
              <ul>
                <li>GeeksForGeeks for DSA </li>
                <li>450 sheet by love babbar</li>
                <li>Youtube playlist by Gate smashers for DBMS</li>
                <li>Articles from GFG and tutorialspoints</li>
                <li>
                  Youtube playlist by Love Babbar for OS and notes provided by
                  them.
                </li>
                <li>W3schools for OOPS.</li>
                <li>Youtube playlist by Neso Acadmy </li>
                <li>PPT by NPTEL.</li>
              </ul>
            </p>
          </p>

          <p>
            <b>5. Some tips to crack coding questions</b>
            <br />

            <p>
              <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                <li>First of all BE VERY CLEAR WITH QUESTION.</li>
                <li>Always solve question on paper first and dry run it.</li>
                <li>Try to pass as many taste cases as you can</li>
                <li>
                  During interview coding round:
                  <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                    <li>Always share you approach to interviewer.</li>
                    <li>Make interview intercative</li>
                    <li>Ask hint if you got stuck</li>
                    <li>
                      Try to interviewer convernce and understand your approach
                    </li>
                    <li>
                      Your approach to solve problem is much more valuable than
                      actual coding
                    </li>
                  </ul>
                </li>
                <li>Practice more</li>
              </ul>
            </p>
          </p>

          <p>
            <b>
              6. How many questions were asked about projects, clubs and
              extracurricular activities in your resume :
            </b>{" "}
            <br />
            <p>
              Many questions on projects including technology used, future
              scope, limitations, atchitectures, functionalities provided etc
              (Prepare for projects, its very important,)
              <br />
              Go through your code before interview once.
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              <ol>
                <li>Practice more on coding problems</li>
                <li>Understand your project very deep</li>
                <li>
                  Have a good knowledge for CS fundamentals( CS, OS, DBMS, OOPs)
                </li>
                <li>Practice for questions for managerial round</li>
              </ol>
            </p>
          </p>

          <p>
            <strong>8. Final Tip for Juniors?</strong>
            <p>
              <ul style={{ listStyle: "circle", paddingLeft: "40px " }}>
                <li>Just keep on trying and learn from mistakes.</li>
                <li>Don't make same mistake again</li>
                <li>
                  In online interview they sometimes asks for showing code or
                  projects, so gather all the information they might ask during
                  inerview.
                </li>
                <li>Be confident and say No if you dont know the answer</li>
                <li>
                  Make your introduction good and practice it more as that is
                  the first question you get and it will help you to maintain
                  confidence
                </li>
                <li>Keep your interview interactive.</li>
                <li>
                  Always ask questions to interviewer when they ask do you have
                  any question.
                </li>
              </ul>
            </p>
          </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:shubhams3102@gmail.com">
            shubhams3102@gmail.com
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default shubham_somawanshi;

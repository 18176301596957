import React from "react";

const mihika_sanghvi = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Mihika Sanghvi</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Schlumberger</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>The entire process was divided into 4 rounds:</p>
            <ol>
              <li>Technical Round 1</li>
              <li>Technical Round 2</li>
              <li>Managerial Round</li>
              <li>HR Round</li>
            </ol>
            <b>Structure of Aptitude Round :</b>
            <br />
            There were 20 mcq which were all technical questions. Next there
            were 2 coding questions. One was easy and the other was medium
            level.
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <ol>
              <li>
                <b>Round 1 (aptitude test): </b>
                <br />
                This round tested your coding ability and understanding of
                technical concepts like OOPS. The mcq’s were either theory
                related or output prediction type questions. The 2 coding
                questions were solvable and time given to solve the coding
                section was more than enough.
              </li>
              <li>
                <b>Interview process:</b>
                <br />
                There were a total of 4 interview rounds which happen in a
                random order for every student.My rounds were held in this
                order:
                <ol>
                  <li>
                    Technical Round 2: In this round, the panel asked questions
                    related to the projects in my resume. I was given a coding
                    question in the end and was asked to share 2 approaches to
                    the same problem. I was also asked questions related to
                    DBMS.
                  </li>
                  <li>
                    Managerial round: This round was an indepth interview
                    regarding my resume. He asked regarding my projects,
                    position of responsibilities, extra curricular,
                    certifications as well as my volunteer work. This round was
                    aimed to test my communication skills and understanding of
                    values like teamwork, hardwork and time management.
                  </li>
                  <li>
                    HR round: This was a casual round where we had a talk
                    regarding the company. He encouraged me to feel free and ask
                    any questions regarding Schlumberger.
                  </li>
                  <li>
                    Technical round 1: This was my last round. It was similar to
                    the previous technical round itself but more coding
                    questions were asked. Since it was online, I was asked to
                    share my screen. He also asked to run one of my projects and
                    show it. In this round I was asked questions related to CN
                    as well. Make sure that you know your project thoroughly as
                    most companies focus on this.
                  </li>
                </ol>
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              After aptitude round, <b>17</b> students were shortlisted and{" "}
              <b>2</b> were given the final offer.
            </p>
          </p>
          <p>
            <b>
              4. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <p>
              I was asked multiple questions regarding my projects in every
              round. Clubs and extracurricular activities came up in the
              managerial round.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              I practiced my coding on leetcode and gfg. These are good
              platforms to practice on. I would recommend to stick to 1 and code
              in an orderly manner instead of trying multiple platforms.
            </p>
          </p>

          <p>
            <strong>
              6. Is there something specific that you would want to tell people
              who try for the same company :
            </strong>
            <p>
              Be honest and answer confidently. The panel were very friendly so
              keep a smile on your face and answer the questions.
            </p>
          </p>
          <p>
            <strong>7. Final Tip for Juniors :</strong>
            <p>
              Internship process can be extremely stressful. There are some
              times when your friends or classmates get selected and you may get
              upset but do not lose hope. Just keep practicing and stay focused.
              You will get a fruitful outcome. All the best !!
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:mihikars20.comp@coep.ac.in">
              mihikars20.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default mihika_sanghvi;

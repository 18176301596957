import React from "react";

const arsh_maknojia = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Arsh Maknojia
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Corp</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The interview consisted of three rounds:                        <br />
                        </p>
                        <p>
                            Technical round<br />
                            Managerial round<br />
                            HR round<br />

                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Aptitude round was conducted online which had 4 sections
                            <br />
                            <ol>
                                <li>Coding section had 2 coding questions of easy to medium difficulty
                                </li>
                                <li>Section 2 - Basic questions on Angular (12 ques.)</li>
                                <li>Section 3 - Basic questions on React (12 ques.)</li>
                                <li>Section 4 - Questions on SQL (12 ques.)</li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ol>
                            <li> Aptitude round as described above
                            </li>
                            <li>Technical Round: It was mostly about the projects I had done and was asked to describe the ones he found interesting. Didn’t go in great detail but asked a lot of questions about the projects.
                            </li>
                            <li>Managerial Round: This was like an HR round, no technical questions were asked. Questions regarding likes and family background were asked.
                            </li>
                            <li>HR Round: Questions like why Citi? Etc. were asked.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        For ICG tech -
                        <ol>
                            <li>.After the online aptitude test 39 students were shortlisted for interview round
                            </li>
                            <li>16 students were shortlisted after the first technical round.
                            </li>
                            <li>After managerial round 12 students were selected for HR round</li>
                            <li>10 students received the final offer.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        450 DSA sheet for DSA, Youtube for Core Subjects

                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            It all depends on how many questions you’ve already done and your ability to make connections between different questions you’ve solved. Many who are able to solve these questions correctly have solved them before, very few figure out the solution so practise.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            All interviewers asked about projects, none asked about clubs and extracurricular activites.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            There are a lot of panels during the interviews for the technical round so your luck will decide what kind of interviewer you’ll get.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Interviewers may compromise some wanting in technical skills but they won’t in terms of communication skills.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:arshmaknojia5@gmail.com">
                            arshmaknojia5@gmail.com                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default arsh_maknojia;

import React from "react";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import { makeStyles, Typography } from "@material-ui/core";
import { backGround } from "../layout/customStyle";
import { data } from "../assets/js/recentNews";
import Confetti from 'react-confetti';


const useStyles = makeStyles((theme) => ({
  aboutWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "#00000097",
    backgroundImage:
      "linear-gradient(#00000000, #000000bb 10%, #000000bb 90%, #00000000 40%)",
    padding: "3em 1.5em",
  },
  aboutContainer: {
    maxWidth: "800px",
  },
  aboutText: {
    color: "#7b909c",
    fontSize: "1.75em",
    // fontFamily: "sans-serif",
    marginBottom: "0.5em",
  },
  heading: {
    textAlign: "center",
    color: "whitesmoke",
    // fontFamily: "Kuvas",
  },
}));

const RecentsPage = () => {
  const classes = useStyles();
  return (
    <div style={backGround}>
      <Navbar recent={true} />

      <Confetti
  width={window.innerWidth}
  height={window.innerHeight}
  numberOfPieces={300}
  recycle={false}
  friction={0.99}
/>

      <h1 style={{ marginTop: "4rem" }} className={classes.heading}>
        {"1"}
      </h1>
      <h1 className={classes.heading}>
        {"Congratulations Winners of CodeQuest 4.0"}
      </h1>
      <br />
      <div className="d-flex justify-content-center mt-3">
        <img
          src={require("../assets/img/gallery/CodeQuest4/CodeQuest4.0_Winners.jpeg")}
          style={{ width: "60%" }}
          alt="codequest4"
        />
      </div>

      <h1 style={{ marginTop: "4rem" }} className={classes.heading}>
        {"2"}
      </h1>
      <h1 className={classes.heading}>
        {"Congratulations Winners of Inspiron 2.0"}
      </h1>
      <br />
      <div className="d-flex justify-content-center mt-3">
        <img
          src={require("../assets/img/gallery/ins_winner.png")}
          style={{ width: "60%" }}
          alt="codequest3"
        />
      </div>
      <h1 style={{ marginTop: "4rem" }} className={classes.heading}>
        {"3"}
      </h1>
      <h1 className={classes.heading}>
        {"Congratulations Winners of CodeQuest 3.0"}
      </h1>
      <br />
      <div className="d-flex justify-content-center mt-3">
        <img
          src={require("../assets/img/gallery/Codequest3/img2.jpeg")}
          style={{ width: "60%" }}
          alt="codequest3"
        />
      </div>
      <h1 style={{ marginTop: "4rem" }} className={classes.heading}>
        {"4"}
      </h1>


      <h1 className={classes.heading}>
        {"Congratulations Winners of Inspiron'22"}
      </h1>
      <br />
      <div className="d-flex justify-content-center mt-3">
        <img
          src={require("../assets/img/gallery/Inspiron/Inspiron_winners.png")}
          style={{ width: "60%" }}
          alt="codequest2"
        />
      </div>
      <h1 style={{ marginTop: "4rem" }} className={classes.heading}>
        {"5"}
      </h1>

      <h1 className={classes.heading}>
        {"Congratulations Winners of Codequest 2.0"}
      </h1>
      <br />
      <div className="d-flex justify-content-center mt-3">
        <img
          src={require("../assets/img/gallery/CodeQuest2/2.jpg")}
          style={{ width: "60%" }}
          alt="codequest2"
        />
      </div>
      <h1 style={{ marginTop: "4rem" }} className={classes.heading}>
        {"6"}
      </h1>

      <h1 className={classes.heading}>
        {"Congratulations Winners of Algowar"}
      </h1>
      <br />
      <div className="d-flex justify-content-center mt-3">
        <img
          src={require("../assets/img/gallery/Algowar/2.jpg")}
          style={{ width: "60%" }}
          alt="algowar"
        />
      </div>
      <h1 style={{ marginTop: "4rem" }} className={classes.heading}>
        {"7"}
      </h1>
      <br />
      <h1 className={classes.heading}>
        {"Congratulations Winners of CodeQuest"}
      </h1>
      <div className="d-flex justify-content-center mt-3">
        <img
          src={require("../assets/img/gallery/CodeQuest/2.jpg")}
          style={{ width: "60%" }}
          alt="contributors"
        />
      </div>
      
      {data.map((eachData, index) => {
        return (
          <div key={`recent-news-data-${index}`}>
            {/* <div style={{ marginTop: "2em" }} /> */}
            {eachData.title.map((t, i) => {
              return (
                <h1
                  key={`recent-news-heading-${i}`}
                  style={{ marginTop: i === 0 ? "4rem" : "0.2rem" }}
                  className={classes.heading}
                >
                  {t}
                </h1>
              );
            })}

            <div className={classes.aboutWrapper}>
              <div className={classes.aboutContainer}>
                <ol>
                  {eachData.content.map((c, i) => {
                    return (
                      <Typography
                        key={`recent-news-content-${i}`}
                        variant="body2"
                        component="li"
                        className={classes.aboutText}
                      >
                        {c}
                      </Typography>
                    );
                  })}
                </ol>
              </div>
            </div>
          </div>
          
          
        );
      })}

      <h1 style={{ marginTop: "4rem" }} className={classes.heading}>
        {"10"}
      </h1>
      <h1 className={classes.heading}>
        {"Congratulations Akanksha for winning the First Prize"}
      </h1>
      <div className="d-flex justify-content-center mt-3">
        <img
          src={require("../assets/img/recent-news/akanksha_mahajan.png")}
          alt="contributors"
        />
      </div>

      <hr />
      <Footer />
    </div>
  );
};

export default RecentsPage;

import React from "react";

const bhagyashree_patil = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Bhagyashree Lomesh Patil
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>CITI</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The recruitment process consisted of four rounds:
                            <br />
                        </p>
                        <p>
                            <ol>
                                <li>Aptitude test</li>
                                <li>Technical interview
                                </li>
                                <li>2 HR interviews
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            I had opted for the PBWMT domain, which had the following aptitude test structure. Other domains had different aptitude test structures.
                            Aptitude test had a difficulty level of easy/moderate.<br />
                            It consisted of sections like Quantitative ability, Logical reasoning, Technical MCQs and 2 coding questions.
                            The technical MCQs mostly had output based questions and coding questions both easy/moderate based on basic concepts.
                            <br />All the sections were individually timed.

                            <br />
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 3 interview rounds after the aptitude test, as follows:<br />
                        <ol>
                            <li>Technical interview: <br />
                                Questions asked in this interview were mostly based on the projects that I had mentioned in my resume.
                                The interviewer began by asking me to explain both of my projects. I was then asked about the tech stack used in my projects and why. He then asked me about the real life applications of these projects. Throughout the interview, the focus was on practical working and applications rather than theoretical aspects.
                                One of my projects used data structures like graphs and hashtables, and algorithms like the dijkstra algorithm. I was asked to explain the working of dijkstra algorithm using a simple graph and the working of the above mentioned data structures.
                                I was then asked about any other subjects that I was interested in and was asked a few questions based on that.
                                The interview concluded with any questions that I may have for him.
                            </li>
                            <li>HR Interview 1:<br />
                                This round lasted about 15-20 minutes. I was asked basic questions about the skills, subjects, hobbies, etc. mentioned on my resume. She then proceeded to ask me about the work I had done in college fests and any extracurricular activities. Make sure you are aware of all the details mentioned on your resume.
                            </li>
                            <li>HR Interview 2:<br />
                                This round lasted about 15 minutes. The interviewer began by asking me to introduce myself. He then proceeded to ask me why i wanted to join citi, what were my strengths and weaknesses and why i wanted to pursue this particular field. Do read about the company and be aware of their values beforehand.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        For the PBWMT domain, about 15 students were shortlisted for the technical interview.
                        <br />About 7 students were shortlisted for the second round and 4 students were selected for the third round.
                        <br />Final offer was given to 3 students for the PBWMT domain.
                        <br />For the ICG domain 10 students were given the offer.

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                GeeksForGeeks and LeetCode for coding practice                            </li>
                            <li>
                                GeeksForGeeks for understanding concepts</li>
                            <li>
                                Indiabix for aptitude questions
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Be consistent and solve as many types of questions as you can. Try analysing and understanding the logic used in the questions you practice.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            A lot of questions were asked about the projects in the technical round so make sure to know your projects extremely well.
                            A few questions about clubs and extracurriculars were asked in the second round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            This process can be quite tiring and draining, but be optimistic and don’t lose hope.
                            <br />Keep calm and do your best.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:bhagyashreelp21.comp@coeptech.ac.in">
                            bhagyashreelp21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default bhagyashree_patil;

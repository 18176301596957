import React from "react";

const rupali_bawale = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Rupali Bawale
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Gainsight</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <li style={{ marginLeft: "10px" }}>
              1 Aptitude test and 3 interview rounds
            </li>
            <li style={{ marginLeft: "10px" }}>
              2 technical rounds were elimination rounds and 1 managerial round
            </li>
            <li style={{ marginLeft: "10px" }}>
              Interviewer were friendly and calm.
            </li>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            Aptitude test was of 1 hr 45 min on HackerEarth platform which
            included 10-15 mcq based on CS fundamentals and 4 coding questions
            (2 ques – 20 marks each, 1 ques – 50 marks, 1 ques – 100 marks). 3
            questions were of easy category and one was medium level dynamic
            programming question.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <i>Technical Round 1</i>
            <br />
            Technical Round 1 was of approximately 1 hr in which they asked me
            to write 4-5 easy coding questions, SQL queries, OS and about
            project. Some of the questions are listed below:
            <ol type="1">
              <li>Find middle element of linked list</li>
              <li>
                Write the number formed by linked list elements eg. 1 → 2→ 3
                then{" "}
              </li>
              o/p: 123 and 321
              <li>
                Tree Traversal(difference between each traversal), BFT code
              </li>
              <li>
                SQL query to print student name who has obtained highest marks
                for each subject{" "}
              </li>
              <li>What is virutal memory?</li>
              <li>
                About scheduling algorithms ( situation was given based on which
                we needed to answer which scheduling algorithm is best suitable
                and why ?)
              </li>
            </ol>
            <i>Technical Round 2</i>
            <br />
            This round was of 1 Hr 30 min. OS, DB, 2 coding questions were
            asked. Interviewer was very sweet and supportive. Gave hints
            whenever I got stuck.
            <ol type="1">
              <li>What is Thrashing?</li>
              <li>What is Normalisation?</li>
              <li>
                <a href="https://practice.geeksforgeeks.org/problems/given-a-linked-list-of-0s-1s-and-2s-sort-it/1">
                  Coding Problem 1
                </a>
              </li>
              <li>
                <a href="https://www.geeksforgeeks.org/design-and-implement-special-stack-data-structure/">
                  Coding Problem 2
                </a>
              </li>
            </ol>
            <i>Managerial Round</i>
            <br />
            It was of approximately 30 min.
            <ol type="1">
              <li>
                Questions related to B.Tech project (team size, who decides team
                members, what will you do if there is disagreement between you
                and faculty advisor, how you will convince him/her)
              </li>
              <li>Greatest achievement in life, biggest regret in life</li>
              <li>What are your expectations from Gainsight?</li>
              <li>
                What makes Gainsight different from others? (To answer this
                question company ppt was helpful)
              </li>
            </ol>
          </p>

          <p>
            <b>
              4.How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            In Technical Interview 1, the interviewer asked me to explain any
            one project (basic working of project, libraries, language used)
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <li style={{ marginLeft: "10px" }}>For DS</li>
            <ol type="1">
              <li>
                <a href="https://www.geeksforgeeks.org/must-do-coding-questions-for-companies-like-amazon-microsoft-adobe/">
                  Must do coding questions
                </a>
              </li>
              <li>
                <a href="https://www.geeksforgeeks.org/commonly-asked-data-structure-interview-questions-set-1/">
                  Commonly Asked DSA Questions
                </a>
              </li>
            </ol>
            <li style={{ marginLeft: "10px" }}>
              For OS: Sanchit Jain videos ( At least for Memory management
              concepts one should watch)
            </li>
            <li style={{ marginLeft: "10px" }}>
              For DB:{" "}
              <a href="https://beginnersbook.com/2015/04/dbms-tutorial/">
                DBMS Tutorial
              </a>{" "}
              (Try to explain concept with the help of an example)
            </li>
            <li style={{ marginLeft: "10px" }}>
              For Sql queries: <a href="https://sqlzoo.net/">SQL Queries</a>
            </li>
            <li style={{ marginLeft: "10px" }}>
              For Quantitative aptitude: R.S. Aggrawal ( solve 10 – 15 questions
              from each section)
            </li>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Practice easy, medium level coding questions from LeetCode,
            HackerRank platforms.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            Just be clear with CS fundamentals. Be calm and confident. It is ok
            if you are not able to answer some questions, just tell them basic
            idea/ answer which you know.
            <br />
          </p>

          <p>
            <i>
              Many times you will face rejections, but it’s important to keep
              trying and have patience.
              <br /> Best of Luck for Internship/ Placement!
            </i>
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:bawalerr17.it@coep.ac.in">
              bawalerr17.it@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default rupali_bawale;

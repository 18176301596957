import React from "react";

const atharva_mutsaddi = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Atharva Mutsaddi
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>D.E Shaw</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Online Aptitude Round
                                </li>
                                <li>
                                    Technical Interview Round 1
                                </li>
                                <li>
                                    Technical Interview Round 2
                                </li>
                                <li>
                                    HR Interview Round
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Coding Section: 1 question, 20 minutes
                                </li>
                                <li>
                                    Quants Section: 10 questions, 20 minutes
                                </li>
                                <li>
                                    Technical Section: 10 questions, 20 mins
                                </li>
                                <li>
                                    Systems Section: 5 questions , 15 minutes
                                </li>
                            </ol>
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were four rounds for the company: <br />
                        <ol>
                            <li>
                                Online Aptitude Round
                            </li>
                            <li>
                                Technical Interview Round 1
                            </li>
                            <li>
                                Technical Interview Round 2
                            </li>
                            <li>
                                HR Interview Round
                            </li>
                        </ol>
                        <br />
                        <b>Aptitude Round:</b> <br />
                        <b>Coding Section: We were asked the following question: </b> <br />
                        Given an array of N integers and an element ‘K’, we have to choose any contiguous subarray (of any size) and add any integer ‘M’ to all the elements of that subarray. If we can perform this operation at most once, what is the maximum frequency of the element ‘K’ we can achieve?
                        < br />
                        This round generally depends on how fast you can program a solution, and the main objective is to pass as many test cases as possible. So don’t be afraid of implementing a brute force method first if you can’t think of anything else.
                        <br />
                        <b>Quants Section: Questions were asked on the following topics:</b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                Age
                            </li>
                            <li>
                                Averages
                            </li>
                            <li>
                                Profit Loss
                            </li>
                            <li>
                                True Discount and Banker Discount
                            </li>
                            <li>
                                Partnership
                            </li>
                            <li>
                                Work and Time
                            </li>
                            <li>
                                Distances and Heights
                            </li>
                        </ul>
                        <br />
                        Questions were of moderate difficulty, but solving questions within the given time period is a challenge. Having sufficient practice and knowing shortcuts and formulae may help a lot with time management.
                        <br />
                        <b>Technical Section :</b> Basic level questions were asked on OS,CN, DBMS, OOPs, and DSA. Topics included:
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                Process and thread scheduling
                            </li>
                            <li>
                                SQL Select Query Question
                            </li>
                            <li>
                                Communication Protocols- (SMTP,FTP POP3, IMAP, etc.)
                            </li>
                            <li>
                                VPNs, IP Addressing, LAN/WAN/MAN
                            </li>
                            <li>
                                Data Structures - Queues, Stacks, and Circular Lists
                            </li>
                        </ul>
                        <br />
                        <b>Systems Section:</b> Questions were based on common knowledge of CS technologies.
                        <br />
                        <br />
                        <b>Technical Interview Round 1 : </b>
                        <br />
                        There were two interviewers for this round. The interview began with them asking me some questions about my extracurricular activities like powerlifting and debate (common icebreaker questions like “how long have I been participating”, “explain your achievements” , etc ).
                        <br />
                        The interviewers asked me about my favourite topic to debate, and I said <b>“Technology”.</b> We engaged in a semi-formal debate on topics like China's progress in the tech field and whether this is a boon or bane for the tech industry.
                        <br />
                        The interviewers and I had some conflicting opinions on this topic, so this debate lasted way longer than it should have (around 15-20 minutes).
                        <br />
                        Next, they moved onto my projects- specifically my DSA project: <b>The Spam Email Analyzer.</b> They asked me the following questions :
                        <br />
                        <ol>
                            <li>
                                <b>Why did you choose C for this problem statement?</b> Hint: Simply replying with “The college’s curriculum made C compulsory.”  is not a very good answer 😂. It’s important to read about the <b>advantages of C over other languages, and how C encourages us to be more careful while designing our own algorithms and data structures</b> while implementing principles of abstraction and encapsulation.
                            </li>
                            <li>
                                <b>What was your role in this group project ? </b> I talked about how I implemented various data structures in the project and the advantages of doing so. I would suggest reading about unique data structures that are commonly used in the industry and then finding research papers that can help you optimise them. This will pique the interest of the interviewer and lead them to ask you more questions about the implementation and working, so ensure you know the basic theory about the principles behind the functioning of the data structure.
                            </li>
                            <li>
                                <b>How do other companies implement the data structure we have used?</b>
                            </li>
                            <li>
                                <b>How will our project react to certain edge cases? </b>
                            </li>
                        </ol>
                        <br />
                        They also asked me some questions on core subjects related to my project such as:
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                Explain email security protocols and encryption algorithms.
                            </li>
                            <li>
                                Hashing principles
                            </li>
                            <li>
                                What are low level and high level programming languages, and differentiate between them.
                            </li>
                            <li>
                                What are compiled and interpreted languages?
                            </li>
                        </ul>
                        <br />
                        Even though core subjects were a weak point for me at the time, I was able to compensate by exhibiting my knowledge about current tech affairs and DSA. <b>Communicating your ideas effectively and precisely to the interviewers is also very important.</b>
                        <br />
                        <br />
                        <b>Professional Fitness/HR Round:</b>
                        <br />
                        Typical HR round questions included:
                        <br />
                        <ol>
                            <li>
                                Explain your journey in the field of Computer Science since you joined COEP. What was your approach to CS? Why did you choose CS?
                            </li>
                            <li>
                                How did you manage to have a pointer above 9.3 while also participating in competitions and extracurriculars? Why would you give priority to co-curricular activities over curricular ones?
                            </li>
                            <li>
                                What are your tech domains of interest? Why? How do you think D.E Shaw can help you reach your goals? Why do you feel you belong to D.E Shaw? What made you apply?
                            </li>
                        </ol>

                        Again, being confident and having good communication skills were helpful for this round. Attending the pre placement talk is important to be aware of the company’s objectives, work ethics, and goals.
                        <br />
                        I would say having a strong resume really helps. Many of the questions they asked were based on my achievements, extracurricular activities, and even my test scores (CGPA, MHT-CET, JEE, BITSAT), and I had a lot to say about these topics.
                        <br />
                        <br />
                        <b>Technical Interview Round 2 : </b>
                        <br />
                        This round also had two interviewers. They asked me to introduce myself and what I do.
                        Since I had a few positions of responsibility on my resume (like being the Tech Lead of CSI) I was asked a few questions about those (like “what exactly does your organisation do?” and “what is your role specifically?”)
                        <br />
                        <br />
                        One of the responsibilities that I had as a Tech Lead was crafting problem statements for competitions and events. So they asked me to design a DSA question that they would hypothetically use for the next year’s D.E. Shaw aptitude round. So I designed a question based on prefix hashing techniques and also explained brute force and optimised solutions for the question.
                        <br />
                        <br />
                        Since nobody (including myself)  was able to pass all the test cases of the D.E Shaw coding round, they gave me a second attempt at it. This time I was quite prepared with the solution and explained the brute force method and possible ways of optimising the solution. So make sure you think of the solution for the coding question after the aptitude round.
                        <br />
                        <br />
                        Next, some questions were asked about the other two projects on my resume. They were machine learning and data science based so they mainly just wanted to know my overall approach to the problem statements.
                        <br />
                        Based on my aptitude test responses, I was asked the following questions about core subjects:
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                VPN and its use cases for students
                            </li>
                            <li>
                                Public IP and Private IP
                            </li>
                            <li>
                                DNS and DHCP
                            </li>
                            <li>
                                LAN and WAN
                            </li>
                            <li>
                                Explain databases (NoSQL and SQL) and optimization techniques. How did you use SQL in your project?
                            </li>
                        </ul>
                        <br />
                        They also gave me a puzzle on hourglasses. I would suggest practising some commonly asked logical reasoning puzzles like Jug filling puzzles and hourglass problems.
                        <br />
                        Finally, they asked me some fun questions about my experience as a powerlifter (my max lifts too 😂)
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        <ol>
                            <li>
                                Aptitude Round- 15 students were selected
                            </li>
                            <li>
                                Technical Round 1 + Professional Fitness - 5 students
                            </li>
                            <li>
                                Technical Round 2 - 2 students
                            </li>

                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                Leetcode
                            </li>
                            <li>
                                GFG
                            </li>
                            <li>
                                Love Babbar for DBMS and OS
                            </li>
                            <li>
                                Striver Core Subjects sheet and Interview Bit for Core subjects questions
                            </li>

                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Consistency is key. Solve two problems every day. One thing I regret is that I didn’t enter many Code Chef contests. Even though Code Chef is more Competitive Programming oriented, it’s still a good place to practise time management for coding questions and thinking of multiple approaches.
                            <br />
                            Also, follow a good SDE sheet from day one. I would recommend Love Babbar’s or Apna College’s since they have a lot of questions for consistent practice.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            A lot. Extracurriculars and roles of responsibility are good icebreakers, and projects are good indicators of how you can identify and approach real world projects, so prepare them well.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Attempt to build a good resume and be thorough with your projects.
                                </li>
                                <li>
                                    Questions asked on core subjects are related to real world use cases and not just simple textbook theory. So read about applications of the core subjects well
                                </li>
                                <li>
                                    The coding question in the D.E. Shaw aptitude round is generally difficult due to having only 20 minutes to solve. So try to pass as many test cases as possible, even if it’s through brute force.
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Projects can be simple in terms of complexity if you have a good real world use case for them.
                                </li>
                                <li>
                                    Don’t be afraid of exploring new topics like AIML, blockchain, or Biotech for your project ideas. These topics are interesting and have a lot of scope for creating new and impressive projects
                                </li>
                                <li>
                                    A lot of other factors, like your communication skills, ability to stay calm under pressure and even your luck, also influence your chances of getting selected
                                </li>
                            </ol>
                            <br />
                            Keep calm, and do the hard work especially when you don’t feel like it 🙂
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:atharvaam21.comp@coeptech.ac.in">
                            atharvaam21.comp@coeptech.ac.in
                        </a>
                        <br />
                        LinkedIn : <a href="https://www.linkedin.com/in/atharva-mutsaddi-a59902228/">Atharva Mutsaddi</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default atharva_mutsaddi;

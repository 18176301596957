import React from "react";

const rachana_gade = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Rachana Gade</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>BNY Mellon</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>The entire process was divided into 3 rounds:</p>
            <ol>
              <li>Online Coding test</li>
              <li>Technical Interview Round</li>
              <li>HR Round</li>
            </ol>
            <b>Structure of Aptitude Round :</b>
            <br />
            The aptitude test had only coding questions. The test was conducted
            on Hackerrank platform. There were 4 problems given with difficulty
            as easy, medium, medium and hard. The duration of the test 90
            minutes.
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <p>
              In the morning we had a Pre-Placement talk where they briefed us
              about the company. 18 students were shortlisted from those who
              gave the test for the further process. The interviews for both
              interns and FTEs were scheduled throughout the day.
            </p>
            <p>
              There were 2 rounds after shortlisting students from the coding
              test:
            </p>
            <ol>
              <li>
                <b>Round I- Technical Interview:</b>
                <br />
                <p>
                  The first round was the technical interview. It lasted for
                  about 35-40 minutes. I was asked to explain the QuickSort
                  algorithm and write down the output of each iteration for a
                  sample unsorted array. Then he asked me to write down the code
                  for the same (syntax didn’t matter a lot). Continuing with
                  sorting he asked me about the concept of stability of an
                  algorithm, the time complexity and stability for various
                  sorting algorithms, and which one would be preferred when and
                  why?
                </p>
                <p>
                  Next I was asked about the Pillars of OOPS, the difference
                  between Object Oriented Programming and Procedural
                  Programming, and also which would be preferred in what
                  situation and why, and asked me to explain along with a
                  real-life example. He also asked me about some of the basic
                  SQL queries.
                </p>
                <p>
                  Then I was asked about the projects listed on my resume. He
                  mainly focused on the group project that I has listed, and was
                  interested in knowing my contribution in the project. He asked
                  me about the algorithms used and the basic flow of the project
                  code. He also asked me if I liked working in a group, and how
                  I would tackle a situation where my group members have
                  different views than that of mine.
                </p>
                <p>
                  I was then given 2 puzzles to solve, the first one where 4
                  people are trying to cross a bridge in the dark.
                  <br />
                  <a href="https://www.geeksforgeeks.org/puzzle-18-torch-and-bridge/">
                    Here is the link to that problem
                  </a>
                  <br />
                  The second puzzle was to find the number of triangles in the
                  given image.
                </p>
              </li>
              <li>
                <b>Round II- HR Interview:</b>
                <br />
                This round lasted for about 30 minutes. It was like a friendly
                talk with the interviewer. It started with a basic introduction
                of myself, and was asked questions like:
                <ul>
                  <li>
                    1.How was your technical interview and how has your day been
                    so far?
                  </li>
                  <li>
                    2.How do you keep yourself updated with the latest
                    technological advancements?
                  </li>
                  <li>
                    3. I was given a situation where there is a bug in my code
                    (or any similar situation) and I am not able to come up with
                    a solution even after trying to solve it for hours. He asked
                    me if I will give up on it, if yes, when, if not, then what
                    will I do instead?
                  </li>
                  <li>
                    4. I was asked about the projects and skills listed on my
                    resume in brief.
                  </li>
                  <li>
                    He then asked me if I had any questions for him. I asked him
                    about the expectations that the company has from its iterns
                    and also asked him about the coding languages and
                    technologies the company used.
                  </li>
                </ul>
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              <b>18 </b>
              students after the coding test, and <b>14</b> after the technical
              interview were shortlisted.
              <b>All 14 were selected for internship after the HR round.</b>
            </p>
          </p>
          <p>
            <b>
              4. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <p>
              I was mainly asked about the projects on my resume, and the
              algorithms used in them.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
                <li>
                  <b>For Coding: </b>
                  GFG, LeetCode and 375 DSA question sheet by{" "}
                  <a href="https://www.youtube.com/watch?v=u6Xsayqxij0">
                    ApnaCollege
                  </a>
                </li>
              <li>
                <b>For Aptitude:</b>{" "}
                <a href="https://www.indiabix.com/">IndiaBix</a>
              </li>
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Practice!! Keep solving problems from GFG/LeetCode, you dont have
              to solve a huge number of problems daily, just make sure you are
              consistent in what you do.
              <br />
              During the test even if your are not able to pass all the test
              cases for a particular question, try to pass a few of them for
              partial marks. Dont let that one question take up all your time.
            </p>
          </p>
          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company :
            </strong>
            <p>
              Review everything that you have put on your resume before going to
              the interview. Try to put only those skills and projects on your
              resume about which you have complete knowledge and those that you
              can explain when asked about in depth. Be confident during your
              interviews, and try not to randomly guess answers
            </p>
          </p>
          <p>
            <strong>8. Final Tip for Juniors :</strong>
            <p>
              This process of interviews and internships can be very stressful
              and frustrating. Whatever happens, don’t give up!! Even if you are
              not shortlisted after a particular round, don’t be disheartened,
              try to give your best in the next attempt. All the best!! :)
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID :{" "}
            <a href="mailto:gaderp20.comp@coep.ac.in">
              gaderp20.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default rachana_gade;

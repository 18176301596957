import React from "react";

const nachiket_deshmukh = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Nachiket Deshmukh
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Tata Consultancy Services - Research</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Resume Shortlisting
                                </li>
                                <li>
                                    Interview
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            No aptitude round was conducted. The T&P Cell shared the resumes of all eligible candidates with the company, and based on their research projects, the company shortlisted the students.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There was only one interview round. <br />
                        The interview was conducted over MS Teams and lasted for almost an hour.The interview started with me introducing myself.They then delved into my resume, focusing on the projects I had mentioned related to Data Structures and Algorithms (DSA), Database Management Systems (DBMS), and Web Development.I was asked to explain the logic behind each of my projects.Specifically, they asked me to convert my DBMS project (a Blood Bank Management System) into an Object-Oriented Programming System (OOPS) design.Similarly, they asked me to convert my DSA project (Pune Metro Travel using Dijkstra’s algorithm) into OOPS.They were keen on understanding my thinking process and logic.They asked about the
                        differences between BFS (Breadth-First Search) and DFS (Depth-First Search), including which is better in certain situations and which is more memory efficient.Questions about the four pillars of OOPS (Encapsulation, Abstraction, Inheritance, and Polymorphism) were asked.They asked some basic questions about programming languages.They also asked about DBMS concepts such as schema, ACID properties, and the differences between SQL and NoSQL databases.Since I had given a session on Linux in college, they asked questions about my experience with Linux.Questions were easy & included:Windows vs. Linux , Common Linux commands & basic shell scripts.At the end, they asked me a puzzle – the classic <a href="https://www.geeksforgeeks.org/puzzle-water-jug-problem/">water jug problem</a>.
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Total 13 students were interviewed and 3 were given the final offer.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Strivers A2Z Sheet <br />
                        Love Babbar CS Fundamentals - Youtube Videos <br />
                        GFG Practice Platform <br />
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Consistent practice is essential. Work on questions regularly and time yourself. Monitor your progress and revise questions as necessary.
                            <br />
                            <br />
                            GFG & Leetcode POTDs are the best way to keep yourself consistent in problem solving.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Half of the interview was based on projects done previously and changing DBMS project & dsa project into OOPs paradigm . Since I had given a session on Linux in college, they asked questions about my experience with Linux. Questions were easy & included : Windows vs. Linux , Common Linux commands & basic shell scripts.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            As the position was for a Research Intern, they were particularly interested in understanding the candidate's thought process and logic-building abilities.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Stay calm throughout the process and don’t let failures discourage you. It's important to seek support from friends and family during challenging times. In interviews , answer questions with confidence . Rejection can be tough, but maintaining a positive attitude and persevering until you secure an internship is crucial.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:deshmukhns21.comp@coeptech.ac.in">
                            deshmukhns21.comp@coeptech.ac.in
                        </a>
                        <br />

                        LinkedIn : <a href="https://www.linkedin.com/in/nachiket-deshmukh-a6881b196/">Nachiket Deshmukh</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default nachiket_deshmukh;

import React from "react";

const mayank_jain = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "1rem",
              position: "absolute",
              marginTop: "1rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Mayank Jain</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>NVIDIA</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ol type="a">
              <li>
                Aptitude Test of 1 hour for 25 questions on HackerRank on 9th
                October 2020.
              </li>
              <li>Technical Round</li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <ul style={{ marginLeft: "1rem", listStyleType: "disc" }}>
              <li>25 questions in total which were broken up as follows:</li>
              <ul style={{ marginLeft: "1.5rem", listStyleType: "circle" }}>
                <li>1 – 14: Multiple Choice Single Correct Questions</li>
                <ul style={{ marginLeft: "1.75rem", listStyleType: "square" }}>
                  <li>Moderate to high difficulty</li>
                  <li>Very tricky questions</li>
                  <li>
                    Based on C and a few (2-3 qs) theoretical concepts
                    pertaining to important subjects
                  </li>
                  <li>Needs thorough understanding of C</li>
                  <li>
                    Commonly asked: Pointer arithmetic, sizeof, function
                    pointers, pre and post increments, layout of a c program in
                    memory, etc.
                  </li>
                </ul>
                <li>15 – 16: Multiple Choice Multiple Correct Questions</li>
                <ul style={{ marginLeft: "1.75rem", listStyleType: "square" }}>
                  <li>Moderate to high difficulty</li>
                  <li>
                    Numerical based on Operating Systems (could vary from test
                    to test)
                  </li>
                </ul>
                <li>17 – 24: Multiple Choice Single Correct Questions</li>
                <ul
                  ul
                  style={{ marginLeft: "1.75rem", listStyleType: "square" }}
                >
                  <li>Quantitative Aptitude</li>
                  <li>Easy to moderate difficulty</li>
                  <li>
                    Based on common concepts available on GFG – Time and Work,
                    <br />
                    Probability, Boats and Streams, Permutation and Combination,
                    Simple and
                    <br />
                    Compound Interest, etc.
                  </li>
                </ul>
                <li>
                  25: Coding Question (3 languages allowed: C, C++, C++14)
                </li>
                <ul style={{ marginLeft: "1.75rem", listStyleType: "square" }}>
                  <li>Easy to moderate difficulty</li>
                  <li>Single coding question</li>
                  <li>
                    On GFG:{" "}
                    <a href="https://www.geeksforgeeks.org/count-ways-express-number-sum-consecutive-numbers/">
                      Count ways to express a number as sum of consecutive
                      numbers
                    </a>
                  </li>
                  <li>
                    (Same qs) on Leetcode:{" "}
                    <a href="https://leetcode.com/problems/consecutive-numbers-sum/">
                      Consecutive Numbers Sum
                    </a>
                  </li>
                </ul>
              </ul>
              <li>Partial credit was given</li>
              <li>
                Negative marking was applicable for multiple-choice questions
              </li>
            </ul>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <ul style={{ marginLeft: "1rem", listStyleType: "disc" }}>
              <li>
                Shortlisting for interviews was done on aptitude as well as
                resumes
              </li>
              <li>
                Technical round was conducted on 20th October 2020 for 1 hour
              </li>
              <li>
                It was conducted by a Senior Manager and a Systems Engineer from
                NVIDIA
              </li>
              <li>
                They asked me about the most interesting project I had worked
                upon. I talked about my project which I did as a part of
                coursework of Operating Systems. You can check it out here:{" "}
                <a href="https://github.com/mayank-02/multithreading-library">
                  Multithreading-Library
                </a>
              </li>
              <li>
                My project involves a LOT of concepts from OS. Explaining it
                took considerable amount of time. They also asked me about
                concepts in the course of explanation like context switch,
                threads, threading models, etc.
              </li>
              <li>
                Code the solution to number of common set bits given two numbers
                in C via screen sharing on{" "}
                <a href="https://codebunk.com/">Codebunk</a>
              </li>
              <li>How do computers handle interrupts?</li>
              <li>
                Experience on kernel level programming and my proficiency in C
              </li>
              <li>
                How does do user space programs communicate with kernel level
                space?{" "}
              </li>
              <li>
                How to add your own system call in the Linux kernel? How do you
                call it?
              </li>
              <li>Logical reasoning question</li>
              <li>Where is interrupt vector table located (RAM or ROM)?</li>
              <li>RAM vs ROM</li>
              <li>
                How would you do garbage collection in C? (implementing
                something similar to what other languages have but in C)
              </li>
              <li>I was mailed about my selection the very same day.</li>
            </ul>
          </p>

          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <ul style={{ marginLeft: "1rem", listStyleType: "disc" }}>
              <li>
                Common interview preparation material as recommended everywhere
              </li>
              <li>
                <a href="http://pages.cs.wisc.edu/~remzi/OSTEP/">
                  Operating Systems: Three Easy Pieces
                </a>
              </li>
            </ul>
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="jainmv17.comp@coep.ac.in">jainmv17.comp@coep.ac.in</a>
            <br />
            <a href="https://www.linkedin.com/in/mayank-v-jain/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default mayank_jain;

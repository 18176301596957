import React from "react";

const aniket_jayateerth = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Aniket Jayateerth
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Google</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />I had applied to Google earlier in August but got rejected for
            the SWE role. In the month of November, I received a mail from them
            to ask if I was interested in the Application Engineering role. The
            structure was straight forward:
            <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
              <li>Application review &amp; shortlisting</li>
              <li>2 x Hangouts interviews</li>
              <li>Hiring Committee Review.</li>
            </ul>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            For me, there wasn’t an aptitude test as such. It was probably
            because they contacted for the role. But if you are applying for the
            SWE role, there will be an online coding round. It consists of 1-2
            DSA questions and they are particularly challenging. One needs a lot
            of practice to be able to solve both.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            After filling a basic form, I was shortlisted for the interview (the
            criteria were not made public). Then I faced 2 rounds in total (held
            on Google Meets) followed by a Hiring Committee review.
            <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
              <li>
                Round 1 (DSA based): Following a brief introduction, the
                interviewer gave a coding question. I had to discuss various
                approaches to solve it and list pros and cons of each approach.
                Once the interviewer was satisfied with my approach, they asked
                me to code the solution. This round went on for around 1 hour.
              </li>
              <li>
                Round 2 (System Design): In this round, the interviewer gave me
                a simple problem statement and I had to design an entire system
                to solve the problem. System design interviews require a lot of
                basic knowledge from DSA, DBMS, OS, Networks, etc. This round
                too, went on for an hour.
              </li>
              <li>
                The third round wasn’t an interview, but your entire application
                is sent to a committee (along with your resume, feedback from
                previous interviews, etc.). The committee then decides whether
                you are a fit for the role.
              </li>
            </ul>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            None! In both of my interviews, I was not asked a single question on
            my resume or projects. But projects helped me in the System design
            interview. So, take all the projects seriously and don’t do them
            just for the sake of doing it. (For e.g. if you’re working on a
            machine learning project, don’t just work on Jupyter Notebooks and
            leave it there. Try to make a simple web-app to showcase the use of
            your model, etc.)
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            Stick to 1-2 resources at maximum or else you start getting
            confused. I personally used HackerRank and LeetCode. GFG is also a
            good resource, but you tend to get lost. Use GFG as a dictionary and
            open it whenever you are stuck. The most useful resource is{" "}
            <a href="https://www.youtube.com/watch?v=4iFALQ1ACdA">
              Love Babbar’s Final 450
            </a>
            . It is a set of 450 problems which I strongly advise to complete. I
            could not complete all of them, but the list is amazing.
            <br />
            For system design, I referred Gaurav Sen’s YouTube channel. But just
            make sure that you look at these at the last (or in your free time),
            because not many companies ask system design questions.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Practice. And start early. If you start in May, I’d advise to start
            with the{" "}
            <a href="https://www.crackingthecodinginterview.com/">CTCI book</a>.
            If you’re starting late, start with HackerRank, LeetCode and Final
            450 (mentioned above). If you genuinely complete these questions
            (without copying the solutions), it won’t be difficult to crack most
            coding questions.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            For Google, they usually start hiring for interns in August. Keep
            checking their careers page. During the interviews, “think out loud”
            because the interviewer will not know what you are thinking. Google
            provides a lot of preparation material / guides before interviews,
            so make sure you go through it.
            <br />
          </p>

          <br />
          <p>
            <i>
              It is important to have a cool attitude towards this whole
              process. Especially in on-campus recruitment. A lot of your
              friends will get selected before you and it generates a lot of
              pressure. Just keep faith in your preparation and knowledge. Keep
              talking to your parents, friends, etc. because all this might feel
              overwhelming. Talk to your seniors; it is likely that we have gone
              through the same stuff which you are going through.
            </i>
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:mailto:jayateerthaa18.comp@coep.ac.in">
              jayateerthaa18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default aniket_jayateerth;

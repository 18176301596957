import React from "react";

const samarth_kanwate = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Samarth Kanwate
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>mastercard</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The entire hiring process was conducted in 2 phases:                        </p>
                        <p>
                            <ol>
                                <li>Aptitude Round</li>
                                <li>Interviews</li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude round was conducted on Hirepro platform. The test consisted of 2 coding questions. The difficulty level of the first coding question was easy while it was medium-hard for the second question.

                            <br />
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Three interview rounds were conducted by the company. The first two were technical rounds and the third one was the HR round:
                        <ol>
                            <li>Technical Round-1: <br /> The tech round 1 lasted for around 45 minutes. It started off with a brief introduction and then the interviewer started asking questions based on the skills mentioned on my resume. I was asked a lot of questions about my projects, I was even asked to write a pseudo code for a few of the functionalities of my project and the algorithms I used, so it's very important that you know each and everything about your project. Also, make sure that you have a proper reason for choosing a particular tech stack or algorithm . The interviewer may also ask you about the scope, scalability, alternatives, and limitations of the projects you have implemented. Then I was asked a coding question on array: Remove all the duplicates in a given array. The question was pretty easy. I was asked to solve it first by brute force method and then optimize it. Then the interviewer asked a few questions on OOPS and some basic questions on HTML, CSS, and a few questions on DSA. The important thing here is not to mention those skills that you are unaware of or not good at, as in most of the interviews, questions are asked purely on the skills you have mentioned in your resume.</li>
                            <li>Technical Round 2: <br />This round lasted for around 35-40 minutes. First I was asked to introduce myself. Then the interviewer started asking questions on stages of software development, version control, and database systems, and some basic questions on Python. Then I was asked a few questions on OS which I was not able to answer. Then the interviewer asked me some questions such as which technologies I would like to work on, and what process/roadmap I follow for learning a framework/language. The primary aim of this round was to assess the professional fitness and willingness to learn. It is totally fine if you are not able to answer some questions but make sure you answer those questions confidently which you can answer.</li>
                            <li>HR Round:<br /> This round lasted for about half an hour. I was asked to introduce myself and give a brief description of where I live, where I completed my schooling, my family background, etc. I was then asked to explain about Mastercard, so make sure you are attentive in the PPT. I was asked about my qualities, regrets, and future goals. This was more of an interaction round to assess your personality and communication skills so it's important you put forth your points clearly and confidently. Prepare neatly for the most frequently asked questions such as introduce yourself, where do you see yourself in 5 years, why do you want to join our firm, why should we prefer you over other applicants, etc. Also, make sure you ask at least one question to the interviewer and maintain professionalism throughout the interview. </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        64 students were shortlisted after the aptitude test, ~33 after the technical round 1, ~25 after the technical round 2, and finally 13 students were given the final offer.

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                DSA: Love Babbar YouTube playlist, Striver Dynamic programming playlist, geeks for geeks, Leetcode                            </li>
                            <li>
                                OOPS, DBMS, CN, OS: geeks for geeks, tutorials point, interview bit. For DBMS, you can refer to the Love Babbar DBMS playlist on YouTube.                            </li>
                            <li>
                                Aptitude: Indiabix, Edugorilla
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            <ol>
                                <li>It's important to practice coding questions regularly on Leetcode, geeks for geeks, or coding ninjas.
                                </li>
                                <li>Set a timer for solving questions while practicing as it helps you in aptitude tests.
                                </li>
                                <li>Make sure you solve the geeks for geeks problem of the day every day.</li>
                            </ol>                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            I was asked a lot of questions about my projects in the first technical round. Surprisingly, I was not asked any questions about clubs and extracurricular activities in any of the rounds.  </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Practice quality coding questions, be well-versed with all the concepts of DSA and OOPS, and prepare short descriptions for your projects.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            I would want to remind you that the internship hiring process can sometimes be challenging and disheartening. However, I urge you to never lose hope. And remember that hard work never goes unnoticed or unrewarded. Stay committed, put in your best effort, and have faith in your abilities.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:samarthdk21.comp@coeptech.ac.in">
                            samarthdk21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default samarth_kanwate;

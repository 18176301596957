import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/Footer.css";

import {
  FaEnvelope,
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  
 
  
} from "react-icons/fa";




import { Typography, Grid } from "@material-ui/core";

class NewFooter extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "black", marginTop: "10vh" }}>
        
        <footer>
          <Grid
            container
            justify="center"
            direction="row"
            style={{ Height: "350px" }}
          >
            <Grid item md={5} sm={12}>
              <div
                style={{
                  marginTop: "1rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                <h2
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="about-heading"
                >
                  About Us
                </h2>
                <Typography
                  paragraph={true}
                  style={{
                    color: "grey",
                    marginTop: "1rem",
                    textAlign: "justify",
                  }}
                >
                  The Computer Society of India (CSI) Student Chapter of College
                  of Engineering Pune (COEP), established in October 2018 is an
                  active student organization which organizes a number of
                  technical activities including workshops, competitions,
                  technical symposiums, guest lectures etc. for its student
                  members. Under the guidance of Department of Computer Science and Engineering COEP, the student
                  chapter has over 300 members, and is run by a Core Team and
                  faculty from the department.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} md={3} sm={12}>
              <div
                style={{
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
              >
                <h2
                  style={{
                    color: "white",
                  }}
                >
                  Quick Links
                </h2>
                <Grid
                  container
                  direction="column"
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  <Grid item>
                    <Link style={{ color: "grey" }} to="/eventPage/2022-23">
                      Events
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link style={{ color: "grey" }} to="/recentsPage">
                      Recent news
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link style={{ color: "grey" }} to="/faqPage">
                      FAQ
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link style={{ color: "grey" }} to="/ty-diaries">
                      Diaries
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link style={{ color: "grey" }} to="/teamPage">
                      Team
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={6} md={3} sm={12}>
              <div
                style={{
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
              >
                <h2
                  style={{
                    color: "white",
                  }}
                >
                  Contact us
                </h2>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  // spacing={2}
                  className="social-links"
                >
                  <Grid
                    item
                    xs={12}
                    md={3}
                    lg={2}
                    key="facebook"
                    style={{ marginBottom: ".7rem" }}
                  >
                    <a
                      href="https://www.facebook.com/csicoep"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="rotate">
                        <FaFacebook size={36} />
                      </i>
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    lg={2}
                    key="linkedin"
                    style={{ marginBottom: ".7rem" }}
                  >
                    <a
                      href="https://in.linkedin.com/company/csi-coep-student-chapter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="rotate">
                        <FaLinkedin size={36} />
                      </i>
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    lg={2}
                    key="email"
                    style={{ marginBottom: ".7rem" }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:csi.comp@coeptech.ac.in"
                    >
                      <i className="rotate">
                        <FaEnvelope size={36} />
                      </i>
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    lg={2}
                    key="instagram"
                    style={{
                      marginBottom: ".7rem",
                    }}
                  >
                    <a
                      href="https://www.instagram.com/csi_coep/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="rotate">
                        <FaInstagram size={36} />
                      </i>
                    </a>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>

          <div style={{ flexDirection: "row", alignItems: "center" }}>
            <hr style={{ flex: 1, height: 0.5, backgroundColor: "#212F3C" }} />
          </div>

          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            style={{ backgroundColor: "black" }}
          >
            <Grid item xs={12}>
              <p style={{ color: "grey" }}>
                Copyright &copy;{" "}
                <a
                  style={{ color: "#4dc7f7", textDecoration: "none" }}
                  href="/"
                >
                  CSI COEP Tech
                </a>
              </p>
            </Grid>
          </Grid>
        </footer>
        
      </div>
    );
  }
}

export default NewFooter;

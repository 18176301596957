import React from "react";

const swebert_correa = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Swebert Correa
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <li>Application shortlisting</li>
            <li>
              Citi AMCAT (questions cannot be revisited, and available in a
              linear fashion)
            </li>
            <li>Technical Interview</li>
            <li>HR + Technical Interview</li>
            <li>Finally 10 students were selected</li>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            Citi opted for an <a href="https://www.myamcat.com/">AMCAT</a> test
            which is a standardised test.
            <br />
            The aptitude test consisted of some quatitative questions. Refer{" "}
            <a href="https://www.indiabix.com/">IndiaBix</a> for help. There
            were some questions related to Computer Engineering like DBMS, DSA,
            etc. which were simple. There was also a language section. Finally
            there were 2 coding questions to be done in C.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />I have ordered these rounds below according to my schedule.
            There were two interview rounds. The first was purely technical. I
            was asked about the workings of my projects, and one question based
            on DSA - <i>what is a stack?</i>
            <br /> The second round is a mix of HR and technical. The HR may
            present some scenarios like -{" "}
            <i>
              What would you do about your team project if your friend has an
              emergency
            </i>
            . Also checkout some brain teasers. The technical part was based on
            what additional functionalities could be added to some of my
            projects.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            All the questions asked to me during the technical rounds were based
            on the projects listed on my resume. But that is solely based on the
            interviewer you get. Still, try to do some worthwhile projects.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            GeekForGeeks, Hackerrank and Leetcode to refer the type of coding
            questions.
            <br />
            IndiaBix and GeekForGeeks for quatitative questions.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Start practicing questions from Hackerrank or Leetcode. Some quirks
            are developed by trying stuff out rather than reading about them.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            Make sure to know some basic historical facts about Citi Bank for
            the HR interview.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:correaswebert@gmail.com">correaswebert@gmail.com</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default swebert_correa;

import React from "react";

const akanksha_mahajan = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Akanksha Mahajan
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Garrett Motion
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            Garrett Motion is an automobile company. They generally come for
            Mechanical students, but this time, they came for the Comp and IT
            branch (B.tech + M.tech) as well. They offered Cyber Security and
            Cloud Infrastructure roles for 6-months and 2-months (summer)
            internships. Their internship process was different. To get selected
            for internship, there were two ways: Internship assessment and cyber
            security assessment. After selection in internship assessment, there
            were 2 or 3 interviews. I got selected through the cyber security
            assessment, it was followed by a 5 day workshop and a presentation
            at the end.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            There were two online MCQ tests 1 hour each with 15 mins break in
            between.
            <br />
            Internship assessment tests had data structure questions. I don’t
            remember if there were coding questions. But I think this would have
            been easily cracked with revising data structures.
            <br />
            Cyber security assessment focused on information security,
            cryptography, networking, operating systems and some computer
            organisation questions. It also included questions about open source
            tools like Wireshark, nmap, etc.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            Around 20 students (total) from COEP and other colleges (including
            3rd year, 4th year B.tech and final year M.tech) were selected to
            attend the cyber security workshop for consecutive 5 days. Workshop
            was spread across wide and general topics like Application security,
            Identity Management, Cloud security, Compliances, Defence in depth
            and much more exciting topics (exciting if you like cyber security
            :P).
            <br />
            To know more about the workshop:
            <br />
            <a
              href="https://www.garrettmotion.com/news/newsroom/article/expanding-students-horizons-for-it-cyber-
            security-talent-in-india/"
            >
              https://www.garrettmotion.com/news/newsroom/article/expanding-students-horizons-for-it-cyber-
              security-talent-in-india/
            </a>
            <br />
          </p>

          <p>
            <b>4. How many questions were asked based on the projects done?</b>{" "}
            <br />
            After 5 days of heavy knowledge workshop, we were given 3 unique
            situational problem statements to solve and present the solution for
            15 mins along with the panel Q & A.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            Participating in hackathons, attending workshops, my summer
            internship and my keen interest in cyber security are the sources
            which helped me nail the entire process.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Coding questions weren’t there. But it is better to know some
            general coding (and good coding practices) as this was one of the
            topics in the workshop.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            Have your fundamentals in information security and computer networks
            strong. You will be evaluated for your curiosity, attentiveness
            (Because the workshop is spanned for 5 days 9am-1pm), approach to
            solve real life problems and presentation after the assessment.
            <br />
            More about the presentation:
            <br />
            Three unique real life case scenarios were given. We had to choose
            any one and present it’s solution or at least how we would approach
            the situation. I’ll brief out the problem statements:
            <br />
            <ol type="a">
              <li>
                IT systems are not working and users are not able to connect to
                their systems. How would you handle this situation as a security
                analyst?
              </li>
              <li>
                Report existing vulnerabilities in critical applications (real
                time data sharing) developed in Java and db hosted on Oracle
                (some specific version was also mentioned).
              </li>
              <li>
                AWS access key leakage was found in the application source code.
                What steps will you take as a cloud security engineer and how
                will you perform secure management of AWS keys during
                development?
              </li>
            </ol>
            I opted for the 3rd problem statement. Time distribution: 10 minutes
            presentation, 5 mins panel Q&A on the presentation. They strictly
            followed the time (not a minute less/extra). My solution included
            assumptions, immediate response action, validating security breach
            and a list of secure management of software development processes to
            prevent AWS key leaks. There were around 10-15 panelists. In Q&A, I
            was asked about why I will do “xyz” that I had mentioned on slides.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:akankshasm17.it@coep.ac.in">
              akankshasm17.it@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default akanksha_mahajan;

import React from "react";

const soham_kumthekar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Soham Kumthekar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Corp</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            In total 39 students were selected for interview rounds after the online test.(for ICG tech portfolio)
                            <br />
                            Total 3 interview rounds were there:
                            <ol>
                                <li>
                                    Technical round
                                </li>
                                <li>
                                    Managerial round

                                </li>
                                <li>
                                    HR round
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        <ol>
                            <li>
                                After the online aptitude test 39 students were shortlisted for interview rounds

                            </li>
                            <li>
                                16 students were shortlisted after the first technical round.

                            </li>
                            <li>
                                After managerial round 12 students were selected for HR round

                            </li>
                            <li>
                                10 students received the final offer.
                            </li>

                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                Leetcode, Striver’s SDE sheet - for coding questions
                            </li>
                            <li>
                                InterviewBit - for aptitude questions

                            </li>
                            <li>
                                JavaPoint and GFG - for oops concepts in java.

                            </li>
                            <li>
                                I would also recommend to prepare some standard answers for the HR interview
                            </li>

                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practice questions daily and use one or two platforms only. Stick to one programming language you are confident in preferably C++ or Java.
                            <br />
                            Regularly revise your solved problems and important DSA concepts.
                            For topics like Backtracking and DP, solve questions from Striver’s SDE sheet.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            Questions about projects were asked in the first round (as stated above). In the HR round, Very few questions were asked about clubs and extracurricular activities.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Have a good knowledge of java, collections in java, study 4 pillars of oops in depth along with their real life examples and have a thorough understanding of the projects listed on your resume, including their core algorithms and applications.
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Be confident and optimistic while answering the questions and don’t panic if you do not know the answer to a question.
                                </li>
                                <li>
                                    This phase is very difficult and rejections can be demotivating, but take it as a learning experience. Try to analyse and improve on your mistakes.
                                </li>
                                <li>
                                    Research about the company you are applying for and prepare standard answers to the questions like ‘introduce yourself’ etc.                                </li>
                            </ol>
                            <br />
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:kumthekarss21.comp@coeptech.ac.in">
                            kumthekarss21.comp@coeptech.ac.in
                        </a>
                        <br />
                        LinkedIn : <a href="https://www.linkedin.com/in/soham-kumthekar-62033b255/">Soham Kumthekar</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default soham_kumthekar;

import React from "react";

const abhinav_kurule = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Abhinav Kurule
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            1st round was the online aptitude test. <br />
                            2nd round was the interview for the shortlisted students from online test.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The online aptitude consisted of 3 types of questions, English proficiency, business/finance-related questions and easy-level coding questions.

                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Initially, we were told that there would be three rounds : Aptitude round, technical interview and HR interview. <br />
                        Due to time constraints, our technical and HR interview rounds were combined. <br />
                        I was specifically asked a lot about MERN and Django, since that were the technologies used in my project. The interviewer further dug deep into the logic of various functionalities in my projects and repeatedly asked for better or different approaches to the functionalities and questioned the technologies used for it, which I feel I confidently answered. This lasted for about 45-50 minutes. In the end he gave me 2 puzzles and asked a few questions based upon my resume and other HR-related questions.


                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Around 150 students were shortlisted for the aptitude round.
                        20 students were selected for the interviews and 10 were selected finally for the internship.


                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Though not much DSA was asked, It is important to prepare for it. I referred to Striver (Take U Forward) for various DSA concepts and solved GFG, Leetcode along with some CC Contets. For CS fundamentals (OS, DBMS, CN, Aptitude) I referred to various youtube playlists, GFG articles and solved questions from websites like IndiaBix and InterviewBit.
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Even if you are able to solve Striver’s SDE sheet of 150 ques before the coding rounds, that should be more than enough. All questions are solvable, just don’t lose your cool.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            For me, almost 80% of the interview was based upon my projects. The interviewer also seemed impressed by my resume so I just played my cards right and presented my values and skills by highlighting my experiences, clubs, extracurricular activities and achievements.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Know your projects really well and should have a great knowledge of all the technologies used along with their logic. Keep your resume crisp and be pretty confident while answering any questions (without bluffing).
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Trust the process.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:abhinavk21.comp@coeptech.ac.in">
                            abhinavk21.comp@coeptech.ac.in
                        </a>
                        <br />

                        LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default abhinav_kurule;

import React from "react";

const abhay_gaikwad = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Abhay Gaikwad
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>BNY Mellon</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />
            British Petroleum had the criteria of 7.5+ CGPA and CS branch. The entire
            process was held online.
            The entire process was divided as follows:
            <ol>
              <li>Online Assessment</li>
              <li>Technical Round 1</li>
              <li>Technical Round 2</li>
              <li>HR Round</li>
            </ol>

          </p>

          <p>
            <b>
              2. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            <ol>
              <li>
                <b>Online Assessment</b>
                <p>
                  Four Coding Questions were to be done in 90 minutes. One Easy, Two Medium and one Hard Question.
                </p>
              </li>
              <li>
                <b>Technical Round 1</b> <br />
                <ul style={{ paddingLeft: "40px", listStyle: "disc" }}>
                  <li>
                    First technical round lasted about 70 mins
                  </li>
                  <li>
                    It began with an introduction, then he went through the Resume and asked which project I would like to discuss. I went with AlphaMax (Chess Engine). This discussion on the project went on for 35 minutes. He was very much interested in the project and asked a lot of Questions on Project.
                  </li>
                  <li>
                    After Project Discussion he gave 1 Coding Question.
                  </li>
                </ul>
                Q) Implement a stack which will support following operations in O(1) time complexity. <br />
                1.push() which adds an element to the top of stack. <br />
                2. pop() which removes an element from top of stack. <br />
                3.findMiddle() which will return middle element of the stack. <br />
                4. deleteMiddle() which will delete the middle element.
              </li>
              <li>
                <b>Technical Round 2</b> <br />
                <ul style={{ paddingLeft: "40px", listStyle: "disc" }}>
                  <li>
                    In the beginning, he gave a Coding question, it was based on Dijkstra’s Algorithm.
                  </li>
                  <li>
                    Then he asked questions on the internship project.
                  </li>
                  <li>
                    After that we discussed the Multithreading Library project. This followed by Questions on Synchronization, different types of locks. Questions on OS.
                  </li>
                  <li>
                    Then he asked Questions on DBMS and CN.
                  </li>
                  <li>
                    It was followed by a system design question.
                  </li>
                  <li>
                    At the end some behavioral questions were asked.
                  </li>
                </ul>
              </li>
              <li>
                <b>HR Round</b> <br />
                <ul style={{ paddingLeft: "40px", listStyle: "disc" }}>
                  <li>
                    Introduce yourself
                  </li>
                  <li>
                    About the interview process
                  </li>
                  <li>
                    Why do you want to join BNY?
                  </li>
                  <li>
                    What is your greatest achievement?
                  </li>
                  <li>
                    What is your biggest failure?
                  </li>
                  <li>
                    Hobbies and Interest.
                  </li>
                </ul>
              </li>
            </ol>
          </p>

          <p>
            <b>
              3. How many students were shortlisted after each round?
            </b>
            <br />
            <ul style={{ paddingLeft: "40px", listStyle: "disc" }}>
              <li>
                After Online Assessment 30 students went for the interviews.
              </li>
              <li>
                I don’t remember the exact count after TECH Interview 1.
              </li>
              <li>
                After TECH Interview 2, 13 students were shortlisted.
              </li>
              <li>
                All 13 were selected after the HR round.
              </li>
            </ul>

          </p>
          <p>
            <b>4. Some tips to crack the coding questions.</b> <br />
            <ul style={{ paddingLeft: "40px", listStyle: "disc" }}>
              <li>
              Practice DSA Questions daily. 
              </li>
              <li>
              Leetcode is a good platform that can be used.
              </li>
              <li>
              Striver’s DSA sheet is a must do.</li>
              <li>
                OS, DBMS series by CodeHelp.  </li>
            </ul>
          </p>

          <p>
            <b>
              5. How many questions were asked about projects, clubs and extracurricular
              activities in your resume?
            </b>
            <br />
            <p>Lot of Questions will be asked on Projects.
            </p>
          </p>

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email ID : <a href="mailto:gaikwadab7@gmail.com">
              gaikwadab7@gmail.com
            </a>
            <br />
            LinkedIn Handle : <a href="https://www.linkedin.com/in/abhay-gaikwad/">Abhay Gaikwad</a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};
export default abhay_gaikwad;

import React from "react";

const vedanshi_shah = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Vedanshi Shah
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>D.E Shaw</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
            There were two technical interviews and 1 HR interview. <br /> <br />
            Technical Interview 1 was based upon the projects mentioned in the resume. Question around the lines of why a particular choice of technology, database concurrency concepts and Object Oriented Programming concepts were tested. Also a SQL query question was given that involved a join of 4 tables. <br /> <br />
            Technical Interview 2 was based upon concepts of OS and CN. We started by talking about the basics of CN, from DNS to HTTPS, what happens when you search for a website on google etc. <br /> <br />
            We then moved onto concepts of OS, where questions regarding locks and threading were tested upon. We also had a discussion about a previous internship on my resume that mainly involved work in the domain of web scraping and machine learning. Then we moved onto linux fundamentals. Linux vs Windows, laptop configuration and comparison as to what is good. Then we moved onto OOP concepts and concluded the interview with any questions that I might have for them. <br /> <br />
            The HR round was based along the lines of why I like computer science and what I know about the company. We even discussed how I came up with my project ideas etc. <br /> <br />
            </p>

          </p>

          <p>
            <b>2. What was the structure of the aptitude round?</b>
            <br />
            <p>
            Consisted of 3 sections: Coding, Technical and Quant <br /> <br />
            Coding: 1 question | 20 minutes <br /> <br />
            Technical and coding together were for 30 minutes. <br /> <br />
            For the coding round, we had a simple question based on priority queues. It was to be solved using a greedy approach. Many students confused it for a dynamic programming question. <br /> <br />
            The question was along the lines of: <br /> <br />
            There were a set of candies, consisting of cost and their sweetness levels. The person likes a lot of sweets and has a fixed amount of money. He everytime picks up the sweet with the highest sweet level possible. Count the maximum number of sweetness levels he can achieve with the given amount of money. <br /> <br />
            The Technical section had basic MCQ concept based questions on CN, OS and DBMS. <br /> <br />
            The Quant section had questions based upon mathematical reasoning and pattern solving. <br /> <br />
            </p>
          </p>

          
          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            <p>
            After the aptitude test 15 students were shortlisted for the interviews. <br />
            After technical round 1 and HR round 4 students were shortlisted. <br />
            After technical round 2, two students were selected for the internship. <br />
            </p>
          </p>

          <p>
            <b>
              5. Some good resources you used in your internship prep
            </b>
            <br />
            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
              <li>450 DSA questions</li>
              <li>GFG for theory of OS, CN & DBMS</li>
              <li>Striver Dynamic Programming playlist</li>
              <li>Aditya Verma</li>
              <li>Leetcode</li>
            </ul>
          </p>

          <p>
            <strong>
              6. Some tips to crack coding questions
            </strong>
            <br />

            <p>
            Practice regularly along with proper revision of questions in order to detect patterns in similar questions. Track your progress and make a proper plan with regards to your preparation journey. Set daily goals and try to achieve them. Be positive and always believe in yourself!</p>
          </p>

          <p>
            <b>7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume</b> <br />
            <p>
            I was mainly asked questions about my projects in the first interview, about which I have detailed above. Regarding clubs and extracurriculars I wasn’t asked much. Just my experience so far as the president of CSI COEP Tech Student Chapter.</p>
          </p>

          <p>
            <b>8. How many questions were asked about projects, clubs and extracurricular
              activities in your resume</b> <br />
            <ol >
              <li>
              Know your projects well
              </li>
              <li>
              Keep your basics and fundamentals clear
              </li>
              <li>
              Understand OOP thoroughly
              </li>
              <li>
              Have a basic understanding about some important concepts of OS, CN & DBMS, although not taught yet
              </li>
            </ol>
          </p>

          <p>
            <strong>
              9. Final Tip for Juniors?
            </strong>
            <ol >
              <li>
              Do not panic and lose hope during your preparation phase
              </li>
              <li>
              Understand the concepts well before rushing to complex parts
              </li>
              <li>
              Have complete understanding of the projects that you put on your resume 
              </li>
              <li>
              Don’t compare yourself with others, as everyone’s journey is different and have faith in yourself.
              </li>
            </ol>
            All the best to all the juniors preparing for internships!
          </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID : <a href="mailto:vedanshis20.comp@coeptech.ac.in">
              vedanshis20.comp@coeptech.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default vedanshi_shah;

import React from "react";

const abhinav_roy = () => {
  return (
    <div>
      <section id="content">
        {/* <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a> */}
        <div className="container-fluid">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Abhinav Roy</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />
          <h5>THE APTITUDE TEST</h5>
          <p>The aptitude test consisted of :</p>
          <ul>
            <li>Quantitative</li>
            <li>Data structures</li>
            <li>basics of DBMS and CO</li>
            <li>3 programming questions</li>
            <li>2 essays</li>
          </ul>
          <p>
            The coding questions in aptitude were of different difficulties. One
            was of dynamic programming question, one of branch and bound and the
            advanced difficulty level question was of string manipulation where
            we had to build a multidimensional matrix of strings and print out
            offset of unique words and some other requirements. I don’t remember
            all requirements of the problem but this is the most basic version
            of what they asked us to do.
          </p>
        </div>
        <br />
        <h5>THE INTERVIEW ROUNDS</h5>
        <p>
          <strong>ROUND 1 - Technical</strong>
          <br />
        </p>
        <p>
          In this round, I was given problem statements related to Data
          structures and I was asked to come up with optimized algorithms and my
          own approaches to solve the problems.
        </p>
        <p>
          <strong>ROUND 2 - Technical</strong>
          <br />
        </p>
        <p>
          In this round, I was given multiple theoretical and numerical puzzles
          and other logical problems where I was either asked to optimize the
          algorithms that they proposed to solve the puzzles or I had to come up
          with my own approach and make modifications based on the changing
          requirements given by them so that the final algorithm covers most
          cases correctly.
        </p>
        <p>
          <strong>ROUND 3 - Technical and HR</strong>
          <br />
          <p>
            The final interview round was mostly Technical and a little bit HR.
            For other people, it was 2 technical rounds and 1 HR round. In this
            round, I was asked questions of personal response. I was asked to
            come up with my own version of data structure that will support most
            operations (like insertion, deletion, search, sort, concatenation)
            in minimal time and I was specifically asked not to copy an existing
            data structure.
          </p>
          <br />
          <p>
            <strong>
              Q. How many questions were asked based on the projects and on
              resume?
            </strong>
            <br />
            <p>
              The final round was focused more on what I had written in my
              Resume and my projects. They asked me about the challenges I faced
              in my projects and asked questions based on implementation of my
              project.
            </p>
            <p>
              I was also asked to write algorithms in my first and last round of
              interview. In one of the rounds, I was asked to write a generic
              code to implement a stack using two queues and when I did it, I
              was asked to modify the code to implement stack with a single
              queue such that it takes minimal time complexity in its respective
              operations.
            </p>
          </p>
          <br />

          <p>
            <strong>
              Q. What are some good resources used by you in your preparation?
            </strong>
            <br />
            <p>
              I used GeeksforGeeks to brush up my data structure skills. Many
              online websites have quantitative resources. Anyone would be fine.
            </p>
          </p>
          <br />

          <p>
            <strong>
              Q. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
            <br />
            <p>
              For the people trying for the same company, remember the rule of
              thumb is not knowing everything but being good at what you know
              already. So don’t chase after learning multiple things at the same
              time.
            </p>
          </p>
          <br />

          <p>
            <strong>* Some tips to crack the coding questions.</strong>
            <br />
            <p>
              Take your time while answering questions, don’t rush into things.
              Brush up data structure skills and carefully go through your
              project and identify which modules could be optimized and how.
            </p>
          </p>
          <br />
          <p>
            Feel free to ask me queries and discuss any topic in detail. All the
            best!
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15em" }}>
            <a href="mailto:royaa18.it@coep.ac.in">royaa18.it@coep.ac.in</a>
          </div>
        </p>
      </section>
    </div>
  );
};

export default abhinav_roy;

import React from "react";

const siya_dhokane = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
          Siya Dhokane
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
          Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
            The internship hiring process was in 2 phases:
<p>Aptitude test(17th August) and 3 interview rounds(19th August)</p>

            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
            The Aptitude test was conducted on HackerRank platform with 5 sections.
            <ol>
                <li><strong>Coding Section(30 minutes):</strong> 2 questions with Easy difficulty level.</li>
                <li><strong>Quantitative section(25 minutes):</strong> 8 questions with medium difficulty and also had negative marking.</li>
                <li><strong>CS fundamentals(20 minutes):</strong> 7 questions and also had negative marking.</li>
                <li><strong>Advance coding Section(45 minutes):</strong>  1 question with difficulty medium to hard. Question was on snakes and ladder.</li>
                <li><strong>Essay writing(15 minutes):</strong> 2 questions
                <p>
                    Questions: 
                    <ul style={{listStyleType:"disc",marginLeft:"2rem"}}>
                        <li>Describe a time when your excitement and passion about the project motivated you to achieve outstanding results on the project.
(Same question was asked to me during HR interview)

</li>
<li>You and your classmates have been working hard on a challenging project. You are on track to complete the project on time but there is still a lot of work to finish. Your classmate has a personal situation that requires him to stop working on the project. What would you do in this situation?
</li>
                    
                    </ul>
                </p>

                </li>
    

            </ol>
            26 candidates were shortlisted after the aptitude test and finally 7 were selected.

             
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <br />
            <p>
            <strong>Round 1 -Technical Interview(40 minutes):</strong>
            <ul>
           <li>It started with the interviewer asking me to give my introduction. 
</li>
           <li>They asked me questions on my project and other approach which I could have used for my project
</li>
           <li>They asked questions on data structures, which is better array or linked list and difference between them, how many queues required to implement stack and many more.
</li>
           <li>The interviewer gave me two problem statements for live coding. First I explained my approach and was further asked to write the code for the same.
</li>
</ul>
</p>
<p>Q1<span>&#41;</span> An array contains integers from 122 to 200 (unsorted array). One of the integers is missing in the array. Find the missing integer.
Followed by Is there any other approach and can you reduce the time complexity 
</p>
<p>
Q2<span>&#41;</span> Rounding off a number to the nearest multiple of 10 later asked me to modify the same code to round the number to the nearest multiple of 5.
Before coding they asked me to explain my approach on the whiteboard.

</p>
<p>They further asked me more questions on dynamic programming.
</p>
<p>
<strong>Round 2 -Technical Interview(40 minutes):</strong>
<p>He started with my introduction and asked me questions on my resume on the projects and courses which I had mentioned.
</p>
After that he gave me 3 coding questions:
<ol>
  <li>Write a function to get the <a href="https://www.geeksforgeeks.org/write-a-function-to-get-the-intersection-point-of-two-linked-lists/">intersection point of two Linked Lists</a>
<p>I was not able to give the complete code but explained my approach to him.</p>
</li>
<li>Function to find the mirror image of a tree.</li>
<li> Bit manipulation: <a href="https://www.geeksforgeeks.org/swap-all-odd-and-even-bits/">Swap all odd and even bits</a>
</li>
</ol>
<p>
  <ul>
    <li>He further asked me questions on OOPs concepts like Static and dynamic polymorphism, Run time and compile time, Method overloading is runtime or compile time and few more.
</li>
<li>Finally, the interview ended with the interviewer asking me if I had any questions for him.
</li>
  </ul>
</p>

</p>
<p>
<strong>Round 3 -Technical+HR Interview(35 minutes):</strong>
<li>

First of all, an introduction and then why you want to be at GS.

</li>
<li>He asked me about a challenging situation in a group project and how you cope up with that. More focus was on Team spirit.
</li>
<li>From how many years you are coding.
</li>
<li>What motivates you to learn new things?
</li>
<li>Which data structure you liked and why(he also said please don’t give answers based on what you read in books).

</li>
<li>Difference between C and python (again don't tell me bookish answer)

</li>
<li>He then asked me about what NSS was about and what I did as a volunteer(which I had mentioned in my resume).
</li>
<br />
The round ended with me, asking a few questions to the interviewer.

</p>



</p>

          

          <p>
            <b>4. How many questions were asked based on the projects done?</b>
            <br />
          <p>
          Many questions were asked on projects , they covered everything from my resume.
 
</p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            
           <p>I solved problems on HackerRank’s Interview Preparation Kit and also revised DSA concepts from GFG.


</p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> 
            <p>
           <ul>
            <li>Practice as many questions as you can as this will increase your thinking and speed.
</li>
<li>At least try to write basic code which clears some test cases and further think about corner cases and modify your code.

</li>
           </ul>


            </p>
          </p>
          <p>
            <b>7. Is there something specific that you would want to tell people who try for the same company?
</b>
<p>
  <ul>
    <li>
    During interviews, keep explaining your thought process and do not simply sit and code as their main objective is to check your problem-solving skills.

    </li>
    <li>If you are not able to solve the question at least tell your approach.
</li>
<li>Stay calm, confident and Be Honest.
</li>
  </ul>
</p>
          </p>

         
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:dhokaness19.comp@coep.ac.in">
            dhokaness19.comp@coep.ac.in
            </a>
          
          </div>
        </div>
      </section>
    </div>
  );
};
export default siya_dhokane;

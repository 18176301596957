import React from "react";

const anagha_mahure = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Anagha Mahure
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Hilti Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            Firstly shortlisted resumes were asked to attend the PPT of company followed by online aptitude test. Out of these 20 students were shortlisted for interview. First round was technical and then HR round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Aptitude round consists of 30 aptitude questions, coding section, and questions on other core subjects such as DSA, Computer Networks, DBMS, Operating System.


                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were total of three rounds. First Online aptitude test then technical interview and lastly HR interview. Technical interview was mostly around the projects I had worked on, DSA, puzzle solving and brainstorming. They basically see your approach towards real life problem and problem solving skills. In HR interview, they will ask you questions about your extracurricular activities and about your interests.
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After Online test 20 students were shortlisted for interview round. All 20 students appeared for technical as well as HR round. Out of these 4 were selected for the final internship offer.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Leetcode, Hackerrank and Striver’s SDE Sheet for DSA practice, Geeks for Geeks for DBMS and other core subjects. W3Schools and TutorialsPoint are some of the sites for reference for theory.


                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practice as many questions as on Leetcode. Cover all types of questions and focus on Array, Trees, linked list.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            They ask you to explain about your project in detail. HR round is all about clubs and extracurricular.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Have complete knowledge about your project and if any improvements that  can be there.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Be confident about all you have prepared and keep practising and exploring core subjects even if it has not yet covered in college.
                            Do not stress much and keep preparing everyday.
                            Give your best!!!

                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:anaghamahure@gmail.com">
                            anaghamahure@gmail.com
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default anagha_mahure;

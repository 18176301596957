import React from "react";

const aniket_warhade = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Aniket Bhagwan Warhade
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Societe Generale</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire hiring process?</b>
            <br />
            Interview process consisted of a pre-placement talk followed by written
            test and 3 interviews round. (1 Tech + 1 Managerial + 1 HR Round).

          </p>

          <p>
            <b>
              2. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            SocGen conducted one Aptitude test and three interview rounds for placement. Each was an elimination round <br />
            1. Written Test <br />
            2. Technical Interview <br />
            3. Managerial Interview <br />
            4. HR
            <ol>
              <li>
                <b>Aptitude Test :-</b>
                <p>
                  The aptitude test was conducted on HirePro. It was divided into 4
                  sections: Quantitative Aptitude , General Computer Science , English, and Coding Questions.There were total 37 questions .
                  <ul style={{ paddingLeft: '40px', listStyle: 'disc' }}>
                    <li>
                      35 questions on Aptitude, English, Reasoning, output prediction
                    </li>
                    <li>
                      2 coding questions.
                    </li>
                  </ul>
                </p>
                Out of 180 students, 55 were shortlisted for technical interview
              </li> <br />
              <li>
                <b>Technical Interview (50-60 mins) :-</b>
                <p>This round was focused on Data
                  Structures and Algorithms. After a brief introduction about myself he went on to ask questions about the topics that I had mentioned in my resume. Interviewer asked me to write the code for <br />
                  <a href="https://leetcode.com/problems/rotate-array/">1. Given an array, rotate the array to the right by k steps, where k is non-negative.</a>
                  <br /> <a href="https://www.geeksforgeeks.org/find-the-missing-number/">2. Find the Missing Number .</a>
                </p>
                I solved both the questions using 2 different approaches and interviewer was satisfied with the solution. Then he asked me about different OOPs concepts like virtual functions, inheritance, polymorphism and its real-life examples, etc. After that the discussion moved towards RDBMS. He asked me to write some sql queries consisting of nesting and joins . This was followed by a few questions on normalisation. <br />
                Interview ended with the discussion on puzzles that interviewer asked me. One of them was <a href="https://www.geeksforgeeks.org/puzzle-100-people-in-a-circle-with-gun-puzzle/">100 people in a circle with gun</a> . Most of the puzzles asked in the interviews can be found on gfg.
              </li> <br />
              <li> 
                <b>Managerial Interview(25-30 mins) :-</b>
                <p>
                  After introducing himself, Interviewer gave me a chance to introduce myself and asked me about the things I hadn’t mentioned on the resume. He then asked me to explain my projects and cross-questioned about the logic that I had used. <br />
                  Then he discussed about my extracurricular activities which I mentioned on my resume. He loved the fact that I was active playing sports and had worked in various positions of responsibility. He asked me about my experience as the member of the athletics team. <br />
                  In the end, Interviewer asked few questions based on SQL and DBMS and to test my reasoning ability he asked me a few logical reasoning questions.
                </p>
              </li>
              <li>
                <b>HR Interview (15 mins) :</b>
                <p>
                  Following questions were asked in HR round:
                  <ul style={{ paddingLeft: "40px", listStyle: 'disc' }}>
                    <li>
                      Introduce yourself, and Explain one of the best projects of yours.
                    </li>
                    <li>
                      Tell me about internship and work you did.
                    </li>
                    <li>
                      Why Societe generale? Why should we select you?
                    </li>
                    <li>
                      What location will you prefer? Any problems in relocations?
                    </li>
                    <li>
                      What will you do if you get some better opportunities than Societe generale?
                    </li>
                  </ul>
                </p>
              </li>

            </ol>
          </p>

          <p>
            <b>
              3. Some good resources you used in your Placement prep
            </b>
            <br />
            <ul style={{ paddingLeft: "40px", listStyle: "disc" }}>
              <li>
                For DSA, Leetcode is a good resource to practice a variety of problems
              </li>
              <li>
                I also used Love Babbar’s 450 questions on DSA to solve selected problems from it.
              </li>
              <li>
                For Core CS Subject like OS, DBMS, CN, OOPS, I used GeeksForGeeks and Love Babbar’s youtube playlist.
              </li>
              <li>
                I prepared for aptitude using Indiabix.
              </li>
            </ul>
          </p>
          <p>
            <b>4. Some tips to crack the coding questions.</b> <br />
            First of all, be good at any one language. Most people use CPP and switch to
            Python whenever relevant. This will make sure you can easily convert an algorithm into code and implementation will not be an issue. Now the challenging part is devising an algorithm. For this, I would suggest you solve as many standard problems as possible and also their variations. In aptitude tests, these standardproblems are rephrased with some long scary stories. Try to reduce it to the underlying standard problem, which you can easily beat.

            <br />
          </p>

          <p>
            <b>
              5. Is there something specific that you would want to tell people who try for the
              same company?
            </b>
            <br />
            <p>SocGen focuses highly on your development skills, and you will be questioned about the very basics of any technical skills you mention on your resume. Interviews will also be focused on the OOPS and Databases. At least one question in interview will be on dbms so make your dbms concepts clear before appearing for the interview. Make sure that your resume is precise and mention only things on which you can talk. Try to be as precise with your answer as possible.
            </p>
          </p>
          <p>
            <b>
              Final Tip for Juniors
            </b>
            <p>
              The whole placement process is very hectic, and a lot of luck is involved in the process. Don't be demotivated if you don't get selected for any company and be sure to stay positive throughout. Keep talking to your family and friends and trust your own preparation. All the best!
            </p>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email ID : <a href="mailto:warhadeab19.comp@coep.ac.in">
              warhadeab19.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default aniket_warhade;

import React from "react";

const shubham_gupta = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Shubham Sachin Gupta
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>British Petroleum Global Business Solutions Pvt. Ltd.</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There was only one interview round ( Technical + HR). <br />
                            The interview was for about 45 mins wherein they focused more on projects and innovative thinking in difficult scenarios.For HR which was like for the last 15 mins they asked more about bp and why the candidate would like to join bp. <br />
                            For the interview round I would suggest going through the bp website and learn more about their “IOC to IEC” quote because it's quite trending in bp and everyone looks up to it seriously and also I was asked about any one sorting algorithm for the coding part which was pretty easy.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude round was online and it was not proctored but instead the college authorities told to give the exam in front of college authorities in Cognizant Lab. < br />
                            There were 2 sections in Aptitude Round.First one was fast and quick problem solving wherein it had mathematical problems,scenario problems and knowledge or general aptitude based questions.Second section was the coding section wherein it had 2 coding questions and the coding questions were pretty easy.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        1st Round - Aptitude Online Exam : There was an online exam on bp platform which we had to give from college campus only in front of supervision. <br />
                        2nd Round - Interview Round : The interviews were taken the very next day of the aptitude exam at the Main Building. <br />
                        The results were declared right after the Interview Round on the same day.
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After the Aptitude Round around 12-13 students were shortlisted for interviews. <br />
                        After the Interview Round 3 students were selected for internship.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Mostly went through the basics of DSA from Youtube and similar resources.
                        Most important resource would be your project details. They are more interested in projects and would ask good questions revolving around your project.
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Coding questions were pretty easy in interviews and in aptitude so I think solving Leetcode Problems on a regular basis would be more than enough for cracking coding questions.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            I was the Vice-Secretary at i2i and it is a social-entrepreneurship club so they were keenly interested in social-entrepreneurship activities that were undertaken in our club,rest they didn't ask much about other clubs or fests.
                            For projects they asked a lot of questions,they asked about the logic and approach used in projects and also asked about the DSA used in projects.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Do mention about the “IOC to IEC” quote during the interview,it would create a positive impact.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Stay Calm and Be Honest.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        LinkedIn : <a href="https://www.linkedin.com/in/shubham-gupta-87550222b">Shubham Gupta</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default shubham_gupta;

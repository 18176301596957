import React from "react";

const harshmohan_kulkarni = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Harshmohan Kulkarni
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            {" "}
            JP Morgan Chase & Co.
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>
              1. What was the Application Procedure for off-campus internship?
            </b>
            <p>
              I became aware of the off-campus internship opportunity through a college
              email in March 2022. The details of the application procedure were provided
              in the email.
            </p>
          </p>
          <p>
            <b>
              2. What was the structure of the entire process for qualifying for the
              internship?
            </b>
            <p>
              <ol>
                <li>
                  <b>
                    Aptitude Exam
                  </b>
                  <p>
                    The hiring process commenced with a preliminary aptitude examination,
                    featuring two coding questions for 60 minutes. The problems primarily
                    revolved around arrays, strings, and some advanced concepts like bitwise
                    operations. While the overall difficulty level ranged from easy to medium, the
                    key was solving them swiftly and accurately.
                  </p>
                  <p>
                    Good number of students appeared for this from our college and 7 got
                    selected for next round.
                  </p>
                </li>
                <li>
                  <b>
                    HR Interview
                  </b>
                  <p>
                    Following the initial assessment, a HR interview was conducted on the
                    HireVue Platform after few days. The platform required candidates to record a
                    two-minute video interview in response to two HR-related questions.
                    Presenting oneself with confidence is essential during this phase. In case of
                    initial recording hesitations, the platform allows for a second attempt to
                    ensure a better presentation.
                  </p>
                  <p>
                    Upon successfully navigating both the preliminary aptitude test and the HR
                    interview, 3 candidates were shortlisted for the subsequent Code for Good
                    Hackathon.
                  </p>
                </li>
                <li>
                  <b>
                    Code For Good Hackathon
                  </b>
                  <p>
                    "Code for Good," an engaging 24-hour online hackathon, took place in June.
                    The event commenced with participants being organized into teams of 6 to 7
                    individuals, forming an eclectic mix of talents. Three days prior to the
                    hackathon kick-off, team compositions were shared, and Slack invitations
                    were exchanged, serving as the platform for team communication and
                    interaction. Additionally, mentors, who happened to be JPMC employees,
                    were assigned to each team, facilitating guidance and support throughout the
                    hackathon journey.
                  </p>
                  <p>
                    At 7:00 AM on the day of the hackathon, the eagerly awaited problem
                    statements were unveiled. After an hour of intensive team discussions and
                    mentoring sessions, where preferences were debated and suggestions were
                    explored, a clear order of problem statement preferences was decided upon.
                    With a total of 10 problem statements to choose from, the teams were geared
                    up and brimming with anticipation for the challenge.
                  </p>
                  <p>
                    Before the clock struck 10 AM, marking the official start of the hackathon, the
                    problem statements were in the hands of the participants. For the next 24
                    hours, teams passionately brainstormed and tirelessly pieced together their
                    prototype solutions. Amidst this journey, two interviews were conducted,
                    evaluating not only technical prowess but also communication and leadership
                    abilities. These interviews served as pivotal points for assessing the
                    participants' potential, offering them opportunities to display their
                    comprehensive skills.
                  </p>
                  <p>
                    In this hackathon environment, collaboration was key. Participants were
                    encouraged to voice their thoughts, interact with mentors, and contribute to
                    ideation freely. While sharing insights and suggesting solutions, maintaining a
                    respectful and considerate demeanor was crucial. Active listening and a
                    willingness to consider all perspectives were valued traits during this
                    hackathon experience.
                  </p>
                </li>
              </ol>
            </p>
          </p>
          <p>
            <b>
              3. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <p>
              During the interview sessions within the hackathon, questions were asked on
              the projects done by me. However, the major emphasis of questions was
              placed on my personal contributions and involvements throughout the course
              of the hackathon.
            </p>
          </p>
          <p>
            <b>
              4.What are some good resources used by you in your preparation?
            </b>
            <p>
              For preparation you can refer 450 DSA sheet and some previous year Code
              For Good questions present on GFG.Thorough preparation of fundamental
              coding questions is advisable
            </p>
          </p>
          <p>
            <b>
              Final Tip for Juniors :
            </b>
            <p>
              Embrace every opportunity to learn, experiment, and collaborate. Be open to
              challenges, as they're stepping stones to growth. Don't shy away from
              seeking guidance and networking with mentors and peers
            </p>
          </p>
          <h3>Contact Info:</h3>
            <div style={{ color: "gray", fontSize: "1.15rem" }}>
              <br />
              Email-ID : <a href="mailto:kulkarnihs20.comp@coeptech.ac.in">
              kulkarnihs20.comp@coeptech.ac.in
              </a>
              <br />
              LinkedIn : <a href="https://www.linkedin.com/in/harshmohan-kulkarni-9710ab20a">Harshmohan Kulkarni</a>
              <br />
            </div>
        </div>
      </section>
    </div>
  );
};

export default harshmohan_kulkarni;

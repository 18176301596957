import React from "react";

const muskan_paryani = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Muskan Paryani
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Barclays</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>
              1. What was the structure of the entire internship hiring process?
            </b>
            <br />
            There were two rounds. The first round was an aptitude test followed
            by a technical + HR interview for the selected students.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test mostly consisted of MCQs based on Java(Predict the
            output type), DBMS(mostly on MongoDB), and Cloud Computing. A lot of
            them were theoretical but the test was pretty difficult to solve in
            the given amount of time.
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            There was only one interview round for us due to time constraints
            so, it was technical+ HR. After my introduction, the interviewer
            asked me to explain my DSA project(file functions library) followed
            by a good number of questions about the same. Then, he asked me some
            questions on other projects mentioned in my resume(some based in the
            libraries and modules used). He also asked some basic questions on
            python and DSA and then concluded the interview with HR-type
            questions(something based on teamwork).
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            As you can see, most of the questions were based on my resume. It
            really decides the direction of your interview. So, just be thorough
            about each and everything you mention. It is something that you can
            prepare beforehand so just make sure you don’t mess this up.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <ol>
              <li>Aptitude Test: RS Agrawal, GFG practice questions</li>
              <li>
                Technical Interview: Crack The Coding Interview, GFG(go through
                puzzle questions as well) Along with that make sure to go
                through the CSI internship diaries for that particular company
                as well to get an overview of the process.
              </li>
            </ol>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Read the questions carefully and don’t just start to write code.
            Generally, the time allotted for the coding questions is really less
            so, the first step should be to find the corner cases and code for
            them and pass some test cases then you can go ahead to write the
            complete solution.
          </p>

          <p>
            <br />
            <i>
              The process is going to be hectic both physically and emotionally,
              just take everything with a pinch of salt. Be patient and
              positive!
            </i>
            <br />
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:paryanimd18.comp@coep.ac.in">
              {window.screen.width < 360
                ? "Email"
                : "paryanimd18.comp@coep.ac.in"}
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default muskan_paryani;

import React from "react";

const mayur_k = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Mayur Mallikarjun Kongutte
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>BNY Mellon</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              The recruitment process for BNY Mellon was divided into three rounds –
              <ol style={{ paddingLeft: "40px" }}>
                <li>
                  Aptitude Test (27th August 2022)
                </li>
                <li>
                  Technical Interview (29th August 2022)
                </li>
                <li>
                  HR Interview (29th August 2022)
                </li>
              </ol>
            </p>

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              <ul style={{ paddingLeft: "40px" }}>
                <li>
                  330+ students appeared for the aptitude test out of which 18 students were shortlisted for the technical interview. 14 students were selected for the final offer.
                </li>
                <li>
                  The Aptitude test was conducted on the HackerRank platform in online mode. The test consisted of only 4 Coding questions for 90 minutes.
                </li>
                <li>
                  The difficulty of coding questions was easy to hard level.
                </li>
              </ul>
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b><br />
            <ol style={{ paddingLeft: "40px" }}>
              <li>
                Aptitude test:
                <ul style={{ paddingLeft: "20px", listStyle: "disc" }}>
                  <li>
                    Question 1 (Easy): There are n candidates where each candidate has skill, skill[i] and we have to form n/2 teams where total skill of each team should be the same. And efficiency is denoted as product of skill levels of two team members. And we need to return total sum of efficiency of all teams.
                  </li>
                  <li>
                    Question 2 (Medium): Given an array of integers and two integers k,x.we were asked to find number of pairs i,j such that {'i/<j and arr[i]<arr[j] '}and there are exactly k integers in range arr[i] to arr[j] exactly divisible by x.
                  </li>
                  <li>
                    Question 3 (Medium): Given two array of strings , you have to return an array of type bool if it is possible to convert ith string of first array to that of second array. Operations allowed were <br /> 1] To swap two adjacent characters <br /> 2] Fix an integer k and increment the value of characters in a substring of length k by 1.

                  </li>
                  <li>
                    Question 4 (Hard): Given a Matrix of size M*N and integer k we were asked to find the size of the largest submatrix whose sum of elements is less than or equal to k.
                  </li>
                </ul>
              </li>
              I could pass all test cases of easy question,1 medium and 1 hard were partially correct.
              <li>
                Technical Interview: <br />
                It was in offline mode conducted at the placement cell.
                <ul style={{ paddingLeft: "20px", listStyle: "disc" }}>
                  <li>
                    Firstly I was asked to introduce myself. Then few questions were asked on my resume. In one of my project I used MongoDB so she asked me why I had used a non-relational database and why not a relational database. Based on my project I was able to give the answer.
                  </li>
                  <li>
                    Then she gave me a coding question The coding question was pretty easy, It was to find whether two linked lists merge forming a Y structure or merge forming a X structure, or do not merge at all. <br />
                    Initially, I gave a brute force approach using two for loops, I was asked to optimize it, then I gave an optimized approach to it. While writing you should always tell what you are thinking.
                  </li>
                  <li>
                    Next, I was asked questions based on OOPS. questions were based on Inheritance and the access modifiers, what happens when a class is inherited using a private or public method.
                  </li>
                  <li>
                    Then I was asked to differentiate between function overloading and function overriding, difference between compile time and run time polymorphism.
                  </li>
                  <li>
                    She then gave me few code snippets based on classes and asked me to predict the output.
                  </li>
                  <li>
                    At the end, she switched to DBMS and I was asked to write a SQL query. I could easily write a query and then she asked few basic concepts like JOIN, ORDER BY, and LIMIT.
                  </li>
                </ul>
                So Be Sure you are well prepared with OOPS and DBMS.
              </li>
              <li>
              HR Round: <br />
              <p style={{paddingLeft:"40px"}}>It lasted for around 10-15 minutes. I was asked some general HR questions. Then he asked me to explain Blockchain Technology, and any leadership experiences I have.</p>
              </li>
          </ol>
        </p>

        <p>
          <b>
            4. How many students were shortlisted after each round?
          </b>
          <br />
          <p>
          At the end of the Technical Round, 15 were shortlisted for the HR round, and then after HR Round 14 were given offer.
          </p>
        </p>

        <p>
          <b>
            5. Some good resources you used in your internship prep
          </b>
          <br />
          <ul style={{paddingLeft:"40px"}}>
            <li>Aptitude: I used Indiabix to practice questions on selected topics.</li>
            <li>
            For Core CS: Before going to the interview look at the SDE sheet on GFG, notes provided by Apna college/Love Babbar youtube channel
            </li>
            <li>
            Coding: I used to practice on leetcode, codechef. There are many other platforms like GFG , HackerRank.
            </li>
          </ul>
        </p>

        <p>
          <b>
            6. Some tips to crack coding questions
          </b>
          <br />

          <ul style={{paddingLeft:"40px", listStyle:"disc"}}>
            <li>
            Practicing questions by giving contests on leetcode,codechef will help you to improve your speed. So be consistent in giving contests.
            </li>
            <li>
            Regarding the coding round, If you cannot think of an optimized solution try to write a brute force approach and pass as many test cases as you can.
            </li>
            </ul>
        </p>

        <p>
          <b>7. How many questions were asked about projects, clubs and extracurricular
            activities in your resume</b> <br />
          <p>
          In the technical Interview, Initially I was asked questions about projects I mentioned in my resume . the questions were asked for 5-10 minutes. So be sure you are confident with projects you mention in your resume and why you have used a particular tech stack for creating that project.
          </p>
        </p>
        <p>
            <b>
              8. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
            As the Aptitude test had only coding questions. Try practicing more questions so that you can easily think of edge cases and solve them in given time.
            </p>
          </p>
        <b>
          9. Final Tip for Juniors?
        </b>
        <p>
          This phase is very difficult, especially when you keep getting
          rejected. Just try to stay calm, talk to your friends, and family, and keep working.
          Keep realistic targets, don't overburden yourself, and please don't compare
          yourself with others as everyone has a different journey and story.
        </p>
        <br />



        <h3>Contact Info:</h3>
        <div style={{ color: "gray", fontSize: "1.15rem" }}>
          <br />
          Email-ID : <a href="mailto:konguttemm20.comp@coep.ac.in">
            konguttemm20.comp@coep.ac.in
          </a>
          <br />
              LinkedIn : <a href="https://www.linkedin.com/in/mayur-kongutte-6850511ba/">Mayur Kongutte</a>
              <br />
          <br />
        </div>
    </div>
      </section >
    </div >
  );
};

export default mayur_k;

import React from "react";

const jayesh = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
           Jayesh Gadilohar
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
         Barclays
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
            Only computer students were allowed.

            </p>
            <p>The internship hiring process was in 2 phases:
</p>
          
            <ol>
              <li>
                <p>Firstly, there was an aptitude round. 15 students were shortlisted after aptitude test.

</p>
              </li>
              <li>
                <p>In second phase there was only one interview round, 9 students got the offer.
</p>
              </li>

            </ol>
         
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              <ol>
                
                 
                <li>
                  <p>
                  The aptitude test was conducted on HackerEarth. It had 30 MCQs and 2 programming questions. The MCQs were technical and were mostly related to the OOPS concept and DBMS (mostly on MongoDB). 

                  </p>
                </li>
                <li>
                  <p>
                  There were 2 coding questions which were of 50 and 20 marks respectively. The second coding question worth 20 marks was quite easy.

                  </p>
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
           
          <p>There was only one interview round (technical + HR round). 
</p>
         <p>I was asked to describe myself a little.</p>
         <p>After my introduction, the interviewer asked me to explain my project and asked about on which project I am currently working on followed by some good questions about the same.
</p>
<p>She asked me some questions on other projects mentioned in my resume.
</p>
<p>She mostly asked about what’s the basic idea of project, which technology and library I have used.
</p>
<p>She also asked some technical question which were mostly related to Data structure and DBMS.
</p>
<p>At last, she also asked some HR type question.
</p>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <p>
            
            Most of the questions were based on my resume. It really decides the direction of your interview. So, just be thorough about each and everything you mention.  The technical questions were mostly on the projects.

            
            </p>
         
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
            <p>
            I solved coding questions on Hackerrank and GeeksForGeeks.


            </p>
            <p>
            I used GeeksforGeeks for brushing up data structures and algorithms, also improving OOPs concept.

           

            </p>
          
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
            Try to break down it into small problems.



            </p>
            <p>Try to write a basic code first which can clear some test cases and then try to check for corner cases.</p>
          </p>
<br/>
       <p>
           <i>
           Analyse your mistakes and rectify them and understand what to say and what not to say in the interview. The process is going to be hectic. It is important to note that luck also plays a bigger role in this. It may happen that you answer most of the questions in an interview correctly, solve the coding questions and still not qualify for the next round. Staying mentally strong and patient is really important in this period.


           </i>
       </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:gadiloharjb19.comp@coep.ac.in ">
            gadiloharjb19.comp@coep.ac.in

            </a>
            
            
          </div>
        </div>
      </section>
    </div>
  );
};

export default jayesh;

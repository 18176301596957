import React from "react";

const vasvi_gupta = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Vasvi Gupta</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Mastercard</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            First round was an aptitude test which only had 2 coding questions
            of 100 marks each.
            <br />
            Second round was the Interview round which was conducted via Zoom.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <i>
              The first question asked was on the topic knapsack which had to be
              solved using Dynamic Programming for all the test cases to pass:
            </i>
            <br />
            <i>
              <br />A rice seller has n kg of rice. There is price list for n Kg
              and less than n Kg of rice. For example, let’s say a seller has 8
              Kg of rice and price list is as follows:
            </i>
            <br />
            <br />
            <table style={{ width: "40%" }}>
              <tr>
                <th>Weight(Kg)</th>
                <th>Price(Rs.)</th>
              </tr>
              <tr>
                <td>1</td>
                <td>1</td>
              </tr>
              <tr>
                <td>2</td>
                <td>5</td>
              </tr>
              <tr>
                <td>3</td>
                <td>8</td>
              </tr>
              <tr>
                <td>4</td>
                <td>9</td>
              </tr>
              <tr>
                <td>5</td>
                <td>10</td>
              </tr>
              <tr>
                <td>6</td>
                <td>17</td>
              </tr>
              <tr>
                <td>7</td>
                <td>17</td>
              </tr>
              <tr>
                <td>8</td>
                <td>20</td>
              </tr>
            </table>
            <br />
            <i>
              Now the question is how he should sell the n Kg of rice so that he
              gets the maximum benefit. You have to reveal the maximum benefit.
              <br />
              <br />
              For the above example, if he sells the 8 Kg as whole he would earn
              Rs. 20, if he sells 1 Kg to 8 different people he would earn
              8*1=Rs. 8. But if he sells it as 2 Kg and 6 Kg. He would earn
              5+17=22 which is the maximum he can get in the above example.
              <br />
              <br />
              So you are given n Kg and price list of 1 to n Kg and you have to
              answer what is the maximum benefit the seller can get by any
              partition of n Kg.
            </i>
            <br />
            <br />
            The second question was based on arrays:
            <br />
            <br />
            <i>
              In India, there is a famous festival called Makar Sankranti. It is
              a tradition to fly kites during this festival. Monti’s school has
              organized a kite-making competition on this occasion. Total N
              students are participating in this competition.
              <br />
              <br />
              To make a single kite, we need 2 sticks. You are given an array
              A[] where A[i] represents the number of sticks the i<sup>
                th
              </sup>{" "}
              student has. Can you find the total number of kites all the
              students will make? Don’t worry about paper, each student has an
              infinite supply of paper.
              <br />
              <br />
              Also, students cannot borrow sticks from other students.
            </i>
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            There were only 2 rounds for MasterCard, Round 1 was aptitude and
            Round 2 was technical + HR interview.
            <br />
            <br />
            <b>
              <i>Round 1 : </i>
            </b>
            The first question asked was on the topic knapsack which had to be
            solved using Dynamic Programming for all the test cases to pass. The
            second question was based on arrays which was relatively very easy.
            <br />
            <br />
            <b>
              <i>Round 2 : </i>
            </b>
            Second round was a technical + HR interview which was taken by 2
            interviewers via a Zoom meeting. Most of the question were based on
            my resume.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            Most of the questions were based on my resume. The technical
            questions asked were on OOPs, linked lists, arrays. Since I had been
            a part of the web team of PSF’20, they asked me which technologies
            did you use while making the website and some questions on CSS. Then
            they asked me to explain any one of my projects, so I asked them if
            I could share my screen and show it to them and they said sure why
            not! Hence I explained my Room Slot Booking System project to them.
            Then the next question they asked me was - What are your
            responsibilities being a Design Head? Then they asked me - What
            difficulties do you face while interacting with people? In the end,
            they asked me why do you want to intern at MasterCard?
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            HackerRank for coding; India Bix for quant; Geeks For Geeks for
            Dynamic Programming, coding and previous year company interview
            questions.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Solve a lot of questions on HackerRank. Start practicing dynamic
            programming from Geeks for Geeks. <br />
            <br />
            For the interview round, be very confident in what you are saying.
            Do not say anything just for the sake of saying it because you will
            be asked follow-up questions on it.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            For MasterCard, they ask you to show them your best work, so you
            should have at least one project about which you are extremely
            thorough.
            <br />
          </p>
          <br />
          <i>
            I would like to point out that luck plays a big role in getting an
            internship. It was really stressful when some of the ‘undeserving’
            students got the internship in a particular company and I did not
            since they were asked easier questions than me. So every time I got
            rejected, I pushed myself harder to get there. Talking to my family,
            friends and seniors helped a lot at that point. I analyzed my
            mistakes and rectified them and understood what to say and what not
            to say in the interview.
          </i>
          <br />
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:guptav18.comp@coep.ac.in">
              guptav18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default vasvi_gupta;

import React, { Component } from "react";
import {
  Event as EventIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import "../assets/css/card_new.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaLinkedin, FaInstagram } from "react-icons/fa";

import { Card, CardContent, Typography, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { inspiron } from "../assets/js/event";

class NewCard extends Component {
  state = {
    active: 1,
    size: 3,
    width: 1200,
    // size: 3 on large and size: 1 on small,
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
    let newSize = this.state.width <= 972 ? 1 : 3;
    this.setState({ size: newSize });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  moveRight = (length) => {
    var newActive = this.state.active;
    newActive = (newActive + 1) % length;
    this.setState({ active: newActive });
    // console.log("Width: ", this.state.width);
    // console.log("Height: ", this.state.height);
  };

  moveLeft = (length) => {
    var items = [];
    var newActive = this.state.active;
    newActive--;
    this.setState({
      active: newActive < 0 ? length - 1 : newActive,
    });
    items.push(newActive, newActive + 1, newActive + 2);
  };

  generateItems = (details, active, size) => {
    let items = [...details]; //making a copy of the details array
    let displayItems = []; // declaring the array to be returned

    var startItem = active - 1;
    if (startItem < 0) {
      startItem = items.length - 1;
    }

    if (startItem + size <= items.length - 1) {
      displayItems = items.splice(startItem, size);
    } else {
      var i, j, count;
      for (i = startItem; i < items.length; i++) {
        displayItems.push(items[i]);
      }
      count = displayItems.length;
      for (j = 0; j < size - count; j++) {
        displayItems.push(items[j]);
      }
    }
    return displayItems;
  };

  onDownload = (linkName) => {
    let a = document.createElement("a");
    a.href = `./assets/content/${linkName}`;
    a.setAttribute("download", linkName);
    a.click();
  };

  render() {
    const {
      title,
      details,
      date,
      downloadables,
      length,
      linkedin,
      instagram,
      viewmore,
     
    } = this.props;

    const { active, size } = this.state;
    const displayItems = this.generateItems(details, active, size);
    const linkUrl = title === "Inspiron 2.0" ? "/Inspiron2.0viewmore" : "/Codequestviewmore";
    return (
      <div className="container">
        <Card style={{ backgroundColor: "#9d9fff1a", color: "white" }}>
          {/* CardHeader */}
          <CardContent>
            <Typography variant="h4" align="center">
              {title !== "Inspiron 2.0" ? (
                title
              ) : (
                <a
                  style={{ color: "#fff" }}
                  href="https://csi.coep.org.in/hackathon-23/"
                >
                  {title}
                </a>
              )}
               
            </Typography>
          </CardContent>
          

          {/* Custom Carousel */}
          <CardContent style={{ padding: "0 16px" }}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid
                container
                xs={1}
                className="my-3 p-0"
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Button
                  onClick={() => this.moveLeft(length)}
                  style={{
                    borderRadius: "30px",
                    padding: "0",
                    minWidth: "32px",
                    color: "white",
                  }}
                >
                  <ChevronLeftIcon fontSize="large" />
                </Button>
              </Grid>

              <Grid xs={10} item className="my-3">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  {displayItems.map((e, i) => {
                    return (
                      <Grid
                        item
                        sm={12}
                        md={e.item === active ? 6 : 3}
                        className={e.item === active ? "py-0" : "mt-2"}
                        key={`diplay-image-wrapper-${i}`}
                      >
                        <Card
                          id={e.item === active ? "FocusCard" : "picCards"}
                          style={{ padding: "8px" }}
                        >
                          {/* {console.log("e->item", e.image)} */}
                          <LazyLoadImage
                            effect="blur"
                            src={require("../assets/img/gallery/" + e.image)}
                            alt={e.description}
                            key={`display-image-${i}`}
                            style={
                              e.item === active
                                ? {
                                    objectFit: "cover",
                                    width: "450px",
                                    height: "234px",
                                    objectPosition: "center",
                                  }
                                : {
                                    objectFit: "cover",
                                    width: "345px",
                                    height: "130px",
                                  }
                            }
                            height={e.item === active ? "234" : "auto"}
                          />
                          {/* <CardMedia
                            component="img"
                            // className={classes.media}
                            // height={e.item === active ? "100%" : ""}
                            style={{ height: "100%" }}
                            image={require("../assets/img/gallery/" + e.image)}
                          /> */}
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid
                xs={1}
                container
                className="my-3 p-0"
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Button
                  onClick={() => this.moveRight(length)}
                  style={{
                    borderRadius: "30px",
                    padding: "0",
                    minWidth: "32px",
                    color: "white",
                  }}
                >
                  <ChevronRightIcon fontSize="large" />
                </Button>
              </Grid>
            </Grid>
          </CardContent>

          {/* This section will contain the description */}
          <CardContent>
            {displayItems.map((e, i) => {
              return (
                <Typography
                  key={`display-description-${i}`}
                  variant="h5"
                  align="center"
                  // style={{ fontFamily: "Gotham" }}
                >
                  {e.description && e.item === active ? e.description : ""}
                </Typography>
              );
            })}
          </CardContent>

          <CardContent>
            <Grid container justify="space-between">
              <Typography
                display="block"
                variant="h6"
                // style={{ fontFamily: "Gotham" }}
              >
                <EventIcon /> {date}
              </Typography>
              {/* {console.log(downloadables)} */}
             
                <Typography
                  display="block"
                  variant="h6"
                  // style={{ fontFamily: "Gotham" }}
                >
                   {downloadables ? (
                 <>
                 Downloadables:{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      padding: "1px 4px 1px 4px",
                      // fontFamily: "Gotham",
                    }}
                    onClick={() => this.onDownload(downloadables)}
                    // href={downloadables}
                    // download="brochure"
                  >
                    Event Brochure
                  </Button> 
                  </>)
              
              : (
                ""
              )}
              <div style={{marginTop:"9px"}}>
              {linkedin ? (
               <>
                 <div style={{ marginTop: "5px",display:"inline" ,marginRight:"30px" }} >
                 <i>
                        <FaLinkedin size={25} />
                      </i>:{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      padding: "1px 4px 1px 4px",
                      // fontFamily: "Gotham",
                    }}
                    onClick={()=>window.open(linkedin)}
                    // href={downloadables}
                    // download="brochure"
                  >
                   Link
                    
                  </Button>
                  </div>
                </>

              ) : (
                ""
              )}


                {instagram ? (
               <>
                 <div style={{ marginTop: "5px",display:"inline" }} >
                 <i>
                        <FaInstagram size={25} />
                      </i>:{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      padding: "1px 4px 1px 4px",
                      // fontFamily: "Gotham",
                    }}
                    onClick={()=>window.open(instagram)}
                    // href={downloadables}
                    // download="brochure"
                  >
                 Link
                    
                  </Button>
                  </div>
                </>

              ) : (
                ""
              )}
               {viewmore ? (
                    <div style={{paddingTop:"10px"}}>
                      View More:{" "}
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          padding: "1px 4px 1px 4px",
                          // fontFamily: "Gotham",
                        }}
                        // onClick={() => this.onDownload(downloadables)}
                      >
                        <Link
                          to={linkUrl}
                          style={{
                            textDecoration: "none",
                            color: "white",
                          }}
                          
                        >
                          View More
                        </Link>
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}

              </div>
                </Typography>

              
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}
export default NewCard;

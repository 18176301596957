import React from "react";

const ninad_barve = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Ninad Barve
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              After applying on Superset, there was an Aptitude+Coding Online exam. 10 students were shortlisted for the Interview Process. It was an Online Interview with two rounds.
            </p>
          </p>
          <p>
            <b>2. Structure of Aptitude Round :</b>
            <br />
            <p>
              The online test was taken on AMCAT platform. <br />
              Aptitude round had 3 sections. Each section was separately timed.
            </p>
            <ol>
              <li>Section 1:</li>
              <p>
                This was an English Proficiency test. It had MCQ based questions on similar words, fill in the blanks(1 or 2 blanks) questions.
              </p>
              <li>Section 2:</li> <p>
                his was a Business Analysis section. There were questions based on Graph reading (Bar chart, Histogram and Candlestick). All students had a company stock candlestick chart based question, where questions regarding the opening/closing prices were asked.
              </p>
              <li>Section 3:</li><p>
                This was a coding section with two questions. Everyone had different questions.
              </p>
              <p>
                Q1) An airplane seat has tablets for passengers to see movies. It is expected that the movies are shown in increasing order of their ratings. But due to some bugs, the movies’ order is random. Find the maximum difference in index formovie having a lower rating from the current movie.(An array with ratings is given, find max(i-j) such that {'a[i] > a[j], i < j)'}
                <br />
                Q2) Street lights have some bugs due to which today’s status is determined by tomorrow’s status. The light is OFF today if yesterday the neighboring two street lights were both ON or OFF. Today’s status is given; find status after k days.
              </p>
              The editor was a bit laggy. It caused the test to get logged out twice during the coding test. However, the timer stopped if you were logged out or there was a lag on the website.
            </ol>
          </p>
          <p>
            <b>
              3. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b> <br />
            There were two interview rounds after the test for the shortlisted students.
            <ol>
              <li>
                First round was a completely theory based round. I was asked questions regarding DSA(DFS vs BFS, Stack(LIFO) vs Queue(FIFO), minimum spanning tree), DBMS(NoSQL used in), OOP concepts. I was also asked general questions regarding steps for software development. Then i was asked to explain any one project which I have done till now.
              </li>
              <li>
                Second round was a Technical+HR round. I was asked HR questions like “Why do you want to join Wells Fargo? What do you expect from this internship?”. Then I was asked about the previous round, who was the interviewer, what topics did she asked on? I was asked to list all the projects that I have done. I was asked to explain in detail the core algorithm for a DSA-II project.              </li>
            </ol>
            Both interviewers asked me to introduce myself before starting the interview. Prepare a clear and concise anser for this question which will list all the technical interests and strengths, also mention about any other interests you might have
          </p>
          <p>
            <b>4. How many students were shortlisted after each round?</b>
            <p>
              Total 133 students were shortlisted for the online test across E-group branches. From the test, 10 students were selected for the Interview process first round. 4 students were selected for the HR round. All the 4 were given the final offer.
            </p>
          </p>
          <p>
            <b>
              5. How many questions were asked about projects, clubs and
              extracurricular activities in your resume 
            </b>
            <br />
            <p>
            I was asked to explain two of my projects in the interviews. No questions on extra-curricular activities were asked.
            </p>
          </p>

          <p>
            <b>
              6. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
            To prepare the coding questions, I was solving questions on Leetcode and Hackerrank.
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
            Business analysis section has bar charts. Study how to read candlestick charts.
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID:
            <a href="mailto: barvenm20.comp@coep.ac.in">
              barvenm20.comp@coep.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ninad_barve;

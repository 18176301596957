import React from "react";

const shirshak_tiple = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Shirshak Kailash Tiple
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Tata Research Design and Development Center (TRDDC) / TCS Research</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The selection process consisted of two rounds : <br />
                            <ol>
                                <li>
                                    Resume shortlisting
                                </li>
                                <li>
                                    Interview Round
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            There was no aptitude round for this company
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <b>First Round:</b> The resume was shortlisted based on the type of project you have done. The best thing you can do for this round is highlight the projects which are related to the Data Science domain as TRDDC focuses primarily on this domain.
                        <br />
                        <br />
                        <b>Second Round:</b> Second round was conducted online and it was an interview round. The majority of questions revolved around projects in the artificial intelligence and machine learning (AIML) field. Additionally, two puzzles were presented, along with a single data structures and algorithms (DSA) question. Expect inquiries primarily drawn from your resume, so ensure you are thoroughly familiar with every aspect of it.

                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Total 13 students were shortlisted for the interview round and eventually 3 were given the offer.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                LeetCode, Interview Bit, GFG for DSA
                            </li>
                            <li>
                                DBMS and OS playlist by Love Babbar
                            </li>
                            <li>
                                OOP playlist by Abdul Bari
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Try to solve the standard questions from coding platforms like LeetCode, GFG or Interview Bit. Try to solve questions on a topic which the company frequently asks, so you get acquainted with the topic.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            The emphasis during the interview was predominantly on projects, with minimal inquiries regarding clubs. However, I did receive questions about my hobbies.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Individuals aspiring to apply to this company should possess a strong understanding of the field of Data Science and be well-versed in related concepts. Internships at TRDDC or TCS Research are not solely Software Development Engineer (SDE) intern roles; instead, they are Research intern positions. If your goal involves publishing research papers in the Data Science field, these internships provide an excellent environment for achieving that.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Stay strong during the internship process. Keep looking for opportunities off-campus and stay calm.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:tiplesk21.comp@coeptech.ac.in">
                            tiplesk21.comp@coeptech.ac.in
                        </a>
                        <br />
                    </div>
                </div>
            </section >
        </div >
    );
};

export default shirshak_tiple;

import React from "react";

const atharva_phand = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Atharva Phand
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Credit Suisse
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            Aptitude Test {"->"} Technical Interview {"->"} HR Interview
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            Around 25 MCQ’s on Data Structures, Algorithms, OOPS, DBMS,
            Quantitative Aptitude and 1 coding question.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <i>Technical Interview </i>
            <br /> Lasted around 50 minutes. The interview started with my
            introduction and then I was asked what I did during lockdown, my
            view on lockdown, whether it was the right decision? Next I was
            given 2 maths puzzles followed by a DBMS Normalization question,
            Bubble Sort, Merge Sort, 4 differences between Python and C and 2-3
            questions on OOPS (Abstract, Interface class, don’t remember
            others). Since my ILOE was Economics, I was asked why I chose that
            subject and what my favourite subject was? To which I replied Maths
            and I was asked to explain any recently studied topic (Markov’s
            inequality). I hope everything’s pretty much covered.
            <br />
            <br />
            <i>HR Interview </i> <br /> Lasted around 20 minutes. Asked me to
            introduce myself, my family members. Journey in COEP till now
            (Mention extra curriculars). (Typical) Elaborate one situation where
            you thought that nothing can be done now, its over; but then you
            bounced back strongly from it and the end result was good. Asked
            about PAN, Passport, License (Here, I started to believe I was in!).
            Then asked if I had any questions for him.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            No questions on projects. The interviewer just noticed my projects
            mentioned in the resume, I had put up some description there.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            GeeksForGeeks for technical concepts, HackerRank for coding,
            Indiabix for quant.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b>
            <br />
            Practice is the key. At least write a code which passes some test
            cases if not all, becomes easy to optimize further.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            Technical Interview is your ticket to Credit Suisse if you crack the
            Aptitude test first. HR doesn’t matter that much! So prepare well
            for the interview. Don’t try to learn new things. Keep it simple.
          </p>
          <p>
            <i>
              Being a TnP coordinator, I was in a close contact with the
              students who used to have interviews, so I used to ask them about
              the questions they faced during interviews. I prepared accordingly
              for my interview (I did not qualify for a single interview till
              Credit Suisse :)). Of all the other things, Patience and Positive
              mindset is a very important factor. Focus and Believe in yourself!
              You’ll surely crack it!
              <br />
              <br />
              Hope this helps! All the Best!
            </i>
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:phandah18.comp@coep.ac.in">
              phandah18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default atharva_phand;

import React from "react";

const renuka_padalkar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Renuka Padalkar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Pattern Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The entire process was divided into 3 rounds.
                            <ol>
                                <li>
                                    Coding Test
                                </li>
                                <li>
                                    Technical Interview
                                </li>
                                <li>
                                    HR Interview
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude test had 2 coding questions . <br />
                            <b>Difficulty-Medium</b>
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ol>
                            <li>
                                Technical Round:
                                <br />
                                In this round,I was asked several questions about  DSA and OOPs. Also I was asked about the projects that I had mentioned in my resume.
                                Questions related to DBMS were asked .In the end I was given  2 coding question related to graphs.
                            </li>
                            <li>
                                HR Round:
                                <br />
                                This was a casual round where I was asked questions like "Why do you want to join Pattern? What are your future goals?".
                            </li>
                        </ol>

                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After the aptitude/coding test 21 students were shortlisted for the interview and 7 students received the internship offer.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        I practised my coding on leetcode and gfg.Go through commonly asked interview questions on Gfg.
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Make sure that you know your projects thoroughly.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Stay focused and determined. All the best!!
                        </p>
                    </p>
                    <br />
                </div>
            </section >
        </div >
    );
};

export default renuka_padalkar;

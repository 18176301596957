import React from "react";

const atharva_marathe = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Atharva Marathe
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>The hiring process consists of three rounds.</p>
            <ol>
              <li>
                <strong>Aptitude test</strong>
              </li>
              <li>
                <strong>Technical Interview Round</strong>
              </li>
              <li>
                <strong>HR Interview Round</strong>
              </li>
            </ol>
            <p>
              After the Aptitude Test, around 40 students were shortlisted for
              the Technical Interview. 10 students were selected after HR
              interview for the final offer.
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />

            <li>
              The Aptitude test was conducted on AMCAT platform. The test
              consisted of Quantitative ability, Verbal, Technical MCQs and 2
              coding questions at the end.
            </li>
            <li>
              Python was not available for the test. We were also not allowed to
              use STL for C++
            </li>
            <li>
              Both the questions were pretty easy to solve, but there were no
              libraries like STL to solve it and we had to write the code from
              scratch.
              <ol>
                <li>
                  <b>Question 1:</b> Sorting array elements by frequency.
                  (Maintaining the order among the elements)
                </li>
                <li>
                  <b>Question 2:</b> Find number of permutations. (Cannot
                  remember the exact question)
                </li>
              </ol>
            </li>
            <li>
              Sample questions of AMCAT tests can be useful to get general idea
              of the test pattern.
            </li>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>There were 2 rounds of interviews: Technical and HR.</p>
            <ol>
              <li>
                <p>
                  <strong>
                    {" "}
                    <em>Technical Round</em>{" "}
                  </strong>
                </p>
                Technical interview went for around 20 – 25 minutes. My entire
                interview was based on the projects that I had done. Some other
                candidates were asked DSA, OOPs and DBMS questions. So really
                depends on the interviewer. I had made web portal to conduct
                college exams as a project. The interviewer asked most of the
                questions based on data analysis. Like how would you interpret
                the results of the students. Graphical, statistical tools I will
                use to analyse the strength and weakness of the students, what
                are his/her favourite subjects, etc. At the end he also asked
                why I wanted to join Citi as an intern. Make sure to prepare for
                these types of questions. At the end he asked if I had any
                questions. Do a little research of the company and ask a good
                question to them, it would indicate that you are highly
                interested to join the company.
              </li>
              <br></br>
              <li>
                <p>
                  <strong>
                    {" "}
                    <em>HR Round</em>{" "}
                  </strong>
                </p>
                The HR round went for around 10 minutes. Some of the questions
                asked were as follows, “Tell me about yourself” “Why you want to
                join Citi? “etc. Go through HR questions on the internet and
                prepare them. Confidence is the key in cracking HR interviews.
              </li>
            </ol>
          </p>

          <p>
            <b>4. How many questions were asked based on the projects done?</b>
            <br />
            <p>
              My entire interview was based on projects. Make sure to prepare
              well for the projects. You should know all the inside outs of the
              project because the interviewer can ask you detailed explanation
              to any level of depth.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              Start preparing for internship as early as possible. I had used my
              entire summer vacations of around 2 months for the preparation. I
              used GFG for studying DSA, OOPs, DBMS concepts. Also, I had
              followed Love Babbar’s 450 DSA question sheet and completed it by
              solving daily 8-9 problems. It is an exhaustive set of most
              common, famous DSA questions that cover every topic. I strongly
              recommend to use it for preparation. Hackerrank, Leetcode are also
              good for preparing coding rounds. I used IndiaBix to practice for
              Aptitude test. Try to make a peer group among your friends while
              preparing as you can discuss doubts among each other and push each
              other to practice more.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Practice as many as variety of questions as you can. Leetcode,
              Hackerrank has a good amount of quality questions. Make sure to
              cover topics such as Dynamic Programming, Greedy as most of the
              questions asked in coding tests are based on algorithmic thinking.
              First implement brute-force approach and pass some basic test
              cases. Then identify redundant work or strategies such as sliding
              window, two-pointers, dp etc. Don’t over-think to get optimized
              solution in one go.
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            Be thorough with basics of DSA, OOPs, some amount of DBMS. Don’t
            bluff anything that you are not sure about. Prepare your projects
            well. And most important is not to underestimate HR Round. You need
            to be confident and answer questions smartly. Go through typical HR
            interview questions beforehand.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            {
              <a href="mailto: maratheas19.comp@coep.ac.in">
                maratheas19.comp@coep.ac.in
              </a>
            }
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default atharva_marathe;

import React from "react";

const prerna_tulsiani = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Prerna Tulsiani
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Deutsche Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              There were three rounds:
              <ol>
                <li>
                  Round 1 - Aptitude Round ( Online - 26th August 2022 )

                </li>
                <li>
                  PPT for shortlisted students ( Online - 29th August 2022 )
                </li>
                <li>
                  Interview Rounds ( Offline - 30th August 2022 )
                  <ul style={{ listStyleType: "circle", paddingLeft: "25px" }}>
                    <li>
                      Technical Round
                    </li>
                    <li>
                      Profit Round
                    </li>
                    <li>
                      HR Round
                    </li>
                  </ul>
                </li>
              </ol >
            </p>

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <ul>
              <li>
                The aptitude test was conducted in online mode on hackerearth platform

              </li>
              <li>
                Duration of aptitude : 90 mins
              </li>
              <li>
                There were 10 mcqs and 2 coding questions, and navigation was on
              </li>
              <li>
                MCQs : <br />
                <ul style={{ listStyleType: "circle", paddingLeft: "40px" }}>
                  <li>
                    2 mcqs on DSA : Worst case time complexity of N queen problem
                    and time complexity of BFS
                  </li>
                  <li>
                    2 mcqs on OS : Starvation and CPU resource management
                    techniques
                  </li>
                  <li>
                    2 mcqs on CN : Subnetting and TCP connections used by FTP

                  </li>
                  <li>
                    <li>
                      2 mcqs on DBMS : Nested queries and queries on aggregate
                      functions

                    </li>
                  </li>
                  <li>
                    2 mcqs on Java : One on output finding and other on error finding
                  </li>
                </ul>
                <li>
                  2 coding questions : <br />
                  <ul style={{ listStyleType: "circle", paddingLeft: "40px" }}>
                    <li>
                      First : A variation of next greater element to the left
                      Comparison criteria was sum of digits for eg. 25&gt;13 as (2+5) &gt; (1+3)
                    </li>
                    <li>
                      Second : An array was given and we had to make all elements of the
                      array by performing the following operations :
                      <br />
                      <ul style={{ listStyleType: "square", paddingLeft: "40px" }}>
                        <li>
                          Choose any element and do the bitwise xor with 2 x where x&gt;=0
                        </li>
                        <li>
                          Return the minimum number of operations
                        </li>
                      </ul>

                    </li>
                  </ul>

                </li>
              </li>
            </ul>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p> Total 4 rounds including the aptitude round
            </p>
            <ol>
              <li>
                <p>
                  <strong>First round was Aptitude Round
                  </strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>Second Round : Technical Round - Offline</strong>
                  <p>
                    This round started with basic DSA questions, like stack using queue, queue
                    using stack, Dijkstra’s Algorithm, Difference between DFS and BFS.
                    Then the interviewer asked me to code for following two questions on paper
                  </p>
                  <ol>
                    <li>
                      Variation of the question : Find duplicate numbers
                      And the interviewer asked me three ways for it
                    </li>
                    <li>
                      Question related to Binary Search Tree
                    </li>
                  </ol>
                  <br />
                  <p>
                    Then after DSA, I was asked some questions on Object Oriented Programming
                    for eg. 4 pillars of OOPs, difference between encapsulation and abstraction,
                    types of polymorphism.

                  </p>
                  <p>
                    After this I was asked questions related to databases - like the difference
                    between sql and nosql, then various types of joins and normal forms. I was also
                    asked to write two queries : one using group by and second using joins and
                    nested queries. After this the interviewer asked me about various functions of
                    MongoDB like findOne etc.
                  </p>
                  <p>
                    Then I was asked questions related to my projects. First the interviewer asked
                    me to explain both my DSA projects (first was Text Editor and second TAR
                    command). Then I had mentioned a full stack MERN project, so the interviewer
                    asked me various questions on MERN like how to establish connection between
                    frontend and backend, how to deploy a full stack project and hooks, useEffect
                    and useState in React.

                  </p>
                  <p>This round was for around 45-50 minutes.
                  </p>
                </p>
              </li>
              <li>
                <p>
                  <strong>Third Round : ProFit (Professional Fitness) Round - Offline</strong>
                </p>
                <p>
                  This round was basically to judge whether one is professionally fit for their
                  company or not. It started with my introduction, my interests and activities I have
                  been a part of.

                </p>
                <p>
                  Then the interviewer gave me a puzzle to solve.
                </p>

                <p>
                  After this I was asked professional situation based questions like - Do you like
                  teamwork? Can you work alone on a project? It’s pros and cons, and most
                  important - why do you want to join DB.
                </p>
                <p>
                  Basically one who had attended the Pre Placement Talk could answer all such
                  questions easily
                </p>
                <p>
                  This round was for around 30 minutes.
                </p>
              </li>
              <li>
                <p>
                  <strong>Fourth Round : HR Round - Online</strong>
                </p>
                <p>
                  This round was conducted online on-campus. Typical HR type questions were
                  asked in this round. Just stay confident and try to answer all questions in this
                  round.

                </p>
                <p>
                  This round was for around 20-25 minutes.
                </p>

                <p>
                  After this I was asked professional situation based questions like - Do you like
                  teamwork? Can you work alone on a project? It’s pros and cons, and most
                  important - why do you want to join DB.
                </p>
                <p>
                  Basically one who had attended the Pre Placement Talk could answer all such
                  questions easily
                </p>
                <p>
                  This round was for around 30 minutes.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            Around 100 students were shortlisted for Aptitude Test
            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
              <li>After 1st Round - 14</li>
              <li>After 2nd Round - 12</li>
              <li>After 3rd Round - 11</li>
              <li>Final Offer - 11</li>

            </ul>
          </p>

          <p>
            <b>
              5. Some good resources you used in your internship prep
            </b>
            <br />
            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
              <li>Abdul Bari’s DSA course</li>
              <li>For Recursion, DP and Graph - Take U Forward Series</li>
              <li>Striver’s DSA sheet and 450 sheet</li>

            </ul>
          </p>

          <p>
            <b>
              6. Some tips to crack coding questions
            </b>
            <br />

            <p>
              Practice is the key to crack coding questions, although the quality and not the
              quantity of the questions you practice matters the most</p>
          </p>

          <p>
            <b>7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume</b> <br />
            <p>
              Many questions related to extra-curricular and co-curricular activities were asked
              in the last two rounds, as they help the interviewer to judge your overall
              personality</p>
          </p>

          <p>
            <strong>
              8. Final Tip for Juniors?
            </strong>
            <p>
              Stay positive and never compromise your health, because the initial 1 month of
              internship phase is very exhausting mentally and physically. Also, programming
              language is never a constraint in solving aptitude DSA questions. I had
              attempted all DSA questions in python :)
            </p>
          </p>

          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID : <a href="mailto:prernat20.comp@coeptech.ac.in">
              prernat20.comp@coeptech.ac.in
            </a>
            <br />
            LinkedIn : <a href="https://www.linkedin.com/in/prerna-tulsiani-00b894202">Prerna Tulsiani</a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default prerna_tulsiani;

import React from "react";

const mayank_jain = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{ fontSize: "2em", position: "absolute", marginTop: "2rem" }}
          />
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Mayank Jain</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            DE Shaw India Pvt. Ltd.
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '21
            </p>
          </div>
          <br />
          <p></p>
          <p>
            <strong>
              Q1. What was the structure of the entire internship hiring
              process?
            </strong>
            <br />
            {/* <p> */}
            a. Aptitude Test of 1 hour on HackerRank on 31st July 2019.
            <br />
            b. 3 rounds of which 2 were entirely technical and 1 HR on 1st
            August 2019.
            {/* </p> */}
          </p>
          <p>
            <br />
            <strong>Q2. What questions were asked in the aptitude test?</strong>
            <br />
            a. The test was of 1 hour.
            <br />
            b. It had two sections – Aptitude (40 mins) and Coding (20 mins)
            <br />
            c. Aptitude – Had basic questions on input/output, quant, three
            questions on GK.
            <br />
            d. Coding – One question which was given a set of computer links
            like A – B separated by a new line, find out the cluster of
            connected computers which when attacked with a virus would result in
            the highest number of computers being compromised. (or given a set
            of computer name’s after the input, check if the computer is within
            the cluster and output Yes/No for every computer). Hint – Using
            disjoint sets for optimal solution or even a graph solution could
            work
            <br />
            e. Time management was the key to a good score.
            <br />
            f. Aptitude solving should be done in easy first hard last (or
            never) manner. That should fetch you a decent score in aptitude.
            <br />
            g. Reading and understanding the problem, writing code, and testing
            against the cases all in 20 mins is hard. So, try to write decent
            code and get as many test cases as possible to work.
          </p>
          <p>
            <br />
            <strong>
              Q3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </strong>{" "}
            <br />
          </p>
          <p>
            <b>Round 1 (Technical):</b>
            <br />
            i. Basic introduction
            <br />
            ii. How was the aptitude test? Since I did not get all the test
            cases, I was asked to elaborate the solution I wrote during the
            test. Followed by optimizations and alternate solutions
            <br />
            iii. After looking at my resume, asked me to explain my projects.
            Had to explain extensively given the number of questions.
            <br />
            iv. While I was explaining my project, for every concept I was asked
            something relevant to that concept. For example, about the linux
            ecosystem or about certain commands, stages in NLP and so on.
            <br />
            v. I was also asked about the kind of work I did in my previous
            internship followed by a lot of questions on OOPs principles since
            my internship was based in C++.
            <br />
            vi. And at last, a few logic puzzles. Out of 2-3, I remember only
            one which was{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.geeksforgeeks.org/puzzle-7-find-the-jar-with-contaminated-pills"
            >
              Puzzle
            </a>
            <br />
          </p>
          <p>
            {" "}
            <b> Round 2 (Technical):</b>
            <br />
            i. Unlike previous round, this round focused mostly on my
            internship, the learnings out of it, the experience, the challenges
            I faced during the internship and how did I overcome them.
            <br />
            ii. Again, following the same pattern, I was asked questions
            relevant to the topic which I had just skimmed in my internship. For
            example, a few of them were on networking, especially TCP vs UDP.
            Not just this, I was asked design-based questions to gauge my
            understanding on the topic. Given a requirement, would you choose X
            and why?
            <br />
            iii. It concluded with questions on data structures. They were
            unique and were mostly medium level since you can get them if you
            are well versed with what the question designer had in mind.
            <br />
          </p>
          <p>
            {" "}
            <b>Round 3 (HR):</b>
            <br />
            i. This was a bit more candid than the previous two rounds.
            <br />
            ii. I was asked to identify my strengths and weaknesses and about my
            co-curricular activities.
            <br />
            iii. The HR asked me why was I interested in joining DESIS and why
            do I think I’m the perfect match for them.
            <br />
            <br />
            Almost every round lasted close to 40 - 60 mins with Round 1 being
            an elimination round. Out of 15 in Round 1, 8 were shortlisted for
            Round 2.
          </p>
          <p />
          <br />
          <p>
            <strong>
              Q4. How many questions were asked based on the projects and on
              resume?
            </strong>
            <br />
            a. A lot. My projects involved a multitude of concepts opening wide
            possibilities of questions.
            <br />
            b. Along with this, I was asked about what my project had to offer
            over existing solutions if there was one.
            <br />
            c. Write a basic outline of the project in 5 mins (pseudo code)
            <br />
            d. The key to explaining projects is doing a good project and
            knowing your project inside out. Tell it like a story. Sell your
            project.
          </p>
          <br />
          <p>
            <strong>
              Q5. What are some good resources used by you in your preparation?
            </strong>
            <br />
            a. Nothing in particular, just check out past experiences from GFG
            and now our own CSI website and identify the most asked questions
            and topics.
            <br />
            b. I used Cracking the Coding Interview to brush up concepts, read
            wikis for new concepts and practiced coding on HackerRank and
            HackerEarth’s challenges. Solved a lot of quant questions online,
            although RS Aggarwal is a good book as suggested by many.
            <br />
            c. Try participating in challenges with friends, those discussions
            helped me later.
          </p>
          <p />
          <br />
          <div style={{ color: "gray", fontSize: "1.15em" }}>
            <a href="mailto:jainmv17.comp@coep.ac.in">
              jainmv17.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default mayank_jain;

import React from "react";

const aditya_verma = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
        Aditya Verma
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>TCS Research</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            There were two rounds of technical interviews. Both of them were phone interviews.

          
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            There was no aptitude test, but there was resume shortlisting (which rejected half of the candidates) so please keep your resumes short and brief.

          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
           <p></p>
           <ol>
              <li>
                <p>
                  The
                  <strong>
                    {" "}
                    <em>first round</em>{" "}
                  </strong>
                </p>
                The first round was based only on the projects I had done in my SY, since the interviewer was a Senior Scientist in the Advanced Computing Field, she asked me what I could have done better in terms of computing in my projects.
<p>So, make sure you are very thorough with your projects. Do not mention anything on your resume on which you cannot speak for at least 5mins.</p>
The interview lasted around 40mins

              </li>
              <br />
              <li>
                <p>
                  The
                  <strong>
                    {" "}
                    <em>second round</em>{" "}
                  </strong>
                </p>
                Second round was based on my coding skills and puzzle-solving. There were two interviewers this time, Both of them were research scientists in the image processing field. At first, there was a general discussion on current trends in the image processing field. Then, they asked me 4 theory and coding questions, and finally a playing-cards-based puzzle.
                <br/>
                <ul>
                <li>
                   <u>First Coding Question</u> 
                    <p><a href="https://www.geeksforgeeks.org/write-a-c-function-to-print-the-middle-of-the-linked-list/
">https://www.geeksforgeeks.org/write-a-c-function-to-print-the-middle-of-the-linked-list/
</a></p>
                </li>
                <li>
                <u>  Second Coding Question</u> 
                    <p><a href="https://www.geeksforgeeks.org/delete-a-node-from-linked-list-without-head-pointer/
">https://www.geeksforgeeks.org/delete-a-node-from-linked-list-without-head-pointer/
</a></p>
                    </li>
                    <li>
                    <u>  Third Coding Question</u> 
                    <p>Difference between Splay Trees and AVL Trees
</p>
</li>
<li>
                    <u>  Fourth Coding Question</u> 
                    <p><a href=" https://leetcode.com/problems/two-sum/"> https://leetcode.com/problems/two-sum/
</a></p>
</li>

                    </ul>
              </li>
              <p>This interview lasted 35mins.</p>
There was no HR round.

              <br />
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>
            <br />
            <p class="has-line-data" data-line-start="48" data-line-end="51">
                <p>
            As I mentioned before, the first round was based only on my projects. The interviewer was asking all the tiny details about my projects.</p>
            <p> You should be able to talk about your projects for at least 5mins. The interviewer also asked me about optimizations that I can do in projects. 
E.g. I had done a project which reduced the time complexity of malloc(), the interviewer asked how could I further improve the performance of dynamic allocation using multithreading.</p>

            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <p class="has-line-data" data-line-start="54" data-line-end="56">
            Here are links to some resources :
            <li>
            <b>GeeksForGeeks</b>: This website is a VERY MUST for DSA preparation, All your doubts will be cleared here. It also has a practice section where you can improve certain topics.
            <p>

<u>GFG:</u> <a href="https://www.geeksforgeeks.org/
">https://www.geeksforgeeks.org/
</a>
</p>
<p><u>Practice Section:</u> <a href="https://practice.geeksforgeeks.org/home/">https://practice.geeksforgeeks.org/home/</a>
</p>


            </li>
            <li>
            <b>Leetcode:</b> This website is very important when it comes to clearing the coding round. It has a very friendly UI and has questions divided into Easy, Medium, and Hard, making it beginner-friendly.
It has also daily challenges and weekly contests which gives you a time-bound     environment.
             <p><u>Leetcode:</u><a href="https://leetcode.com/explore/"> https://leetcode.com/explore/</a>
</p>
            </li>


            </p>
          </p>

          <p class="has-line-data" data-line-start="57" data-line-end="58">
            <strong>6. Some tips to crack the coding questions.</strong>
            <p class="has-line-data" data-line-start="59" data-line-end="64">
            The only tip to clear the coding round is to DAILY solve a question from Leetcode. Start off with the EASY problems and finally make your way to the MEDIUM and HARD. If you do this, the coding round will be a piece of cake.

            </p>
          </p>

          <p>
            <i>
            Some general tips :
<li> During the interview, The interviewer may give you a question that you have probably solved before, and you probably may know the optimized solution also. Do not directly give the optimized solution to the interviewer, always act like you have seen this question for the first time, and give a brute force solution first and then build your way to an optimized solution. The interviewer is only interested in your way of thinking and not in the final solution.
</li>
<li>If you are getting rejected in interviews, do not lose self-confidence. Analyse your mistakes from each interview and learn from them.
</li>
            </i>
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:vermaar19.comp@coep.ac.in
">
            vermaar19.comp@coep.ac.in


            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default aditya_verma;

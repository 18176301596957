import React from "react";

const chinmay_dixit = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Chinmay Dixit
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            eQ Technologic
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />
          <p>
            <b>
              1. What was the structure of the entire internship hiring process?
            </b>
            <br />
            <li style={{ marginLeft: "2em" }}>
              <b>Aptitude Test:</b> AMCAT (approximately 1 hour){" "}
            </li>
            <li style={{ marginLeft: "2em" }}>
              <b>Interviews:</b> 3 rounds all of which were technical
            </li>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The syllabus for AMCAT can be found on their website and the
            questions asked were pretty basic. There were 2 coding questions
            both of which were quite straightforward.
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            <i>Round 1</i>
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>
                Introduction: Basic intro to my resume, academic and
                co-curricular interests.
              </li>
              <li>
                Since I mentioned machine learning as one of my interests, I was
                asked to briefly explain what all I had learnt, and if I had any
                projects in that domain.
              </li>
              <li>
                Resume/ Project based: Next, the interviewer jumped on to my
                resume where I had mentioned two projects both of which were
                part of coursework. I was asked to explain my DSA project
                (C-sudoku), what technologies I had used, algorithms and data
                structures I used and a key takeaway from this project.
              </li>
              <li>
                A few questions related to object oriented programming in Java
                and C++.
              </li>
              <li>
                Pointers and pass by address in C on which I was asked to
                complete a simple code snippet. A basic python program which he
                further modified and a few questions asking me to elaborate on
                the logic I used.
              </li>
              <li>
                A few questions on DBMS and a query related to aggregation in
                MySQL.
              </li>
            </ul>
            <br />
            <i>Round 2</i>
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>Introduction: Similar to round 1.</li>
              <li>
                Data structures and complexity analysis: Tree, self-balancing
                trees, arrays, linked lists and a few other data structures, how
                and when they’re used (this is where the complexity part comes
                in).
              </li>
              <li>
                SQL and NoSQL databases: A few questions related to data
                retrieval time, json and when either one of them are used.
              </li>
            </ul>
            <br />
            <i>Round 3</i>
            <ul style={{ listStyleType: "disc", marginLeft: "2rem " }}>
              <li>
                This round was a code based round where the interviewer sent me
                a problem statement (print a correctly formatted calendar using
                a function which takes the month and year as the input. Assume
                that you have access to a function that returns the day on which
                the month began.) via email and I was supposed to be ready with
                the solution in 20 to 30 minutes.
              </li>
              <li>
                I was asked to explain the code I wrote and the logic behind it
                (the syntax wasn’t as important).
              </li>
            </ul>
            <br />
            At the end of the third round, the interviewer was quite satisfied
            and gave me a positive feedback. The first round went on for an
            hour, the second interview was about 45 mins long and the third one
            went on for about 20 minutes. All 3 interviewers were quite friendly
            and gave me enough time to think about the questions. Towards the
            end of the first two rounds, the interviewers asked me whether I had
            any questions for them. I asked them about their roles and a few
            technical questions related to the company’s domain of work.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>
            <br />A few questions based on my DSA project and if there was any
            other project I had worked on. The introduction part in the first
            and the second round was mainly based on my resume and other
            interests.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            Concepts and algorithms from{" "}
            <a href="https://www.geeksforgeeks.org/">
              <em>GFG</em>
            </a>{" "}
            and problem solving practice on{" "}
            <a href="https://www.hackerrank.com/">
              <em>HackerRank</em>
            </a>
            .
          </p>

          <p>
            <i>
              Be thorough with your DSA concepts and keep practicing by solving
              coding questions. During the interview, maintain your composure
              and be completely honest with the interviewer.
            </i>
          </p>

          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:dixitcs18.comp@coep.ac.in">
              dixitcs18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default chinmay_dixit;

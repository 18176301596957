import React from "react";

const naishadh_vora = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{ fontSize: "2em", position: "absolute", marginTop: "2rem" }}
          />
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Naishadh Vora
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Standard Chartered
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />
          <p>
            These details are for when Standard Chartered came to our college
            for internships for the Summer of 2020, and selected 6 people. Their
            process was a little different as compared to the other companies
            that offered internships.
            <br />
            <br />
            They started off with an “personality test”, that was not an
            elimination round. It is hard to say whether they took this into
            account when selecting the final students eventually, but at least
            at the start, this test was only for name sake.
            <br />
            After this, everyone was sent the link for a logical reasoning test.
            This test was elimination based, and we received scores on
            completion. The cutoff criteria for the test was being above the
            35th percentile (fixed before we gave the test). This test had 15
            questions of the same format – 8 diagrams were given, you were
            supposed to figure out the remaining diagram based on the ones
            given.
            <br />
            After this, everyone who cleared had to give a numerical reasoning
            test. The cutoff criteria was again being above the 35th percentile.
            This test had another 15 questions based on simple quant concepts,
            mainly involving data interpretation – graphs were given, and simple
            metrics had to be calculated/approximated. The main challenge was
            time rather than the difficulty of the questions (this is pretty
            typical for quant MCQs for aptitude tests). That was it for the day,
            it went on from around 5PM to 7:30PM.
            <br />
            <br />
            The next day, there was a coding test that involved one coding
            question, 15 quant MCQs and 1 SQL question. I’m not exactly sure of
            the time constraints involved, but the quant MCQs were under very
            tight constraints – something along the lines of 45 seconds per
            question. The time was fixed per section, you couldn’t devote more
            to one than the other. The quant MCQs came first, followed
            immediately by the SQL question. It wasn’t very difficult, just
            involved a natural join followed by a filtering WHERE clause, and
            renaming a column.
            <br />
            Next was the coding question, which wasn’t very complicated either.
            Again, I’m hazy on the details, but pretty standard stuff for anyone
            even slightly familiar with competitive coding.
            <br />
            After this, the company took a few days to get back to us. They
            scheduled a PPT, followed by the results of the coding test.
            <br />
            <br />
            The next round was Group Discussion, in groups of 8-10, in relative
            order to the performance of the previous tests. In this round, the
            discussion was centered around whether SC should give a loan to this
            particular oil company that was an integral part of the country’s
            economy, albeit guilty of exploiting their workers. Everyone had 5
            minutes to go through the argument, decide on a stand, and then
            discuss. The scheduled time was 15 minutes, after which we were all
            given a break for lunch.
            <br />
            The next round of shortlists came, after which interviews were
            scheduled.
            <br />
            <br />
            The first one was a mixture of both HR and technical questions. I
            was asked to describe myself a little, to which I gave a brief intro
            about myself, and the clubs I was in. He asked a few questions
            regarding the work I had done in those clubs (CSI &amp; SDS). After
            that, there were a few questions related to my projects, the kind of
            methods I had implemented to get the required output etc. However,
            no competitive coding style questions were asked.
            <br />
            A few of the candidates only had one round of these interviews, but
            I was called in for another. This one was purely technical. My
            language of preference is Java, so I was asked an elementary
            question regarding counting. I gave the solution using a HashTable
            (verbally, no code), and then he asked me a few more questions about
            Collections Framework in Java, ArrayLists, and so on. It went on for
            about 20 mins, before all the round were finally finished.
            <br />
            <br />
            <b>Verdict: Selected</b>
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15em" }}>
            <a href="mailto:vorana17.it@coep.ac.in">vorana17.it@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default naishadh_vora;

import React from "react";

const aditya_raskar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Aditya Dnyaneshwar Raskar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            First round was the online aptitude test. Second round was the interview for the shortlisted students from online test.
                            We did not have two separate interviews(technical and HR) because of time constraints . In a single interview there were both technical and HR questions.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Online Aptitude round consisted of questions of different domain.
                            1st set consisted of questions related to English proficiency.
                            Second set had questions related to business analytics, candlestick graphs, stock prices etc.
                            Third set included the coding questions. They were of easy to  moderate level.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Initially, we were told that there would be three rounds : Aptitude round, technical interview and HR interview.
                        Due to time constraints, technical and HR interview rounds were combined into a single interview round.
                        <br />
                        <br />
                        Details about the aptitude round are mentioned already.
                        <br />
                        <br />
                        Talking about the interview, <br />
                        I was asked extensively about my resume, particularly my involvement in projects I had worked on and some questions on clubs I mentioned in resume. <br />
                        For solo projects , questions were mainly based on what's there in project and to check knowledge of project.  For group projects, I was asked about team dynamics, division of tasks, and the technical solutions we implemented.
                        I feel that you should prepare good for interview. Also be confident and positive during interview, it makes good impression on the interviewer.

                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        For aptitude test - around 150 students
                        <br />
                        <br />
                        After online aptitude test for interview - 20 students
                        <br />
                        <br />
                        After Interview -  10 students


                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Gfg, Leetcode , InterviewBit , Abdul Bari
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Make sure your DSA concepts are very clear.<br />
                            Practice Regularly: Consistency is key. Try to solve problems daily. <br />
                            Optimize Solutions: Focus on writing efficient code, considering time and space complexities.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            During the interviews, 5-6 questions were asked about my projects and involvement in clubs and extracurricular activities. They were particularly interested in my leadership roles and the technical challenges faced during projects.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Go through dsa concepts.Dbms is also important. Make projects as better as you can. Also keep eyes on what you are adding in resume.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Stay focused , be honest and thoroughly understand your projects. Prepare well in advance . Practise regularly on coding platforms and participate in mock interviews to build confidence. Additionally, work on personal projects. Keep a positive attitude throughout the preparation process. Good luck!!
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:raskarad21.comp@coeptech.ac.in">
                            raskarad21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default aditya_raskar;

import React from "react";

const anjali_dofe = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Anjali Ajay Dofe
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Deutsche Bank
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ol>
              <li>
                <p>There were overall 4 online rounds conducted.</p>
              </li>
              <li>
                <p>
                  Online Aptitude/Coding Test (120 minutes): Those with CGPA 7.5
                  and above can attempt the test. It was conducted on an online
                  platform <strong>HackerEarth</strong>.
                </p>
              </li>
              <li>
                <p>
                  The 3 other rounds were technical interview,
                  professional-fitment round, and HR Interview Round.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <ol>
              <li>
                <p>
                  The round was divided into two parts: 10 MCQS (4 marks each)
                  and 2 Coding questions.
                </p>
              </li>
              <li>
                <p>
                  The questions in the aptitude test analysed our coding and CS
                  fundamentals. The questions included basics of DBMS and
                  predict the output of code (mostly in Java) kind of questions.{" "}
                </p>
              </li>
              <li>
                <p>
                  One of the coding questions which I had was that given a
                  circular array, arrange the element present twice in the array
                  at the first and second index of the array swapping only
                  adjacent elements and return the minimum number of steps
                  required to do so.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>There were three interview rounds in total.</p>
            <ol>
              <li>
                <p>
                  The
                  <strong>
                    {" "}
                    <em>first round</em>{" "}
                  </strong>
                </p>
                The first round i.e., the technical round began with the
                standard question “Tell Me About Yourself”. Then they asked me
                about the projects listed on my resume. I explained the gist on
                my projects and answered any follow-up questions they asked
                based on my answer. I was mostly asked basics and fundamentals
                of Object-Oriented Programming such as what is encapsulation and
                inheritance and state their different types. The asked a lot on
                data structures and algorithms, which included information about
                nonlinear data structures such as trees and graphs and basic
                questions such as what a pointer is and what is the difference
                between passing by value and passing by reference in functions.
                The interviewer also asked me to live code a question to perform
                addition without using arithmetic operators by sharing my
                screen. At the end the interviewer asked me if I had any
                questions for them. This round lasted for around 45 minutes.
              </li>
              <br />
              <li>
                <p>
                  The
                  <strong>
                    {" "}
                    <em>second round</em>{" "}
                  </strong>
                </p>
                This round was the Professional-fitment round. I was asked to
                introduce myself and to talk about the contents listed on my
                resume. The interviewer asked me a lot of situational questions
                which included a question where I was put in a situation that I
                am working on a project whose deadline is within a week and it
                is a very hard project and at the last moment my boss asks me to
                add another feature to the project and submit that too along
                with the completed project how I would tackle such a situation.
                He took around 3 to 4 different answers from me on the same
                question. The sir also asked me if I would prefer working as a
                team or working solo and why. At the end the interviewer asked
                me if I had any questions for them. This round lasted for around
                30 minutes.
              </li>
              <br />
              <li>
                <p>
                  The
                  <strong>
                    {" "}
                    <em>third round</em>{" "}
                  </strong>
                </p>
                The third round was the HR round. The HR asked me to introduce
                myself and asked me about my hobbies. He went through my resume
                and asked me about the clubs listed on my resume. He asked me
                why are there lower number of women in the field of technology
                and engineering compared to the number of men and why do women
                choose other fields over technology and our discussion on this
                topic went on for about 10 minutes. He asked me if I knew about
                the company or its recent history. I honestly answered that I
                knew the company but not about its recent past activities or
                news. It is important that you be honest here. He finally
                concluded asking if I had any questions for him.
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <br />
            <ol>
              <li>
                <p>
                  Few questions were asked based on the projects and other
                  details listed on my resume.
                </p>
              </li>
              <li>
                <p>
                  They asked me about the projects I have worked on and to give
                  a brief description of those. I had a project on Face Mask
                  Detection using ML and AI, so they asked me why I chose this
                  project and why AI and the tools and technologies I used.
                </p>
              </li>
              <li>
                <p>
                  They also asked me about the clubs I was in, and a few
                  questions based on that.
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              I used GeeksForGeeks for theoretical and conceptual knowledge and
              their quizzes. For coding practise, I used LeetCode. I would
              recommend sticking to one coding platform only. Even Hackerrank
              and CodeChef are good platforms. I also referred to YouTube
              playlists for clearing my basics of DBMS, CN and coding.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              To crack coding questions the most important thing is to practise
              regularly. Try to practise daily and make it a point to solve
              minimum two questions every day. Use the programming language with
              which you are most comfortable. Be sure to take into consideration
              all possible corner cases and coding for those test cases too as
              at times your solution looks right but fails a few test cases. In
              a coding round too if you are unable to solve a question make sure
              you code for as many corner cases as you can or by using brute
              force so at least a few cases are cleared and then try to think of
              a more optimized solution.
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            Be clear with fundamentals of oops and data structures. There will
            surely be many questions on these topics. Also go through basics of
            DBMS, CN and OS as their may be few questions on these topics as
            well. Mention only those skills on your resume on which you would be
            able to answer questions. Be well prepared and stay confident.
            Revise and go through all the projects which you have mentioned on
            your resume. Students with good communication skills are preferred,
            so try and improve your communication skills by practising to talk
            in English using proper Grammar. Stay calm during interviews, be
            confident and most importantly be honest. If you do not know
            something, tell them truthfully but don’t lie cause, if they find out it
            may lead to your rejection. Just be yourself and don’t worry you
            will surely do well. All the interviewers are sweet. Also ask
            appropriate questions to them at end of interviews it creates a good
            impression.
          </p>
          <br />
          <p>
            <i>
              A general suggestion for all companies: Be honest and remain calm
              and confident. It doesn’t matter even if you are unable to solve
              coding questions just make sure to explain you thought process or
              solution you have thought about to your interviewer. If you are
              not clear about any question, feel free to ask your interviewer to
              repeat it or explain a bit more about it. Also try working on your
              communication in English as it is a plus point. I wish you guys
              all the best for your future!!!
            </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            {/* <a href="mailto:nairas18.comp@coep.ac.in">
              nairas18.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/anupn08/">LinkedIn Handle</a> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default anjali_dofe;

import React from "react";

const aman_sayyad = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Aman Sayyad
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Siemens</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The interview was short and simple, lasting just 25 to 30 minutes. It was a mix of technical and HR questions. The interviewer was friendly and didn't ask for an introduction. Instead, he asked basic questions like the difference between C++ and C. He also asked me to explain the project mentioned in my resume and inquired about my role and contributions to the club. Additionally, he asked about my areas of interest and posed some resume-based questions. At the end, he invited me to ask him any questions.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            There were 75 MCQs for 45 min. Test consists of logical reasoning questions, a few technical and general aptitude questions. Questions were easy-level questions and one can pass this round if they are strong in aptitude. You have to be really quick to clear the round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 2 rounds < br />
                        <ol>
                            <li>
                                Aptitude Round
                            </li>
                            <li>
                                Interview Round
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                After First Round 13 students were shortlisted
                            </li>
                            <li>
                                After Final Round 10 students get selected.
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                Leetcode (for coding practice)
                            </li>
                            <li>
                                GFG
                            </li>
                            <li>
                                IndiaBIX (for aptitude practice)
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Before looking at the answers, try to solve the problem yourself. Think of your own solution rather than relying on someone else's approach. Try to come up with multiple solutions to a single problem—this way, you'll enhance your ability to think critically and creatively. Focus on solving quality and unique questions that will motivate you to think.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            They just ask my role in club (CSAT) and my contribution and related to project just to explain it.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Don't stress about this internship period. Stay curious and enjoy the learning process. To truly learn and understand things in depth, read books and ask questions about what you are learning—both to yourself and to your professors. This is how you will achieve a deeper understanding. Focus on developing your skills and enhancing your thinking process.
                        </p>
                    </p>
                    <br />
                </div>
            </section >
        </div >
    );
};

export default aman_sayyad;

import React from "react";

const falguni_patil = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Falguni Patil
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Smartserv</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li class="has-line-data" data-line-start="12" data-line-end="13">
                Written test
              </li>
              <li class="has-line-data" data-line-start="13" data-line-end="14">
                Technical Interview 1
              </li>
              <li class="has-line-data" data-line-start="14" data-line-end="15">
                Technical Interview 2
              </li>
              <li class="has-line-data" data-line-start="15" data-line-end="17">
                Technical Interview 3 (Interview with senior software engineer)
              </li>
            </ul>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li class="has-line-data" data-line-start="19" data-line-end="21">
                There were two sections
              </li>
              <ol type="1">
                <li
                  class="has-line-data"
                  data-line-start="21"
                  data-line-end="22"
                >
                  Language based quiz questions(Needed to choose one of the C,
                  C++, Java, Python, Javascript languages).
                </li>
                <li
                  class="has-line-data"
                  data-line-start="22"
                  data-line-end="24"
                >
                  Quiz on MySQL (Medium + Hard Questions)
                </li>
              </ol>
            </ul>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <ol type="1">
              <i>
                <li
                  class="has-line-data"
                  data-line-start="26"
                  data-line-end="28"
                >
                  Technical Interview 1
                </li>
              </i>
            </ol>
            <ul
              style={{
                marginLeft: "3rem",
                listStyleType: "disc",
              }}
            >
              <li class="has-line-data" data-line-start="28" data-line-end="29">
                TCP vs UDP, application of each one.
              </li>
              <li class="has-line-data" data-line-start="29" data-line-end="30">
                Private and Public IP
              </li>
              <li class="has-line-data" data-line-start="30" data-line-end="31">
                No SQL vs SQL (Advantages and disadvantages)
              </li>
              <li class="has-line-data" data-line-start="31" data-line-end="32">
                Difference in var and let in javascript.
              </li>
              <li class="has-line-data" data-line-start="32" data-line-end="33">
                Linux commands to change permission of owner, group and user.
              </li>
              <li class="has-line-data" data-line-start="33" data-line-end="35">
                Asked to code for largest sum subarray.
              </li>
            </ul>
            <ol start="2">
              <i>
                <li
                  class="has-line-data"
                  data-line-start="35"
                  data-line-end="37"
                >
                  Technical Interview 2
                </li>
              </i>
            </ol>
            <ul
              style={{
                marginLeft: "3rem",
                listStyleType: "disc",
              }}
            >
              <li class="has-line-data" data-line-start="37" data-line-end="38">
                Explain projects mentioned on resume.
              </li>
              <li class="has-line-data" data-line-start="38" data-line-end="39">
                He picked my DSA project which was based on external sorting and
                asked questions on external sorting.
              </li>
              <li class="has-line-data" data-line-start="39" data-line-end="41">
                How can you optimise your project using OS concepts?
                <br />
                (I explained various ways, but he was expecting pagination)
              </li>
              <li class="has-line-data" data-line-start="41" data-line-end="42">
                What is hash maps, application of hash maps?
              </li>
              <li class="has-line-data" data-line-start="42" data-line-end="44">
                Write a program to shift 1’s to right and 0’s to the left in
                array of 0-1.
                <br />
                (Asked to me optimise it by time and space complexities)
              </li>
              <li class="has-line-data" data-line-start="44" data-line-end="46">
                What will you use to get all parent id’s of child-parent
                database?
              </li>
            </ul>
            <ol start="3">
              <i>
                <li
                  class="has-line-data"
                  data-line-start="46"
                  data-line-end="48"
                >
                  Technical Interview 3
                </li>
              </i>
            </ol>
            <ul
              style={{
                marginLeft: "3rem",
                listStyleType: "disc",
              }}
            >
              <li class="has-line-data" data-line-start="48" data-line-end="49">
                Tell me about yourself.
              </li>
              <li class="has-line-data" data-line-start="49" data-line-end="50">
                Explain one of the most interesting project of yours.
              </li>
              <li class="has-line-data" data-line-start="50" data-line-end="51">
                He asked me about use cases of project.
              </li>
              <li class="has-line-data" data-line-start="51" data-line-end="52">
                What is public key encryption?
              </li>
              <li class="has-line-data" data-line-start="52" data-line-end="53">
                Examples of public key and private key encryption.
              </li>
              <li class="has-line-data" data-line-start="53" data-line-end="54">
                System design question - How will you design notification based
                system and what will be schema for it?
              </li>
              <li class="has-line-data" data-line-start="54" data-line-end="56">
                System design question - Air traffic management system and How
                will you get data for it.
              </li>
            </ul>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            Every interviewer asked me questions on the basis of projects and
            resume.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <a href="https://geeksforgeeks.com">GeeksForGeeks</a>,{" "}
            <a href="https://www.crackingthecodinginterview.com/">CTCI</a>,{" "}
            <a href="https://leetcode.com">Leetcode</a>,{" "}
            <a href="https://hackerrank.com">Hackerrank </a>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Practice coding questions on{" "}
            <a href="https://hackerrank.com">Hackerrank</a>,{" "}
            <a href="https://leetcode.com">Leetcode </a> etc.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            Most of the questions were asked on projects and resume.
            <br />
          </p>

          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:patilfp17.it@coep.ac.in">patilfp17.it@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default falguni_patil;

import React from "react";

const shyam_aradhye = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Shyam Aradhye
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Barclays</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              1 HR + Technical Interview
            </p>

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              2 Coding questions + MCQs on DSA, DBMS, CN,
              OS, and Predict the Output(Java mainly)
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b><br />
            There were 2 rounds :
            <p style={{ paddingLeft: "40px" }}>
              First the aptitude round which comprised 2
              Dynamic Programming questions and MCQs revolving around concepts from
              DSA, DBMS, CN, OS, and some “Predict the output” questions which were tricky
              and were mostly in Java. <br /> About 2 hours after the test, the results were declared
              and 27 were shortlisted for the interview. Each candidate had only 1 interview of
              about 30 minutes which was both HR and Technical. As I had previous internship
              experience, my interview was mostly HR based and centered around my
              previous internship. I was asked some basic technical questions on Joins from
              DBMS, and basic security questions.

            </p>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            <p>
              27 from the aptitude and 11 from the interview
            </p>
          </p>

          <p>
            <b>
              5. Some good resources you used in your internship prep
            </b>
            <br />
            <p>
              GFG for DSA concepts,
              Hackerrank for solving, Indiabix for aptitude questions, and gate-smashers for
              core subjects.

            </p>
          </p>

          <p>
            <b>
              6. Some tips to crack coding questions
            </b>
            <br />

            <p>
              If you're not fully prepared, just make sure
              that you practice a single type of question many times because if you're in luck,
              that one type of question might come in the exam. But that doesn't mean you
              simply solve only 1 type of problem. Expose yourself to varying difficulty levels
              and don't be afraid of checking the solutions early. Coding is genuinely very
              difficult and requires a lot of time and effort. It's not like this is the last time you're
              going to code. You’ll have to keep learning for the rest of your professional life.

            </p>
          </p>

          <p>
            <b>7. How many questions were asked about projects, clubs and extracurricular
              activities in your resume</b> <br />
            <p>
              I was asked a lot of questions about my previous
              internships ranging from how I got the gig, what duties were assigned to me, how
              I handled certain situations, etc.
            </p>
          </p>
          <b>
            8. Final Tip for Juniors?
          </b>
          <p>
            This phase is very difficult, especially when you keep getting
            rejected. Just try to stay calm, talk to your friends, and family, and keep working.
            Keep realistic targets, don't overburden yourself, and please don't compare
            yourself with others as everyone has a different journey and story.
          </p>
          <br />



          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID : <a href="mailto:shyamva20.comp@coep.ac.in">
              shyamva20.comp@coep.ac.in
            </a>
            <br />
          </div>
        </div>
      </section >
    </div >
  );
};

export default shyam_aradhye;

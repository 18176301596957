import React from "react";

const omkar_thorat = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{ fontSize: "2em", position: "absolute", marginTop: "2rem" }}
          />
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Omkar Thorat</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>MasterCard</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />
          <h5>What was the structure of the entire interview process?</h5>
          <p>There were two rounds:</p>
          <ul>
            <li>
              First round was an aptitude test which had different sections –
              Technical, Verbal ability, Quantitative. More weightage was given
              to Technical section.
            </li>
            <li>
              Second round was the Interview round which was conducted at the
              MasterCard office.
            </li>
          </ul>
          <h5>What questions were asked in the aptitude test?</h5>
          <p>
            Aptitude test had different sections – Technical, Verbal ability,
            Quantitative. More weightage was given to Technical section. Coding
            questions were fairly moderate in terms of difficulty.
          </p>
          <h5>How many interview rounds were there for the company?</h5>
          <p>
            I had 1 round-Technical round in which most of the questions were
            revolving around what I had mentioned in my resume, there were some
            questions related to sorting, OOPs, Data structures.
          </p>
          <h5>
            How many questions were asked based on the projects done and resume?
          </h5>
          <p>
            Resume was the most important aspect of my interview. The
            interviewer had asked the reason and motivation behind the projects
            that I had mentioned in the Resume. He further went on to ask me to
            select my favourite project and asked questions about how I went
            around doing it. He then moved to ask me about my previous work
            experience as I had done an internship during my SY summer vacation.
            Majority of the flow of my interview my revolving around everything
            that I had mentioned in my resume. I would suggest that one should
            spend a considerable amount of time while making the resume, take
            help from seniors, friends, family members. Resume shall be your
            first impression when you walk into the interview, so make sure it’s
            good.
          </p>
          <h5>What are some good resources used by you in your preparation?</h5>
          <p>
            GeeksforGeeks is a good resource for learning various algorithms,
            LeetCode, HackerRank and CodeChef are good platforms for coding.
            There are YouTube videos that show mock interviews taken by
            ex-Google, ex-Facebook employees.
          </p>
          <h5>Some tips to crack the coding questions.</h5>
          <ul>
            <li>
              <em>Aptitude Test</em>: Practice a lot of coding questions from
              various platforms. These practices should be timed, note down how
              much time you take to think about the logic to solve easy, medium,
              hard level questions and how much time you take to implement your
              solution. These timed practices helps you in understanding where
              you should improve, which topics are your strength/weakness. It’s
              necessary to make a strategy while solving questions in Aptitude
              tests.
            </li>
            <li>
              <em>Interviews</em>: When a question is asked by the interviewer
              listen to it carefully and clearly understand what is expected .
              Ask your interviewer if you do not understand the question
              correctly, while solving a question speak what logic/concept you
              are using as you solve the question. Do not lie in your resume and
              during your interview. If you do not know anything about a
              concept, don’t try to guess what it might be. If you think your
              logic about solving a question is right be confident about it.
            </li>
            <li>
              Whenever Interviewer asks about your projects make sure you tell
              about the motivation behind working on that project. Make sure at
              the end of the interview if given a chance you ask a good question
              related to either the internship or company or the interviewer.
              For example, I was given a chance and I had asked about the kind
              of projects that shall be given to the interns, to what extent do
              interns get to choose a project, and whether the interns shall be
              contributing to live projects.
            </li>
          </ul>
          <h5>Additional points to note:</h5>
          <p>
            One of the most important thing to understand is that internship
            period is an extremely stressful period, therefore it is very
            important to surround yourself with as much positivity as you can.{" "}
            <br />
            It is very important to make peace with the fact that luck plays a
            bigger role than you expect, there shall be lot of times you will
            feel that someone ‘undeserving’ got accepted whereas you did not,
            but you have to learn to make peace with it.
            <br /> At the same time keep talking to your family members,
            friends, seniors , express your concerns to them. Also make sure you
            analyse where you could have been better, learn from your mistakes,
            help your friends, keep a track of what all questions are been asked
            in HR and Technical interviews, understand what should you say and
            what should you not say during the interview.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15em" }}>
            <a href="mailto:omkarrt17.it@coep.ac.in">omkarrt17.it@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default omkar_thorat;

import React from "react";

const falguni_patil = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Falguni Patil
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Credit Suisse
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            Interviews usually consist of two or three rounds. Usually first
            round is technical interview round. In which they will ask you about
            DSA concepts, different algorithms, DC concepts, some DBMS concepts
            etc. second round can be HR or again technical depending on company.
            And last round will be on HR in which they will evaluate your soft
            skills and communication skills.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            Aptitude test is different for different companies. Most of
            companies asked quantitative, logical reasoning, data
            interpretation, verbal reasoning and two or three coding questions.
            There were negative marking for aptis of companies like DE shaw,
            Arscecium and Goldman Sachs.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            For most of the companies interviews consist of three rounds:
            <br />
            1. Technical Round
            <br />
            2. HR round
            <br />
            3. Last round (which is both Technical as well as HR round)
            <br />
            # Last year one or two companies took *GD round also. (*Group
            discussion)
            <br />
          </p>

          <p>
            <b>4. What sort of questions were asked in the interviews?</b>{" "}
            <br />
            Most of the coding questions were asked are available on GFG
            interview preparation section. So prepare all the questions from
            interview corner of GFG. Most of the technical interview questions
            are on DSA. So Revise all the concepts of array, list, stack, queue,
            tree, graphs, etc. And try to solve code on paper which will help
            you for interview. Many interviewers asks to optimize the solution.
            So be prepare with brute force algorithm as well as optimize
            solution with minimum time complexity.
            <br />
            <i>Sources – CTCI book, GFG interview preparation section.</i>
            <br />
            Most common questions generally asked in HR interviews <br />
            Q. Tell me about yourself. <br />
            Q. What are your weaknesses/strengths <br />
            Q. Where do you see yourself after 5 years <br />
            Q. Go through your CV. Try to answer HR questions according to
            company.
          </p>

          <p>
            <b>5. How many questions were asked based on the projects done?</b>{" "}
            <br />
            Many interviewers did not directly ask about projects. In my case,
            He asked to go through CV and then He asked me about my projects.
            But most of interviewers can ask about our projects. So mention only
            projects that you are confident and clear about. They usually ask –{" "}
            <br />
            Q. what’s new in your project than existing one? <br />
            Q. Which algorithm did you use in your project?(In case of DSA
            project) <br />
            Q. Different functionality of project. <br />
            Many times they ask different questions to judge our conceptual
            understanding of project like In my case, He asked me about project
            and functionalities. As my project was on sort cmd, He asked me
            different sorting algorithms and their time complexities.
          </p>

          <p>
            <b>6. How many questions were based on the resume?</b> <br />
            Usually HR asks about resume, they can ask you about extracurricular
            activities, your electives, projects, etc. But not every interviewer
            asks question on your resume.
          </p>

          <p>
            <b>
              7. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            1. Aptitude test – R S Agrawal (R S Agrawal is large book, so please
            don’t solve each section of this book. Most of the questions asked
            in aptitude test were on topics – Probability and statistics,
            permutation and combination, work and time problems, Ratios,
            distance-velocity problems etc.) and there are various online
            aptitude tests are available on sites like indiabix.com <br />
            2. Coding test - Try to solve coding questions from hackerrank
            platform. Go through company wise preparation from gfg because there
            are chances to repeat the question. <br />
            3. Interview preparation - CTCI book is the best for interview
            preparation.
            <br />
          </p>

          <p>
            <b>8. Some tips to crack the coding questions.</b> <br />
            1. Solve more n more coding questions as there is no shortcut.{" "}
            <br />
            2. Prefer Java and C++ for coding. <br />
            3. Optimize solution as it will pass your most of the test cases.{" "}
            <br />
          </p>

          <p>
            <b>
              9. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            If you are trying for credit suisse company then following are few
            things that you have to consider for preparation of aptitude and
            interviews (pl note that there is hardly one interview experience
            for credit suisse on gfg)
            <br />
            1. Aptitude test of credit suisse consist of puzzle so try to solve
            puzzles from puzzle section of gfg. Quant asked in aptitude test was
            quite easy.
            <br />
            2. For technical interview, try to practice all the topic wise
            interview questions from GFG. Most of the technical questions were
            oops concepts, time complexities of search algorithms and sorting
            algorithms. <br />
            3. Credit suisse’s one of requirement is student should have good
            communication skill. So try to improve your communiation skills.{" "}
            <br />
            4. Try to practice most common questions asked in HR interviews
            because HR interviews matters a lot. <br />
            5. In our case, there were panels who took interviews of students.
            So In my panel he asked most of the questions on DSA, DBMS, and
            project. <br />
            6. In other panels, They asked on AI-ML if they had mentioned AI-ML
            on their CV. So mention project in your CV only if you are clear
            about your project. <br />
            7. Usually Financial companies want employee who has devotion to
            company so during answering questions like “Where do you see
            yourself after 5 years” Don’t mention plans like want to do MBA and
            set up startup. Because they are looking for candidates who can for
            work them for long duration. So answer each question wisely.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:patilfp17.it@coep.ac.in">patilfp17.it@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default falguni_patil;

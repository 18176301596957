import React from "react";

const sayali_patil = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Sayali Vilas Patil</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Barclays</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              Barclays started their aptitude and interview procedures in the
              last week of October. As per official hiring flow there were
              written test, group discussion, Technical interview & HR interview
              but actually there was one written test and one Interview
              (Technical + HR ). Finally 9 students got selected.
            </p>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              Aptitude was quite difficult as compared to other company’s
              aptitude. In Aptitude there were questions about DSA, DBMS (RDBMS
              + MongoDB), Java Threading, CPP OOPS concept, some questions from
              python. All questions were technical. Last section of aptitude was
              coding. There were 2 questions. Exactly I don’t remember the
              questions, but one was based on array manipulation. For coding
              questions using python, java is profitable as you can use inbuilt
              Libraries. If you are using C then it might become very lengthy
              code and sometimes impossible to solve complete question.
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>
              Interview questions are completely different for each student.
              They also depend on interviewer and company. Some companies have
              live coding in interviews, some ask only technical questions. My
              interview was quite simple. There was only one interviewer. First,
              she asked me to explain all the project that I had listed in my
              resume. Based on which she asked at max 3 to 4 questions. After
              that she asked me about my hobbies, where do I live and series of
              HR level questions. Good communication skills and confidence are
              keys to crack interview.
            </p>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <br />
            <p>
              As I mentioned my interview started with project explanation and
              couple of counter questions based on listed project. Other than
              that, they didn’t ask me single technical questions.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              GeeksForGeeks is best website for the preparation. I studied Data
              Structures, Algorithms, OOPs thoroughly from GeeksForGeeks. Some
              of my friends used RS Aggrawal for the Logical Reasoning and
              Quantitative part.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Many companies conduct their aptitude on hackerrank. Solving one
              two coding questions on hackerrank, codechef daily helps a lot. On
              geeksforgeeks, there are company specific questions group, try to
              solve them also.
            </p>
          </p>
          <br />
          <p>
            <i>
              The whole internship period is so hectic & emotional. There comes
              a time when your friends, people from other branches start getting
              selected but you don’t, you start feeling like you are the only
              dump person on this earth. Some days you feel lonely, lost. It is
              ok to cry, it is ok to take break, but it is not at all ok to
              STOP. Internship process is all about luck and efforts you put in.
              So, start preparing and best of luck!!!
            </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            {
              <a href="mailto:patilsv19.comp@coep.ac.in">
                patilsv19.comp@coep.ac.in
              </a>
            }
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default sayali_patil;

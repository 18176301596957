import React from "react";

const atharva_satav = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Atharva Satav
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            ENTC, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            First is an online test that one needs to clear and few top scorers are shortlisted for the interview based on their test performance.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            First we had to clear an online test which consisted of three sections. Section A was English, Section B was statistics and basic financial graph related questions and Section C was coding.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        After clearing the online test we had one interview round in which we were asked both technical and non-technical questions.
                        Along with technical questions one should be also able to answer the non-technical questions in an impressive manner as that portrays your personality, strengths and weaknesses.


                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        20 students were shortlisted for the interview based on their performance in the online test.
                        Then from these 20 students only 10 cleared the interview and were offered an internship at Wells Fargo.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                Leetcode for practicing DSA questions.
                            </li>
                            <li>
                                YouTube videos for learning basics of mern stack.
                            </li>
                            <li>
                                YouTube videos (Apna College) for understanding how to answer non-technical questions in an impressive manner and preparing a resume.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practicing a lot of DSA questions will help you recognise the pattern of questions and the approaches to solve those questions.
                            While practicing problems, irrespective of you being able to solve it or not, look for effective and best solutions to those problems, posted by people on leetcode and try to understand those solutions.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            They ask you about the concepts that you have incorporated in your projects and not much focus is given on extracurricular activities.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Do not neglect the basics of any concept. For example you might be able to solve tough Linked List problems but then they might ask you where and how are linked list used in real world technical applications.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Explore and get your hands dirty on various tech stacks. And don't do this just for the sake of getting an internship and just to burden yourself with work, but do this just to explore something new and enjoy the learning process by doing it with interest.
                        </p>
                    </p>
                    <br />



                </div>
            </section >
        </div >
    );
};

export default atharva_satav;

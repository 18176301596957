import React from "react";

const omkar_oak = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Omkar Oak
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The entire interview process consisted of 3 parts: <br />
                            <ol>
                                <li>
                                    Aptitude round (28th Aug)
                                </li>
                                <li>
                                    PPT (29th Aug)
                                </li>
                                <li>
                                    Interviews (30th Aug)
                                </li>

                            </ol>
                            Wells Fargo was just the third company to visit for internships and placements. The aptitude round was online and the interviews were offline in person.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude round was taken on AMCAT platform. It comprised 3 sections - Verbal reasoning, Business analysis and Coding section (3 questions). Going back on questions was not allowed and each section was timed separately.
                            <br />
                            <ol>
                                <li>
                                    The verbal reasoning section had some vocab based questions and some reading comprehension.
                                </li>
                                <li>
                                    The Business analysis section was lengthy and we had to solve 15 questions in 25 mins. Each question had some data given in tables, candlestick patterns or histograms and we had to make logical decisions based on it. This was the most difficult part of the test due to the time constraint.
                                </li>
                                <li>
                                    The coding section had 3 questions each of leetcode easy to medium level. But the time was only 20 mins per question. Everyone has different questions.
                                </li>
                            </ol>
                            <br />
                            <b>Q1.</b> First question was easy and it was based on String manipulation and character validation - Detecting invalid characters in a string.
                            <br />
                            <b>Q2.</b> Second question was based on 1 dimensional DP and was solved using HashMap and character dp array, but the difficulty was that the data was given as a string and not as a list and so we had to convert it manually into a list.
                            <br />
                            <b>Q3.</b> The third question was about the first non-repeated character in the string which could be solved using HashMap.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There was only one interview which was a Technical + HR interview combined.
                        The interview started with the regular question “tell me something about yourself that is not there on your resume”. Then he proceeded to ask why I was learning Japanese as I had mentioned it in my resume. After that, we went straight into technical questions.
                        <br />
                        <br />
                        <b>Technical (40 mins):</b>
                        <br />
                        He asked extensively about my projects and asked me to explain QuadTree data structure (as it was part of my project). Then he asked me questions on header files, static variables etc. in C language. Then he asked me questions on project deployment, how to handle dependencies and how I will make the local database on my computer accessible to all users (Using some hosting tool). Then he asked some more questions on OOP.
                        For my bc-linux utility project he asked me about the testing I had done before submitting the project and if I have to run millions of test cases on it, then what will be my approach. Then he again asked a few basic CN questions about HTTP and TCP connections and the DNS hierarchical model. Then he asked some questions on Latex as I had mentioned it on my resume.
                        <br />
                        <br />
                        <b>HR (20 mins):</b>
                        <br />
                        After that, he switched to HR type questions - asking about extracurricular activities and college life. Then he asked me what I read/view to keep myself updated about the advancements in technologies. The interview ended with me asking him 2 questions regarding the work responsibilities in the company and in what subjects should I upskill to be better prepared for the role. The entire interview went on for about 1 hr.




                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Total <b>143 students</b> were shortlisted for the aptitude round. From the test, <b>20 students</b> were selected for the Interview process and <b>10</b> were given the final offer.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        For DSA, refer to the Strivers SDE sheet and solve questions on leetcode, GFG regularly. Do not ignore the core CS subjects like CN, DBMS and OS as a lot of questions are also asked based on them (even if these subjects are not covered in college yet).
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practice questions regularly along with proper revision, so that you can detect patterns in similar questions. Practice is the only way. Try to solve leetcode questions consistently.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Most of the questions are asked based on the projects and skills mentioned in your resume. So, include only those projects and skills in your resume for which you are confident to answer difficult questions. Regarding clubs and extracurriculars, I was not asked much.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Wells Fargo assesses not just your technical abilities but also evaluates your English proficiency, data analytics skills, and overall learning approach. Thus, improving on these aspects might help you a lot, not only for WF but for other companies as well.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Be <b>CONFIDENT</b> and communicate your thoughts in a relaxed and optimistic way
                                </li>
                                <li>
                                    Be thorough with your resume and prepare everything that is mentioned on the resume
                                </li>
                                <li>
                                    It is not expected that you have to answer all the questions correctly. But rather your approach to problem solving is being evaluated.
                                </li>
                            </ol>
                            <br />
                            Overall, the internship season is pretty hectic with aptitude rounds popping every now and then. So, initiate your preparation 2-3 months prior to the start of the internship activities for a strong head start. Also, don't be demotivated by failures - you will face rejections but your resilience in bouncing back is what will lead you to the next opportunity.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:oakos21.comp@coeptech.ac.in">
                            oakos21.comp@coeptech.ac.in
                        </a>
                        <br />
                        LinkedIn : <a href="https://www.linkedin.com/in/omkaroak">Omkar Oak</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default omkar_oak;

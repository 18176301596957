import React from "react";

const simran_veer = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Simran Veer
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            One Round(technical+HR).
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Basic Aptitude Question <br />
                            Finance Related Questions <br />
                            Coding Questions(2)

                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Aptitude Round <br />
                        Technical+HR Round: <br />
                        Started with brief introduction ,then asked the following:<br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                DSA project and dbms
                            </li>
                            <li>
                                one of the subject which i studied in my previous semester(mentioned in resume)(TOC,DSA,Microprocessor)
                            </li>
                            <li>
                                my hobbies and interest in field such as finance and psychology
                            </li>
                            <li>
                                extra curricular activities and family background
                            </li>

                        </ul>
                        Ended with questions for them

                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        20 for interview and 10 got the final offer

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Love babbar(dsa cpp),DP and backtracking playlist()
                        edugorilla,indibix(Aptitude round)


                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Try to solve as many leetcode question you can(especially of array and string)
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Regarding projects there were several questions and one to two question regarding club and extra curricular


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Have basic knowledge about finance or chart reading of stocks and all,whatever subjects you mention in resume make sure you have good knowledge about it,do a good and unique dsa project.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            There are times where the situations are very stressful ,make sure you don't lose hope and stay healthy.All the best!
                        </p>
                    </p>
                    <br />



                </div>
            </section >
        </div >
    );
};

export default simran_veer;

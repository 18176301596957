import React from "react";

const shitij_agrawal = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
          Shitij Shailendra Agrawal
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}> Barclays</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <ol style={{paddingLeft:"40px"}}>
              <li>
                Aptitude Round
              </li>
              <li>
                Technical+HR Round
              </li>
            </ol>

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
            The test was conducted on HackerEarth; There are a total of 30 MCQ and 2 programming questions.
            </p>
          </p>

          <p>
            <b>3. How many rounds were there for the company, can you elaborate about each
              round in brief</b>
            <ol>
              <li>
              Aptitude Round:contains a total of 30 MCQs and 2 coding questions, where questions are like,
              <ul>
                <li>
                Find the output of the code (Languages: java, CPP, and python)
                </li>
                <li>
                For a given java function what will be the output for a specific input
                </li>
                <li>
                Give the SQL query for the given problem statement
                </li>
                <li>
                Problems related to OS (Operating System)
                </li>
                <li>
                Problems related to CN (Computer Networks)
                </li>
              </ul>
              </li>
              <li>
                Technical+HR Round:
                <ul>
                  <li>
                  This round was the most interesting. Firstly they asked me to introduce
myself and I told them about my educational background, about the clubs, projects and the companies for which I had worked in the past.Based on my experience they asked me about my experience with
the company, what kind of work I had done for that company, how I got around that company, etc.
                  </li>
                </ul>
              </li>
              <li>
                Techical Part: <br />
                They asked me to build security protocols for the Barclays banking system in India.
              </li>
              <li>
              HR questions they asked
              <ol>
                <li>
                Tell me about your team project
                </li>
                <li>
                The most difficult time for me in my team, etc.
                </li>
              </ol>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            <p>
            72 students were selected after the aptitude test and after that, they again shortlisted 27 students for an interview and finally, 11 students were selected.
            </p>
          </p>

            <p>
              <b>
                5. Some good resources you used in your internship prep
              </b>
              <br />
              Javatpoint - Java, OOP concepts, and Operating System <br />
              GeeksForGeek - Data Structures and Algorithms, Computer Networks
            </p>

            <p>
              <b>
                6. Some tips to crack coding questions
              </b>
              <br />

              <p>
              Be good with Data Structures and Algorithms
              </p>
            </p>

            <p>
              <strong>
                Final Tip for juniors:
              </strong>
              <br />
              Create lots of projects and get involved in different clubs.
            </p>
            
           

            <h3>Contact Info:</h3>
            <div style={{ color: "gray", fontSize: "1.15rem" }}>
              <br />
              Email-ID : <a href="mailto:agrawalss21.comp@coep.ac.in">
                agrawalss21.comp@coep.ac.in
              </a>
              <br />
              LinkedIn : <a href="https://www.linkedin.com/in/shitij-agrawal/">Shitij Agrawal</a>
              <br />
            </div>
        </div >
      </section >
    </div >
  );
};

export default shitij_agrawal;

import React from "react";

const christy_biju = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Christy Biju</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>The structure of the entire process were as follows :</p>
            <ol>
              <li>Aptitude test</li>
              <li>Written Exam</li>
              <li>Group Discussion Round</li>
              <li>Technical + HR Round</li>
            </ol>
            <b>Structure of Aptitude Round :</b>
            <br />
            <p>
              There were in all four to five sections consisting of Technical,
              Finance, English, General Knowledge, Quant and each section had
              approximately 30 to 40 questions
            </p>
          </p>

          <p>
            <b>
              2. How many rounds were there for the company, can you elaborate
              about each round in brief :
            </b>
            <br />
            <p>There were 4 rounds in total:</p>
            <ol>
              <p>
                <li>
                  <b>Aptitude Round :</b>
                </li>
                <p>
                  The aptitude round was of easy to moderate difficulty with a
                  time limit of 2 hrs
                </p>
                <li>
                  <b>Written Test :</b>
                </li>
                <p>
                  5 questions were given and we had to solve any 4 of them.
                  Coding questions and financial questions were asked.
                </p>
                <li>
                  <b>Group Discussion : </b>
                </li>
                <p>
                  In this round, a topic from the list was given to us and we
                  had to discuss it for about 10 minutes. Then each of us was
                  given one topic about which we had to give our own views.
                  There were 7 to 8 students in each group and topics like
                  Metaverse, Digital India were asked and we had to give our
                  opinion on those topics.
                </p>
                <li>
                  <b>Technical + HR round : </b>
                </li>
                <p>
                  In this round, I was asked few technical question related to
                  the skills mentioned in the resume along with some DSA
                  questions. Some questions on projects were asked and my
                  contribution in the group project. Questions on
                  extracurricular activities and my part in different clubs were
                  also asked followed by few general HR questions
                </p>
              </p>
            </ol>
          </p>

          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              After the aptitude round, around <b>50</b> students were selected.
              After the Written test and GD round, <b>20</b> were selected out
              of which <b>14</b> were selected for the final offer.
            </p>
          </p>
          <p>
            <b>
              4. How many questions were asked about projects, clubs and
              extracurricular activities in your resume
            </b>
            <br />
            <p>
              Not a lot of questions were asked on the projects. Question on
              extracurricular activities and position of responsibility of clubs
              were asked
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              For aptitude questions I practiced on Indiabix and referred to RS
              Agarwal book. For coding, I solved 450 series of DSA questions by
              Love Babbar and practiced coding on platforms like LeetCode
              ,GeeksForGeeks and Hackerrank. For OOPs and other core subjects, I
              referred to GFG.
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Stick to one programming language you are confident in preferably
              C++ or Java. Practice as many questions possible and try to solve
              a question with more than one approach. Continuously revise all
              the DSA concepts time to time. First try the brute force approach
              and then optimize the solution.If unable to pass any test cases,
              try to find some edge case and pass the test for it.
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              Good Communication is a must for GD and Interview Rounds. FinIQ is
              a finance based company so you may get work related to the finance
              field. So make sure to try for this company if finance is
              something you’re interested in.
            </p>
          </p>
          <p>
            <strong>8. Final Tip for Juniors :</strong>
            <p>
              Practice coding questions as much as possible and be well versed
              with all the DSA concepts. Also be a bit selective while choosing
              which company you would like to join as once you received the
              accepting letter you can't give further interviews. Be motivated
              throughout the process and don't lose hope as it may be
              disheartening for not getting your dream company. It is necessary
              to remember that a lot of this process is luck based and there
              will be times when you feel that you deserved better or someone
              undeserving got your place. But there is nothing you can do about
              it. All you should focus on is building a good profile and giving
              your best at the aptitude tests and interviews.Just keep faith in
              your preparation and knowledge. All the very best!!
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            LinkedIn Handle :{" "}
            <a href="https://www.linkedin.com/in/christy-biju-32bb22228/">
              Christy Biju
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default christy_biju;

import React from "react";

const yash_suryawanshi = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Yash S Suryawanshi
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Arcesium</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              There were 3 Technical interviews and 1 HR interview. I had all
              the technical interviews on paper. But, most of the other
              shortlisted students had 1 technical interview online.
            </p>
            <ol>
              <li><b>Technical Interview 1</b></li>
              <p>
                The interviewer gave me a question on Linked Lists, particularly
                -{" "}
                <a href="https://leetcode.com/problems/reverse-nodes-in-k-group/">
                  Reverse Nodes in k-Group- LeetCode&nbsp;
                </a>
                I was asked to write the solution on the paper. We talked about
                edge cases and I was asked about the time complexity of the code
                I had written.
                <br />
                Questions on pointer arithmetic, location of storage of
                pointers, stack and heap part of the program (where do they lie
                in memory, size comparison between the two, what limits their
                size), and such were discussed. I was asked about what happens
                exactly when we include (no pun intended) "#include stdio.h" in
                a C program. What will happen if it was included after defining
                the main function instead of on top? Would it make a difference?
                Then I was given some time to ask questions. Make sure you do
                ask questions, it reflects your interest in the firm
              </p>
              <li><b>Technical Interview 2</b></li>
              <p>
                Again I was asked on pointers, particularly, would this program
                compile? If yes, why and what's the output?
                <br />
                char c = 'A';
                <br />
                int *ptr = &c;
                <br />
                printf("%d", *ptr);
                <br />
                After reasoning an answer, he modified the question to
                <br />
                char c = 'A';
                <br />
                void *ptr = &c;
                <br />
                printf("%d", *ptr);
                <br />
                Would this compile and what will be the output? Then I was asked
                about the use of void pointers. Then we shifted to a coding
                question, particularly -
                <a href="https://leetcode.com/problems/longest-increasing-path-in-a-matrix/">
                  {" "}
                  Longest Increasing Path in a Matrix - LeetCode
                </a>
                <br />
                The example he gave was -
                <br />
                11 5 6
                <br />
                12 15 7
                <br />
                10 9 8
                <br />
                Here the longest consecutive path is 5--6--7--8--9--10, so 6. We
                spoke about edge cases and time complexities.
              </p>
              <li><b>Technical Interview 3</b></li>
              <p>
                Again we began with an introduction. I was asked about the
                basics of React (What are components? How can components talk
                with each other?) Then I was asked about one of my projects. I
                had to detail the algorithm I had used. Thereafter I was given a
                coding question, particularly - You have been given an array of
                letters and numbers, you have to make a license plate out of it
                provided -
                <br />
                1. All the letters come first (If there are 3 letters, then the
                first 3 characters in license plate are letters)
                <br />
                2. All the numbers follow the letters thereafter.
                <br />
                Return all the possible license plates that could be formed
                using them (Return a 2D vector containing all such
                combinations).
                <br />
                <b> Sample Input:</b>
                <br />
                A B C
                <br />
                1 2 3 4
                <br />
                <b>All 3!*4! combinations in a 2D vector</b>
                <br />
                <b>Example:</b> ABC1234, CAB4321, BCA2143...
                <br />A different but analogous question - {" "}
                <a href="https://leetcode.com/problems/letter-combinations-of-a-phone-number/">
                  {" "}
                  Letter Combinations of a Phone Number - LeetCode
                </a>
                <br />
                Then I was asked about pillars of OOP, constructors in C++, and
                types of constructors, about Singleton class and its
                implementation. I was asked what happens when an executable (of
                a C/C++ program) created on a different OS and/or compiler is
                run on another OS and/or compiler. Would it run the same or
                would there be a difference?
                <br /> <br />
                <li><b>HR Interview</b></li>
                <p>
                  I was asked on one of my projects and was called to develop a
                  different solution based on the same project. I had to detail
                  factors I need to consider to give a Read Time for an article.
                  Rest, I was asked standard questions. Make sure you stick to
                  the point, and if possible, think about them beforehand.
                  Questions asked included -
                  <ol>
                  <li>What if you're not selected for Arcesium?</li>
                  <li>
                    What is the most challenging project you have worked on
                    so far and what was so challenging about it?
                  </li>
                  <li>
                    What motivates you in your work and makes you give your
                    best?
                  </li>
                  <li>Where do you see yourself 5 years down the line?</li>
                  <li>Why Arcesium?</li>
                  </ol>
                </p>
              </p>
            </ol>
          </p>
          <p>
          <b>2. Structure of Aptitude Round :</b>
            <br />
            <p>
              The aptitude round consisted of 3 sections - Quant, Technical,
              Coding.
            </p>
            <ol>
              <li>Quant: 15 questions | 20 minutes</li>
              <li>Technical: 15 questions | 15 minutes</li>
              <li>Coding: 2 questions | 45 minutes</li>
              <p>
                1. In the first problem we had to return the second most maximum
                product from 2 unsorted arrays by picking a number from each
                <br />
                2. The second problem was on chocolates There are 2 varieties,
                dark and white. We are given an N*2 grid of mold where
                chocolates could be kept and the number of white chocolates to
                be placed. White chocolates cannot lie in adjacent columns. We
                have to return the number of distinct ways to fill the mold.
              </p>
            </ol>
          </p>
          <p>
            <b>3. How many students were shortlisted after each round?</b>
            <p>
              <b>Aptitude Round: </b> 15 were shortlisted
              <br />
              <b>Technical 1: </b> Out of 15, 10-12 were shortlisted.
              <br />
              <b>Technical 2: </b> Out of 6-8. 2 were shortlisted.
              <br />
              <b>Technical 3 and HR round: </b> Both students were selected.
            </p>
          </p> 
          <p>
            <b>
              4. How many questions were asked about projects, clubs and
              extracurricular activities in your resume ?
            </b>
            <br />
            <p>
              Not many. I have detailed it in my 3rd Technical and HR
              interviews. For clubs, they didn’t ask many questions. I was asked
              what was my role in the club and the general purpose of the club
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              <ol>
                <li>
                  <b>DSA</b> - 450 DSA Cracker | GfG Practice | Leetcode |
                  Striver and Aditya Verma
                </li>
                <li>
                  <b>Aptitude</b>- Indiabix | Edugorilla
                </li>
              </ol>
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Practice regularly and time yourself on the questions. Track your
              progress. Revisit/revise questions as needed
            </p>
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              <ol>
                <li>Mock tests are available for Arcesium on Edugorilla</li>
                <li>Go through GfG archives for Arcesium</li>
              </ol>
            </p>
          </p>
          <p>
            <strong>8. Final Tip for Juniors :</strong>
            <p>
              <ol>
                <li>
                  Make sure you're thinking out loud and go back to the
                  basics to try to reason the answer
                </li>
                <li>Be tidy and thorough with your paper work</li>
                <li>Don’t Panic</li>
                <li>
                  Don’t think that you need to have extravagant projects.
                  Make sure the projects reflect a part of you and you’re
                  excited about them
                </li>
                <li>
                  Find out what works best for you from all the advice given
                  by others
                </li>
                <li>
                  The process is heavily dependent on luck. Acknowledge and
                  accept that and go with the same grit and determination for
                  the next rounds/interviews.
                </li>
              </ol>
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID:
            <a href="mailto: suryawanshiys20.comp@coep.ac.in">
              suryawanshiys20.comp@coep.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default yash_suryawanshi;

export const data = [
  {
    title: [
      "7",
      "Congratulations to all the Contributors of",
      "CSI Adyayan (July - Sept 2020)",
    ],
    content: [
      `An Introduction to PyCUDA using Prefix Sum Algorithm Mayank V.
            Jain, Manas M. Joshi & Amit D. Joshi pp. 1-7`,
      `CuPy: A Brief Overview Aayush Shah, Rushikesh Bedagkar & Amit D.
            Joshi pp. 8-12`,
      `GPU Accelerated Multiple Regression using cuML Snehal Jaykar,
            Tejaswini Kokate, Amit D. Joshi & Suraj T. Sawant pp. 13-17`,
      `Parallel Computing: A PyOpenCL Approach Mayuri A. Karvande,
            Shiwani S. Bayas, Amit D. Joshi & Suraj T. Sawant pp. 18-23`,
    ],
  },
  {
    title: [
      "8",
      "Congratulations to all the Contributors of",
      "CSI Communications (November 2020)",
    ],
    content: [
      `Generative Adversarial Networks ( GAN ): An overview
      Prathamesh V. Barve, Amit D. Joshi
      pp. 36-38`,
    ],
  },
  {
    title: [
      "9",
      "Congratulations to all the Contributors of",
      "CSI Adyayan (April - June 2020)",
    ],
    content: [
      `An Introduction to GPU Accelerated Machine Learning Algorithms using cuML
      Prathamesh V. Barve, Aarushi S. Phade, Amit D. Joshi
      pp. 5-8`,
      `A Framework for Distributed Machine Learning
      Eesha Kurode, Pranav Joglekar, Shivam Hire, Amit D. Joshi
      pp. 42-45`,
      `Generating 2D Julia Set Fractals using CUDA
      Jugal Rawlani, Amit D. Joshi
      pp. 46-50`,
      `Accelerate code with OpenACC
      Aniket Zope, Sahil Sawant, Dhruv Kudale, Amit D. Joshi
      pp. 69-74`,
      `GPU Computing using OpenCL
      Dhruv Kudale, Amit D. Joshi
      pp. 75-81`,
      `Ad Hoc Bluetooth Communication System
      Naishadh Vora, Aayush Shah, Umang Sakhare, Amit D. Joshi
      pp. 118-120`,
    ],
  },
];

import React from "react";

const ankita_vaid = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Ankita Vaid</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Arcesium</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Information Technology, Batch of '21
            </p>
          </div>
          <br />

          <b>1. What was the structure of the entire interview process?</b>
          <p>
            First we had presentation of company, followed by aptitude test on
            same day. And then they shortlisted 14-15 students based on aptitude
            test. For interview we had first technical round , then HR round and
            again final technical round. Both technical rounds were of almost an
            hour.
          </p>

          <b>2. What questions were asked in the aptitude test?</b>
          <p>It consists of three sections:</p>
          <ul>
            <li>
              <em>Logical reasoning</em> part which was pretty easy. There were
              almost 15 questions with negative marking, practice from R.S
              aggarwal.
            </li>
            <li>
              <em>Technical MCQ’s</em>: there were some questions to predict
              output, some based on oops concept, and some related to the
              syllabus like mutex and semaphores.
            </li>
            <li>
              <em>Coding part</em>: This is most important part. We had two
              coding questions to be solved in 45 minutes which was pretty less.
              I won’t exactly remember the questions but one question was based
              on Dynamic programming for sure and other was on strings.
            </li>
          </ul>

          <b>3. How many interview rounds were there for the company?</b>
          <p>Three rounds:</p>
          <ul>
            <li>technical round</li>
            <li>HR round</li>
            <li>technical round</li>
          </ul>

          <b>4. What sort of questions were asked in the interviews?</b>
          <p>
            Questions in the interview were mostly on data structures and
            algorithms, like 75%, majorly linked lists, trees, arrays, dynamic
            programming, then followed by oops concepts like virtual functions,
            polymorphism, etc, some puzzles and also general database questions.
            <p>
              <h5>Technical Round I: (Approx 50-55 min)</h5>
              <p>
                It started with introduction and some sort of informal
                discussion. Then they asked me about my DSA project. For
                project, they are not interested in logic part but it is mostly
                on Why have you chosen that project? What is the further scope?
                What you have done unique in your project? After the discussion
                on project, they went to the technical questions:
              </p>
              <ul>
                <li>
                  Write code for pow function? They are interested in most
                  optimal soution as well as the approach
                </li>
                <li>How to find loop in linked list?</li>
                <li>
                  How to delete the node in singly linked list with no head and
                  tail pointer, only pointer is pointing to particular node?
                </li>
                <li>Two questions based on binary search tree</li>
                <li>Application of trie data structure.</li>
                <li>Four pillars of OOPs</li>
                <li>Use of virtual functions in polymorphism</li>
                <li>What is inline functions?</li>
                <li>
                  One puzzle was asked whose logic was based on loop question.
                  Solving puzzles will impress them :{" "}
                </li>
              </ul>
            </p>
            <p>
              <h5>HR Round: 10-15min</h5>
              <ul>
                <li>Introduce yourself</li>
                <li>Where do you see yourself in 5 years</li>
                <li>About family and place, etc </li>
              </ul>
            </p>
            <p>
              <h5>Technical round-II: 45 min approx</h5>
              <ul>
                <li>Difference between mutex and semaphores</li>
                <li>What is deadlock?</li>
                <li>
                  Given a linked list of n elements, reverse the three elements.
                </li>
                <li>
                  Given a array of integers, positive and negative, find the
                  maximum sum.
                </li>
                <li>One puzzle</li>
              </ul>
            </p>
          </p>

          <b>5. How many questions were asked based on the projects done?</b>
          <p>
            Frankly speaking, I had two projects in my resume and they started
            with my data structures course project, compression algorithms. They
            want to know why you have choosen this project, what you have done
            something new and what you can do more to make it better, etc . They
            aren’t interested in your code and algo. In my interview process,
            project was just the starting point of technical conversation.
          </p>

          <b>6. How many questions were based on the resume?</b>
          <p>
            So, every interview starts by introduction about yourself and they
            ask something about CV which they find typically interesting or new
            among candidates. In my case, it started with CSI. Like what is CSI,
            your role, what you guys do, your further plans and goals, etc. so
            here they check out on your extracurricular.
          </p>

          <b>
            7. What are some good resources used by you in your preparation?
          </b>
          <p>
            Geeks for geeks is best platform. I covered almost all algorithms
            from gfg, followed by some course materials, and interview
            experiences. Hackerrank platform to practice competitive programming
            and R.S aggarwal for aptitude test.
          </p>

          <b>8. Some tips to crack the coding questions.</b>
          <p>
            <em>Practice, practice and practice.</em> And also while solving the
            questions if you are unable to solve it completely atleast write the
            code and pass few test cases.
          </p>

          <b>
            9. Is there something specific that you would want to tell people
            who try for the same company?
          </b>
          <p>
            I suggest that just preparing for technical interviews is not
            enough. Focus on aptitude preparation also beacause there are almost
            200 student for apti and tese companies select just 15-20. almost
            10%.
          </p>
          <p>
            The companies coming in campus mostly focus on your basics of
            computer science rather than projects and all.
            <br />
            If you guys feel that you haven’t done summer internship in your
            second year and most of your friends have done it, so its ok. Dont
            panic about that. You utilize that summer for your preparation and
            get the best of further opportunities. While interview, be confident
            but not overconfident , ask them doubts about company, etc etc.
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:ankitama.it@coep.ac.in">ankitama.it@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ankita_vaid;

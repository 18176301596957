import React from "react";

const apurva_haspe = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Apurva Haspe
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>mastercard</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            The interview was a 3 rounds process ( 2 technical + 1 HR )
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                        It was an online aptitude test conducted on HirePro.<br/>

First section had basic Aptitude questions covering concepts like profit and loss and some pattern based questions.<br/>

Second half was the coding section. It had 2 questions one of it was a DP and the other was a basic string manipulation.
                            <br />
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        1st technical round was majorly based on my project discussion. The interviewer liked the project idea and hence held interest in knowing about the functionalities, tech stack, algorithms used and also the future scope and limitations. He also asked a few python based questions.
                        <br />
                        2nd technical round was majorly based on OOPs concepts for me. The questions were to check if I clearly understood the 4 pillars and differences between them. Some memory-management questions as well and was told to write a very basic code in C to check syntax.
                        <br />
                        3rd round was the HR round that includes questions to check your confidence and language. Questions included introduction, failures and learnings from college, family introduction, and why mastercard.

                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Post aptitude test almost 60-65 students were shortlisted
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        DSA in C by Abdul bari to clear my concepts and leetcode for hands on coding.
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practise as much as you can daily and stick to one language that you are thorough with.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            Nothing during the technical rounds however good amount was asked during the HR round
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            I would say, they check your attitude, problem-solving abilities and your eagerness to learn new things so, that should be portrayed well during the interview.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Be confident and drive your interview in the direction that you want meaning only speak about things that you have good knowledge about and can answer further questions on.
                            <br />Also, do not panic take it easy on yourself :)
                        </p>
                    </p>
                    <br />

                </div>
            </section >
        </div >
    );
};

export default apurva_haspe;

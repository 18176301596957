import React from "react";

const deepika_goyal = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Deepika Goyal
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Goldman Sachs
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />
          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            The internship hiring process was structured in 2 phases i.e.
            aptitude tests and interviews which further usually consist of two
            to three rounds.
            <br />
            Goldman Sachs was the first company to visit our college. An
            aptitude test was scheduled on 27th August followed by interview
            rounds on 31st August 2020.
          </p>
          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test was conducted on HackerRank Platform which
            consisted of 5 sections, i.e.
            <br />
            <div style={{ marginLeft: "2em" }}>
              <b>Coding Section -</b> 2 Questions of Easy/Medium Difficulty
              level (30 minutes)
            </div>
            <div style={{ marginLeft: "2em" }}>
              <b>Quantitative Section -</b> Around 8 questions with negative
              marking (25 minutes)
            </div>
            <div style={{ marginLeft: "2em" }}>
              <b>Technical Section -</b> MCQs based on CN, CO, Data Structures
              with negative marking (20 minutes)
            </div>
            <div style={{ marginLeft: "2em" }}>
              <b>Coding Section -</b> 1 Question of Medium/Hard Difficulty level
              (45 minutes)
            </div>
            <div style={{ marginLeft: "2em" }}>
              <b>Essay Section -</b> 2 Essays
            </div>
            To qualify for this round, you need to clear cut-offs in at least 3
            sections.
            <br />
            For Quantitative Section, you can use GFG or R.S. Aggarwal for
            preparation.
            <br />
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            After the aptitude test, a total of 27 students were shortlisted for
            the interview rounds. All the rounds were held online on the Zoom
            interface.
            <br />
            <div style={{ marginLeft: "2em" }}>
              <b>Round 1 -</b> Technical Round
            </div>
            <div style={{ marginLeft: "2em" }}>
              <b>Round 2 -</b> Technical Round
            </div>
            <div style={{ marginLeft: "2em" }}>
              <b>Round 3 -</b> Technical and HR Round
            </div>
          </p>

          <p>
            <b>4. What sort of questions were asked in the interviews?</b>{" "}
            <br />
            <i>Round 1 (Technical Round)</i>
            <br /> In this round, I was given a coding question on HackerRank
            based on array manipulation. Initially, the interviewer asked me
            about the approach which I was going to use. So I passed all the
            test cases, but the solution suggested by me was not an optimal one,
            to which she asked me to improve. After thorough thinking, I arrived
            at one of the optimal solutions. After this, another question based
            on Binary tree was given. After the coding questions, my interviewer
            asked me about some technical concepts based on OOPs and data
            structures. This round lasted for about 30-35 minutes. <br />
            <br />
            <i>Round 2 (Technical Round)</i>
            <br /> About 15 students were shortlisted for this round. My
            interview started off with some quantitative and logical questions.
            Later I was asked questions about every project mentioned in my
            resume. Questions like which technology I worked with and questions
            related to that or about some specific algorithms used in projects.
            Also, he asked me the reason why I chose to work on these particular
            kinds of projects. A small tip, be very smart to select which
            projects are to be written in your resume. It is like the most
            important section, which any interviewer would like to focus on.
            This round lasted for around 20-25 minutes.
            <br />
            <br />
            <i>Round 3 (Technical and HR Round)</i>
            <br /> In this round, initially I was asked about myself, and my
            interest in some particular domain. Later few basic technical
            questions followed by designing of Data Structure for a particular
            problem. After that, a puzzle was given. Initially, it was getting
            difficult to reach the solution. So I took time to understand it and
            kept suggesting whatever came to my mind, at that point in time and
            reached the final solution. Later few questions were asked based on
            what I wrote in my resume, and the interview round ended with sheer
            relief. A small piece of advice, don't hesitate to ask your
            interviewer for time for solving problems and do clear your doubts
            regarding it, by asking questions to them. Keep it interactive.
            <br />
          </p>

          <p>
            <b>5. How many questions were asked based on the projects done?</b>{" "}
            <br />
            So basically my second round was mostly based on projects.
            Initially, he asked me to elaborate on each and every project I
            mentioned on my resume, and accordingly, he asked questions related
            to them. My interviewer also asked me why this particular technology
            I prefer and what makes my project different. Also, what was the
            inspiration to select the topic/domain.
          </p>

          <p>
            <b>6. How many questions were based on the resume?</b> <br />
            During this interview, I realized, the importance of the "Project"
            section in resume. It appeared to me as if other sections were
            pretty much ignored while evaluating us (like volunteer experience
            or some club activities). Please note, it might not be the case with
            everyone. So be careful and thoughtful while writing your resume.
          </p>

          <p>
            <b>
              7. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            I'll say referring to GeeksForGeeks is mandatory. Also, I referred
            HackerRank/LeetCode for solving questions. Heavy practice is
            required like you need to put hours in it. Do pick a language, my
            suggestions are (C++/Java)
          </p>

          <p>
            <b>8. Some tips to crack the coding questions.</b> <br />
            One suggestion from my side would be that, read the coding questions
            properly first, and make a map like imagination of the problem in
            your mind. It might appear, that the question is pretty difficult at
            first, so try breaking down it into small problems, and start
            solving it (this might help to pass few test cases at least), and
            remember that each test case does have some weightage.
          </p>

          <p>
            <b>
              9. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            During the interview, makes sure it’s interactive, do ask genuine
            questions, and when any coding problem is given, keep suggesting
            solutions (even if you are not able to reach the final one) as it
            helps them evaluate us on our thinking process, don’t just be
            silent. Also, good communication skill is needed. Be confident in
            what you speak.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="goyald18.comp@coep.ac.in">goyald18.comp@coep.ac.in</a>
            <br />
            <a href=" https://www.linkedin.com/in/deepika9294/">
              {" "}
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default deepika_goyal;

import React from "react";

const jia_johnson = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Jia Johnson
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>D.E Shaw</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There were two technical interviews and one HR interview.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            There were three sections coding, technical and mathematics. For coding round, one question was given to be solved. The technical sections consisted of questions on networks, operating system, dbms and OOPS.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ol>
                            <li>
                                <b>Technical Interview 1:</b> I was asked questions based on java, python and c programming.They asked me to explain my thought process and the logic behind the code I wrote for aptitude test problem. I was also asked questions on OOPS here and was made to solve few puzzles.
                            </li>
                            <li>
                                <b>HR Round:</b> The first technical round was followed by the HR round. In the HR round, they asked why I wanted to join the company and which role in the systems department excited me the most.They also asked a few scenario-based questions and others based on what I had written on my resume.
                            </li>
                            <li>
                                <b>Technical Round 2:</b> In this round, I was asked about my projects, including why I chose a particular tech stack over other options and why I chose certain data structures for my DSA projects. They asked me to explain the database design of one of my projects and questioned me on SQL and DBMS related to that design. They also asked me about networks, starting with questions from the aptitude round. At the end, I was given a puzzle to solve.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                For the aptitude round, 102 students were shortlisted.
                            </li>
                            <li>
                                For the interview, 15 students were shortlisted.
                            </li>
                            <li>
                                For the interview, 15 students were shortlisted.
                            </li>
                            <li>
                                After the final round 2 students were offered the internship.
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                Strivers DSA Questions
                            </li>
                            <li>
                                Gate smashers for OS,DBMS and CN
                            </li>
                            <li>
                                Aditya Verma for DP
                            </li>
                            <li>
                                Leetcode
                            </li>
                            <li>
                                Kunal Kushwaha OOPs playlist
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practice different types of questions. After solving a problem look if there are other optimal approaches. Be consistent and revise whenever possible.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            A good time was spent on projects in technical round 2. Questions related to clubs and extracurricular were asked in the HR round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Answer confidently during the interviews. If you do not know the answer to a question, it is okay to say that you do not know. Try to at least learn the basics of DBMS, OS, and CN, even if they are subjects for upcoming semesters. Only include projects on your resume that you know very well and can answer any questions about. Before the interview, review common interview puzzles from GeeksforGeeks. Also, pay attention to the presentation given by the company, as I was asked questions based on it in the HR round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            It’s alright to feel a little overwhelmed during your preparation journey.Be consistent practice questions, revise regularly .Don’t worry, you’ll do great!
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:jiaj21.comp@coeptech.ac.in">
                            jiaj21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default jia_johnson;

import React from "react";

const manas_nighrunkar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Manas Nighrunkar
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Nuance</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            It was a short process comprising of -
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li>Resume Shortlisting (25 students were selected)</li>
              <li> 1 Tech Interview</li>
              <li>1 HR Interview </li>
            </ul>
            Finally 10 students were selected.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            There was no aptitude test due to some company issues. Generally
            there are 3 sections:
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li>
                Technical - Questions based on C/C++, DSA, OS, DBMS and some
                code snippets
              </li>
              <li>
                Coding - 2 coding questions which can be solved in any language
              </li>
              <li>Quant/Aptitude - Logical, Maths (interest, distance, etc)</li>
            </ul>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li class="has-line-data" data-line-start="39" data-line-end="42">
                <i> Tech Round</i>
                <br />
                It started with DSA questions on arrays, linked list and graphs.
                They looked through my CV thoroughly, and started with questions
                on my projects.
              </li>
              <br />
              <li class="has-line-data" data-line-start="42" data-line-end="46">
                <i> HR Round</i>
                <br />
                They asked questions like tell us about yourself, any
                co-curricular activities and team experience. This was to assess
                your communication skills and see how you behave.
              </li>
            </ul>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>
            <br />
            <p class="has-line-data" data-line-start="48" data-line-end="51">
              Most of the interview was based on projects only. They wanted
              AI/ML projects and asked for process behind layers in ML and some
              situation based on projects. Also, the searching algorithm of AI
              was asked.
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <p class="has-line-data" data-line-start="54" data-line-end="56">
              I practiced coding questions on{" "}
              <a href="https://hackerrank.com">Hackerank</a> and{" "}
              <a href="https://codechef.com">Codechef</a>, and for theory{" "}
              <a href="https://geeksforgeeks.com">GFG </a>
              is the best. They are some “Interview tips” available reading that
              also helps a lot.
            </p>
          </p>

          <p class="has-line-data" data-line-start="57" data-line-end="58">
            <strong>6. Some tips to crack the coding questions.</strong>
            <p class="has-line-data" data-line-start="59" data-line-end="64">
              Start your preparation from summer holidays, practice lots of
              coding questions on CodeChef/HackerEarth/HackerRank. Try to solve
              questions in C++, Java or Python only. Do not look for efficient
              code at the start, your main objective is to be clearing all test
              cases, and then try for better efficiency of code. Also, read the
              language of the question very carefully.
            </p>
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            For Nuance, if you are interested in machine learning or artificial
            intelligence, this is the perfect company for you. They only look at
            candidates with prior knowledge in this domain. Basically, they
            won’t look for HR quality as much but technical is a must.
          </p>

          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:nighrunkarmanas@gmail.com">
              nighrunkarmanas@gmail.com
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default manas_nighrunkar;

import coordinator from "../../img/team/2018/coordinator.jpg";
import eventshead from "../../img/team/2018/eventshead.jpg";
import hod from "../../img/team/2018/hod.jpg";
import jteventshead from "../../img/team/2018/jteventshead.jpg";
import jtoperationshead from "../../img/team/2018/jtoperationshead.jpg";
import jtoutreachhead from "../../img/team/2018/jtoutreachhead.jpg";
import jttechlead from "../../img/team/2018/jttechlead.jpg";
import jttreasurer from "../../img/team/2018/jttreasurer.jpg";
import operationshead from "../../img/team/2018/operationshead.jpg";
import outreachhead from "../../img/team/2018/outreachhead.jpg";
import president from "../../img/team/2018/president.jpg";
import techlead from "../../img/team/2018/techlead.jpg";
import treasurer from "../../img/team/2018/treasurer.jpeg";
import vicepresident from "../../img/team/2018/vicepresident.jpg";

const team2018 = [
  {
    post: [
      {
        initial: "Dr. (Mrs)",
        name: "Vahida",
        surname: "Attar",
        desc:
          "Head, Department of Computer Engineering and Information Technology",
        image: hod,
        mail: "mailto:hod.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/vahidacompcoepacin",
      },
      {
        initial: "Mr.",
        name: "Amit",
        surname: "Joshi",
        desc: "CSI COEP Student Chapter Coordinator",
        image: coordinator,
        mail: "mailto:adj.comp@coep.ac.in",
        about: "https://www.coep.org.in/mycoep/adjcompcoepacin",
      },
    ],
  },
  {
    post: [
      {
        name: "Naman",
        surname: "Modi",
        position: "President",
        image: president,
        linkedin: "https://www.linkedin.com/in/naman-modi/",
        github: "https://github.com/naman-modi",
      },
      {
        name: "Mayank",
        surname: "Jain",
        position: "Vice President",
        image: vicepresident,
        linkedin: "https://www.linkedin.com/in/mayank-v-jain/",
        github: "https://github.com/mayank-02",
      },
    ],
  },
  {
    post: [
      {
        name: "Aayush",
        surname: "Shah",
        position: "Technical Lead",
        image: jttechlead,
        linkedin: "https://www.linkedin.com/in/shahaayush1999/",
        github: "https://github.com/shahaayush1999",
      },
      {
        name: "Naishadh",
        surname: "Vora",
        position: "Technical Lead",
        image: techlead,
        linkedin: "https://www.linkedin.com/in/naishadhvora/",
        github: "https://github.com/naishadh14",
      },
      {
        name: "Tejaswini",
        surname: "Kokate",
        position: "Treasurer",
        image: treasurer,
        linkedin: "https://www.linkedin.com/in/tejaswini-kokate-814746175/",
        github: "https://github.com/tejaswinikokate",
      },
      {
        name: "Falguni",
        surname: "Patil",
        position: "Treasurer",
        image: jttreasurer,
        linkedin: "https://www.linkedin.com/in/falguni-patil-860930174/",
        github: "https://github.com/Falguni99",
      },
      {
        name: "Ved",
        surname: "Dandekar",
        position: "Operations Head",
        image: operationshead,
        linkedin: "https://www.linkedin.com/in/ved-dandekar-91667a150/",
        github: "https://github.com/veddandekar",
      },
      {
        name: "Jagruti",
        surname: "Jethwani",
        position: "Operations Head",
        image: jtoperationshead,
        linkedin: "https://www.linkedin.com/in/jagrutijethwani/",
        github: "",
      },
      {
        name: "Umang",
        surname: "Sakhare",
        position: "Outreach Head",
        image: outreachhead,
        linkedin: "https://www.linkedin.com/in/umang-sakhare-a9a30b173/",
        github: "https://github.com/umang-sakhare17",
      },
      {
        name: "Ankita",
        surname: "Vaid",
        position: "Outreach Head",
        image: jtoutreachhead,
        linkedin: "https://www.linkedin.com/in/ankita-vaid-a73156177/",
        github: "https://github.com/ANKI1905",
      },
      {
        name: "Eesha",
        surname: "Kurode",
        position: "Events Head",
        image: eventshead,
        linkedin: "https://www.linkedin.com/in/eesha-kurode/",
        github: "https://github.com/eesha-k",
      },
      {
        name: "Yash",
        surname: "Gangwar",
        position: "Events Head",
        image: jteventshead,
        linkedin: "https://www.linkedin.com/in/yashgangwar/",
        github: "https://github.com/YashGangwar",
      },
    ],
  },
];

export default team2018;

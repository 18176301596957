import React from "react";
import coding1 from "./coding/coding1.png";
import coding2 from "./coding/coding2.png";

const sanmeet = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
          Sanmeet Ramnath Wakchaure
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
            Whole hiring process finished off in one day. Initially there was a short pre-placement talk in which they discussed about the company (their business model, job scope, etc.) and gave information about hiring process. 
           <ol>
            <li>
            <b>Round 1 :</b> Filling up an excel sheet about personal and academic details

            </li>
            <li>
        <b>Round 2 :</b> Group Discussion (GD)

            </li>
            <li>
            <b>Round 3 :</b> Technical Round

            </li>
            <li>
            <b>Round 4 :</b> General Interview

            </li>

           </ol>
            </p>
            <p>
                <li>
                    <strong>Group Discussion:</strong>
                    Students were divided into groups. They gave us 9 topics beforehand for preparation, out of which gave one topic for discussion. And the topic for our group was – “What matters in professional success in early career, A. Details or B. Big picture?”. It was 10-15 minutes open discussion where all students could discuss/debate on that topic (Even they allowed little arguments also). After that they asked us to select one topic other than the one which they gave earlier for discussion. So, one of us selected the topic – “India will benefit more from Smaller Kerala/Tripura sized smaller states or UP/MP sized large states, if its wants to progress faster?”. But this time, they asked our opinion about the topic one by one (Not open discussion). Once this got over, they asked us to describe about our favourite personality (which we mentioned in the excel sheet in Round 1) in just 3 lines. Basically, it was personal opinion round.
                    <p>
                        <b><i><u>My suggestion for GD</u></i></b>
                        <br/>
                    Don’t seat quiet. Speak confidently what you think. Once they start discussion, you can take a group lead to initiate discussion, this becomes a plus point for you.


                    </p>

                </li>
                <br/>
                <li>
                    <strong>Technical Round :</strong>
                    This round had 2 questions. Both questions were based on array (2-D array) and loops. We had to solve them on paper during the G-meet keeping video-camera on. They gave us liberty to write either code or pseudocode. Also there was no programming language barrier. We were supposed to submit our solutions within time on their mail id.
                    <p><ol>
                        <li><b>Question 1:</b>
                        <br/>
                        There is a tournament of some sport with participating teams A,B,C and D. Each team has competed with each other team and their scores in respective matches is shown in below image. You have to take input of scores (score pairs) from user in the order given in image and print the score pairs in the tabular format as shown. Output is a 5 x 5 grid, where first row and first column contains team names (A,B,C,D) and in remaining grid, you have to print score pairs in correct order. i.e. if score pair (A,B) is (3,0), then print “3,0” in the cell with row A and column B and print “0,3” in the cell with row B and column A and so on. For diagonal entries, print hyphen (-).
                        <br/>
                        <p>
                        <img src={coding1} alt="coding1"/>
                        </p>
                       
                            </li>
                            <li><b>Question 2:</b>
                        <br/>
                        Print atomic numbers of the elements as per their position in Periodic Table (see below image) and print “0” if element is not present in that position. (They told us to print first 4 or 5 rows of Periodic Table).
                        <br/>
                        <p>
                        <img src={coding2} alt="coding2" />
                        </p>
                     
                        There was no that much focus on DSA throughout the interview.
                       
                            </li>
                           
                           
                    </ol>
                   
                    </p>
                    
                </li>
                <li>
                    <strong>General Interview:</strong>
                    This was a short interview (for 10-15 mins). First they asked me to introduce myself (prepare beforehand for this question). After my introduction, they saw my resume and asked questions on the projects that I mentioned there. At last they told me to ask any questions/queries if any. It was friendly discussion with interviewers.


                </li>
            </p>
            
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
            No Aptitude Test.
          </p>
          </p>

          <p>
            <b>3. How many questions were asked based on the projects and on resume?</b>
            <p>
            They didn’t ask deeply on projects that I mentioned in the resume. Just asked to give overall idea (in general) about the projects. Apart from projects, nothing else was asked on resume.
            </p>
            
          </p>

          <p>
            <b>
              4. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
            For this particular company, basic DSA knowledge will be sufficient (No need of deep DSA concepts). Normal programming skills and in general mathematical knowledge (school level) will be sufficient.

            <p>For other companies, I would recommend –</p>
            <p>
                For aptitude test:<a href="https://www.indiabix.com">https://www.indiabix.com</a>
                   <br/>
               
                Love Babbar's 450 DSA sheet: <a href="https://drive.google.com/file/d/1FMdN_OCfOI0iAeDlqswCiC2DZzD4nPsb/view">450 DSA sheet</a>
            </p>
            <p>For other Core subjects (OS, DBMS, CN, OOPS) 
                <br/>
                GFG: <a href="https://www.geeksforgeeks.org">https://www.geeksforgeeks.org</a>
            </p>
            <p>
            Have a look at Love Babbar’s Youtube channel - 
            <a href="https://www.youtube.com/channel/UCQHLxxBFrbfdrk1jF0moTpw">Love Babbar</a>
            </p>

            </p>
          </p>

          <p>
            <b>
              5. Some tips to crack the coding questions.
            </b>
            <br />
            
            <p>
            For this company, basic programming knowledge (like arrays, strings, loops, functions, etc) would be enough.


            </p>
          </p>

          <p>
            <b>6. Is there something specific that you would want to tell people who try for the same company?</b> <br />
            <p>
            You need to have good communication skills for GD. Deliver your thoughts confidently. Keep your academic cgpa as good as possible, because it may add value to your performance. Prepare beforehand for some common questions like your introduction, strengths and weaknesses, your future plans, etc.

            </p>
          </p>

         
          <br />
          <p>
            <i>
            Everybody goes through failure in their life at different points of time. It is okay to accept that you may have failed. This does not mean that you have lost everything. It could be the stepping stone towards success. Accepting and owning your mistakes will help you move forward and push in the right direction. You can learn from it and become better, which will help you crack another interview in the future.

            
            </i>
          </p>
          <p>
            <i>
            One important thing is, This company is more oriented to finance sector. So, at the end the tasks given by them will be some way related to finance. If you are interested in finance, then surely try for this company. You will learn a lot.
            <p> All the best for your future endeavours!!</p>

            
            </i>
          </p>
         
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:wakchauresr19.comp@coep.ac.in ">
            wakchauresr19.comp@coep.ac.in 
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default sanmeet;

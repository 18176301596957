import React from "react";

const vedang_khedekar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Vedang Prashant Khedekar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Siemens</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There were two rounds: <br />
                            <ol>
                                <li>
                                    Round 1 - Aptitude Round
                                </li>
                                <li>
                                    Interview Rounds
                                    <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                        <li>
                                            Technical + HR Round
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude test was conducted in online mode on Mercer Mettl platform <br />
                            Duration of aptitude : 60 mins

                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Total 2 rounds including the aptitude round <br />
                        <ol>
                            <li>
                                First round was Aptitude Round <br />
                                <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                    <li>
                                        Quants - 10 Questions
                                    </li>
                                    <li>
                                        English - 10 Questions
                                    </li>
                                    <li>
                                        Logical  - 10 Questions
                                    </li>
                                    <li>
                                        Computer Fundamentals 25 Questions
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Second Round : Technical +HR Round <br />
                                The interview mainly focused on my resume. The interviewer asked many questions about my project and also asked me to write an SQL query. Since I mentioned Java in my resume, there were numerous questions on Java as well. They wanted to know about my career goals and aspirations. The interviewer asked about my role and responsibilities in my previous projects. The interviewer asked about my role and responsibilities in my previous projects. Additionally, the interviewer asked typical HR questions and inquired about my personal life.
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Around 50 students were shortlisted for Aptitude Test <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                After 1st Round - 13
                            </li>
                            <li>
                                After 2nd Round - 10
                            </li>
                        </ul>

                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                GFG last minute notes on OS, CN, DBMS
                            </li>
                            <li>
                                For Recursion, DP and Graph - Take U Forward Series
                            </li>
                            <li>
                                Striver’s SDE sheet
                            </li>
                            <li>
                                Leetcode
                            </li>
                            <li>
                                Aptitude questions on Indiabix
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            The key to mastering coding questions is focusing on the quality of practice, not just the quantity. Working on well-thought-out problems regularly is essential for success. This approach helps improve your skills and builds confidence.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Many questions related to extra-curricular and co-curricular activities were asked , as they help the interviewer to judge your overall personality.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Stay positive and try for the best  because the initial 1 month of internship phase is very exhausting mentally. You may feel like not attempting for some companies due to frustration but try to stay calm. Always be confident in a interview that's the most important thing. You may not know few answers but if you handle the situation properly you can still get selected.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:khedekarvp21.comp@coeptech.ac.in">
                            khedekarvp21.comp@coeptech.ac.in
                        </a>
                        <br />

                        LinkedIn : <a href="https://www.linkedin.com/in/vedang-khedekar-30571322a/">Vedang Khedekar</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default vedang_khedekar;

import React from "react";

const anushka_tadphale = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Anushka Tadphale
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '23
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              There was an initial screening of applications. We were asked to
              fill an excel sheet consisting various questions about
              ourselves(Interests, hobbies, achievements, a few open-ended
              questions to express opinions) After this screening around 50
              students were selected. There were 2 rounds after this. <br />
              There were 2 rounds after this.
            </p>
            <ol>
              <li>
                <p>GD, personal opinions and self-introduction.</p>
              </li>
              <li>
                <p>Technical Round.</p>
              </li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <p>
              FinIQ didn’t have an aptitude test. But as I mentioned before, 2
              pseudo-codes were asked. One of them was about printing the
              periodic table in proper format, and other was about array
              manipulation. Both were quite easy.{" "}
            </p>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <p>There were three interview rounds in total.</p>
            <ol>
              <li>
                <p>
                  <strong>GD and personal opinions</strong>
                </p>
                <p>
                  We were given a list of topics in advance and students were
                  divided randomly in groups of 8. In this round, a topic from
                  the list was given to us and we had to discuss it for about 10
                  minutes. Then each of us was given one topic about which we
                  had to give our own views. The next thing was
                  self-introduction to be done in a minute. The key to clear
                  this round is good communication skills and being confident
                  about yourself. Keep your answers precise; they often keep a
                  brisk time-limit of about 1 minute. 40-50% students got
                  selected for the next round
                </p>
              </li>
              <br />
              <li>
                <p>
                  <strong>Technical round</strong>
                </p>
                <p>
                  In this round we were given 2 coding questions. But
                  surprisingly, we had to write only the pseudo-code.
                  Parallelly, we were called for interview one by one. It was a
                  15-minute interview. I was asked about my project and a few
                  technical questions. I was asked about which clubs I am a part
                  of and what kind of work I do there. This was followed by few
                  general HR questions
                </p>
              </li>
              <br />
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects done and on
              resume?
            </b>
            <br />
            <p>
              In the technical round, the interviewers scrolled through my
              resume and asked me a few questions on it. I had a project which
              had GUI written in Python. So they asked me if I know other
              modules (from Python or any other language) to implement GUI. Then
              I was asked some OOP questions. They also asked a few questions
              about the excel sheet filled at the beginning.{" "}
            </p>
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            <p>
              I practiced on Hackerrank and GFG for coding questions. Hackerrank
              ‘Interview preparation kit’ is great to kick-start coding
              practice. The Final-450 series of DSA and competitive coding
              questions by Love Babbar can help to prepare thoroughly. For
              aptitude questions I practiced on Indiabix. Though, this
              preparation was not much required for FinIQ:)
            </p>
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            <p>
              Practice regularly. The vacation after SY can be utilised very
              well for this purpose (Its good if you start earlier!) I didn’t
              have much competitive coding experience, but I practiced in the
              vacation. This helped me to crack tests of other companies
              (mentioned at the end)
            </p>
          </p>

          <p>
            <strong>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </strong>
          </p>
          <p>
            This is a finance company. So, the work you get to do here will be
            somewhat related to finance. If you want pure technical work, you
            might have to rethink about this company. The interview process is
            quite easy. Good hold over basic technical concepts and
            communication skills can give you a lead.
          </p>
          <br />
          <p>
            <i>
              I cleared the aptitude test of Goldman Sachs and reached till the
              HR round of Deutsche Bank. These tests had moderate to hard level
              of aptitude and coding questions. For coding questions of these
              tests, try to clear as many test cases as possible. Don’t jump
              directly to get an optimized solution.
            </i>
          </p>
          <p>
            <i>
              I learnt a lot from interviews of GS and DB. First thing, stay
              calm, clear, and honest. Be thorough with the concepts related to
              your projects and some improvements you can add to it. Think
              aloud; sometimes the interviewer will try to lead you towards the
              answer. And lastly, keep your confidence and hopes high throughout
              the recruitment process. Sometimes, it may happen that you reach
              till the last round of interviews and still don’t get the offer.
              Learn from your mistakes, but don’t think too much about them:)
            </i>
          </p>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:tadphaleaa19.comp@coep.ac.in">
              tadphaleaa19.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default anushka_tadphale;

import React from "react";

const mugdha_bapat = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Mugdha Bapat</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}> Arcesium</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />
          <p>
            <b>
              1. What was the structure of the entire internship hiring process?
            </b>
            <br />
            Arcesium visited COEP in the first week of September. We had an
            aptitude test, 3 technical interviews and an HR interview.
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            There were 3 sections. Any time left in one section would rollover
            to the other.
            <ol type="1">
              <li>
                <b>Quantitative :</b> 15 MCQs in 20 minutes with negative
                marking, based on basic maths - speed - distance, work - time,
                permutations - combinations, probability, AP - GP etc. Questions
                were not very difficult, but there wasn’t enough time to read
                and solve all of them. So, complete all easy ones first, and
                difficult ones at the end (or never).
              </li>
              <li>
                <b>Technical MCQs :</b> Same number of questions, time and
                marking scheme. It consisted of basic input-output questions,
                OOP, DBMS queries, networking, etc.
              </li>
              <li>
                <b>Coding section :</b> 2 questions of high difficulty, total 40
                minutes. One was on trees and other was on strings + recursion.
                It's very difficult to pass all test cases in the given time, so
                write some code and try passing at least a few test cases.
              </li>
            </ol>
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            Because it was a virtual process, 3 technical interviews (45 mins
            each) were on codepair and HR (15-20 mins) on BlueJeans platform.
            All interviewers were very cooperative.
            <br />
            <ul>
              <li>
                <i>Technical round 1</i> <br />
                It started with my introduction, and how I would rate myself in
                DSA (on 10). I was given a question on strings. He asked me my
                approach (it was brute force, initially) and its time
                complexity. Then, he asked me to optimise my code. Make sure you
                tell them whatever you can think of. They give you hints to
                optimise the approach.
              </li>
              <br />
              <li>
                <i>Technical round 2</i>
                <br /> It started with my projects. I was asked to explain my
                favorite project. Because it was on machine learning, I was
                asked about the accuracy, algorithm used, how I decided the
                number of layers in CNN model, etc. Then, he asked me about the
                web project I had mentioned - API used, its functions, security
                and questions on DNS (which you will learn at the start of TY).
                Then, there were questions on concepts and algorithms (no
                coding, I had to explain on the whiteboard in codepair) in
                graphs - BFS, DFS, Dijkstra, etc.
              </li>
              <br />
              <li>
                <i>Technical round 3</i>
                <br /> This was more of a discussion than a question - answer
                round. It started with multithreading in python and python list
                implementation - using dynamic array, linked list, BST, B-Trees,
                etc. It is advisable to have a basic idea of what advanced data
                structures are, only by definition. There were OOP questions and
                another coding question (only algo, no code) - I had to use each
                data structure (from array to heap) to solve it and analyse the
                time / space complexity.
              </li>
              <br />
              <li>
                <i>HR round </i>
                <br />
                She started the conversation by asking me about my interview
                experiences. I was asked to introduce myself. You can talk about
                your extracurriculars and hobbies here. She further asked me
                about my minor course in finance, and general questions like why
                Arcesium and what do you think the company does. Have a general
                idea of the company before the round.
              </li>
            </ul>
            For all rounds, they asked me if I had any questions for them. You
            can ask anything relevant to the panel.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>
            <br />I had done 3 projects and questions on them were only in round
            2. More than the code of the project, it was about the related
            concepts, why I chose a project and if anything better could be
            done. Mention only those projects for which you can answer most of
            the questions. Also, unless you have a strong liking for something,
            try to diversify your projects.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>
            <br />
            GFG is a great resource to brush up OOP and DSA. I went through the
            topic wise articles and mcqs on GFG for aptis. I mainly used
            HackerRank for coding (usually companies also use it for aptis). A
            lot of people also use CodeChef and Leetcode.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b>
            <br />
            Start practising early. Even if you don't do an internship or a
            fancy project in the vacation after SY, start competitive coding in
            the vacation itself - there's hardly any time after college starts.
            YouTube has good videos on dynamic programming. Arcesium used
            HackerRank for coding - it has time considerations for different
            languages, so I used Python. But C++ provides a lot of
            functionalities and is faster than Python, so it can be useful if
            the platform has the same time constraints for all languages.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            Preparing for interviews is important, but also spend a lot of time
            preparing for the aptitude as these companies shortlist less people
            for interviews. Don't ignore the quantitative and technical MCQs
            section. Even if you barely pass the coding section's test cases,
            other sections have equal weightage. For quantitative, I read up a
            few things from R.S Agarwal and solved them from{" "}
            <a href="https://www.indiabix.com/">IndiaBix</a>. Secondly, for
            interviews, completely explain whatever you know about that question
            and think out loud(important). You don't need to know 100 different
            things. Even if you have learnt a few skills, make sure you go
            through those concepts properly.
          </p>

          <p>
            <br />
            <i>
              The entire internship process is extremely stressful. No matter
              how much you know, it depends a lot on how you can answer at that
              particular time. Similarly, you might feel you deserve a company
              more than XYZ, or XYZ deserves it more than you. Either ways, make
              peace with the fact that luck plays a very big role, along with
              hard work. Go through interview experiences on GFG and stay in
              touch with your seniors. Feel free to contact me anytime!
            </i>
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="bapatmp18.comp@coep.ac.in">bapatmp18.comp@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default mugdha_bapat;

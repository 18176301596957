import React from "react";

const parth_yewale = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Parth Yewale
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Pattern Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Round 1 : Online Aptitude Test.
                                </li>
                                <li>
                                    Round 2 : Offline Interview at Pattern Technologies.
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Online test having 3 coding questions. Duration was 1.30 hr.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <ol>
                            <li>
                                The coding test included 3 coding questions. Level of questions was from moderate to difficult.
                            </li>
                            <li>
                                The technical round focused on my projects, coding ability, web development skills and some knowledge about tools like git, github.
                            </li>
                            <li>
                                The HR round was completely about my extracurricular activities, hobbies, family background and some general things like what you do in your free time, how you support your passion.
                            </li>
                        </ol>

                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        After Aptitude Test : 21 shortlisted for Interview
                        <br />
                        After Technical Round : 9 shortlisted for HR
                        <br />
                        Finally, 7 were given the internship.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        I used LeetCode for practising coding questions. Additionally, I turned to GFG to brush up my concepts of OOPs.
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practise more on LeetCode and similar platforms. Choose one programming language and master in that. You may also refer to <a href="https://450dsa.com/">450 DSA Cracker</a>.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            They didn’t focus much on projects. Just 2-3 Ques. were asked on each project. They may also ask more about the data structures used in the projects.
                            <br />
                            Questions on the resume were pretty less. I was asked questions on CN, DBMS, OS, Rest APIs which I directly told them that I don’t have idea about the same. I was also asked some questions on web development. Also, I was asked to write 3 git commands, difference between git and github, git bash,etc.
                            <br />
                            During my HR round, one or two Ques. were asked on extracurricular activities.
                            <br />
                            <br />
                            Tip : Keep your interviews interactive. Make sure that your interview doesn’t go silent for more than 30 sec., this builds awkwardness.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Coding Test has just 3 coding ques. but they are pretty difficult. Solve more and more coding problems. Also, interviews are at their office so you will get some sort of experience.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Prepare a short but effective Introduction as it will reflect your personality at the start of interview itself. Focus and practise more for the Interviews. Rejections are part of life, see it as an experience and analyse your mistakes for your next attempt.
                            <br />
                            <br />
                            Wishing you the best !!!
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:yewalepm21.comp@coeptech.ac.in">
                            yewalepm21.comp@coeptech.ac.in
                        </a>
                        <br />
                        LinkedIn : <a href="https://www.linkedin.com/in/parth-yewale-6157ba22a/">Parth Yewale</a>
                        <br />
                    </div>
                </div>
            </section >
        </div >
    );
};

export default parth_yewale;

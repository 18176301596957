import React from "react";

const ritesh_chaudhari = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
          Ritesh Narendra Chaudhari

          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}> Deutsche Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            There were four rounds:
            <ul style={{listStyle:"disc", paddingLeft:"40px"}}>
              <li>
              First round was an aptitude and coding round for 90 min (conducted on Hackerearth)
              </li>
              <li>And three interview rounds:
                <ol>
                  <li>
                  1 technical
                  </li>
                  <li>
                  Pro-fit round
                  </li>
                  <li>
                  HR round
                  </li>
                </ol>
              </li>
            </ul>

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            Aptitude round was divided into 2 sections:
            <ol>
              <li>
              Technical MCQs : It has 10 Questions based on core subjects like Computer Networks, DBMS , Operating Systems and OOP ( in Java).
              </li>
              <li>
              Coding Round : 2 coding questions were given (easy - moderate).
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many rounds were there for the company, can you elaborate about each
              round in brief</b>
              <ol>
                <li>
                Technical Round (Around 50 minutes):
                <ul style={{listStyle:"disc", paddingLeft:"40px"}}>
                  <li>
                  For me this round was purely Resume based. They didn’t ask me anything related to DSA or OOP to me (majority of my friends were asked to solve questions on OOPs, DSA and even puzzles).
                  </li>
                  <li>
                  Many questions related to the choice of tech stacks were asked. So make sure you know what you have used and why you have used it over other choices available.
                  </li>
                  <li>
                  Everything I mentioned on my resume was questioned, they asked almost everything to check if I really know the stuff.
                  </li>
                </ul>
                </li>
                <li>
                Pro-fit Round (Around 40-45 minutes)
                <ul style={{listStyle:"disc", paddingLeft:"40px"}}>
                  <li>
                  This was the best round for me. It started with questions like “Tell me about yourself and what do you like”.
                  </li>
                  <li>
                  As soon as I said I love football, we had a 5-10 minute discussion on that. He was a Barca fan and I am a Madrid fan. ;)
                  </li>
                  <li>
                  After that he asked me to explain any one of my projects which I liked the most and why I think so.
                  </li>
                  <li>
                  Questions based on my previous internship and clubs were asked.
                  </li>
                  <li>
                  He asked me whether I love to work in a team or individually, he also gave me 2-3 real situations (not technical) and asked me how I will handle it.
                  </li>
                  <li>
                  Later he asked me about my background.
                  </li>
                  <li>
                  This round was basically just to check if you are the right fit for the company.
                  </li>
                </ul>
                </li>
                <li>
                HR Round (Around 10-15 minutes)
                <ul style={{listStyle:"disc", paddingLeft:"40px"}}>
                  <li>
                  This round was a question answer session. Some of the questions were:
                  <ol>
                    <li>
                    How are you? What were your emotions throughout the day?
                    </li>
                    <li>
                    Why Deutsche?
                    </li>
                    <li>
                    Tell me about your family background?
                    </li>
                    <li>
                    Do you want to do further studies?
                    </li>
                    <li>
                    What are the 2 things your friends like about you and 2 things they dislike about you?
                    </li>
                  </ol>
                  </li>
                </ul>
                </li>
              </ol>
          </p>

          <p>
            <b>
              4. How many students were shortlisted after each round?
            </b>
            <br />
            <p style={{paddingLeft:"40px"}}>
            <b>Aptitude:</b> 14 out of around 150. <br />
            <b>Technical Round:</b> 12/14 were shortlisted. <br />
            <b>Pro-fit Round:</b> 11/12 were shortlisted. <br />
            <b>HR Round:</b> All 11 made it to Deutsche!!!!
            </p>
          </p>

            <p>
              <b>
                5. Some good resources you used in your internship prep
              </b>
              <br />
              <p style={{paddingLeft:"40px"}}>
              <b>For Coding:</b> Love Babbar’s 450 Sheet, Leetcode and GeeksforGeeks. <br />
              <b>For Core subjects:</b> GFG Last minutes notes. <br />
              <b>For Aptitude: </b> Indiabix and Interviewbit. <br />
              </p>

            </p>

            <p>
              <b>
                6. Some tips to crack coding questions
              </b>
              <br />

              <ul style={{listStyle:"disc", paddingLeft:"40px"}}>
                <li>
                After reading the question, try to think of a brute force approach first and then try to optimize it (if not, try to pass as many test cases as possible).
                </li>
                <li>
                Also, try to think about the base case(s) for your code.
                </li>
              </ul>
            </p>

            <p>
              <b>7. How many questions were asked about projects, clubs and extracurricular
                activities in your resume</b> <br />
              <ul style={{listStyle:"disc", paddingLeft:"40px"}}>
                <li>
                In one word? ALOT!!!
                </li>
                <li>
                Surprisingly, I was asked about them in almost all the rounds even in the HR round.
                </li>
              </ul>
            </p>
            <p>
              <strong>
                8. Is there something specific that you would want to tell people who try for the
                same company
              </strong>
              <ul style={{listStyle:"disc", paddingLeft:"40px"}}>
                <li>
                Do OOPs as much as possible, they ask a lot on OOPs.
                </li>
                <li>
                For aptitude, just go through the syntax of Java as they ask a few code snippets and ask the output (every question in aptitude except Coding questions have 4 marks, so don’t overlook that).
                </li>
                <li>
                Apart from this DSA is also very important as they ask you to code a few in front of them.
                </li>
              </ul>
            </p>
            <p>
              <strong>
                8. Final Tip for Juniors :
              </strong>
            <br />
            <p>Just be clear on whatever you have on your resume, get your DSA and OOPs basics cleared. <br />
            And CHILL, the interviewers are very nice and friendly, just be confident in the interviews.</p>
            </p>

            <h3>Contact Info:</h3>
            <div style={{ color: "gray", fontSize: "1.15rem" }}>
              <br />
              Email-ID : <a href="mailto:riteshnc20.comp@coep.ac.in">
                riteshnc20.comp@coep.ac.in
              </a>
              
            </div>
        </div >
      </section >
    </div >
  );
};

export default ritesh_chaudhari;

import React from "react";

const shreya_bhatkhande = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Shreya Bhatkhande
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>mastercard</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There were four rounds in total:                        </p>
                        <p>
                            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>

                                <li>1 Coding round.
                                </li>
                                <li>2 Technical interview rounds</li>
                                <li>1 HR interview round</li>
                            </ul>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The coding round was conducted on  ‘HirePro’ platform.
                            <br />In the Coding round there were two questions:

                            <br />
                        </p>
                        <p>
                            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>

                                <li>First question was based on Dynamic Programming. The question was of a high difficulty level.

                                </li>
                                <li>Second question was based on strings and it was an easy question.
                                    Hashmaps or a simple array could be used to solve the problem.
                                </li>
                            </ul>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        After shortlisting on the basis of the coding round 3 interview rounds were conducted :
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>Technical round 1:<br />
                                I was asked to introduce myself. The interviewer then asked me questions mainly on the concept of OOP mainly focussing on constructor, destructor, types of Polymorphism with their examples.
                                What are the advantages of OOP? What was the need to transition from a procedural programming language (C language) to an object oriented language?
                                Then I had to write a code of an easy level string question and explain it line by line. Also I had to explain the difference between an Array and a Stack.
                                In DBMS, I had to explain the ACID properties with examples of each property.
                                Further, I was asked a puzzle which was of easy level. The interview concluded with me asking him a couple of questions about FinTech.
                            </li>
                            <li>Technical round 2:<br />
                                This was a tougher round for me since the interviewer was from the Information Security domain. He asked me the difference between authentication and authorization, encryption and decryption.
                                <br />
                                Then, he delved into the projects I mentioned, and asked me to explain the algorithm used in the DSA project. He also gave me a similar problem statement and the algorithm that I should use in such a case.
                                He asked me about each and every word mentioned in the description of the second project. In my case, I had used various python libraries for data analysis and visualisation , so he asked me about each library I had used. So  basically ,this round was mainly dependent on the projects.
                                I asked him a couple more questions in the end, which is also an important part to set a good impression.
                            </li>
                            <li>HR Round:<br />
                                I was again asked to interview myself without the technical skills and stressing more on co-curricular and extra-curricular activities and what all I had learnt and achieved from them.
                                Further, I was asked about my family background and my family members’ occupations.
                                She then gave me a situation based on teamwork and adjustments.
                                This interview also concluded with me asking a question about Mastercard from a woman’s perspective.
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>60-62 were shortlisted after the online aptitude test
                            </li>
                            <li>40 students shortlisted for Technical round 2.</li>
                            <li>20-22 for the HR round</li>
                            <li>13 people were given the final offer.</li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                            <li>
                                Striver SDE Sheet and youtube Channel
                            </li>
                            <li>
                                GeeksforGeeks for the concepts of OOP.
                            </li>
                            <li>
                                GeeksforGeeks puzzles.

                            </li>
                            <li>
                                Aditya Verma DP playlist.
                            </li>
                            <li>
                                Indiabix for aptitude questions.
                            </li>
                            <li>Interviewbit questions on CN, DBMS and OOP.</li>
                        </ul>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                <li>Practice Coding on a regular basis on Leetcode and GeeksforGeeks. Solve questions of a variety of topics so that you develop a good coding aptitude.
                                </li>
                                <li>Start with the Brute force approach first and then try to optimise it.
                                </li>
                                <li>Have a strong-hold on basic Data Structures and important and commonly asked Algorithms like Greedy,  Binary Search, Recursion.
                                </li>
                            </ul>                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            In the second interview round the interviewer dug deep and asked me a good amount of questions on my projects to test my understanding.
                            <br />I was asked about clubs and extracurriculars in the HR round. She just asked some basic questions based on them.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            As mentioned above, practice coding , OOP fundamentals and projects.
                            <br />Strengthen your communication skills and ability to set a good and sincere first impression.
                            <br />Ask new and creative questions at the end of each round as it distinguishes you from the rest.
                            <br />Attend the PPT with full focus as some questions are based on it as well.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                <li>Draft your resume wisely and do not add skills which you are not confident about.
                                </li>
                                <li>Be confident during the interviews but also do not make up things.</li>
                                <li>Believe in yourself. Internship period is pretty stressful, you might lose your confidence as companies come and go. Talk to your friends and family if you feel low.
                                </li>
                                <li>This process is based on luck also along with talent. So do not lose hope and keep trying for the upcoming companies.
                                </li>
                            </ul>
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:bhatkhandess21.comp@coeptech.ac.in">
                            bhatkhandess21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default shreya_bhatkhande;

import React from "react";

const siddhi_shinde = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Siddhi Shinde
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '24
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <p>
              The recruitment process had 3 stages:
              <ol style={{ paddingLeft: "40px" }}>
                <li>
                  Aptitude Test
                </li>
                <li>
                  Technical Interview
                </li>
                <li>
                  HR interview
                </li>
              </ol>
            </p>

          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            <ol>
              <li>
                Quantitative:This section had mental ability based questions
              </li>
              <li>
                Techincal: Questions on output prediction, OOPs concepts etc ,C/C++ basics.
              </li>
              <li>
                Verbal : Paragraphs are given based on which we had to answer questions.
              </li>
              <li>
                Coding Questions: I had two coding questions one based on bit manipulation and other on
                matrices .
              </li>
            </ol>
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b><br />
            There were two rounds after the aptitude test .
            <ol style={{ paddingLeft:"40px" }}>
              <li>
                Technical round –
                <p style={{ paddingLeft: "40px" }}>
                  The interviewer asked me to explain the pillars of OOPs and how they were implemented in my project. As I had used Django the interviewer also asked about how authentication is done.I was also asked on how I would plan to deploy .There was a question based on the diamond problem of inheritance and follow up questions based on what I had answered . I was also asked a puzzle which was similar to <a href="https://www.geeksforgeeks.org/puzzle-1-how-to-measure-45-minutes-using-two-identical-wires/"> Puzzle 1 | (How to Measure 45 minutes using two identical wires?) - GeeksforGeeks  </a>The interviewer also asked questions on linked list and time complexities of sorting algorithms.
                </p>
                <p>
                  For the technical round be thorough with whatever you have mentioned in your resume  .Don’t go overboard and mention skills for the sake of it .I had mentioned only four skills  in the technical proficiency section of my resume and tried capitalise on those. The interviewers in general are interested in the thought process and approach you take to answer a given question .
                </p>
              </li>
              <li>
                HR round –
                <p>
                  I was asked to introduce myself and then asked to speak about my project was about. After a few questions on my project, I was asked about OOPs concepts and when a language can be considered to be object oriented. I was also asked about the pros and cons of cloud technology. Then we discussed about the hobbies mentioned in my resume. I was asked the standard question ,Why I would like to work at Citi ?  I highly recommend paying attention during the company presentation and try to base your answer around what they mention.
                </p>
                <p>
                  While answering the questions be confident and try to support your answers with actual examples as this shows the interviewers that you are observant and can recognise problems in real life applications
                </p>
              </li>
            </ol>
          </p>

          <p>
            <b>
              4. Some good resources you used in your internship prep
            </b>
            <br />
            <p>
              I highly recommend using the 450 questions cracker sheet and leetcoding on a regular basis.
              Practise doing timed test on Hackerrank ,it will  improve your ability to think under pressure.
              I also used to read medium articles on whatever topics I had mentioned in my resume.
              For OOPs I really found Corey Schafer’s playlist helpful .For aptitude I referred to Indiabix as suggested by many seniors .Go through commonly asked interview questions on GFG .
            </p>
          </p>

          <p>
            <b>
              5. Some tips to crack coding questions
            </b>
            <br />
            <ol style={{paddingLeft:"40px"}}>
              <li>
              Start as early as possible. 
              </li>
              <li>
              Stick to one or two platforms only 
              </li>
              <li>
              For topics such as Backtracking and DP go through Striver’s and Aditya Verma’s  playlist .All these topics have standard questions and understanding these patterns will allow you to solve questions built upon them .
              </li>
            </ol>
            
          </p>

          <p>
            <b>6. How many questions were asked about projects, clubs and extracurricular
              activities in your resume</b> <br />
            <p>
            Projects served as a starting point for the interview and questions were asked on all possibly related topics. Apart from that I was asked about my hobbies mentioned in the resume during the HR round . Don’t put anything in your resume which you are not sure about because even though it might seem like just one topic the interviewers can generate a lot of questions from that .Keeping my resume minimal had helped a lot especially during preparation.
            </p>
            <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <ol style={{paddingLeft:"40px"}}>
              <li>
              Try to do well in the HR round and pay attention during the company presentation.
              </li>
              <li>
              Go through standard questions asked in the AMCAT tests . 
              </li>
            </ol>
          </p>
          </p>
          <b>
            8. Final Tip for Juniors?
          </b>
          <ol style={{paddingLeft:"40px"}}>
            <li>
            Be ready with the details for the superset profile as the time frame given to complete this process is really short . 
            </li>
            <li>
            Don’t take anything personally incase you don’t do well in a test or interview. Just keep refining and learning from the process .
            </li>
            <li>
            Go through CSI diaries of the upcoming companies (I found this really helpful ).
            </li>
          </ol>
          <br />
          
        </div>
      </section >
    </div >
  );
};

export default siddhi_shinde;

import React from "react";

const avdhut_kamble = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Avdhut Kamble
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Pattern Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            <ol>
                                <li>
                                    Online Aptitude Test
                                </li>
                                <li>
                                    Technical Round
                                </li>
                                <li>
                                    Techno-Managerial Round
                                </li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The test was conducted on DoSelect platform on 29th August, 23. Duration - 1 hour 30 minutes. There were only three coding questions; first was relatively easy, House Robber problem with a circular linked list. The second question revolved around identifying overlapping intervals, falling within the easy-medium range of difficulty. However, the third question was challenging, and unfortunately, I couldn't solve it within the given time frame.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were only two rounds further in the interview process.
                        < br />
                        < br />
                        <b>Technical Round:</b> <br />
                        Basic questions like introduce yourself, courses I’ve opted in and stuff. Then questions revolved around DSA and DBMS. I was asked to design a database schema for one of my projects. We further discussed some design choices in my projects and how we could further optimise them. This round lasted approximately 30-45 minutes.
                        < br />
                        < br />
                        <b>Techno-Managerial Round</b> <br />
                        Pretty basic questions like introduce yourself, why Pattern Technologies, Questions about my prior internships, etc. We were done in 15-20 mins.

                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        <ol>
                            <li>
                                After <b>Aptitude Round</b> : 21 students
                            </li>
                            <li>
                                <b>Technical Round</b> : 10-12 students
                            </li>
                            <li>
                                <b>Techno-Managerial Round</b> : 7 students
                            </li>
                        </ol>
                        All 7 students were selected.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        Leetcode and Neetcode’s Algorithm Roadmap for problem solving and IndiaBix for aptitude prep.
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practise a LOT. Focus on a variety of problems, with an emphasis on medium and hard difficulty levels rather than just the easy ones.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Lots of them, a significant portion of the discussion revolved around my resume, including my projects, clubs, and extracurricular activities.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Prior to your interviews, do your research about the company. Asking questions and demonstrating a genuine interest in the company's objectives can leave a positive impression.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            The internship application process can be stressful and frustrating. Don’t lose your cool and be patient. All the best!
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        LinkedIn : <a href="https://www.linkedin.com/in/avdhut-kamble/">Avdhut Kamble</a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default avdhut_kamble;

export const editorial_2022 = [
    {
        sy_week_1 : [
            {
                problem_name: "Insulin Drive",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-junior-sys-2022/challenges/insulin-drive-1",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/SY/WEEK%20-%201/Insulin%20Drive.py",
                explanation: "https://drive.google.com/file/d/1CRdsx--_kIB0zId-rD9D6QK3DaEuSmjV/view?usp=sharing",
            },
            {
                problem_name: "Toys and Money Confusion",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-junior-sys-2022/challenges/toys-and-money-confusion",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/SY/WEEK%20-%201/Toys%20and%20Money%20Confusion.cpp",
                explanation: "https://drive.google.com/file/d/1ninIzV5dceKZ4ZGsMbF-zuxDm2IUMG6A/view?usp=sharing",
            },
            {
            problem_name: "Railing Dilemma in Hogwarts",
            problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-junior-sys-2022/challenges/railing-dilemma-1",
            code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/SY/WEEK%20-%201/Railing%20Dilemma%20in%20Hogwarts.py",
            explanation: "https://drive.google.com/file/d/1nlkzQq946djXpC9_9nARt01KAGUl01s1/view?usp=sharing",
            },
        ],
        sy_week_2 : [
            {
                problem_name: "Elections in COEP University",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-junior-sys-2022/challenges/elections-in-coep-1",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/SY/WEEK%20-%202/Elections%20in%20COEP%20University.py",
                explanation: "https://drive.google.com/file/d/1lEqYBkh3MOMfgv8L5Z87ty7bP0F83Wz4/view?usp=sharing",
            },
            {
                problem_name: "Confusion in Charlie's Christmas",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-junior-sys-2022/challenges/charlies-christmas-1",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/SY/WEEK%20-%202/Confusion%20in%20Charlie's%20Christmas.cpp",
                explanation: "https://drive.google.com/file/d/15leFs2W4Tl6FnkpS0Gk0UzRmRYFE7VvL/view?usp=sharing",
            },
            {
                problem_name: "COEP College Tour",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-junior-sys-2022/challenges/coep-college-tour",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/SY/WEEK%20-%202/COEP%20College%20Tour.py",
                explanation: "https://drive.google.com/file/d/1WiYfiK-PtdXYMRKcNIPp5CtknBnJh_6L/view?usp=sharing",
            }
        ],
        sy_week_3 : [
            {
                problem_name: "Gym of CodeQuest Land",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-junior-sys-2022/challenges/gym-of-codequest-land",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/SY/WEEK%20-%203/Gym%20of%20CodeQuest%20Land.cpp",
                explanation: "https://drive.google.com/file/d/1oVXc91R8pcIaz7fygelEjQb6ICBu-9Tm/view?usp=sharing",
            },
            {
                problem_name: "Alice and Bunny in Wonderland",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-junior-sys-2022/challenges/alice-in-wonderland-2",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/SY/WEEK%20-%203/Alice%20and%20Bunny%20in%20Wonderland.py",
                explanation: "https://drive.google.com/file/d/1IBhGat9pFecDg2rX5VWXON6DJag2Ha6o/view?usp=sharing",
            },
            {
                problem_name: "Tug of War in COEP",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-junior-sys-2022/challenges/tug-of-war-in-coep",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/SY/WEEK%20-%203/Tug%20of%20War.py",
                explanation: "https://drive.google.com/file/d/1kaYnac8WgEaiXjbuayxB3jI2UQEko2ci/view?usp=sharing",
            }

        ],
        ty_week_1 : [
            {
                problem_name: "XOR Pretty",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-senior-tys-2022/challenges/xor-pretty",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/TY/WEEK%20-%201/XORPretty.cpp",
                explanation: "https://drive.google.com/file/d/11ZAPp9WJHx7X7JJUeSLngLnsmbmc8Sgj/view?usp=sharing",
            },
            {
                problem_name: "Rolls Mania",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-senior-tys-2022/challenges/rolls-mania",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/TY/WEEK%20-%201/RollsMania.cpp",
                explanation: "https://drive.google.com/file/d/1y5bGGSnpak6jBQ9Jxuj_emybHSjOoAnS/view?usp=sharing",
            },
            {
            problem_name: "Magical Creatures of Newt Sacamder",
            problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-senior-tys-2022/challenges/magical-creatures-of-newt-scamander",
            code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/TY/WEEK%20-%201/MagicalCreaturesOfNewtScamader.cpp",
            explanation: "https://drive.google.com/file/d/1CU9uvHjtMqcf3XW0_uc9xn5ptE40gBto/view?usp=sharing",
            },
        ],
        ty_week_2 : [
            {
                problem_name: "Pizza Hut of CodeQuest Land",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-senior-tys-2022/challenges/pizza-hut-of-codequest-land",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/TY/WEEK%20-%202/PizzaHutOfCodeQuestLand.cpp",
                explanation: "https://drive.google.com/file/d/16AhLgc0Jg4g1NC-CuhHPMwjTfr9yqhk6/view?usp=sharing",
            },
            {
                problem_name: "Marauder's Map",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-senior-tys-2022/challenges/maraudes-map",
                code: "",
                explanation: "",
            },
            {
                problem_name: "Destroying Monuments",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-senior-tys-2022/challenges/destroying-monuments",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/TY/WEEK%20-%202/DestroyingMonuments.cpp",
                explanation: "https://drive.google.com/file/d/1g1yb0cQetdSqq0eMRDNxq4Qzb6J-DwBO/view?usp=sharing",
            }
        ],
        ty_week_3 : [
            {
                problem_name: "Theobroma, Food of the Gods",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-senior-tys-2022/challenges/theobroma-food-of-the-gods",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/TY/WEEK%20-%203/Theobroma.cpp",
                explanation: "https://drive.google.com/file/d/10tX8ef2PtFE0UDrhjI_r41yIIca0r2k_/view?usp=sharing",
            },
            {
                problem_name: "The Game of Tiles",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-senior-tys-2022/challenges/the-game-of-tiles-1",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/TY/WEEK%20-%203/GameOfTiles.cpp",
                explanation: "https://drive.google.com/file/d/1dsIF5j4uOasH7v_SAYq22UhWxRMcHRNC/view?usp=sharing",
            },
            {
                problem_name: "Chain of Cables",
                problem_statement: "https://www.hackerrank.com/contests/codequest-3-0-senior-tys-2022/challenges/chain-of-cables",
                code: "https://github.com/CSI-COEP/CodeQuest3.0_Solutions/blob/main/TY/WEEK%20-%203/ChainOfCables.cpp",
                explanation: "https://drive.google.com/file/d/1nTPDH0ynCHQr0zryqVLpF_Jczfg3xOFy/view?usp=sharing",
            }

        ]
    },
]

export default editorial_2022; 
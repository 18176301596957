import React from "react";

const yash_gangwar = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{ fontSize: "2em", position: "absolute", marginTop: "2rem" }}
          />
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Yash Gangwar</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '21
            </p>
          </div>
          <br />
          <h5>What was the structure of the entire interview process?</h5>
          <p>Really long process to be honest.</p>
          <ul>
            <li>Aptitude test</li>
            <li>Aptitude writing (Article on social issue)</li>
            <li>Group Discussion</li>
            <li>Technical interviews x 2</li>
            <li>HR Interview</li>
          </ul>
          <h5>What questions were asked in the aptitude test?</h5>
          <p>
            <em>
              TAKE EXTREME CARE TO CHECK WHETHER THERE IS NEGATIVE MARKING OR
              NOT.
            </em>
          </p>
          <ul>
            <li>
              Coding question x 2 Both were quite easy. Basic knowledge of array
              and string operations were required
            </li>
            <li>
              Technical
              <ul style={{ fontSize: "1em" }}>
                <li>Theoretical questions based on DC networking, OS etc.</li>
                <li>
                  You either know them, or you don’t. Don’t waste a lot of time
                  here if you’re not sure whether you can answer or not
                </li>
              </ul>
            </li>
            <li>
              Aptitude maths
              <ul style={{ fontSize: "1em" }}>
                <li>
                  A lot of financial questions (interest rates, profit loss)
                </li>
                <li>
                  Maths aptitude questions (Percentage, Train station problems,
                  etc)
                </li>
              </ul>
            </li>
            <li>
              Logical
              <ul style={{ fontSize: "1em" }}>
                <li>Pattern recognition</li>
                <li>Questions related to family relations</li>
                <li>Clock type questions</li>
              </ul>
            </li>
          </ul>
          <h5>What sort of questions were asked in the interviews?</h5>
          <p>HR</p>
          <ul>
            <li>Looked alot at my CV</li>
            <li>Asked about hobbies and extracurricular</li>
            <li>Expectations from future</li>
            <li>Current academic interests</li>
          </ul>
          <p>Technical</p>
          <ul>
            <li>Some basic questions on malloc and calloc</li>
            <li>
              Asked algorithm questions on removing duplicates from the array
              and further optimisation of solution.
            </li>
            <li>
              Project based. Technology used and company worked for. How was
              work culture etc.
            </li>
          </ul>
          <h5>How many questions were asked based on the projects done?</h5>
          <p>
            Surprisingly not a lot. What’s important is to know your project
            very thoroughly. Even if the number of projects on your resume are
            less, you should be able to answer anything they ask. See, if your
            projects are interesting and they are intrigued they might start
            asking you completely about the project. You can prepare very well
            about your projects but not too much about the random topics he/she
            might ask. So it’s better to divert the conversation towards your
            projects. For that to happen the initial questions they might ask
            about your project should be very thorough and well answered.
          </p>
          <h5>How many questions were based on the resume?</h5>
          <p>
            Based on my resume, I was asked about my skill set and projects. The
            resume was only referred in the start then kept aside. Your resume
            served mostly as just a conversation starter. They might find things
            in your resume that they’re interested in and the conversation picks
            up from there. Hence, it’s important to not add things in your
            resume that you are not too confident about. Keep it simple while
            adding a variety of fields to talk about.
          </p>
          <h5>What are some good resources used by you in your preparation?</h5>
          <p>
            Did a hell lot of coding on leet code. Once you have practiced
            enough on leet code you can answer any algorithmic design question
            any company can throw at you. Besides that, I read a lot on
            “Cracking the coding interview”. Helps to get a clear perspective of
            the examiner. Give enough time preparing for quantitative as well,
            as the weightage is similar to technical.
          </p>
          <h5>Some tips to crack the coding questions.</h5>
          <ul>
            <li>
              In the summer or before you start practicing, select a good
              competitive coding language. Some tips on that:
              <ul style={{ fontSize: "1em" }}>
                <li>DON’T EVEN THINK ABOUT C.</li>
                <li>
                  Languages to consider: C++, Java, Python or any other modern
                  language with lots of support libraries for graphs, maps etc.
                </li>
                <li>
                  While practicing, learn well how to use these predefined data
                  structures and all its parameters. Eg, If using C++, learn all
                  the STL and get good hands on practice with them. You’ll be
                  depending on them a lot on coding questions.
                </li>
              </ul>
            </li>
            <li>
              Read the question multiple times before even starting to type
              anything. There might be some small detail that might make your
              solution x10 easier.
            </li>
            <li>
              As you might already know, you’d probably be asked to just write
              function definition. So practice accordingly. Some companies may
              ask to write entire code (very unlikely) so look over how to take
              arguments from the command line etc.
            </li>
            <li>
              You’d be required to fulfill test cases and be awarded marks based
              on that. In a very very unlikely scenario where you are unable to
              solve within time constraints, write simple code to satisfy as
              many cases as you can. (I’ve even heard people return randomised
              values and clear multiple cases.) Again, last resort. Don’t do
              such a thing until you’ve no choice.
            </li>
            <li>
              I prefered leaving coding for the last as you can never be sure
              how long it might take. There’s no point in leaving easy quant or
              tech questions for coding.
            </li>
          </ul>
          <h5>
            Is there something specific that you would want to tell people who
            try
          </h5>
          <p>
            I’m currently interning at FinIQ. Your deciding factor for the
            selection would be the Group Discussion and HR interview. If you
            have a lot of extracurricular activities and are a proficient
            communicator you should be good. The only suggestion I have is that
            be very sure you want to be here as the company is very finance
            engineering oriented. You might end up learning a lot about finance
            but not too much on the technical domain. Work culture is great and
            I’m sure you’ll enjoy your time there. All the best for your future
            and let me know if you need any further help.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15em" }}>
            <a href="mailto:yashg17.comp@coep.ac.in">yashg17.comp@coep.ac.in</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default yash_gangwar;

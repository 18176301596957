import React from "react";

const arya_gandhi = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Arya Gandhi</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>FinIQ</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '22
            </p>
          </div>
          <br />

          <p>
            <b>
              1. What was the structure of the entire internship hiring process?
            </b>
            <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
              <li>
                Round 1 - Filling up an excel sheet about personal and academic
                details
              </li>
              <li>Round 2 - GD</li>
              <li>Round 3 - Essays</li>
              <li>Round 4 - HR interview</li>
              <li>Round 5 - Technical interview</li>
              <li>Round 6 - HR interview</li>
            </ul>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            There was no aptitute test.
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
              <li>
                GD: The topic for GD was 'If you are an aviation investment
                company, what would you invest in - helicopter taxi or space
                travel'. 3-4 topics were sent to the students beforehand by mail
                and one topic out of them was given to the group once the GD
                started.
              </li>
              <li>
                Essay writing:
                <ol type="1">
                  <li>
                    After World War 2, the UK (Britain) won and Japan lost, but
                    Japan still grew far more than the UK in the next 30-40 yr
                    horizon, Japan became a much wealthier country as compared
                    to the UK. How and Why
                  </li>
                  <li>
                    If all countries grow at the same percentage growth rate, is
                    the world as a whole becoming wealthier? Support your answer
                    with reasoning.
                  </li>
                  <li>
                    You are a Pharma research company working on COVID vaccine,
                    make a 6 month plan for vaccine research and mass
                    production. Make a flowchart for the same!
                  </li>
                </ol>
                A day's time was given to write all three essays and a word
                document was to be submitted for the same.
              </li>
              <li>
                First HR round: Basic questions were asked such as introduce
                yourself, where are you from, what do your parents do, what are
                your favourite pastimes, what are your plans for higher studies,
                etc. They were quite conversational and the questions were easy
                to answer.
              </li>
              <li>
                Technical round: In this round, I was expecting questions
                related to computers and coding but all the questions were
                quants. These questions were - divide ₹100 into 7 boxes such
                that any amount from 1-100 can be given without opening the
                boxes, divide a cake into 8 equal parts using only 3 cuts, angle
                between hands of clock when the time is 12:15, what is greater
                3^6 or 5^4, a basic probability question(6 red and 4 green
                balls), ABCD x E = DCBA (distinct digits) find A B C and D. PS :
                I had forgotten to take a sheet of paper with me at the time of
                this interview so I solved all the questions mentally which
                actually worked in my favour(the interviewer mentioned this).
                But the interviewer also told me to always have a rough sheet
                nearby during any interview because it will not always happen
                that the question is mentally solvable.
              </li>
              <li>
                Second HR round: Questions asked were - describe yourself(keep
                an answer ready for this beforehand as it is definitely asked in
                the HR interview and people tend to go blank) , if you had to
                find out the number of railway stations in India what approach
                would you take, why is Abdul Kalam your favourite personality(I
                had mentioned this in the excel sheet that they had made us fill
                for round 1), any specific university for MS.
              </li>
            </ul>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            Not even a single question.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            <a href="https://geeksforgeeks.com">GeeksforGeeks</a> and{" "}
            <a href="https://hackerrank.com">Hackerrank</a> (though these were
            not much help for this particular company). For FinIQ specifically,
            previous knowledge of basic school level mathematics and a fine
            sense of logic helped.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            There were no coding questions.
          </p>
          <p>
            <br />
            <i>
              Chances are that you may get rejected at various stages for
              different companies in the beginning. It can be quite heart
              breaking but it is important to not lose hope. Talking to friends
              and family helps a lot. Also it is necessary to remember that a
              lot of this process is luck based and there will be times when you
              feel that you deserved better or someone undeserving got your
              place. But there is nothing you can do about it. All you should
              focus on is building a good profile and giving your best at the
              aptitude tests and interviews. Remaining calm and confident even
              if you don't know the answer is the key to cracking the interview
              rounds.
              <br />
              <br />
              Keep answers of a few frequently asked questions ready beforehand
              (especially for the HR rounds). Wear formal clothes, they create a
              good impression on the interviewer. Use good vocabulary and a
              polite tone. If the interview is online, ensure beforehand that
              all your devices are working fine(in case of any technical
              difficulty, do not panic. Interviewers are quite considerate). Sit
              in a good spot away from commotion. Always keep a sheet of paper
              and pen with you for the interview rounds. Answer anything and
              everything that you know related to the topic they have asked
              about. Think out loud.
              <br />
              <br />
              The interviewer is as interested in the thought process as the
              answer.Lastly, it is super important to remember that the
              interviewers are humans too so talk to them as if you'd talk to a
              teacher or a friend without panicking. Also it is totally going to
              happen that you'll see people around you get good internships
              while you are still stuck in the process. At this time it is
              necessary to keep your calm and think of it as bonus time to
              prepare more.
              <br />
              <br />
              Great things are in store for you so keep hope. Best of luck!
            </i>
            <br />
          </p>
          <br />
          <hr style={{ borderTop: "1px solid #000" }} />
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Arcesium{" "}
            <i
              style={{
                lineHeight: "unset",
                margin: "unset",
                color: "steelblue",
              }}
            >
              Not Selected
            </i>
          </h5>
          <br />
          <p>
            <b>
              1. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            <ul style={{ marginLeft: "2rem", listStyleType: "disc" }}>
              <li>
                Technical round 1: This round took place on codepair (a
                collaborative platform for real time coding i.e the interviewer
                and interviewee can both see what the other person is typing on
                the screen). The question asked to me was the frog problem (n
                tiles, k maximum step size, find number of possible ways to
                reach last tile). I was asked to write a function for the above
                problem and optimise it. After that, he asked some basic
                questions of DSA. The interviewer for this round was super
                helpful and guied me in the process of optimising my function.
              </li>
              <li>
                Technical round 2: Questions asked were - what are malloc,
                calloc, what is BST, draw a BST, what is time complexity for
                searching, how to optimise it, balanced tree, how to balance.
                (for this round, having learnt in depth about balanced trees in
                the advanced data structures course offered in Honors helped a
                lot.)
              </li>
              <li>
                Technical round 3: Questions asked were - what are decorators,
                what is polymorphism, what is abstraction, what are abstract
                classes, what is function overloading, explain the layers of OSI
                model and their functions, what is TCP, what is UDP, difference
                between the two, what is HTTP, do you know what subnetting is.
              </li>
              <li>
                There was an HR round after this but sadly only 2 people were
                shortlisted for it and I was not one of them.
              </li>
            </ul>
          </p>
          <p>
            <br />
            <i>
              Even though I did not get selected by Arcesium, the entire process
              was one big learning experience which is why I thought of sharing
              it here. The interview rounds all happened in a day (on that same
              day, an interview for FinIQ was also scheduled). This can make the
              process quite hectic and there may be times when you feel quite
              tired and dejected. But staying calm and motivated during that
              time period is super important. Try talking to friends and family
              about all of this, it really helps. And most importantly, don’t
              lose hope. You’ll get there. All the best!
            </i>
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:gandhias18.comp@coep.ac.in">
              gandhias18.comp@coep.ac.in
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default arya_gandhi;

import React from "react";

const yash_gangwar_citi = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Yash Gangwar</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            <ol type="1">
              <li>Aptitude Test</li>
              <li>Technical Interview</li>
              <li>Managerial Interview</li>
            </ol>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            By far the easiest aptitude test I came across. Analytical questions
            were somewhat good. Technical questions involved only pseudo code
            and focused only on concepts and algorithms, and not on programming
            language. I felt there was very little focus on coding and more on
            problem solving ability.
          </p>

          <p>
            <b>3. How many interview rounds were there for the company?</b>
            <br />
            <i>Aptitude Test</i>
            <br />
            Very simple and easy. Just keep a track of time and try to focus
            more on quantitative as they might take some time.
            <br />
            <br />
            <i>Technical Interview</i>
            <br />
            Didn’t dive into technical questions too hard. Asked me questions
            based on my resume. He wanted to know if there was any professional
            work I had done and if I had deployed any of my technical projects.
            He spend quite some time trying to understand the details of my
            latest project and how it helped the masses.
            <br />
            <br />
            <i>Managerial Interview </i>
            <br />
            One of the interviews I’ve enjoyed the most. He asked me a lot of
            questions that genuinely puzzled me. Some of the questions he had
            asked were guesstimates - to guess the population of cites, a lot of
            questions about my views on topics like the Covid-19 and many
            others. He spent a lot of time getting to know how I think and we
            had a somewhat of a long ‘interview’.
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />
            All of my technical questions were based on projects and points on
            my resume. Interviewer rarely asked any coding or theory questions.
            He asked how I had done my projects, which one’s I consider the best
            representation of what I had to offer and the clients I had worked
            for in the past. I’d say, know you resume well to get through the
            technical round.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />I had been using <a href="https://leetcode.com/">Leetcode</a>{" "}
            for a while to prepare for coding questions in general. Although the
            coding questions in Citi bank’s aptitude were comparatively easier,
            it helped answering them. I read ‘
            <a href="https://drive.google.com/open?id=1zWdVt8aUI5fwUR5Tls3xs8arc_8AZ7mb">
              Cracking the Coding Interview
            </a>{" "}
            - Gayle MacDowell’ for preparation. Not much technical preparation
            was required for me, but I sure would have preferred to have some
            practice or experience answering managerial interview questions like
            guesstimate.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            If you have mediocre experience with competitive coding and are
            versed well with the basic concepts like arrays, you should do well.
            The only reason I can expect someone to falter is if one
            misinterprets the question or has trouble managing the time. That
            being said, you should still take it seriously and only let out a
            sigh when you finally get placed :)
            <br />
          </p>
          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <br />
            Citicorp is big corporation spanning multiple nations. There’s a lot
            of opportunity for growth here, as far as I’ve heard. Read well
            though the job description Citi provides the hires prior to hiring
            and understand what is expected from the employees and your
            responsibilities in the company. If you enjoy tackling problems, but
            might not want to dive very deep into core computer theory, I’m sure
            you’d thrive at Citi.
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="https://www.linkedin.com/in/yashgangwar/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default yash_gangwar_citi;

import React from "react";

const bhaskar_pardeshi = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Bhaskar Pardeshi
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>VMware</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '21
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <br />
            This was a 6-months internship profile. We were informed about it,
            by the college, in the month of November. The internship process had
            the following things in the specified order
            <li>Company presentation</li>
            <li>Online aptitude test</li>
            <li>Interviews</li>
            <li>Results</li>
          </p>

          <p>
            <b>2. What questions were asked in the aptitude test?</b>
            <br />
            The aptitude test was 1.5 hours long. It was divided into multiple
            sections. Each section had a fixed amount of time, and the remainder
            time of a section was not carried onto the next, even if it was
            solved quickly. It had the following sections
            <br />
            <ol type="a">
              <li>Quantitative section (10 questions in 15 mins)</li>
              It consisted of Time and Work, Profit and Loss, Permutation and
              Combination and Data Interpretation related questions.
              <li>Technical section (30 questions in 30 mins)</li>
              It had Operating systems, Computer networking, Data Structures and
              Algorithms numericals.
              <li>Coding section (1 question in 30 mins)</li>
              The questions was “Finding the second smallest integer with m
              digits, where the sum of all digits is n, given m and n”.
              <li>Programming section (10 questions in 15 mins)</li>
              Four options were provided – C, C++, Java, and Python.
              Code-snippets and output related question were asked corresponding
              to the two languages selected at the beginning of the section.
            </ol>
          </p>

          <p>
            <b>
              3. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </b>
            <br />
            The company required a candidate to appear for 4 interviews before
            getting the internship offer. Few candidates were filtered after
            each interview round. Each interview had only one interviewer. The
            interview rounds were as follows
            <ol type="A">
              <li>Technical round 1 (1hr) -</li>
              It involved solving the 3 coding problems on the code-pair
              platform of hackerank. Stub code was not provided, it was expected
              to write the entire code and explain the space-time complexities
              of the same. However, if the interviewer was satisfied by logic or
              the function written for the problem, he/she may skip executing it
              <ol type="a">
                <li>Finding the Lowest Common Ancestor in a binary tree</li>
                <li>Writing a SQL query given some information need</li>
                <li>
                  Checking if a string of opening and closing brackets of 3
                  types is balanced or not
                </li>
              </ol>
              Following this, he asked about my T.Y. internship project and
              asked few questions on the concepts related to the frameworks used
              in the project.
              <li>Technical round 2 (45 min) -</li>
              <ol type="a">
                <li>
                  The interviewer asked to explain him a project of mine, which
                  I thought was the best.
                </li>
                <li>Then he asked questions on my OS’s mini project.</li>
                <li>
                  Following this a lot of time was spent on OS-related questions
                  – Creating processes, creating threads, different memory
                  segments in a process image, different ways of IPC. The
                  interviewer expected kernel-level understanding of the
                  concepts along with some idea of the data structures involved.
                </li>
                <li>
                  The interview ended with a simple coding question – “Shifting
                  all 0s in the array to the right”. Writing the function only
                  was sufficient and he finally asked about the complexities of
                  the proposed solution.
                </li>
              </ol>
              <li>Managerial round (45 mins) -</li>
              <ol type="a">
                <li>The interviewer asked about my club-related activities.</li>
                <li>
                  Following this, he asked which topic I was more comfortable
                  with and knew a lot about. I went with OS. (Few students went
                  with CN and even DSA).
                </li>
                <li>
                  He then various OS-related questions and again expected
                  kernel-level understanding of each of – Processes, Threads,
                  Address Spaces, different ways of IPC, different ways of
                  Scheduling. He finally asked a bit more on how socket IPC
                  affected the states of the communicating processes.
                </li>
                <li>
                  Following the technical questions, he asked what my plans for
                  higher education were and whether I am willing to relocate to
                  Bangalore if required. He gave a brief idea about what types
                  of projects was VMware involved in and will I be interested in
                  working on those types of projects.
                </li>
              </ol>
              <li>HR round (10 mins)</li>
              <ol type="a">
                <li>The interview process in general</li>
                <li>More about myself</li>
                <li>About any other offers I am currently holding</li>
                <li>
                  Whether I will accept an FTE offer, if extended at the end of
                  the internship
                </li>
                <li>
                  Whether I am willing to relocate to Bangalore if required
                </li>
              </ol>
            </ol>
          </p>

          <p>
            <b>
              4. How many questions were asked based on the projects and on
              resume?
            </b>{" "}
            <br />A lot! Irrespective of the company, every interviewer will ask
            about the work you did during the T.Y. Summer Internship – the main
            objective, technologies used, concepts involved, your contribution,
            etc. As far as projects are concerned, listing only those projects,
            which you are confident about in the resume makes sense. As in my
            case, two interviewers spent a lot of time on my OS mini project.
            Some thought can also be given in what order you specify the
            projects in your resume.
          </p>

          <p>
            <b>
              5. What are some good resources used by you in your preparation?
            </b>{" "}
            <br />
            For coding, LeetCode helped me a lot. For programming language or
            syntax related questions, it’s better to refer the questionnaires on
            GeeksForGeeks. For technical understanding of core subjects too,
            referring GeeksForGeeks is good. However, NEVER trust the answers on
            GeeksForGeeks, it only servers as a question bank. Studying the
            topics from well-known reference book really makes a difference.
          </p>

          <p>
            <b>6. Some tips to crack the coding questions.</b> <br />
            Solving a lot of Easy and Medium difficulty questions on LeetCode
            gives you a good understanding of common coding patterns. Try to
            avoid Hard problems, as they require a lot of time to get done with.
            LeetCode has a lot of features, one of which makes you solve 2
            random questions in 1 hour and rates your solution too with respect
            to others. I would recommend anyone who is appearing for coding
            tests to try this feature out, as this builds a skill of solving
            questions in a certain time and prevents you guys from spending a
            lot of time on a single question. If stuck for a very long time on a
            single question, there is no shame in checking out the discussions
            section for the solutions.
          </p>

          <p>
            <b>
              7. Is there something specific that you would want to tell people
              who try for the same company?
            </b>{" "}
            <br />
            The aptitude is pretty easy to clear. However, a good understanding
            of Operating Systems and Computer Networks would really boost your
            chances of clearing the interviews.
            <br />
          </p>

          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <a href="mailto:pardeshibs17.comp@coep.ac.in">
              pardeshibs17.comp@coep.ac.in
            </a>
            <br />
            <a href="https://www.linkedin.com/in/bhaskar-pardeshi/">
              LinkedIn Handle
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default bhaskar_pardeshi;

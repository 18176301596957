import React from "react";

const rujuta_budke = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Rujuta Budke
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            Aptitude test followed by 3 rounds of interviews
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude test differed for various roles. Mine consisted of logical questions, some mathematical questions and coding questions.
                            <br />
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 3 rounds. First one was the technical round. Here, they asked about my projects in detail. The second round was a managerial round. This was similar to an HR interview. They asked about why I had applied to Citi and in general about my journey from 10th grade to college. The last round was an HR round.
                    </p>
                    <br />
                    <p>
                        <b> 4. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            The majority of the technical round was based on projects. They also asked a bit about projects in the HR round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Don’t lose faith in yourself                        </p>
                    </p>
                    <br />
                </div>
            </section >
        </div >
    );
};

export default rujuta_budke;

import React from "react";
import { Redirect } from "react-router-dom";
import { data as TYData } from "../assets/diary-data/TY_diaryData";
import { data as BtechData } from "../assets/diary-data/BTech_diaryData.js";
import { data as PlacementData } from "../assets/placements-data/PlacementsData.js";

function findCompany(companyName, data) {
  //console.log(data);
  const currentCompany = data.find((comp) => comp.company.toLocaleLowerCase().replace(/\s/g,`${'_'}`) === companyName||
  comp.company.toLocaleLowerCase().replace(/\s/g,`${'-'}`) === companyName||
  comp.company.toLocaleLowerCase().replace(/\s/g,`${' '}`) === companyName||
  comp.company.toLocaleLowerCase().replace(/\s/g,`${''}`) === companyName);
  return currentCompany;
}

function getTitle(location) {
  let title = "";
  if (location.startsWith("/ty-diaries/"))
    title = "TY Internship Diaries";
  else if (location.startsWith("/btech-diaries/"))
    title = "BTech Internship Diaries";
  else
    title = "Placement Diaries";

  return title;
}

function CompanyWiseBatchless({ match, history }) {
  const companyName = match.params.company;
  const title = getTitle(history.location.pathname);
  let company = {};
  if (title === "TY Internship Diaries")
    company = findCompany(companyName, TYData);
  else if (title === "BTech Internship Diaries")
    company = findCompany(companyName, BtechData);
  else
    company = findCompany(companyName, PlacementData);

  if (company === undefined) {
    return <Redirect to="/"/>
  }
  const batches = company.batches;

  const path = history.location.pathname;
  
  
  if (path[path.length - 1] === "/")
    return <Redirect to={path + batches[batches.length - 1]} />
  
  return <Redirect to={path + "/" + batches[batches.length - 1]} />
}

export default CompanyWiseBatchless;
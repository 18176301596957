import React from "react";

const yash_shah = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{ fontSize: "2em", position: "absolute", marginTop: "2rem" }}
          ></span>
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Yash Shah</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Wolters Kluwer
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '20
            </p>
          </div>
          <br />
          <p>
            <strong>
              Q. What was the structure of the entire internship hiring process?
            </strong>
            <br />
            There was supposed to be an online aptitude test, but the internet
            in the college was down, so it was canceled. We were asked to send
            across our resumes, based on which we were shortlisted and were
            asked to come to their office for the interview process.
          </p>
          <br />
          <p>
            <strong>
              Q. How many interview rounds were there for the company, and in
              brief could you elaborate each round?
            </strong>
            <br /> Most candidates had 2 rounds, but I had three rounds of
            interview, all of them technical but with increasing levels of
            diificulty.
          </p>

          <h5>FIRST ROUND</h5>
          <p>
            It was quite a basic round where I was tested on my knowledge of
            python. Asked me various questions on Comprehensions, deepcopy vs
            shallow copy, typecasting, few corner cases. These sum up most of
            what was asked.
            <br />
            Some Useful Links: <br />
            <a href="https://learnandlearn.com/python-programming/python-how-to/python-function-arguments-mutable-and-immutable">
              https://learnandlearn.com/python-programming/python-how-to/python-function-arguments-mutable-and-immutable{" "}
            </a>
            <br />
            <a href="https://medium.com/@tyastropheus/tricky-python-ii-parameter-passing-for-mutable-immutable-objects-10e968cbda35">
              https://medium.com/@tyastropheus/tricky-python-ii-parameter-passing-for-mutable-immutable-objects-10e968cbda35
            </a>
          </p>
          <h5>SECOND ROUND</h5>
          <p>
            Started off by taking a look at my resume. Asked me questions about
            my DSA(bc) and PPL(Language Translator) project. Asked me to explain
            my process of building them and what problems I had faced, how I
            overcame them. I was then asked about random things:
            <ul>
              <li>What is the raw mode in the terminal?</li>
              <li>What is the GIL? How do you overcome it?</li>
              <li>Why exactly is python slow? What is JIT?</li>
              <li>
                Started to talk about data science, how much I knew, basic
                questions like clustering, classification etc. Which one do you
                use and when
              </li>
              <li>
                Asked me basics about how comfortable I was with other stacks
                (LAMP / MEAN) etc
              </li>
              <li>
                Asked me a design question:
                <br /> There are 30 data scientists all with individual training
                loads (mixed GPU and CPU load), as a company what is the most
                efficient way (Cost wise) that you can give each one of them
                access to hardware (either shared or centralized) such that
                performance isn’t lost?
              </li>
              <li>
                Would you prefer cloud based solutions or in-house hardware?{" "}
              </li>
            </ul>
            <br />
          </p>
          <h5>THIRD ROUND</h5>
          <p>
            This has been my most challenging round so far. It was a technical /
            aptitude round combined.
            <ul>
              <li>
                Started off by asking me a few puzzles, and adding corner cases
                to them
              </li>
              <li>
                Started by asking me a few NLP questions, after they saw the
                language translator:
              </li>
              <li>
                What is POS / NER? What is TFIDF, What is it’s mathematical
                formula
              </li>
              <li>Word2Vec, CBOW, LDA</li>

              <li>
                {" "}
                Asked me how comfortable I was with Data Science / Machine
                Learning / Deep Learning, I said not a lot; but the rest of the
                interview was based off this only.
                <li>
                  Asked me to name all classification techniques that I knew.
                  Difference between them, advantages, disadvantages.
                </li>
                <li>Decision Trees, Bagging, Boosting, AdaBoost, XGBoost.</li>
                <li>
                  Precision, recall, f1,AUC. How to determine over fitting from
                  ROC.{" "}
                </li>
              </li>
              <li>
                Basics into neural nets, activation functions, their
                differences, ranges.
              </li>
              <li>Difference between RNNs and LSTMs.</li>
            </ul>
            <p>
              The interview ended here, they asked me for feedback, and gave me
              some in return and said they would inform us in 2-3 working days
            </p>
            <br />
          </p>
          <h5>MISCELLANEOUS QUESTIONS</h5>
          <p>
            <strong>
              How many questions were asked based on the projects and on resume?
            </strong>
            <br />
            Quite a few, to make sure I knew what I was talking about.
          </p>
          <p>
            <strong>
              What are some good resources used by you in your preparation?
            </strong>
            <br />
            CTCI is a nice book to refer.
          </p>
          <p>
            <strong>Some tips to crack the coding questions</strong>
            <br />I think the biggest problem that we face as students is that
            when companies come, we’re generally out of practice, so keep
            practicing.
          </p>
          <p>
            <strong>
              Is there something specific that you would want to tell people who
              try for the same company?
            </strong>
            <br />
            Have your fundamentals about Data Science / Machine Learning strong,
            not just the applications / coding part of it
          </p>
          <br />
          <div style={{ color: "gray", fontSize: "1.15em" }}>
            <a href="mailto: yashah1234@gmail.com	"> yashah1234@gmail.com </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default yash_shah;

import React from "react";

const aditya_choudhary = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Aditya Choudhary
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>CarrotSoft Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There was no aptitude test. Students with a CGPA above 7 were directly shortlisted for the interview, which lasted approximately 15-20 minutes per candidate. Interviews were conducted in descending order of CGPA, with candidates having higher CGPAs interviewed first.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            No Aptitutde Round was conducted.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There was only one round, which was the technical round, conducted by two interviewers. At the beginning, they asked me to introduce myself, and then we discussed the various tech stacks I am familiar with. Subsequently, I was asked several questions on DBMS. Some of the questions I remember were about ACID properties, SQL injections, and the differences between SQL and NoSQL. Finally, we discussed how I could contribute to building their startup.
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        Out of the 50-60 students who were interviewed, only 5 were ultimately offered the internship.
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                Striver 191 Placement SDE Sheet
                            </li>
                            <li>
                                OS and DBMS notes from Code Help
                            </li>
                            <li>
                                CN concepts from - <a href="https://www.youtube.com/@PowerCertAnimatedVideos">https://www.youtube.com/@PowerCertAnimatedVideos</a>
                            </li>
                            <li>
                                OOPS Concepts from GFG
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Thoroughly practice DSA questions from platforms like LeetCode, Geeks for Geeks, etc. Don’t directly jump to the best solution; start with the brute force approach and gradually work your way to the optimal solution, understanding at each step what can be improved. Apply the concepts you have already learned while solving these DSA questions.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Only one question was asked about the project, and it was to give a brief explanation.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            This startup offers involvement in various aspects of work unlike traditional MNCs where tasks are specific and come with numerous restrictions. Opt for this startup if you seek extensive exploration. While your earnings might be lower compared to peers, the learning experience will undoubtedly be enriching.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            The interview process can be arduous, often taking unexpected turns. It relies on both your efforts and luck, with the latter being beyond your control. Therefore, it's crucial to exert maximum effort to secure a position in a good company. Learn from rejections and thoroughly prepare before each subsequent interview. Patience is key throughout this process. While internships are valuable learning experiences, your ultimate goal should be securing a placement. Approach each opportunity as a chance to learn and perform your best.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:choudharyap21.comp@coeptech.ac.in">
                            choudharyap21.comp@coeptech.ac.in
                        </a>
                        <br />
                    </div>
                </div>
            </section >
        </div >
    );
};

export default aditya_choudhary;

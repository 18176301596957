import React from "react";

const shriya_kale = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Shriya Kale
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Corp</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '25
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There were four rounds:                        <br />
                        </p>
                        <p>
                            <ol>
                                <li>Aptitude round (online)</li>
                                <li>Three interview rounds which included <br />
                                    <ol>
                                        <li>Technical Round</li>
                                        <li>HR Round</li>
                                        <li>HR Round</li>
                                    </ol></li>
                            </ol>
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            It consisted of 4 sections(for ICG-Tech):
                            <br />
                        </p>
                        <p>
                            <ol>
                                <li>
                                    Coding Questions:<br />
                                    <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                        <li>First question was to find whether a given number comes under fibonacci series or not. The difficulty level was easy.</li>
                                        <li>Second question was similar to find smallest permutation possible. The difficulty level was moderate.</li>
                                    </ul>
                                </li>
                                <li>SQL</li>
                                <li>React</li>
                                <li>Angular</li>
                            </ol></p>

                    </p>
                    <br />
                    <p>
                        <b> 3. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 3 rounds other than Aptitude round.<br />
                        1. Technical Round (40-45 minutes): <br />
                        It started off by asking me to introduce myself. Then he asked me about my projects in depth. I was asked to explain complete flow(i.e frontend/backend) of project on paper, various tech stacks used, choice of using it over other, pseudocode of algorithms used, idea behind the project, scalability and improvements. He also asked me how do you keep yourself updated with latest technology. He then went on asking me to write a code of some basic coding questions like pattern printing, various approaches to reverse a string, swap two numbers without using third variable, how sort function can be used in different languages.
                        He also asked to rate all the skills mentioned in the resume between 1-5 with reasons. Many OOPS concepts like different types of inheritance along with their real-time examples, virtual function and friend function; DBMS concepts like what is database, have you worked with any database earlier, normalisation, indexing and some SQL queries were also asked to write. He then asked me if I had any questions for him. Make sure to ask a question to show willingness to be a part of their company.
                        <br />
                        2. HR Round(10-15 minutes): <br />
                        He asked me questions like:
                        What do you prefer more teamwork or being individual while working on a project?
                        Why do you want to be a part of Citi?
                        Where do you see yourself in 10 years?
                        Then he asked me if I had any questions for him.
                        <br />
                        3. HR Round(10-15 minutes):<br />
                        First I was asked to introduce myself. He asked me common HR questions like how was your day, what are your hobbies, tell me about your family background, what is your stressbuster, then he went through my resume and asked why do you find yourself suitable for a Software Developer role. At last, I was given a chance to ask a question if any.
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round? </b>
                        <br />
                        For ICG-Tech:
                        <ol>
                            <li>
                                Aptitude: 39 were shortlisted
                            </li>
                            <li>
                                Technical Round: 16 were shortlisted                           </li>
                            <li>
                                HR Round: 12 were shortlisted                          </li>
                            <li>
                                HR Round: 10 were given final offer!!

                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b>5. Some good resources you used in your internship prep? </b>
                        <br />
                        <ol>
                            <li>
                                Coding questions: <a href="https://takeuforward.org/interviews/strivers-sde-sheet-top-coding-interview-problems/">Striver's SDE Sheet – Top Coding Interview Problems</a>
                            </li>
                            <li>
                                OOPS: <a href="https://www.interviewbit.com/oops-interview-questions/">Interviewbit</a>, <a href="https://www.geeksforgeeks.org/object-oriented-programming-in-cpp/">gfg</a>
                            </li>
                            <li>
                                DBMS: <a href="https://www.youtube.com/watch?v=dl00fOOYLOM">Love Babbar One shot video</a>
                            </li>
                            <li>
                                CN, OS: CodeHelp notes & gfg last minute notes
                            </li>
                            <li>
                                Aptitude, puzzle: Interviewbit, gfg, R.S.Aggarwal Quantitative Aptitude Book
                            </li>
                        </ol>
                    </p>
                    <br />
                    <p>
                        <b> 6. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Solve 3-4 coding questions daily. Follow any good SDE sheet and solve it consistently. Stick to one programming language. First think about brute force approach and then try to optimize it. Main focus must be to pass as many test cases as possible.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume</b>
                        <br />
                        <p>
                            My first half of the technical round was only about project-related questions.
                            Being a member of Hockey Team, some questions were asked on various hockey tournaments played, how was the experience, etc.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 8. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Make sure to attend pre-placement talk before appearing for interview.
                            <br />Fill your preferences for intern position appropriately because aptitude test and interviews will be according to your preference.
                            <br />Be thorough with your projects and tech stack used.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Firstly be thorough with your resume. Be confident during interview that makes more difference on our impression, while answering HR questions make sure you are positive and don’t bluff any answer. All the best!!
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:kalesr21.comp@coeptech.ac.in">
                            kalesr21.comp@coeptech.ac.in
                        </a>
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default shriya_kale;

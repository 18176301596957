import React, { useState } from "react";
import { Grid, Button, makeStyles, Badge } from "@material-ui/core";
import { Link } from "react-router-dom";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
// import NameCard from "../components/NameCard";
// import SectionHeading from "../components/SectionHeading";
// import { backGround } from "../layout/customStyle";
import { ArrowBack } from "@material-ui/icons";
import InternshipCardPage from "./InternshipCardPage";
import { backGround } from "../layout/customStyle";
import "./css/diary_page.css";
import TyDiaries from "./Companies/TyDiaries";
import BTechDiaries from "./Companies/BtechDiaries";
import PlacementDiaries from "./Companies/PlacementDiaries";

const useStyles = makeStyles(() => ({
  root: {
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.05)",
      cursor: "pointer",
    },
  },
  paperRoot: {
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.05)",
      cursor: "pointer",
    },
    width: "350px",
    height: "150px",
    alignContent: "center",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0)",
  },
  badgeRoot: {
    fontSize: "1.1rem",
    padding: ".5rem",
    paddingTop: ".9rem",
    paddingBottom: ".9rem",
    borderRadius: "1rem",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width:421px)"]: {
      marginRight: "20px",
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width:321px)"]: {
      marginRight: "40px",
    },
  },
  badgeColor: {
    backgroundColor: "rgba(44,79,150,1)",
    color: "rgba(255,255,255,1)",
  },
}));

export default function DiaryPage(props) {
  const { title, data, BTech, TY, Placement, diary } = props;
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const [item, setItem] = useState([]);
  const [id, setId] = useState(-1);
  const [cardClicked, setCardClicked] = useState(false);
  const htmlData = useState(null)[0];
  const dateData = useState(null)[0];
  const handleClick = (item, id) => {
    setClicked(true);
    setItem(item);
    setId(id);
  };

  const toggle = () => {
    setClicked(!clicked);
  };

  // const handleNameCardClick = (clicked, html, date) => {
  //   setCardClicked(clicked);
  //   setHtmlData(html);
  //   setDateData(date);
  // };

  


  const cardToggle = () => {
    setCardClicked(false);
  };

  if (cardClicked) {
    // console.log(PlacementData);
    return (
      <InternshipCardPage
        html={htmlData}
        date={dateData}
        title={title}
        cardToggle={cardToggle}
      />
    );
  }

  return (
    <main style={{ ...backGround, overflow: "hidden" }}>
      {/* <Paper style={{ background: "rgba(135, 135, 135,0.5)", margin: "auto" }}> */}
      <Navbar diary={diary} BTech={BTech} TY={TY} placements={Placement} />
      <div className="mb-5">
        <div style={{ marginTop: "40px", marginBottom: "40px" }}>
          <h1
            style={{
              textAlign: "center",
              color: "white",
              // fontFamily: "Gotham",
              fontSize: "2.4rem",
              marginBottom: "1.2rem",
            }}
          >
            {title}
          </h1>
        </div>

        <div className="container-fluid">
          {clicked ? (
            <Grid container justify="center" alignItems="center">
              <Grid container justify="center" alignItems="center">
                <h4
                  style={{
                    textAlign: "center",
                    color: "#4dc7f7",
                    // fontFamily: "Varela Round",
                    fontSize: "1.75rem",
                    marginBottom: "0.75rem",
                  }}
                >
                  {item.company}
                </h4>
              </Grid>

              <Grid container justify="center" alignItems="center">
                <Button
                  color="primary"
                  onClick={toggle}
                  className="my-2"
                  startIcon={<ArrowBack />}
                  style={{
                    color: "white",
                    fontSize: "25px",
                  }}
                >
                  Go back
                </Button>
              </Grid>
              <div>
                {title === "TY Internship Diaries" &&
                  (() => {
                    // console.log(item);
                    // console.log(item.company);

                    // console.log("entered here");
                    
                    return (
                      <TyDiaries
                        item={item}
                        interns={item.interns}
                        title={props.title}
                        company={item.company}
                        id={id}
                      />
                    );
                  })()}
              </div>
              <div>
                {title === "BTech Internship Diaries" &&
                  (() => {
                    // console.log(item);
                    // console.log(item.company);

                    // console.log("entered here");
                    return (
                      <BTechDiaries
                        item={item}
                        interns={item.interns}
                        title={props.title}
                        company={item.company}
                        button={item.disp}
                        id={id}
                      />
                    );
                  })()}
              </div>
              <div>
                {title === "Placement Diaries" &&
                  (() => {
                    // console.log(item);
                    // console.log(item.company);

                    // console.log("entered here");
                    return (
                      <PlacementDiaries
                        item={item}
                        title={props.title}
                        company={item.company}
                        button={item.disp}
                        id={id}
                      />
                    );
                  })()}
              </div>
            </Grid>
          ) : (
            <div
              style={{
                overflow: "hidden",
              }}
              className="grid-item"
            >
              <Grid container spacing={4} justify="center">
                {title === "TY Internship Diaries" &&
                  data.map((item, id) => (

                     <Link
                      to={`/ty-diaries/${item.company.toLocaleLowerCase().replace(/\s/g,`${'_'}`)}/${item.batches[item.batches.length - 1]
                      }`}>
                   
                      <Grid key={`interns-data-${id}`} item>
                        <div className={classes.paperRoot}>
                          <Badge
                            color="primary"
                            classes={{
                              badge: classes.badgeRoot,
                              colorPrimary: classes.badgeColor,
                            }}
                            style={{ color: "red" }}
                            badgeContent="New"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            overlap={
                              item.company === "" ? "circle" : "rectangle"
                            }
                            invisible={!item.isUpdated}
                          >
                            <img
                              alt="logo"
                              onClick={() => {
                                handleClick(item, id);
                              }}
                              src={item.logo}
                              style={{ width: "100%", maxWidth: "250px", maxHeight:"80px" }}
                            />
                          </Badge>
                        </div>
                      </Grid>
                    </Link>
                  ))}
                {title === "Placement Diaries" &&
                  data.map((item, id) => (
                    <Link
                      to={`/placement-diaries/${item.company.toLocaleLowerCase().replace(/\s/g,`${'_'}`)}/${
                        item.batches[item.batches.length - 1]
                      }`}
                    >
                      <Grid key={`interns-data-${id}`} item>
                        <div className={classes.paperRoot}>
                          <Badge
                            color="primary"
                            classes={{
                              badge: classes.badgeRoot,
                              colorPrimary: classes.badgeColor,
                            }}
                            style={{ color: "red" }}
                            badgeContent="New"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            overlap={
                              item.company === "" ? "circle" : "rectangle"
                            }
                            invisible={!item.isUpdated}
                          >
                            <img
                              alt="logo"
                              onClick={() => {
                                handleClick(item, id);
                              }}
                              src={item.logo}
                              style={{ width: "100%", maxWidth: "250px", maxHeight:"80px" }}
                            />
                          </Badge>
                        </div>
                      </Grid>
                    </Link>
                  ))}
                {title === "BTech Internship Diaries" &&
                  data.map((item, id) => (
                    <Link
                      to={`/btech-diaries/${item.company.toLocaleLowerCase().replace(/\s/g,`${'_'}`)}/${
                        item.batches[item.batches.length - 1]
                      }`}
                    >
                      <Grid key={`interns-data-${id}`} item>
                        <div className={classes.paperRoot}>
                          <Badge
                            color="primary"
                            classes={{
                              badge: classes.badgeRoot,
                              colorPrimary: classes.badgeColor,
                            }}
                            style={{ color: "red" }}
                            badgeContent="New"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            overlap={
                              item.company === "" ? "circle" : "rectangle"
                            }
                            invisible={!item.isUpdated}
                          >
                            <img
                              alt="logo"
                              onClick={() => {
                                handleClick(item, id);
                              }}
                              src={item.logo}
                              style={{ width: "100%", maxWidth: "250px", maxHeight:"100px" }}
                            />
                          </Badge>
                        </div>
                      </Grid>
                    </Link>
                  ))}
              </Grid>
            </div>
          )}
        </div>
      </div>
      <Footer />
      {/* </Paper> */}
    </main>
  );
}

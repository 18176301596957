import React, { Component } from "react";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import NameCard from "../../components/NameCard";
import { Paper,IconButton, Grid, Typography } from "@material-ui/core";
import {backGround} from "../../layout/customStyle";
import Navbar from "../../layout/Navbar";
// import Footer from "../../layout/Footer";
import { TiWarningOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import {data as PlacementData} from "../../assets/placements-data/PlacementsData";
import { generateBatchName } from "../../utils/batch";

class PlacementDiaries extends Component {
  state = {
    key: this.props.item.batches.indexOf(this.props.match.params.batch),
    // title:this.props.item.batches, 
    cardClicked : false,
    htmlData : null,
    dateData:null,
    p: false,
  };

  handleForwardClick = () => {
    let key = this.state.key;
    if (key === this.props.item.batches.length - 1) {
      key = 0;
    } else {
      key++;
    }
    this.setState({ key: key });
    this.props.history.push(`/placement-diaries/${this.props.item.company.toLocaleLowerCase().replace(/\s/g,`${'_'}`)}/${this.props.item.batches[key]}`)
  };
  handleBackwardClick = () => {
    let key = this.state.key;
    if (key === 0) {
      key = this.props.item.batches.length - 1;
    } else {
      key--;
    }
    this.setState({ key: key });
    this.props.history.push(`/placement-diaries/${this.props.item.company.toLocaleLowerCase().replace(/\s/g,`${'_'}`)}/${this.props.item.batches[key]}`)
  };
  setFunc=(d,h)=>{
    this.setState({p:true,dateData:d,htmlData:h});
  }

componentDidUpdate(prevProps, prevState) {

if (prevProps.match.params.batch !== this.props.match.params.batch) {
 
  this.setState({ key:this.props.item.batches.indexOf(this.props.match.params.batch)});
}
}
  click=(values)=>{
    //  console.log("company"+ this.props.item.company);
     
    // console.log(values.firstname);
    // console.log(values.lastname);
    
    var a=this.props.item.company.toLocaleLowerCase();
    // console.log(a);
    var name=(values.firstname+values.lastname).toLocaleLowerCase();
    if(this.props.title==="Placement Diaries"){
   

      PlacementData.forEach((item,index) => {
        if(a===item.company.toLocaleLowerCase()){
          item.interns.forEach((val,ind) => {
            if(
              (val.firstname+val.lastname).toLocaleLowerCase()===name){
                // this.setFunc.bind(this,values.date,val.html);
                this.setFunc(values.date, val.html);
                // console.log(val.html);
              }
          })
        }
      })
    }

    //this.setState({...this.state,dateData:values.date,htmlData:values.html});
  }
  cardToggle=()=>{
    this.setState({p:false})
  }
  render() {
    //console.log(this.state.dateData);
   
    if(this.state.p===true){
      return (
        <>
        <main style={backGround}>
          {/* <Paper style={{ background: "rgba(135, 135, 135,0.5)" }}> */}
          <Navbar placements={true}/>
          <div className="mb-5">
           
            <div className="container-fluid">
              <div className="container mb-5">
                <h6
                  style={{
                    textAlign: "center",
                    color: "white",
                    // fontFamily: "Varela Round",
                    fontSize: "1.3rem",
                  }}
                >
                  <i>
                    In case of further queries, visit our{" "}
                    <Link to="/faqPage"> FAQ Page</Link> to find out the answers
                    to commonly asked questions.
                  </i>
                </h6>
              </div>
              <br />
              <Grid container justify="center" alignItems="center">
                <Paper
                  variant="outlined"
                  elevation={3}
                  style={{
                    padding: "1rem",
                    // width: "52rem",
                    boxShadow: "1em 1em 1em #040404b8",
                  }}

                >{this.state.htmlData()}
                 
                  {this.state.dateData!== undefined ? (
                    <div
                      style={{
                        // display: "flex",
                        // justifyContent: "center",
                        textAlign: "center",
                        marginTop: "1.5rem",
                      }}
                    >
                      <Typography variant="h6" color="textPrimary">
                        &copy; CSI COEP
                        <br />
                        Date Published: {this.state.dateData.day}
                        <sup>{this.state.dateData.super}</sup> {this.state.dateData.rest}
                      </Typography>
                    </div>
                  ) : null}
                </Paper>
              </Grid>
              <div className="container mb-5 mt-5">
                <h2
                  style={{
                    textAlign: "center",
                    color: "#FEE715FF",
                    fontSize: "2.4rem",
                    marginBottom: "0.75rem",
                    marginRight: "1rem",
                  }}
                >
                  <TiWarningOutline
                    style={{
                      fontSize: "3.5rem",
                      margin: "1rem",
                    }}
                  />
                  Disclaimer
                </h2>
                <h6
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "1.3rem",
                  }}
                >
                  <i>
                    This is to inform readers that the views, thoughts, and
                    opinions expressed here belong solely to the author, and not
                    necessarily to the author's employer, organization, committee
                    or another group or individual.
                  </i>
                </h6>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
          {/* </Paper> */}
        </main>
      </>
        
      )
    }
    else{
      
      return (
        <div className="container-fluid">
          <Grid container justify="center" style={{ marginTop: "1rem" }}>
            {this.props.item.batches.length > 1 && <Grid item>
              <IconButton onClick={this.handleBackwardClick}>
                <ArrowBack
                  style={{
                    color: "white",
                    marginTop: "1rem",
                    marginRight: "1rem",
                  }}
                />
              </IconButton>
            </Grid>}
            <Grid item>
              <Typography
                variant="overline"
                style={{ color: "white", fontSize: "1.75rem" }}
              >
                {generateBatchName(this.props.item.batches[this.state.key])}
              </Typography>
            </Grid>
            {this.props.item.batches.length > 1 && <Grid item>
              <IconButton onClick={this.handleForwardClick}>
                <ArrowForward
                  style={{
                    color: "white",
                    marginTop: "1rem",
                    marginLeft: "0.8rem",
                  }}
                />
              </IconButton>
            </Grid>}
          </Grid>
          <Grid container justify="center">
            {this.props.item.interns.map((values, key) => {
              if (values.batch === this.props.item.batches[this.state.key]) {
                return (
                  <Grid
                        key={`interns-name-card-${key}`}
                        md={3}
                        xs={12}
                        sm={4}
                        item
                      >
                        <Link to={`/placement-diaries/${this.props.item.company.toLocaleLowerCase().replace(/\s/g,`${'_'}`)}/${this.props.match.params.batch}/${values.firstname.toLocaleLowerCase()+'-'+values.lastname.toLocaleLowerCase()}`}>
                          <div><NameCard {...values}/></div>
                        </Link>
                        </Grid>
                )
              }
              return null;
            }
            )}
          </Grid>
        </div>
      );
    }
  }
}

export default PlacementDiaries;
